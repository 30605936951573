import React from "react";
import { TablesOption } from "../../dimensions/category.type";
import ReactTooltip from "react-tooltip";

type TablesOptionCardProps = {
  table: TablesOption;
  onSelect: (id: string) => void;
  isSelected: boolean;
  key: string;
};

export default function TablesOptionCard({ table, onSelect, isSelected }: TablesOptionCardProps) {
  const handleClick = () => onSelect(table.id);

  return (
    <div
      onClick={handleClick}
      className={`table-option-card flex flex-col rounded-sm w-28 border p-2 mr-4 mt-4 cursor-pointer ${isSelected ? "active-chart-card" : ""
        }`}
      data-tip={
        table.tooltip ||
        "Compare two or more values, rank data categories, look up specific values, and recognize patterns in data. Use when you have discrete items like products or customers."
      }
    >
      <img src={table.image} alt="" className="mb-2" />
      <h1 className="font-semibold">{table.title}</h1>
      {table.showNewBadge && <h1 className="font-semibold new-badge option-new-badge">NEW</h1>}

      <ReactTooltip place="bottom" padding="12px" effect="solid" className="table-tooltip" />
    </div>
  );
}
