import { Plotter } from "../plotters/plotter";
import { ChartType } from "../../definitions";
import { Extreme } from "../extreme";
import {
    DataProperty, RECT, HIGHLIGHTABLE, LABEL, CHART_AREA, LogType, BAR, TOOLTIP, FONT_SIZE, FONT_FAMILY, TEXT_ANCHOR, END, START, ABSOLUTE_SORT, SECOND_ABSOLUTE_SORT, FONT_WEIGHT, DATA_SCALE_GROUP, DATA_PROPERTY, HEIGHT, WIDTH, X, Y, FILL, FONT_STYLE, ITALIC, INITIAL, FORMULA_ELEMENT, FONT_SIZE_UNIT, CHART_ID, BOLD
} from "../../library/constants";
import {
    getOrdinalScale, getLeftSideGridlinesEnd, getRightSideGrindlinesStart, plotGridlines, getTextAnchor, plotSortableHeader, addBlurIfNeeded,
    getVarianceColor, drawAbsoluteIcons, getCommentMarkerAttributes, addCommentMarkers, addMouseHandlers
} from "./chart";
import * as d3 from "d3";
import * as drawing from "./../../library/drawing";
import * as styles from "./../../library/styles";
import * as debug from "./../../library/debug";
import { isAdditionalMeasure } from "../../library/helpers";
import { DataPoint } from "../dataPoint";
import { VarianceSettings } from "../../settings/varianceSettings";
import { ViewModel } from "../../settings/viewModel";

export function plotPlusMinusChart(width: number, xPosition: number, groupIndex: number, chartHierarchyIndex: number, chartId: number, multiples: boolean,
    plotter: Plotter, dataProperty: DataProperty, plotOrder: number) {
    let { viewModel, settings, svg, plotArea, currentRow, groupHeaderHeight, currentYPosition: currentHeight, dataPropertyForComments } = plotter;
    let totalWidth = plotter.width;
    let height = plotter.getCurrentChartHeight();
    let chartGroup = viewModel.lastLevelChartGroups[groupIndex];
    let extreme: Extreme = null;
    if (settings.plottingHorizontally()) {
        extreme = chartGroup.extremes.getExtreme(dataProperty, settings);
    }
    else {
        extreme = viewModel.extremes.getAbsoluteExtreme(dataProperty, settings.absoluteChart);
    }
    let chartHierarchy = viewModel.getChartHierarchy(groupIndex, chartHierarchyIndex);
    let start = xPosition + (viewModel.HasCommentsColumn ? Math.max(40, extreme.getMinLabelOffset(settings)) : extreme.getMinLabelOffset(settings));
    let end = Math.max(start, xPosition + width - (viewModel.HasCommentsColumn ? Math.max(40, extreme.getMaxLabelOffset(settings)) : extreme.getMaxLabelOffset(settings)));
    let xScale = drawing.getLinearScale(Math.min(extreme.min, 0), Math.max(extreme.max, 0), start, end);
    let yScale = getOrdinalScale(chartHierarchy.dataPoints(), currentHeight, currentHeight + height, viewModel, settings.getGapBetweenColumns());
    let plottableDataPoints = plotter.getDataPointsToPlotChartElements(chartHierarchy, yScale, dataProperty, true);
    let gridlinePlottableDataPoints = plotter.getDataPointsToPlotChartElements(chartHierarchy, yScale, dataProperty, false);
    plotter.currentlyShowingDataPoints = plottableDataPoints.inView;
    let chartArea = drawing.createGroupElement(plotArea, `${CHART_AREA}_${groupIndex}_${chartHierarchyIndex}_${chartId} ${CHART_AREA}`);
    let scaleGroup = settings.getScaleGroup(dataProperty);
    chartArea.attr(DATA_SCALE_GROUP, scaleGroup);
    chartArea.attr(DATA_PROPERTY, dataProperty);
    chartArea.attr(CHART_ID, chartId);
    let axisPosition = xScale(0);
    let sort = dataProperty === DataProperty.AbsoluteDifference ? ABSOLUTE_SORT : SECOND_ABSOLUTE_SORT;
    let header = settings.getHeader(dataProperty);
    let isHeaderBold = settings.isHeaderBold(dataProperty) || settings.shouldUseBoldGroupHeaders(chartGroup, viewModel.maximumExpandedGroupLevel);

    if (debug.shouldLog(LogType.DisplayBounds)) {
        debug.drawBoundingBox(chartArea, xPosition, width, currentHeight, height, chartId);
    }
    let leftGridlineEndFunction = getLeftSideGridlinesEnd(xScale, sort, axisPosition);
    let rightGridlineStartFunction = getRightSideGrindlinesStart(xScale, sort, axisPosition, xPosition);
    plotGridlines(yScale, xPosition, width, axisPosition, settings, viewModel, gridlinePlottableDataPoints.inView, chartArea, chartId, leftGridlineEndFunction, rightGridlineStartFunction, groupIndex, chartHierarchyIndex);
    if (settings.shouldPlotVerticalAxis(chartHierarchy)) {
        let semanticAxis = !isAdditionalMeasure(dataProperty);
        drawing.plotVerticalAxis(chartArea, chartId, semanticAxis, axisPosition, currentHeight, height, settings.colorScheme.axisColor, settings.getReferenceScenarioForDataProperty(dataProperty), settings.showTotals, yScale, viewModel.hasHierarchy, chartHierarchy.isGrandTotal, settings.getGapBetweenColumns());
    }
    let headerXPosition = Math.round(axisPosition);
    let anchor = getTextAnchor(extreme.min, extreme.max);

    if (multiples) {
        let yPosition = chartGroup.level * groupHeaderHeight;
        if (chartGroup.isSubtotal) {
            yPosition = viewModel.maximumExpandedGroupLevel * groupHeaderHeight;
        }
        if (settings.shouldShowScenarioSortHeaders(plotter)) {
            let sortableHeader = plotSortableHeader(chartHierarchy, plotter, viewModel, totalWidth, header, currentRow, groupIndex, xPosition, yPosition + groupHeaderHeight + 3, anchor, isHeaderBold, dataProperty, plotOrder, true, width, headerXPosition, false, true, true, true, false, groupIndex, false, chartGroup, false, null, false, chartId);
            sortableHeader.setChartChangeIcons(drawAbsoluteIcons, ChartType.AbsoluteChart);
        }
    }
    else {
        let sortableHeader = plotSortableHeader(chartHierarchy, plotter, viewModel, totalWidth, header, currentRow, null, xPosition, 3, anchor, isHeaderBold, dataProperty, plotOrder, true, width, headerXPosition, false, true, true, true, false, groupIndex, false, chartGroup, false, null, false, chartId);
        sortableHeader.setChartChangeIcons(drawAbsoluteIcons, ChartType.AbsoluteChart);
    }
    let bars = drawing.getShapes(chartArea, `.${BAR}${chartId}`, `${BAR}${chartId} ${HIGHLIGHTABLE} ${TOOLTIP}`, RECT, plottableDataPoints.inViewWithoutFormulas.filter(d => d.getValue(dataProperty)));
    bars
        .attr(HEIGHT, Math.round(yScale.bandwidth()))
        .attr(WIDTH, d => Math.abs(axisPosition - xScale(d.getValue(dataProperty))))
        .attr(X, d => d.isNegative(dataProperty) ? xScale(d.getValue(dataProperty)) : axisPosition)
        .attr(Y, d => Math.round(yScale(d.getCategory(viewModel))));
    addMouseHandlers(bars, null, svg, viewModel);
    styles.applyToBars(bars, getVarianceColor(settings, dataProperty), settings.getValueScenarioForDataProperty(dataProperty), settings.chartStyle, true, settings.colorScheme);
    addBlurIfNeeded(bars, settings);
    // plotter.addTooltips(bars);
    let labelDataPoints = plottableDataPoints.inView.filter(d => d.getValue(dataProperty) && d.getValue(dataProperty) !== 0);
    if (!settings.showDataLabels) {
        labelDataPoints = [];
    }
    let labels = drawing.getLabels(chartArea, `${LABEL}${chartId}`, labelDataPoints);
    labels
        .text(d => d.getLabel(dataProperty))
        .style(FONT_SIZE, `${settings.labelFontSize}${FONT_SIZE_UNIT}`)
        .style(TEXT_ANCHOR, (d: DataPoint) => { return d.isNegative(dataProperty) ? END : START; })
        .style(FONT_FAMILY, settings.labelFontFamily)
        .style(FONT_WEIGHT, (d: DataPoint) => isHeaderBold ? BOLD : d.getFontWeight())
        .style(FONT_STYLE, (d: DataPoint) => d.italic ? ITALIC : INITIAL)
        .attr(X, d => d.isNegative(dataProperty) ? xScale(d.getValue(dataProperty)) - 5 : xScale(d.getValue(dataProperty)) + 5)
        .attr(Y, d => drawing.centerTextVertically(yScale, d.getCategory(viewModel), settings.labelFontSize))
        .attr(FILL, (d: DataPoint) => d.getLabelColor(settings.labelFontColor, dataProperty))
        .classed(FORMULA_ELEMENT, (d: DataPoint) => d.isCurrentlyEditedFormula())
        .classed(HIGHLIGHTABLE, true)
        .classed(TOOLTIP, true);
    // plotter.addTooltips(labels);
    // addMouseHandlers(labels, selectionManager, svg, viewModel);
    addBlurIfNeeded(labels, settings);

    if (viewModel.HasCommentsColumn) {
        let commentDataPoints: DataPoint[] = [];
        // comment markers with default data property (not being dragged to another data property):
        if (dataProperty === dataPropertyForComments) {
            commentDataPoints.push(...plottableDataPoints.inViewWithoutFormulas.filter(d => d.hasComment() && d.commentMarkerDataProperty === null));
        }
        commentDataPoints.push(...plottableDataPoints.inViewWithoutFormulas.filter(d => d.hasComment()).filter(d => d.commentMarkerDataProperty === dataProperty));
        plotCommentMarkers(chartArea, commentDataPoints, xScale, yScale, settings, dataProperty, viewModel, plotter.categoriesWidth, groupIndex);
        //plotter.addCommentMarkerTooltip(chartArea);
    }
}

function plotCommentMarkers(container: d3.Selection<SVGElement, any, any, any>, commentDataPoints: DataPoint[], xScale: d3.ScaleLinear<number, number>, yScale: d3.ScaleBand<string>,
    settings: VarianceSettings, dataProperty: DataProperty, viewModel: ViewModel, categoriesWidth: number, groupIndex: number) {
    let scaleBandWidth = yScale.bandwidth();
    let markerAttrs = getCommentMarkerAttributes(scaleBandWidth);
    let getMarkerYPosition = (d: DataPoint): number => yScale(d.getCategory(viewModel)) + scaleBandWidth / 2;
    let getMarkerXPosition = (d: DataPoint): number => xScale(0) + (d.isNegative(dataProperty) ? 1 : -1) * (markerAttrs.radius + markerAttrs.margin);
    addCommentMarkers(container, commentDataPoints, getMarkerXPosition, getMarkerYPosition, markerAttrs.radius, markerAttrs.fontSize, settings.colorScheme.highlightColor, settings.labelFontFamily, viewModel, categoriesWidth, groupIndex);
}
