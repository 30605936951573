import { ViewModel } from "../../settings/viewModel";
import { PlotDefinition, ChartType } from "../../definitions";
import { DataProperty } from "../../library/constants";
import { HorizontalPlotter } from "./horizontalPlotter";

export class HorizontalActualPlotter extends HorizontalPlotter {
    constructor(viewModel: ViewModel, width: number, height: number, reportArea: d3.Selection<SVGElement, any, any, any>, svg: d3.Selection<SVGElement, any, any, any>,
        locale: string) {
        super(viewModel, width, height, reportArea, svg, locale);
    }

    protected getPlotDefinition(): PlotDefinition {
        let plotDefinition = this.getBlankPlotDefinition(1);
        if (this.viewModel.hasHierarchy) {
            this.addValueColumns(plotDefinition, ChartType.Table);
        }
        else {
            this.addValueColumns(plotDefinition, ChartType.ValueChart);
        }
        return plotDefinition;
    }
}