import { DataViewHierarchyLevel, DataViewMatrixNode } from "@zebrabi/matrix-data";

import { ICreateGroups, SortDirection, CategoryOrder, CumulativeMinMaxAndOffsets, MinMax, EXPAND_COLLAPSE_WIDTH, FULL_CATEGORY, CategoryOrderRelative, CategoryTreeNode, IHierarchical, FormulaEditMode } from "../definitions";
import { ChartHierarchy } from "./chartHierarchy";
import { VarianceSettings } from "../settings/varianceSettings";
import { TopNSetting } from "../settings/topNSetting";
import { Extremes } from "./extremes";
import { ViewModel } from "../settings/viewModel";
import { DataProperty, NORMAL, BOLD, CATEGORY, VALUE_SORT, REFERENCE_SORT, SECOND_REFERENCE_SORT, THIRD_REFERENCE_SORT, TopNType, CategoryDisplayOptions } from "../library/constants";
import { DataPoint } from "./dataPoint";
import { Extreme } from "./extreme";
import { ChartGroup } from "./chartGroup";
import { LevelCategoryWidth } from "./../interfaces";
import * as d3 from "d3";
import * as calculations from "./../calculations";
import * as drawing from "./../library/drawing";
import * as statistics from "@zebrabi/legacy-library-common/outliersCalculation";
import * as formatting from "./../library/formatting";
import { Visual } from "../visual";

export class ChartGroups implements ICreateGroups {
    public chartGroups: ChartGroup[];
    private settings: VarianceSettings;

    constructor(private viewModel: ViewModel) {
        this.chartGroups = [];
        this.settings = viewModel.settings;
    }

    get length(): number {
        return this.chartGroups.length;
    }

    get hasCategories(): boolean {
        return this.chartGroups[0].hasCategories;
    }

    public addGroup(chartGroup: ChartGroup) {
        this.chartGroups.push(chartGroup);
    }

    public recalculateSubtotals(): void {
        this.chartGroups.forEach(cg => cg.recalculateSubtotals());
    }

    public createGroup(hasCategories: boolean, node: DataViewMatrixNode, hierarchyLevels: DataViewHierarchyLevel[], isRowGrandTotal: boolean, isColumnGrandTotal: boolean, level: number, totalLabel: string): ChartGroup {
        let nodeName = node.isSubtotal ? totalLabel : (node.value == null ? "" : node.value);
        if (!node.isSubtotal && hierarchyLevels && hierarchyLevels[level] && hierarchyLevels[level].sources && hierarchyLevels[level].sources.length && hierarchyLevels[level].sources[0].type.dateTime) {
            nodeName = formatting.fixDateTime(nodeName, hierarchyLevels[level].sources[0].format, this.viewModel.locale);
        }
        let chartGroup = new ChartGroup(hasCategories, node, hierarchyLevels, <string>nodeName, this.viewModel, isRowGrandTotal, isColumnGrandTotal, node.isSubtotal === true, null, level, null);
        this.chartGroups.push(chartGroup);
        return chartGroup;
    }

    public getChartGroups(): ChartGroup[] {
        let chartGroups = [];
        this.chartGroups.forEach(g => chartGroups = chartGroups.concat(g.getChartGroups()));
        return chartGroups;
    }

    public getNumberOfChartsInAGroup(): number {
        return this.chartGroups[0].getNumberOfChartsInGroup();
    }

    public getMaxNumberOfCategories(): number {
        return this.chartGroups.reduce((max, chartGroup) => {
            return Math.max(max, chartGroup.getNumberOfCategories());
        }, 0);
    }

    public getMaxCategoryWidthNoOutliers(viewModel: ViewModel): number {
        let categoryWidths = this.getCategoryWidths(viewModel);
        return d3.max(categoryWidths);
    }

    public getMaxCategoryWidth(viewModel: ViewModel): number {
        let categoryWidths = this.getCategoryWidths(viewModel);
        let [minCategoryWidth, maxCategoryWidth] = statistics.outliersCalculation(categoryWidths);
        return maxCategoryWidth ? maxCategoryWidth : 0;
    }

    public getCategoryWidths(viewModel: ViewModel): number[] {
        let prefixWidth = this.getTotalPrefixWidth(viewModel);
        let categoryWidths: number[] = [];
        viewModel.lastLevelChartGroups.forEach(chartGroup => {
            chartGroup.chartHierarchies.forEach(hierarchy => {
                categoryWidths = categoryWidths.concat(hierarchy.getCategoryWidths(prefixWidth, viewModel.maximumExpandedHierarchyLevel, viewModel.maximumExpandedGroupLevel));
            });
        });
        return categoryWidths;
    }

    private getTotalPrefixWidth(viewModel: ViewModel) {
        let shouldAddPrefixSpace = (this.settings.hasInvertOrResult() || this.settings.hasFlatResults) && this.settings.categoriesDisplayOptions !== CategoryDisplayOptions.Auto;
        let prefixWidth = shouldAddPrefixSpace ? drawing.measureTextWidth("=", this.settings.labelFontSize, this.settings.labelFontFamily, BOLD, NORMAL) + 3 : 0;
        let expandCollapseEnabled = viewModel.hasHierarchy && this.settings.getRealInteractionSettingValue(this.settings.allowExpandCollapseColumnsChange);
        let expandCollapseWidth = expandCollapseEnabled ? EXPAND_COLLAPSE_WIDTH : 0;
        let formulaIndent = Visual.formulaEditMode !== FormulaEditMode.None ? 15 : 0;
        return expandCollapseWidth + prefixWidth + formulaIndent;
    }

    public getMaxCategoryWidthByLevel(viewModel: ViewModel): number {
        let categoryWidths = this.getCategoryWidthsByLevel(viewModel);
        let categoryWidthsByLevel: number[][] = [];
        let maxLevel = d3.max(categoryWidths.map(el => el.level));
        for (let i = 0; i <= maxLevel; i++) {
            categoryWidthsByLevel.push([]);
        }
        categoryWidths.map(el => categoryWidthsByLevel[el.level].push(el.categoryWidth));
        let max = 0;
        for (let i = 0; i < categoryWidthsByLevel.length; i++) {
            let [minCategoryWidth, maxCategoryWidth] = statistics.outliersCalculation(categoryWidthsByLevel[i]);
            max = Math.max(maxCategoryWidth, max);
            if (this.settings.getTopNSetting(i) && this.settings.getTopNSetting(i).type !== TopNType.Off) {
                max = Math.max(max, ...categoryWidthsByLevel[i]);
            }
        }
        return max ? max : 0;
    }

    public getCategoryWidthsByLevel(viewModel: ViewModel): LevelCategoryWidth[] {
        let prefixWidth = this.getTotalPrefixWidth(viewModel);
        let categoryWidths: LevelCategoryWidth[] = [];
        viewModel.lastLevelChartGroups.forEach(chartGroup => {
            chartGroup.chartHierarchies.forEach(hierarchy => {
                categoryWidths = categoryWidths.concat(hierarchy.getCategoryWidthsByLevel(prefixWidth, viewModel.maximumExpandedHierarchyLevel, viewModel.maximumExpandedGroupLevel));
            });
        });
        return categoryWidths;
    }

    public sort(chartSort: SortDirection, categorySort: SortDirection, dataProperty: DataProperty) {
        if (chartSort === SortDirection.Descending) {
            this.chartGroups.sort((a, b) => {
                if (a.isRowGrandTotal || a.isSubtotal) {
                    return 1;
                }
                else if (b.isRowGrandTotal || b.isSubtotal) {
                    return -1;
                }
                return b.getTotal(dataProperty) - a.getTotal(dataProperty);
            });
        }
        else if (chartSort === SortDirection.Ascending) {
            this.chartGroups.sort((a, b) => {
                if (a.isRowGrandTotal || a.isSubtotal) {
                    return 1;
                }
                else if (b.isRowGrandTotal || b.isSubtotal) {
                    return -1;
                }
                return a.getTotal(dataProperty) - b.getTotal(dataProperty);
            });
        }
        this.chartGroups.forEach(chartGroup => {
            chartGroup.sort(chartSort, categorySort, dataProperty);
        });
    }

    public sortTree(categoryTree: CategoryTreeNode, categorySort: SortDirection) {
        if (!categoryTree.children || !categoryTree.children.length) {
            return;
        } else {
            categoryTree.children.sort((a, b) => categorySort === SortDirection.Descending ? b.result - a.result : a.result - b.result);
            categoryTree.children.forEach(c => this.sortTree(c, categorySort));
        }
    }

    public sortByCategory(categorySort: SortDirection, dataProperty: DataProperty, isHierarchical: boolean, chartGroups: ChartGroup[]) {
        // First sort the group parts in the correct order
        let category = isHierarchical ? FULL_CATEGORY : CATEGORY;
        let summedValues = this.getSummedValues(dataProperty, category, chartGroups);

        let rootNode: CategoryTreeNode = { category: "", value: 0, referenceValue: 0, secondReferenceValue: 0, thirdReferenceValue: 0, fourthReferenceValue: 0, fifthReferenceValue: 0, sixthReferenceValue: 0, seventhReferenceValue: 0, result: 0, children: [], level: -1 };
        let summedCategoryTree = this.getSummedCategoryTree(summedValues, dataProperty, rootNode, this.chartGroups);
        summedCategoryTree = this.fixCategoryTreeResults(summedCategoryTree, dataProperty);
        this.sortTree(summedCategoryTree, categorySort);

        for (let i = 0; i < chartGroups.length; i++) {
            chartGroups[i].sortByCategoryTree(summedCategoryTree, category);
        }
    }

    public setTopNByCategory(setting: TopNSetting, locale: string, percentageFormat: string, isHierarchical: boolean, chartGroups: ChartGroup[], isFocusMode: boolean) {
        let category = isHierarchical ? FULL_CATEGORY : CATEGORY;
        let summedValues = this.getSummedValues(setting.dataProperty, category, chartGroups);

        let rootNode: CategoryTreeNode = { category: "", value: 0, referenceValue: 0, secondReferenceValue: 0, thirdReferenceValue: 0, fourthReferenceValue: 0, fifthReferenceValue: 0, sixthReferenceValue: 0, seventhReferenceValue: 0, result: 0, children: [], level: -1 };
        let summedCategoriesTree = this.getSummedCategoryTree(summedValues, setting.dataProperty, rootNode, this.chartGroups);
        // now traverse tree and fix totals
        summedCategoriesTree = this.fixCategoryTreeResults(rootNode, setting.dataProperty);

        this.chartGroups.forEach(chartGroup => {
            chartGroup.cutTopNByCategory(setting, locale, percentageFormat, isHierarchical, summedCategoriesTree, isFocusMode);
        });
    }

    public fixCategoryTreeResults(rootNode: CategoryTreeNode, dataProperty: DataProperty) {
        for (let i = 0; i < rootNode.children.length; i++) {
            rootNode.children[i] = this.fixCategoryTreeResults(rootNode.children[i], dataProperty);
            let nodeValue = rootNode.children[i].value;
            let nodeReferenceValue = rootNode.children[i].referenceValue;
            let nodeSecondReferenceValue = rootNode.children[i].secondReferenceValue;
            let nodeThirdReferenceValue = rootNode.children[i].thirdReferenceValue;
            let nodeFourthReferenceValue = rootNode.children[i].fourthReferenceValue;
            let nodeFifthReferenceValue = rootNode.children[i].fifthReferenceValue;
            let nodeSixthReferenceValue = rootNode.children[i].sixthReferenceValue;
            let nodeSeventhReferenceValue = rootNode.children[i].seventhReferenceValue;

            let value = this.viewModel.getValueFromComparison(this.settings.valueScenario, nodeValue, nodeReferenceValue, nodeSecondReferenceValue, nodeThirdReferenceValue, nodeFourthReferenceValue, nodeFifthReferenceValue, nodeSixthReferenceValue, nodeSeventhReferenceValue, nodeValue);
            if (rootNode.children[i].children.length) {
                //let nodeValue = rootNode.children[i].value;
                if (dataProperty === DataProperty.RelativeDifference) {
                    let refValue = this.viewModel.getValueFromComparison(this.settings.referenceScenario, nodeValue, nodeReferenceValue, nodeSecondReferenceValue, nodeThirdReferenceValue, nodeFourthReferenceValue, nodeFifthReferenceValue, nodeSixthReferenceValue, nodeSeventhReferenceValue, nodeReferenceValue);
                    rootNode.children[i].result = calculations.calculateRelativeValue(value, refValue);
                }
                else if (dataProperty === DataProperty.SecondRelativeDifference) {
                    let secondRefValue = this.viewModel.getValueFromComparison(this.settings.secondReferenceScenario, nodeValue, nodeReferenceValue, nodeSecondReferenceValue, nodeThirdReferenceValue, nodeFourthReferenceValue, nodeFifthReferenceValue, nodeSixthReferenceValue, nodeSeventhReferenceValue, nodeSecondReferenceValue);
                    rootNode.children[i].result = calculations.calculateRelativeValue(value, secondRefValue);
                }
                else if (dataProperty === DataProperty.ThirdRelativeDifference) {
                    let thirdRefValue = this.viewModel.getValueFromComparison(this.settings.thirdReferenceScenario, nodeValue, nodeReferenceValue, nodeSecondReferenceValue, nodeThirdReferenceValue, nodeFourthReferenceValue, nodeFifthReferenceValue, nodeSixthReferenceValue, nodeSeventhReferenceValue, nodeThirdReferenceValue);
                    rootNode.children[i].result = calculations.calculateRelativeValue(value, thirdRefValue);
                }
                else if (dataProperty === DataProperty.FourthRelativeDifference) {
                    let fourthRefValue = this.viewModel.getValueFromComparison(this.settings.fourthReferenceScenario, nodeValue, nodeReferenceValue, nodeSecondReferenceValue, nodeThirdReferenceValue, nodeFourthReferenceValue, nodeFifthReferenceValue, nodeSixthReferenceValue, nodeSeventhReferenceValue, nodeFourthReferenceValue);
                    rootNode.children[i].result = calculations.calculateRelativeValue(value, fourthRefValue);
                }
                else if (dataProperty === DataProperty.FifthRelativeDifference) {
                    let fifthRefValue = this.viewModel.getValueFromComparison(this.settings.fifthReferenceScenario, nodeValue, nodeReferenceValue, nodeSecondReferenceValue, nodeThirdReferenceValue, nodeFourthReferenceValue, nodeFifthReferenceValue, nodeSixthReferenceValue, nodeSeventhReferenceValue, nodeFifthReferenceValue);
                    rootNode.children[i].result = calculations.calculateRelativeValue(value, fifthRefValue);
                }
                else if (dataProperty === DataProperty.SixthRelativeDifference) {
                    let sixthRefValue = this.viewModel.getValueFromComparison(this.settings.sixthReferenceScenario, nodeValue, nodeReferenceValue, nodeSecondReferenceValue, nodeThirdReferenceValue, nodeFourthReferenceValue, nodeFifthReferenceValue, nodeSixthReferenceValue, nodeSeventhReferenceValue, nodeSixthReferenceValue);
                    rootNode.children[i].result = calculations.calculateRelativeValue(value, sixthRefValue);
                }
                else if (dataProperty === DataProperty.SeventhRelativeDifference) {
                    let seventhRefValue = this.viewModel.getValueFromComparison(this.settings.seventhReferenceScenario, nodeValue, nodeReferenceValue, nodeSecondReferenceValue, nodeThirdReferenceValue, nodeFourthReferenceValue, nodeFifthReferenceValue, nodeSixthReferenceValue, nodeSeventhReferenceValue, nodeSeventhReferenceValue);
                    rootNode.children[i].result = calculations.calculateRelativeValue(value, seventhRefValue);
                }
                else {
                    rootNode.children[i].result = rootNode.children[i].value;
                }
            }
        }
        return rootNode;
    }

    // tslint:disable-next-line: max-func-body-length
    public getSummedCategoryTree(summedValues: CategoryOrder[], dataProperty: DataProperty, rootNode: CategoryTreeNode, currHierarchicals: IHierarchical[]) {
        let cg = this;

        for (let i = 0; i < currHierarchicals.length; i++) {
            let hierarchical = currHierarchicals[i];
            if (hierarchical instanceof ChartGroup) {
                if (hierarchical.chartGroups.length > 0) {
                    rootNode = cg.getSummedCategoryTree(summedValues, dataProperty, rootNode, hierarchical.chartGroups);
                }
                else {
                    // means we are at a chart group with no subgroups, go recursively inside hierarchies and set values
                    for (let j = 0; j < hierarchical.chartHierarchies.length; j++) {
                        let currentHierarcy = hierarchical.chartHierarchies[j];
                        if (currentHierarcy.isGrandTotal) {
                            continue;
                        }
                        if (rootNode.children.map(n => n.category).indexOf(currentHierarcy.fullCategory) === -1) {
                            rootNode.children.push({
                                category: currentHierarcy.fullCategory, level: currentHierarcy.level,
                                children: [], value: 0, referenceValue: 0, secondReferenceValue: 0, thirdReferenceValue: 0, fourthReferenceValue: 0, fifthReferenceValue: 0, sixthReferenceValue: 0, seventhReferenceValue: 0, result: 0
                            });
                        }
                        let currentHierarchyNodeIx = rootNode.children.findIndex(c => c.category === currentHierarcy.fullCategory);
                        if (currentHierarcy.hasChildHierarchies) {
                            rootNode.children[currentHierarchyNodeIx] = cg.getSummedCategoryTree(summedValues, dataProperty, rootNode.children[currentHierarchyNodeIx], currentHierarcy.chartHierarchies);
                        } else {
                            if (rootNode.children[currentHierarchyNodeIx].children.length === 0) {
                                let vals = summedValues.filter(sv => sv.category !== null ? sv.category.startsWith(currentHierarcy.fullCategory) : false);
                                for (let i = 0; i < vals.length; i++) {
                                    rootNode.children[currentHierarchyNodeIx].children.push({
                                        category: vals[i].category, level: currentHierarcy.level + 1,
                                        children: [], value: 0, referenceValue: 0, secondReferenceValue: 0, thirdReferenceValue: 0, fourthReferenceValue: 0, fifthReferenceValue: 0, sixthReferenceValue: 0, seventhReferenceValue: 0, result: vals[i].value,
                                    });
                                }
                            }
                        }
                        let valueDp = this.viewModel.getDataPropertyFromComparison(this.settings.valueScenario, DataProperty.Value);
                        if (dataProperty === DataProperty.RelativeDifference) {
                            rootNode.children[currentHierarchyNodeIx].value += currentHierarcy.getTotal(valueDp);
                            let dp = this.viewModel.getDataPropertyFromComparison(this.settings.referenceScenario, DataProperty.ReferenceValue);
                            rootNode.children[currentHierarchyNodeIx].referenceValue += currentHierarcy.getTotal(dp);
                        }
                        else if (dataProperty === DataProperty.SecondRelativeDifference) {
                            rootNode.children[currentHierarchyNodeIx].value += currentHierarcy.getTotal(valueDp);
                            let dp = this.viewModel.getDataPropertyFromComparison(this.settings.secondReferenceScenario, DataProperty.SecondReferenceValue);
                            rootNode.children[currentHierarchyNodeIx].secondReferenceValue += currentHierarcy.getTotal(dp);
                        }
                        else if (dataProperty === DataProperty.ThirdRelativeDifference) {
                            rootNode.children[currentHierarchyNodeIx].value += currentHierarcy.getTotal(valueDp);
                            let dp = this.viewModel.getDataPropertyFromComparison(this.settings.thirdReferenceScenario, DataProperty.ThirdReferenceValue);
                            rootNode.children[currentHierarchyNodeIx].thirdReferenceValue += currentHierarcy.getTotal(dp);
                        }
                        // fourth
                        else if (dataProperty === DataProperty.FourthRelativeDifference) {
                            rootNode.children[currentHierarchyNodeIx].value += currentHierarcy.getTotal(valueDp);
                            let dp = this.viewModel.getDataPropertyFromComparison(this.settings.fourthReferenceScenario, DataProperty.FourthReferenceValue);
                            rootNode.children[currentHierarchyNodeIx].fourthReferenceValue += currentHierarcy.getTotal(dp);
                        }
                        // fifth
                        else if (dataProperty === DataProperty.FifthRelativeDifference) {
                            rootNode.children[currentHierarchyNodeIx].value += currentHierarcy.getTotal(valueDp);
                            let dp = this.viewModel.getDataPropertyFromComparison(this.settings.fifthReferenceScenario, DataProperty.FifthReferenceValue);
                            rootNode.children[currentHierarchyNodeIx].fifthReferenceValue += currentHierarcy.getTotal(dp);
                        }
                        // sixth
                        else if (dataProperty === DataProperty.SixthRelativeDifference) {
                            rootNode.children[currentHierarchyNodeIx].value += currentHierarcy.getTotal(valueDp);
                            let dp = this.viewModel.getDataPropertyFromComparison(this.settings.sixthReferenceScenario, DataProperty.SixthReferenceValue);
                            rootNode.children[currentHierarchyNodeIx].sixthReferenceValue += currentHierarcy.getTotal(dp);
                        }
                        // seventh
                        else if (dataProperty === DataProperty.SeventhRelativeDifference) {
                            rootNode.children[currentHierarchyNodeIx].value += currentHierarcy.getTotal(valueDp);
                            let dp = this.viewModel.getDataPropertyFromComparison(this.settings.seventhReferenceScenario, DataProperty.SeventhReferenceValue);
                            rootNode.children[currentHierarchyNodeIx].seventhReferenceValue += currentHierarcy.getTotal(dp);
                        }
                        else {
                            rootNode.children[currentHierarchyNodeIx].value += currentHierarcy.getTotal(dataProperty);
                        }
                    }
                }
            } else if (hierarchical instanceof ChartHierarchy) {
                let currentHierarcy = hierarchical;
                if (currentHierarcy.isGrandTotal) {
                    continue;
                }
                if (rootNode.children.map(n => n.category).indexOf(currentHierarcy.fullCategory) === -1) {
                    rootNode.children.push({
                        category: currentHierarcy.fullCategory, level: currentHierarcy.level,
                        children: [], value: 0, referenceValue: 0, secondReferenceValue: 0, thirdReferenceValue: 0, fourthReferenceValue: 0, fifthReferenceValue: 0, sixthReferenceValue: 0, seventhReferenceValue: 0, result: 0
                    });
                }
                let currentHierarchyNodeIx = rootNode.children.findIndex(c => c.category === currentHierarcy.fullCategory);
                if (currentHierarcy.hasChildHierarchies) {
                    rootNode.children[currentHierarchyNodeIx] = cg.getSummedCategoryTree(summedValues, dataProperty, rootNode.children[currentHierarchyNodeIx], currentHierarcy.chartHierarchies);
                } else {
                    // add last lvl children (already calculated before), if not yet added
                    if (rootNode.children[currentHierarchyNodeIx].children.length === 0) {
                        let vals = summedValues.filter(sv => sv.category !== null ? sv.category.startsWith(currentHierarcy.fullCategory) : false);
                        for (let i = 0; i < vals.length; i++) {
                            rootNode.children[currentHierarchyNodeIx].children.push({
                                category: vals[i].category, level: currentHierarcy.level + 1,
                                children: [], value: 0, referenceValue: 0, secondReferenceValue: 0, thirdReferenceValue: 0, fourthReferenceValue: 0, fifthReferenceValue: 0, sixthReferenceValue: 0, seventhReferenceValue: 0, result: vals[i].value,
                            });
                        }
                    }
                }
                // add totals values of this hierarchy
                let valueDp = this.viewModel.getDataPropertyFromComparison(this.settings.valueScenario, DataProperty.Value);
                if (dataProperty === DataProperty.RelativeDifference) {
                    rootNode.children[currentHierarchyNodeIx].value += currentHierarcy.getTotal(valueDp);
                    let dp = this.viewModel.getDataPropertyFromComparison(this.settings.secondReferenceScenario, DataProperty.ReferenceValue);
                    rootNode.children[currentHierarchyNodeIx].referenceValue += currentHierarcy.getTotal(dp);
                }
                else if (dataProperty === DataProperty.SecondRelativeDifference) {
                    rootNode.children[currentHierarchyNodeIx].value += currentHierarcy.getTotal(valueDp);
                    let dp = this.viewModel.getDataPropertyFromComparison(this.settings.secondReferenceScenario, DataProperty.SecondReferenceValue);
                    rootNode.children[currentHierarchyNodeIx].secondReferenceValue += currentHierarcy.getTotal(dp);
                }
                else if (dataProperty === DataProperty.ThirdRelativeDifference) {
                    rootNode.children[currentHierarchyNodeIx].value += currentHierarcy.getTotal(valueDp);
                    let dp = this.viewModel.getDataPropertyFromComparison(this.settings.thirdReferenceScenario, DataProperty.ThirdReferenceValue);
                    rootNode.children[currentHierarchyNodeIx].thirdReferenceValue += currentHierarcy.getTotal(dp);
                }
                else if (dataProperty === DataProperty.FourthRelativeDifference) {
                    rootNode.children[currentHierarchyNodeIx].value += currentHierarcy.getTotal(valueDp);
                    let dp = this.viewModel.getDataPropertyFromComparison(this.settings.fourthReferenceScenario, DataProperty.FourthReferenceValue);
                    rootNode.children[currentHierarchyNodeIx].fourthReferenceValue += currentHierarcy.getTotal(dp);
                }
                else if (dataProperty === DataProperty.FifthRelativeDifference) {
                    rootNode.children[currentHierarchyNodeIx].value += currentHierarcy.getTotal(valueDp);
                    let dp = this.viewModel.getDataPropertyFromComparison(this.settings.fifthReferenceScenario, DataProperty.FifthReferenceValue);
                    rootNode.children[currentHierarchyNodeIx].fifthReferenceValue += currentHierarcy.getTotal(dp);
                }
                else if (dataProperty === DataProperty.SixthRelativeDifference) {
                    rootNode.children[currentHierarchyNodeIx].value += currentHierarcy.getTotal(valueDp);
                    let dp = this.viewModel.getDataPropertyFromComparison(this.settings.sixthReferenceScenario, DataProperty.SixthReferenceValue);
                    rootNode.children[currentHierarchyNodeIx].sixthReferenceValue += currentHierarcy.getTotal(dp);
                }
                else if (dataProperty === DataProperty.SeventhRelativeDifference) {
                    rootNode.children[currentHierarchyNodeIx].value += currentHierarcy.getTotal(valueDp);
                    let dp = this.viewModel.getDataPropertyFromComparison(this.settings.seventhReferenceScenario, DataProperty.SeventhReferenceValue);
                    rootNode.children[currentHierarchyNodeIx].seventhReferenceValue += currentHierarcy.getTotal(dp);
                }
                else {
                    rootNode.children[currentHierarchyNodeIx].value += currentHierarcy.getTotal(dataProperty);
                }
            }
        }
        return rootNode;
    }

    public getSummedCategories(dataProperty: DataProperty, category: string): CategoryOrder[] {
        let summedHierarchies: CategoryOrder[] = [];
        let summedRelativeHierarchies: CategoryOrderRelative[] = [];
        let leafChartGroup = this.getFirstLeafChartGroup(this.chartGroups);
        for (let i = 0; i < leafChartGroup.chartHierarchies.length; i++) {
            let chartHierarchy = leafChartGroup.chartHierarchies[i];
            if (chartHierarchy.isGrandTotal) {
                continue;
            }
            if (dataProperty === DataProperty.RelativeDifference || dataProperty === DataProperty.SecondRelativeDifference || dataProperty === DataProperty.ThirdRelativeDifference ||
                dataProperty === DataProperty.FourthRelativeDifference || dataProperty === DataProperty.FifthRelativeDifference || dataProperty === DataProperty.SixthRelativeDifference || dataProperty === DataProperty.SeventhRelativeDifference) {
                summedRelativeHierarchies.push({ category: chartHierarchy.getCategory(), value: 0, referenceValue: 0, secondReferenceValue: 0, thirdReferenceValue: 0, fourthReferenceValue: 0, fifthReferenceValue: 0, sixthReferenceValue: 0, seventhReferenceValue: 0 });
            }
            else {
                summedHierarchies.push({ category: chartHierarchy.getCategory(), value: 0 });
            }
        }
        this.addChartGroupsValues(this.chartGroups, summedHierarchies, summedRelativeHierarchies, dataProperty);
        return this.calculateCategoryOrder(summedHierarchies, summedRelativeHierarchies, dataProperty);
    }

    private addChartGroupsValues(chartGroups: ChartGroup[], summedHierarchies: CategoryOrder[], summedRelativeHierarchies: CategoryOrderRelative[], dataProperty: DataProperty) {
        let cg = this;
        chartGroups.forEach((group) => {
            if (group.chartGroups.length > 0) {
                cg.addChartGroupsValues(group.chartGroups, summedHierarchies, summedRelativeHierarchies, dataProperty);
            }
            else {
                for (let j = 0; j < group.chartHierarchies.length; j++) {
                    if (group.chartHierarchies[j].isGrandTotal) {
                        continue;
                    }
                    let totalDP = this.viewModel.getDataPropertyFromComparison(this.settings.valueScenario, DataProperty.Value);
                    if (dataProperty === DataProperty.RelativeDifference) {
                        summedRelativeHierarchies[j].value += group.chartHierarchies[j].getTotal(totalDP);
                        let refDP = this.viewModel.getDataPropertyFromComparison(this.settings.referenceScenario, DataProperty.ReferenceValue);
                        summedRelativeHierarchies[j].referenceValue += group.chartHierarchies[j].getTotal(refDP);
                    }
                    else if (dataProperty === DataProperty.SecondRelativeDifference) {
                        summedRelativeHierarchies[j].value += group.chartHierarchies[j].getTotal(totalDP);
                        let secondRefDP = this.viewModel.getDataPropertyFromComparison(this.settings.secondReferenceScenario, DataProperty.SecondReferenceValue);
                        summedRelativeHierarchies[j].secondReferenceValue += group.chartHierarchies[j].getTotal(secondRefDP);
                    }
                    else if (dataProperty === DataProperty.ThirdRelativeDifference) {
                        summedRelativeHierarchies[j].value += group.chartHierarchies[j].getTotal(totalDP);
                        let thirdRefDP = this.viewModel.getDataPropertyFromComparison(this.settings.thirdReferenceScenario, DataProperty.ThirdReferenceValue);
                        summedRelativeHierarchies[j].thirdReferenceValue += group.chartHierarchies[j].getTotal(thirdRefDP);
                    }
                    else if (dataProperty === DataProperty.FourthRelativeDifference) {
                        summedRelativeHierarchies[j].value += group.chartHierarchies[j].getTotal(totalDP);
                        let fourthRefDP = this.viewModel.getDataPropertyFromComparison(this.settings.fourthReferenceScenario, DataProperty.FourthReferenceValue);
                        summedRelativeHierarchies[j].fourthReferenceValue += group.chartHierarchies[j].getTotal(fourthRefDP);
                    }
                    else if (dataProperty === DataProperty.FifthRelativeDifference) {
                        summedRelativeHierarchies[j].value += group.chartHierarchies[j].getTotal(totalDP);
                        let fifthRefDP = this.viewModel.getDataPropertyFromComparison(this.settings.fifthReferenceScenario, DataProperty.FifthReferenceValue);
                        summedRelativeHierarchies[j].fifthReferenceValue += group.chartHierarchies[j].getTotal(fifthRefDP);
                    }
                    else if (dataProperty === DataProperty.SixthRelativeDifference) {
                        summedRelativeHierarchies[j].value += group.chartHierarchies[j].getTotal(totalDP);
                        let sixthRefDP = this.viewModel.getDataPropertyFromComparison(this.settings.sixthReferenceScenario, DataProperty.SixthReferenceValue);
                        summedRelativeHierarchies[j].sixthReferenceValue += group.chartHierarchies[j].getTotal(sixthRefDP);
                    }
                    else if (dataProperty === DataProperty.SeventhRelativeDifference) {
                        summedRelativeHierarchies[j].value += group.chartHierarchies[j].getTotal(totalDP);
                        let seventhRefDP = this.viewModel.getDataPropertyFromComparison(this.settings.seventhReferenceScenario, DataProperty.SeventhReferenceValue);
                        summedRelativeHierarchies[j].seventhReferenceValue += group.chartHierarchies[j].getTotal(seventhRefDP);
                    }
                    else {
                        summedHierarchies[j].value += group.chartHierarchies[j].getTotal(dataProperty);
                    }
                }
            }
        });
    }

    private getFirstLeafChartGroup(chartGroups: ChartGroup[]) {
        if (chartGroups[0].chartGroups.length > 0) {
            return this.getFirstLeafChartGroup(chartGroups[0].chartGroups);
        }
        return chartGroups[0];
    }

    // tslint:disable-next-line: max-func-body-length
    public getSummedValues(dataProperty: DataProperty, category: string, chartGroups: ChartGroup[]): CategoryOrder[] {
        let summedValues: CategoryOrder[] = [];
        let summedRelativeValues: CategoryOrderRelative[] = [];
        let hierarchies = chartGroups[0].getAllChartHierarchies();
        for (let i = 0; i < hierarchies.length; i++) {
            let chartHierarchy = hierarchies[i];
            for (let j = 0; j < chartHierarchy.numberOfPoints(); j++) {
                if (dataProperty === DataProperty.RelativeDifference || dataProperty === DataProperty.SecondRelativeDifference || dataProperty === DataProperty.ThirdRelativeDifference ||
                    dataProperty === DataProperty.FourthRelativeDifference || dataProperty === DataProperty.FifthRelativeDifference || dataProperty === DataProperty.SixthRelativeDifference || dataProperty === DataProperty.SeventhRelativeDifference) {
                    summedRelativeValues.push({ category: chartHierarchy.getDataPoint(j)[category], value: 0, referenceValue: 0, secondReferenceValue: 0, thirdReferenceValue: 0, fourthReferenceValue: 0, fifthReferenceValue: 0, sixthReferenceValue: 0, seventhReferenceValue: 0 });
                }
                else {
                    summedValues.push({ category: chartHierarchy.getDataPoint(j)[category], value: 0 });
                }
            }
        }
        for (let i = 0; i < chartGroups.length; i++) {
            let currentCategory = 0;
            let hierarchies = chartGroups[i].getAllChartHierarchies();
            for (let j = 0; j < hierarchies.length; j++) {
                let chartHieararchy = hierarchies[j];
                for (let k = 0; k < chartHieararchy.numberOfPoints(); k++) {
                    let valueDP = this.viewModel.getDataPropertyFromComparison(this.settings.valueScenario, DataProperty.Value);
                    if (dataProperty === DataProperty.RelativeDifference) {
                        let value = chartHieararchy.getDataPoint(k).getValue(valueDP);
                        if (value) {
                            summedRelativeValues[currentCategory].value += value;
                        }
                        let dp = this.viewModel.getDataPropertyFromComparison(this.settings.referenceScenario, DataProperty.ReferenceValue);
                        let referenceValue = chartHieararchy.getDataPoint(k).getValue(dp);
                        if (referenceValue) {
                            summedRelativeValues[currentCategory].referenceValue += referenceValue;
                        }
                        currentCategory++;
                    }
                    else if (dataProperty === DataProperty.SecondRelativeDifference) {
                        let value = chartHieararchy.getDataPoint(k).getValue(valueDP);
                        if (value) {
                            summedRelativeValues[currentCategory].value += value;
                        }
                        let dp = this.viewModel.getDataPropertyFromComparison(this.settings.secondReferenceScenario, DataProperty.SecondReferenceValue);
                        let secondReferenceValue = chartHieararchy.getDataPoint(k).getValue(dp);
                        if (secondReferenceValue) {
                            summedRelativeValues[currentCategory].secondReferenceValue += secondReferenceValue;
                        }
                        currentCategory++;
                    }
                    else if (dataProperty === DataProperty.ThirdRelativeDifference) {
                        let value = chartHieararchy.getDataPoint(k).getValue(valueDP);
                        if (value) {
                            summedRelativeValues[currentCategory].value += value;
                        }
                        let dp = this.viewModel.getDataPropertyFromComparison(this.settings.thirdReferenceScenario, DataProperty.ThirdReferenceValue);
                        let thirdReferenceValue = chartHieararchy.getDataPoint(k).getValue(dp);
                        if (thirdReferenceValue) {
                            summedRelativeValues[currentCategory].thirdReferenceValue += thirdReferenceValue;
                        }
                        currentCategory++;
                    }
                    else if (dataProperty === DataProperty.FourthRelativeDifference) {
                        let value = chartHieararchy.getDataPoint(k).getValue(valueDP);
                        if (value) {
                            summedRelativeValues[currentCategory].value += value;
                        }
                        let dp = this.viewModel.getDataPropertyFromComparison(this.settings.fourthReferenceScenario, DataProperty.FourthReferenceValue);
                        let fourthReferenceValue = chartHieararchy.getDataPoint(k).getValue(dp);
                        if (fourthReferenceValue) {
                            summedRelativeValues[currentCategory].fourthReferenceValue += fourthReferenceValue;
                        }
                        currentCategory++;
                    }
                    else if (dataProperty === DataProperty.FifthRelativeDifference) {
                        let value = chartHieararchy.getDataPoint(k).getValue(valueDP);
                        if (value) {
                            summedRelativeValues[currentCategory].value += value;
                        }
                        let dp = this.viewModel.getDataPropertyFromComparison(this.settings.fifthReferenceScenario, DataProperty.FifthReferenceValue);
                        let fifthReferenceValue = chartHieararchy.getDataPoint(k).getValue(dp);
                        if (fifthReferenceValue) {
                            summedRelativeValues[currentCategory].fifthReferenceValue += fifthReferenceValue;
                        }
                        currentCategory++;
                    }
                    else if (dataProperty === DataProperty.SixthRelativeDifference) {
                        let value = chartHieararchy.getDataPoint(k).getValue(valueDP);
                        if (value) {
                            summedRelativeValues[currentCategory].value += value;
                        }
                        let dp = this.viewModel.getDataPropertyFromComparison(this.settings.sixthReferenceScenario, DataProperty.SixthReferenceValue);
                        let sixthReferenceValue = chartHieararchy.getDataPoint(k).getValue(dp);
                        if (sixthReferenceValue) {
                            summedRelativeValues[currentCategory].sixthReferenceValue += sixthReferenceValue;
                        }
                        currentCategory++;
                    }
                    else if (dataProperty === DataProperty.SeventhRelativeDifference) {
                        let value = chartHieararchy.getDataPoint(k).getValue(valueDP);
                        if (value) {
                            summedRelativeValues[currentCategory].value += value;
                        }
                        let dp = this.viewModel.getDataPropertyFromComparison(this.settings.seventhReferenceScenario, DataProperty.SeventhReferenceValue);
                        let seventhReferenceValue = chartHieararchy.getDataPoint(k).getValue(dp);
                        if (seventhReferenceValue) {
                            summedRelativeValues[currentCategory].seventhReferenceValue += seventhReferenceValue;
                        }
                        currentCategory++;
                    }

                    else {
                        let value = chartHieararchy.getDataPoint(k).getValue(dataProperty);
                        if (value) {
                            summedValues[currentCategory].value += value;
                        }
                        currentCategory++;
                    }
                }
            }
        }
        return this.calculateCategoryOrder(summedValues, summedRelativeValues, dataProperty);
    }

    public calculateCategoryOrder(summedValues: CategoryOrder[], summedRelativeValues: CategoryOrderRelative[], dataProperty: DataProperty): CategoryOrder[] {
        if (dataProperty === DataProperty.RelativeDifference) {
            summedRelativeValues.map(categoryOrder => summedValues.push({
                category: categoryOrder.category,
                value: calculations.calculateRelativeValue(categoryOrder.value, categoryOrder.referenceValue),
            }));
        }
        else if (dataProperty === DataProperty.SecondRelativeDifference) {
            summedRelativeValues.map(categoryOrder => summedValues.push({
                category: categoryOrder.category,
                value: calculations.calculateRelativeValue(categoryOrder.value, categoryOrder.secondReferenceValue),
            }));
        }
        else if (dataProperty === DataProperty.ThirdRelativeDifference) {
            summedRelativeValues.map(categoryOrder => summedValues.push({
                category: categoryOrder.category,
                value: calculations.calculateRelativeValue(categoryOrder.value, categoryOrder.thirdReferenceValue),
            }));
        }
        else if (dataProperty === DataProperty.FourthRelativeDifference) {
            summedRelativeValues.map(categoryOrder => summedValues.push({
                category: categoryOrder.category,
                value: calculations.calculateRelativeValue(categoryOrder.value, categoryOrder.fourthReferenceValue),
            }));
        }
        else if (dataProperty === DataProperty.FifthRelativeDifference) {
            summedRelativeValues.map(categoryOrder => summedValues.push({
                category: categoryOrder.category,
                value: calculations.calculateRelativeValue(categoryOrder.value, categoryOrder.fifthReferenceValue),
            }));
        }
        else if (dataProperty === DataProperty.SixthRelativeDifference) {
            summedRelativeValues.map(categoryOrder => summedValues.push({
                category: categoryOrder.category,
                value: calculations.calculateRelativeValue(categoryOrder.value, categoryOrder.sixthReferenceValue),
            }));
        }
        else if (dataProperty === DataProperty.SeventhRelativeDifference) {
            summedRelativeValues.map(categoryOrder => summedValues.push({
                category: categoryOrder.category,
                value: calculations.calculateRelativeValue(categoryOrder.value, categoryOrder.seventhReferenceValue),
            }));
        }
        return summedValues;
    }

    public sortByGroup(chartSort: SortDirection, categorySort: SortDirection, dataProperty: DataProperty, referenceIndex: number, isHierarhical: boolean, chartGroups: ChartGroup[]) {
        let category = isHierarhical ? FULL_CATEGORY : CATEGORY;
        if (referenceIndex < 0) {
            referenceIndex = 0;
        }
        else if (referenceIndex > chartGroups.length - 1) {
            referenceIndex = chartGroups.length - 1;
        }
        let chartGroup = chartGroups[referenceIndex];
        chartGroup.sort(chartSort, categorySort, dataProperty);
        let sortedHierarchies = chartGroup.chartHierarchies.reduce((result, hierarchy) => result.concat(hierarchy.getHierarchyCategories(category)), []);
        let sortedCategories = chartGroup.getCategories(category);
        for (let i = 0; i < chartGroups.length; i++) {
            if (i === referenceIndex) {
                continue;
            }
            if (isHierarhical) {
                chartGroups[i].sortByReferenceHierarchies(sortedHierarchies);
            }
            chartGroups[i].sortByReferenceCategories(sortedCategories, category);
        }
    }

    public calculate(locale: string, percentageFormat: string): Extremes {
        let minsAndMaxes = this.chartGroups.map(chartGroup => chartGroup.calculate(locale, percentageFormat));
        return calculations.reduceMinsAndMaxes(minsAndMaxes);
    }

    public setTopN(setting: TopNSetting, locale: string, percentageFormat: string, isFocusMode: boolean) {
        this.chartGroups.forEach(chartGroup => {
            chartGroup.setTopN(setting, locale, percentageFormat, isFocusMode);
        });
    }

    public calculateCumulative(locale: string, percentageFormat: string, maximumExpandedHierarchyLevel: number, maximumExpandedGroupLevel: number): CumulativeMinMaxAndOffsets {
        let minsAndMaxes = this.chartGroups.map(chartGroup => chartGroup.calculateCumulative(locale, percentageFormat, maximumExpandedHierarchyLevel, maximumExpandedGroupLevel));
        return {
            value: calculations.reduceMinMax(minsAndMaxes.map(mm => mm.value)),
            referenceValue: calculations.reduceMinMax(minsAndMaxes.map(mm => mm.referenceValue)),
            secondReferenceValue: calculations.reduceMinMax(minsAndMaxes.map(mm => mm.secondReferenceValue)),
            thirdReferenceValue: calculations.reduceMinMax(minsAndMaxes.map(mm => mm.thirdReferenceValue)),
            fourthReferenceValue: calculations.reduceMinMax(minsAndMaxes.map(mm => mm.fourthReferenceValue)),
            fifthReferenceValue: calculations.reduceMinMax(minsAndMaxes.map(mm => mm.fifthReferenceValue)),
            sixthReferenceValue: calculations.reduceMinMax(minsAndMaxes.map(mm => mm.sixthReferenceValue)),
            seventhReferenceValue: calculations.reduceMinMax(minsAndMaxes.map(mm => mm.seventhReferenceValue)),
            absolute: calculations.reduceMinMax(minsAndMaxes.map(mm => mm.absolute)),
            secondAbsolute: calculations.reduceMinMax(minsAndMaxes.map(mm => mm.secondAbsolute)),
            thirdAbsolute: calculations.reduceMinMax(minsAndMaxes.map(mm => mm.thirdAbsolute)),
            fourthAbsolute: calculations.reduceMinMax(minsAndMaxes.map(mm => mm.fourthAbsolute)),
            fifthAbsolute: calculations.reduceMinMax(minsAndMaxes.map(mm => mm.fifthAbsolute)),
            sixthAbsolute: calculations.reduceMinMax(minsAndMaxes.map(mm => mm.sixthAbsolute)),
            seventhAbsolute: calculations.reduceMinMax(minsAndMaxes.map(mm => mm.seventhAbsolute)),
        };
    }

    public calculateCumulativeLabelOffset(mm: Extreme, width: number, locale: string, dataProperty: DataProperty, chartGroup: ChartGroup): MinMax {
        let xScale = drawing.getLinearScale(mm.min, mm.max, 0 + mm.minLabelOffset, width - mm.maxLabelOffset);
        if (chartGroup) {
            let mm = chartGroup.calculateCumulativeLabelOffset(xScale, width, locale, dataProperty);
            return { min: Math.max(0, mm.min), max: Math.max(0, mm.max) };
        }
        else {
            return this.chartGroups.reduce((result, current) => {
                let mm = current.calculateCumulativeLabelOffset(xScale, width, locale, dataProperty);
                return { min: Math.max(result.min, mm.min), max: Math.max(result.max, mm.max) };
            }, { min: 0, max: 0 });
        }
    }

    public getAllHierarchiesByLevel(level: number): ChartHierarchy[] {
        return this.chartGroups.reduce((result, g) => result.concat(g.getAllHierarchiesByLevel(level)), []);
    }

    public getAllGroupsByLevel(level: number): ChartGroup[] {
        return this.chartGroups.reduce((result, g) => result.concat(g.getAllGroupsByLevel(level)), []);
    }

    public getChartHeights(categoryHeight: number): number[] {
        return this.chartGroups.reduce((result: number[], chartGroup: ChartGroup) => {
            return result.concat(chartGroup.getChartHeights(categoryHeight));
        }, []);
    }

    public getMaximumExpandedLevel(): number {
        return this.chartGroups.reduce((result, g) => Math.max(result, g.getMaximumExpandedLevel()), -1);
    }

    public getColumnWidths(dataProperties: DataProperty[], viewModel: ViewModel, showingScenarioHeaders: boolean, sizeToData: boolean, chartGroups: ChartGroup[]): number[] {
        let columnWidths = chartGroups.map((g, i) => g.getColumnWidth(dataProperties, viewModel, showingScenarioHeaders, sizeToData, i));
        return columnWidths.reduce((result, current) => result.concat(current), []);
    }

    public addGrandTotals(viewModel: ViewModel) {
        for (let i = 0; i < this.chartGroups.length; i++) {
            let chartGroup = this.chartGroups[i];
            chartGroup.addGrandTotals(viewModel);
        }
    }

    public getAllDataPoints(): DataPoint[] {
        return this.chartGroups.reduce((result, group) => {
            return result.concat(group.getAllDataPoints());
        }, []);
    }

    public markElementsAsNotPlotted() {
        this.chartGroups.forEach(g => g.markElementsAsNotPlotted());
    }

    public markSelectedPoints(selectionIds: string[]) {
        this.chartGroups.forEach(g => g.markSelectedPoints(selectionIds));
    }

    public getMaxExpandedGroupLevel(): number {
        return d3.max(this.chartGroups.map(g => g.getMaxExpandedGroupLevel()));
    }
}