import { Plotter } from "./plotter";
import { PlotDefinition, VERTICAL_MARGIN, MAXIMUM_CATEGORY_HEIGHT, ChartType, ACT_ABS_REL, SAFETY_MARGIN, TextOptions, AbsoluteChart, ChartPlotDefinition } from "../../definitions";
import { ViewModel } from "../../settings/viewModel";
import { CategoryRowHeight, DataProperty, LogType, BLACK, DEBUG, SEGOE_UI_BOLD, SEGOE_UI, START, EMPTY, ShowAsTableOptions } from "../../library/constants";
import { Visual } from "../../visual";
import { ColumnAdder } from "../columnAdder";
import { ChartHierarchy } from "../chartHierarchy";
import { ChartGroup } from "../chartGroup";
import { sum, isAdditionalMeasure } from "./../../library/helpers";
import * as d3 from "d3";
import * as drawing from "./../../library/drawing";
import * as helpers from "./../../helpers";
import * as debug from "./../../library/debug";
import { plotAnalytics } from "./analyticsPlotter";

export abstract class VerticalPlotter extends Plotter {
    private numberOfChartsPerGroup: number;
    private numberOfGroups: number;
    protected abstract getPlotDefinitions(): PlotDefinition[];
    constructor(viewModel: ViewModel, width: number, height: number, reportArea: d3.Selection<SVGElement, any, any, any>, svg: d3.Selection<SVGElement, any, any, any>,
        /*selectionManager: ISelectionManager, options: VisualUpdateOptions,*/ locale: string) {
        super(viewModel, width, height, reportArea, svg, locale);
    }

    protected initialize(): void {
        this.numberOfChartsPerGroup = this.viewModel.chartGroups.getNumberOfChartsInAGroup();
        this.numberOfGroups = this.viewModel.chartGroups.chartGroups.length;
    }

    public getRowHeight(): number {
        let numberOfCategories = this.viewModel.getNumberOfCategories(true);
        let categoryHeight: number;
        let spaceForEachCategory: number;
        let minimumCategoryHeight: number;

        const availableHeightActual = this.availableHeight - (this.settings.grandTotalGap ? VERTICAL_MARGIN : 0);

        switch (this.settings.categoriesRowHeight) {
            case CategoryRowHeight.Auto:
                if (this.numberOfChartsPerGroup === 1) {
                    let totalNumber = d3.sum(numberOfCategories);
                    spaceForEachCategory = (availableHeightActual - this.totalGroupHeadersHeight) / totalNumber;
                }
                else {
                    spaceForEachCategory = (availableHeightActual - this.totalGroupHeadersHeight - (this.numberOfGroups - 1) * VERTICAL_MARGIN) / sum(numberOfCategories);
                }
                minimumCategoryHeight = this.getMinimumCategoryHeight();
                categoryHeight = Math.max(minimumCategoryHeight, spaceForEachCategory);
                categoryHeight = Math.min(2 * minimumCategoryHeight, categoryHeight);
                break;
            case CategoryRowHeight.FontSized:
                categoryHeight = this.getMinimumCategoryHeight();
                categoryHeight = Math.min(categoryHeight, MAXIMUM_CATEGORY_HEIGHT);
                break;
            case CategoryRowHeight.FontSized_1_1:
                categoryHeight = this.getMinimumCategoryHeight() * 1.1;
                categoryHeight = Math.min(categoryHeight, MAXIMUM_CATEGORY_HEIGHT);
                break;
            case CategoryRowHeight.FontSized_1_5:
                categoryHeight = this.getMinimumCategoryHeight() * 1.5;
                categoryHeight = Math.min(categoryHeight, MAXIMUM_CATEGORY_HEIGHT);
                break;
            case CategoryRowHeight.Stretch:
                if (this.numberOfChartsPerGroup === 1 && this.numberOfGroups === 1) {
                    spaceForEachCategory = (availableHeightActual - this.totalGroupHeadersHeight) / numberOfCategories[0];
                }
                else {
                    spaceForEachCategory = (availableHeightActual - this.totalGroupHeadersHeight - (this.numberOfGroups - 1) * VERTICAL_MARGIN) / sum(numberOfCategories);
                }
                minimumCategoryHeight = this.getMinimumCategoryHeight();
                categoryHeight = Math.max(minimumCategoryHeight, spaceForEachCategory);
                break;
            case CategoryRowHeight.Fixed:
                categoryHeight = this.settings.categoriesHeight;
                break;
        }

        return categoryHeight;
    }

    protected get totalGroupHeadersHeight(): number {
        const numberOfGroupHeaders = this.settings.showRowGrandTotal ? this.numberOfGroups - 1 : this.numberOfGroups;

        return numberOfGroupHeaders * this.groupHeaderHeight;
    }

    protected getChartHeights() {
        let numberOfCategories = this.viewModel.getNumberOfCategories(true);
        const categoryHeight = this.getRowHeight();
        return numberOfCategories.map(c => categoryHeight * c);
    }

    public calculateTotalVisualHeight() {
        if (this.numberOfChartsPerGroup === 1 && this.numberOfGroups === 1) {
            this.totalVisualHeight = Math.floor(this.chartHeights[0] + this.totalGroupHeadersHeight);
        }
        else {
            const chartHeights = this.settings.showFrozenRowGrandTotal()
                ? this.chartHeights.slice(0, this.chartHeights.length - 1)
                : this.chartHeights;
            let totalHeight = this.totalGroupHeadersHeight + sum(chartHeights);

            if (this.settings.grandTotalGap) {
                totalHeight += VERTICAL_MARGIN;
            }
            this.totalVisualHeight = Math.floor(totalHeight);
        }
    }

    protected getDefinitionThatFits(): PlotDefinition {
        let plotDefinitions = this.getPlotDefinitions();
        plotDefinitions.forEach(pd => {
            this.viewModel.additionalMeasures.forEach((c, i) => {
                let dp = helpers.getAdditionalMeasurePropertyFromIndex(i);
                let chartType = this.settings.getAdditionalMeasureChartType(dp);
                this.addChartDefinition(pd, chartType, dp, DataProperty.ReferenceValue, false, false);
            });
        });
        let arrayOfDataPropertyArrays = [];
        if (this.settings.chartType === ACT_ABS_REL) {
            plotDefinitions.forEach(pd => {
                arrayOfDataPropertyArrays.push(pd.chartPlotDefinitions.map(cd => cd.dataProperty));
            });
            this.filterRemovedChartsFromDefinitions(plotDefinitions);
        }
        if (this.settings.suppressEmptyColumns) {
            this.filterEmptyColumns(plotDefinitions);
        }

        this.fixAbsoluteRatios(plotDefinitions);
        for (let i = 0; i < plotDefinitions.length; i++) {
            let currentDefinition = plotDefinitions[i];
            if (this.definitionFits(currentDefinition)) {
                this.fullDataProperties = arrayOfDataPropertyArrays[i];
                return currentDefinition;
            }
        }
        if (this.settings.chartType === ACT_ABS_REL) {
            this.fullDataProperties = arrayOfDataPropertyArrays[plotDefinitions.length - 1];
        }
        return plotDefinitions[plotDefinitions.length - 1];
    }

    private fixAbsoluteRatios(plotDefinitions: PlotDefinition[]) {
        let numberOfAbsoluteCharts = 0;
        let nubmerOfRelativeCharts = 0;
        let numberOfRelativeAdditionalMeasures = 0;
        let allCharts = 0;
        plotDefinitions.forEach(pd => {
            pd.chartPlotDefinitions.forEach(cpd => {
                if (cpd.chartType !== ChartType.Table) {
                    if (helpers.isRelativeMeasure(cpd.dataProperty, this.settings)) {
                        nubmerOfRelativeCharts++;
                        if (isAdditionalMeasure(cpd.dataProperty)) {
                            numberOfRelativeAdditionalMeasures++;
                        }
                    }
                    else {
                        numberOfAbsoluteCharts++;
                    }
                    allCharts++;
                }
            });
            if (numberOfAbsoluteCharts > 0 && nubmerOfRelativeCharts === 0) {
                pd.absoluteRatio = 1;
            } else if (numberOfAbsoluteCharts === 0 && nubmerOfRelativeCharts > 0) {
                pd.absoluteRatio = 0;
            }
            else if (numberOfRelativeAdditionalMeasures > 0) {
                // Absolute ratio is only set for a fixed layout without taking additional measures in cosideration.
                // Since the number of relative additional measures is dynamic (user controlled) we have to update the absolute ratio.
                pd.absoluteRatio = numberOfAbsoluteCharts / allCharts;
            }
        });
    }

    private definitionFits(definition: PlotDefinition): boolean {
        let numberOfAllElements = definition.chartPlotDefinitions.length;
        let numberOfCharts = d3.sum(definition.chartPlotDefinitions.map(d => d.chartType === ChartType.Table ? 0 : 1));
        let tableWidths = d3.sum(definition.chartPlotDefinitions.map(d => d.chartType === ChartType.Table ? this.viewModel.getTableWidth(d.dataProperty, -1) : 0));
        return (this.availableWidth - numberOfAllElements * VERTICAL_MARGIN - this.settings.minChartWidth * numberOfCharts - tableWidths) > 0;
    }

    // tslint:disable-next-line: max-func-body-length
    @debug.measureDuration("Vertical plot")
    public plotReport(partialPlot: boolean): void {
        this.partialPlot = partialPlot;
        this.calculateChartWidths();
        if (!this.viewModel.plotOnlyCategories) {
            let chartWidth = this.getChartWidthForDataProperty(DataProperty.Value);
            this.improveCumulativeOffsetsIfNeeded(this.viewModel.extremes.valueCumulative, DataProperty.Value, chartWidth, null);
            if (this.viewModel.HasReferenceColumn) {
                chartWidth = this.getChartWidthForDataProperty(DataProperty.ReferenceValue);
                this.improveCumulativeOffsetsIfNeeded(this.viewModel.extremes.referenceValueCumulative, DataProperty.ReferenceValue, chartWidth, null);
                chartWidth = this.getChartWidthForDataProperty(DataProperty.AbsoluteDifference);
                this.improveCumulativeOffsetsIfNeeded(this.viewModel.extremes.absoluteCumulative, DataProperty.AbsoluteDifference, chartWidth, null);
                if (this.viewModel.HasSecondReferenceColumn) {
                    chartWidth = this.getChartWidthForDataProperty(DataProperty.SecondReferenceValue);
                    this.improveCumulativeOffsetsIfNeeded(this.viewModel.extremes.secondReferenceValueCumulative, DataProperty.SecondReferenceValue, chartWidth, null);
                    chartWidth = this.getChartWidthForDataProperty(DataProperty.SecondAbsoluteDifference);
                    this.improveCumulativeOffsetsIfNeeded(this.viewModel.extremes.secondAbsoluteCumulative, DataProperty.SecondAbsoluteDifference, chartWidth, null);
                }
                if (this.viewModel.HasThirdReferenceColumn) {
                    chartWidth = this.getChartWidthForDataProperty(DataProperty.ThirdReferenceValue);
                    this.improveCumulativeOffsetsIfNeeded(this.viewModel.extremes.thirdAbsoluteCumulative, DataProperty.ThirdReferenceValue, chartWidth, null);
                    chartWidth = this.getChartWidthForDataProperty(DataProperty.ThirdAbsoluteDifference);
                    this.improveCumulativeOffsetsIfNeeded(this.viewModel.extremes.thirdAbsoluteCumulative, DataProperty.ThirdAbsoluteDifference, chartWidth, null);
                }
                if (this.viewModel.HasFourthReferenceColumn) {
                    chartWidth = this.getChartWidthForDataProperty(DataProperty.FourthReferenceValue);
                    this.improveCumulativeOffsetsIfNeeded(this.viewModel.extremes.fourthAbsoluteCumulative, DataProperty.FourthReferenceValue, chartWidth, null);
                    chartWidth = this.getChartWidthForDataProperty(DataProperty.FourthAbsoluteDifference);
                    this.improveCumulativeOffsetsIfNeeded(this.viewModel.extremes.fourthAbsoluteCumulative, DataProperty.FourthAbsoluteDifference, chartWidth, null);
                }
                if (this.viewModel.HasFifthReferenceColumn) {
                    chartWidth = this.getChartWidthForDataProperty(DataProperty.FifthReferenceValue);
                    this.improveCumulativeOffsetsIfNeeded(this.viewModel.extremes.fifthAbsoluteCumulative, DataProperty.FifthReferenceValue, chartWidth, null);
                    chartWidth = this.getChartWidthForDataProperty(DataProperty.FifthAbsoluteDifference);
                    this.improveCumulativeOffsetsIfNeeded(this.viewModel.extremes.fifthAbsoluteCumulative, DataProperty.FifthAbsoluteDifference, chartWidth, null);
                }
                if (this.viewModel.HasSixthReferenceColumn) {
                    chartWidth = this.getChartWidthForDataProperty(DataProperty.SixthReferenceValue);
                    this.improveCumulativeOffsetsIfNeeded(this.viewModel.extremes.sixthAbsoluteCumulative, DataProperty.SixthReferenceValue, chartWidth, null);
                    chartWidth = this.getChartWidthForDataProperty(DataProperty.SixthAbsoluteDifference);
                    this.improveCumulativeOffsetsIfNeeded(this.viewModel.extremes.sixthAbsoluteCumulative, DataProperty.SixthAbsoluteDifference, chartWidth, null);
                }
                if (this.viewModel.HasSeventhReferenceColumn) {
                    chartWidth = this.getChartWidthForDataProperty(DataProperty.SeventhReferenceValue);
                    this.improveCumulativeOffsetsIfNeeded(this.viewModel.extremes.seventhAbsoluteCumulative, DataProperty.SeventhReferenceValue, chartWidth, null);
                    chartWidth = this.getChartWidthForDataProperty(DataProperty.SeventhAbsoluteDifference);
                    this.improveCumulativeOffsetsIfNeeded(this.viewModel.extremes.seventhAbsoluteCumulative, DataProperty.SeventhAbsoluteDifference, chartWidth, null);
                }
            }
            this.calculateChartWidths();
        }
        this.xPosition = this.settings.showUnfrozenCategories() ? this.categoriesWidth + VERTICAL_MARGIN : SAFETY_MARGIN;
        this.currentRow = 0;
        this.currentChart = 0;
        this.currentYPosition = 0;
        this.chartId = 0;
        let scrollTop = (<any>window).currentScrollTop;
        let chartGroups = this.viewModel.lastLevelChartGroups;
        for (let groupIndex = 0; groupIndex < chartGroups.length; groupIndex++) {
            let chartGroup = chartGroups[groupIndex];
            if (chartGroup.isSubtotal) {
                continue;
            }
            if (chartGroup.isRowGrandTotal && this.settings.showFrozenRowGrandTotal()) {
                this.plotArea = Visual.grandTotalRow;
                this.grandTotalHeight = this.chartHeights[this.currentChart];
                this.actualYEnd = this.currentYPosition;
                this.currentYPosition = 0;
            } else if (chartGroup.isRowGrandTotal && this.settings.grandTotalGap) {
                this.currentYPosition += VERTICAL_MARGIN;
            }
            else {
                this.plotArea = this.reportArea;
            }
            let group = chartGroup.group;
            if (group && !chartGroup.isRowGrandTotal) {
                if (this.isCurrentHeaderInView(scrollTop) && !chartGroup.isPlotted) {
                    let textOptions = this.getGroupHeaderTextOptions(group);
                    helpers.drawTextWithOptions(textOptions);
                    chartGroup.isPlotted = true;
                }
                this.currentYPosition += Math.round(this.groupHeaderHeight);
                if (debug.shouldLog(LogType.DisplayBounds)) {
                    if (this.numberOfGroups > 1) {
                        drawing.drawLine(this.reportArea, 0, this.width, this.currentYPosition - this.groupHeaderHeight, this.currentYPosition - this.groupHeaderHeight, 1, BLACK, DEBUG);
                        if (groupIndex > 0) {
                            drawing.drawLine(this.reportArea, 0, this.width, this.currentYPosition - this.groupHeaderHeight - VERTICAL_MARGIN, this.currentYPosition - this.groupHeaderHeight - VERTICAL_MARGIN, 1, BLACK, DEBUG);
                        }
                    }
                    drawing.drawLine(this.reportArea, 0, this.width, this.currentYPosition, this.currentYPosition, 1, BLACK, DEBUG);
                }
            }
            let hierarchies = chartGroup.getAllChartHierarchies();
            for (let hierarchyIndex = 0; hierarchyIndex < hierarchies.length; hierarchyIndex++) {
                this.chartId = 0;
                hierarchies[hierarchyIndex].plotYPosition = this.currentYPosition;
                if ((<any>window).collapseScroll && hierarchies[hierarchyIndex].fullCategory === (<any>window).collapseCategory) {
                    (<any>window).currentScrollTop = this.currentYPosition;
                    (<any>window).collapseScroll = false;
                }
                if (this.shouldPlotHierachy(groupIndex, hierarchyIndex, scrollTop, hierarchies[hierarchyIndex], chartGroup)) {
                    if (chartGroup.hasCategories && this.shouldShowCategories(groupIndex)) {
                        let tempPlotArea = this.plotArea;
                        if (this.settings.showFrozenCategories()) {
                            if (this.plotArea === Visual.grandTotalRow) {
                                this.plotArea = Visual.grandTotalCategoriesSelection;
                            } else {
                                this.plotArea = Visual.categoriesSelection;
                            }
                        }
                        this.plotCategories(groupIndex, hierarchyIndex, !this.viewModel.plotOnlyCategories);
                        this.plotArea = tempPlotArea;
                    }
                    this.plot(groupIndex, hierarchyIndex, false, true, chartGroup.isRowGrandTotal && this.settings.showFrozenRowGrandTotal(), chartGroup.isSubtotal, chartGroup.level);
                    this.currentlyShowingDataPoints.forEach(dp => dp.isPlotted = true);
                    hierarchies[hierarchyIndex].isPlotted = true;
                    if (this.xPosition > this.totalWidth) {
                        this.totalWidth = this.xPosition;
                    }
                    hierarchies[hierarchyIndex].plottedHeader = true;
                    this.xPosition = this.settings.showUnfrozenCategories() ? this.categoriesWidth + VERTICAL_MARGIN : SAFETY_MARGIN;
                }
                if (!(chartGroup.isRowGrandTotal && this.settings.showFrozenRowGrandTotal())) {
                    this.currentRow++;
                    this.currentYPosition += this.chartHeights[this.currentChart];
                }
                this.currentChart++;
            }

            if (!(chartGroup.isRowGrandTotal && this.settings.showFrozenRowGrandTotal())) {
                this.actualYEnd = this.currentYPosition;
                if (this.settings.showUnfrozenRowGrandTotal()) {
                    this.actualYEnd = this.actualYEnd - this.chartHeights[this.chartHeights.length - 1];
                }
            }
        }
        if (this.foundActual) {
            plotAnalytics(this.actualWidth, this.actualXPosition, this.actualYStart, 0, this, DataProperty.Value,
                this.viewModel.extremes.analyticsValues, this.valueChartIntegrated);
        }
        if (this.settings.shouldDrawColumnAdder(this.fullDataProperties, this.viewModel.numberOfGroupLevels)) {
            let columnAdder = new ColumnAdder(this.viewModel, this.fullDataProperties, this.categoriesWidth, this);
            columnAdder.plotColumnAdder(0);
        }
    }

    private getChartWidthForDataProperty(dataProperty: DataProperty): number {
        let definition = this.definitionToPlot.chartPlotDefinitions.filter(d => d.dataProperty === dataProperty);
        if (definition.length === 1) {
            return definition[0].width;
        }
        return 0;
    }

    private getGroupHeaderTextOptions(group: string): TextOptions {
        const fontFamily = this.settings.groupTitleFontFamily === SEGOE_UI_BOLD ? SEGOE_UI : this.settings.groupTitleFontFamily;
        const bold = this.settings.groupTitleFontFamily === SEGOE_UI_BOLD ? true : false;
        return {
            container: this.settings.freezeCategories ? Visual.categoriesSelection : this.svg,
            text: group,
            x: 0,
            y: this.currentYPosition,
            color: this.settings.groupTitleFontColor,
            fontFamily: fontFamily,
            fontSize: this.settings.groupTitleFontSize,
            textAnchor: START,
            textClass: EMPTY,
            expectedHeight: this.groupHeaderHeight,
            bold: bold,
        };
    }

    private shouldPlotHierachy(groupIndex: number, hierarchyIndex: number, scrollTop: number, hierarchy: ChartHierarchy, chartGroup: ChartGroup) {
        return ((groupIndex === 0 && hierarchyIndex === 0 && this.settings.freezeHeaders && !hierarchy.anyDataPointPlotted()) || (this.isCurrentHierarchyInView(scrollTop) || (chartGroup.isRowGrandTotal && this.settings.showFrozenRowGrandTotal()))) && !hierarchy.allDataPointsPlotted();
    }

    private isCurrentHeaderInView(scrollTop: number): boolean {
        return this.currentYPosition + this.groupHeaderHeight > scrollTop && this.currentYPosition < scrollTop + this.height;
    }

    private isCurrentHierarchyInView(scrollTop: number): boolean {
        return this.currentYPosition + this.chartHeights[this.currentChart] + 300 > scrollTop
            && this.currentYPosition - 300 < scrollTop + this.height;
    }

    protected getAbsoluteChartLabelOffset(dataProperty: DataProperty): number {
        let mm = this.viewModel.extremes;
        let cumulative = dataProperty === DataProperty.AbsoluteDifference ? mm.absoluteCumulative : mm.secondAbsoluteCumulative;
        let absolute = dataProperty === DataProperty.AbsoluteDifference ? mm.absolute : mm.secondAbsolute;
        switch (this.settings.absoluteChart) {
            case AbsoluteChart.Waterfall:
            case AbsoluteChart.CalculationWaterfall:
                return cumulative.minLabelOffset + cumulative.maxLabelOffset;
            case AbsoluteChart.Bar:
                return absolute.minLabelOffset + absolute.maxLabelOffset;
        }
    }

    protected getChartWidth(d: ChartPlotDefinition, gropuIndex: number): number {
        return d.width;
    }

    protected getMaxCategoryWidth(): number {
        return this.width / 4;
    }

    protected isMultipleCharts(): boolean {
        return (!this.settings.showRowGrandTotal && this.viewModel.getNumberOfCharts() > 1)
            || (this.settings.showRowGrandTotal && this.viewModel.getNumberOfCharts() > 2);
    }
}
