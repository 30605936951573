import React from "react";
import TablesCategories from "../../tables/categories/components/TablesCategories/TablesCategories";
import { Templates } from "../../templates/components/Templates";

export default function ChartChooserMain() {
  return (
    <main className="chart-chooser-main p-6 w-full">
      <p className="text-xs pb-2 mb-4 border-b border-gray-300">
        Zebra BI Tables for Office enables you to visualize your data with many different table types. Select the
        desired table type from the list of icons. Your table will be auto - generated and you will be able to change
        the data.
      </p>
      <TablesCategories />
      {/*<Templates />*/}
    </main>
  );
}
