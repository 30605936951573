import { ColorScheme } from "./interfaces"
import {
    ChartStyle, Scenario, MarkerStyle,
    DESIGN_SETTINGS, BLACK, WHITE, GRIDLINE_COLOR, MAJOR_GRIDLINE_COLOR, FILL, PATH, STROKE, STROKE_DASHARRAY, STROKE_WIDTH, STROKE_LINECAP, STROKE_LINEJOIN,
    PY, PL, FC, NEUTRAL, DASHED_DASHARRAY, DOTTED_DASHARRAY, ROUND, POSITIVE, NEGATIVE, MARKER, NONE, FILL_OPACITY,
    CUSTOMER_POSITIVE_COLOR, CUSTOMER_NEGATIVE_COLOR, CUSTOMER_NEUTRAL_COLOR, CUSTOMER_MARKER_COLOR, CUSTOMER_LINE_COLOR, CUSTOMER_AXIS_COLOR, CUSTOMER_GRIDLINE_COLOR,
    CUSTOMER_DOT_CHART_COLOR, CUSTOMER_MORE_COLORS, CUSTOMER_PY_COLOR, CUSTOMER_PL_COLOR, CUSTOMER_FC_COLOR, CUSTOMER_APPLY_PATTERNS, HIGHLIGHT_COLOR, FC2, FC3, CUSTOMER_PL2_COLOR, CUSTOMER_PL3_COLOR, PL2, PL3, PLAN_2_DASHARRAY, PLAN_3_DASHARRAY
} from "./constants"

import { getFCNumberFromScenario, getScenarioFromMarkerStyle, isForecastScenario, isPlanScenario } from "../helpers";
import * as d3 from "d3";

// tslint:disable-next-line: max-func-body-length
export function getColorSchemeFromStyle(chartStyle: ChartStyle): ColorScheme {
    switch (chartStyle) {
        case ChartStyle.Zebra:
            return {
                positiveColor: "#7aca00",
                negativeColor: "#ff0000",
                neutralColor: "#404040",
                markerColor: BLACK,
                lineColor: "#404040",
                axisColor: BLACK,
                gridlineColor: "#ccc",
                majorGridlineColor: "#999",
                dotChartColor: "#4080FF",
                useCustomScenarioColors: false,
                highlightColor: HIGHLIGHT_COLOR,
            };
        case ChartStyle.ZebraLight:
            return {
                positiveColor: "#7aca00",
                negativeColor: "#ff0000",
                neutralColor: "#DEDDC8",
                markerColor: "#404040",
                lineColor: "#404040",
                axisColor: BLACK,
                gridlineColor: "#ccc",
                majorGridlineColor: "#999",
                dotChartColor: "#4080FF",
                useCustomScenarioColors: false,
                highlightColor: HIGHLIGHT_COLOR,
            };
        case ChartStyle.DrHichert:
            return {
                positiveColor: "#8CB400",
                negativeColor: "#FF0000",
                neutralColor: "#404040",
                markerColor: BLACK,
                lineColor: "#404040",
                axisColor: BLACK,
                gridlineColor: "#b0b0b0",
                majorGridlineColor: "#999",
                dotChartColor: "#4080FF",
                useCustomScenarioColors: false,
                highlightColor: HIGHLIGHT_COLOR,
            };
        case ChartStyle.PowerBI:
            return {
                positiveColor: "#01B8AA",
                negativeColor: "#FD625E",
                neutralColor: "#374649",
                markerColor: BLACK,
                lineColor: "#374649",
                axisColor: BLACK,
                gridlineColor: "#ccc",
                majorGridlineColor: "#999",
                dotChartColor: "#4080FF",
                useCustomScenarioColors: false,
                highlightColor: HIGHLIGHT_COLOR,
            };
        case ChartStyle.ColorblindSafe:
            return {
                positiveColor: "#6D97FF",
                negativeColor: "#000000",
                neutralColor: "#CCCCCC",
                markerColor: "#404040",
                lineColor: "#404040",
                axisColor: BLACK,
                gridlineColor: "#ccc",
                majorGridlineColor: "#999",
                dotChartColor: "#4080FF",
                useCustomScenarioColors: false,
                highlightColor: HIGHLIGHT_COLOR,
            };
        case ChartStyle.Company:
            return {
                positiveColor: CUSTOMER_POSITIVE_COLOR,
                negativeColor: CUSTOMER_NEGATIVE_COLOR,
                neutralColor: CUSTOMER_NEUTRAL_COLOR,
                markerColor: CUSTOMER_MARKER_COLOR,
                lineColor: CUSTOMER_LINE_COLOR,
                axisColor: CUSTOMER_AXIS_COLOR,
                gridlineColor: CUSTOMER_GRIDLINE_COLOR,
                majorGridlineColor: "#999",
                dotChartColor: CUSTOMER_DOT_CHART_COLOR,
                useCustomScenarioColors: CUSTOMER_MORE_COLORS.toString() === "true",
                previousYearColor: CUSTOMER_PY_COLOR,
                planColor: CUSTOMER_PL_COLOR,
                plan2Color: CUSTOMER_PL2_COLOR,
                plan3Color: CUSTOMER_PL3_COLOR,
                forecastColor: CUSTOMER_FC_COLOR,
                applyPatterns: CUSTOMER_APPLY_PATTERNS.toString() !== "false",
                highlightColor: HIGHLIGHT_COLOR,
            };
        default:
            return {
                positiveColor: "#7aca00",
                negativeColor: "#ff0000",
                neutralColor: "#404040",
                markerColor: BLACK,
                lineColor: "#404040",
                axisColor: BLACK,
                gridlineColor: "#ccc",
                majorGridlineColor: "#999",
                dotChartColor: "#4080FF",
                useCustomScenarioColors: false,
                highlightColor: HIGHLIGHT_COLOR,
            };
    }
}

export function getLighterColor(hex: string): string {
    let color = getColorFromHex(hex);
    let brightness = getBrightness(color);
    let factor = 0.1 + Math.pow((255 - brightness) / 255, 2) * 0.4;
    let lighterColor: Color = {
        r: Math.round(Math.min(255, color.r + 255 * factor)),
        g: Math.round(Math.min(255, color.g + 255 * factor)),
        b: Math.round(Math.min(255, color.b + 255 * factor)),
    };
    return colorToHex(lighterColor);
}

export function getLabelColor(hex: string): string {
    let color = getColorFromHex(hex);
    let brightness = getBrightness(color);
    return brightness < 129 ? WHITE : BLACK;
}

function getColorFromHex(hex: string): Color {
    let result = [];
    if (!hex) {
        hex = BLACK;
    }

    if (hex.length === 4 || hex.length === 5) {
        result = /^#?([a-f\d]{1})([a-f\d]{1})([a-f\d]{1})?$/i.exec(hex);
    }
    else {
        result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})*$/i.exec(hex);
    }

    if (!result) {
        return { r: 0, g: 0, b: 0 };
    }
    return {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
    };
}

function getBrightness(color: Color): number {
    return Math.sqrt(color.r * color.r * 0.241 + color.g * color.g * 0.691 + color.b * color.b * 0.068);
}

function colorToHex(color: Color): string {
    return "#" + componentToHex(color.r) + componentToHex(color.g) + componentToHex(color.b);
}

function componentToHex(c) {
    let hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
}

export class Color {
    r: number;
    g: number;
    b: number;
}

export function getVarianceColor(invert: boolean, value: number, scheme: ColorScheme): string {
    if (value < 0) {
        if (invert) {
            return scheme.positiveColor;
        }
        return scheme.negativeColor;
    }
    else {
        if (invert) {
            return scheme.negativeColor;
        }
        return scheme.positiveColor;
    }
}

export function getRelativeVarianceColor(invertSetting: boolean, invertDataPoint: boolean, invertGroup: boolean, invertColumn: boolean, value: number, scheme: ColorScheme): string {
    if (value < 0) {
        // Using !== as XOR, as described here:
        // https://github.com/Microsoft/TypeScript/issues/587
        if (invertSetting !== invertDataPoint !== invertGroup !== invertColumn) {
            return scheme.positiveColor;
        }
        return scheme.negativeColor;
    }
    else {
        if (invertSetting !== invertDataPoint !== invertGroup !== invertColumn) {
            return scheme.negativeColor;
        }
        return scheme.positiveColor;
    }
}

export function applyToBars(element: d3.Selection<any, any, any, any>, colorGetter: (d: any) => string, scenario: Scenario, style: ChartStyle, isVariance: boolean, colorScheme: ColorScheme) {
    applyTo(element, colorGetter, scenario, style, isVariance, colorScheme, 1, false);
}

export function applyToLines(element: d3.Selection<any, any, any, any>, colorGetter: (d: any) => string, scenario: Scenario, style: ChartStyle, isVariance: boolean, colorScheme: ColorScheme, isReferenceScenario: boolean) {
    let strokeWidth = 0;
    if (isPlanScenario(scenario)) {
        strokeWidth = isReferenceScenario ? 1 : 2;
    }
    else if (isForecastScenario(scenario)) {
        strokeWidth = 1;
    }
    applyTo(element, colorGetter, scenario, style, isVariance, colorScheme, strokeWidth, true);
}

function applyTo(element: d3.Selection<any, any, any, any>, colorGetter: (d: any) => string, scenario: Scenario, style: ChartStyle, isVariance: boolean, colorScheme: ColorScheme, strokeWidth: number, useTargetFill: boolean) {
    element.attr(STROKE_WIDTH, 0);
    if (scenario === Scenario.Actual) {
        element.attr(FILL, d => colorGetter(d));
    }
    else if (isPlanScenario(scenario)) {
        if (colorScheme.useCustomScenarioColors && !isVariance) {
            let plColor = (d: any) => {
                if (d.isHighlighted) {
                    return colorGetter(d);
                }
                else if (scenario === Scenario.Plan) {
                    return colorScheme.planColor;
                }
                else if (scenario === Scenario.Plan2) {
                    return colorScheme.plan2Color;
                }
                return colorScheme.plan3Color;
            }
            element.attr(FILL, d => plColor(d));
            element.attr(FILL_OPACITY, 1);
            if (colorScheme.applyPatterns) {
                element.attr(FILL_OPACITY, 0)
                    .attr(STROKE_WIDTH, 1)
                    .attr(STROKE, d => plColor(d));
                if (useTargetFill) {
                    element.attr(FILL, WHITE);
                }
                else {
                    element.attr(FILL_OPACITY, 0.1);
                }
            }
        }
        else {
            element
                .attr(FILL, d => colorGetter(d))
                .attr(STROKE_DASHARRAY, () => {
                    if (scenario === Scenario.Plan2) {
                        return PLAN_2_DASHARRAY;
                    }
                    else if (scenario === Scenario.Plan3) {
                        return PLAN_3_DASHARRAY;
                    }
                })
                .attr(STROKE_WIDTH, strokeWidth)
                .attr(STROKE, d => colorGetter(d));
            if (useTargetFill) {
                element.attr(FILL, WHITE);
            }
            else {
                element.attr(FILL_OPACITY, style === ChartStyle.DrHichert ? 0 : 0.1);
            }
        }
    }
    else if (scenario === Scenario.PreviousYear) {
        element.attr(FILL, d => {
            if (colorScheme.useCustomScenarioColors && !isVariance) {
                if (d.isHighlighted) {
                    return colorScheme.applyPatterns ? getLighterColor(colorGetter(d)) : colorGetter(d);
                }
                return colorScheme.applyPatterns ? getLighterColor(colorScheme.previousYearColor) : colorScheme.previousYearColor;
            }
            else {
                // Variances should not be lightened
                if (isVariance) {
                    return colorGetter(d);
                }
                else {
                    return getLighterColor(colorGetter(d));
                }
            }
        });
    }
    else if (isForecastScenario(scenario)) {
        let fcColor = (d: any) => colorScheme.useCustomScenarioColors && !isVariance && !d.isHighlighted ? colorScheme.forecastColor : colorGetter(d);
        element.attr(FILL, d => {
            if (colorScheme.useCustomScenarioColors && !colorScheme.applyPatterns) {
                return fcColor(d);
            }
            else {
                return `url(#diagonal-stripe-${getPatternFromColor(fcColor(d), colorScheme)}${getFCNumberFromScenario(scenario)})`;
            }
        });
        if (strokeWidth !== 0) {
            element
                .attr(STROKE_WIDTH, strokeWidth)
                .attr(STROKE, d => fcColor(d));
        }
    }
}

export function applyToElement(element: d3.Selection<SVGElement, any, any, any>, color: string, scenario: Scenario, style: ChartStyle, colorScheme: ColorScheme) {
    let isVariance = color === colorScheme.positiveColor || color === colorScheme.negativeColor;
    if (scenario === Scenario.Actual) {
        element.attr(FILL, color);
    }
    else if (isPlanScenario(scenario)) {
        let planDashArray = "";
        let planColor = colorScheme.planColor;
        if (scenario === Scenario.Plan2) {
            planColor = colorScheme.plan2Color;
            planDashArray = PLAN_2_DASHARRAY;
        }
        else if (scenario === Scenario.Plan3) {
            planColor = colorScheme.plan3Color;
            planDashArray = PLAN_3_DASHARRAY;
        }

        if (colorScheme.useCustomScenarioColors && !isVariance) {
            element.attr(FILL, planColor);
            if (colorScheme.applyPatterns) {
                element
                    .attr(FILL_OPACITY, 0)
                    .attr(STROKE_WIDTH, 1)
                    .attr(STROKE, planColor);
            }
        }
        else {
            element
                .attr(FILL, color)
                .attr(FILL_OPACITY, style === ChartStyle.DrHichert ? 0 : 0.1)
                .attr(FILL_OPACITY, 0)
                .attr(STROKE_WIDTH, 1)
                .attr(STROKE, color)
                .attr(STROKE_DASHARRAY, planDashArray);
        }
    }
    else if (scenario === Scenario.PreviousYear) {
        if (colorScheme.useCustomScenarioColors && !isVariance) {
            element.attr(FILL, colorScheme.applyPatterns ? getLighterColor(colorScheme.previousYearColor) : colorScheme.previousYearColor);
        }
        else {
            // Variances should not be lightened
            if (isVariance) {
                element.attr(FILL, color);
            }
            else {
                element.attr(FILL, getLighterColor(color));
            }
        }
    }
    else if (isForecastScenario(scenario)) {
        let fcColor = colorScheme.useCustomScenarioColors && !isVariance ? colorScheme.forecastColor : color;
        if (colorScheme.useCustomScenarioColors && !colorScheme.applyPatterns) {
            element.attr(FILL, fcColor);
        }
        else {
            element.attr(FILL, `url(#diagonal-stripe-${getPatternFromColor(fcColor, colorScheme)}${getFCNumberFromScenario(scenario)})`);
        }
    }
}

export function applyMarkerStyleToElement(element: d3.Selection<SVGElement, any, any, any>, color: string, markerStyle: MarkerStyle, style: ChartStyle, colorScheme: ColorScheme) {
    if (markerStyle === MarkerStyle.Actual || markerStyle === MarkerStyle.Plan || markerStyle === MarkerStyle.Plan2 || markerStyle === MarkerStyle.Plan3 ||
        markerStyle === MarkerStyle.PreviousYear || markerStyle === MarkerStyle.Forecast || markerStyle === MarkerStyle.Forecast2 || markerStyle === MarkerStyle.Forecast3) {
        applyToElement(element, color, getScenarioFromMarkerStyle(markerStyle), style, colorScheme);
    }
    else if (markerStyle === MarkerStyle.Gridline) {
        element
            .attr(FILL, color);
    }
    else if (markerStyle === MarkerStyle.None) {
        element
            .attr(FILL, NONE);
    }
}

function getPatternFromColor(color: string, colorScheme: ColorScheme): string {
    if (color === colorScheme.neutralColor) {
        return NEUTRAL;
    }
    else if (color === colorScheme.positiveColor) {
        return POSITIVE;
    }
    else if (color === colorScheme.negativeColor) {
        return NEGATIVE;
    }
    else if (color === colorScheme.markerColor) {
        return MARKER;
    }
    else if (color === colorScheme.forecastColor) {
        return FC;
    }
    else if (color === colorScheme.planColor) {
        return PL;
    }
    else if (color === colorScheme.plan2Color) {
        return PL2;
    }
    else if (color === colorScheme.plan3Color) {
        return PL3;
    }
    else if (color === colorScheme.previousYearColor) {
        return PY;
    }
    else if (color === colorScheme.highlightColor) {
        return HIGHLIGHT_COLOR;
    }
    else {
        return color;
    }
}
