
import { DataView, DataViewMetadataColumn } from "@zebrabi/matrix-data";

import * as translations from "@zebrabi/legacy-library-common/translations";
import { EMPTY } from "./constants";

export function getCategoriesAndGroups(dv: DataView[], locale: string): string {
    let categories = getCategoriesFields(dv);
    let groups = getGroupsFields(dv);
    let byTranslation = translations.getTranslatedByString(locale);
    if (groups !== EMPTY) {
        groups = ` ${byTranslation} ${groups}`;
    }
    if (categories !== EMPTY) {
        categories = getCategoriesPrefix(groups, categories, byTranslation) + categories;
    }
    return `${groups}${categories}`;
}

export function getCategoriesFields(dv: DataView[]): string {
    let categoryColumns = dv[0].metadata.columns.filter(c => c.roles && c.roles["Category"] === true);
    if (categoryColumns && categoryColumns.length > 0) {
        return getFields(categoryColumns);
    }
    // else if (dv[0].matrix && dv[0].matrix.rows && dv[0].matrix.rows.root) {
    //     return getFieldsFromISQExpr(dv[0].matrix.rows.root.childIdentityFields);
    // }
    else {
        return EMPTY;
    }
}

export function getGroupsFields(dv: DataView[]): string {
    let groupColumns = dv[0].metadata.columns.filter(c => c.roles && c.roles["Group"] === true);
    if (groupColumns && groupColumns.length > 0) {
        return getFields(groupColumns);
    }
    // else if (dv[0].matrix && dv[0].matrix.columns && dv[0].matrix.columns.root) {
    //     return getFieldsFromISQExpr(dv[0].matrix.columns.root.childIdentityFields);
    // }
    else {
        return EMPTY;
    }
}

function getFields(columns: DataViewMetadataColumn[]): string {
    let result = EMPTY;
    if (columns != null) {
        result = columns.map(c => c.displayName).join(", ");
    }
    return result;
}

// function getFieldsFromISQExpr(childIdentityFields: data.ISQExpr[]): string {
//     let result = "";
//     if (childIdentityFields) {
//         result = childIdentityFields.map(d => (<any>d).ref).join(", ");
//     }
//     return result;
// }

function getCategoriesPrefix(groups: string, categories: string, byTranslation: string): string {
    return groups !== null && groups !== EMPTY && categories.indexOf(",") === -1 ? ", " : ` ${byTranslation} `;
}

// export function isDateTimeDataView(dvValueColumnGroup: DataViewValueColumn): boolean {
//     return dvValueColumnGroup.source.type.dateTime;
// }

// export function getFormatDataView(dvValueColumnGroup: DataViewValueColumn): string {
//     return dvValueColumnGroup.source.format;
// }

// export function isPercentageDataDataView(dvValueColumnGroup: DataViewValueColumnGroup, valuesIndex: number = 0): boolean {
//     let source = dvValueColumnGroup && dvValueColumnGroup.values && dvValueColumnGroup.values[valuesIndex] ? dvValueColumnGroup.values[valuesIndex].source : null;
//     return source && source.format && source.format.indexOf("%") !== -1;
// }

export function getValue<T>(objects: any, objectName: string, propertyName: string, defaultValue: T): T {
    if (objects) {
        let object = objects[objectName];
        if (object) {
            let property: T = <T>object[propertyName];
            if (property !== undefined) {
                return property;
            }
        }
    }
    return defaultValue;
}
