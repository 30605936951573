
import { Plotter } from "../plotters/plotter";
import { ViewModel } from "../../settings/viewModel";

import { TextAlign, GRIDLINE_OFFSET, ChartType, ACT_ABS_REL, AbsoluteChart, ValueChart, ICON_TOOLTIP_Y_OFFSET } from "../../definitions";
import { DataProperty, Scenario, CLICK, CONTEXT_MENU_DIV, DISPLAY, BLOCK, NONE, MOUSEOUT, MOUSEOVER, OPACITY, FONT_WEIGHT, BOLD, NORMAL, CATEGORY, FILTER, TEXT, RECT, HIGHLIGHTABLE, CIRCLE, PATH, LABEL, RECTANGLE, HEADER_ROW, LEFT, RIGHT, GRIDLINE, FLAT_RESULTS, ShowTotals, GRAND_TOTAL, LINE, WHITE, MOUSELEAVE, HEADER_TOOLTIP, ShowAsTableOptions, X, Y, STROKE_WIDTH, CX, CY, NumberOfAdditionalMeasures, DATA_PROPERTY, X1, Y1, X2, Y2, STROKE, TRANSFORM, WIDTH, HEIGHT, FILL, R, ColumnFormat, COMMENT_MARKER, FONT_SIZE, PX, FONT_FAMILY, TEXT_ANCHOR, MIDDLE, FILL_OPACITY, GRIDLINE_TEST_CLASS, G, DisplayUnits, FONT_STYLE, D, THIN_SPACE, ARROW_DOWN, DRAG, DRAGSTART, DRAGEND, STROKE_DASHARRAY, STROKE_OPACITY, RECT_BACKGROUND, CHART_AREA, CommentBoxPlacement, CURSOR, GRAB, GRABBING, REPORT_AREA, COMMENT_DRAG_AREA_BLUE, COMMENT_MARKER_ACTIVE, COMMENT_DRAG_AREA_STROKE_DASHARRAY, TRANSPARENT, COMMENT_DRAG_AREA, CHART_GROUP_INDEX, FONT_SIZE_UNIT, CHART_ID, ICON } from "../../library/constants";
import { Header } from "../header";
import { NullHeader } from "../nullHeader";
import { Visual } from "../../visual";
import { DataPoint } from "../dataPoint";
import * as d3 from "d3";
import { ChartHierarchy } from "../chartHierarchy";
import { VarianceSettings } from "../../settings/varianceSettings";
import { ChartGroup } from "../chartGroup";
import { Extreme } from "../extreme";
import { Extremes } from "../extremes";

import * as drawing from "./../../library/drawing";
import * as styles from "./../../library/styles";
import * as ui from "./../../library/ui/showTooltip";
import * as scenarios from "./../../settings/scenarios";
import * as formatting from "../../library/formatting";
import { isAdditionalMeasure, isValueMeasure } from "../../library/helpers";
import { isAbsoluteDifferenceMeasure, isForecastScenario, isPlanScenario } from "../../helpers";
import { CommentMarker } from "@zebrabi/legacy-library-common/interfaces";
import { applyExcelSLicers } from "@zebrabi/data-helpers/slicers";

export function plotSortableHeader(chartHierarchy: ChartHierarchy, plotter: Plotter, viewModel: ViewModel, totalWidth: number, headerText: string, currentRow: number, referenceId: number, xPosition: number, yPosition: number,
    textAlign: TextAlign, bold: boolean, dataProperty: DataProperty, plotOrder: number, shouldShorten: boolean, width: number, textXPosition: number, isGroupHeader: boolean,
    useDragAndDrop: boolean, allowSort: boolean, showDropDownArrow: boolean, onlyShowChartChangeIcons: boolean, groupIndex: number, isIntegratedSecondHeader: boolean, chartGroup: ChartGroup, isCategoriesHeader: boolean, scenario: Scenario = null, isRowLevelSort: boolean = false, columnOrder?: number): Header {

    if (currentRow !== 0 || chartHierarchy !== null && chartHierarchy.plottedHeader || headerText == null) {
        return new NullHeader(plotter, Visual.headers, viewModel, totalWidth, dataProperty, plotOrder, referenceId, xPosition, yPosition,
            headerText, textAlign, bold, shouldShorten, width, textXPosition, isGroupHeader, useDragAndDrop, allowSort, showDropDownArrow, onlyShowChartChangeIcons, groupIndex, isIntegratedSecondHeader, chartGroup, scenario, isCategoriesHeader, columnOrder);
    }
    let header = new Header(plotter, isRowLevelSort ? Visual.categoriesHeaders : Visual.headers, viewModel, totalWidth, dataProperty, plotOrder, referenceId, xPosition, yPosition,
        headerText, textAlign, bold, shouldShorten, width, textXPosition, isGroupHeader, useDragAndDrop, allowSort, showDropDownArrow, onlyShowChartChangeIcons, groupIndex, isIntegratedSecondHeader, chartGroup, scenario, isCategoriesHeader, columnOrder);
    header.addHeader();
    return header;
}

export function addMouseHandlers(elements: d3.Selection<any, DataPoint, SVGElement, DataPoint>, selectionManager: any, container: d3.Selection<SVGElement, any, HTMLElement, any>, viewModel: ViewModel) {
    // This must be an anonymous function instead of a lambda because
    // d3 uses 'this' as the reference to the element that was clicked.
    elements.on(CLICK, function (d) {
        const el = d3.select(this);
        //console.log(Visual.getInstance().dataView)
        if (viewModel.settings.enableFiltering) {
            let multiSelect = (<PointerEvent>d3.event)?.ctrlKey || false;

            let contextMenuDisplay = d3.select("." + CONTEXT_MENU_DIV).style(DISPLAY);
            if (contextMenuDisplay === BLOCK) {
                d3.select("." + CONTEXT_MENU_DIV).style(DISPLAY, NONE);
                return;
            }

            if ((<any>window).highlightTimeout != null) {
                window.clearTimeout((<any>window).highlightTimeout);
            }
            el.on(MOUSEOUT, null);

            applyExcelSLicers(d.category, Visual.getInstance().dataView?.rowFields[0], multiSelect)
                .then(result => {
                    Visual.selectedCategories = result;
                    syncStateOffice(viewModel);
                });

            // if (d.isGrandTotal()) {
            //     if (Visual.isFilterApplied()) {
            //         applyExcelSLicers("", Visual.getInstance().dataView?.rowFields[0], false)
            //             .then((result) => {
            //                 Visual.selectedCategories = result;
            //                 syncStateOffice(viewModel);
            //             });
            //     }
            //     return;
            // }


            // let selection: DataPoint[] = d.isOther() || d.isOtherTotal() ? d.associatedDataPoints : [d];
            // let selectionIds = Visual.selectedCategories; //ISelectionId[] = [];
            // if (shouldSelectWholeRow(viewModel, el)) {
            //     if (d.isTotal() && viewModel.settings.plottingHorizontally()) {
            //         selection = selection.concat(getSiblings(selection, viewModel, false));
            //         selectionIds = selection.map(d => d.fullCategory);
            //     }
            //     else {
            //         if (d.isOther() || d.isOtherTotal()) {
            //             let siblings = getSiblings([d], viewModel, false);
            //             let arrayOfOtherDPs = siblings.map(dp => dp.associatedDataPoints);
            //             selection = selection.concat([].concat(...arrayOfOtherDPs));
            //         }
            //         else {
            //             selection = selection.concat(getSiblings(selection, viewModel, false));
            //         }
            //         selectionIds = selection.map(d => d.fullCategory);
            //     }
            // }
            // else {
            //     selectionIds = selection.map(d => d.fullCategory);
            // }

            //let selectionIDsBefore = selectionManager.getSelectionIds();
            // if ((d.isOther()) && !d.isSelected && !selectionIDsBefore.length || (d.isOther() || d.isOtherTotal()) && d.isSelected && !selectionIDsBefore.some(id => selectionIds.indexOf(id) < 0)) {
            //     multiSelect = true;
            // }

            // if (deselectingSelectedRow(selectionIds, selectionManager, viewModel, el, multiSelect)) {
            //     selectionManager.clear();
            //     syncStateOffice(viewModel);
            //     return;
            // }

            // selectionManager.select(selectionIds, multiSelect).then((ids: ISelectionId[]) => {
            //     syncStateOffice(viewModel);
            // });

        }

        (<Event>d3.event).stopPropagation();
    });

    container.on(CLICK, (el) => {
        if (Visual.isFilterApplied()) {
            applyExcelSLicers("", Visual.getInstance().dataView?.rowFields[0], false)
                .then((result) => {
                    Visual.selectedCategories = result;
                    syncStateOffice(viewModel);
                });
        }
    });

    elements.on(MOUSEOVER, function (d) {
        let categoryShapes = highlightableCategories();
        let currentCategoryShape = categoryShapes.nodes().find(shape => shape === this);
        if (currentCategoryShape) {
            d3.selectAll("." + HEADER_TOOLTIP).remove();
            if (currentCategoryShape.textContent !== currentCategoryShape.__data__.category) {
                let position = drawing.getBoundingBoxHtml(this);
                ui.showTooltip(Visual.visualDiv, {
                    x: position.left + 16,
                    y: position.top + -20,
                }, currentCategoryShape.__data__.category, 250, 1000)
            }
        }
        if (Visual.isFilterApplied()) {
            return;
        }

        if ((<any>window).highlightTimeout != null) {
            clearTimeout((<any>window).highlightTimeout);
        }
        let shapes = highlightableShapes();
        shapes.style("cursor", Visual.visualSettings.enableFiltering ? "pointer" : "default");

        let parent = (<ChartHierarchy>d.parent);
        let el = d3.select(this);
        let selectedDataPoints = d.isTotal() && parent ? parent.getChildren(true).concat(d) : [d];
        if (shouldSelectWholeRow(viewModel, el)) {
            selectedDataPoints = selectedDataPoints.concat(getSiblings(selectedDataPoints, viewModel, false));
        }

        shapes.nodes().forEach(shape => {
            if (selectedDataPoints.indexOf(shape.__data__) > -1) {
                d3.select(shape).attr(OPACITY, 1);
            }
            else {
                d3.select(shape).attr(OPACITY, 0.6);
            }
        });
        let labelShapes = highlightableLabels();
        labelShapes.nodes().forEach(textShape => {
            if (selectedDataPoints.indexOf(textShape.__data__) > -1) {
                d3.select(textShape)
                    .style(FONT_WEIGHT, BOLD)
                    .attr(OPACITY, 1);
            }
            else {
                d3.select(textShape)
                    .style(FONT_WEIGHT, NORMAL)
                    .attr(OPACITY, 0.6);
            }
        });
        let selectedCategories = selectedDataPoints.map(se => se.fullCategory);
        categoryShapes.nodes().forEach(categoryShape => {
            if (selectedDataPoints.indexOf(categoryShape.__data__) > -1 ||
                (viewModel.settings.plottingHorizontally() && selectedCategories.indexOf(categoryShape.__data__.fullCategory) > -1) && categoryShape.classList.contains(CATEGORY)) {
                d3.select(categoryShape)
                    .style(FONT_WEIGHT, BOLD)
                    .attr(OPACITY, 1);
            }
            else {
                d3.select(categoryShape)
                    .style(FONT_WEIGHT, NORMAL)
                    .attr(OPACITY, 0.6);
            }
        });
        highlightHeaders(viewModel, selectedDataPoints);
        if (d.shouldBlur()) {
            let position = drawing.getBoundingBoxHtml(this);
            let leftOffset = (position.left + 150 > Visual.width) ? -100 : 0;
            ui.showTooltip(Visual.visualDiv, { x: Math.max(position.left + leftOffset, 0), y: position.top - 20 }, "Unlimited number of levels available in Pro version", 100, 3000);
        }

        el.on(MOUSEOUT, (d) => {
            el.on(MOUSEOUT, null);
            (<any>window).highlightTimeout = setTimeout(() => {
                if (!Visual.isFilterApplied()) {
                    highlightableShapes()
                        .attr(OPACITY, d => d.shouldBlur() ? 0.4 : 1);
                    highlightableLabelRectangles()
                        .attr(OPACITY, 1 - (viewModel.settings.labelBackgroundTransparency / 100));
                    highlightableLabels()
                        .style(FONT_WEIGHT, d => d.getFontWeight())
                        .attr(OPACITY, d => d.shouldBlur() ? 0.4 : 1);
                    highlightableLabels().nodes().forEach(label => {
                        // let dataProperty = <DataProperty>parseInt(d3.select((<HTMLElement>label).parentNode).attr(DATA_PROPERTY));
                        // let isBold = viewModel.settings.isHeaderBold(dataProperty);
                        // if (isBold) {
                        //     d3.select(label).style(FONT_WEIGHT, BOLD);
                        // }
                    });
                    highlightableCategories()
                        .style(FONT_WEIGHT, d => d.getFontWeight())
                        .attr(OPACITY, d => d.shouldBlur() ? 0.4 : 1);
                    if (viewModel.settings.plottingHorizontally()) {
                        highlightableHeaders()
                            .attr(OPACITY, d => d.shouldBlur() ? 0.4 : 1);
                    }
                }
            }, 400);
        });
    });

}

// tslint:disable-next-line: max-func-body-length
// export function addMouseHandlers(elements: d3.Selection<any, DataPoint, SVGElement, DataPoint>, selectionManager: ISelectionManager, container: d3.Selection<SVGElement, any, HTMLElement, any>, viewModel: ViewModel) {
//     // Allow selection only if the visual is rendered in a view that supports interactivity (e.g. Report)
//     if (!viewModel.host.allowInteractions) {
//         return;
//     }
//     (<any>window).highlightTimeout = null;
//     // This must be an anonymous function instead of a lambda because
//     // d3 uses 'this' as the reference to the element that was clicked.
//     elements.on(CLICK, function (d) {
//         if (d.isGrandTotal()) {
//             selectionManager.clear();
//             syncState(selectionManager, viewModel);
//             return;
//         }
//         let contextMenuDisplay = d3.select("." + CONTEXT_MENU_DIV).style(DISPLAY);
//         if (contextMenuDisplay === BLOCK) {
//             d3.select("." + CONTEXT_MENU_DIV).style(DISPLAY, NONE);
//             return;
//         }

//         let multiSelect = (<PointerEvent>d3.event).ctrlKey;
//         if ((<any>window).highlightTimeout != null) {
//             window.clearTimeout((<any>window).highlightTimeout);
//         }
//         let el = d3.select(this);
//         el.on(MOUSEOUT, null);
//         let selection: DataPoint[] = d.isOther() || d.isOtherTotal() ? d.associatedDataPoints : [d];
//         let selectionIds: ISelectionId[] = [];
//         if (shouldSelectWholeRow(viewModel, el)) {
//             if (d.isTotal() && viewModel.settings.plottingHorizontally()) {
//                 selection = selection.concat(getSiblings(selection, viewModel, false));
//                 selectionIds = selection.map(d => d.selectionId);
//             }
//             else {
//                 if (d.isOther() || d.isOtherTotal()) {
//                     let siblings = getSiblings([d], viewModel, false);
//                     let arrayOfOtherDPs = siblings.map(dp => dp.associatedDataPoints);
//                     selection = selection.concat([].concat(...arrayOfOtherDPs));
//                 }
//                 else {
//                     selection = selection.concat(getSiblings(selection, viewModel, false));
//                 }
//                 selectionIds = selection.map(d => d.selectionId);
//             }
//         }
//         else {
//             selectionIds = selection.map(d => d.selectionId);
//         }
//         let selectionIDsBefore = selectionManager.getSelectionIds();
//         if ((d.isOther()) && !d.isSelected && !selectionIDsBefore.length || (d.isOther() || d.isOtherTotal()) && d.isSelected && !selectionIDsBefore.some(id => selectionIds.indexOf(id) < 0)) {
//             multiSelect = true;
//         }
//         if (deselectingSelectedRow(selectionIds, selectionManager, viewModel, el, multiSelect)) {
//             selectionManager.clear();
//             syncState(selectionManager, viewModel);
//             return;
//         }

//         selectionManager.select(selectionIds, multiSelect).then((ids: ISelectionId[]) => {
//             syncState(selectionManager, viewModel);
//         });
//         (<Event>d3.event).stopPropagation();
//     });
//     container.on(CLICK, (el) => {
//         if (selectionManager.hasSelection()) {
//             selectionManager.clear();
//             syncState(selectionManager, viewModel);
//         }
//     });
//     elements.on(MOUSEOVER, function (d) {
//         let categoryShapes = highlightableCategories();
//         let currentCategoryShape = categoryShapes.nodes().find(shape => shape === this);
//         if (currentCategoryShape) {
//             d3.selectAll("." + HEADER_TOOLTIP).remove();
//             if (currentCategoryShape.textContent !== currentCategoryShape.__data__.category) {
//                 let position = drawing.getBoundingBoxHtml(this);
//                 ui.showTooltip(Visual.visualDiv, {
//                     x: position.left + 16,
//                     y: position.top + -20,
//                 }, currentCategoryShape.__data__.category, 250, 1000)
//             }
//         }
//         if (selectionManager.hasSelection()) {
//             return;
//         }
//         if ((<any>window).highlightTimeout != null) {
//             clearTimeout((<any>window).highlightTimeout);
//         }
//         let shapes = highlightableShapes();
//         let parent = (<ChartHierarchy>d.parent);
//         let el = d3.select(this);
//         let selectedDataPoints = d.isTotal() && parent ? parent.getChildren(true).concat(d) : [d];
//         if (shouldSelectWholeRow(viewModel, el)) {
//             selectedDataPoints = selectedDataPoints.concat(getSiblings(selectedDataPoints, viewModel, false));
//         }

//         shapes.nodes().forEach(shape => {
//             if (selectedDataPoints.indexOf(shape.__data__) > -1) {
//                 d3.select(shape).attr(OPACITY, 1);
//             }
//             else {
//                 d3.select(shape).attr(OPACITY, 0.6);
//             }
//         });
//         let labelShapes = highlightableLabels();
//         labelShapes.nodes().forEach(textShape => {
//             if (selectedDataPoints.indexOf(textShape.__data__) > -1) {
//                 d3.select(textShape)
//                     .style(FONT_WEIGHT, BOLD)
//                     .attr(OPACITY, 1);
//             }
//             else {
//                 d3.select(textShape)
//                     .style(FONT_WEIGHT, NORMAL)
//                     .attr(OPACITY, 0.6);
//             }
//         });
//         let selectedCategories = selectedDataPoints.map(se => se.fullCategory);
//         categoryShapes.nodes().forEach(categoryShape => {
//             if (selectedDataPoints.indexOf(categoryShape.__data__) > -1 ||
//                 (viewModel.settings.plottingHorizontally() && selectedCategories.indexOf(categoryShape.__data__.fullCategory) > -1) && categoryShape.classList.contains(CATEGORY)) {
//                 d3.select(categoryShape)
//                     .style(FONT_WEIGHT, BOLD)
//                     .attr(OPACITY, 1);
//             }
//             else {
//                 d3.select(categoryShape)
//                     .style(FONT_WEIGHT, NORMAL)
//                     .attr(OPACITY, 0.6);
//             }
//         });
//         highlightHeaders(viewModel, selectedDataPoints);
//         if (d.shouldBlur()) {
//             let position = drawing.getBoundingBoxHtml(this);
//             let leftOffset = (position.left + 150 > Visual.width) ? -100 : 0;
//             ui.showTooltip(Visual.visualDiv, { x: Math.max(position.left + leftOffset, 0), y: position.top - 20 }, "Unlimited number of levels available in Pro version", 100, 3000);
//         }

//         el.on(MOUSEOUT, (d) => {
//             el.on(MOUSEOUT, null);
//             (<any>window).highlightTimeout = setTimeout(() => {
//                 if (!selectionManager.hasSelection()) {
//                     highlightableShapes()
//                         .attr(OPACITY, d => d.shouldBlur() ? 0.4 : 1);
//                     highlightableLabelRectangles()
//                         .attr(OPACITY, 1 - (viewModel.settings.labelBackgroundTransparency / 100));
//                     highlightableLabels()
//                         .style(FONT_WEIGHT, d => d.getFontWeight())
//                         .attr(OPACITY, d => d.shouldBlur() ? 0.4 : 1);
//                     highlightableLabels().nodes().forEach(label => {
//                         let dataProperty = <DataProperty>parseInt(d3.select((<HTMLElement>label).parentNode).attr(DATA_PROPERTY));
//                         let isBold = viewModel.settings.isHeaderBold(dataProperty);
//                         if (isBold) {
//                             d3.select(label).style(FONT_WEIGHT, BOLD);
//                         }
//                     });
//                     highlightableCategories()
//                         .style(FONT_WEIGHT, d => d.getFontWeight())
//                         .attr(OPACITY, d => d.shouldBlur() ? 0.4 : 1);
//                     if (viewModel.settings.plottingHorizontally()) {
//                         highlightableHeaders()
//                             .attr(OPACITY, d => d.shouldBlur() ? 0.4 : 1);
//                     }
//                 }
//             }, 400);
//         });
//     });
// }

export function addBlurIfNeeded(elements: d3.Selection<any, DataPoint, any, any>, settings: VarianceSettings) {
    if (settings.proVersionActive()) {
        return;
    }
    elements
        .style(FILTER, (d: DataPoint) => {
            return d.shouldBlur() ? "url(#blur)" : null;
        })
        .attr(OPACITY, (d: DataPoint) => {
            return d.shouldBlur() ? "0.4" : null;
        });
}

function shouldSelectWholeRow(viewModel: ViewModel, el: d3.Selection<any, any, any, any>): boolean {
    return (el.node().nodeName === TEXT && el.classed(CATEGORY)) && viewModel.settings.plottingHorizontally();
}

function getSiblings(datapoints: DataPoint[], viewModel: ViewModel, includeTotals: boolean): DataPoint[] {
    let sibilings: DataPoint[] = [];
    let allDataPoints = viewModel.chartGroups.getAllDataPoints();
    let isSiblingFn = includeTotals ? isSibling : isNonTotalSibling;
    datapoints.forEach(d => {
        sibilings = sibilings.concat(allDataPoints.filter(dp => isSiblingFn(d, dp)));
    });
    return sibilings;
}

function isSibling(d: DataPoint, siblingCandidate: DataPoint): boolean {
    return siblingCandidate !== d && siblingCandidate.fullCategory === d.fullCategory;
}

function isNonTotalSibling(d: DataPoint, siblingCandidate: DataPoint): boolean {
    return siblingCandidate !== d &&
        siblingCandidate.fullCategory === d.fullCategory &&
        (<ChartHierarchy>siblingCandidate.parent).firstGroupParent.isSubtotal !== true &&
        (<ChartHierarchy>siblingCandidate.parent).firstGroupParent.isColumnGrandTotal !== true;
}

function getGroupsFromSelectedElements(selectedElements: DataPoint[]): ChartGroup[] {
    return selectedElements.reduce((result, dataPoint) => {
        return result.concat(getGroups(dataPoint));
    }, []);
}

function getGroups(dataPoint: DataPoint): ChartGroup[] {
    let result: ChartGroup[] = [];
    let parent = dataPoint.parent;
    while (parent) {
        if ((<any>parent).group) {
            result.push(<ChartGroup>parent);
        }
        parent = parent.parent;
    }
    return result;
}

// This is a workaround for when we have an entire row selected and we click on it again (without pressing Ctrl).
// Since we selected multiple items in this case (whole row) we should use multiselect option, but this causes
// many of the standard interaction to not work correctly.
// function deselectingSelectedRow(selectionIds: ISelectionId[], selectionManager: ISelectionManager, viewModel: ViewModel, el: d3.Selection<any, any, any, any>, multiSelect: boolean): boolean {
//     return !multiSelect && shouldSelectWholeRow(viewModel, el) && selectingTheSameElementsAsAreSelected(selectionIds, selectionManager);
// }

// function selectingTheSameElementsAsAreSelected(selectionIds: ISelectionId[], selectionManager: ISelectionManager): boolean {
//     let currentlySelected = selectionManager.getSelectionIds();
//     if (currentlySelected.length !== selectionIds.length) {
//         return false;
//     }
//     for (let i = 0; i < selectionIds.length; i++) {
//         if (currentlySelected.indexOf(selectionIds[i]) < 0) {
//             return false;
//         }
//     }
//     return true;
// }

function highlightableShapes(): d3.Selection<any, DataPoint, any, any> {
    return d3.selectAll(`${RECT}.${HIGHLIGHTABLE}, ${CIRCLE}.${HIGHLIGHTABLE}, ${PATH}.${HIGHLIGHTABLE}, ${G}.${HIGHLIGHTABLE}`);
}

function highlightableLabelRectangles(): d3.Selection<any, DataPoint, any, any> {
    return d3.selectAll(`${RECT}.${LABEL}${RECTANGLE}`);
}

function highlightableLabels(): d3.Selection<any, DataPoint, any, any> {
    return d3.selectAll(`${TEXT}.${HIGHLIGHTABLE}.${LABEL}`);
}

function highlightableCategories(): d3.Selection<any, DataPoint, any, any> {
    return d3.selectAll(`${TEXT}.${HIGHLIGHTABLE}.${CATEGORY}`);
}

function highlightableHeaders(): d3.Selection<any, any, any, any> {
    return d3.selectAll(`${TEXT}.${HEADER_ROW}`);
}

// export function syncState(selectionManager: ISelectionManager, viewModel: ViewModel) {
//     let selectionIds = selectionManager.getSelectionIds();
//     let shapes = highlightableShapes();
//     let labels = highlightableLabels();
//     let categories = highlightableCategories();
//     let headers = highlightableHeaders();
//     if (!selectionIds) {
//         return;
//     }
//     if (!selectionIds.length) {
//         shapes.attr(OPACITY, d => d.shouldBlur() ? 0.4 : 1);
//         labels.attr(OPACITY, d => d.shouldBlur() ? 0.4 : 1);
//         labels.style(FONT_WEIGHT, d => d.getFontWeight());
//         labels.nodes().forEach(label => {
//             let dataProperty = <DataProperty>parseInt(d3.select((<HTMLElement>label).parentNode).attr(DATA_PROPERTY));
//             let isBold = viewModel.settings.isHeaderBold(dataProperty);
//             if (isBold) {
//                 d3.select(label).style(FONT_WEIGHT, BOLD);
//             }
//         });
//         categories.attr(OPACITY, d => d.shouldBlur() ? 0.4 : 1);
//         categories.style(FONT_WEIGHT, d => d.getFontWeight());
//         if (viewModel.settings.plottingHorizontally()) {
//             headers.attr(OPACITY, 1);
//         }
//         return;
//     }
//     let allDataPoints = viewModel.chartGroups.getAllDataPoints();
//     allDataPoints.forEach(d => d.isSelected = false);
//     viewModel.chartGroups.markSelectedPoints(selectionIds);
//     shapes.attr(OPACITY, d => d.shouldBlur() ? 0.4 : (d.isSelected ? 1 : 0.3));
//     labels
//         .attr(OPACITY, d => d.shouldBlur() ? 0.4 : (d.isSelected ? 1 : 0.3))
//         .style(FONT_WEIGHT, d => d.isSelected ? BOLD : NORMAL);
//     let selectedDataPoints = allDataPoints.filter(dp => dp.isSelected);
//     let selectedCategories = selectedDataPoints.map(dp => dp.fullCategory);
//     categories.each(function (d: DataPoint) {
//         let isSelected = d.isSelected || (viewModel.settings.plottingHorizontally() && selectedCategories.indexOf(d.fullCategory) > -1);
//         d3.select(this).attr(OPACITY, d.shouldBlur() ? 0.4 : (isSelected ? 1 : 0.3)).style(FONT_WEIGHT, isSelected ? BOLD : NORMAL);
//     });
//     highlightHeaders(viewModel, selectedDataPoints);
// }

export function syncStateOffice(viewModel: ViewModel) {
    // const shapes = <d3.Selection<any, DataPoint, any, any>>d3.selectAll(`${RECT}.${HIGHLIGHTABLE}, ${CIRCLE}.${HIGHLIGHTABLE}, ${PATH}.${HIGHLIGHTABLE}`);
    // const texts = <d3.Selection<any, DataPoint, any, any>>d3.selectAll(`${TEXT}.${HIGHLIGHTABLE}`);
    // if (Visual.isFilterApplied()) {
    //     shapes
    //         .attr(OPACITY, d => isPointSelectedOffice(d, Visual.selectedCategories, isOthersSelected) ? 1 : 0.3);
    //     texts
    //         .attr(FONT_WEIGHT, d => isPointSelectedOffice(d, Visual.selectedCategories, isOthersSelected) || d.isHighlighted ? BOLD : NORMAL)
    //         .attr(OPACITY, d => isPointSelectedOffice(d, Visual.selectedCategories, isOthersSelected) ? 1 : 0.3);
    // }
    // else {
    //     shapes.attr(OPACITY, 1);
    //     texts.attr(FONT_WEIGHT, d => d.isHighlighted ? BOLD : NORMAL);
    //     texts.attr(OPACITY, 1);
    //     d3.selectAll(`${LINE}.${SELECTION_DROP_LINE}`).remove();
    // }

    let shapes = highlightableShapes();
    let labels = highlightableLabels();
    let categories = highlightableCategories();
    let headers = highlightableHeaders();

    // if (!selectionIds) {
    //     return;
    // }

    if (!Visual.isFilterApplied()) {
        shapes.attr(OPACITY, d => d.shouldBlur() ? 0.4 : 1);
        labels.attr(OPACITY, d => d.shouldBlur() ? 0.4 : 1);
        labels.style(FONT_WEIGHT, d => d.getFontWeight());
        labels.nodes().forEach(label => {
            let dataProperty = <DataProperty>parseInt(d3.select((label).parentNode).attr(DATA_PROPERTY));

            let isBold = viewModel.settings.isHeaderBold(dataProperty);
            if (isBold) {
                d3.select(label).style(FONT_WEIGHT, BOLD);
            }
        });
        categories.attr(OPACITY, d => d.shouldBlur() ? 0.4 : 1);
        categories.style(FONT_WEIGHT, d => d.getFontWeight());
        if (viewModel.settings.plottingHorizontally()) {
            headers.attr(OPACITY, 1);
        }
        return;
    }

    let allDataPoints = viewModel.chartGroups.getAllDataPoints();
    allDataPoints.forEach(d => d.isSelected = false);
    viewModel.chartGroups.markSelectedPoints(Visual.selectedCategories);
    shapes.attr(OPACITY, d => d.shouldBlur() ? 0.4 : (d.isSelected ? 1 : 0.3));
    labels
        .attr(OPACITY, d => d.shouldBlur() ? 0.4 : (d.isSelected ? 1 : 0.3))
        .style(FONT_WEIGHT, d => d.isSelected ? BOLD : NORMAL);
    let selectedDataPoints = allDataPoints.filter(dp => dp.isSelected);
    let selectedCategories = selectedDataPoints.map(dp => dp.fullCategory);
    categories.each(function (d: DataPoint) {
        let isSelected = d.isSelected || (viewModel.settings.plottingHorizontally() && selectedCategories.indexOf(d.fullCategory) > -1);
        d3.select(this).attr(OPACITY, d.shouldBlur() ? 0.4 : (isSelected ? 1 : 0.3)).style(FONT_WEIGHT, isSelected ? BOLD : NORMAL);
    });
    highlightHeaders(viewModel, selectedDataPoints);
}

function highlightHeaders(viewModel: ViewModel, selectedDataPoints: DataPoint[]) {
    if (viewModel.settings.plottingHorizontally()) {
        let groupsWithSelectedElements = getGroupsFromSelectedElements(selectedDataPoints);
        let headerTexts = groupsWithSelectedElements.map(g => g.group);
        let headerShapes = highlightableHeaders();
        headerShapes.nodes().forEach(headerShape => {
            if (headerTexts.indexOf(headerShape.textContent) > -1) {
                d3.select(headerShape)
                    .attr(OPACITY, 1);
            }
            else {
                d3.select(headerShape)
                    .attr(OPACITY, 0.6);
            }
        });
    }
}

export function getTextAnchor(minValue: number, maxValue: number): TextAlign {
    if (minValue !== 0 && maxValue !== 0) {
        return "center";
    }
    else if (minValue === 0) {
        return "left";
    }
    return "right";
}

export function getTextAnchorForWaterfallChart(extreme: Extreme): TextAlign {
    if (extreme.max === 0 && extreme.maxLabelOffset === 0) {
        return "right";
    }
    else if (extreme.min === 0 && extreme.minLabelOffset === 0) {
        return "left";
    }
    return "center";
}

export function plotGridlines(yScale: d3.ScaleBand<string>, xPosition: number, width: number, axisPosition: number,
    settings: VarianceSettings, viewModel: ViewModel, dataPoints: DataPoint[], container: d3.Selection<SVGElement, any, any, any>, id: number,
    leftSideGridlinesEnd: (d: DataPoint, start: number) => number, rightSideGridlinesStart: (d: DataPoint, end: number) => number,
    groupIndex: number, hierachyIndex: number) {
    let skipLastPoint = viewModel.skipLastPointGridline(groupIndex, hierachyIndex);

    // Plot the left side of the gridlines (from the left edge of the chart to the datapoint or the axis)
    let leftEdge = Math.min(axisPosition, xPosition);
    let leftSideGridlinesStart = (d: DataPoint, start: number) => leftEdge;
    plotGridline(container, `${LEFT}${id} ${GRIDLINE_TEST_CLASS}`, settings, viewModel, dataPoints, leftEdge, leftEdge, leftSideGridlinesStart, leftSideGridlinesEnd, yScale, skipLastPoint);

    // Plot the right side of the gridlines (from the axis or the datapoint to the right edge of the chart)
    let rightEdge = Math.max(axisPosition, xPosition + width);
    let rightSideGridlinesEnd = (d: DataPoint, end: number) => rightEdge;
    plotGridline(container, `${RIGHT}${id} ${GRIDLINE_TEST_CLASS}`, settings, viewModel, dataPoints, rightEdge, rightEdge, rightSideGridlinesStart, rightSideGridlinesEnd, yScale, skipLastPoint);
}

export function plotGridline(container: d3.Selection<SVGElement, any, any, any>, classed: string, settings: VarianceSettings, viewModel: ViewModel, dataPoints: DataPoint[], start: number, end: number,
    startFunction: (d: DataPoint, start: number) => number, endFunction: (d: DataPoint, end: number) => number, yScale: d3.ScaleBand<string>, skipLastPoint: boolean) {
    let bandWidth = yScale.bandwidth();
    let gapInPixelsHalf = (settings.getGapBetweenColumns() * yScale.bandwidth()) / (1 - settings.getGapBetweenColumns()) / 2;
    let height = gapInPixelsHalf;
    let colorFunction = (d: DataPoint) => {
        if (d.isNormal() || d.isCollapsed || d.isOther() || d.isFormula()) {
            return settings.colorScheme.gridlineColor;
        }
        return settings.colorScheme.majorGridlineColor;
    };
    let pointsToPlot = skipLastPoint ? dataPoints.slice(0, dataPoints.length - 1) : dataPoints;
    if (settings.showMajorGridlines) {
        let flatResults = pointsToPlot.filter(d => d.isFlatResult());
        plotGridlinePart(container, `${GRIDLINE}-${FLAT_RESULTS}-${classed}`, flatResults, startFunction, start, yScale, viewModel, height, endFunction, end, colorFunction);
        let totals = pointsToPlot.filter(d => (d.isTotal() || d.isOtherTotal()));
        let grandTotal = pointsToPlot.filter(d => d.isGrandTotal());
        height = settings.showTotals === ShowTotals.Below ? -gapInPixelsHalf : bandWidth + gapInPixelsHalf;
        plotGridlinePart(container, `${GRIDLINE}-total-${classed}`, totals, startFunction, start, yScale, viewModel, -1 * height, endFunction, end, colorFunction);
        height = gapInPixelsHalf + (gapInPixelsHalf * 0.05);
        plotGridlinePart(container, `${GRIDLINE}-${GRAND_TOTAL}-${classed}`, grandTotal, startFunction, start, yScale, viewModel, height, endFunction, end, colorFunction);
    }

    let data: DataPoint[] = [];
    if (!settings.dontShowMinorGridlines()) {
        height = gapInPixelsHalf;
        let normalAndOtherDataPoints = pointsToPlot.filter(d => d.isNormal() || d.isOther() || d.isFormula());

        let gridlineDensity = settings.showAsTable ? settings.gridlineDensityTable : settings.gridlineDensity;
        for (let i = gridlineDensity; i < normalAndOtherDataPoints.length; i += gridlineDensity) {
            data.push(normalAndOtherDataPoints[i]);
        }
    }

    let categoryFormatDataPoints = pointsToPlot.filter(d => settings.getCategoryFormatTopBorder(d.category));
    data = [...data, ...categoryFormatDataPoints]
    plotGridlinePart(container, `${GRIDLINE}-${NORMAL}-${classed}`, data, startFunction, start, yScale, viewModel, height, endFunction, end, colorFunction);
}

function plotGridlinePart(container: d3.Selection<SVGElement, any, any, any>, classed: string, data: DataPoint[],
    startFunction: (d: DataPoint, start: number) => number, start: number, yScale: d3.ScaleBand<string>, viewModel: ViewModel, heightAdjustment: number,
    endFunction: (d: DataPoint, end: number) => number, end: number, colorFunction: (d: DataPoint) => string) {
    let g = container.selectAll(`.${classed}`).data(data);
    let line = g.enter().append(LINE).classed(classed, true);
    g = g.merge(line);
    g.exit().remove();
    g
        .attr(X1, d => Math.round(startFunction(d, start)))
        .attr(Y1, d => Math.max(1, Math.round(yScale(d.getCategory(viewModel)) - heightAdjustment)))
        .attr(X2, d => Math.round(endFunction(d, end)))
        .attr(Y2, d => Math.max(1, Math.round(yScale(d.getCategory(viewModel)) - heightAdjustment)))
        .attr(STROKE_WIDTH, 1)
        .attr(STROKE, d => colorFunction(d));
}

export function getLeftSideGridlinesEnd(xScale: d3.ScaleLinear<number, number>, valueProperty: string, axisPosition: number): (d: DataPoint, start: number) => number {
    return (d: DataPoint, startOfGridlines: number): number => {
        if (!d[valueProperty] || d[valueProperty] >= 0) {
            return axisPosition;
        }
        return Math.max(startOfGridlines, xScale(d[valueProperty]) - GRIDLINE_OFFSET);
    };
}

export function getRightSideGrindlinesStart(xScale: d3.ScaleLinear<number, number>, valueProperty: string, axisPosition: number, xPosition: number): (d: DataPoint, end: number) => number {
    return (d: DataPoint, endOfGridlines: number): number => {
        if (!d[valueProperty] || d[valueProperty] <= 0) {
            return axisPosition;
        }
        return Math.min(endOfGridlines, Math.max(xPosition, xScale(d[valueProperty]) + GRIDLINE_OFFSET));
    };
}

export function getVarianceColor(settings: VarianceSettings, dataProperty: DataProperty): (d: DataPoint) => string {
    return (d: DataPoint) => {
        return styles.getRelativeVarianceColor(settings.invert, d.isInverted, d.hasInvertedGroupAncestor(), settings.getColumnInvert(dataProperty), d.getValue(dataProperty), settings.colorScheme);
    };
}

export function getNeutralColor(settings: VarianceSettings): (d: DataPoint) => string {
    return (d: DataPoint) => {
        return d.isHighlighted ? settings.getCategoryHighlightColor(d.category) : settings.colorScheme.neutralColor;
    };
}

export function getMarkerColor(settings: VarianceSettings): (d: DataPoint) => string {
    return (d: DataPoint) => {
        return d.isHighlighted ? settings.getCategoryHighlightColor(d.category) : settings.colorScheme.markerColor;
    };
}

export function getEmptyMinsAndMaxes(): Extremes {
    let additionalMeasureExtremes: Extreme[] = [];
    for (let index = 0; index < NumberOfAdditionalMeasures; index++) {
        additionalMeasureExtremes.push(new Extreme(0, 0, 0, 0));
    }
    return new Extremes(
        new Extreme(0, 0, 0, 0),
        new Extreme(0, 0, 0, 0),
        new Extreme(0, 0, 0, 0),
        new Extreme(0, 0, 0, 0),
        new Extreme(0, 0, 0, 0),
        new Extreme(0, 0, 0, 0),
        new Extreme(0, 0, 0, 0),
        new Extreme(0, 0, 0, 0),
        new Extreme(0, 0, 0, 0),
        new Extreme(0, 0, 0, 0),
        new Extreme(0, 0, 0, 0),
        new Extreme(0, 0, 0, 0),
        new Extreme(0, 0, 0, 0),
        new Extreme(0, 0, 0, 0),
        new Extreme(0, 0, 0, 0),
        new Extreme(0, 0, 0, 0),
        new Extreme(0, 0, 0, 0),
        new Extreme(0, 0, 0, 0),
        new Extreme(0, 0, 0, 0),
        new Extreme(0, 0, 0, 0),
        new Extreme(0, 0, 0, 0),
        new Extreme(0, 0, 0, 0),
        new Extreme(0, 0, 0, 0),
        new Extreme(0, 0, 0, 0),
        new Extreme(0, 0, 0, 0),
        new Extreme(0, 0, 0, 0),
        new Extreme(0, 0, 0, 0),
        new Extreme(0, 0, 0, 0),
        new Extreme(0, 0, 0, 0),
        new Extreme(0, 0, 0, 0),
        new Extreme(0, 0, 0, 0),
        new Extreme(0, 0, 0, 0),
        new Extreme(0, 0, 0, 0),
        new Extreme(0, 0, 0, 0),
        new Extreme(0, 0, 0, 0),
        new Extreme(0, 0, 0, 0),
        new Extreme(0, 0, 0, 0),
        new Extreme(0, 0, 0, 0),
        new Extreme(0, 0, 0, 0),
        new Extreme(0, 0, 0, 0),
        new Extreme(0, 0, 0, 0),
        additionalMeasureExtremes,
        [], []);
}

// tslint:disable-next-line: max-func-body-length
export function drawValueIcons(chartType: ChartType, columnSettingsDiv: HTMLElement, icon: d3.Selection<SVGElement, any, any, any>, viewModel: ViewModel, iconSize: number, dataProperty: DataProperty, plotter: Plotter): number {
    icon.selectAll("*").remove();
    if (viewModel.settings.chartType === ACT_ABS_REL) {
        appendBarIcon(chartType, columnSettingsDiv, icon, 0, viewModel, dataProperty, plotter);
        if (allowOverlappedChartChange(viewModel, dataProperty)) {
            appendOverlappedBarIcon(chartType, columnSettingsDiv, icon, 30, viewModel, dataProperty, plotter);
            appendPinIcon(chartType, columnSettingsDiv, icon, 60, viewModel, dataProperty, plotter);
            if (isAdditionalMeasure(dataProperty)) {
                appendTableIcon(chartType, columnSettingsDiv, icon, 90, viewModel, dataProperty, plotter);
                return 110;
            }
            else {
                if (viewModel.hasHierarchy) {
                    appendCalculationWaterfallIcon(chartType, columnSettingsDiv, icon, 90, viewModel, dataProperty, plotter);
                    if (viewModel.settings.noInvertsOrResultsOrSkips()) {
                        appendWaterfallIcon(chartType, columnSettingsDiv, icon, 120, viewModel, true, dataProperty, plotter);
                        appendTableIcon(chartType, columnSettingsDiv, icon, 150, viewModel, dataProperty, plotter);
                        return 170;
                    }
                    else {
                        appendTableIcon(chartType, columnSettingsDiv, icon, 120, viewModel, dataProperty, plotter);
                        return 140;
                    }
                }
                else {
                    appendWaterfallIcon(chartType, columnSettingsDiv, icon, 90, viewModel, true, dataProperty, plotter);
                    appendTableIcon(chartType, columnSettingsDiv, icon, 120, viewModel, dataProperty, plotter);
                    return 140;
                }
            }
        }
        else {
            appendPinIcon(chartType, columnSettingsDiv, icon, 30, viewModel, dataProperty, plotter);
            if (isAdditionalMeasure(dataProperty)) {
                appendTableIcon(chartType, columnSettingsDiv, icon, 60, viewModel, dataProperty, plotter);
                return 80;
            }
            else {
                if (viewModel.hasHierarchy) {
                    appendCalculationWaterfallIcon(chartType, columnSettingsDiv, icon, 60, viewModel, dataProperty, plotter);
                    if (viewModel.settings.noInvertsOrResultsOrSkips()) {
                        appendWaterfallIcon(chartType, columnSettingsDiv, icon, 90, viewModel, true, dataProperty, plotter);
                        appendTableIcon(chartType, columnSettingsDiv, icon, 120, viewModel, dataProperty, plotter);
                        return 140;
                    }
                    else {
                        appendTableIcon(chartType, columnSettingsDiv, icon, 90, viewModel, dataProperty, plotter);
                        return 110;
                    }
                }
                else {
                    appendWaterfallIcon(chartType, columnSettingsDiv, icon, 60, viewModel, true, dataProperty, plotter);
                    appendTableIcon(chartType, columnSettingsDiv, icon, 90, viewModel, dataProperty, plotter);
                    return 110;
                }
            }
        }
    }
    else {
        appendBarIcon(chartType, columnSettingsDiv, icon, 0, viewModel, dataProperty, plotter);
        if (viewModel.HasReferenceColumn) {
            if (allowOverlappedChartChange(viewModel, dataProperty)) {
                appendOverlappedBarIcon(chartType, columnSettingsDiv, icon, 30, viewModel, dataProperty, plotter);
                if (isAdditionalMeasure(dataProperty)) {
                    appendPinIcon(chartType, columnSettingsDiv, icon, 60, viewModel, dataProperty, plotter);
                    appendTableIcon(chartType, columnSettingsDiv, icon, 90, viewModel, dataProperty, plotter);
                    return 110;

                }
                else {
                    if (viewModel.hasHierarchy) {
                        appendCalculationWaterfallIcon(chartType, columnSettingsDiv, icon, 60, viewModel, dataProperty, plotter);
                        if (viewModel.settings.noInvertsOrResultsOrSkips()) {
                            appendWaterfallIcon(chartType, columnSettingsDiv, icon, 90, viewModel, true, dataProperty, plotter);
                            appendPinIcon(chartType, columnSettingsDiv, icon, 120, viewModel, dataProperty, plotter);
                            appendTableIcon(chartType, columnSettingsDiv, icon, 150, viewModel, dataProperty, plotter);
                            return 170;
                        }
                        else {
                            appendPinIcon(chartType, columnSettingsDiv, icon, 90, viewModel, dataProperty, plotter);
                            appendTableIcon(chartType, columnSettingsDiv, icon, 120, viewModel, dataProperty, plotter);
                            return 140;
                        }
                    }
                    else {
                        appendWaterfallIcon(chartType, columnSettingsDiv, icon, 60, viewModel, true, dataProperty, plotter);
                        appendPinIcon(chartType, columnSettingsDiv, icon, 90, viewModel, dataProperty, plotter);
                        appendTableIcon(chartType, columnSettingsDiv, icon, 120, viewModel, dataProperty, plotter);
                        return 140;
                    }
                }
            }
            else {
                if (isAdditionalMeasure(dataProperty)) {
                    appendPinIcon(chartType, columnSettingsDiv, icon, 30, viewModel, dataProperty, plotter);
                    appendTableIcon(chartType, columnSettingsDiv, icon, 60, viewModel, dataProperty, plotter);
                    return 80;
                }
                else {
                    if (viewModel.hasHierarchy) {
                        appendCalculationWaterfallIcon(chartType, columnSettingsDiv, icon, 30, viewModel, dataProperty, plotter);
                        if (viewModel.settings.noInvertsOrResultsOrSkips()) {
                            appendWaterfallIcon(chartType, columnSettingsDiv, icon, 60, viewModel, true, dataProperty, plotter);
                            appendPinIcon(chartType, columnSettingsDiv, icon, 90, viewModel, dataProperty, plotter);
                            appendTableIcon(chartType, columnSettingsDiv, icon, 120, viewModel, dataProperty, plotter);
                            return 140;
                        }
                        else {
                            appendPinIcon(chartType, columnSettingsDiv, icon, 60, viewModel, dataProperty, plotter);
                            appendTableIcon(chartType, columnSettingsDiv, icon, 90, viewModel, dataProperty, plotter);
                            return 110;
                        }
                    }
                    else {
                        appendWaterfallIcon(chartType, columnSettingsDiv, icon, 30, viewModel, true, dataProperty, plotter);
                        appendPinIcon(chartType, columnSettingsDiv, icon, 60, viewModel, dataProperty, plotter);
                        appendTableIcon(chartType, columnSettingsDiv, icon, 90, viewModel, dataProperty, plotter);
                        return 110;
                    }
                }
            }
        }
        else {
            if (isAdditionalMeasure(dataProperty)) {
                appendPinIcon(chartType, columnSettingsDiv, icon, 30, viewModel, dataProperty, plotter);
                appendTableIcon(chartType, columnSettingsDiv, icon, 60, viewModel, dataProperty, plotter);
                return 80;
            }
            else {
                if (viewModel.hasHierarchy) {
                    appendCalculationWaterfallIcon(chartType, columnSettingsDiv, icon, 30, viewModel, dataProperty, plotter);
                    if (viewModel.settings.noInvertsOrResultsOrSkips()) {
                        appendWaterfallIcon(chartType, columnSettingsDiv, icon, 60, viewModel, true, dataProperty, plotter);
                        appendPinIcon(chartType, columnSettingsDiv, icon, 90, viewModel, dataProperty, plotter);
                        appendTableIcon(chartType, columnSettingsDiv, icon, 120, viewModel, dataProperty, plotter);
                        return 140;
                    }
                    else {
                        appendPinIcon(chartType, columnSettingsDiv, icon, 60, viewModel, dataProperty, plotter);
                        appendTableIcon(chartType, columnSettingsDiv, icon, 90, viewModel, dataProperty, plotter);
                        return 110;
                    }
                }
                else {
                    appendWaterfallIcon(chartType, columnSettingsDiv, icon, 30, viewModel, true, dataProperty, plotter);
                    appendPinIcon(chartType, columnSettingsDiv, icon, 60, viewModel, dataProperty, plotter);
                    appendTableIcon(chartType, columnSettingsDiv, icon, 90, viewModel, dataProperty, plotter);
                    return 110;
                }
            }
        }
    }
}

function allowOverlappedChartChange(viewModel: ViewModel, dataProperty: DataProperty): boolean {
    if (scenarios.getScenario(dataProperty, viewModel) === viewModel.value.scenario && !isAdditionalMeasure(dataProperty)) {
        return true;
    }
    return false;
}

export function drawAbsoluteIcons(chartType: ChartType, columnSettingsDiv: HTMLElement, icon: d3.Selection<SVGElement, any, any, any>, viewModel: ViewModel, iconSize: number, dataProperty: DataProperty, plotter: Plotter): number {
    icon.selectAll("*").remove();
    if (!viewModel.hasHierarchy) {
        appendPlusMinusBarIcon(chartType, columnSettingsDiv, icon, 0, viewModel, dataProperty, plotter);
        if (isAdditionalMeasure(dataProperty)) {
            appendTableIcon(chartType, columnSettingsDiv, icon, 30, viewModel, dataProperty, plotter);
            return 50;
        }
        else {
            appendWaterfallIcon(chartType, columnSettingsDiv, icon, 30, viewModel, false, dataProperty, plotter);
            appendTableIcon(chartType, columnSettingsDiv, icon, 60, viewModel, dataProperty, plotter);
            return 80;
        }
    }
    else {
        appendPlusMinusBarIcon(chartType, columnSettingsDiv, icon, 0, viewModel, dataProperty, plotter);
        if (viewModel.settings.noInvertsOrResultsOrSkips()) {
            if (isAdditionalMeasure(dataProperty)) {
                appendTableIcon(chartType, columnSettingsDiv, icon, 30, viewModel, dataProperty, plotter);
                return 50;
            }
            else {
                appendCalculationWaterfallIcon(chartType, columnSettingsDiv, icon, 30, viewModel, dataProperty, plotter);
                appendWaterfallIcon(chartType, columnSettingsDiv, icon, 60, viewModel, false, dataProperty, plotter);
                appendTableIcon(chartType, columnSettingsDiv, icon, 90, viewModel, dataProperty, plotter);
                return 110;
            }
        }
        else {
            if (isAdditionalMeasure(dataProperty)) {
                appendTableIcon(chartType, columnSettingsDiv, icon, 30, viewModel, dataProperty, plotter);
                return 50;
            }
            else {
                appendCalculationWaterfallIcon(chartType, columnSettingsDiv, icon, 30, viewModel, dataProperty, plotter);
                appendTableIcon(chartType, columnSettingsDiv, icon, 60, viewModel, dataProperty, plotter);
                return 80;
            }
        }
    }
}

export function drawRelativeIcons(chartType: ChartType, columnSettingsDiv: HTMLElement, icon: d3.Selection<SVGElement, any, any, any>, viewModel: ViewModel, iconSize: number, dataProperty: DataProperty, plotter: Plotter): number {
    icon.selectAll("*").remove();
    appendPlusMinusDotIcon(chartType, columnSettingsDiv, icon, 0, viewModel, dataProperty, plotter);
    appendTableIcon(chartType, columnSettingsDiv, icon, 30, viewModel, dataProperty, plotter);
    return 80;
}

function appendPlusMinusBarIcon(chartType: ChartType, columnSettingsDiv: HTMLElement, icon: d3.Selection<SVGElement, any, any, any>, left: number, viewModel: ViewModel, dataProperty: DataProperty, plotter: Plotter) {
    let group = icon.append("g")
        .attr(TRANSFORM, `translate(${left}, 0)`)
        .classed(ICON, true);
    appendBasicRect(group, 20, 20, WHITE, 0, 0, 0);
    group.on(CLICK, () => {
        if (isAdditionalMeasure(dataProperty)) {
            viewModel.settings.persistAdditionalMeasureAbsoluteCharts(dataProperty, AbsoluteChart.Bar);
        }
        else {
            viewModel.settings.absoluteChart = AbsoluteChart.Bar;
            viewModel.settings.persistAbsoluteChart(dataProperty);
        }
    })
        .on(MOUSEOVER, () => {
            let parentBoundingBox = drawing.getBoundingBoxHtml(icon.node().parentElement);
            ui.showTooltip(plotter.settings.showFrozenCategories() ? Visual.headersDoubleOuterDiv : Visual.headersDiv, {
                x: parentBoundingBox.left + left + (plotter.settings.showFrozenCategories() ? -plotter.categoriesWidth : (<any>window).currentScrollLeft) - plotter.getCommentBoxOffsetLeft(),
                y: parentBoundingBox.top + ICON_TOOLTIP_Y_OFFSET - plotter.titleHeight - plotter.getCommentBoxOffsetTop()
            }, "Plus-minus", 250, 1000);
        })
        .on(MOUSELEAVE, () => {
            d3.selectAll("." + HEADER_TOOLTIP).remove();
        });
    if (chartType === ChartType.AbsoluteChart && viewModel.settings.absoluteChart === AbsoluteChart.Bar) {
        group.style(OPACITY, 1);
    }
    let darkGray = "#333";
    appendBasicRect(group, 20, 20, WHITE, 0, 0, 0);
    appendBasicRect(group, 1, 19, darkGray, 7, 1, 1);
    appendBasicRect(group, 9, 5, darkGray, 8, 2, 1);
    appendBasicRect(group, 5, 5, darkGray, 8, 8, 1);
    appendBasicRect(group, 4, 5, darkGray, 3, 14, 1);
}

function appendPlusMinusDotIcon(chartType: ChartType, columnSettingsDiv: HTMLElement, icon: d3.Selection<SVGElement, any, any, any>, left: number, viewModel: ViewModel, dataProperty: DataProperty, plotter: Plotter) {
    let group = icon.append("g")
        .attr(TRANSFORM, `translate(${left}, 0)`)
        .classed(ICON, true);
    appendBasicRect(group, 20, 20, WHITE, 0, 0, 0);
    group.on(CLICK, () => {
        viewModel.settings.persistShowDataPropertyAsTable(dataProperty, ShowAsTableOptions.False);
    })
        .on(MOUSEOVER, () => {
            let parentBoundingBox = drawing.getBoundingBoxHtml(icon.node().parentElement);
            ui.showTooltip(plotter.settings.showFrozenCategories() ? Visual.headersDoubleOuterDiv : Visual.headersDiv, {
                x: parentBoundingBox.left + left + (plotter.settings.showFrozenCategories() ? -plotter.categoriesWidth : (<any>window).currentScrollLeft) - plotter.getCommentBoxOffsetLeft(),
                y: parentBoundingBox.top + ICON_TOOLTIP_Y_OFFSET - plotter.titleHeight - plotter.getCommentBoxOffsetTop()
            }, "Pin", 250, 1000);
        })
        .on(MOUSELEAVE, () => {
            d3.selectAll("." + HEADER_TOOLTIP).remove();
        });
    if (chartType === ChartType.PlusMinusDot) {
        group.style(OPACITY, 1);
    }
    let darkGray = "#333";
    appendBasicRect(group, 1, 20, darkGray, 9, 0, 1);
    appendBasicRect(group, 8, 1, darkGray, 10, 5, 1);
    appendBasicRect(group, 5, 1, darkGray, 4, 14, 1);
    appendBasicCircle(group, 3, darkGray, 17, 5, 1);
    appendBasicCircle(group, 3, darkGray, 5, 14, 1);
}

function appendBarIcon(chartType: ChartType, columnSettingsDiv: HTMLElement, icon: d3.Selection<SVGElement, any, any, any>, left: number, viewModel: ViewModel, dataProperty: DataProperty, plotter: Plotter) {
    let group = icon.append("g")
        .attr(TRANSFORM, `translate(${left}, 0)`)
        .classed(ICON, true);
    appendBasicRect(group, 20, 20, WHITE, 0, 0, 0);
    group.on(CLICK, () => {
        if (isAdditionalMeasure(dataProperty)) {
            viewModel.settings.persistAdditionalMeasureValueCharts(dataProperty, ValueChart.Bar);
        }
        else {
            viewModel.settings.valueChart = ValueChart.Bar;
            viewModel.settings.persistValueChart(dataProperty);
        }
    })
        .on(MOUSEOVER, () => {
            let parentBoundingBox = drawing.getBoundingBoxHtml(icon.node().parentElement);
            ui.showTooltip(plotter.settings.showFrozenCategories() ? Visual.headersDoubleOuterDiv : Visual.headersDiv, {
                x: parentBoundingBox.left + left + (plotter.settings.showFrozenCategories() ? -plotter.categoriesWidth : (<any>window).currentScrollLeft) - plotter.getCommentBoxOffsetLeft(),
                y: parentBoundingBox.top + ICON_TOOLTIP_Y_OFFSET - plotter.titleHeight - plotter.getCommentBoxOffsetTop()
            }, "Bar", 250, 1000);
        })
        .on(MOUSELEAVE, () => {
            d3.selectAll("." + HEADER_TOOLTIP).remove();
        });
    if (isBarChartSelected(chartType, viewModel, dataProperty)) {
        group.style(OPACITY, 1);
    }
    let darkGray = "#333";
    appendBasicRect(group, 1, 20, darkGray, 3, 0, 1);
    appendBasicRect(group, 13, 7, darkGray, 4, 2, 1);
    appendBasicRect(group, 8, 7, darkGray, 4, 11, 1);
}

function isBarChartSelected(chartType: ChartType, viewModel: ViewModel, dataProperty: DataProperty): boolean {
    if (chartType === ChartType.ValueChart && isAdditionalMeasure(dataProperty)) {
        return viewModel.settings.getAdditionalMeasureValueChart(dataProperty) === ValueChart.Bar;
    }
    return chartType === ChartType.ValueChart && viewModel.settings.valueChart === ValueChart.Bar;
}


function appendPinIcon(chartType: ChartType, columnSettingsDiv: HTMLElement, icon: d3.Selection<SVGElement, any, any, any>, left: number, viewModel: ViewModel, dataProperty: DataProperty, plotter: Plotter) {
    let group = icon.append("g")
        .attr(TRANSFORM, `translate(${left}, 0)`)
        .classed(ICON, true);
    appendBasicRect(group, 20, 20, WHITE, 0, 0, 0);
    group.on(CLICK, () => {
        if (isAdditionalMeasure(dataProperty)) {
            viewModel.settings.persistAdditionalMeasureValueCharts(dataProperty, ValueChart.Pin);
        }
        else {
            viewModel.settings.valueChart = ValueChart.Pin;
            viewModel.settings.persistValueChart(dataProperty);
        }
    })
        .on(MOUSEOVER, () => {
            let parentBoundingBox = drawing.getBoundingBoxHtml(icon.node().parentElement);
            ui.showTooltip(plotter.settings.showFrozenCategories() ? Visual.headersDoubleOuterDiv : Visual.headersDiv, {
                x: parentBoundingBox.left + left + (plotter.settings.showFrozenCategories() ? -plotter.categoriesWidth : (<any>window).currentScrollLeft) - plotter.getCommentBoxOffsetLeft(),
                y: parentBoundingBox.top + ICON_TOOLTIP_Y_OFFSET - plotter.titleHeight - plotter.getCommentBoxOffsetTop()
            }, "Pin", 250, 1000);
        })
        .on(MOUSELEAVE, () => {
            d3.selectAll("." + HEADER_TOOLTIP).remove();
        });
    if (isPinChartSelected(chartType, viewModel, dataProperty)) {
        group.style(OPACITY, 1);
    }
    let darkGray = "#333";
    appendBasicRect(group, 1, 20, darkGray, 3, 0, 1);
    appendBasicRect(group, 13, 1, darkGray, 4, 5, 1);
    appendBasicRect(group, 8, 1, darkGray, 4, 14, 1);
    appendBasicCircle(group, 3, darkGray, 17, 5, 1);
    appendBasicCircle(group, 3, darkGray, 12, 14, 1);
}

function isPinChartSelected(chartType: ChartType, viewModel: ViewModel, dataProperty: DataProperty): boolean {
    if (chartType === ChartType.ValueChart && isAdditionalMeasure(dataProperty)) {
        return viewModel.settings.getAdditionalMeasureValueChart(dataProperty) === ValueChart.Pin;
    }
    return chartType === ChartType.ValueChart && viewModel.settings.valueChart === ValueChart.Pin;
}

function appendOverlappedBarIcon(chartType: ChartType, columnSettingsDiv: HTMLElement, icon: d3.Selection<SVGElement, any, any, any>, left: number, viewModel: ViewModel, dataProperty: DataProperty, plotter: Plotter) {
    let group = icon.append("g")
        .attr(TRANSFORM, `translate(${left}, 0)`)
        .classed(ICON, true);
    appendBasicRect(group, 20, 20, WHITE, 0, 0, 0);
    group.on(CLICK, () => {
        viewModel.settings.valueChart = ValueChart.OverlappedBar;
        viewModel.settings.persistValueChart(dataProperty);
    })
        .on(MOUSEOVER, () => {
            let parentBoundingBox = drawing.getBoundingBoxHtml(icon.node().parentElement);
            ui.showTooltip(plotter.settings.showFrozenCategories() ? Visual.headersDoubleOuterDiv : Visual.headersDiv, {
                x: parentBoundingBox.left + left + (plotter.settings.showFrozenCategories() ? -plotter.categoriesWidth : (<any>window).currentScrollLeft) - plotter.getCommentBoxOffsetLeft(),
                y: parentBoundingBox.top + ICON_TOOLTIP_Y_OFFSET - plotter.titleHeight - plotter.getCommentBoxOffsetTop()
            }, "Overlapped bar", 250, 1000);
        })
        .on(MOUSELEAVE, () => {
            d3.selectAll("." + HEADER_TOOLTIP).remove();
        });
    if (chartType === ChartType.ValueChart && viewModel.settings.valueChart === ValueChart.OverlappedBar) {
        group.style(OPACITY, 1);
    }
    let darkGray = "#333";
    appendBasicRect(group, 1, 20, darkGray, 3, 0, 1);
    appendBasicRect(group, 13, 6, darkGray, 4, 3, 1);
    appendBasicRect(group, 8, 6, darkGray, 4, 13, 1);
    appendBasicRect(group, 9, 1, darkGray, 4, 1, 1);
    appendBasicRect(group, 1, 1, darkGray, 12, 2, 1);
    appendBasicRect(group, 11, 1, darkGray, 4, 11, 1);
    appendBasicRect(group, 1, 5, darkGray, 14, 12, 1);
    appendBasicRect(group, 2, 1, darkGray, 12, 16, 1);
    return group;
}

function appendWaterfallIcon(chartType: ChartType, columnSettingsDiv: HTMLElement, icon: d3.Selection<SVGElement, any, any, any>, left: number, viewModel: ViewModel, valueChart: boolean, dataProperty: DataProperty, plotter: Plotter) {
    let group = icon.append("g")
        .attr(TRANSFORM, `translate(${left}, 0)`)
        .classed(ICON, true);
    appendBasicRect(group, 20, 20, WHITE, 0, 0, 0);
    if (valueChart) {
        let property = ValueChart.Waterfall;
        group.on(CLICK, () => {
            if (isAdditionalMeasure(dataProperty)) {
                viewModel.settings.persistAdditionalMeasureValueCharts(dataProperty, ValueChart.Waterfall);
            }
            else {
                viewModel.settings.valueChart = property;
                viewModel.settings.persistValueChart(dataProperty);
            }
        })
            .on(MOUSEOVER, () => {
                let parentBoundingBox = drawing.getBoundingBoxHtml(icon.node().parentElement);
                ui.showTooltip(plotter.settings.showFrozenCategories() ? Visual.headersDoubleOuterDiv : Visual.headersDiv, {
                    x: parentBoundingBox.left + left + (plotter.settings.showFrozenCategories() ? -plotter.categoriesWidth : (<any>window).currentScrollLeft) - plotter.getCommentBoxOffsetLeft(),
                    y: parentBoundingBox.top + ICON_TOOLTIP_Y_OFFSET - plotter.titleHeight - plotter.getCommentBoxOffsetTop()
                }, "Waterfall", 250, 1000);
            })
            .on(MOUSELEAVE, () => {
                d3.selectAll("." + HEADER_TOOLTIP).remove();
            });
        if (chartType === ChartType.ValueChart && viewModel.settings.valueChart === property) {
            group.style(OPACITY, 1);
        }
    }
    else {
        group.on(CLICK, () => {
            viewModel.settings.absoluteChart = AbsoluteChart.Waterfall;
            viewModel.settings.persistAbsoluteChart(dataProperty);
        })
            .on(MOUSEOVER, () => {
                let parentBoundingBox = drawing.getBoundingBoxHtml(icon.node().parentElement);
                ui.showTooltip(plotter.settings.showFrozenCategories() ? Visual.headersDoubleOuterDiv : Visual.headersDiv, {
                    x: parentBoundingBox.left + left + (plotter.settings.showFrozenCategories() ? -plotter.categoriesWidth : (<any>window).currentScrollLeft) - plotter.getCommentBoxOffsetLeft(),
                    y: parentBoundingBox.top + ICON_TOOLTIP_Y_OFFSET - plotter.titleHeight - plotter.getCommentBoxOffsetTop()
                }, "Waterfall", 250, 1000);
            })
            .on(MOUSELEAVE, () => {
                d3.selectAll("." + HEADER_TOOLTIP).remove();
            });
        if (chartType === ChartType.AbsoluteChart && viewModel.settings.absoluteChart === AbsoluteChart.Waterfall) {
            group.style(OPACITY, 1);
        }
    }
    let darkGray = "#333";
    appendBasicRect(group, 20, 20, WHITE, 0, 0, 0);
    appendBasicRect(group, 1, 19, darkGray, 3, 1, 1);
    appendBasicRect(group, 13, 5, darkGray, 4, 2, 1);
    appendBasicRect(group, 5, 5, darkGray, 12, 8, 1);
    appendBasicRect(group, 9, 5, darkGray, 4, 14, 1);
}

function appendCalculationWaterfallIcon(chartType: ChartType, columnSettingsDiv: HTMLElement, icon: d3.Selection<SVGElement, any, any, any>, left: number, viewModel: ViewModel, dataProperty: DataProperty, plotter: Plotter) {
    let group = icon.append("g")
        .attr(TRANSFORM, `translate(${left}, 0)`)
        .classed(ICON, true);
    appendBasicRect(group, 20, 20, WHITE, 0, 0, 0);
    group
        .on(CLICK, () => {
            if (isValueMeasure(dataProperty)) {
                viewModel.settings.valueChart = ValueChart.CalculationWaterfall;
                viewModel.settings.persistValueChart(dataProperty);
            }
            else if (isAbsoluteDifferenceMeasure(dataProperty)) {
                viewModel.settings.absoluteChart = AbsoluteChart.CalculationWaterfall;
                viewModel.settings.persistAbsoluteChart(dataProperty);
            }
        })
        .on(MOUSEOVER, () => {
            let parentBoundingBox = drawing.getBoundingBoxHtml(icon.node().parentElement);
            ui.showTooltip(plotter.settings.showFrozenCategories() ? Visual.headersDoubleOuterDiv : Visual.headersDiv, {
                x: parentBoundingBox.left + left + (plotter.settings.showFrozenCategories() ? -plotter.categoriesWidth : (<any>window).currentScrollLeft) - plotter.getCommentBoxOffsetLeft(),
                y: parentBoundingBox.top + ICON_TOOLTIP_Y_OFFSET - plotter.titleHeight - plotter.getCommentBoxOffsetTop()
            }, "Calculation Waterfall", 250, 1000);
        })
        .on(MOUSELEAVE, () => {
            d3.selectAll("." + HEADER_TOOLTIP).remove();
        });
    if (chartType === ChartType.AbsoluteChart && viewModel.settings.absoluteChart === AbsoluteChart.CalculationWaterfall) {
        group.style(OPACITY, 1);
    }
    else if (chartType === ChartType.ValueChart && viewModel.settings.valueChart === ValueChart.CalculationWaterfall) {
        group.style(OPACITY, 1);
    }
    let darkGray = "#333";
    appendBasicRect(group, 20, 20, WHITE, 0, 0, 0);
    appendBasicRect(group, 1, 19, darkGray, 3, 1, 1);
    appendBasicRect(group, 8, 5, darkGray, 4, 2, 1);
    appendBasicRect(group, 5, 5, darkGray, 7, 8, 1);
    appendBasicRect(group, 10, 5, darkGray, 7, 14, 1);
    appendBasicRect(group, 1, 1, darkGray, 11, 7, 1);
    appendBasicRect(group, 1, 1, darkGray, 7, 13, 1);
}

function appendTableIcon(chartType: ChartType, columnSettingsDiv: HTMLElement, icon: d3.Selection<SVGElement, any, any, any>, left: number, viewModel: ViewModel, dataProperty: DataProperty, plotter: Plotter) {
    let group = icon.append("g")
        .attr(TRANSFORM, `translate(${left}, 0)`)
        .classed(ICON, true);
    appendBasicRect(group, 20, 20, WHITE, 0, 0, 0);
    group.on(CLICK, () => {
        viewModel.settings.persistShowDataPropertyAsTable(dataProperty, ShowAsTableOptions.True);
    })
        .on(MOUSEOVER, () => {
            let parentBoundingBox = drawing.getBoundingBoxHtml(icon.node().parentElement);
            ui.showTooltip(plotter.settings.showFrozenCategories() ? Visual.headersDoubleOuterDiv : Visual.headersDiv, {
                x: parentBoundingBox.left + left + (plotter.settings.showFrozenCategories() ? -plotter.categoriesWidth : (<any>window).currentScrollLeft) - plotter.getCommentBoxOffsetLeft(),
                y: parentBoundingBox.top + ICON_TOOLTIP_Y_OFFSET - plotter.titleHeight - plotter.getCommentBoxOffsetTop()
            }, "Table", 250, 1000);
        })
        .on(MOUSELEAVE, () => {
            d3.selectAll("." + HEADER_TOOLTIP).remove();
        });
    if (chartType === ChartType.Table) {
        group.style(OPACITY, 1);
    }
    let darkGray = "#333";
    appendBasicRect(group, 16, 2, darkGray, 2, 2, 1);
    appendBasicRect(group, 16, 2, darkGray, 2, 9, 1);
    appendBasicRect(group, 16, 2, darkGray, 2, 16, 1);
}

function appendBasicRect(parent: d3.Selection<SVGElement, any, any, any>, width: number, height: number, fill: string, x: number, y: number, opacity: number) {
    let rectangle = parent.append(RECT)
        .attr(WIDTH, width)
        .attr(HEIGHT, height)
        .attr(FILL, fill);
    rectangle.attr(X, x);
    rectangle.attr(Y, y);
    if (opacity !== 1) {
        rectangle.style(OPACITY, opacity);
    }
}

function appendBasicCircle(parent: d3.Selection<SVGElement, any, any, any>, radius: number, fill: string, x?: number, y?: number, strokeWidth?: number) {
    let circle = parent.append(CIRCLE)
        .attr(R, radius)
        .attr(FILL, fill);
    if (strokeWidth) {
        circle.attr(STROKE_WIDTH, strokeWidth);
    }
    if (x) {
        circle.attr(CX, x);
    }
    if (y) {
        circle.attr(CY, y);
    }
}

export function plottingValueChart(dataProperty: DataProperty): boolean {
    return dataProperty === DataProperty.Value || dataProperty === DataProperty.ReferenceValue || dataProperty === DataProperty.SecondReferenceValue || dataProperty === DataProperty.ThirdReferenceValue ||
        dataProperty === DataProperty.FourthReferenceValue || dataProperty === DataProperty.FifthReferenceValue || dataProperty === DataProperty.SixthReferenceValue ||
        dataProperty === DataProperty.SeventhReferenceValue;
}

export function plottingAbsoluteChart(dataProperty: DataProperty): boolean {
    return dataProperty === DataProperty.AbsoluteDifference || dataProperty === DataProperty.SecondAbsoluteDifference || dataProperty === DataProperty.ThirdAbsoluteDifference ||
        dataProperty === DataProperty.FourthAbsoluteDifference || dataProperty === DataProperty.FifthAbsoluteDifference || dataProperty === DataProperty.SixthAbsoluteDifference ||
        dataProperty === DataProperty.SeventhAbsoluteDifference;
}

export function getOrdinalScale(dataPoints: DataPoint[], rangeStart: number, rangeEnd: number, viewModel: ViewModel, gap: number): d3.ScaleBand<string> {
    return d3.scaleBand()
        .domain(dataPoints.map(d => d.getCategory(viewModel)))
        .range([rangeStart, rangeEnd])
        .paddingInner(gap)
        .paddingOuter(gap / 2);
}

export function getTooltipColor(value: number, scenario: Scenario, settings: VarianceSettings): string {
    if (value === null) {
        return WHITE;
    }
    if (settings.colorScheme.useCustomScenarioColors) {
        if (scenario === Scenario.PreviousYear) {
            return settings.colorScheme.previousYearColor;
        }
        else if (isPlanScenario(scenario)) {
            if (scenario === Scenario.Plan) {
                return settings.colorScheme.planColor;
            }
            else if (scenario === Scenario.Plan2) {
                return settings.colorScheme.plan2Color;
            }
            else {
                return settings.colorScheme.plan3Color;
            }
        }
        else if (isForecastScenario(scenario)) {
            return settings.colorScheme.forecastColor;
        }
    }
    if (scenario === Scenario.PreviousYear) {
        return styles.getLighterColor(settings.colorScheme.neutralColor);
    }
    return settings.colorScheme.neutralColor;
}

export function getTooltipHeader(datapoint: DataPoint, settings: VarianceSettings): string {
    let result = datapoint.category;
    if (settings.plottingHorizontally()) {
        let parent = datapoint.parent;
        while (parent) {
            if ((<any>parent).group) {
                result = `${result} / ${settings.getGroupName((<any>parent))}`;
            }
            parent = parent.parent;
        }
    }
    return result;
}

export function isPercentAdditionalMeasure(dataProperty: DataProperty, settings: VarianceSettings): boolean {
    if (isAdditionalMeasure(dataProperty) && settings.getColumnFormat(dataProperty) === ColumnFormat.Percent) {
        return true;
    }
    return false;
}


export function shouldPlotValuePoint(d: DataPoint, plotter: Plotter, yScale: d3.ScaleBand<string>): boolean {
    if (d.isNormal() || d.isFlatResult() || d.isOther() || d.isTotal() || d.isOtherTotal() || d.isFormula()) {
        return plotter.isDataPointInView(d, yScale);
    }
    return false;
}

export function getFormattedDataLabel(value: number, decimalPlaces: number, displayUnits: DisplayUnits, locale: string, encloseNegativeValuesInParentheses: boolean, isVariance: boolean, percentageFormat?: string, hideUnits: boolean = false): string {
    if (value == null) {
        return "";
    }
    let encloseInParenthesis = encloseNegativeValuesInParentheses && value < 0;
    let prefix = isVariance && value > 0 ? "+" : "";
    let labelValue = encloseInParenthesis ? Math.abs(value) : value;
    let label = `${prefix}${formatting.getDataLabel(labelValue, decimalPlaces, displayUnits, locale, percentageFormat, hideUnits)}`;
    if (encloseInParenthesis) {
        label = `(${label})`;
    }
    return label;
}

export function getFormattedRelativeDataLabel(value: number, decimalPlaces: number, locale: string, encloseNegativeValuesInParentheses: boolean, hideUnits: boolean, showPercentageInLabel: boolean): string {
    let label = getFormattedDataLabel(value, decimalPlaces, DisplayUnits.Relative, locale, encloseNegativeValuesInParentheses, true, null, hideUnits);
    if (showPercentageInLabel) {
        label += "%";
    }
    return label;
}

export function getFormattedSuppressedRelativeDataLabel(value: number, suppressValue: number, decimalPlaces: number, locale: string, encloseNegativeValuesInParentheses: boolean, hideUnits: boolean, showPercentageInLabel: boolean, percentageFormat: string, isAdditionalMeasure: boolean, hideSuppressedValue: boolean): string {
    if (hideSuppressedValue) {
        return ""
    }
    if (value < 0) {
        suppressValue *= -1;
    }

    let label = getFormattedDataLabel(suppressValue, decimalPlaces, DisplayUnits.Relative, locale, encloseNegativeValuesInParentheses, true, null, hideUnits);

    if (isAdditionalMeasure) {
        if (percentageFormat.includes("%")) {
            label += "%";
        }
    } else if (showPercentageInLabel) {
        label += "%";
    }

    return value > 0 ? `>${THIN_SPACE}${label}` : `<${THIN_SPACE}${label}`
}

export function getCommentMarkerAttributes(scaleBandWidth: number): CommentMarker {
    return {
        radius: Math.min(15, scaleBandWidth * 0.5),
        margin: Math.min(10, scaleBandWidth * 0.3),
        fontSize: Math.min(20, Math.round(scaleBandWidth / 2 + 3)),
    };
}

// tslint:disable-next-line: max-func-body-length
export function addCommentMarkers(container: d3.Selection<SVGElement, any, any, any>, commentDataPoints: DataPoint[],
    getMarkerHorizontalPosition: (d: DataPoint) => number, getMarkerVerticalPosition: (d: DataPoint) => number,
    markerRadius: number, markerFontSize: number, markerColor: string, markerFontFamily: string, viewModel: ViewModel, categoriesWidth: number, groupIndex: number, dataProperty: DataProperty = null) {
    // Remove data points with empty strings/comments
    commentDataPoints = commentDataPoints.filter(dp => dp.commentsValues.some(cv => cv))
    let commentMarkers = container
        .selectAll(COMMENT_MARKER)
        .data(commentDataPoints);

    let commentMarkerGroup = commentMarkers.enter()
        .append("g")
        .classed("comment-marker-group", true);

    let circles = commentMarkerGroup
        .append(CIRCLE)
        .classed(COMMENT_MARKER, true)
        .classed(HIGHLIGHTABLE, true);

    commentMarkers = commentMarkers.merge(circles)
        .attr(CX, d => getMarkerHorizontalPosition(d))
        .attr(CY, d => getMarkerVerticalPosition(d))
        .attr("r", markerRadius);
    commentMarkers.exit().remove();
    setCommentMarkerCircleStyle(commentMarkers, markerColor);

    let commentNumbers = commentMarkerGroup
        .append(TEXT)
        .classed(COMMENT_MARKER, true)
        .classed(HIGHLIGHTABLE, true)
        .text((d, i) => getCommentMarkerNumber(d, i, viewModel))
        .attr(X, d => getMarkerHorizontalPosition(d))
        .attr(Y, d => getMarkerVerticalPosition(d));

    setCommentMarkerNumberStyle(commentNumbers, markerColor, markerFontSize, markerFontFamily);
    let startx = 0;
    let offsetX = viewModel.settings.showFrozenCategories() ? categoriesWidth : 0;
    if (viewModel.settings.showCommentBox && viewModel.settings.commentBoxPlacement === CommentBoxPlacement.Left) {
        //TODO: offsetX += (Visual.hostWidth - Visual.width);
    }

    let addedDragAreaRects: d3.Selection<SVGRectElement, any, any, any>[] = [];
    let draggedCommentGroup: d3.Selection<Element, any, any, any> = undefined;
    let droppedAreaDataProperty: DataProperty = undefined;
    let initialDataProperty: DataProperty = undefined;
    let draggedCommentGroupBB = undefined;
    let fullCategory = undefined;
    let fullGroup = undefined;

    let draggable = d3.drag()
        .subject(function () {
            let t = d3.select(this);
            return { x: +t.attr(X), y: +t.attr(Y) };
        })
        .on(DRAGSTART, function () {
            Visual.disableHoverEvents = true;
            offsetX -= (<any>window).currentScrollLeft;
            draggedCommentGroup = d3.select(this);
            draggedCommentGroupBB = draggedCommentGroup.node().getBoundingClientRect();
            let dataPoint = <DataPoint>(<unknown>draggedCommentGroup.data()[0]);
            fullCategory = dataPoint.fullCategory;
            fullGroup = dataPoint.parent?.firstGroupParent?.fullGroup;
            initialDataProperty = dataPoint.commentMarkerDataProperty;
            if (initialDataProperty === null) {
                initialDataProperty = dataProperty;
            }
            startx = Number(draggedCommentGroup.select(TEXT).attr(X));

            let text = draggedCommentGroup.select(TEXT);
            let textHeight = Number((<any>text.node()).getBoundingClientRect().height);
            let textY = Number(text.attr(Y)) - textHeight / 2;
            let rectBackgrounds = d3.selectAll(`${G}.${REPORT_AREA} .${RECT_BACKGROUND}.${CHART_GROUP_INDEX}_${groupIndex}`);
            let chartAreas = d3.selectAll(`${G}.${REPORT_AREA} .${CHART_AREA}`);

            // move container (chartArea) to the last place to avoid Z-index issues caused by other chartAreas. 
            let containerNode = container.node();
            containerNode.parentElement.insertBefore(containerNode, containerNode.parentElement.lastChild);

            // sort chartAreas, because they might not be in order because of chartArea reorder
            let chartAreasNodes = chartAreas.nodes().sort((a, b) => +(<HTMLElement>a).getAttribute(CHART_ID) - +(<HTMLElement>b).getAttribute(CHART_ID));

            // Draw the drag areas
            rectBackgrounds.each(function (d, i) {
                let rectBackground = d3.select(this);
                let bb = (<HTMLElement>rectBackground.node()).getBoundingClientRect();
                let dragAreaRect = drawing.drawRectangle(container, bb.x - offsetX, textY, bb.width, draggedCommentGroupBB.height, COMMENT_DRAG_AREA, TRANSPARENT);
                setDragAreaRectsProperties(dragAreaRect, <DataProperty><any>(d3.select(chartAreasNodes[i]).attr(DATA_PROPERTY)), initialDataProperty);
                addedDragAreaRects.push(dragAreaRect);
            });
            commentMarkerGroup.style(CURSOR, GRABBING);
        })
        .on(DRAG, () => {
            let x = (<any>d3.event).sourceEvent.x;
            draggedCommentGroup.select(CIRCLE).attr(CX, x - offsetX);
            draggedCommentGroup.select(TEXT).attr(X, x - offsetX);
            let commentGroupBB = draggedCommentGroup.node().getBoundingClientRect();

            // Check for if the marker intersects any of the dragAreaRects
            addedDragAreaRects.forEach(dragArea => {
                let dragAreaBB = dragArea.node().getBoundingClientRect();
                if (commentGroupBB.x > dragAreaBB.x && commentGroupBB.x < dragAreaBB.x + dragAreaBB.width) {
                    droppedAreaDataProperty = Number(dragArea.attr(DATA_PROPERTY));
                    dragArea.attr(STROKE_DASHARRAY, "");
                }
                else {
                    dragArea.attr(STROKE_DASHARRAY, COMMENT_DRAG_AREA_STROKE_DASHARRAY);
                }
            });
        })
        .on(DRAGEND, () => {
            Visual.disableHoverEvents = false;
            commentMarkerGroup.style(CURSOR, GRAB);
            offsetX += (<any>window).currentScrollLeft;
            d3.selectAll(`.${COMMENT_DRAG_AREA}`).remove();
            if (droppedAreaDataProperty === initialDataProperty || droppedAreaDataProperty == null) {
                draggedCommentGroup.select(CIRCLE).transition().duration(100).attr(CX, startx);
                draggedCommentGroup.select(TEXT).transition().duration(100).attr(X, startx);
            }
            else {
                viewModel.settings.setCommentMarkersDataProperty(fullCategory, fullGroup, Number(droppedAreaDataProperty));
            }
        });

    commentMarkerGroup.style(CURSOR, GRAB);
    commentMarkerGroup.call(draggable)
        .on(MOUSEOVER, function () {
            d3.select(this).classed(COMMENT_MARKER_ACTIVE, true);
        }).on(MOUSELEAVE, function () {
            d3.select(this).classed(COMMENT_MARKER_ACTIVE, false);
        })
}

function setDragAreaRectsProperties(rect: d3.Selection<SVGRectElement, any, any, any>, dataProperty: DataProperty, initialDataProperty: DataProperty) {
    rect.attr(OPACITY, 0)
    rect.attr(STROKE_DASHARRAY, COMMENT_DRAG_AREA_STROKE_DASHARRAY)
    rect.attr(STROKE, COMMENT_DRAG_AREA_BLUE)
    rect.attr(STROKE_OPACITY, "0.6")
    rect.style(CURSOR, GRABBING);
    rect.attr(DATA_PROPERTY, dataProperty);
    rect.transition().duration(400).attr(OPACITY, 1);

    if (Number(dataProperty) === initialDataProperty) {
        rect.attr(STROKE_DASHARRAY, "");
    }
    else {
        rect.attr(STROKE_DASHARRAY, COMMENT_DRAG_AREA_STROKE_DASHARRAY);
    }
}

function setCommentMarkerNumberStyle(commentNumbers: d3.Selection<d3.BaseType, DataPoint, SVGElement, any>, color: string, fontSize: number, fontFamily: string) {
    commentNumbers
        .attr(STROKE_WIDTH, 2)
        .attr(FILL, color)
        .style(FONT_SIZE, fontSize + FONT_SIZE_UNIT)
        .style(FONT_FAMILY, fontFamily)
        .style(TEXT_ANCHOR, MIDDLE)
        .attr("dominant-baseline", "central");
}

function setCommentMarkerCircleStyle(commentCircles: d3.Selection<d3.BaseType, DataPoint, SVGElement, any>, color: string) {
    commentCircles
        .attr(STROKE, color)
        .attr(STROKE_WIDTH, 1.5)
        .attr(FILL, WHITE)
        .attr(FILL_OPACITY, 0.1);
}

function getCommentMarkerNumber(d: DataPoint, index: number, viewModel: ViewModel = null): number {
    let currentIndex;

    if (viewModel) {
        const commentMap: DataPoint[] = viewModel.getCommentsMap();

        let commentDataPoints = commentMap.filter(d => d.hasComment());

        // filter duplicates
        commentDataPoints = commentDataPoints.filter((dp, index, self) =>
            index === self.findIndex((d) => (
                d.fullCategory === dp.fullCategory && JSON.stringify(d.commentsValues) == JSON.stringify(dp.commentsValues)
            ))
        )

        commentDataPoints.forEach((dp, mapIndex) => {
            if (d.fullCategory === dp.fullCategory && JSON.stringify(d.commentsValues) == JSON.stringify(dp.commentsValues)) {
                currentIndex = mapIndex + 1;
            }
        });

    } else {
        currentIndex = Visual.commentAutoCurrentNumber++;
    }

    return d.commentsValues && d.commentsValues.length > 0 && !isNaN(+d.commentsValues[0]) ? +d.commentsValues[0] : currentIndex;
}
