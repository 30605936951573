import { DataView, DataViewMetadataColumn, DataViewMatrixNode } from '@zebrabi/matrix-data'
import { VarianceSettings } from "./settings/varianceSettings";
import { Visual } from "./visual";
import { mountReactTablesChooser } from "@zebrabi/tables-chooser/react";
import { persistManagerInstance } from "@zebrabi/office-settings/PersistManager";
import { KNOWLEDGE_BASE_URL, TEMPLATES_URL } from '@zebrabi/licensing/constants';
import { getOfficeSettings } from "@zebrabi/office-settings";
import { looksLikeCommentHeader, MeasureRoles, tryGetMeasureRoleFromName } from "@zebrabi/data-helpers/fieldAssignment";
import { getTablesMetadata } from "@zebrabi/data-helpers/tablesMetadata";
import { valuesTranslations } from '@zebrabi/data-helpers/translations';
import { getSampleDataRangeStartRowAndColumn, getExcelTable } from '@zebrabi/data-helpers/excelDataHelpers';
import { flagHandler } from '@zebrabi/zebrabi-core';
import { getMatrixColumnsChildren, getMatrixRowsRecursiveChildren, getMatrixRowsRecursiveChildrenForRange, parseRowHierarchyDataView } from '@zebrabi/data-helpers/hierarchyParsing';
import { DATA_SOURCE, DataSource, EMPTY_WORKSHEET_MSG, PARSING_ERROR_MSG } from '@zebrabi/data-helpers/editData';

export class DataHelper {
    private dataChangeHandlerReference: OfficeExtension.EventHandlerResult<Excel.BindingDataChangedEventArgs>;

    private storedDataSource: DataSource;

    constructor(public visual: Visual, public creationSettings: VarianceSettings) {
    }

    public async getDataView(): Promise<DataView> {
        if (Office.context.host === Office.HostType.PowerPoint) {
            return this.visual.dataView
        }
        if (Office.context.host === Office.HostType.Excel) {
            this.storedDataSource = this.getDataSource();
            if (!this.storedDataSource) {
                //check for empty sheet
                const isEmpty = await this.checkForEmptyWorksheet();
                if (isEmpty) {
                    mountReactTablesChooser();
                    return this.getEmptyDataView(this.getDataParsingErrorMsgHtml(true));
                }
            }
            return this.readExcelData();
        }
        throw new Error("Unsupported Office app")
    }

    public async checkForEmptyWorksheet(): Promise<boolean> {
        return Excel.run(async (context) => {
            const activeWorksheet = context.workbook.worksheets.getActiveWorksheet();
            const usedRangeOrNull = activeWorksheet.getUsedRangeOrNullObject(true);
            await context.sync();
            return usedRangeOrNull.isNullObject;
        }).catch((error) => {
            console.log("Excel.Run error: " + error);
            if (error instanceof OfficeExtension.Error) {
                console.log('Debug info: ' + JSON.stringify(error.debugInfo));
            }
            return true;
        });
    }

    public async writeDataViewToWorksheet() {
        await Excel.run(async (context) => {
            const activeWorksheet = context.workbook.worksheets.getActiveWorksheet();
            const dataView = this.visual.dataView;
            if (!dataView) {
                return;
            }

            let data = [];
            let columnCount = 0;
            const isCrossTables = dataView.metadata.columns.some(col => col.roles?.Group);
            if (isCrossTables) {
                this.visual.isChooserCrossTables = true;
                data.push(["Category", ...dataView.matrix.columns.root.children.map(c => c.value)]);
                dataView.matrix?.rows?.root?.children.forEach((c, i) => {
                    let rowData = [c.value];
                    dataView.matrix.columns.root.children.forEach((v, j) => {
                        rowData.push(c.values[j].value);
                    })
                    data.push(rowData);
                });
            }
            else {
                const categoryFields = dataView.rowFields?.length > 0 ? dataView.rowFields : ["Category"]
                data.push([...categoryFields, ...dataView.valueFields]);
                columnCount = data[0].length;

                dataView.matrix?.rows?.root?.children.forEach((c, i) => {
                    //     let rowData = [c.value];
                    //     dataView.valueFields.forEach((v, j) => {
                    //         rowData.push(c.values[j].value);
                    //     });
                    //     data.push(rowData);

                    // handle hierarchies (2 levels)
                    if (c.children) {
                        c.children.forEach((cc, j) => {
                            if (cc.children) {  // 3 levels
                                cc.children.forEach((ccc, k) => {
                                    let rowData = [c.value, cc.value, ccc.value];
                                    dataView.valueFields.forEach((v, l) => {
                                        rowData.push(ccc.values[l].value);
                                    });
                                    data.push(rowData);
                                });
                            }
                            else {
                                let rowData = [c.value, cc.value];
                                dataView.valueFields.forEach((v, k) => {
                                    rowData.push(cc.values[k].value);
                                });
                                data.push(rowData);
                            }
                        });
                    }
                    else {
                        let rowData = [c.value];
                        dataView.valueFields.forEach((v, j) => {
                            rowData.push(c.values[j].value);
                        });
                        //console.log(rowData);
                        // // handle totals in hierarchies for CopyPaste PoC, needs improvement
                        // if (rowData.length < columnCount) {
                        //     rowData.unshift(undefined);
                        // }
                        data.push(rowData);
                    }
                });
            }

            const { startRow, startColumn } = await getSampleDataRangeStartRowAndColumn(activeWorksheet, context);

            const rowCount = data.length; //dataView.matrix?.rows?.root?.children.length + 1;
            const colCount = isCrossTables ? dataView.matrix.columns.root.children.length + 1 : columnCount; //dataView.valueFields.length + categoryFields.length;

            const range = activeWorksheet.getRangeByIndexes(startRow, startColumn, rowCount, colCount);
            range.values = data;
            range.select();
            const table = activeWorksheet.tables.add(range, true);

            activeWorksheet.load({ id: true });
            table.load({ id: true, name: true });

            await context.sync();

            this.saveDataSource({ table: table.name, tableId: table.id, worksheetId: activeWorksheet.id });
        }).catch((error) => {
            console.log("Excel.Run error: " + error);
            if (error instanceof OfficeExtension.Error) {
                console.log('Debug info: ' + JSON.stringify(error.debugInfo));
            }
        });
    }

    private async readExcelData(): Promise<DataView> {
        return Excel.run(async (context) => {
            let activeWorksheet = context.workbook.worksheets.getActiveWorksheet();
            activeWorksheet.load({ id: true, name: true });
            await context.sync();

            // check for saved data source
            const storedDataSource = this.storedDataSource;
            if (storedDataSource) {
                // create existing add-in from saved data source
                if (storedDataSource.pivotTable) {
                    //console.log("inserted from stored pivot table")
                    const storedPivotTable = context.workbook.pivotTables.getItemOrNullObject(storedDataSource.pivotTable);
                    const ws = storedDataSource.worksheetId ? context.workbook.worksheets.getItemOrNullObject(storedDataSource.worksheetId) : null;
                    await context.sync();
                    if (!storedPivotTable.isNullObject) {
                        //const ptTest = context.workbook.worksheets.getItem(storedDataSource.worksheetId).pivotTables.getItem(storedDataSource.pivotTable)
                        //Hack: it looks like that for the multi level row hierarcihies pivot table has to be read from worksheet and not workbook
                        // TODO: check if this is still needed (might be related to ZVE-1139)
                        if (flagHandler.has("office-tables-hierarchy-parsing-refactor")) {
                            if (ws && !ws.isNullObject) {
                                const wsPivotTable = ws.pivotTables.getItemOrNullObject(storedDataSource.pivotTable);
                                await context.sync();
                                if (!wsPivotTable.isNullObject) {
                                    return await this.getPivotTableDataView(wsPivotTable, context, ws, false);
                                }
                            }
                            return await this.getPivotTableDataView(storedPivotTable, context, activeWorksheet, false);
                        } else {
                            const pivotTable = !ws || ws.isNullObject ? storedPivotTable : ws.pivotTables.getItem(storedDataSource.pivotTable);
                            return await this.getPivotTableDataView(pivotTable, context, activeWorksheet, false);
                        }
                    }
                    return this.getEmptyDataView(this.getDataParsingErrorMsgHtml() + " Error details: pivot table used to create this add-in not found: " + storedDataSource.pivotTable)
                }

                if (storedDataSource.worksheetId && storedDataSource.worksheetId !== activeWorksheet.id) {
                    const dataSourceWs = context.workbook.worksheets.getItemOrNullObject(storedDataSource.worksheetId);
                    await context.sync();
                    if (!dataSourceWs.isNullObject) {
                        activeWorksheet = dataSourceWs;
                    }
                }
                else if (!storedDataSource.worksheetId && storedDataSource.range) {
                    const storedWsName = storedDataSource.range.split("!", 2)[0];
                    if (storedDataSource.range && activeWorksheet.name !== storedWsName) {
                        activeWorksheet = context.workbook.worksheets.getItemOrNullObject(storedWsName);
                    }
                }

                let sourceDataRange = await this.getStoredDataSourceRange(context, activeWorksheet, storedDataSource);
                if (sourceDataRange) {
                    let rangeDataView = await this.getDataViewFromRange(context, sourceDataRange);
                    return rangeDataView ? rangeDataView : this.getEmptyDataView(this.getDataParsingErrorMsgHtml() + " Error details: error reading data from saved data source " + JSON.stringify(storedDataSource));
                }
                return this.getEmptyDataView(this.getDataParsingErrorMsgHtml() + " Error details: could not parse " + JSON.stringify(storedDataSource));
            }

            // inserting a new add-in
            const selectedRange = await this.getSelectedRange(context);

            if (selectedRange === null) {
                return await this.getEmptySelectionDataView(context, activeWorksheet, null);
            }
            else {
                const pivotTables = selectedRange.getPivotTables();
                const pivotTablesCount = pivotTables.getCount();
                await context.sync();
                if (pivotTablesCount.value > 0) {
                    return await this.getPivotTableDataView(pivotTables.getFirst(), context, activeWorksheet, true);
                }

                const tables = selectedRange.getTables();
                const tablesCount = tables.getCount();
                await context.sync();
                if (tablesCount.value > 0) {
                    const table = tables.getFirst();
                    return await this.getExcelTableDataView(table, context, activeWorksheet, true);
                }

                // insert from suitable Range
                selectedRange.load({ values: true });
                await context.sync();
                const isEmptyCellSelected = selectedRange.values[0][0] === "" && selectedRange.cellCount === 1;

                if (isEmptyCellSelected) {
                    return await this.getEmptySelectionDataView(context, activeWorksheet, selectedRange);
                }
                else {
                    if (selectedRange.cellCount < 6) {
                        // try get surrounding range
                        const surroundingRange = await this.getSurroundingNonBlankRange(context, activeWorksheet, selectedRange);
                        if (surroundingRange) {
                            let rangeDataView = await this.getDataViewFromRange(context, surroundingRange);
                            if (rangeDataView) {
                                this.saveDataSource({ range: surroundingRange.address, worksheetId: activeWorksheet.id });
                                return rangeDataView;
                            }
                        }
                    }
                    else {
                        // use selected range
                        const rangeDataView = await this.getDataViewFromRange(context, selectedRange);
                        if (rangeDataView) {
                            this.saveDataSource({ range: selectedRange.address, worksheetId: activeWorksheet.id });
                            return rangeDataView;
                        }
                    }

                    return this.getEmptyDataView(this.getDataParsingErrorMsgHtml() + " Error details: error reading data from selected range");
                }
            }

        }).catch((error) => {
            console.log("readData Excel.Run error: " + error);
            if (error instanceof OfficeExtension.Error) {
                console.log('Debug info: ' + JSON.stringify(error.debugInfo));
            }
            return null;
        });
    }

    private getDataSource(): DataSource {
        return <DataSource>getOfficeSettings(DATA_SOURCE);
    }

    private saveDataSource(dataSource: DataSource) {
        Office.context.document.settings.set(DATA_SOURCE, dataSource);
        persistManagerInstance.update({
            objectName: DATA_SOURCE,
            properties: dataSource
        });
    }

    private async getEmptySelectionDataView(context: Excel.RequestContext, activeWorksheet: Excel.Worksheet, selectedRange: Excel.Range): Promise<DataView> {
        const tableCount = activeWorksheet.tables.getCount();
        const pivotTableCount = activeWorksheet.pivotTables.getCount();
        const pivotTablesCollection = activeWorksheet.pivotTables.load({ id: true, name: true });
        const tableCollection = activeWorksheet.tables;
        await context.sync();

        if (pivotTableCount.value > 0) {
            const pivotTable = pivotTablesCollection.items[0];
            return await this.getPivotTableDataView(pivotTable, context, activeWorksheet, true);
        }
        else if (tableCount.value > 0) {
            const table = tableCollection.getItemAt(0);
            return await this.getExcelTableDataView(table, context, activeWorksheet, true);
        }
        else {
            // try to use used range otherwise show chooser
            if (selectedRange) {
                const surroundingRange = await this.getSurroundingNonBlankRange(context, activeWorksheet, selectedRange);
                if (surroundingRange) {
                    let rangeDataView = await this.getDataViewFromRange(context, surroundingRange);
                    if (rangeDataView) {
                        this.saveDataSource({ range: surroundingRange.address, worksheetId: activeWorksheet.id });
                        return rangeDataView;
                    }
                }
            }

            const usedRange = activeWorksheet.getUsedRangeOrNullObject(true)
            await context.sync();
            if (!usedRange.isNullObject) {
                let rangeDataView = await this.getDataViewFromRange(context, usedRange);
                if (rangeDataView) {
                    this.saveDataSource({ range: usedRange.address, worksheetId: activeWorksheet.id });
                    return rangeDataView;
                }
            }

            mountReactTablesChooser();
            return this.getEmptyDataView(this.getDataParsingErrorMsgHtml(true) + " Error details: no suitable data source found.");
        }
    }

    private async getExcelTableDataView(table: Excel.Table, context: Excel.RequestContext, activeWorksheet: Excel.Worksheet, saveToSettings: boolean): Promise<DataView> {
        table.load({ id: true, name: true });
        await context.sync();

        let rangeDataView = await this.getDataViewFromRange(context, table.getRange());
        if (rangeDataView && saveToSettings) {
            this.saveDataSource({ table: table.name, tableId: table.id, worksheetId: activeWorksheet.id });
            return rangeDataView;
        }
        return this.getEmptyDataView(this.getDataParsingErrorMsgHtml() + " Error details: could not parse Excel Table " + table.name);
    }

    private async checkForValidFieldsConfiguration(pivotTable: Excel.PivotTable, dataView: DataView): Promise<boolean> {
        const rowHierarcyhiesCount = pivotTable.rowHierarchies.getCount();
        const colHierarcyhiesCount = pivotTable.columnHierarchies.getCount();
        const dataHierarcyhiesCount = pivotTable.dataHierarchies.getCount();

        await pivotTable.context.sync()
        let isValid = true;
        if (rowHierarcyhiesCount.value === 0 || dataHierarcyhiesCount.value === 0) {
            dataView.errorMessage = "Not enough fields. Please have at least one field in rows and one in values.";
            isValid = false;
        }
        else if (colHierarcyhiesCount.value > 2) {  //empty columns has one hidden "column" Values
            dataView.errorMessage = "Too many fields. Please have no more than one field in columns.";
            isValid = false;
        }

        return isValid;
    }

    /* https://docs.microsoft.com/en-us/office/dev/add-ins/excel/excel-add-ins-pivottables#pivottable-layouts-and-getting-pivoted-data */
    private async getPivotTableDataView(pivotTable: Excel.PivotTable, context: Excel.RequestContext, activeWorksheet: Excel.Worksheet, saveToSettings: boolean) {
        let dataView = this.getEmptyDataView("");
        //Change pivot table layout to tabular.
        try {
            const bodyFullRange = pivotTable.layout.getRange();
            /* add onChangeEvent  */
            this.addDataChangeHandler(context, bodyFullRange, Excel.BindingType.range, "tablesPivotTableDataChangeEvent");

            const isValidConfiguration = await this.checkForValidFieldsConfiguration(pivotTable, dataView);
            if (!isValidConfiguration) {
                return dataView;
            }

            const rowHierarchies = pivotTable.rowHierarchies;
            const columnHierarchies = pivotTable.columnHierarchies;
            const dataHierarchies = pivotTable.dataHierarchies;

            pivotTable.load({ name: true });
            pivotTable.layout.load({ showRowGrandTotals: true, showColumnGrandTotals: true, subtotalLocation: true });

            //// loading a items/fields crashes the Excel in some cases so we (for now?) use only items/name for fields detection
            //todo: check if we can use {field:PivotFieldLoadOptions}
            // rowHierarchies.load("items/name, items/fields");
            // columnHierarchies.load("items/name, items/fields");
            // dataHierarchies.load("items/name, items/field"); // items/numberFormat, items/position, items/showAs
            rowHierarchies.load({ id: true, name: true });
            columnHierarchies.load({ id: true, name: true });
            dataHierarchies.load({ id: true, name: true }); // items/numberFormat, items/position, items/showAs

            await context.sync();

            //let columnFields = columnHierarchies.items.map(item => item.name).filter(c => c !== "Values"); //columnHierarchies.items.filter(item => item.fields.items.length > 0).map(item => item.fields.items[0]);
            //let columnFields = columnHierarchies.items.filter(item => item.id !== "Values").map(c => c.name);
            const valuesCol = columnHierarchies.items.find(item => valuesTranslations.includes(item.id));
            let columnFields = columnHierarchies.items.filter(item => item.id !== valuesCol?.id).map(c => c.name); // "Values" column is filtered out since it is not used as Grouping column
            //let columnFields = columnHierarchies.items.filter(item => !valuesTranslations.includes(item.id)).map(c => c.name); // "Values" column is filtered out since it is not used as Grouping column

            let rowFields = rowHierarchies.items.map(item => item.name);
            let valueFields = dataHierarchies.items.map(hier => hier.name);

            dataView = {
                metadata: { columns: [] },
                matrix: null,
                rowFields: rowFields,
                columnFields: columnFields,
                valueFields: valueFields,
                rowGrandTotal: pivotTable.layout.showRowGrandTotals,    // adds grand total columns, used for column parsing
                columnGrandTotal: pivotTable.layout.showColumnGrandTotals,  // adds grand total row, used for row parsing
                rowNames: rowFields,//.map(f => f.name), //rowNames,
                columnNames: columnFields,//.map(f => f.name), //columnNames,
                valueNames: valueFields//.map(f => f.name) //valueNames
            };

            // Fill the DataView metadata property
            getTablesMetadata(dataView, true, false);

            if (dataView.valueFields.length < 1 || dataView.rowFields.length < 1) {
                dataView.errorMessage = "Invalid data fields configuration or empty data";
                return dataView;
            }

            //// this code block is commented out because loading a layout type crashes the Excel randomly, we should be able to re-use it when this bug is fixed by MSFT
            // if (dataView.rowFields.length > 1) {    // switch to tabular layout if we have rows hierarchies
            //     // Retrieve the type and string representation of the data source of the PivotTable.
            //     let isLocalTable = null;
            //     if (Office.context.requirements.isSetSupported("ExcelApi", "1.15")) {
            //         const pivotTableDataSourceType = pivotTable.getDataSourceType();
            //         //const pivotTableDataSourceString = pivotTable.getDataSourceString();
            //         await context.sync();
            //         const pivotTableDataSourceTypeValue = pivotTableDataSourceType.value;
            //         isLocalTable = pivotTableDataSourceTypeValue === Excel.DataSourceType.localRange || pivotTableDataSourceTypeValue === Excel.DataSourceType.localTable;
            //     }

            //     if (isLocalTable) {
            //         //console.log("layoutType code")
            //         // pivotTable.layout.load("layoutType");
            //         // await context.sync();
            //         // if (pivotTable.layout.layoutType !== "Tabular") {
            //         //     dataView.errorMessage = "An error occured while reading pivot table data. Please use 'Tabular' pivot table layout if possible."
            //         //     pivotTable.layout.layoutType = "Tabular";
            //         //     await context.sync();
            //         //     dataView.errorMessage = "";
            //         // }
            //     }
            // }

            const dataBodyRange = pivotTable.layout.getDataBodyRange();
            const rowLabelsRange = pivotTable.layout.getRowLabelRange();
            const columnLabelsRange = pivotTable.layout.getColumnLabelRange();
            dataBodyRange.load({ values: true });
            rowLabelsRange.load({ values: true });
            columnLabelsRange.load({ values: true });

            rowLabelsRange.load({ valueTypes: true, rowCount: true, columnCount: true, numberFormat: true, numberFormatCategories: true })

            await context.sync();
            // Get cells (data) of different parts of pivot table.
            let dataValues = dataBodyRange.values;
            let rowLabelsValues = rowLabelsRange?.values || [];
            let columnLabelsValues = columnLabelsRange.values;

            this.checkRowsLabelsDataTypeForDates(rowLabelsRange, rowLabelsValues);

            // Parse data and construct dataview 
            if (dataView.valueFields.length < 1 || dataView.rowFields.length < 1) {
                dataView.errorMessage = "Not enough fields. Please have at least one field in rows and one in values.";
            }

            if (dataView.valueFields.length < 20) {
                if (dataView.columnFields.length < 2) {
                    parseRowHierarchyDataView(dataView, rowLabelsValues, columnLabelsValues, dataValues, pivotTable.layout); // check
                } else {
                    dataView.errorMessage = "Too many column (group) fields.";
                }
            }
            else {
                dataView.errorMessage = "Too many value fields.";
            }

            if (saveToSettings) {
                this.saveDataSource({ pivotTable: pivotTable.name, worksheetId: activeWorksheet.id });
            }
        }
        catch (error) {
            console.log(error)
            dataView.errorMessage = "pivot table data could not be parsed correctly";
        }

        return dataView;
    }

    private addDataChangeHandler(context: Excel.RequestContext, range: Excel.Range, bindingType: Excel.BindingType, id: string) {
        const binding = context.workbook.bindings.add(range, bindingType, id);
        if (!this.dataChangeHandlerReference) {
            this.dataChangeHandlerReference = binding.onDataChanged.add(this.dataChangedEventHandler.bind(this));
        }
    }

    private checkRowsLabelsDataTypeForDates(rowLabelsRange: Excel.Range, rowLabelsValues: any[][]) {
        for (let i = 0; i < rowLabelsRange.rowCount; i++) {
            for (let j = 0; j < rowLabelsRange.columnCount; j++) {
                const valueType = rowLabelsRange.valueTypes[i][j];
                const numberFormatCategory = rowLabelsRange.numberFormatCategories[i][j];

                if ((valueType === Excel.RangeValueType.double || valueType === Excel.RangeValueType.integer)
                    && (numberFormatCategory === Excel.NumberFormatCategory.custom || numberFormatCategory === Excel.NumberFormatCategory.date)) {
                    const val = rowLabelsValues[i][j];
                    rowLabelsValues[i][j] = new Date(Date.UTC(0, 0, val - 1)).toLocaleDateString();
                }
            }
        }
    }

    private getEmptyDataView(msg: string): DataView {
        return {
            matrix: null,
            rowFields: null,
            columnFields: null,
            valueFields: null,
            rowGrandTotal: null,
            columnGrandTotal: null,
            errorMessage: msg ? msg : "No pivot table found.",
            rowNames: null,
            columnNames: null,
            valueNames: null
        };
    }

    /* turns a 2x8 array into an 8x2 */
    public transposeArray(source: any[][]): Array<[][]> {
        return source[0].map((_, c) => source.map(r => r[c]))
    }

    private async dataChangedEventHandler(event: Excel.BindingDataChangedEventArgs) {
        await Excel.run(async (context) => {
            await context.sync();
            if (this.dataChangeHandlerReference) {
                this.dataChangeHandlerReference.remove();
                await this.dataChangeHandlerReference.context.sync();
                this.dataChangeHandlerReference = null;
            }

            const binding = context.workbook.bindings.getItemOrNullObject(event.binding.id);
            await context.sync();
            // update stored range if it has changed, this handles inserts/deletes of Excel rows/columns
            if (!binding.isNullObject) {
                this.storedDataSource = this.getDataSource();
                if (this.storedDataSource?.range) {
                    const bindingRange = binding.getRange();
                    bindingRange.load({ address: true });
                    await context.sync();
                    const boundRangeAddress = bindingRange.address;

                    if (boundRangeAddress !== this.storedDataSource.range) {
                        this.storedDataSource.range = boundRangeAddress;
                        this.saveDataSource(this.storedDataSource);
                    }
                }
                binding.delete();
            }

            await context.sync();
            this.visual.update(Visual.visualSettings);

            return context.sync();
        }).catch((error) => {
            console.log("dataChangedEvent error: " + error);
            if (error instanceof OfficeExtension.Error) {
                console.log('Debug info: ' + JSON.stringify(error.debugInfo));
            }
        });
    }

    private async getStoredDataSourceRange(context: Excel.RequestContext, activeWorksheet: Excel.Worksheet, dataSource: DataSource): Promise<Excel.Range> {
        let range: Excel.Range = null;
        try {
            if (dataSource && dataSource.table) {
                range = (await getExcelTable(context, activeWorksheet, dataSource.table, dataSource.tableId)).getRange();
                await context.sync();
            }
            else if (dataSource && dataSource.range) {
                const savedSourceAddress: string = dataSource.range.toString();
                const savedWsRange = savedSourceAddress.split("!", 2)
                // use only range address without worksheet name
                const sourceRange = savedWsRange.length === 2 ? savedWsRange[1] : dataSource.range;
                // read data from stored range
                range = activeWorksheet.getRange(sourceRange);
                await context.sync();
            }

            return range;
        }
        catch (error) {
            console.log(error)
            return null;
        }
    }

    private async getSelectedRange(context: Excel.RequestContext): Promise<Excel.Range> {
        let selectedRange: Excel.Range = null;
        try {
            selectedRange = context.workbook.getSelectedRange();
            selectedRange.load({ address: true, cellCount: true });
            //TODO: check if selectedRange.worksheet === activeWorksheet ?
            await context.sync();
        }
        catch (e) {
            console.log("error parsing Selected range:", e);
            selectedRange = null;
        }
        return selectedRange;
    }

    private async getSurroundingNonBlankRange(context: Excel.RequestContext, activeWorksheet: Excel.Worksheet, selectedCell: Excel.Range): Promise<Excel.Range> {
        try {
            const usedRange = activeWorksheet.getUsedRangeOrNullObject(true)
            await context.sync();
            if (usedRange.isNullObject) {
                return null;
            }

            const range = selectedCell.getSurroundingRegion();
            range.load({ address: true });
            await context.sync();
            range.select();
            return range;
        }
        catch (e) {
            console.log("error parsing Selected range:", e);
            return null;
        }
    }

    private getNumberOfCategoryColumns(data: Excel.Range): number {
        const columnCount = data.columnCount;
        const rowCount = data.rowCount;

        //console.log(data.valueTypes)
        let numberOfTextColumns = 0;
        for (let i = 0; i < columnCount; i++) {
            const colValues = data.values.map((row) => row[i]);
            //colValues.forEach((row) => console.log(row, typeof row))

            const isStringCol = colValues.every((row) => {
                const cell = row;
                return typeof cell === "string";
            });

            const colValuesTypes = data.valueTypes.map((row) => row[i]);
            const isStringTypeCol = colValuesTypes.every((row) => {
                const cellType = row;
                return cellType === Excel.RangeValueType.string || cellType === Excel.RangeValueType.empty;
            });

            // use isStringTypeCol??
            //console.log(isStringCol, isStringTypeCol)
            if (isStringCol) {
                numberOfTextColumns++;
            }
            else {
                break;
            }
        }

        return numberOfTextColumns;
    }

    private async getDataViewFromRange(context: Excel.RequestContext, sourceRange: Excel.Range): Promise<DataView> {
        try {
            const range = sourceRange;
            range.load({ address: true, cellCount: true, columnCount: true, rowCount: true, values: true, valueTypes: true });

            const firstRow = range.getRow(0);
            firstRow.load({ values: true });

            await context.sync();

            const categoryColumnsCount = this.getNumberOfCategoryColumns(range);
            const columnHeaders: string[] = firstRow.values[0].map((v: any) => v?.toString().trim() ?? "");

            const categoryColumnsHeaders = columnHeaders
                .filter((h, i) => i < categoryColumnsCount);
            const measureColumnsHeaders = columnHeaders
                .filter((h, i) => i >= categoryColumnsCount);

            const categoryLabelsRange = range.getAbsoluteResizedRange(range.rowCount, categoryColumnsCount);
            categoryLabelsRange.load({ columnCount: true, numberFormatCategories: true, rowCount: true, values: true, valueTypes: true });

            await context.sync();

            this.checkRowsLabelsDataTypeForDates(categoryLabelsRange, categoryLabelsRange.values);

            const categoryLabelsValues = categoryLabelsRange.values;
            categoryLabelsValues.shift();    // remove first row (column headers)

            const categoryLabels: string[][] = [];
            if (categoryColumnsCount > 1) {
                this.handleRepeatedCategories(categoryLabelsValues, categoryColumnsCount, categoryLabels);
            }

            const categoryFields = categoryColumnsHeaders.length === 0 || categoryColumnsHeaders.length === 1 && categoryColumnsHeaders[0] === "" ? ["Category"] : categoryColumnsHeaders;

            let measureCount = measureColumnsHeaders.length;
            const hasCommentColumn = measureColumnsHeaders.some(c => looksLikeCommentHeader(c)) || this.hasCommentsDataCol(range.valueTypes)
            if (hasCommentColumn) {
                measureCount--;
            }

            let isCrossTablesData = false;
            // migrate existing cross tables
            if (this.visual.isChooserCrossTables || this.creationSettings !== null && this.creationSettings?.showCrossTables === undefined && measureCount > 4) {
                if (this.creationSettings) {
                    this.creationSettings.showCrossTables = true;
                }
                isCrossTablesData = true;
            }
            else if (this.creationSettings !== null && this.creationSettings?.showCrossTables) {
                isCrossTablesData = true;
            }

            let columnFields = [];
            let valueFields = measureColumnsHeaders;

            if (isCrossTablesData) { // parse cross tables
                valueFields = ["Values"];
                columnFields = ["Group"];
                isCrossTablesData = true;
            }

            const dataView: DataView = {
                metadata: { columns: [] },
                matrix: null,
                rowFields: categoryFields,
                columnFields: columnFields,
                valueFields: <any>valueFields,
                rowGrandTotal: false,
                columnGrandTotal: false,
                rowNames: this.creationSettings === null || categoryFields.length > 1 ? categoryFields : [],    // keep the previous incorrect behavior for existing add-ins so that the formaulas are not lost
                columnNames: columnFields,
                valueNames: valueFields
            };

            if (range.cellCount < 3) {
                dataView.errorMessage = this.getDataParsingErrorMsgHtml();
                return dataView;
            }

            getTablesMetadata(dataView, false, hasCommentColumn);

            const dataValuesRange = range.getOffsetRange(1, categoryColumnsCount).getResizedRange(-1, -categoryColumnsCount);
            dataValuesRange.load({ values: true, valueTypes: true });
            await context.sync();

            this.parseRangeDataView(dataView, categoryLabels?.length > 0 ? categoryLabels : categoryLabelsValues, <any>measureColumnsHeaders, dataValuesRange.values, dataValuesRange);

            this.addDataChangeHandler(context, range, Excel.BindingType.range, "tablesRangeChanged")

            return dataView;
        } catch (error) {
            console.log("range parsing error")
            console.error(error);
            return null;
        }
    }

    private handleRepeatedCategories(categoryLabelsValues: any[][], categoryColumnsCount: number, categoryLabels: string[][]): string[][] {
        if (categoryLabelsValues[0][0] === categoryLabelsValues[1][0]) {
            // remove repeated hierarchy categories
            let currentHierarchy: string[] = [];

            categoryLabelsValues.forEach((row) => {
                let rowLabels: string[] = [];
                for (let i = 0; i < categoryColumnsCount; i++) {
                    const cell = row[i];
                    if (currentHierarchy?.[i] === cell) {
                        rowLabels.push("");
                    }
                    else {
                        rowLabels.push(cell);
                        if (currentHierarchy?.[i] !== undefined) {
                            currentHierarchy[i] = cell;
                        }
                        else {
                            currentHierarchy.push(cell);
                        }
                    }
                }
                categoryLabels.push(rowLabels);
            });
        }
        return categoryLabels;
    }

    hasCommentsDataCol(valueTypes: Excel.RangeValueType[][]): boolean {
        if (!valueTypes || valueTypes[0]?.length < 2) {
            return false;
        }

        let hasCommentsCol = false;
        // iterate 2. to last col
        for (let j = 1; j < valueTypes[0].length; j++) {
            const colValueTypes = valueTypes.map(t => t[j]);
            colValueTypes.shift();
            let isTextCol = colValueTypes.every(t => t === Excel.RangeValueType.error || t === Excel.RangeValueType.empty || t === Excel.RangeValueType.string);
            if (isTextCol) {
                hasCommentsCol = true;
                break;
            }
        }
        return hasCommentsCol;
    }

    parseRangeDataView(dataView: DataView, rowLabelsValues: any[][], columnLabelsValues: any[][], dataValues: any[][], range: Excel.Range): DataView {

        dataView.matrix = { rows: null, columns: null, valueSources: [] };
        //rows
        let categoryColumns = dataView.metadata.columns.filter(f => f.roles["Category"]);
        let commentColumns = dataView.metadata.columns.filter(f => f.roles[MeasureRoles.Comments]);
        const commentsIndex = commentColumns.length > 0 ? commentColumns[0].index : null;

        dataView.matrix.rows = {
            levels: categoryColumns.map(cc => {
                return { sources: [cc] }
            }),
            root: {
                children: flagHandler.has("office-tables-hierarchy-parsing-refactor") ?
                    getMatrixRowsRecursiveChildrenForRange(0, rowLabelsValues.length - 1, 0, rowLabelsValues, dataValues, range) :
                    getMatrixRowsRecursiveChildren(0, rowLabelsValues.length - 1, 0, rowLabelsValues, dataValues, null, true, commentColumns?.length > 0, range, commentsIndex)
            }
        };

        //columns
        let valueColumns = dataView.metadata.columns.filter(f => f.roles[MeasureRoles.Values] || f.roles[MeasureRoles.PreviousYear] || f.roles[MeasureRoles.Plan] || f.roles[MeasureRoles.Forecast] || f.roles[MeasureRoles.Comments]);
        let groupColumns = dataView.metadata.columns.filter(f => f.roles["Group"]);
        let levels = [];
        if (groupColumns.length) {
            groupColumns.forEach(gc => {
                levels.push({ sources: [gc] })
            });
        }
        if (!levels.length || valueColumns.length > 1) {
            levels.push({ sources: valueColumns })
        }
        dataView.matrix.columns = {
            levels: levels,
            root: {
                children: getMatrixColumnsChildren(valueColumns, groupColumns, columnLabelsValues, dataView.rowGrandTotal)
            }
        }

        dataView.matrix.valueSources = valueColumns;

        return dataView;
    }

    private getDataParsingErrorMsgHtml(isEmptyWorksheet: boolean = false): string {
        // https://zebrabi.com/templates/
        // https://help.zebrabi.com/excel/preparing-data/
        let msg = isEmptyWorksheet ? EMPTY_WORKSHEET_MSG : PARSING_ERROR_MSG;
        if (isEmptyWorksheet) {
            msg += "<br> If it does not, please try to <a href='#' onclick='window.location.reload();'>reload your add-in.</a><br>"
        }
        let kbUrl = KNOWLEDGE_BASE_URL.replace("$host", Office.context.host === Office.HostType.Excel ? "excel" : "powerpoint").replace("$visual", "tables");
        let templateUrl = TEMPLATES_URL.replace("$host", Office.context.host === Office.HostType.Excel ? "excel" : "powerpoint").replace("$visual", "tables");
        return `<p>${msg} For more help on data preparation visit our <a href='${kbUrl}' target='_blank' id='error-msg-kb-url'>knowledge base</a>  or download a <a href='${templateUrl}' target='_blank' id='error-msg-template-url'>template file</a>.</p>`;
    }

    private isValidNonNullNumber(value: any) {
        return value !== null && value !== "" && typeof value === "number"; // Number(value) // //!isNaN(value)
    }

    /** Default helper for invoking an action and handling errors. */
    async tryCatch(callback) {
        try {
            await callback();
        }
        catch (error) {
            // Note: In a production add-in, you'd want to notify the user through your add-in's UI.
            console.error(error);
        }
    }
}

