import { calculationCategories } from "./calculation/calculationCategories";
import { comparisonCategories } from "./comparison/comparisonCategories";
import { crossCategories } from "./cross/crossCategories";
import { structureCategories } from "./structure/structureCategories";
import { hierarchyCategories } from "./hierarchy/hierarchyCategories";

export const categories = {
  structure: structureCategories,
  comparison: comparisonCategories,
  cross: crossCategories,
  calculation: calculationCategories,
  hierarchy: hierarchyCategories
};

export const tablesMap: any = {
  ...getTablesSettingsMapFromDimensionCategories(structureCategories),
  ...getTablesSettingsMapFromDimensionCategories(comparisonCategories),
  ...getTablesSettingsMapFromDimensionCategories(crossCategories),
  ...getTablesSettingsMapFromDimensionCategories(calculationCategories),
  ...getTablesSettingsMapFromDimensionCategories(hierarchyCategories)
};

function getCategoryTablesSettingsMap(categoryTables: any) {
  return categoryTables
    .map((table) => ({
      [table.id]: { ...table },
    }))
    .reduce((prev: any, curr: any) => ({ ...prev, ...curr }));
}

function getTablesSettingsMapFromDimensionCategories(dimensionCategories: any) {
  return dimensionCategories
    .map((cat: any) => getCategoryTablesSettingsMap(cat.tables))
    .reduce((prev: any, curr: any) => ({ ...prev, ...curr }));
}
