import { VerticalPlotter } from "./verticalPlotter";
import { ViewModel } from "../../settings/viewModel";
import { PlotDefinition, ChartType } from "../../definitions";
import { DataProperty, Scenario } from "../../library/constants";

export class VerticalIntegratedPlotter extends VerticalPlotter {
    constructor(viewModel: ViewModel, width: number, height: number, reportArea: d3.Selection<SVGElement, any, any, any>, svg: d3.Selection<SVGElement, any, any, any>,
        locale: string) {
        super(viewModel, width, height, reportArea, svg, locale);
    }

    protected getPlotDefinitions(): PlotDefinition[] {
        let plotDefinition = this.getBlankPlotDefinition(1);
        let chartPlotDefinitions: PlotDefinition[] = [];
        chartPlotDefinitions.push(plotDefinition);
        if (this.settings.groupsInColumns) {
            this.addChartDefinition(plotDefinition, ChartType.ValueChart, DataProperty.Value, DataProperty.ReferenceValue, true, true);
        }
        else {
            if (this.viewModel.hasHierarchy) {
                let firstTableProperty = this.viewModel.reference.scenario === Scenario.PreviousYear ? DataProperty.ReferenceValue : DataProperty.Value;
                let secondTableProperty = this.viewModel.reference.scenario === Scenario.PreviousYear ? DataProperty.Value : DataProperty.ReferenceValue;
                this.addChartDefinition(plotDefinition, ChartType.Table, firstTableProperty, DataProperty.ReferenceValue, false, false);
                this.addChartDefinition(plotDefinition, ChartType.Table, secondTableProperty, DataProperty.ReferenceValue, false, false);
                if (this.viewModel.HasSecondReferenceColumn) {
                    this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.SecondReferenceValue, DataProperty.ReferenceValue, false, false);
                }
                if (this.viewModel.HasThirdReferenceColumn) {
                    this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.ThirdReferenceValue, DataProperty.ReferenceValue, false, false);
                }
                if (this.viewModel.HasFourthReferenceColumn) {
                    this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.FourthReferenceValue, DataProperty.ReferenceValue, false, false);
                }
                if (this.viewModel.HasFifthReferenceColumn) {
                    this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.FifthReferenceValue, DataProperty.ReferenceValue, false, false);
                }
                if (this.viewModel.HasSixthReferenceColumn) {
                    this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.SixthReferenceValue, DataProperty.ReferenceValue, false, false);
                }
                if (this.viewModel.HasSeventhReferenceColumn) {
                    this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.SeventhReferenceValue, DataProperty.ReferenceValue, false, false);
                }
                chartPlotDefinitions.push(plotDefinition);

                plotDefinition = this.getBlankPlotDefinition(1);
                this.addChartDefinition(plotDefinition, ChartType.Table, firstTableProperty, DataProperty.ReferenceValue, false, false);
                this.addChartDefinition(plotDefinition, ChartType.Table, secondTableProperty, DataProperty.ReferenceValue, false, false);
                chartPlotDefinitions.push(plotDefinition);

                plotDefinition = this.getBlankPlotDefinition(1);
                this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.Value, DataProperty.ReferenceValue, false, false);
                chartPlotDefinitions.push(plotDefinition);
            }
            else {
                this.addChartDefinition(plotDefinition, ChartType.ValueChart, DataProperty.Value, DataProperty.ReferenceValue, true, true);
                if (this.viewModel.HasSecondReferenceColumn) {
                    this.addChartDefinition(plotDefinition, ChartType.AbsoluteChart, DataProperty.SecondAbsoluteDifference, null, false, false);
                }
                if (this.viewModel.HasThirdReferenceColumn) {
                    this.addChartDefinition(plotDefinition, ChartType.AbsoluteChart, DataProperty.ThirdAbsoluteDifference, null, false, false);
                }
                if (this.viewModel.HasFourthReferenceColumn) {
                    this.addChartDefinition(plotDefinition, ChartType.AbsoluteChart, DataProperty.FourthAbsoluteDifference, null, false, false);
                }
                if (this.viewModel.HasFifthReferenceColumn) {
                    this.addChartDefinition(plotDefinition, ChartType.AbsoluteChart, DataProperty.FifthAbsoluteDifference, null, false, false);
                }
                if (this.viewModel.HasSixthReferenceColumn) {
                    this.addChartDefinition(plotDefinition, ChartType.AbsoluteChart, DataProperty.SixthAbsoluteDifference, null, false, false);
                }
                if (this.viewModel.HasSeventhReferenceColumn) {
                    this.addChartDefinition(plotDefinition, ChartType.AbsoluteChart, DataProperty.SeventhAbsoluteDifference, null, false, false);
                }
            }
        }
        return chartPlotDefinitions;
    }

}