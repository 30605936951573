import React from "react";
import { MainDimension } from "@zebrabi/chart-chooser/react/charts/dimensions/types";

type Props = {
  dimension: MainDimension;
  isActive?: boolean;
  onActive: (dimension: MainDimension) => void;
};

export default function TablesDimensionItem({ dimension, isActive = false, onActive }: Props) {
  const handleActive = () => onActive(dimension);

  return (
    <div
      className={`dimension-item cursor-pointer rounded-sm mx-3.5 relative ${isActive ? "active-card" : ""}`}
      onClick={handleActive}
    >
      {isActive && <div className="active-blue-line"></div>}
      <img className="h-5 p" src={dimension.image} alt={dimension.title} />
      <h1 className="text-xs whitespace-nowrap">{dimension.title}</h1>
      {dimension.showNewBadge && <h1 className="text-xs whitespace-nowrap new-badge">NEW</h1>}
    </div>
  );
}
