import { Header } from "./header";
import { ViewModel } from "../settings/viewModel";
import { EMPTY } from "../library/constants";

export class NullHeader extends Header {
    public addHeader() { return; }
    public plotHeader() { return; }
    public fixBackgroundRect(width: number) { return; }
    public fixBorderLines(width: number) { return; }
    public addHeaderRectangle(id: number, xPosition: number, multiples: boolean, numberOfCharts: number) { return; }
    public displayChartSettingsIcon(id: number, xPosition: number, drawIcon: (icon: d3.Selection<SVGElement, any, any, any>, viewModel: ViewModel, size: number) => void,
        applyFunction: (viewModel: ViewModel) => void, multiples: boolean, numberOfCharts: number) { return; }
    public getColumnHeaderText(header: string): string {
        return EMPTY;
    }
}