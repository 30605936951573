import { DataProperty, CHART_AREA, START, END, LogType, LABEL, FONT_SIZE, PX, TEXT_ANCHOR, FONT_FAMILY, FONT_WEIGHT, BOLD, NORMAL, HIGHLIGHTABLE, TOOLTIP, FONT_STYLE, ITALIC, ColumnFormat, Scenario, SETTINGS_ICON, DATA_SCALE_GROUP, DATA_PROPERTY, X, Y, FILL, INITIAL, FORMULA_ELEMENT, EMPTY, TOTAL_VARIANCE_SEPARATOR, THIN_SPACE, DisplayUnits, FONT_SIZE_UNIT, CHART_ID } from "../../library/constants";
import { Plotter } from "../plotters/plotter";
import { getOrdinalScale, plotSortableHeader, addBlurIfNeeded, plotGridline, drawValueIcons, drawAbsoluteIcons, drawRelativeIcons, getCommentMarkerAttributes, addCommentMarkers, getVarianceColor, getFormattedDataLabel, getFormattedRelativeDataLabel, getFormattedSuppressedRelativeDataLabel } from "./chart";
import { ChartType, DataPointType, FormulaEditMode, INTEGRATED, ACT_ABS_REL, VERTICAL_MARGIN, NegativeValuesFormat } from "../../definitions";
import { DataPoint } from "../dataPoint";
import { VarianceSettings } from "../../settings/varianceSettings";
import { ViewModel } from "../../settings/viewModel";
import * as styles from "./../../library/styles";
import * as scenarios from "./../../settings/scenarios";

import * as drawing from "./../../library/drawing";
import * as debug from "./../../library/debug";
import * as helpersLib from "./../../library/helpers";
import * as helpers from "./../../helpers";
import * as d3 from "d3";
import { ChartHierarchy } from "../chartHierarchy";
import { isRelativeMeasure } from "./../../helpers";

// tslint:disable-next-line: max-func-body-length 
export function plotTable(width: number, xPosition: number, groupIndex: number, chartHierarchyIndex: number, chartId: number,
    dataProperty: DataProperty, plotOrder: number, multiples: boolean, plotter: Plotter) {
    let height = plotter.getCurrentChartHeight();
    let { viewModel, settings, groupHeaderHeight, svg, plotArea, currentRow, currentYPosition: currentHeight, dataPropertyForComments } = plotter;
    let totalWidth = plotter.width;
    let chartHierarchy = viewModel.getChartHierarchy(groupIndex, chartHierarchyIndex);

    let start = xPosition;
    let end = start + width;
    let yScale = getOrdinalScale(chartHierarchy.dataPoints(), currentHeight, currentHeight + height, viewModel, settings.getGapBetweenColumns());
    let plottableDataPoints = plotter.getDataPointsToPlotChartElements(chartHierarchy, yScale, dataProperty, true);
    let gridlinePlottableDataPoints = plotter.getDataPointsToPlotChartElements(chartHierarchy, yScale, dataProperty, false);
    plotter.currentlyShowingDataPoints = plottableDataPoints.inView;
    let chartArea = drawing.createGroupElement(plotArea, `${CHART_AREA}_${groupIndex}_${chartHierarchyIndex}_${chartId} ${CHART_AREA}`);
    let scaleGroup = settings.getScaleGroup(dataProperty);
    chartArea.attr(DATA_SCALE_GROUP, scaleGroup);
    chartArea.attr(DATA_PROPERTY, dataProperty);
    chartArea.attr(CHART_ID, chartId);    
    let dp = getDataProperties(dataProperty, viewModel);
    let chartGroup = viewModel.lastLevelChartGroups[groupIndex];
    let tableChartDefinition = plotter.isTableChartDefinition(dataProperty);
    let [labelAnchor, labelXPosition] = getLabelAnchorAndXPosition(tableChartDefinition, chartHierarchy, viewModel, dataProperty, start, end);
    let shouldPlotTotalVariance = ((settings.chartType === INTEGRATED || settings.chartType === ACT_ABS_REL) && plotter.valueChartIntegrated && settings.showRowGrandTotal && dataProperty === DataProperty.Value);

    if (debug.shouldLog(LogType.DisplayBounds)) {
        debug.drawBoundingBox(chartArea, xPosition, width, currentHeight, height, chartId);
    }
    let header = settings.getHeader(dataProperty);
    let isHeaderBold = settings.isHeaderBold(dataProperty) || settings.shouldUseBoldGroupHeaders(chartGroup, viewModel.maximumExpandedGroupLevel);
    if (multiples) {
        let yPosition = chartGroup.level * groupHeaderHeight;
        if (chartGroup.isSubtotal) {
            yPosition = viewModel.maximumExpandedGroupLevel * groupHeaderHeight;
        }
        if (settings.shouldShowScenarioSortHeaders(plotter)) {
            let sortableHeader = plotSortableHeader(chartHierarchy, plotter, viewModel, totalWidth, header, currentRow, groupIndex, start, yPosition + groupHeaderHeight + 3, "right", isHeaderBold, dataProperty, plotOrder, true, width, 0, false, true, true, true, false, groupIndex, false, chartGroup, false, dp.scenario, false, chartId);
            if (dp.drawIconFunction) {
                sortableHeader.setChartChangeIcons(dp.drawIconFunction, ChartType.Table);
            }
        }
    }
    else {
        let sortableHeader = plotSortableHeader(chartHierarchy, plotter, viewModel, totalWidth, settings.getHeader(dataProperty), currentRow, null, start, 3, "right", isHeaderBold, dataProperty, plotOrder, false, width, 0, false, true, true, true, false, groupIndex, false, chartGroup, false, dp.scenario, false, chartId);
        if (dp.drawIconFunction) {
            sortableHeader.setChartChangeIcons(dp.drawIconFunction, ChartType.Table);
        }
    }
    let labels = drawing.getLabels(chartArea, `${LABEL}${chartId}`, plottableDataPoints.inView);
    let extreme = viewModel.extremes.getTableExtreme(dataProperty);
    if (settings.plottingHorizontally()) {
        extreme = chartGroup.extremes.getTableExtreme(dataProperty);
    }
    let indent = extreme.min < 0 && helpersLib.isDifferenceMeasure(dataProperty, settings.getColumnFormat(dataProperty));
    labels
        .text((d: DataPoint) => {
            if (isRelativeMeasure(dataProperty, settings)) {
                if (settings.suppressLargeRelativeVariance) {
                    if (Math.abs(d.getValue(dataProperty)) > Math.abs(settings.suppressLargeRelativeVarianceValue)) {
                        return getFormattedSuppressedRelativeDataLabel(d.getValue(dataProperty), settings.suppressLargeRelativeVarianceValue, 0, viewModel.locale, settings.negativeValuesFormat === NegativeValuesFormat.Parenthesis, settings.shouldHideDataLabelUnits(), settings.showPercentageInLabel, settings.getPercentageFormat(dataProperty), helpersLib.isAdditionalMeasure(dataProperty), false);
                    }
                }
            }
            return d.getLabel(dataProperty);
        })

        .style(FONT_SIZE, `${settings.labelFontSize}${FONT_SIZE_UNIT}`)
        .style(TEXT_ANCHOR, labelAnchor)
        .style(FONT_FAMILY, settings.labelFontFamily)
        .style(FONT_WEIGHT, (d: DataPoint) => isHeaderBold ? BOLD : d.getFontWeight())
        .style(FONT_STYLE, d => d.italic ? ITALIC : INITIAL)
        .attr(X, d => getXPosition(labelXPosition, d, dataProperty, indent, settings, isHeaderBold) - 2)
        .attr(Y, d => Math.round(drawing.centerTextVertically(yScale, d.getCategory(viewModel), settings.labelFontSize)))
        .attr(FILL, (d: DataPoint) => {
            let initialColor = (dp.colorLabels ? getColor(d, dataProperty, settings) : settings.labelFontColor);
            if (initialColor === settings.labelFontColor) {
                return d.getLabelColor(settings.labelFontColor, dataProperty);
            }
            return initialColor;
        })
        .classed(FORMULA_ELEMENT, (d: DataPoint) => d.isCurrentlyEditedFormula())
        .classed(`${LABEL}${chartId}`, true)
        .classed(HIGHLIGHTABLE, true)
        .classed(TOOLTIP, true);

    if (shouldPlotTotalVariance) {
        let totalPoints = plottableDataPoints.inView.filter(d => d.dataPointType === DataPointType.GrandTotal);
        if (totalPoints.length != 0) {
            let totalPoint: DataPoint = totalPoints[0];
            let valueLabelWidth = drawing.measureTextWidth(totalPoint.valueLabel, settings.labelFontSize, settings.labelFontFamily, totalPoint.getFontWeight(), NORMAL);
            let valueLabelX = getXPosition(labelXPosition, totalPoint, dataProperty, indent, settings, isHeaderBold) - 2;

            let varianceSeparator = drawing.getLabels(chartArea, `${LABEL}${chartId + 1}`, totalPoints);
            varianceSeparator
                .attr(X, valueLabelX + valueLabelWidth + 5)
                .attr(Y, d => Math.round(drawing.centerTextVertically(yScale, d.getCategory(viewModel), settings.labelFontSize)))
                .text(TOTAL_VARIANCE_SEPARATOR)
                .style(FONT_SIZE, `${settings.labelFontSize}${FONT_SIZE_UNIT}`)
                .style(FONT_FAMILY, settings.labelFontFamily)
                .classed(`${LABEL}${chartId}`, true)
                .classed(HIGHLIGHTABLE, true)
                .classed(TOOLTIP, true);
            styles.applyToBars(varianceSeparator, getVarianceColor(settings, DataProperty.AbsoluteDifference), scenarios.getScenario(dataProperty, viewModel), settings.chartStyle, true, settings.colorScheme);

            let totalVarianceLabel = drawing.getLabels(chartArea, `${LABEL}${chartId + 2}`, totalPoints);
            totalVarianceLabel
                .attr(X, valueLabelX + valueLabelWidth + 12)
                .attr(Y, d => Math.round(drawing.centerTextVertically(yScale, d.getCategory(viewModel), settings.labelFontSize)))
                .text(d => d.absoluteDifferenceLabel)
                .style(FONT_SIZE, `${settings.labelFontSize}${FONT_SIZE_UNIT}`)
                .style(TEXT_ANCHOR, labelAnchor)
                .style(FONT_FAMILY, settings.labelFontFamily)
                .style(FONT_WEIGHT, isHeaderBold ? BOLD : NORMAL)
                .style(FONT_STYLE, d => d.italic ? ITALIC : INITIAL)
                .classed(`${LABEL}`, true)
                .classed(HIGHLIGHTABLE, true)
                .classed(TOOLTIP, true);

            // plotter.addTooltips(varianceSeparator)
            // plotter.addTooltips(totalVarianceLabel)
        }
    }

    // plotter.addTooltips(labels);

    if (settings.shouldUseItalicText(dataProperty)) {
        labels.style(FONT_STYLE, ITALIC);
    }
    // addMouseHandlers(labels, selectionManager, svg, viewModel);
    addBlurIfNeeded(labels, settings);
    let skipLastPoint = viewModel.skipLastPointGridline(groupIndex, chartHierarchyIndex);
    plotGridline(chartArea, chartId.toString(), settings, viewModel, plottableDataPoints.inView, xPosition, xPosition + width,
        (d: DataPoint) => xPosition, (d: DataPoint) => end, yScale, skipLastPoint);

    if (viewModel.HasCommentsColumn) {
        let commentDataPoints: DataPoint[] = [];
        // comment markers with default data property (not being dragged to another data property):
        if (dataProperty === dataPropertyForComments) {
            commentDataPoints.push(...plottableDataPoints.inViewWithoutFormulas.filter(d => d.hasComment() && d.commentMarkerDataProperty === null));
        }
        commentDataPoints.push(...plottableDataPoints.inViewWithoutFormulas.filter(d => d.hasComment()).filter(d => d.commentMarkerDataProperty === dataProperty));
        plotCommentMarkers(chartArea, viewModel, commentDataPoints, start, yScale, settings, plotter.categoriesWidth, groupIndex, dataProperty);
        //plotter.addCommentMarkerTooltip(chartArea);
    }
}

function plotCommentMarkers(container: d3.Selection<SVGElement, any, any, any>, viewModel: ViewModel, commentDataPoints: DataPoint[], xPosition: number, yScale: d3.ScaleBand<string>, settings: VarianceSettings, categoriesWidth: number, groupIndex: number, dataProperty: DataProperty) {
    let scaleBandWidth = yScale.bandwidth();
    let markerAttrs = getCommentMarkerAttributes(scaleBandWidth);
    let xPosCorrection = VERTICAL_MARGIN / 2;
    let getMarkerYPosition = (d: DataPoint): number => yScale(d.getCategory(viewModel)) + scaleBandWidth / 2;
    let getMarkerXPosition = (d: DataPoint): number => xPosition + markerAttrs.radius - xPosCorrection;
    addCommentMarkers(container, commentDataPoints, getMarkerXPosition, getMarkerYPosition, markerAttrs.radius, markerAttrs.fontSize, settings.colorScheme.highlightColor, settings.labelFontFamily, viewModel, categoriesWidth, groupIndex, dataProperty);
}

function getLabelAnchorAndXPosition(tableChartDefinition: boolean, chartHierarchy: ChartHierarchy, viewModel: ViewModel, dataProperty: DataProperty, start: number, end: number): [string, number] {
    if (!chartHierarchy.isGrandTotal) {
        return [END, end]
    }
    if (tableChartDefinition) {
        return [END, end];
    }
    if (viewModel.hasHierarchy) {
        return [START, start];
    }
    if (helpers.isVariance(dataProperty)) {
        return [END, end];
    }
    if (helpersLib.isAdditionalMeasure(dataProperty)) {
        let columnFormat = viewModel.settings.getColumnFormat(dataProperty);
        if (columnFormat === ColumnFormat.Value || columnFormat === ColumnFormat.Percent) {
            return [START, start];
        }
        else {
            return [END, end];
        }
    }
    return [START, start];
}

function getXPosition(labelXPosition: number, d: DataPoint, dataProperty: DataProperty, indent: boolean, settings: VarianceSettings, isHeaderBold: boolean) {
    if (indent && !d.isNegative(dataProperty)) {
        labelXPosition -= (isHeaderBold ? settings.rightAlignParenthesisOffsetBold : settings.rightAlignParenthesisOffsetNormal);
    }
    return Math.round(labelXPosition);
}

// tslint:disable-next-line: max-func-body-length
function getDataProperties(dataProperty: DataProperty, viewModel: ViewModel): DataProperties {
    let iconFunction;
    switch (dataProperty) {
        case DataProperty.Value:
            return {
                scenario: viewModel.value.scenario,
                colorLabels: false,
                drawIconFunction: drawValueIcons,
            };
        case DataProperty.ReferenceValue:
            return {
                scenario: viewModel.reference.scenario,
                colorLabels: false,
                drawIconFunction: drawValueIcons,
            };
        case DataProperty.SecondReferenceValue:
            return {
                scenario: viewModel.secondReference.scenario,
                colorLabels: false,
                drawIconFunction: drawValueIcons,
            };
        case DataProperty.ThirdReferenceValue:
            return {
                scenario: viewModel.thirdReference.scenario,
                colorLabels: false,
                drawIconFunction: drawValueIcons,
            };
        case DataProperty.FourthReferenceValue:
            return {
                scenario: viewModel.fourthReference.scenario,
                colorLabels: false,
                drawIconFunction: drawValueIcons,
            };
        case DataProperty.FifthReferenceValue:
            return {
                scenario: viewModel.fifthReference.scenario,
                colorLabels: false,
                drawIconFunction: drawValueIcons,
            };
        case DataProperty.SixthReferenceValue:
            return {
                scenario: viewModel.sixthReference.scenario,
                colorLabels: false,
                drawIconFunction: drawValueIcons,
            };
        case DataProperty.SeventhReferenceValue:
            return {
                scenario: viewModel.seventhReference.scenario,
                colorLabels: false,
                drawIconFunction: drawValueIcons,
            };
        case DataProperty.AbsoluteDifference:
        case DataProperty.SecondAbsoluteDifference:
        case DataProperty.ThirdAbsoluteDifference:
        case DataProperty.FourthAbsoluteDifference:
        case DataProperty.FifthAbsoluteDifference:
        case DataProperty.SixthAbsoluteDifference:
        case DataProperty.SeventhAbsoluteDifference:
            return {
                scenario: null,
                colorLabels: true,
                drawIconFunction: drawAbsoluteIcons,
            };
        case DataProperty.RelativeDifference:
        case DataProperty.SecondRelativeDifference:
        case DataProperty.ThirdRelativeDifference:
        case DataProperty.FourthRelativeDifference:
        case DataProperty.FifthRelativeDifference:
        case DataProperty.SixthRelativeDifference:
        case DataProperty.SeventhRelativeDifference:
            return {
                scenario: null,
                colorLabels: true,
                drawIconFunction: drawRelativeIcons,
            };
        case DataProperty.AdditionalMeasure1:
        case DataProperty.AdditionalMeasure2:
        case DataProperty.AdditionalMeasure3:
        case DataProperty.AdditionalMeasure4:
        case DataProperty.AdditionalMeasure5:
        case DataProperty.AdditionalMeasure6:
        case DataProperty.AdditionalMeasure7:
        case DataProperty.AdditionalMeasure8:
        case DataProperty.AdditionalMeasure9:
        case DataProperty.AdditionalMeasure10: {
            let columnFormat = viewModel.settings.getColumnFormat(dataProperty);
            if (columnFormat === ColumnFormat.Value || columnFormat === ColumnFormat.Percent) {
                iconFunction = drawValueIcons;
            }
            else if (columnFormat === ColumnFormat.AbsoluteDifference) {
                iconFunction = drawAbsoluteIcons;
            }
            else if (columnFormat === ColumnFormat.RelativeDifference) {
                iconFunction = drawRelativeIcons;
            }
            return {
                scenario: viewModel.additionalMeasures[dataProperty - DataProperty.AdditionalMeasure1].scenario,
                colorLabels: columnFormat === ColumnFormat.AbsoluteDifference || columnFormat === ColumnFormat.RelativeDifference,
                drawIconFunction: iconFunction,
            };
        }
        case DataProperty.AdditionalMeasure11:
        case DataProperty.AdditionalMeasure12:
        case DataProperty.AdditionalMeasure13:
        case DataProperty.AdditionalMeasure14:
        case DataProperty.AdditionalMeasure15:
        case DataProperty.AdditionalMeasure16:
        case DataProperty.AdditionalMeasure17:
        case DataProperty.AdditionalMeasure18:
        case DataProperty.AdditionalMeasure19:
        case DataProperty.AdditionalMeasure20: {
            let columnFormat = viewModel.settings.getColumnFormat(dataProperty);
            if (columnFormat === ColumnFormat.Value || columnFormat === ColumnFormat.Percent) {
                iconFunction = drawValueIcons;
            }
            else if (columnFormat === ColumnFormat.AbsoluteDifference) {
                iconFunction = drawAbsoluteIcons;
            }
            else if (columnFormat === ColumnFormat.RelativeDifference) {
                iconFunction = drawRelativeIcons;
            }
            return {
                scenario: viewModel.additionalMeasures[dataProperty - DataProperty.AdditionalMeasure11 + 10].scenario,
                colorLabels: columnFormat === ColumnFormat.AbsoluteDifference || columnFormat === ColumnFormat.RelativeDifference,
                drawIconFunction: iconFunction,
            };
        }
    }
}

function getColor(d: DataPoint, dataProperty: DataProperty, settings: VarianceSettings): string {
    let value = d.getValue(dataProperty);
    if (value < 0) {
        // Using !== as XOR, as described here:
        // https://github.com/Microsoft/TypeScript/issues/587
        if (settings.invert !== d.isInverted !== d.hasInvertedGroupAncestor() !== settings.getColumnInvert(dataProperty)) {
            return d.getLabelColor(settings.labelFontColor, dataProperty);
        }
        return settings.colorScheme.negativeColor;
    }
    else {
        if (settings.invert !== d.isInverted !== d.hasInvertedGroupAncestor() !== settings.getColumnInvert(dataProperty)) {
            return settings.colorScheme.negativeColor;
        }
        return d.getLabelColor(settings.labelFontColor, dataProperty);
    }
}

interface DataProperties {
    scenario: Scenario;
    colorLabels: boolean;
    drawIconFunction: (chartType: ChartType, columnSettingsDiv: HTMLElement, icon: d3.Selection<SVGElement, any, any, any>, viewModel: ViewModel, iconSize: number, dataProperty: DataProperty, plotter: Plotter) => number;
}