import { VarianceSettings } from "../settings/varianceSettings";
import * as helpers from "./../helpers";

export class Extreme {
    constructor(public min: number, public max: number, public minLabelOffset: number, public maxLabelOffset: number) {
    }

    get range(): number {
        return helpers.calculateRange(this.min, this.max);
    }

    public labelOffsetSum(settings: VarianceSettings): number {
        return this.getMinLabelOffset(settings) + this.getMaxLabelOffset(settings);
    }

    public getMinLabelOffset(settings: VarianceSettings): number {
        return settings.showDataLabels ? this.minLabelOffset : 0;
    }

    public getMaxLabelOffset(settings: VarianceSettings): number {
        return settings.showDataLabels ? this.maxLabelOffset : 0;
    }

    public getLabelOffset(settings: VarianceSettings, min: boolean): number {
        return min ? this.getMinLabelOffset(settings) : this.getMaxLabelOffset(settings);
    }

    public getTableWidth(headerWidth: number): number {
        return Math.max(this.maxLabelOffset, this.minLabelOffset, headerWidth);
    }
}