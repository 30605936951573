import { useState, useEffect } from "react";
import { TablesChooserStore } from "./store";

export default function useStore<T>(key: string): [T, (value: T) => void] {
  const [state, setState] = useState<T>(TablesChooserStore.get()[key]);

  useEffect(() => TablesChooserStore.subscribe((value) => setState(value[key])), []);

  const setStoreState = (value: T) => TablesChooserStore.set({ [key]: value });

  return [state, setStoreState];
}
