import { DIV, INPUT, LABEL, BUTTON, FORMULA_CATEGORY, THIRD_ABSOLUTE_SORT, P, BOLD, ITALIC, INITIAL, NORMAL, FORMULA_ELEMENT, FONT_WEIGHT, FONT_STYLE, SELECT, OPTION, WIDTH, HEIGHT, PX, FLOAT, DISPLAY, POSITION, ID, TEXT_ALIGN, CENTER, FONT_SIZE, LINE_HEIGHT, MARGIN, BACKGROUND, MOUSEOVER, MOUSELEAVE, HEADER_TOOLTIP, MARGIN_LEFT, FILL, DisplayUnits, EMPTY, CLICK, NONE, CHECKBOX, TYPE, COLOR, BLACK } from "../library/constants";
import { VarianceSettings } from "../settings/varianceSettings";
import { Formula, FormulaEditMode, RELATIVE } from "../definitions";
import { Visual } from "../visual";

import * as Awesomplete from "awesomplete";
import * as d3 from "d3";
import * as Calc from "expression-calculator";
import { ChartHierarchy } from "../charting/chartHierarchy";
import { contains } from "../helpers";
import pickr from "@simonwep/pickr";

export class FormulaEditor {
    private formulaNameInput: HTMLInputElement;
    private autoCompleteInput: HTMLInputElement;
    private formulaCategory: SVGTextElement;
    private editingExistingFormula: boolean;
    private formula: Formula;
    private formulaDiv: HTMLDivElement;
    private formulaLabelIcon: HTMLElement;
    readonly formulaEditorClass = "formula-editor";
    private italicButton: HTMLDivElement;
    private italicButtonManager: HTMLDivElement;
    private italicButtonManagerActive: boolean;
    private percentButton: HTMLDivElement;
    private percentButtonManager: HTMLDivElement;
    private boldButton: HTMLDivElement;
    private boldButtonManager: HTMLDivElement;
    private boldButtonManagerActive: boolean;
    private formulaElements: d3.Selection<d3.BaseType, any, any, any>;
    private confirmationButton: HTMLButtonElement;
    private siblings: string[];
    private formulaManagerButton: d3.Selection<HTMLButtonElement, any, any, any>;
    private formulaListElement: d3.Selection<HTMLDivElement, any, any, any>;
    private deleteButton: HTMLElement;
    private mainCheckbox: HTMLElement;
    private viableFormulas: Formula[];
    private formulasToDelete: Formula[];
    private deleteFormulasButton: HTMLElement;
    private undoButton: HTMLElement;
    private doneButton: HTMLElement;
    private anyManagerChangesMade: boolean;
    private prohibitedNames: string[];
    units: HTMLSelectElement;
    unitsManager: HTMLSelectElement;
    parent: HTMLDivElement;


    constructor(private settings: VarianceSettings, private hostElement: HTMLElement) {
        this.editingExistingFormula = Visual.formulaEditMode === FormulaEditMode.Edit;
        (<any>window).shouldKeepPickers = true;
        if (this.editingExistingFormula) {
            this.formula = this.settings.getEditedFormula();
        }
        else {
            this.formula = {
                identity: "",
                expression: "",
                hierarchyIdentity: Visual.formulaEditPosition.hierarchyIdentity,
                position: Visual.formulaEditPosition.category,
                bold: false,
                italic: false,
                decimalPlaces: 1,
                units: DisplayUnits.Auto,
                fontColor: undefined,
            }
        }
        this.siblings = this.getSiblings();
        // Additionally prohibit names of parents in hierarchy due to drawing errors in some edge cases
        this.prohibitedNames = JSON.parse(JSON.stringify(this.siblings.map(s => s.toString())));
        let firstParent = Visual.formulaEditPosition.isTotal() ? (<ChartHierarchy>Visual.formulaEditPosition.parent.parent) : (<ChartHierarchy>Visual.formulaEditPosition.parent);
        while (firstParent && firstParent instanceof ChartHierarchy && firstParent.category !== EMPTY) {
            this.prohibitedNames.push(firstParent.category.toString());
            firstParent = <ChartHierarchy>(firstParent.parent);
        }
        this.crateFormulaUIElements();
        // set up autocomplete
        this.addAutoCompleteFunctionality(this.siblings);
        this.formulasToDelete = [];
        this.anyManagerChangesMade = false;
        this.boldButtonManagerActive = false;
        this.italicButtonManagerActive = false;
        // give warning if old formula name is not available anymore
        if (this.editingExistingFormula) {
            if (contains(this.prohibitedNames, this.formulaNameInput.value?.trim())) {
                this.formulaNameInput.setCustomValidity("Formula or data with this name already exists.");
            }
        }
        this.setConfirmationButtonState(this.isFormulaValid(false));
    }

    private getSiblings(): string[] {
        let formulaEditPosition = Visual.formulaEditPosition;
        let autoCompleteDataSource: string[] = [];
        if (formulaEditPosition?.isTotal()) {
            let hierarchies = formulaEditPosition.parent.parent.getHierarchies();
            autoCompleteDataSource = hierarchies.filter(h => h.totalDataPoint && JSON.stringify(h.totalDataPoint.formula) !== JSON.stringify(this.formula)).map(h => h.totalDataPoint?.category);
        }
        else {
            autoCompleteDataSource = (<ChartHierarchy>formulaEditPosition.parent).getAllDataPoints().filter(d => JSON.stringify(d.formula) !== JSON.stringify(this.formula) && !d.isTotal()).map(p => p.category);
        }
        return autoCompleteDataSource;
    }

    private crateFormulaUIElements() {
        this.parent = document.createElement(DIV);
        this.parent.classList.add("formula-parent");
        this.formulaDiv = document.createElement(DIV);
        this.formulaDiv.classList.add(this.formulaEditorClass);
        this.formulaDiv.style.width = `${Visual.width - 65}px`;
        this.parent.appendChild(this.formulaDiv);
        this.hostElement.appendChild(this.parent);
        this.addFormulaIdentityInput();
        this.addFormulaLabel();
        this.addAutocompleteFormulaEditor();
        this.addConfirmButton();
        this.addVerticalLine();
        this.addPercentButton();
        this.addUnitsSelection();
        this.addDecimalPlacesSelect();
        this.addVerticalLine();
        this.addBoldButton();
        this.addItalicButton();
        this.addFontColorPicker();
        this.addVerticalLine();
        if (this.editingExistingFormula) {
            this.addDeleteButton();
        }
        if (!Visual.formulaManagerOpen) {
            this.addFormulaManagerButton();
        } else {
            this.setFormulaManagerElements();
        }
        this.addCancelButton();
        this.formulaNameInput.focus();
    }

    setFormulaManagerElements() {
        this.viableFormulas = this.settings.formulaCalculation.formulas;
        if (!this.viableFormulas.length) {
            return;
        }
        this.formulaListElement = d3.select(document.createElement(DIV));
        this.parent.append(this.formulaListElement.node());
        this.formulaListElement.classed("formula-list", true);
        this.formulaListElement.style(WIDTH, Visual.width + PX);
        this.formulaListElement.style(HEIGHT, Visual.height - 55 + PX);
        if (this.formulaManagerButton) {
            this.formulaManagerButton.style(DISPLAY, NONE);
        }

        this.hideFormulaEditorSpecificButtons();
        this.addCheckboxIcon();
        this.addFormulasToList();
        // ADD same elements but for manager
        this.addVerticalLine(true);
        this.addPercentButton(true);
        this.addUnitsSelection(true);
        this.addDecimalPlacesSelect(true);
        this.addVerticalLine(true);
        this.addBoldButton(true);
        this.addItalicButton(true);
        this.addFontColorPicker(true);
        this.addVerticalLine(true);

        this.addFormulasDeleteButton();
        this.addDoneButton();
        this.addUndoButton();
        this.showHideFormulaManagerSettings(this.someFormulasChecked());
    }

    private addFormulaManagerButton() {
        this.viableFormulas = this.settings.formulaCalculation.formulas;
        if (!this.viableFormulas.length) {
            return;
        }

        this.formulaManagerButton = d3.select(document.createElement(BUTTON));
        this.formulaManagerButton.classed("formula-manager-button", true).text("Manage formulas");
        this.formulaDiv.append(this.formulaManagerButton.node());
        this.formulaManagerButton.on(CLICK, () => {
            if (!Visual.formulaManagerOpen) {
                Visual.formulaManagerOpen = true;
                // persist settings...
                if (Visual.formulaEditMode !== FormulaEditMode.Add) {
                    this.formula.identity = this.formulaNameInput.value?.trim();
                    this.formula.expression = this.autoCompleteInput.value?.trim();
                    Visual.formulaEditPosition.formula = this.formula;
                    this.settings.addFormula(this.formula, this.editingExistingFormula);
                }
                this.setFormulaManagerElements();
            }
        });
    }

    private removeFormulaManagerInputs() {
        d3.selectAll(".vertical-line-manager").remove();
        d3.select(".units-manager").remove();
        d3.select(".percent-button-manager").remove();
        d3.select(".delete-formulas").remove();
        d3.select(".decimal-places-manager").remove();
        d3.selectAll(".formula-formatting-manager").remove();
        d3.select(".special-manager").remove();
        d3.select(".comma-manager").remove();
    }

    private addDoneButton() {
        let done = () => {
            this.settings.formulaCalculation.formulas.forEach(f => {
                if (f.tempFontColor !== undefined) {
                    f.fontColor = f.tempFontColor;
                    f.tempFontColor = undefined;
                }
                if (f.tempBold !== undefined) {
                    f.bold = f.tempBold;
                    f.tempBold = undefined;
                }
                if (f.tempItalic !== undefined) {
                    f.italic = f.tempItalic;
                    f.tempItalic = undefined;
                }
                if (f.tempDecimalPlaces !== undefined) {
                    f.decimalPlaces = f.tempDecimalPlaces;
                    f.tempDecimalPlaces = undefined;
                }
                if (f.tempUnits !== undefined) {
                    f.units = f.tempUnits;
                    f.tempUnits = undefined;
                }
            });
            let shouldCloseEditor = false;
            if (this.editingExistingFormula && this.formulasToDelete.indexOf(this.formula) > -1) {
                shouldCloseEditor = true;
            }
            this.settings.deleteFormulas(this.formulasToDelete);
            Visual.formulaManagerOpen = false;
            this.anyManagerChangesMade = false;
            this.showHideFormulaManagerSettings(false);
            d3.selectAll(".formula-list").remove();
            if (this.formulaManagerButton) {
                this.formulaManagerButton.style(DISPLAY, null);
            }
            this.formulaNameInput.style.display = null;
            this.formulaLabelIcon.style.display = null;
            this.autoCompleteInput.style.display = null;
            this.confirmationButton.style.display = null;
            if (this.deleteButton) {
                this.deleteButton.style.display = null;
            }
            this.removeCheckboxIcon();
            this.removeFormulaManagerInputs();
            this.showFormulaEditorSpecificButtons();
            if (shouldCloseEditor) {
                (<any>window).shouldKeepPickers = false;
                Visual.formulaEditMode = FormulaEditMode.None;
                Visual.formulaEditPosition = null;
                Visual.getInstance().constructViewModelAndUpdate(this.settings);
                //this.settings.host.switchFocusModeState(false);
            }
        }
        this.doneButton = this.addButton("Save & Close", "done", done, this.formulaDiv);
    }

    private addUndoButton() {
        let undo = () => {
            this.anyManagerChangesMade = false;
            this.settings.formulaCalculation.formulas.forEach(f => {
                f.tempItalic = undefined;
                f.tempBold = undefined;
                f.tempDecimalPlaces = undefined;
                f.tempFontColor = undefined;
                f.tempUnits = undefined;
            });
            this.viableFormulas.push(...this.formulasToDelete);
            this.formulasToDelete = [];
            this.formulaListElement.selectAll("*").remove();
            this.addFormulasToList();
            this.showHideFormulaManagerSettings(this.someFormulasChecked());
            d3.select(".checkbox").property("indeterminate", false).property("checked", false);
        }
        this.undoButton = this.addButton("Undo", "undo", undo, this.formulaDiv);
        let undoicon = document.createElement(DIV);
        undoicon.classList.add("back");
        this.undoButton.insertBefore(undoicon, this.undoButton.firstChild);
    }

    private addFormulasDeleteButton() {
        let deleteOnClick = (e) => {
            this.formulasToDelete.push(...this.getCheckedFormulas());
            this.anyManagerChangesMade = true;
            this.viableFormulas = this.viableFormulas.filter(f => this.formulasToDelete.indexOf(f) < 0);
            this.formulaListElement.selectAll("*").remove();
            this.addFormulasToList();
            this.showHideFormulaManagerSettings(this.someFormulasChecked());
            d3.select(".checkbox").property("indeterminate", false).property("checked", false);
        };
        this.deleteFormulasButton = this.addButton("", "delete-formulas", deleteOnClick, this.formulaDiv, true);
    }

    private showHideFormulaManagerSettings(show: boolean) {
        d3.selectAll(".vertical-line-manager").style(DISPLAY, show ? null : NONE);
        d3.select(".units-manager").style(DISPLAY, show ? null : NONE);
        this.deleteFormulasButton.style.display = show ? null : NONE;
        d3.select(".percent-button-manager").style(DISPLAY, show ? null : NONE);
        d3.select(".decimal-places-manager").style(DISPLAY, show ? null : NONE);
        d3.selectAll(".formula-formatting-manager").style(DISPLAY, show ? null : NONE);
        d3.select(".special-manager").style(DISPLAY, show ? "inline-block" : NONE);
        d3.select(".comma-manager").style(DISPLAY, show ? null : NONE);

        if (this.anyManagerChangesMade && Visual.formulaManagerOpen) {
            this.undoButton.style.display = null;
            this.doneButton.style.display = null;
        } else {
            this.undoButton.style.display = NONE;
            this.doneButton.style.display = NONE;
        }

        this.mainCheckbox.style.display = this.viableFormulas.length ? null : NONE;
    }

    private addCheckboxIcon() {
        this.mainCheckbox = <HTMLInputElement>document.createElement(INPUT);
        this.mainCheckbox.setAttribute(TYPE, CHECKBOX);
        this.mainCheckbox.classList.add("checkbox");
        this.formulaDiv.insertBefore(this.mainCheckbox, this.formulaDiv.firstChild);

        this.mainCheckbox.addEventListener('change', () => {
            let sel = d3.selectAll(".formula-list .item input");
            sel.nodes().forEach((node) => {
                (<HTMLInputElement>node).checked = (<HTMLInputElement>this.mainCheckbox).checked ? true : false;
            })
            this.showHideFormulaManagerSettings(this.someFormulasChecked());
        })
    }

    private removeCheckboxIcon() {
        this.mainCheckbox.remove();
    }

    private getCheckedItems() {
        let checkedCheckboxFormulaElements = <HTMLElement[]>d3.selectAll(".formula-list .item input").nodes().filter(node => (<HTMLInputElement>node).checked);
        return checkedCheckboxFormulaElements.map(el => el.parentElement);
    }

    private getCheckedFormulas(): Formula[] {
        let checkedCheckboxFormulaElements = <HTMLElement[]>d3.selectAll(".formula-list .item input").nodes().filter(node => (<HTMLInputElement>node).checked);
        return checkedCheckboxFormulaElements.map(el => this.settings.findFormula(el.getAttribute("data-hierarchy"), el.getAttribute("data-identity")));
    }

    private addFormulasToList() {
        let titleItem = this.formulaListElement.append(DIV).classed("title-item", true);
        titleItem.append(DIV).classed("name", true).text("Name")
        titleItem.append(DIV).classed("expression", true).text("Expression");
        titleItem.append(DIV).classed("unit-item", true).text("Units");
        titleItem.append(DIV).classed("decimal-item", true).text("Decimal places");

        this.viableFormulas.forEach(f => {
            // add div
            let item = this.formulaListElement.append(DIV).classed("item", true);
            // add checkbox
            let checkbox = item.append(INPUT).attr(TYPE, CHECKBOX).attr("data-identity", f.identity).attr("data-hierarchy", f.hierarchyIdentity);
            checkbox.on("change", () => {
                if (this.someFormulasChecked() && !this.allFormulasChecked()) {
                    d3.select(".checkbox").property("checked", false);
                    d3.select(".checkbox").property("indeterminate", true);
                } else if (this.allFormulasChecked()) {
                    d3.select(".checkbox").property("checked", true);
                    d3.select(".checkbox").property("indeterminate", false);
                } else {
                    d3.select(".checkbox").property("checked", false);
                    d3.select(".checkbox").property("indeterminate", false);
                }

                this.showHideFormulaManagerSettings(this.someFormulasChecked());
            });
            // add formulaName
            item.append(DIV).classed("name", true).text(f.identity)

            // add formula
            item.append(DIV).classed("expression", true).text(f.expression);

            // add units
            let options = {
                "Default": "-",
                "Auto": "Auto",
                "None": "None",
                "K": "Thousands",
                "M": "Millions",
                "G": "Billions",
                "P": "Percent (%)"
            }
            item.append(DIV).classed("unit-item", true).text(options[f.units]);

            // add decimal places
            item.append(DIV).classed("decimal-item", true).text(f.decimalPlaces.toString() === "-1" ? "-" : f.decimalPlaces.toString());

            item.style(FONT_WEIGHT, f.bold ? BOLD : NORMAL)
                .style(FONT_STYLE, f.italic ? ITALIC : NORMAL)
                .style(COLOR, f.fontColor ? f.fontColor : null);
        })
    }

    private addFormulaIdentityInput() {
        this.formulaNameInput = document.createElement(INPUT);
        this.formulaNameInput.classList.add(this.formulaEditorClass);
        this.formulaNameInput.style.width = "100px";
        if (this.editingExistingFormula && this.formula) {
            this.formulaNameInput.value = this.formula.identity;
        }
        else {
            this.formulaNameInput.placeholder = "Formula name..";
        }
        this.formulaNameInput.required = true;
        this.formulaNameInput.onkeyup = (ev) => {
            if (this.formulaCategory) {
                this.formulaCategory.textContent = this.formulaNameInput.value?.trim();
            }
            if (contains(this.prohibitedNames, this.formulaNameInput.value?.trim())) {
                this.formulaNameInput.setCustomValidity("Formula or data with this name already exists.");
            }
            else {
                this.formulaNameInput.setCustomValidity("");
            }
            this.setConfirmationButtonState(this.isFormulaValid(false));
        };
        this.formulaDiv.appendChild(this.formulaNameInput);
    }

    private addFormulaLabel() {
        let formulaLabel = document.createElement(LABEL);
        formulaLabel.style.display = "inline-block";
        formulaLabel.innerText = "=";
        this.formulaLabelIcon = formulaLabel;
        this.formulaDiv.appendChild(formulaLabel);
    }

    private addAutocompleteFormulaEditor() {
        this.autoCompleteInput = document.createElement(INPUT);
        this.autoCompleteInput.style.width = "400px";
        if (this.editingExistingFormula) {
            this.autoCompleteInput.value = this.formula.expression;
        }
        else {
            this.autoCompleteInput.placeholder = "[Var1] + [Var2] * [Var3]";
        }
        this.autoCompleteInput.classList.add(this.formulaEditorClass);
        this.autoCompleteInput.classList.add("awesomplete");
        this.autoCompleteInput.required = false;
        this.autoCompleteInput.onkeydown = (e) => {
            let input = <HTMLInputElement>e.target;
            let key = e.key;
            let operators = ["/", "*", "+", "-", "(", ")"];
            if (key === "Tab") {
                e.preventDefault();
            }
            if (contains(operators, key) && input.selectionEnd === input.value.length) {
                e.preventDefault();
                input.value = `${input.value}${key} `;
            }
        };
        this.formulaDiv.appendChild(this.autoCompleteInput);
    }

    private addItalicButton(manager?: boolean) {
        let italicOnClick = (e) => {
            if (manager) {
                this.anyManagerChangesMade = true;
                this.italicButtonManagerActive = !this.italicButtonManagerActive;
                d3.selectAll(this.getCheckedItems()).style(FONT_STYLE, this.italicButtonManagerActive ? ITALIC : NORMAL);
                this.italicButtonManager.classList.toggle("icon-selected", this.italicButtonManagerActive);
                this.getCheckedFormulas().forEach(f => { f.tempItalic = this.italicButtonManagerActive });
                this.showHideFormulaManagerSettings(true);
            } else {
                this.formula.italic = !this.formula.italic;
                if (this.formula.italic) {
                    this.italicButton.classList.add("icon-selected");
                }
                else {
                    this.italicButton.classList.remove("icon-selected");
                }
                let fontStyle = this.formula.italic ? ITALIC : INITIAL;
                if (this.formulaCategory) {
                    this.formulaCategory.style.fontStyle = fontStyle;
                }
                if (this.formulaElements) {
                    this.formulaElements.style(FONT_STYLE, fontStyle);
                }
            }
        };
        if (manager) {
            this.italicButtonManager = this.addTextButton("I", "italic", italicOnClick, false, true);
        } else {
            this.italicButton = this.addTextButton("I", "italic", italicOnClick, this.formula.italic);
        }
    }

    private addFontColorPicker(manager?: true) {
        // First delete any existing ones. Seems like update triggers multiple times
        // when going to focus mode and then the picker is added multiple times.
        if (!manager) {
            d3.selectAll(".pcr-app").remove();
        }
        let textColorIcon = d3.select(this.formulaDiv)
            .append(DIV)
            .classed(manager ? "special-manager" : "special", true)
            .style(WIDTH, 20 + PX)
            .style(HEIGHT, 20 + PX)
            .style(DISPLAY, "inline-block")
            .style(MARGIN_LEFT, 5 + PX)
            .append(DIV).attr(ID, manager ? "formula-color-picker-manager" : "formula-color-picker");
        textColorIcon.append(P)
            .style(TEXT_ALIGN, CENTER)
            .style(FONT_SIZE, 18 + PX)
            .style(LINE_HEIGHT, 18 + PX)
            .style(MARGIN, 0)
            .text("A");
        let colorRect = textColorIcon.append(DIV)
            .classed("fill-icon-rect-formula", true)
            .style(BACKGROUND, this.formula.fontColor ? this.formula.fontColor : this.settings.labelFontColor);

        const colorpkr = pickr.create({
            el: manager ? "#formula-color-picker-manager" : '#formula-color-picker',
            container: '#sandbox-host',
            theme: 'monolith',
            swatches: null,
            appClass: manager ? 'formulamanagercolorpickr' : 'formulacolorpickr',
            closeWithKey: 'Escape',
            adjustableNumbers: true,
            default: manager ? BLACK : this.formula.fontColor ? this.formula.fontColor : this.settings.labelFontColor,
            useAsButton: true,
            autoReposition: true,
            defaultRepresentation: 'RGBA',
            components: {
                preview: true,
                hue: true,
                opacity: true,
                interaction: {
                    save: true,
                    clear: true,
                    input: true,
                },
            },
        });

        colorpkr.on("save", (color) => {
            if (manager) {
                colorpkr.hide();
                let hexColor = color ? color.toHEXA().toString() : null;
                this.anyManagerChangesMade = true;
                d3.selectAll(this.getCheckedItems()).style(COLOR, hexColor);
                this.getCheckedFormulas().forEach(f => { f.tempFontColor = hexColor });
                this.showHideFormulaManagerSettings(true);
            } else {
                colorpkr.hide();
                let hexColor = color ? color.toHEXA().toString() : undefined;
                this.formula.fontColor = hexColor;
                colorRect.style(BACKGROUND, hexColor ? hexColor : this.settings.labelFontColor);
                this.formulaCategory.style.fill = hexColor ? hexColor : this.settings.labelFontColor;
                this.formulaElements.style(FILL, hexColor ? hexColor : this.settings.labelFontColor);
            }
        }).on("clear", () => {
            if (manager) {
                colorpkr.hide();
                this.anyManagerChangesMade = true;
                d3.selectAll(this.getCheckedItems()).style(COLOR, undefined);
                this.getCheckedFormulas().forEach(f => { f.tempFontColor = null });
                this.showHideFormulaManagerSettings(true);
            } else {
                colorpkr.hide();
                colorRect.style(BACKGROUND, this.settings.labelFontColor);
                this.formula.fontColor = undefined;
            }
        });
    }

    private addUnitsSelection(manager?: boolean) {
        let units = document.createElement(SELECT);
        units.className = manager ? "units-manager" : "units";
        this.addOption(units, "-", DisplayUnits.Default, this.formula.units, manager);
        this.addOption(units, "Auto", DisplayUnits.Auto, this.formula.units, manager);
        this.addOption(units, "None", DisplayUnits.None, this.formula.units, manager);
        this.addOption(units, "Thousands", DisplayUnits.Thousands, this.formula.units, manager);
        this.addOption(units, "Millions", DisplayUnits.Millions, this.formula.units, manager);
        this.addOption(units, "Billions", DisplayUnits.Billions, this.formula.units, manager);
        this.addOption(units, "Percent (%)", DisplayUnits.Percent, this.formula.units, manager);
        this.formulaDiv.appendChild(units);
        units.onchange = (e: Event) => {
            if (Visual.formulaManagerOpen) {
                this.anyManagerChangesMade = true;
                let selectedUnits = <DisplayUnits>units.options[units.selectedIndex].value;
                this.setPercentButtonState(selectedUnits === DisplayUnits.Percent, true);
                d3.selectAll(this.getCheckedItems()).selectAll(".unit-item").text(selectedUnits);
                this.getCheckedFormulas().forEach(f => { f.tempUnits = <DisplayUnits>units.options[units.selectedIndex].value });
                this.showHideFormulaManagerSettings(true);
            } else {
                this.formula.units = <DisplayUnits>units.options[units.selectedIndex].value;
                this.setPercentButtonState(this.formula.units.toString() === DisplayUnits.Percent, false);
            }
        }
        if (manager) {
            this.unitsManager = units;
        } else {
            this.units = units;
        }
    }

    private addOption(units: HTMLSelectElement, text: string, value: string, currentlySelected: string, manager?: boolean) {
        let optionElement = document.createElement(OPTION);
        optionElement.textContent = text;
        optionElement.value = value;
        optionElement.selected = manager ? false : value === currentlySelected
        units.options.add(optionElement);
    }

    private setPercentButtonState(enabled: boolean, manager: boolean) {
        let button = manager ? this.percentButtonManager : this.percentButton;
        if (enabled) {
            button.classList.add("icon-selected");
        } else {
            button.classList.remove("icon-selected");
        }
    }

    private getPercentButtonSelected(manager): boolean {
        let button = manager ? this.percentButtonManager : this.percentButton;
        if (button.classList.contains("icon-selected")) {
            return true;
        } else {
            return false;
        }
    }

    private addPercentButton(manager?: boolean) {
        let percentOnClick = (e) => {
            if (manager) {
                if (this.getPercentButtonSelected(manager)) {
                    this.unitsManager.value = DisplayUnits.Default;
                }
                else {
                    this.unitsManager.value = DisplayUnits.Percent;
                }
                this.unitsManager.onchange(undefined);
            }
            else {
                if (this.getPercentButtonSelected(manager)) {
                    this.units.value = DisplayUnits.Default;
                } else {
                    this.units.value = DisplayUnits.Percent;
                }
                this.units.onchange(undefined);
            }
        };
        if (manager) {
            this.percentButtonManager = this.addTextButton("%", "percent-button-manager", percentOnClick, false, true);
        } else {
            this.percentButton = this.addTextButton("%", "percent-button", percentOnClick, this.formula.units === DisplayUnits.Percent);
        }
    }

    private addDecimalPlacesSelect(manager?: boolean) {
        let comma = document.createElement(P);
        comma.className = manager ? "comma-manager" : "comma";
        comma.textContent = ",";
        this.formulaDiv.appendChild(comma);
        let decPlaces = document.createElement(SELECT);
        decPlaces.className = manager ? "decimal-places-manager" : "decimal-places";
        this.addOption(decPlaces, "-", "-1", this.formula.decimalPlaces.toString(), manager);
        this.addOption(decPlaces, "0", "0", this.formula.decimalPlaces.toString(), manager);
        this.addOption(decPlaces, "1", "1", this.formula.decimalPlaces.toString(), manager);
        this.addOption(decPlaces, "2", "2", this.formula.decimalPlaces.toString(), manager);
        this.addOption(decPlaces, "3", "3", this.formula.decimalPlaces.toString(), manager);
        this.addOption(decPlaces, "4", "4", this.formula.decimalPlaces.toString(), manager);

        decPlaces.onchange = (ev: Event) => {
            if (Visual.formulaManagerOpen) {
                this.anyManagerChangesMade = true;
                d3.selectAll(this.getCheckedItems()).selectAll(".decimal-item").text(decPlaces.options[decPlaces.selectedIndex].text);
                this.getCheckedFormulas().forEach(f => { f.tempDecimalPlaces = +parseInt(decPlaces.options[decPlaces.selectedIndex].value) });
                this.showHideFormulaManagerSettings(true);
            } else {
                this.formula.decimalPlaces = parseInt(decPlaces.options[decPlaces.selectedIndex].value);
            }
        };
        this.formulaDiv.appendChild(decPlaces);
    }

    private addBoldButton(manager?: boolean) {
        let boldOnClick = (e) => {
            if (Visual.formulaManagerOpen) {
                this.anyManagerChangesMade = true;
                this.boldButtonManagerActive = !this.boldButtonManagerActive;
                d3.selectAll(this.getCheckedItems()).style(FONT_WEIGHT, this.boldButtonManagerActive ? BOLD : NORMAL);
                this.getCheckedFormulas().forEach(f => { f.tempBold = this.boldButtonManagerActive });
                this.boldButtonManager.classList.toggle("icon-selected", this.boldButtonManagerActive);
                this.showHideFormulaManagerSettings(true);
            } else {
                this.formula.bold = !this.formula.bold;
                if (this.formula.bold) {
                    this.boldButton.classList.add("icon-selected");
                }
                else {
                    this.boldButton.classList.remove("icon-selected");
                }
                let fontWeight = this.formula.bold ? BOLD : INITIAL;
                if (this.formulaCategory) {
                    this.formulaCategory.style.fontWeight = fontWeight;
                }
                if (this.formulaElements) {
                    this.formulaElements.style(FONT_WEIGHT, fontWeight);
                }
            }
        };
        if (manager) {
            this.boldButtonManager = this.addTextButton("B", "bold", boldOnClick, false, true);
            this.boldButtonManager.style.fontWeight = BOLD;
        } else {
            this.boldButton = this.addTextButton("B", "bold", boldOnClick, this.formula.bold);
            this.boldButton.style.fontWeight = BOLD;
        }
    }

    private addTextButton(text: string, className: string, onClickHandler: (e: any) => void, active: boolean, manager?: boolean): HTMLDivElement {
        let button = document.createElement(DIV);
        button.classList.add(manager ? "formula-formatting-manager" : "formula-formatting");
        button.classList.add(className);
        if (active) {
            button.classList.add("icon-selected");
        }
        button.innerText = text;
        button.onclick = onClickHandler;
        this.formulaDiv.appendChild(button);
        return button;
    }

    private addConfirmButton() {
        let confirmationText = this.editingExistingFormula ? "Save" : "Add";
        let confirmOnClick = (e) => {
            if (!this.isFormulaValid(true)) {
                return;
            }
            (<any>window).shouldKeepPickers = false;
            this.formula.identity = this.formulaNameInput.value?.trim();
            this.formula.expression = this.autoCompleteInput.value?.trim();
            this.settings.addFormula(this.formula, this.editingExistingFormula);
            Visual.formulaEditMode = FormulaEditMode.None;
            Visual.formulaEditPosition = null;
            //this.settings.host.switchFocusModeState(false);
            Visual.isInFocus = false;
            Visual.shouldChangeSize = true;
            Visual.getInstance().constructViewModelAndUpdate(this.settings);
        }
        this.confirmationButton = this.addButton(confirmationText, "confirm", confirmOnClick, this.formulaDiv);
        let tick = document.createElement(DIV);
        tick.classList.add("tick");
        this.confirmationButton.appendChild(tick);
        this.confirmationButton.disabled = !this.editingExistingFormula;
        this.confirmationButton.onmouseenter = () => {
            if (this.confirmationButton.disabled) {
                this.confirmationButton.style.backgroundColor = "#DFF6DD";
                this.confirmationButton.style.color = null;
            }
            else {
                this.confirmationButton.style.backgroundColor = "#0b7c0b";
                this.confirmationButton.style.color = "white";
            }
        }
        this.confirmationButton.onmouseleave = () => {
            this.confirmationButton.style.backgroundColor = null;
            this.confirmationButton.style.color = null;
        }
    }

    private addDeleteButton() {
        let deleteOnClick = (e) => {
            (<any>window).shouldKeepPickers = false;
            Visual.formulaEditMode = FormulaEditMode.None;
            Visual.formulaEditPosition = null;
            this.settings.deleteFormula(this.formula.identity, this.formula.position);
            // this.settings.host.switchFocusModeState(false);
            Visual.isInFocus = false;
            Visual.shouldChangeSize = true;
            Visual.getInstance().constructViewModelAndUpdate(this.settings);
            //this.settings.host.switchFocusModeState(false);
        };
        this.deleteButton = this.addButton("", "delete", deleteOnClick, this.formulaDiv);
    }

    private allFormulasChecked(): boolean {
        return d3.selectAll(".formula-list .item input").nodes().every((node) => (<HTMLInputElement>node).checked);
    }

    private someFormulasChecked(): boolean {
        return d3.selectAll(".formula-list .item input").nodes().some((node) => (<HTMLInputElement>node).checked);
    }

    private hideFormulaEditorSpecificButtons() {
        this.formulaNameInput.style.display = NONE;
        this.formulaLabelIcon.style.display = NONE;
        this.autoCompleteInput.style.display = NONE;
        this.confirmationButton.style.display = NONE;
        if (this.deleteButton) {
            this.deleteButton.style.display = NONE;
        }
        d3.selectAll(".vertical-line").style(DISPLAY, NONE);
        this.units.style.display = NONE;
        d3.select(".decimal-places").style(DISPLAY, NONE);
        d3.selectAll(".formula-formatting").style(DISPLAY, NONE);
        d3.select(".special").style(DISPLAY, NONE);
        d3.select(".comma").style(DISPLAY, NONE);
    }

    private showFormulaEditorSpecificButtons() {
        if (this.formulaManagerButton) {
            this.formulaManagerButton.style(DISPLAY, null);
        } else {
            this.addFormulaManagerButton();
        }
        this.formulaNameInput.style.display = null;
        this.formulaLabelIcon.style.display = null;
        this.autoCompleteInput.style.display = null;
        this.confirmationButton.style.display = null;
        if (this.deleteButton) {
            this.deleteButton.style.display = null;
        }
        d3.selectAll(".vertical-line").style(DISPLAY, null);
        this.units.style.display = null;
        d3.select(".decimal-places").style(DISPLAY, null);
        d3.selectAll(".formula-formatting").style(DISPLAY, null);
        d3.select(".special").style(DISPLAY, "inline-block");
        d3.select(".comma").style(DISPLAY, null);
    }

    private addCancelButton() {
        let cancelOnClick = (e) => {
            if (Visual.formulaManagerOpen) {
                Visual.formulaManagerOpen = false;
                this.anyManagerChangesMade = false;
                this.settings.formulaCalculation.formulas.forEach(f => {
                    f.tempItalic = undefined;
                    f.tempBold = undefined;
                    f.tempDecimalPlaces = undefined;
                    f.tempFontColor = undefined;
                    f.tempUnits = undefined;
                });
                this.showHideFormulaManagerSettings(false);
                d3.selectAll(".formula-list").remove();
                this.showFormulaEditorSpecificButtons();
                this.viableFormulas.push(...this.formulasToDelete);
                this.formulasToDelete = [];
                this.removeCheckboxIcon();
                this.removeFormulaManagerInputs();
                this.showFormulaEditorSpecificButtons();
            } else {
                (<any>window).shouldKeepPickers = false;
                Visual.formulaEditMode = FormulaEditMode.None;
                Visual.formulaEditPosition = null;
                // this.settings.host.switchFocusModeState(false);
                Visual.isInFocus = false;
                Visual.shouldChangeSize = true;
                Visual.getInstance().constructViewModelAndUpdate(this.settings);
            }
        };
        let cancel = this.addButton("\u2715", "cancel", cancelOnClick, this.parent);
        cancel.classList.remove("formula-button");
    }

    private addButton(text: string, className: string, onClickHandler: (e: any) => void, parent: HTMLDivElement, noButtonClass?: boolean): HTMLButtonElement {
        let button = document.createElement(BUTTON);
        button.innerText = text;
        button.onclick = onClickHandler;
        button.classList.add(className);
        button.classList.add("child");
        if (!noButtonClass) {
            button.classList.add("formula-button");
        }
        parent.appendChild(button);
        return button;
    }

    private addVerticalLine(manager?: boolean) {
        let line = document.createElement(DIV);
        line.className = manager ? "vertical-line-manager" : "vertical-line";
        this.formulaDiv.appendChild(line);
    }

    public setFormulaElements() {
        this.formulaCategory = this.hostElement.querySelector(`.${FORMULA_CATEGORY}`);
        this.formulaElements = d3.select(this.hostElement).selectAll(`.${FORMULA_ELEMENT}`);
    }

    private isFormulaValid(displayErrors: boolean): boolean {
        if (!this.formulaNameInput.checkValidity()) {
            this.formulaNameInput.reportValidity();
            return false;
        }

        this.autoCompleteInput.setCustomValidity("")
        if (!this.autoCompleteInput.checkValidity()) {
            this.autoCompleteInput.reportValidity();
            return false;
        }

        // preprocess formula, find fields, create mapping, create input for parser
        let rawFormulaString = this.autoCompleteInput.value?.trim();
        let fieldRegex = new RegExp(/\[.+?\]/, "g"); // /\[.+?\]/g;  ///\(([^\]]+)\)/; // "";
        let matches = rawFormulaString.match(fieldRegex);
        // create field names mapping
        let fieldsMapping = {};
        let j = 0;
        if (matches) {
            matches.forEach((field) => {
                if (!fieldsMapping[field]) {
                    fieldsMapping[field] = "varName" + j;
                    j++;
                }
            });
        }

        let sanitizedFormulaString = rawFormulaString;
        Object.keys(fieldsMapping).forEach(fieldKey => sanitizedFormulaString = sanitizedFormulaString.split(fieldKey).join(fieldsMapping[fieldKey]));
        // run parser
        try {
            let calc = new Calc();
            calc.compile(sanitizedFormulaString);
            return true;
        }
        catch (ex) {
            if (displayErrors) {
                this.autoCompleteInput.setCustomValidity("formula parsing error");
            }
            return false;
        }
    }

    private addAutoCompleteFunctionality(dateSourceList: string[]) {
        let awesomplete = new Awesomplete(this.autoCompleteInput, {
            list: dateSourceList.map(item => ({
                label: item,
                value: "[" + item + "]"
            })),
            minChars: 1,
            autoFirst: true,
            tabSelect: true,

            filter: (text, input) => {
                return Awesomplete.FILTER_CONTAINS(text, input.match(/[^ ]*$/)[0]);
            },
            item: (text, input) => {
                return Awesomplete.ITEM(text, input.match(/[^ ]*$/)[0]);
            },
            replace: function (text) {
                var before = this.input.value.match(/^.+ \s*|/)[0];
                this.input.value = before + "[" + text + "] ";
            },
        });

        this.autoCompleteInput.addEventListener("awesomplete-selectcomplete", () => {
            this.setConfirmButtonSettings();
        });

        this.autoCompleteInput.addEventListener("keyup", event => {
            if (event.key === "Enter") {
                this.isFormulaValid(true);
            }
            else if (event.key === "Escape") {
                // this.settings.host.switchFocusModeState(false);
                Visual.isInFocus = false;
                Visual.shouldChangeSize = true;
                Visual.getInstance().constructViewModelAndUpdate(this.settings);
            }
            else {
                this.setConfirmationButtonState(this.isFormulaValid(false));
            }
        });
    }

    private setConfirmationButtonState(valid: boolean) {
        this.confirmationButton.disabled = !valid;
        if (this.formulaManagerButton && Visual.formulaEditMode !== FormulaEditMode.Add) {
            this.formulaManagerButton.node().disabled = this.confirmationButton.disabled;
        }
        if (this.confirmationButton.disabled) {
            this.confirmationButton.style.backgroundColor = "rgb(180 224 175)";
            this.confirmationButton.style.borderColor = "gray";
        }
        else {
            this.confirmationButton.style.backgroundColor = null;
            this.confirmationButton.style.borderColor = null;
        }
    }

    private setConfirmButtonSettings() {
        this.confirmationButton.disabled = !this.isFormulaValid(false);
        if (this.formulaManagerButton && Visual.formulaEditMode !== FormulaEditMode.Add) {
            this.formulaManagerButton.node().disabled = this.confirmationButton.disabled;
        }
    }
}