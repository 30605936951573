import { ISelectionId } from "@zebrabi/legacy-library-common/interfaces";
import { VarianceSettings } from "../settings/varianceSettings";
import { ViewModel } from "../settings/viewModel";
import { DataPointType, IHierarchical, AbsoluteChart, CumulativeMinMaxAndOffsets, MinMax, ChartType, Formula, FormulaEditMode, } from "../definitions";
import { DataProperty, EMPTY, NORMAL, ITALIC, ColumnFormat, Scenario, BOLD, ShowAsTableOptions, P, DisplayUnits, DISPLAY, D, AUTO } from "../library/constants";
import { Extremes } from "./extremes";
import { Extreme } from "./extreme";
import { getAdditionalMeasurePropertyFromIndex, getIndexFromAdditionalMeasureDataProperty, isRelativeMeasure } from "../helpers"
import * as d3 from "d3";
import * as drawing from "./../library/drawing";
import * as formatting from "./../library/formatting";
import { getFormattedRelativeDataLabel, getFormattedDataLabel } from "./charts/chart";
import { ChartGroup } from "./chartGroup";
import { getFormula } from "../settings/formulaHelpers";
import { ChartHierarchy } from "./chartHierarchy";
import { Visual } from "../visual";

export class DataPoint {
    public valueLabel: string;
    public referenceValueLabel: string;
    public secondReferenceLabel: string;
    public thirdReferenceLabel: string;
    public fourthReferenceLabel: string;
    public fifthReferenceLabel: string;
    public sixthReferenceLabel: string;
    public seventhReferenceLabel: string;
    public additionalMeasureLabels: string[];
    public absoluteDifference: number;
    public absoluteDifferenceLabel: string;
    public relativeDifference: number;
    public relativeDifferenceLabel: string;
    public secondAbsoluteDifference: number;
    public secondAbsoluteDifferenceLabel: string;
    public secondRelativeDifference: number;
    public secondRelativeDifferenceLabel: string;
    public thirdAbsoluteDifference: number;
    public thirdAbsoluteDifferenceLabel: string;
    public thirdRelativeDifference: number;
    public thirdRelativeDifferenceLabel: string;
    public fourthAbsoluteDifference: number;
    public fourthAbsoluteDifferenceLabel: string;
    public fourthRelativeDifference: number;
    public fourthRelativeDifferenceLabel: string;
    public fifthAbsoluteDifference: number;
    public fifthAbsoluteDifferenceLabel: string;
    public fifthRelativeDifference: number;
    public fifthRelativeDifferenceLabel: string;
    public sixthAbsoluteDifference: number;
    public sixthAbsoluteDifferenceLabel: string;
    public sixthRelativeDifference: number;
    public sixthRelativeDifferenceLabel: string;
    public seventhAbsoluteDifference: number;
    public seventhAbsoluteDifferenceLabel: string;
    public seventhRelativeDifference: number;
    public seventhRelativeDifferenceLabel: string;
    public absoluteCumulative: number;
    public secondAbsoluteCumulative: number;
    public thirdAbsoluteCumulative: number;
    public fourthAbsoluteCumulative: number;
    public fifthAbsoluteCumulative: number;
    public sixthAbsoluteCumulative: number;
    public seventhAbsoluteCumulative: number;
    public valueCumulative: number;
    public referenceValueCumulative: number;
    public secondReferenceValueCumulative: number;
    public thirdReferenceValueCumulative: number;
    public fourthReferenceValueCumulative: number;
    public fifthReferenceValueCumulative: number;
    public sixthReferenceValueCumulative: number;
    public seventhReferenceValueCumulative: number;
    public isInverted: boolean;
    public isInvertedTotal: boolean;
    public isResult: boolean;
    public isSkipped: boolean;
    public isCollapsed: boolean;
    public isSelected: boolean;
    public isBold: boolean;
    public isHighlighted: boolean;
    public associatedDataPoints: DataPoint[];
    public isPlotted: boolean;
    private settings: VarianceSettings;
    public invertedSubtotalCalculation: boolean[];
    public italic: boolean;
    public formula: Formula;
    public commentMarkerDataProperty: DataProperty;

    constructor(public value: number, public referenceValue: number, public secondReferenceValue: number, public thirdReferenceValue: number, public fourthReferenceValue: number, public fifthReferenceValue: number, public sixthReferenceValue: number, public seventhReferenceValue: number, public additionalMeasures: number[], public tooltipsValues: string[], public commentsValues: string[], public category: string, public fullCategory: string,
        public selectionId: ISelectionId, public measureSelectionId: ISelectionId, public dataPointType: DataPointType, private viewModel: ViewModel, public level: number, public parent: IHierarchical, public hierarchyIdentity: string) {
        this.settings = viewModel.settings;
        this.isInverted = this.settings.isInverted(category) !== (<ChartHierarchy>parent)?.isInvertedTotal;
        this.isResult = this.settings.isResult(category);
        this.isSkipped = this.settings.isSkipped(category);
        this.isSelected = false;
        this.isPlotted = false;
        this.isHighlighted = this.settings.isCategoryHighlighted(category);
        this.isBold = this.settings.getCategoryFormatIsBold(category)
        if (this.dataPointType === DataPointType.Total || this.dataPointType === DataPointType.OtherTotal) {
            this.isCollapsed = this.settings.isCollapsed(this.fullCategory);
            this.isInvertedTotal = (<ChartHierarchy>parent)?.isInvertedTotal || false;
        }
        this.viewModel.additionalMeasures.forEach((m, i) => {
            let dp = getAdditionalMeasurePropertyFromIndex(i);
            if (this.settings.getColumnFormat(dp) === ColumnFormat.RelativeDifference && (this.isOther() || this.isOtherTotal())) {
                this.additionalMeasures[i] = undefined;
            }
        });
        this.italic = this.getItalic();
        this.invertedSubtotalCalculation = [];
        if (this.isFormula()) {
            this.formula = this.settings.findFormula(hierarchyIdentity, this.category);
        }
        this.commentMarkerDataProperty = this.settings.getCommentMarkersDataProperty(fullCategory, this.parent?.firstGroupParent?.fullGroup);
    }

    public getValue(dataProperty: DataProperty): number {
        switch (dataProperty) {
            case DataProperty.Value:
                return this.value;
            case DataProperty.ReferenceValue:
                return this.referenceValue;
            case DataProperty.SecondReferenceValue:
                return this.secondReferenceValue;
            case DataProperty.ThirdReferenceValue:
                return this.thirdReferenceValue;
            case DataProperty.FourthReferenceValue:
                return this.fourthReferenceValue;
            case DataProperty.FifthReferenceValue:
                return this.fifthReferenceValue;
            case DataProperty.SixthReferenceValue:
                return this.sixthReferenceValue;
            case DataProperty.SeventhReferenceValue:
                return this.seventhReferenceValue;
            case DataProperty.AbsoluteDifference:
                return this.absoluteDifference;
            case DataProperty.SecondAbsoluteDifference:
                return this.secondAbsoluteDifference;
            case DataProperty.ThirdAbsoluteDifference:
                return this.thirdAbsoluteDifference;
            case DataProperty.FourthAbsoluteDifference:
                return this.fourthAbsoluteDifference;
            case DataProperty.FifthAbsoluteDifference:
                return this.fifthAbsoluteDifference;
            case DataProperty.SixthAbsoluteDifference:
                return this.sixthAbsoluteDifference;
            case DataProperty.SeventhAbsoluteDifference:
                return this.seventhAbsoluteDifference;
            case DataProperty.RelativeDifference:
                return this.relativeDifference;
            case DataProperty.SecondRelativeDifference:
                return this.secondRelativeDifference;
            case DataProperty.ThirdRelativeDifference:
                return this.thirdRelativeDifference;
            case DataProperty.FourthRelativeDifference:
                return this.fourthRelativeDifference;
            case DataProperty.FifthRelativeDifference:
                return this.fifthRelativeDifference;
            case DataProperty.SixthRelativeDifference:
                return this.sixthRelativeDifference;
            case DataProperty.SeventhRelativeDifference:
                return this.seventhRelativeDifference;
            case DataProperty.AdditionalMeasure1:
            case DataProperty.AdditionalMeasure2:
            case DataProperty.AdditionalMeasure3:
            case DataProperty.AdditionalMeasure4:
            case DataProperty.AdditionalMeasure5:
            case DataProperty.AdditionalMeasure6:
            case DataProperty.AdditionalMeasure7:
            case DataProperty.AdditionalMeasure8:
            case DataProperty.AdditionalMeasure9:
            case DataProperty.AdditionalMeasure10:
                return this.additionalMeasures[dataProperty - DataProperty.AdditionalMeasure1];
            case DataProperty.AdditionalMeasure11:
            case DataProperty.AdditionalMeasure12:
            case DataProperty.AdditionalMeasure13:
            case DataProperty.AdditionalMeasure14:
            case DataProperty.AdditionalMeasure15:
            case DataProperty.AdditionalMeasure16:
            case DataProperty.AdditionalMeasure17:
            case DataProperty.AdditionalMeasure18:
            case DataProperty.AdditionalMeasure19:
            case DataProperty.AdditionalMeasure20:
                return this.additionalMeasures[10 + dataProperty - DataProperty.AdditionalMeasure11];
        }
    }

    public getCumulative(dataProperty: DataProperty): number {
        switch (dataProperty) {
            case DataProperty.Value:
                return this.valueCumulative;
            case DataProperty.ReferenceValue:
                return this.referenceValueCumulative;
            case DataProperty.SecondReferenceValue:
                return this.secondReferenceValueCumulative;
            case DataProperty.ThirdReferenceValue:
                return this.thirdReferenceValueCumulative;
            case DataProperty.FourthReferenceValue:
                return this.fourthReferenceValueCumulative;
            case DataProperty.FifthReferenceValue:
                return this.fifthReferenceValueCumulative;
            case DataProperty.SixthReferenceValue:
                return this.sixthReferenceValueCumulative;
            case DataProperty.SeventhReferenceValue:
                return this.seventhReferenceValueCumulative;
            case DataProperty.AbsoluteDifference:
                return this.absoluteCumulative;
            case DataProperty.SecondAbsoluteDifference:
                return this.secondAbsoluteCumulative;
            case DataProperty.ThirdAbsoluteDifference:
                return this.thirdAbsoluteCumulative;
            case DataProperty.FourthAbsoluteDifference:
                return this.fourthAbsoluteCumulative;
            case DataProperty.FifthAbsoluteDifference:
                return this.fifthAbsoluteCumulative;
            case DataProperty.SixthAbsoluteDifference:
                return this.sixthAbsoluteCumulative;
            case DataProperty.SeventhAbsoluteDifference:
                return this.seventhAbsoluteCumulative;
        }
    }

    public getLabel(dataProperty: DataProperty): string {
        switch (dataProperty) {
            case DataProperty.Value:
                return this.valueLabel;
            case DataProperty.ReferenceValue:
                return this.referenceValueLabel;
            case DataProperty.SecondReferenceValue:
                return this.secondReferenceLabel;
            case DataProperty.ThirdReferenceValue:
                return this.thirdReferenceLabel;
            case DataProperty.FourthReferenceValue:
                return this.fourthReferenceLabel;
            case DataProperty.FifthReferenceValue:
                return this.fifthReferenceLabel;
            case DataProperty.SixthReferenceValue:
                return this.sixthReferenceLabel;
            case DataProperty.SeventhReferenceValue:
                return this.seventhReferenceLabel;
            case DataProperty.AbsoluteDifference:
                return this.absoluteDifferenceLabel;
            case DataProperty.SecondAbsoluteDifference:
                return this.secondAbsoluteDifferenceLabel;
            case DataProperty.ThirdAbsoluteDifference:
                return this.thirdAbsoluteDifferenceLabel;
            case DataProperty.FourthAbsoluteDifference:
                return this.fourthAbsoluteDifferenceLabel;
            case DataProperty.FifthAbsoluteDifference:
                return this.fifthAbsoluteDifferenceLabel;
            case DataProperty.SixthAbsoluteDifference:
                return this.sixthAbsoluteDifferenceLabel;
            case DataProperty.SeventhAbsoluteDifference:
                return this.seventhAbsoluteDifferenceLabel;
            case DataProperty.RelativeDifference:
                return this.relativeDifferenceLabel;
            case DataProperty.SecondRelativeDifference:
                return this.secondRelativeDifferenceLabel;
            case DataProperty.ThirdRelativeDifference:
                return this.thirdRelativeDifferenceLabel;
            case DataProperty.FourthRelativeDifference:
                return this.fourthRelativeDifferenceLabel;
            case DataProperty.FifthRelativeDifference:
                return this.fifthRelativeDifferenceLabel;
            case DataProperty.SixthRelativeDifference:
                return this.sixthRelativeDifferenceLabel;
            case DataProperty.SeventhRelativeDifference:
                return this.seventhRelativeDifferenceLabel;
            case DataProperty.AdditionalMeasure1:
            case DataProperty.AdditionalMeasure2:
            case DataProperty.AdditionalMeasure3:
            case DataProperty.AdditionalMeasure4:
            case DataProperty.AdditionalMeasure5:
            case DataProperty.AdditionalMeasure6:
            case DataProperty.AdditionalMeasure7:
            case DataProperty.AdditionalMeasure8:
            case DataProperty.AdditionalMeasure9:
            case DataProperty.AdditionalMeasure10:
                return this.additionalMeasureLabels[dataProperty - DataProperty.AdditionalMeasure1];
            case DataProperty.AdditionalMeasure11:
            case DataProperty.AdditionalMeasure12:
            case DataProperty.AdditionalMeasure13:
            case DataProperty.AdditionalMeasure14:
            case DataProperty.AdditionalMeasure15:
            case DataProperty.AdditionalMeasure16:
            case DataProperty.AdditionalMeasure17:
            case DataProperty.AdditionalMeasure18:
            case DataProperty.AdditionalMeasure19:
            case DataProperty.AdditionalMeasure20:
                return this.additionalMeasureLabels[10 + dataProperty - DataProperty.AdditionalMeasure11];
            case DataProperty.TooltipsMeasure1:
            case DataProperty.TooltipsMeasure2:
            case DataProperty.TooltipsMeasure3:
            case DataProperty.TooltipsMeasure4:
            case DataProperty.TooltipsMeasure5:
                return this.tooltipsValues[dataProperty - DataProperty.TooltipsMeasure1];
        }
    }

    public isNegative(dataProperty: DataProperty): boolean {
        switch (dataProperty) {
            case DataProperty.Value:
                return this.value < 0;
            case DataProperty.ReferenceValue:
                return this.referenceValue < 0;
            case DataProperty.SecondReferenceValue:
                return this.secondReferenceValue < 0;
            case DataProperty.ThirdReferenceValue:
                return this.thirdReferenceValue < 0;
            case DataProperty.FourthReferenceValue:
                return this.fourthReferenceValue < 0;
            case DataProperty.FifthReferenceValue:
                return this.fifthReferenceValue < 0;
            case DataProperty.SixthReferenceValue:
                return this.sixthReferenceValue < 0;
            case DataProperty.SeventhReferenceValue:
                return this.seventhReferenceValue < 0;
            case DataProperty.AbsoluteDifference:
                return this.absoluteDifference < 0;
            case DataProperty.SecondAbsoluteDifference:
                return this.secondAbsoluteDifference < 0;
            case DataProperty.ThirdAbsoluteDifference:
                return this.thirdAbsoluteDifference < 0;
            case DataProperty.FourthAbsoluteDifference:
                return this.fourthAbsoluteDifference < 0;
            case DataProperty.FifthAbsoluteDifference:
                return this.fifthAbsoluteDifference < 0;
            case DataProperty.SixthAbsoluteDifference:
                return this.sixthAbsoluteDifference < 0;
            case DataProperty.SeventhAbsoluteDifference:
                return this.seventhAbsoluteDifference < 0;
            case DataProperty.RelativeDifference:
                return this.relativeDifference < 0;
            case DataProperty.SecondRelativeDifference:
                return this.secondRelativeDifference < 0;
            case DataProperty.ThirdRelativeDifference:
                return this.thirdRelativeDifference < 0;
            case DataProperty.FourthRelativeDifference:
                return this.fourthRelativeDifference < 0;
            case DataProperty.FifthRelativeDifference:
                return this.fifthRelativeDifference < 0;
            case DataProperty.SixthRelativeDifference:
                return this.sixthRelativeDifference < 0;
            case DataProperty.SeventhRelativeDifference:
                return this.seventhRelativeDifference < 0;
            case DataProperty.AdditionalMeasure1:
            case DataProperty.AdditionalMeasure2:
            case DataProperty.AdditionalMeasure3:
            case DataProperty.AdditionalMeasure4:
            case DataProperty.AdditionalMeasure5:
            case DataProperty.AdditionalMeasure6:
            case DataProperty.AdditionalMeasure7:
            case DataProperty.AdditionalMeasure8:
            case DataProperty.AdditionalMeasure9:
            case DataProperty.AdditionalMeasure10:
                return this.additionalMeasures[dataProperty - DataProperty.AdditionalMeasure1] < 0;
            case DataProperty.AdditionalMeasure11:
            case DataProperty.AdditionalMeasure12:
            case DataProperty.AdditionalMeasure13:
            case DataProperty.AdditionalMeasure14:
            case DataProperty.AdditionalMeasure15:
            case DataProperty.AdditionalMeasure16:
            case DataProperty.AdditionalMeasure17:
            case DataProperty.AdditionalMeasure18:
            case DataProperty.AdditionalMeasure19:
            case DataProperty.AdditionalMeasure20:
                return this.additionalMeasures[10 + dataProperty - DataProperty.AdditionalMeasure11] < 0;
        }
    }

    private getDecimalPlaces(dataProperty: DataProperty): number {
        // prioritizing: row level format > column level format > global setting.
        // https://ale-926.atlassian.net/wiki/spaces/ZEBRABI/pages/62750721/Data+labels+display+units+decimal+places
        let decimalPlaces = this.getRowDecimalPlaces();
        if (decimalPlaces === -1) {
            decimalPlaces = this.settings.getColumnDecimalPlaces(dataProperty);
        }
        if (decimalPlaces === -1) {
            if (this.isRowDisplayUnitPercent()) {
                return this.settings.decimalPlacesPercentage;
            } else {
                return this.settings.decimalPlaces;
            }
        }
        return decimalPlaces;
    }

    private getDisplayUnits(dataProperty: DataProperty): DisplayUnits {
        if (this.isFormula() && this.formula) {
            if (this.isRowDisplayUnitPercent()) {
                return DisplayUnits.Percent;
            }
            let formulaFormat = this.formula.units;
            if (formulaFormat !== null && formulaFormat !== undefined && formulaFormat !== DisplayUnits.Default) {
                return formulaFormat;
            }
            return this.settings.getColumnDisplayUnits(dataProperty);
        }

        let categoryFormatDisplayUnits = this.settings.getCategoryFormatDisplayUnits(this.category);
        if (categoryFormatDisplayUnits !== null && categoryFormatDisplayUnits !== undefined && categoryFormatDisplayUnits !== DisplayUnits.Default) {
            return categoryFormatDisplayUnits;
        }
        return this.settings.getColumnDisplayUnits(dataProperty);
    }

    /** This method should be used for getting the category which is then used with the d3 ordinal scale.
     * This prevents the DataPoints to be displayed on top of each other when two DataPoints on different
     * levels in the hierarchy have the same category.
     */
    public getCategory(viewModel: ViewModel): string {
        if (viewModel.hasHierarchy) {
            if (this.isNormal()) {
                return this.fullCategory + this.level;
            }
        }
        return this.category;
    }

    public getCategoryPrefix(): string {
        if ((this.isTotal() || this.isOtherTotal()) && this.isResult) {
            return "=";
        }
        else if (this.isFlatResult()) {
            return "=";
        }
        if ((this.isNormal() || this.isTotal() || this.isOther() || this.isOtherTotal() || this.isFormula()) && this.isInverted) {
            return "-";
        }
        return EMPTY;
    }

    public shouldBlur(): boolean {
        return this.level > 1 && !this.viewModel.settings.proVersionActive();
    }

    public getAbsoluteDifferenceLabel(absoluteDifferenceValue: number, absoluteDifferenceDataUnits: DisplayUnits, absoluteDifferenceDecimalPlaces: number, absoluteDifferencePercentageFormat: string, locale: string, showParenthesis: boolean, hideUnits: boolean): string {
        let absoluteDifferenceLabel = "";

        if (absoluteDifferenceDataUnits === DisplayUnits.Percent && this.settings.isPercentageData) {
            absoluteDifferenceValue *= 100;
        } else if ((this.settings.displayUnits === DisplayUnits.Percent && absoluteDifferenceDataUnits !== DisplayUnits.Percent) && !this.settings.isPercentageData) {
            absoluteDifferenceValue /= 100;
        }

        // the formatter automatically multiplies the number by 100 when the % is provided as format, so we manually divide by 100
        if (absoluteDifferencePercentageFormat?.includes("%") && absoluteDifferenceDataUnits === DisplayUnits.Percent) {
            absoluteDifferenceValue /= 100;
        }

        absoluteDifferenceLabel = absoluteDifferenceLabel = getFormattedDataLabel(absoluteDifferenceValue, absoluteDifferenceDecimalPlaces,
            absoluteDifferenceDataUnits, locale, showParenthesis, true, absoluteDifferencePercentageFormat, hideUnits);

        // Due to a bug in formatter when using percentage formats with a combination of DisplayUnits.Thousands / DisplayUnits.Millions / DisplayUnits.Bilions, we need to manually append the rest of the format characters
        if (!absoluteDifferencePercentageFormat?.includes("%") && absoluteDifferenceDataUnits === DisplayUnits.Thousands || absoluteDifferenceDataUnits === DisplayUnits.Millions || absoluteDifferenceDataUnits === DisplayUnits.Billions) {
            absoluteDifferenceLabel = absoluteDifferenceLabel = absoluteDifferenceLabel.replace(this.settings.labelPercentagePointUnit[1], this.settings.labelPercentagePointUnit);
        }
        return absoluteDifferenceLabel;
    }

    // tslint:disable-next-line: max-func-body-length
    public calculate(locale: string, percentageFormat: string): Extremes {
        let maxAbsoluteLabelWidth: number = 0;
        let minAbsoluteLabelWidth: number = 0;
        let maxSecondAbsoluteLabelWidth: number = 0;
        let minSecondAbsoluteLabelWidth: number = 0;
        let maxThirdAbsoluteLabelWidth: number = 0;
        let minThirdAbsoluteLabelWidth: number = 0;
        let maxFourthAbsoluteLabelWidth: number = 0;
        let minFourthAbsoluteLabelWidth: number = 0;
        let maxFifthAbsoluteLabelWidth: number = 0;
        let minFifthAbsoluteLabelWidth: number = 0;
        let maxSixthAbsoluteLabelWidth: number = 0;
        let minSixthAbsoluteLabelWidth: number = 0;
        let maxSeventhAbsoluteLabelWidth: number = 0;
        let minSeventhAbsoluteLabelWidth: number = 0;
        let maxIntegratedRelativeLabelWidth: number = 0;
        let minIntegratedRelativeLabelWidth: number = 0;
        let maxSecondIntegratedRelativeLabelWidth: number = 0;
        let minSecondIntegratedRelativeLabelWidth: number = 0;
        let maxIntegratedAbsoluteLabelWidth: number = 0;
        let minIntegratedAbsoluteLabelWidth: number = 0;
        let maxSecondIntegratedAbsoluteLabelWidth: number = 0;
        let minSecondIntegratedAbsoluteLabelWidth: number = 0;
        let maxThirdIntegratedAbsoluteLabelWidth: number = 0;
        let minThirdIntegratedAbsoluteLabelWidth: number = 0;
        let maxFourthIntegratedAbsoluteLabelWidth: number = 0;
        let minFourthIntegratedAbsoluteLabelWidth: number = 0;
        let maxFifthIntegratedAbsoluteLabelWidth: number = 0;
        let minFifthIntegratedAbsoluteLabelWidth: number = 0;
        let maxSixthIntegratedAbsoluteLabelWidth: number = 0;
        let minSixthIntegratedAbsoluteLabelWidth: number = 0;
        let maxSeventhIntegratedAbsoluteLabelWidth: number = 0;
        let minSeventhIntegratedAbsoluteLabelWidth: number = 0;
        let showParenthesis = this.settings.showNegativeValuesInParenthesis();
        let absoluteDifferencePercentageFormat = formatting.getPercentageFormatOrNull(this.settings.isPercentageData || this.settings.displayUnits === DisplayUnits.Percent || this.isRowDisplayUnitPercent(), this.settings.labelPercentagePointUnit, true, true);
        let hideUnits = this.settings.shouldHideDataLabelUnits();
        let shouldInvertLabels = this.settings.absoluteChart === AbsoluteChart.CalculationWaterfall;

        let minValueLabelWidth = 0, maxValueLabelWidth = 0;
        if (this.viewModel.HasValueColumn) {
            // Value
            if (this.value !== null) {
                if (!this.isGrandTotal()) {
                    this.viewModel.emptyColumn[DataProperty.Value] = false;
                }
            }
            [minValueLabelWidth, maxValueLabelWidth] = this.formatAndMeasureLabel(this.value, DataProperty.Value, "valueLabel",
                locale, showParenthesis, percentageFormat, hideUnits, this.handleValueLabelWidth);
            if (this.viewModel.HasCommentsColumn && !this.viewModel.HasReferenceColumn) {
                if (this.value < 0) {
                    minValueLabelWidth += 20;
                }
                else {
                    maxValueLabelWidth += 20;
                }
            }
        }

        let minReferenceValueLabelWidth = 0, maxReferenceValueLabelWidth = 0, minRelative = null, maxRelative = null, minRelativeLabelWidth = 0, maxRelativeLabelWidth = 0;
        if (this.viewModel.HasReferenceColumn) {
            // Reference value
            if (this.referenceValue !== null) {
                if (!this.isGrandTotal()) {
                    this.viewModel.emptyColumn[DataProperty.ReferenceValue] = false;
                }
            }
            [minReferenceValueLabelWidth, maxReferenceValueLabelWidth] = this.formatAndMeasureLabel(this.referenceValue, DataProperty.ReferenceValue, "referenceValueLabel",
                locale, showParenthesis, percentageFormat, hideUnits, this.handleValueLabelWidth);

            let value = this.getValueFromComparison(this.settings.valueScenario, this.value, this.settings.valuePosition);
            let referenceValue = this.getValueFromComparison(this.settings.referenceScenario, this.referenceValue, this.settings.referencePosition);
            // Absolute difference
            this.calculateAbsoluteDifference(value, referenceValue, "absoluteDifference");

            if (this.absoluteDifference != null) {
                if (value !== null && referenceValue !== null) {
                    if (!this.isGrandTotal()) {
                        this.viewModel.emptyColumn[DataProperty.AbsoluteDifference] = false;
                    }
                }
                let absoluteDifferenceDataUnits = this.getDisplayUnits(DataProperty.AbsoluteDifference);
                let absoluteDifferenceDecimalPlaces = this.getDecimalPlaces(DataProperty.AbsoluteDifference);
                this.absoluteDifferenceLabel = this.getAbsoluteDifferenceLabel(this.absoluteDifference, absoluteDifferenceDataUnits, absoluteDifferenceDecimalPlaces, absoluteDifferencePercentageFormat, locale, showParenthesis, hideUnits);
                let labelWidth = drawing.measureTextWidth(this.absoluteDifferenceLabel, this.settings.labelFontSize, this.settings.labelFontFamily, NORMAL, NORMAL);
                if ((this.isNegative(DataProperty.AbsoluteDifference) || (this.isInverted && shouldInvertLabels)) && labelWidth > minAbsoluteLabelWidth) {
                    minAbsoluteLabelWidth = labelWidth;
                }
                else if ((!this.isNegative(DataProperty.AbsoluteDifference) || (this.isInverted && shouldInvertLabels)) && labelWidth > maxAbsoluteLabelWidth) {
                    maxAbsoluteLabelWidth = labelWidth;
                }
                if (this.isNegative(DataProperty.Value) && labelWidth > minIntegratedAbsoluteLabelWidth) {
                    minIntegratedAbsoluteLabelWidth = labelWidth;
                }
                else if (!this.isNegative(DataProperty.Value) && labelWidth > maxIntegratedAbsoluteLabelWidth) {
                    maxIntegratedAbsoluteLabelWidth = labelWidth;
                }
            }

            // Relative difference
            [minRelative, maxRelative] = this.calculateRelativeDifference(value, referenceValue, "relativeDifference");
            if (this.relativeDifference !== null) {
                if (!this.isGrandTotal()) {
                    this.viewModel.emptyColumn[DataProperty.RelativeDifference] = false;
                }
                this.relativeDifferenceLabel = getFormattedRelativeDataLabel(this.relativeDifference, this.getDecimalPlaces(DataProperty.RelativeDifference), locale,
                    showParenthesis, hideUnits, this.settings.showPercentageInLabel);
                let labelWidth = drawing.measureTextWidth(this.relativeDifferenceLabel, this.settings.labelFontSize, this.settings.labelFontFamily, NORMAL, ITALIC);
                if (this.isNegative(DataProperty.RelativeDifference) && labelWidth > minRelativeLabelWidth) {
                    minRelativeLabelWidth = labelWidth;
                }
                else if (!this.isNegative(DataProperty.RelativeDifference) && labelWidth > maxRelativeLabelWidth) {
                    maxRelativeLabelWidth = labelWidth;
                }
                if (this.isNegative(DataProperty.Value) && labelWidth > minIntegratedRelativeLabelWidth) {
                    minIntegratedRelativeLabelWidth = labelWidth;
                }
                else if (!this.isNegative(DataProperty.Value) && labelWidth > maxIntegratedRelativeLabelWidth) {
                    maxIntegratedRelativeLabelWidth = labelWidth;
                }
            }
        }

        let minSecondReferenceValueLabelWidth = 0, maxSecondReferenceValueLabelWidth = 0, minSecondRelative = null, maxSecondRelative = null, minSecondRelativeLabelWidth = 0, maxSecondRelativeLabelWidth = 0;
        if (this.viewModel.HasSecondReferenceColumn) {
            // Second reference value
            if (this.secondReferenceValue !== null) {
                if (!this.isGrandTotal()) {
                    this.viewModel.emptyColumn[DataProperty.SecondReferenceValue] = false;
                }
            }
            [minSecondReferenceValueLabelWidth, maxSecondReferenceValueLabelWidth] = this.formatAndMeasureLabel(this.secondReferenceValue, DataProperty.SecondReferenceValue, "secondReferenceLabel",
                locale, showParenthesis, percentageFormat, hideUnits, this.handleValueLabelWidth);

            let value = this.getValueFromComparison(this.settings.secondValueScenario, this.value, this.settings.secondValuePosition);
            let secondReferenceValue = this.getValueFromComparison(this.settings.secondReferenceScenario, this.secondReferenceValue, this.settings.secondReferencePosition);
            // Second asbsolute difference
            this.calculateAbsoluteDifference(value, secondReferenceValue, "secondAbsoluteDifference");

            if (this.secondAbsoluteDifference != null) {
                if (value !== null && secondReferenceValue !== null) {
                    if (!this.isGrandTotal()) {
                        this.viewModel.emptyColumn[DataProperty.SecondAbsoluteDifference] = false;
                    }
                }
                let secondAbsoluteDifferenceDataUnits = this.getDisplayUnits(DataProperty.SecondAbsoluteDifference);
                let secondAbsoluteDifferenceDecimalPlaces = this.getDecimalPlaces(DataProperty.SecondAbsoluteDifference);
                this.secondAbsoluteDifferenceLabel = this.getAbsoluteDifferenceLabel(this.secondAbsoluteDifference, secondAbsoluteDifferenceDataUnits, secondAbsoluteDifferenceDecimalPlaces, absoluteDifferencePercentageFormat, locale, showParenthesis, hideUnits);

                let labelWidth = drawing.measureTextWidth(this.secondAbsoluteDifferenceLabel, this.settings.labelFontSize, this.settings.labelFontFamily, NORMAL, NORMAL);
                if ((this.isNegative(DataProperty.SecondAbsoluteDifference) || (this.isInverted && shouldInvertLabels)) && labelWidth > minSecondAbsoluteLabelWidth) {
                    minSecondAbsoluteLabelWidth = labelWidth;
                }
                else if ((!this.isNegative(DataProperty.SecondAbsoluteDifference) || (this.isInverted && shouldInvertLabels)) && labelWidth > maxSecondAbsoluteLabelWidth) {
                    maxSecondAbsoluteLabelWidth = labelWidth;
                }
                if (this.isNegative(DataProperty.SecondReferenceValue) && labelWidth > minSecondIntegratedAbsoluteLabelWidth) {
                    minSecondIntegratedAbsoluteLabelWidth = labelWidth;
                }
                else if (!this.isNegative(DataProperty.SecondReferenceValue) && labelWidth > maxSecondIntegratedAbsoluteLabelWidth) {
                    maxSecondIntegratedAbsoluteLabelWidth = labelWidth;
                }
            }

            [minSecondRelative, maxSecondRelative] = this.calculateRelativeDifference(value, secondReferenceValue, "secondRelativeDifference");
            if (this.secondRelativeDifference !== null) {
                if (!this.isGrandTotal()) {
                    this.viewModel.emptyColumn[DataProperty.SecondRelativeDifference] = false;
                }
                this.secondRelativeDifferenceLabel = getFormattedRelativeDataLabel(this.secondRelativeDifference, this.getDecimalPlaces(DataProperty.SecondRelativeDifference), locale,
                    showParenthesis, hideUnits, this.settings.showPercentageInLabel);
                let labelWidth = drawing.measureTextWidth(this.secondRelativeDifferenceLabel, this.settings.labelFontSize, this.settings.labelFontFamily, NORMAL, ITALIC);
                if (this.isNegative(DataProperty.SecondRelativeDifference) && labelWidth > minSecondRelativeLabelWidth) {
                    minSecondRelativeLabelWidth = labelWidth;
                }
                else if (!this.isNegative(DataProperty.SecondRelativeDifference) && labelWidth > maxSecondRelativeLabelWidth) {
                    maxSecondRelativeLabelWidth = labelWidth;
                }
                if (this.isNegative(DataProperty.Value) && labelWidth > minSecondIntegratedRelativeLabelWidth) {
                    minSecondIntegratedRelativeLabelWidth = labelWidth;
                }
                else if (!this.isNegative(DataProperty.Value) && labelWidth > maxSecondIntegratedRelativeLabelWidth) {
                    maxSecondIntegratedRelativeLabelWidth = labelWidth;
                }
            }
        }
        let minThirdReferenceValueLabelWidth = 0, maxThirdReferenceValueLabelWidth = 0, minThirdRelative = null, maxThirdRelative = null, minThirdRelativeLabelWidth = 0, maxThirdRelativeLabelWidth = 0;
        if (this.viewModel.HasThirdReferenceColumn) {
            // Third reference value
            if (this.thirdReferenceValue !== null) {
                if (!this.isGrandTotal()) {
                    this.viewModel.emptyColumn[DataProperty.ThirdReferenceValue] = false;
                }
            }
            [minThirdReferenceValueLabelWidth, maxThirdReferenceValueLabelWidth] = this.formatAndMeasureLabel(this.thirdReferenceValue, DataProperty.ThirdReferenceValue, "thirdReferenceLabel",
                locale, showParenthesis, percentageFormat, hideUnits, this.handleValueLabelWidth);

            let value = this.getValueFromComparison(this.settings.thirdValueScenario, this.value, this.settings.thirdValuePosition);
            let thirdReferenceValue = this.getValueFromComparison(this.settings.thirdReferenceScenario, this.thirdReferenceValue, this.settings.thirdReferencePosition);
            // Third asbsolute difference
            this.calculateAbsoluteDifference(value, thirdReferenceValue, "thirdAbsoluteDifference");

            if (this.thirdAbsoluteDifference != null) {
                if (value !== null && thirdReferenceValue !== null) {
                    if (!this.isGrandTotal()) {
                        this.viewModel.emptyColumn[DataProperty.ThirdAbsoluteDifference] = false;
                    }
                }
                let thirdAbsoluteDifferenceDataUnits = this.getDisplayUnits(DataProperty.ThirdAbsoluteDifference);
                let thirdAbsoluteDifferenceDecimalPlaces = this.getDecimalPlaces(DataProperty.ThirdAbsoluteDifference);
                this.thirdAbsoluteDifferenceLabel = this.getAbsoluteDifferenceLabel(this.thirdAbsoluteDifference, thirdAbsoluteDifferenceDataUnits, thirdAbsoluteDifferenceDecimalPlaces, absoluteDifferencePercentageFormat, locale, showParenthesis, hideUnits);

                let labelWidth = drawing.measureTextWidth(this.thirdAbsoluteDifferenceLabel, this.settings.labelFontSize, this.settings.labelFontFamily, NORMAL, NORMAL);
                if ((this.isNegative(DataProperty.ThirdAbsoluteDifference) || (this.isInverted && shouldInvertLabels)) && labelWidth > minThirdAbsoluteLabelWidth) {
                    minThirdAbsoluteLabelWidth = labelWidth;
                }
                else if ((!this.isNegative(DataProperty.ThirdAbsoluteDifference) || (this.isInverted && shouldInvertLabels)) && labelWidth > maxThirdAbsoluteLabelWidth) {
                    maxThirdAbsoluteLabelWidth = labelWidth;
                }
                if (this.isNegative(DataProperty.ThirdReferenceValue) && labelWidth > minThirdIntegratedAbsoluteLabelWidth) {
                    minThirdIntegratedAbsoluteLabelWidth = labelWidth;
                }
                else if (!this.isNegative(DataProperty.ThirdReferenceValue) && labelWidth > maxThirdIntegratedAbsoluteLabelWidth) {
                    maxThirdIntegratedAbsoluteLabelWidth = labelWidth;
                }
            }

            [minThirdRelative, maxThirdRelative] = this.calculateRelativeDifference(value, thirdReferenceValue, "thirdRelativeDifference");

            if (this.thirdRelativeDifference !== null) {
                if (!this.isGrandTotal()) {
                    this.viewModel.emptyColumn[DataProperty.ThirdRelativeDifference] = false;
                }
                this.thirdRelativeDifferenceLabel = getFormattedRelativeDataLabel(this.thirdRelativeDifference, this.getDecimalPlaces(DataProperty.ThirdRelativeDifference), locale,
                    showParenthesis, hideUnits, this.settings.showPercentageInLabel);
                let labelWidth = drawing.measureTextWidth(this.thirdRelativeDifferenceLabel, this.settings.labelFontSize, this.settings.labelFontFamily, NORMAL, ITALIC);
                if (this.isNegative(DataProperty.ThirdRelativeDifference) && labelWidth > minThirdRelativeLabelWidth) {
                    minThirdRelativeLabelWidth = labelWidth;
                }
                else if (!this.isNegative(DataProperty.ThirdRelativeDifference) && labelWidth > maxThirdRelativeLabelWidth) {
                    maxThirdRelativeLabelWidth = labelWidth;
                }
                if (this.isNegative(DataProperty.Value) && labelWidth > minThirdRelativeLabelWidth) {
                    minThirdRelativeLabelWidth = labelWidth;
                }
                else if (!this.isNegative(DataProperty.Value) && labelWidth > maxThirdRelativeLabelWidth) {
                    maxThirdRelativeLabelWidth = labelWidth;
                }
            }
        }
        let minFourthReferenceValueLabelWidth = 0, maxFourthReferenceValueLabelWidth = 0, minFourthRelative = null, maxFourthRelative = null, minFourthRelativeLabelWidth = 0, maxFourthRelativeLabelWidth = 0;
        if (this.viewModel.HasFourthReferenceColumn) {
            // Fourth reference value
            if (this.fourthReferenceValue !== null) {
                if (!this.isGrandTotal()) {
                    this.viewModel.emptyColumn[DataProperty.FourthReferenceValue] = false;
                }
            }
            [minFourthReferenceValueLabelWidth, maxFourthReferenceValueLabelWidth] = this.formatAndMeasureLabel(this.fourthReferenceValue, DataProperty.FourthReferenceValue, "fourthReferenceLabel",
                locale, showParenthesis, percentageFormat, hideUnits, this.handleValueLabelWidth);

            let value = this.getValueFromComparison(this.settings.fourthValueScenario, this.value, this.settings.fourthValuePosition);
            let fourthReferenceValue = this.getValueFromComparison(this.settings.fourthReferenceScenario, this.fourthReferenceValue, this.settings.fourthReferencePosition);
            // fourth asbsolute difference
            this.calculateAbsoluteDifference(value, fourthReferenceValue, "fourthAbsoluteDifference");

            if (this.fourthAbsoluteDifference != null) {
                if (value !== null && fourthReferenceValue !== null) {
                    if (!this.isGrandTotal()) {
                        this.viewModel.emptyColumn[DataProperty.FourthAbsoluteDifference] = false;
                    }
                }
                let fourthAbsoluteDifferenceDataUnits = this.getDisplayUnits(DataProperty.FourthAbsoluteDifference);
                let fourthAbsoluteDifferenceDecimalPlaces = this.getDecimalPlaces(DataProperty.FourthAbsoluteDifference);
                this.fourthAbsoluteDifferenceLabel = this.getAbsoluteDifferenceLabel(this.fourthAbsoluteDifference, fourthAbsoluteDifferenceDataUnits, fourthAbsoluteDifferenceDecimalPlaces, absoluteDifferencePercentageFormat, locale, showParenthesis, hideUnits);

                let labelWidth = drawing.measureTextWidth(this.fourthAbsoluteDifferenceLabel, this.settings.labelFontSize, this.settings.labelFontFamily, NORMAL, NORMAL);
                if ((this.isNegative(DataProperty.FourthAbsoluteDifference) || (this.isInverted && shouldInvertLabels)) && labelWidth > minFourthAbsoluteLabelWidth) {
                    minFourthAbsoluteLabelWidth = labelWidth;
                }
                else if ((!this.isNegative(DataProperty.FourthAbsoluteDifference) || (this.isInverted && shouldInvertLabels)) && labelWidth > maxFourthAbsoluteLabelWidth) {
                    maxFourthAbsoluteLabelWidth = labelWidth;
                }
                if (this.isNegative(DataProperty.FourthReferenceValue) && labelWidth > minFourthIntegratedAbsoluteLabelWidth) {
                    minFourthIntegratedAbsoluteLabelWidth = labelWidth;
                }
                else if (!this.isNegative(DataProperty.FourthReferenceValue) && labelWidth > maxFourthIntegratedAbsoluteLabelWidth) {
                    maxFourthIntegratedAbsoluteLabelWidth = labelWidth;
                }
            }

            [minFourthRelative, maxFourthRelative] = this.calculateRelativeDifference(value, fourthReferenceValue, "fourthRelativeDifference");

            if (this.fourthRelativeDifference !== null) {
                if (!this.isGrandTotal()) {
                    this.viewModel.emptyColumn[DataProperty.FourthRelativeDifference] = false;
                }
                this.fourthRelativeDifferenceLabel = getFormattedRelativeDataLabel(this.fourthRelativeDifference, this.getDecimalPlaces(DataProperty.FourthRelativeDifference), locale,
                    showParenthesis, hideUnits, this.settings.showPercentageInLabel);
                let labelWidth = drawing.measureTextWidth(this.fourthRelativeDifferenceLabel, this.settings.labelFontSize, this.settings.labelFontFamily, NORMAL, ITALIC);
                if (this.isNegative(DataProperty.FourthRelativeDifference) && labelWidth > minFourthRelativeLabelWidth) {
                    minFourthRelativeLabelWidth = labelWidth;
                }
                else if (!this.isNegative(DataProperty.FourthRelativeDifference) && labelWidth > maxFourthRelativeLabelWidth) {
                    maxFourthRelativeLabelWidth = labelWidth;
                }
                if (this.isNegative(DataProperty.Value) && labelWidth > minFourthRelativeLabelWidth) {
                    minFourthRelativeLabelWidth = labelWidth;
                }
                else if (!this.isNegative(DataProperty.Value) && labelWidth > maxFourthRelativeLabelWidth) {
                    maxFourthRelativeLabelWidth = labelWidth;
                }
            }
        }
        let minFifthReferenceValueLabelWidth = 0, maxFifthReferenceValueLabelWidth = 0, minFifthRelative = null, maxFifthRelative = null, minFifthRelativeLabelWidth = 0, maxFifthRelativeLabelWidth = 0;
        if (this.viewModel.HasFifthReferenceColumn) {
            // Fifth reference value
            if (this.fifthReferenceValue !== null) {
                if (!this.isGrandTotal()) {
                    this.viewModel.emptyColumn[DataProperty.FifthReferenceValue] = false;
                }
            }
            [minFifthReferenceValueLabelWidth, maxFifthReferenceValueLabelWidth] = this.formatAndMeasureLabel(this.fifthReferenceValue, DataProperty.FifthReferenceValue, "fifthReferenceLabel",
                locale, showParenthesis, percentageFormat, hideUnits, this.handleValueLabelWidth);

            let value = this.getValueFromComparison(this.settings.fifthValueScenario, this.value, this.settings.fifthValuePosition);
            let fifthReferenceValue = this.getValueFromComparison(this.settings.fifthReferenceScenario, this.fifthReferenceValue, this.settings.fifthReferencePosition);
            // Fifth asbsolute difference
            this.calculateAbsoluteDifference(value, fifthReferenceValue, "fifthAbsoluteDifference");

            if (this.fifthAbsoluteDifference != null) {
                if (value !== null && fifthReferenceValue !== null) {
                    if (!this.isGrandTotal()) {
                        this.viewModel.emptyColumn[DataProperty.FifthAbsoluteDifference] = false;
                    }
                }
                let fifthAbsoluteDifferenceDataUnits = this.getDisplayUnits(DataProperty.FifthAbsoluteDifference);
                let fifthAbsoluteDifferenceDecimalPlaces = this.getDecimalPlaces(DataProperty.FifthAbsoluteDifference);
                this.fifthAbsoluteDifferenceLabel = this.getAbsoluteDifferenceLabel(this.fifthAbsoluteDifference, fifthAbsoluteDifferenceDataUnits, fifthAbsoluteDifferenceDecimalPlaces, absoluteDifferencePercentageFormat, locale, showParenthesis, hideUnits);

                let labelWidth = drawing.measureTextWidth(this.fifthAbsoluteDifferenceLabel, this.settings.labelFontSize, this.settings.labelFontFamily, NORMAL, NORMAL);
                if ((this.isNegative(DataProperty.FifthAbsoluteDifference) || (this.isInverted && shouldInvertLabels)) && labelWidth > minFifthAbsoluteLabelWidth) {
                    minFifthAbsoluteLabelWidth = labelWidth;
                }
                else if ((!this.isNegative(DataProperty.FifthAbsoluteDifference) || (this.isInverted && shouldInvertLabels)) && labelWidth > maxFifthAbsoluteLabelWidth) {
                    maxFifthAbsoluteLabelWidth = labelWidth;
                }
                if (this.isNegative(DataProperty.FifthReferenceValue) && labelWidth > minFifthIntegratedAbsoluteLabelWidth) {
                    minFifthIntegratedAbsoluteLabelWidth = labelWidth;
                }
                else if (!this.isNegative(DataProperty.FifthReferenceValue) && labelWidth > maxFifthIntegratedAbsoluteLabelWidth) {
                    maxFifthIntegratedAbsoluteLabelWidth = labelWidth;
                }
            }

            [minFifthRelative, maxFifthRelative] = this.calculateRelativeDifference(value, fifthReferenceValue, "fifthRelativeDifference");

            if (this.fifthRelativeDifference !== null) {
                if (!this.isGrandTotal()) {
                    this.viewModel.emptyColumn[DataProperty.FifthRelativeDifference] = false;
                }
                this.fifthRelativeDifferenceLabel = getFormattedRelativeDataLabel(this.fifthRelativeDifference, this.getDecimalPlaces(DataProperty.FifthRelativeDifference), locale,
                    showParenthesis, hideUnits, this.settings.showPercentageInLabel);
                let labelWidth = drawing.measureTextWidth(this.fifthRelativeDifferenceLabel, this.settings.labelFontSize, this.settings.labelFontFamily, NORMAL, ITALIC);
                if (this.isNegative(DataProperty.FifthRelativeDifference) && labelWidth > minFifthRelativeLabelWidth) {
                    minFifthRelativeLabelWidth = labelWidth;
                }
                else if (!this.isNegative(DataProperty.FifthRelativeDifference) && labelWidth > maxFifthRelativeLabelWidth) {
                    maxFifthRelativeLabelWidth = labelWidth;
                }
                if (this.isNegative(DataProperty.Value) && labelWidth > minFifthRelativeLabelWidth) {
                    minFifthRelativeLabelWidth = labelWidth;
                }
                else if (!this.isNegative(DataProperty.Value) && labelWidth > maxFifthRelativeLabelWidth) {
                    maxFifthRelativeLabelWidth = labelWidth;
                }
            }
        }
        let minSixthReferenceValueLabelWidth = 0, maxSixthReferenceValueLabelWidth = 0, minSixthRelative = null, maxSixthRelative = null, minSixthRelativeLabelWidth = 0, maxSixthRelativeLabelWidth = 0;
        if (this.viewModel.HasSixthReferenceColumn) {
            // Sixth reference value
            if (this.sixthReferenceValue !== null) {
                if (!this.isGrandTotal()) {
                    this.viewModel.emptyColumn[DataProperty.SixthReferenceValue] = false;
                }
            }
            [minSixthReferenceValueLabelWidth, maxSixthReferenceValueLabelWidth] = this.formatAndMeasureLabel(this.sixthReferenceValue, DataProperty.SixthReferenceValue, "sixthReferenceLabel",
                locale, showParenthesis, percentageFormat, hideUnits, this.handleValueLabelWidth);

            let value = this.getValueFromComparison(this.settings.sixthValueScenario, this.value, this.settings.sixthValuePosition);
            let sixthReferenceValue = this.getValueFromComparison(this.settings.sixthReferenceScenario, this.sixthReferenceValue, this.settings.sixthReferencePosition);
            // Sixth asbsolute difference
            this.calculateAbsoluteDifference(value, sixthReferenceValue, "sixthAbsoluteDifference");

            if (this.sixthAbsoluteDifference != null) {
                if (value !== null && sixthReferenceValue !== null) {
                    if (!this.isGrandTotal()) {
                        this.viewModel.emptyColumn[DataProperty.SixthAbsoluteDifference] = false;
                    }
                }
                let sixthAbsoluteDifferenceDataUnits = this.getDisplayUnits(DataProperty.SixthAbsoluteDifference);
                let sixthAbsoluteDifferenceDecimalPlaces = this.getDecimalPlaces(DataProperty.SixthAbsoluteDifference);
                this.sixthAbsoluteDifferenceLabel = this.getAbsoluteDifferenceLabel(this.sixthAbsoluteDifference, sixthAbsoluteDifferenceDataUnits, sixthAbsoluteDifferenceDecimalPlaces, absoluteDifferencePercentageFormat, locale, showParenthesis, hideUnits);

                let labelWidth = drawing.measureTextWidth(this.sixthAbsoluteDifferenceLabel, this.settings.labelFontSize, this.settings.labelFontFamily, NORMAL, NORMAL);
                if ((this.isNegative(DataProperty.SixthAbsoluteDifference) || (this.isInverted && shouldInvertLabels)) && labelWidth > minSixthAbsoluteLabelWidth) {
                    minSixthAbsoluteLabelWidth = labelWidth;
                }
                else if ((!this.isNegative(DataProperty.SixthAbsoluteDifference) || (this.isInverted && shouldInvertLabels)) && labelWidth > maxSixthAbsoluteLabelWidth) {
                    maxSixthAbsoluteLabelWidth = labelWidth;
                }
                if (this.isNegative(DataProperty.SixthReferenceValue) && labelWidth > minSixthIntegratedAbsoluteLabelWidth) {
                    minSixthIntegratedAbsoluteLabelWidth = labelWidth;
                }
                else if (!this.isNegative(DataProperty.SixthReferenceValue) && labelWidth > maxSixthIntegratedAbsoluteLabelWidth) {
                    maxSixthIntegratedAbsoluteLabelWidth = labelWidth;
                }
            }

            [minSixthRelative, maxSixthRelative] = this.calculateRelativeDifference(value, sixthReferenceValue, "sixthRelativeDifference");

            if (this.sixthRelativeDifference !== null) {
                if (!this.isGrandTotal()) {
                    this.viewModel.emptyColumn[DataProperty.SixthRelativeDifference] = false;
                }
                this.sixthRelativeDifferenceLabel = getFormattedRelativeDataLabel(this.sixthRelativeDifference, this.getDecimalPlaces(DataProperty.SixthRelativeDifference), locale,
                    showParenthesis, hideUnits, this.settings.showPercentageInLabel);
                let labelWidth = drawing.measureTextWidth(this.sixthRelativeDifferenceLabel, this.settings.labelFontSize, this.settings.labelFontFamily, NORMAL, ITALIC);
                if (this.isNegative(DataProperty.SixthRelativeDifference) && labelWidth > minSixthRelativeLabelWidth) {
                    minSixthRelativeLabelWidth = labelWidth;
                }
                else if (!this.isNegative(DataProperty.SixthRelativeDifference) && labelWidth > maxSixthRelativeLabelWidth) {
                    maxSixthRelativeLabelWidth = labelWidth;
                }
                if (this.isNegative(DataProperty.Value) && labelWidth > minSixthRelativeLabelWidth) {
                    minSixthRelativeLabelWidth = labelWidth;
                }
                else if (!this.isNegative(DataProperty.Value) && labelWidth > maxSixthRelativeLabelWidth) {
                    maxSixthRelativeLabelWidth = labelWidth;
                }
            }
        }
        let minSeventhReferenceValueLabelWidth = 0, maxSeventhReferenceValueLabelWidth = 0, minSeventhRelative = null, maxSeventhRelative = null, minSeventhRelativeLabelWidth = 0, maxSeventhRelativeLabelWidth = 0;
        if (this.viewModel.HasSeventhReferenceColumn) {
            // Seventh reference value
            if (this.seventhReferenceValue !== null) {
                if (!this.isGrandTotal()) {
                    this.viewModel.emptyColumn[DataProperty.SeventhReferenceValue] = false;
                }
            }
            [minSeventhReferenceValueLabelWidth, maxSeventhReferenceValueLabelWidth] = this.formatAndMeasureLabel(this.seventhReferenceValue, DataProperty.SeventhReferenceValue, "seventhReferenceLabel",
                locale, showParenthesis, percentageFormat, hideUnits, this.handleValueLabelWidth);

            let value = this.getValueFromComparison(this.settings.seventhValueScenario, this.value, this.settings.seventhValuePosition);
            let seventhReferenceValue = this.getValueFromComparison(this.settings.seventhReferenceScenario, this.seventhReferenceValue, this.settings.seventhReferencePosition);
            // Seventh asbsolute difference
            this.calculateAbsoluteDifference(value, seventhReferenceValue, "seventhAbsoluteDifference");

            if (this.seventhAbsoluteDifference != null) {
                if (value !== null && seventhReferenceValue !== null) {
                    if (!this.isGrandTotal()) {
                        this.viewModel.emptyColumn[DataProperty.SeventhAbsoluteDifference] = false;
                    }
                }
                let seventhAbsoluteDifferenceDataUnits = this.getDisplayUnits(DataProperty.SeventhAbsoluteDifference);
                let seventhAbsoluteDifferenceDecimalPlaces = this.getDecimalPlaces(DataProperty.SeventhAbsoluteDifference);
                this.seventhAbsoluteDifferenceLabel = this.getAbsoluteDifferenceLabel(this.seventhAbsoluteDifference, seventhAbsoluteDifferenceDataUnits, seventhAbsoluteDifferenceDecimalPlaces, absoluteDifferencePercentageFormat, locale, showParenthesis, hideUnits);

                let labelWidth = drawing.measureTextWidth(this.seventhAbsoluteDifferenceLabel, this.settings.labelFontSize, this.settings.labelFontFamily, NORMAL, NORMAL);
                if ((this.isNegative(DataProperty.SeventhAbsoluteDifference) || (this.isInverted && shouldInvertLabels)) && labelWidth > minSeventhAbsoluteLabelWidth) {
                    minSeventhAbsoluteLabelWidth = labelWidth;
                }
                else if ((!this.isNegative(DataProperty.SeventhAbsoluteDifference) || (this.isInverted && shouldInvertLabels)) && labelWidth > maxSeventhAbsoluteLabelWidth) {
                    maxSeventhAbsoluteLabelWidth = labelWidth;
                }
                if (this.isNegative(DataProperty.SeventhReferenceValue) && labelWidth > minSeventhIntegratedAbsoluteLabelWidth) {
                    minSeventhIntegratedAbsoluteLabelWidth = labelWidth;
                }
                else if (!this.isNegative(DataProperty.SeventhReferenceValue) && labelWidth > maxSeventhIntegratedAbsoluteLabelWidth) {
                    maxSeventhIntegratedAbsoluteLabelWidth = labelWidth;
                }
            }

            [minSeventhRelative, maxSeventhRelative] = this.calculateRelativeDifference(value, seventhReferenceValue, "seventhRelativeDifference");

            if (this.seventhRelativeDifference !== null) {
                if (!this.isGrandTotal()) {
                    this.viewModel.emptyColumn[DataProperty.SeventhRelativeDifference] = false;
                }
                this.seventhRelativeDifferenceLabel = getFormattedRelativeDataLabel(this.seventhRelativeDifference, this.getDecimalPlaces(DataProperty.SeventhRelativeDifference), locale,
                    showParenthesis, hideUnits, this.settings.showPercentageInLabel);
                let labelWidth = drawing.measureTextWidth(this.seventhRelativeDifferenceLabel, this.settings.labelFontSize, this.settings.labelFontFamily, NORMAL, ITALIC);
                if (this.isNegative(DataProperty.SeventhRelativeDifference) && labelWidth > minSeventhRelativeLabelWidth) {
                    minSeventhRelativeLabelWidth = labelWidth;
                }
                else if (!this.isNegative(DataProperty.SeventhRelativeDifference) && labelWidth > maxSeventhRelativeLabelWidth) {
                    maxSeventhRelativeLabelWidth = labelWidth;
                }
                if (this.isNegative(DataProperty.Value) && labelWidth > minSeventhRelativeLabelWidth) {
                    minSeventhRelativeLabelWidth = labelWidth;
                }
                else if (!this.isNegative(DataProperty.Value) && labelWidth > maxSeventhRelativeLabelWidth) {
                    maxSeventhRelativeLabelWidth = labelWidth;
                }
            }
        }
        let relativeValues = [this.relativeDifference];
        if (this.secondRelativeDifference) {
            relativeValues.push(this.secondRelativeDifference);
        }
        if (this.thirdRelativeDifference) {
            relativeValues.push(this.thirdRelativeDifference);
        }
        if (this.fourthRelativeDifference) {
            relativeValues.push(this.fourthRelativeDifference);
        }
        if (this.fifthRelativeDifference) {
            relativeValues.push(this.fifthRelativeDifference);
        }
        if (this.sixthRelativeDifference) {
            relativeValues.push(this.sixthRelativeDifference);
        }
        if (this.seventhRelativeDifference) {
            relativeValues.push(this.seventhRelativeDifference);
        }

        this.additionalMeasureLabels = [];
        let additionalMeasureExtremes: Extreme[] = [];
        for (let i = 0; i < this.viewModel.additionalMeasures.length; i++) {
            let value = this.additionalMeasures[i];
            let dp = getAdditionalMeasurePropertyFromIndex(i);
            if (value !== undefined && value !== null) {
                if (!this.isGrandTotal()) {
                    this.viewModel.emptyColumn[dp] = false;
                }
            }
            let columnFormat = this.settings.getColumnFormat(dp);
            let decimalPlaces = this.getDecimalPlaces(dp);
            let percentageFormat = this.settings.getPercentageFormat(dp);
            let label: string;
            let isVariance = (columnFormat === ColumnFormat.AbsoluteDifference || columnFormat === ColumnFormat.RelativeDifference);
            let displayUnits: DisplayUnits;
            let rowLevelDisplayUnits: DisplayUnits;
            let columnLevelDisplayUnits = this.settings.getColumnDisplayUnits(dp);
            if (this.isFormula()) {
                if (this.formula) {
                    rowLevelDisplayUnits = this.formula.units;
                }
            } else {
                rowLevelDisplayUnits = this.settings.getCategoryFormatDisplayUnits(this.category);
            }

            if (columnFormat === ColumnFormat.RelativeDifference) {
                displayUnits = DisplayUnits.Relative;
            }
            else if (columnFormat === ColumnFormat.Percent) {
                displayUnits = DisplayUnits.Percent;
            }
            else {
                displayUnits = this.getDisplayUnits(dp);
            }

            if ((this.isOther() || this.isOtherTotal()) && this.settings.getColumnFormat(dp) === ColumnFormat.RelativeDifference) {
                label = "";
            }

            else if (this.isRowDisplayUnitPercent()) {
                label = getFormattedDataLabel(value, decimalPlaces, DisplayUnits.Percent, locale, showParenthesis, false, "", hideUnits);
                if (label) {
                    label = label + "%";
                }
            }
            else {
                if (percentageFormat && value !== null && percentageFormat?.indexOf("%") !== -1 &&
                    ((columnLevelDisplayUnits !== DisplayUnits.Percent) || (columnLevelDisplayUnits === DisplayUnits.Percent && (rowLevelDisplayUnits === DisplayUnits.Default || rowLevelDisplayUnits === null))
                    ) && this.settings.getColumnFormat(dp) !== ColumnFormat.RelativeDifference && typeof value !== "string") {
                    value /= 100;
                }
                label = getFormattedDataLabel(value, decimalPlaces, displayUnits, locale, showParenthesis, isVariance, percentageFormat,
                    hideUnits && (columnFormat !== ColumnFormat.Percent && columnFormat !== ColumnFormat.RelativeDifference));
            }
            this.additionalMeasureLabels.push(label);
            let labelWidth = drawing.measureTextWidth(label, this.settings.labelFontSize, this.settings.labelFontFamily, NORMAL, NORMAL);
            let minLabelOffset = 0, maxLabelOffset = 0;
            if (this.additionalMeasures[i] < 0) {
                minLabelOffset = this.getLabelOffset(labelWidth);
            }
            else {
                maxLabelOffset = this.getLabelOffset(labelWidth);
            }
            let shouldSkipDataPointForExtremesCalculation = (columnFormat === ColumnFormat.Percent || columnFormat === ColumnFormat.Value) && this.isGrandTotal();
            additionalMeasureExtremes.push(new Extreme(
                Math.min(0, shouldSkipDataPointForExtremesCalculation ? 0 : this.additionalMeasures[i]),
                Math.max(0, shouldSkipDataPointForExtremesCalculation ? 0 : this.additionalMeasures[i]),
                minLabelOffset,
                maxLabelOffset
            ));
            if (this.settings.getAdditionalMeasureChartType(dp) === ChartType.PlusMinusDot) {
                relativeValues.push(this.additionalMeasures[i]);
            }
        }

        return new Extremes(
            new Extreme(
                Math.min(0, this.getExtremeValue(this.value, DataProperty.Value)),
                Math.max(0, this.getExtremeValue(this.value, DataProperty.Value)),
                this.isGrandTotal() ? 0 : this.getLabelOffset(minValueLabelWidth),
                this.isGrandTotal() ? 0 : this.getLabelOffset(maxValueLabelWidth)),
            new Extreme(
                Math.min(0, this.getExtremeValue(this.referenceValue, DataProperty.ReferenceValue)),
                Math.max(0, this.getExtremeValue(this.referenceValue, DataProperty.ReferenceValue)),
                this.getLabelOffset(minReferenceValueLabelWidth),
                this.getLabelOffset(maxReferenceValueLabelWidth)),
            new Extreme(
                Math.min(0, this.getExtremeValue(this.secondReferenceValue, DataProperty.SecondReferenceValue)),
                Math.max(0, this.getExtremeValue(this.secondReferenceValue, DataProperty.SecondReferenceValue)),
                this.getLabelOffset(minSecondReferenceValueLabelWidth),
                this.getLabelOffset(maxSecondReferenceValueLabelWidth)),
            new Extreme(
                Math.min(0, this.getExtremeValue(this.thirdReferenceValue, DataProperty.ThirdReferenceValue)),
                Math.max(0, this.getExtremeValue(this.thirdReferenceValue, DataProperty.ThirdReferenceValue)),
                this.getLabelOffset(minThirdReferenceValueLabelWidth),
                this.getLabelOffset(maxThirdReferenceValueLabelWidth)),
            new Extreme(
                Math.min(0, this.getExtremeValue(this.fourthReferenceValue, DataProperty.FourthReferenceValue)),
                Math.max(0, this.getExtremeValue(this.fourthReferenceValue, DataProperty.FourthReferenceValue)),
                this.getLabelOffset(minFourthReferenceValueLabelWidth),
                this.getLabelOffset(maxFourthReferenceValueLabelWidth)),
            new Extreme(
                Math.min(0, this.getExtremeValue(this.fifthReferenceValue, DataProperty.FifthReferenceValue)),
                Math.max(0, this.getExtremeValue(this.fifthReferenceValue, DataProperty.FifthReferenceValue)),
                this.getLabelOffset(minFifthReferenceValueLabelWidth),
                this.getLabelOffset(maxFifthReferenceValueLabelWidth)),
            new Extreme(
                Math.min(0, this.getExtremeValue(this.sixthReferenceValue, DataProperty.SixthReferenceValue)),
                Math.max(0, this.getExtremeValue(this.sixthReferenceValue, DataProperty.SixthReferenceValue)),
                this.getLabelOffset(minSixthReferenceValueLabelWidth),
                this.getLabelOffset(maxSixthReferenceValueLabelWidth)),
            new Extreme(
                Math.min(0, this.getExtremeValue(this.seventhReferenceValue, DataProperty.SeventhReferenceValue)),
                Math.max(0, this.getExtremeValue(this.seventhReferenceValue, DataProperty.SeventhReferenceValue)),
                this.getLabelOffset(minSeventhReferenceValueLabelWidth),
                this.getLabelOffset(maxSeventhReferenceValueLabelWidth)),
            new Extreme(
                Math.min(this.absoluteDifference, 0),
                Math.max(this.absoluteDifference, 0),
                this.getLabelOffset(minAbsoluteLabelWidth),
                this.getLabelOffset(maxAbsoluteLabelWidth)),
            new Extreme(
                Math.min(this.secondAbsoluteDifference, 0),
                Math.max(this.secondAbsoluteDifference, 0),
                this.getLabelOffset(minSecondAbsoluteLabelWidth),
                this.getLabelOffset(maxSecondAbsoluteLabelWidth)),
            new Extreme(
                Math.min(this.thirdAbsoluteDifference, 0),
                Math.max(this.thirdAbsoluteDifference, 0),
                this.getLabelOffset(minThirdAbsoluteLabelWidth),
                this.getLabelOffset(maxThirdAbsoluteLabelWidth)),
            new Extreme(
                Math.min(this.fourthAbsoluteDifference, 0),
                Math.max(this.fourthAbsoluteDifference, 0),
                this.getLabelOffset(minFourthAbsoluteLabelWidth),
                this.getLabelOffset(maxFourthAbsoluteLabelWidth)),
            new Extreme(
                Math.min(this.fifthAbsoluteDifference, 0),
                Math.max(this.fifthAbsoluteDifference, 0),
                this.getLabelOffset(minFifthAbsoluteLabelWidth),
                this.getLabelOffset(maxFifthAbsoluteLabelWidth)),
            new Extreme(
                Math.min(this.sixthAbsoluteDifference, 0),
                Math.max(this.sixthAbsoluteDifference, 0),
                this.getLabelOffset(minSixthAbsoluteLabelWidth),
                this.getLabelOffset(maxSixthAbsoluteLabelWidth)),
            new Extreme(
                Math.min(this.seventhAbsoluteDifference, 0),
                Math.max(this.seventhAbsoluteDifference, 0),
                this.getLabelOffset(minSeventhAbsoluteLabelWidth),
                this.getLabelOffset(maxSeventhAbsoluteLabelWidth)),
            new Extreme(
                minRelative,
                maxRelative,
                this.getLabelOffset(minRelativeLabelWidth),
                this.getLabelOffset(maxRelativeLabelWidth)),
            new Extreme(
                minSecondRelative,
                maxSecondRelative,
                this.getLabelOffset(minSecondRelativeLabelWidth),
                this.getLabelOffset(maxSecondRelativeLabelWidth)),
            new Extreme(
                minThirdRelative,
                maxThirdRelative,
                this.getLabelOffset(minThirdRelativeLabelWidth),
                this.getLabelOffset(maxThirdRelativeLabelWidth)),
            new Extreme(
                minFourthRelative,
                maxFourthRelative,
                this.getLabelOffset(minFourthRelativeLabelWidth),
                this.getLabelOffset(maxFourthRelativeLabelWidth)),
            new Extreme(
                minFifthRelative,
                maxFifthRelative,
                this.getLabelOffset(minFifthRelativeLabelWidth),
                this.getLabelOffset(maxFifthRelativeLabelWidth)),
            new Extreme(
                minSixthRelative,
                maxSixthRelative,
                this.getLabelOffset(minSixthRelativeLabelWidth),
                this.getLabelOffset(maxSixthRelativeLabelWidth)),
            new Extreme(
                minSeventhRelative,
                maxSeventhRelative,
                this.getLabelOffset(minSeventhRelativeLabelWidth),
                this.getLabelOffset(maxSeventhRelativeLabelWidth)),
            new Extreme(
                0,
                0,
                this.getLabelOffset(minIntegratedAbsoluteLabelWidth),
                this.getLabelOffset(maxIntegratedAbsoluteLabelWidth)),
            new Extreme(
                0,
                0,
                this.getLabelOffset(minSecondIntegratedAbsoluteLabelWidth),
                this.getLabelOffset(maxSecondIntegratedAbsoluteLabelWidth)),
            new Extreme(
                0,
                0,
                this.getLabelOffset(minIntegratedRelativeLabelWidth),
                this.getLabelOffset(maxIntegratedRelativeLabelWidth)),
            new Extreme(
                0,
                0,
                this.getLabelOffset(minSecondIntegratedRelativeLabelWidth),
                this.getLabelOffset(maxSecondIntegratedRelativeLabelWidth)),
            new Extreme(0, 0, 0, 0),
            new Extreme(0, 0, 0, 0),
            new Extreme(0, 0, 0, 0),
            new Extreme(0, 0, 0, 0),
            new Extreme(0, 0, 0, 0),
            new Extreme(0, 0, 0, 0),
            new Extreme(0, 0, 0, 0),
            new Extreme(0, 0, 0, 0),
            new Extreme(0, 0, 0, 0),
            new Extreme(0, 0, 0, 0),
            new Extreme(0, 0, 0, 0),
            new Extreme(0, 0, 0, 0),
            new Extreme(0, 0, 0, 0),
            new Extreme(0, 0, 0, 0),
            new Extreme(0, 0, 0, 0),
            additionalMeasureExtremes,
            relativeValues,
            !this.settings.anyAnalyticsLinesEnabled() || this.isTotal() || this.isGrandTotal() ? [] : [this.getValue(this.settings.analyticsDataProperty)]
        );
    }

    private calculateRelativeDifference(value: number, referenceValue: number, fieldName: string): [number, number] {
        this[fieldName] = null;
        let minRelative = null, maxRelative = null;
        if (referenceValue !== 0 && referenceValue != null && value != null) {
            this[fieldName] = (value - referenceValue) / Math.abs(referenceValue) * 100;
        }
        if (this[fieldName] > 0) {
            maxRelative = this[fieldName];
        }
        if (this[fieldName] < 0) {
            minRelative = this[fieldName];
        }
        return [minRelative, maxRelative];
    }

    private calculateAbsoluteDifference(value: number, referenceValue: number, fieldName: string) {
        if (this.settings.suppressAbsoluteVariances && (value === null || referenceValue === null)) {
            this[fieldName] = null;
        }
        else if (value === null && referenceValue !== null) {
            this[fieldName] = -referenceValue;
        }
        else if (value !== null && referenceValue === null) {
            this[fieldName] = value;
        }
        else if (value !== null && referenceValue !== null) {
            this[fieldName] = value - referenceValue;
        }
    }

    private formatAndMeasureLabel(value: number, dataProperty: DataProperty, fieldName: string, locale: string, showParenthesis: boolean,
        percentageFormat: string, hideUnits: boolean, handleLabelWidth: (labelWidth: number, value: number) => [number, number]): [number, number] {
        this.formatLabel(value, dataProperty, fieldName, locale, showParenthesis, false, percentageFormat, hideUnits);
        let fontWeight = this.settings.getColumnViewSetting(dataProperty, "bold") ? BOLD : NORMAL;
        let labelWidth = drawing.measureTextWidth(this[fieldName], this.settings.labelFontSize, this.settings.labelFontFamily, fontWeight, NORMAL);
        return handleLabelWidth(labelWidth, value);
    }

    private formatLabel(value: number, dataProperty: DataProperty, fieldName: string, locale: string, showParenthesis: boolean, isVariance: boolean, percentageFormat: string, hideUnits: boolean) {
        let suffix = (this.isRowDisplayUnitPercent() || this.getDisplayUnits(dataProperty) === DisplayUnits.Percent) && value !== null && percentageFormat === null ? "%" : EMPTY;
        this[fieldName] = getFormattedDataLabel(value, this.getDecimalPlaces(dataProperty), this.getDisplayUnits(dataProperty), locale, showParenthesis, isVariance, percentageFormat, hideUnits) + suffix;
    }

    private handleValueLabelWidth(labelWidth: number, value: number): [number, number] {
        let isValueNegative = value < 0;
        let minValueLabelWidth = 0, maxValueLabelWidth = 0;
        if (isValueNegative) {
            minValueLabelWidth = labelWidth;
        }
        else if (!isValueNegative) {
            maxValueLabelWidth = labelWidth;
        }
        return [minValueLabelWidth, maxValueLabelWidth];
    }

    private getExtremeValue(value: number, dataProperty: DataProperty): number {
        if (this.isGrandTotal() || this.isRowDisplayUnitPercent()) {
            return 0;
        }
        return value;
    }

    public matchingPosition(askingPosition: number, scenarioPosition: number): boolean {
        if (askingPosition === null || scenarioPosition === null || askingPosition === undefined || scenarioPosition === undefined) {
            return true;
        } else {
            return askingPosition === scenarioPosition;
        }
    }

    public getValueFromComparison(scenario: Scenario, defaultValue: number, position?: number): number {
        if (this.viewModel.value.scenario === scenario && this.matchingPosition(position, this.viewModel.value.position)) {
            return this.value;
        }
        else if (this.viewModel.reference.scenario === scenario && this.matchingPosition(position, this.viewModel.reference.position)) {
            return this.referenceValue;
        }
        else if (this.viewModel.secondReference.scenario === scenario && this.matchingPosition(position, this.viewModel.secondReference.position)) {
            return this.secondReferenceValue;
        }
        else if (this.viewModel.thirdReference.scenario === scenario && this.matchingPosition(position, this.viewModel.thirdReference.position)) {
            return this.thirdReferenceValue;
        }
        else if (this.viewModel.fourthReference.scenario === scenario && this.matchingPosition(position, this.viewModel.fourthReference.position)) {
            return this.fourthReferenceValue;
        }
        else if (this.viewModel.fifthReference.scenario === scenario && this.matchingPosition(position, this.viewModel.fifthReference.position)) {
            return this.fifthReferenceValue;
        }
        else if (this.viewModel.sixthReference.scenario === scenario && this.matchingPosition(position, this.viewModel.sixthReference.position)) {
            return this.sixthReferenceValue;
        }
        else if (this.viewModel.seventhReference.scenario === scenario && this.matchingPosition(position, this.viewModel.seventhReference.position)) {
            return this.seventhReferenceValue;
        }
        return defaultValue;
    }

    private getLabelOffset(labelOffset: number): number {
        if (labelOffset === 0) {
            return labelOffset;
        }
        let labelOffsetMargin = 10;
        return labelOffsetMargin + labelOffset;
    }

    public setCumulative(valueCumulative: number, referenceValueCumulative: number, secondReferenceValueCumulative: number, thirdReferenceValueCumulative: number, fourthReferenceValueCumulative: number, fifthReferenceValueCumulative: number, sixthReferenceValueCumulative: number, seventhReferenceValueCumulative: number,
        absoluteCumulative: number, secondAbsoluteCumulative: number, thirdAbsoluteCumulative: number, fourthAbsoluteCumulative: number, fifthAbsoluteCumulative: number, sixthAbsoluteCumulative: number, seventhAbsoluteCumulative: number) {
        if (this.isFlatResult()) {
            this.valueCumulative = 0;
            this.referenceValueCumulative = 0;
            this.secondReferenceValueCumulative = 0;
            this.thirdReferenceValueCumulative = 0;
            this.fourthReferenceValueCumulative = 0;
            this.fifthReferenceValueCumulative = 0;
            this.sixthReferenceValueCumulative = 0;
            this.seventhReferenceValueCumulative = 0;
            this.absoluteCumulative = 0;
            this.secondAbsoluteCumulative = 0;
            this.thirdAbsoluteCumulative = 0;
            this.fourthAbsoluteCumulative = 0;
            this.fifthAbsoluteCumulative = 0;
            this.sixthAbsoluteCumulative = 0;
            this.seventhAbsoluteCumulative = 0;
        }
        else {
            this.valueCumulative = valueCumulative;
            this.referenceValueCumulative = referenceValueCumulative;
            this.secondReferenceValueCumulative = secondReferenceValueCumulative;
            this.thirdReferenceValueCumulative = thirdReferenceValueCumulative;
            this.fourthReferenceValueCumulative = fourthReferenceValueCumulative;
            this.fifthReferenceValueCumulative = fifthReferenceValueCumulative;
            this.sixthReferenceValueCumulative = sixthReferenceValueCumulative;
            this.seventhReferenceValueCumulative = seventhReferenceValueCumulative;
            this.absoluteCumulative = absoluteCumulative;
            this.secondAbsoluteCumulative = secondAbsoluteCumulative;
            this.thirdAbsoluteCumulative = thirdAbsoluteCumulative;
            this.fourthAbsoluteCumulative = fourthAbsoluteCumulative;
            this.fifthAbsoluteCumulative = fifthAbsoluteCumulative;
            this.sixthAbsoluteCumulative = sixthAbsoluteCumulative;
            this.seventhAbsoluteCumulative = seventhAbsoluteCumulative;
        }
    }

    public getCumulativeLabelOffset(currentMax: number, min: boolean, dataProperty: DataProperty, maximumExpandeHierarchyLevel: number, maximumExpandedGroupLevel: number): number {
        if (min && (this.getCumulative(dataProperty) + this.getValue(dataProperty) * (this.isInverted ? -1 : 1)) < 0) {
            return this.calculateLabelOffset(currentMax, dataProperty);
        }
        else if (!min && (this.getCumulative(dataProperty) + this.getValue(dataProperty) * (this.isInverted ? -1 : 1)) > 0) {
            return this.calculateLabelOffset(currentMax, dataProperty);
        }
        return currentMax;
    }

    public calculateGrandTotalCumulative(): CumulativeMinMaxAndOffsets {
        this.valueCumulative = 0;
        this.referenceValueCumulative = 0;
        this.secondReferenceValueCumulative = 0;
        this.thirdReferenceValueCumulative = 0;
        this.fourthReferenceValueCumulative = 0;
        this.fifthReferenceValueCumulative = 0;
        this.sixthReferenceValueCumulative = 0;
        this.seventhReferenceValueCumulative = 0;
        this.absoluteCumulative = 0;
        this.secondAbsoluteCumulative = 0;
        this.thirdAbsoluteCumulative = 0;
        this.fourthAbsoluteCumulative = 0;
        this.fifthAbsoluteCumulative = 0;
        this.sixthAbsoluteCumulative = 0;
        this.seventhAbsoluteCumulative = 0;
        let minAbsolute = Math.min(0, this.absoluteDifference);
        let maxAbsolute = Math.max(0, this.absoluteDifference);
        let absoluteLabelWidth = drawing.measureTextWidth(this.absoluteDifferenceLabel, this.settings.labelFontSize, this.settings.labelFontFamily, NORMAL, NORMAL);
        let minLabelOffset = this.isNegative(DataProperty.AbsoluteDifference) ? this.getLabelOffset(absoluteLabelWidth) : 0;
        let maxLabelOffset = this.isNegative(DataProperty.AbsoluteDifference) ? 0 : this.getLabelOffset(absoluteLabelWidth);
        let minSecondAbsolute = Math.min(0, this.secondAbsoluteDifference);
        let maxSecondAbsolute = Math.max(0, this.secondAbsoluteDifference);
        let secondAbsoluteLabelWidth = drawing.measureTextWidth(this.secondAbsoluteDifferenceLabel, this.settings.labelFontSize, this.settings.labelFontFamily, NORMAL, NORMAL);
        let secondMinLabelOffset = this.isNegative(DataProperty.SecondAbsoluteDifference) ? this.getLabelOffset(secondAbsoluteLabelWidth) : 0;
        let secondMaxLabelOffset = this.isNegative(DataProperty.SecondAbsoluteDifference) ? 0 : this.getLabelOffset(secondAbsoluteLabelWidth);
        // Third
        let minThirdAbsolute = Math.min(0, this.thirdAbsoluteDifference);
        let maxThirdAbsolute = Math.max(0, this.thirdAbsoluteDifference);
        let thirdAbsoluteLabelWidth = drawing.measureTextWidth(this.thirdAbsoluteDifferenceLabel, this.settings.labelFontSize, this.settings.labelFontFamily, NORMAL, NORMAL);
        let thirdMinLabelOffset = this.isNegative(DataProperty.ThirdAbsoluteDifference) ? this.getLabelOffset(thirdAbsoluteLabelWidth) : 0;
        let thirdMaxLabelOffset = this.isNegative(DataProperty.ThirdAbsoluteDifference) ? 0 : this.getLabelOffset(thirdAbsoluteLabelWidth);
        // Fourth
        let minFourthAbsolute = Math.min(0, this.fourthAbsoluteDifference);
        let maxFourthAbsolute = Math.max(0, this.fourthAbsoluteDifference);
        let fourthAbsoluteLabelWidth = drawing.measureTextWidth(this.fourthAbsoluteDifferenceLabel, this.settings.labelFontSize, this.settings.labelFontFamily, NORMAL, NORMAL);
        let fourthMinLabelOffset = this.isNegative(DataProperty.FourthAbsoluteDifference) ? this.getLabelOffset(fourthAbsoluteLabelWidth) : 0;
        let fourthMaxLabelOffset = this.isNegative(DataProperty.FourthAbsoluteDifference) ? 0 : this.getLabelOffset(fourthAbsoluteLabelWidth);
        // Fifth
        let minFifthAbsolute = Math.min(0, this.fifthAbsoluteDifference);
        let maxFifthAbsolute = Math.max(0, this.fifthAbsoluteDifference);
        let fifthAbsoluteLabelWidth = drawing.measureTextWidth(this.fifthAbsoluteDifferenceLabel, this.settings.labelFontSize, this.settings.labelFontFamily, NORMAL, NORMAL);
        let fifthMinLabelOffset = this.isNegative(DataProperty.FifthAbsoluteDifference) ? this.getLabelOffset(fifthAbsoluteLabelWidth) : 0;
        let fifthMaxLabelOffset = this.isNegative(DataProperty.FifthAbsoluteDifference) ? 0 : this.getLabelOffset(fifthAbsoluteLabelWidth);
        // Sixth
        let minSixthAbsolute = Math.min(0, this.sixthAbsoluteDifference);
        let maxSixthAbsolute = Math.max(0, this.sixthAbsoluteDifference);
        let sixthAbsoluteLabelWidth = drawing.measureTextWidth(this.sixthAbsoluteDifferenceLabel, this.settings.labelFontSize, this.settings.labelFontFamily, NORMAL, NORMAL);
        let sixthMinLabelOffset = this.isNegative(DataProperty.SixthAbsoluteDifference) ? this.getLabelOffset(sixthAbsoluteLabelWidth) : 0;
        let sixthMaxLabelOffset = this.isNegative(DataProperty.SixthAbsoluteDifference) ? 0 : this.getLabelOffset(sixthAbsoluteLabelWidth);
        // seventh
        let minSeventhAbsolute = Math.min(0, this.seventhAbsoluteDifference);
        let maxSeventhAbsolute = Math.max(0, this.seventhAbsoluteDifference);
        let seventhAbsoluteLabelWidth = drawing.measureTextWidth(this.seventhAbsoluteDifferenceLabel, this.settings.labelFontSize, this.settings.labelFontFamily, NORMAL, NORMAL);
        let seventhMinLabelOffset = this.isNegative(DataProperty.SeventhAbsoluteDifference) ? this.getLabelOffset(seventhAbsoluteLabelWidth) : 0;
        let seventhMaxLabelOffset = this.isNegative(DataProperty.SeventhAbsoluteDifference) ? 0 : this.getLabelOffset(seventhAbsoluteLabelWidth);

        return {
            value: new Extreme(0, 0, 0, 0),
            referenceValue: new Extreme(0, 0, 0, 0),
            secondReferenceValue: new Extreme(0, 0, 0, 0),
            thirdReferenceValue: new Extreme(0, 0, 0, 0),
            fourthReferenceValue: new Extreme(0, 0, 0, 0),
            fifthReferenceValue: new Extreme(0, 0, 0, 0),
            sixthReferenceValue: new Extreme(0, 0, 0, 0),
            seventhReferenceValue: new Extreme(0, 0, 0, 0),
            absolute: new Extreme(minAbsolute, maxAbsolute, minLabelOffset, maxLabelOffset),
            secondAbsolute: new Extreme(minSecondAbsolute, maxSecondAbsolute, secondMinLabelOffset, secondMaxLabelOffset),
            thirdAbsolute: new Extreme(minThirdAbsolute, maxThirdAbsolute, thirdMinLabelOffset, thirdMaxLabelOffset),
            fourthAbsolute: new Extreme(minFourthAbsolute, maxFourthAbsolute, fourthMinLabelOffset, fourthMaxLabelOffset),
            fifthAbsolute: new Extreme(minFifthAbsolute, maxFifthAbsolute, fifthMinLabelOffset, fifthMaxLabelOffset),
            sixthAbsolute: new Extreme(minSixthAbsolute, maxSixthAbsolute, sixthMinLabelOffset, sixthMaxLabelOffset),
            seventhAbsolute: new Extreme(minSeventhAbsolute, maxSeventhAbsolute, seventhMinLabelOffset, seventhMaxLabelOffset)
        };
    }

    private calculateLabelOffset(currentMax: number, dataProperty: DataProperty): number {
        let labelWidth = drawing.measureTextWidth(this.getLabel(dataProperty), this.settings.labelFontSize, this.settings.labelFontFamily, this.getFontWeight(), this.settings.shouldUseItalicText(dataProperty) ? ITALIC : NORMAL);
        let labelOffset = this.getLabelOffset(labelWidth);
        return Math.max(labelOffset, currentMax);
    }

    public calculateCumulativeLabelOffset(xScale: d3.ScaleLinear<number, number>, width: number, locale: string, dataProperty: DataProperty): MinMax {
        let labelWidth = drawing.measureTextWidth(this.getLabel(dataProperty), this.settings.labelFontSize, this.settings.labelFontFamily, NORMAL, this.settings.shouldUseItalicText(dataProperty) ? ITALIC : NORMAL);
        if ((this.isNegative(dataProperty) && !this.isInverted) || (!this.isNegative(dataProperty) && this.isInverted)) {
            let xPosition = xScale(this.getCumulative(dataProperty) + this.getValue(dataProperty) * (this.isInverted ? -1 : 1)) - 5;
            if (this.getCumulative(dataProperty) + this.getValue(dataProperty) >= 0 && xPosition - labelWidth < 0) {
                return { min: this.getLabelOffset(Math.abs(xPosition - labelWidth)), max: 0 };
            }
        }
        else {
            let xPosition = xScale(this.getCumulative(dataProperty) + this.getValue(dataProperty) * (this.isInverted ? -1 : 1)) + 5;
            if (this.getCumulative(dataProperty) + this.getValue(dataProperty) <= 0 && xPosition + labelWidth > width) {
                return {
                    min: 0, max: this.getLabelOffset((xPosition + labelWidth) - width),
                };
            }
        }
        return { min: 0, max: 0 };
    }

    public getFontWeight(): string {
        let group = this.parent.firstGroupParent;

        if (this.settings.getCategoryFormatIsBold((this.category))) {
            return BOLD;
        }
        if (this.isGrandTotal()) {
            return BOLD;
        }
        if (this.isFormula()) {
            let formula = getFormula(this, this.settings.formulaCalculation.formulas, this.hierarchyIdentity);
            if (formula && formula.bold) {
                return BOLD;
            }
        }
        if (group && group.isSubtotal) {
            if (this.settings.shouldUseBoldGroupHeaders(group, this.viewModel.maximumExpandedGroupLevel)) {
                return BOLD;
            }
            return NORMAL;
        }
        if (this.isBold) {
            return BOLD;
        }
        if ((this.isNormal() || this.isOther())) {
            return NORMAL;
        }
        if (this.isTotal() || this.isOtherTotal()) {
            if (this.isResult) {
                return BOLD;
            }
            return this.level === this.viewModel.maximumExpandedHierarchyLevel + 1 ? NORMAL : BOLD;
        }
        if (this.isFormula()) {
            return NORMAL;
        }
        return BOLD;
    }

    private getItalic(): boolean {
        if (this.isFormula()) {
            let formula = getFormula(this, this.settings.formulaCalculation.formulas, this.hierarchyIdentity);
            return formula && formula.italic;
        }

        if (this.settings.getCategoryFormatIsItalic(this.category)) {
            return true;
        }

        return false;
    }

    public getLabelColor(defaultColor?: string, dataProperty?: DataProperty): string {
        if (this.isFormula() && this.formula?.fontColor) {
            return this.formula.fontColor;
        }

        let categoryFormatTextColor = this.settings.getCategoryFormatTextColor(this.category)
        if (categoryFormatTextColor !== "" && categoryFormatTextColor !== null) {
            return categoryFormatTextColor;
        }
        else {
            return this.settings.getColumnTextColor(dataProperty, defaultColor);
        }
    }

    public getLeftIndent(totalPrefixWidth: number): number {
        if ((this.isNormal() || this.isOther() || this.dataPointType === DataPointType.Formula) && this.level >= 1) {
            return (this.level - 1) * this.settings.categoriesIndent + totalPrefixWidth;
        }
        return this.level * this.settings.categoriesIndent + totalPrefixWidth;
    }

    public isNormal(): boolean {
        return this.dataPointType === DataPointType.Normal;
    }

    public isOther(): boolean {
        return this.dataPointType === DataPointType.Other;
    }

    public isOtherTotal(): boolean {
        return this.dataPointType === DataPointType.OtherTotal
    }

    public isGrandTotal(): boolean {
        return this.dataPointType === DataPointType.GrandTotal;
    }

    public isTotal(): boolean {
        return this.dataPointType === DataPointType.Total || this.dataPointType === DataPointType.FormulaTotal;
    }

    public isFlatResult(): boolean {
        return this.dataPointType === DataPointType.FlatResult || (this.isFormula() && this.settings.flatResultCategories.indexOf(this.category) > -1);
    }

    public isFormula(): boolean {
        return this.dataPointType === DataPointType.Formula || this.dataPointType === DataPointType.FormulaTotal;
    }

    public isRowDisplayUnitPercent(): boolean {
        return (this.viewModel.settings.getCategoryFormatDisplayUnits(this.category) === DisplayUnits.Percent) || (this.isFormula() && this.formula?.units === DisplayUnits.Percent);
    }

    public isOutlier(viewModel: ViewModel, dataProperty: DataProperty): boolean {
        return this.isNegative(dataProperty) ? this.getValue(dataProperty) < viewModel.minOutlierValue : this.getValue(dataProperty) > viewModel.maxOutlierValue;
    }

    public hasInvertedGroupAncestor(): boolean {
        let groupParent = this.parent.firstGroupParent;
        while (groupParent) {
            if (groupParent.isInverted) {
                return true;
            }
            groupParent = <ChartGroup>groupParent.parent;
        }
        return false;
    }

    public setValue(dp: DataProperty, value: number) {
        switch (dp) {
            case DataProperty.Value:
                this.value = value;
                break;
            case DataProperty.ReferenceValue:
                this.referenceValue = value;
                break;
            case DataProperty.SecondReferenceValue:
                this.secondReferenceValue = value;
                break;
            case DataProperty.ThirdReferenceValue:
                this.thirdReferenceValue = value;
                break;
            case DataProperty.FourthReferenceValue:
                this.fourthReferenceValue = value;
                break;
            case DataProperty.FifthReferenceValue:
                this.fifthReferenceValue = value;
                break;
            case DataProperty.SixthReferenceValue:
                this.sixthReferenceValue = value;
                break;
            case DataProperty.SeventhReferenceValue:
                this.seventhReferenceValue = value;
                break;
            case DataProperty.AdditionalMeasure1:
            case DataProperty.AdditionalMeasure2:
            case DataProperty.AdditionalMeasure3:
            case DataProperty.AdditionalMeasure4:
            case DataProperty.AdditionalMeasure5:
            case DataProperty.AdditionalMeasure6:
            case DataProperty.AdditionalMeasure7:
            case DataProperty.AdditionalMeasure8:
            case DataProperty.AdditionalMeasure9:
            case DataProperty.AdditionalMeasure10:
            case DataProperty.AdditionalMeasure11:
            case DataProperty.AdditionalMeasure12:
            case DataProperty.AdditionalMeasure13:
            case DataProperty.AdditionalMeasure14:
            case DataProperty.AdditionalMeasure15:
            case DataProperty.AdditionalMeasure16:
            case DataProperty.AdditionalMeasure17:
            case DataProperty.AdditionalMeasure18:
            case DataProperty.AdditionalMeasure19:
            case DataProperty.AdditionalMeasure20:
                let index = getIndexFromAdditionalMeasureDataProperty(dp);
                this.additionalMeasures[index] = value;
                break;
        }
    }

    public removeItselfFromParent() {
        if (this.isTotal()) {
            (<ChartHierarchy>this.parent).removeFromParent(this);
        }
        else {
            (<ChartHierarchy>this.parent).removeFromDataPoints(this);
        }
    }

    public hasComment(): boolean {
        return this.commentsValues && this.commentsValues.length > 0 && this.commentsValues[0] !== null;
    }

    public getParentCategories(): string {
        let parentCategories = EMPTY;
        let parent = this.parent;
        while (parent && (parent instanceof ChartHierarchy)) {
            parentCategories = `${(<ChartHierarchy>parent).category} ${parentCategories}`
            parent = parent.parent;
        }
        return parentCategories;
    }

    public isCurrentlyEditedFormula(): boolean {
        if (Visual.formulaEditMode === FormulaEditMode.None || !this.isFormula()) {
            return false;
        }
        if (this.category === EMPTY && Visual.formulaEditMode === FormulaEditMode.Add) {
            return true;
        }
        if (this.fullCategory === Visual.formulaEditPosition.fullCategory && Visual.formulaEditMode === FormulaEditMode.Edit) {
            return true;
        }
        return false;
    }

    public getRowBold = (): boolean => {
        if (this.isFormula()) {
            return this.formula.bold
        }
        else {
            return this.settings.getCategoryFormatIsBold(this.category)
        }
    }

    public setRowBold = () => {
        if (this.isFormula()) {
            this.formula.bold = !this.formula.bold;
            this.settings.addFormula(this.formula, true);
        }
        else {
            this.settings.toggleAndPersistCategoryFormatBold(this.category);
        }
    }

    public getRowItalic = (): boolean => {
        if (this.isFormula()) {
            return this.formula.italic;
        }
        else {
            return this.settings.getCategoryFormatIsItalic(this.category)
        }
    }

    public setRowItalic = () => {
        if (this.isFormula()) {
            this.formula.italic = !this.formula.italic;
            this.settings.addFormula(this.formula, true);
        }
        else {
            this.settings.toggleAndPersistCategoryFormatItalic(this.category);
        }
    }

    public getRowFontColor = (): string => {
        if (this.isFormula()) {
            return this.formula.fontColor;
        }
        else {
            return this.settings.getCategoryFormatTextColor(this.category);
        }
    }

    public setRowFontColor = (fontColor: string) => {
        if (this.isFormula()) {
            this.formula.fontColor = fontColor
            this.settings.addFormula(this.formula, true);
        }
        else {
            this.settings.persistCategoryFormatTextColor(this.category, fontColor);
        }
    }

    public getRowHighlightColor = (): string => {
        return this.settings.getCategoryFormatHighlightColor(this.category);
    }

    public setRowHighlightColor = (highlightColor: string) => {
        this.settings.persistCategoryFormatHighlightColor(this.category, highlightColor);
    }

    public getRowTopBorder = (): boolean => {
        return this.settings.getCategoryFormatTopBorder(this.category);
    }

    public setRowTopBorder = () => {
        this.settings.toggleAndPersistCategoryFormatTopBorder(this.category);
    }

    public getRowNumberFormat = (): DisplayUnits => {
        if (this.isFormula()) {
            return this.formula ? this.formula.units : DisplayUnits.Auto;
        }
        else {
            return this.settings.getCategoryFormatDisplayUnits(this.category);
        }
    }

    public setRowNumberFormat = (units: DisplayUnits) => {
        if (this.isFormula()) {
            this.formula.units = units;
            this.settings.addFormula(this.formula, true);
        }
        else {
            this.settings.persistCategoryFormatNumberFormat(this.category, units);
        }
    }

    public getRowDecimalPlaces = (): number => {
        if (this.isFormula()) {
            return this.formula ? this.formula.decimalPlaces : -1;
        }
        else {
            return this.settings.getCategoryFormatDecimalPlaces(this.category);
        }
    }

    public setRowDecimalPlaces = (places: number) => {
        if (this.isFormula()) {
            this.formula.decimalPlaces = places;
            this.settings.addFormula(this.formula, true);
        }
        else {
            this.settings.persistCategoryFormatDecimalPlaces(this.category, places);
        }
    }
}