import { Categories } from "../category.type";

import CrossTableSimpleImage from "./images/cross-table-simple.svg";
import { CrossTableSimpleSampleData } from "./crossCategories.mock";

export const crossCategories: Categories = [
  {
    displayName: "Single series",
    tables: [
      {
        id: "cross-table-simple",
        title: "Cross table",
        image: CrossTableSimpleImage,
        sampleData: CrossTableSimpleSampleData,
        disabledComments: true,
        isMultiples: true,
        tooltip:
          "A two-way table consisting of columns and rows also known as multi-dimensional table. Structure, summarize and display bigger data sets.",
        settings: {
          showAsTable: false,
          groupsInColumns: true,
          valueChart: 0,
        },
      },
    ],
  },
];
