import { Extreme } from "./charting/extreme";
import { Extremes } from "./charting/extremes";
import { getEmptyMinsAndMaxes } from "./charting/charts/chart";

export function calculateRelativeValue(value: number, referenceValue: number): number {
    if (referenceValue !== 0 && referenceValue != null && value != null) {
        if (value * referenceValue < 0) {
            return null;
        }
        else {
            return (value - referenceValue) / Math.abs(referenceValue) * 100;
        }
    }
    return null;
}

export function reduceMinsAndMaxes(extremes: Extremes[]): Extremes {
    let result = getEmptyMinsAndMaxes();
    extremes.forEach(mm => {
        calculateExtremes(mm.value, result.value);
        calculateExtremes(mm.referenceValue, result.referenceValue);
        calculateExtremes(mm.secondReferenceValue, result.secondReferenceValue);
        calculateExtremes(mm.thirdReferenceValue, result.thirdReferenceValue);
        calculateExtremes(mm.fourthReferenceValue, result.fourthReferenceValue);
        calculateExtremes(mm.fifthReferenceValue, result.fifthReferenceValue);
        calculateExtremes(mm.sixthReferenceValue, result.sixthReferenceValue);
        calculateExtremes(mm.seventhReferenceValue, result.seventhReferenceValue);
        calculateExtremes(mm.absolute, result.absolute);
        calculateExtremes(mm.secondAbsolute, result.secondAbsolute);
        calculateExtremes(mm.thirdAbsolute, result.thirdAbsolute);
        calculateExtremes(mm.fourthAbsolute, result.fourthAbsolute);
        calculateExtremes(mm.fifthAbsolute, result.fifthAbsolute);
        calculateExtremes(mm.sixthAbsolute, result.sixthAbsolute);
        calculateExtremes(mm.seventhAbsolute, result.seventhAbsolute);
        calculateExtremes(mm.relative, result.relative);
        calculateExtremes(mm.secondRelative, result.secondRelative);
        calculateExtremes(mm.thirdRelative, result.thirdRelative);
        calculateExtremes(mm.fourthRelative, result.fourthRelative);
        calculateExtremes(mm.fifthRelative, result.fifthRelative);
        calculateExtremes(mm.sixthRelative, result.sixthRelative);
        calculateExtremes(mm.seventhRelative, result.seventhRelative);
        calculateExtremes(mm.integratedAbsolute, result.integratedAbsolute);
        calculateExtremes(mm.secondIntegratedAbsolute, result.secondIntegratedAbsolute);
        calculateExtremes(mm.integratedRelative, result.integratedRelative);
        calculateExtremes(mm.secondIntegratedRelative, result.secondIntegratedRelative);
        for (let i = 0; i < mm.additionalMeasures.length; i++) {
            calculateExtremes(mm.additionalMeasures[i], result.additionalMeasures[i]);
        }
        result.relativeValues = result.relativeValues.concat(mm.relativeValues);
        result.analyticsValues = result.analyticsValues.concat(mm.analyticsValues);
    });
    return result;
}

export function calculateExtremes(mm: Extreme, result: Extreme) {
    if (mm.min < result.min) {
        result.min = mm.min;
    }
    if (mm.max > result.max) {
        result.max = mm.max;
    }
    if (mm.minLabelOffset > result.minLabelOffset) {
        result.minLabelOffset = mm.minLabelOffset;
    }
    if (mm.maxLabelOffset > result.maxLabelOffset) {
        result.maxLabelOffset = mm.maxLabelOffset;
    }
}

export function reduceMinMax(minMaxes: Extreme[]): Extreme {
    return minMaxes.reduce((result, current) => {
        return new Extreme(
            Math.min(result.min, current.min),
            Math.max(result.max, current.max),
            Math.max(result.minLabelOffset, current.minLabelOffset),
            Math.max(result.maxLabelOffset, current.maxLabelOffset));
    },
        new Extreme(0, 0, 0, 0));
}
