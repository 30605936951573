import React from "react";
import ZebraBIIcon from "./logo.svg";

export default function TablesChooserLogo() {
  return (
    <div className="flex justify-evenly py-6 w-full">
      <img src={ZebraBIIcon} alt="zebrabi" className="logo-img" />
      <h1 className="logo-text">
        Zebra BI
        <span className="fw-400"> Tables</span>
      </h1>
    </div>
  );
}
