import { SheetData } from "x-data-spreadsheet";

export const PandlSampleData: SheetData = {
  rows: {
    0: { cells: { 0: { text: "Account" }, 1: { text: "AC" }, 2: { text: "PY" } } },
    1: { cells: { 0: { text: "Net Sales" }, 1: { text: "5400" }, 2: { text: "4900" } } },
    2: { cells: { 0: { text: "Cost of goods sold" }, 1: { text: "3500" }, 2: { text: "3100" } } },
    3: { cells: { 0: { text: "Gross profit" }, 1: { text: "1900" }, 2: { text: "1800" } } },
    4: { cells: { 0: { text: "OOI" }, 1: { text: "1636" }, 2: { text: "1758" } } },
    5: { cells: { 0: { text: "SG&A" }, 1: { text: "1887" }, 2: { text: "1402" } } },
    6: { cells: { 0: { text: "Operating profit" }, 1: { text: "1649" }, 2: { text: "2156" } } },
    7: { cells: { 0: { text: "Taxes" }, 1: { text: "1348" }, 2: { text: "1200" } } },
    8: { cells: { 0: { text: "Net profit" }, 1: { text: "301" }, 2: { text: "956" } } },
  },
};

export const PandlCommentData: SheetData = {
  rows: {
    0: { cells: { 0: { text: "Comments" } } },
    1: {
      cells: {
        0: {
          text: "Sales revenue growth achieved by additional account managers and successful AdWords marketing campaign.",
        },
      },
    },
    2: {
      cells: {
        0: {
          text: "Material costs increased by 12K USD due to higher price of copper.",
        },
      },
    },
    4: {
      cells: { 0: { text: "Personnel costs have increased because of the 8% FTE growth in the sales department." } },
    },
  },
};

// Hierarchical P&L statement
export const HierarchyPandLSampleData: SheetData = {
  rows: {
    0: { cells: { 0: { text: "Account group" }, 1: { text: "Account" }, 2: { text: "AC" }, 3: { text: "PY" } } },
    1: { cells: { 0: { text: "Sales revenue" }, 1: { text: "Licenses" }, 2: { text: "896.4" }, 3: { text: "713.3" } } },
    2: { cells: { 0: { text: "" }, 1: { text: "Consulting" }, 2: { text: "90" }, 3: { text: "72" } } },
    3: { cells: { 0: { text: "" }, 1: { text: "Maintenance" }, 2: { text: "10" }, 3: { text: "22" } } },
    4: { cells: { 0: { text: "" }, 1: { text: "Other revenue" }, 2: { text: "65" }, 3: { text: "6" } } },
    5: { cells: { 0: { text: "" }, 1: { text: "Other op. income" }, 2: { text: "17" }, 3: { text: "45" } } },
    6: { cells: { 0: { text: "Expenses" }, 1: { text: "Purchases" }, 2: { text: "379.2" }, 3: { text: "344.1" } } },
    7: { cells: { 0: { text: "" }, 1: { text: "Material expenses" }, 2: { text: "54" }, 3: { text: "11" } } },
    8: { cells: { 0: { text: "" }, 1: { text: "Personnel expenses" }, 2: { text: "127.1" }, 3: { text: "76" } } },
    9: { cells: { 0: { text: "" }, 1: { text: "Amortization" }, 2: { text: "40" }, 3: { text: "56" } } },
    10: { cells: { 0: { text: "" }, 1: { text: "Other op. expenses" }, 2: { text: "152.1" }, 3: { text: "78" } } },
    11: { cells: { 0: { text: "Operating profit" }, 1: { text: "Operating profit" }, 2: { text: "" }, 3: { text: "" } } },
    12: { cells: { 0: { text: "Profit before tax" }, 1: { text: "Investment income" }, 2: { text: "53" }, 3: { text: "43" } } },
    13: { cells: { 0: { text: "" }, 1: { text: "Financial income, net" }, 2: { text: "66" }, 3: { text: "73" } } },
    14: { cells: { 0: { text: "Profit after tax" }, 1: { text: "Income tax" }, 2: { text: "111" }, 3: { text: "132.1" } } },
    15: { cells: { 0: { text: "Group profit" }, 1: { text: "Profit to other interests" }, 2: { text: "55" }, 3: { text: "89" } } },
  },
};

export const HierarchyPandLCommentData: SheetData = {
  rows: {
    0: { cells: { 0: { text: "Comments" } } },
    4: {
      cells: {
        0: {
          text: "Significant increases in other revenue.",
        },
      },
    },
    7: {
      cells: {
        0: {
          text: "Increase due to the disruption in the purchasing process.",
        },
      },
    },
  },
};
