import React from "react";
import TablesChooserLogo from "../TablesChooserLogo/TablesChooserLogo";

type Props = {
  children?: React.ReactNode;
};

export default function TablesChooserAside({ children }: Props) {
  return (
    <aside className="flex flex-col items-center border-r-gray relative">
      <TablesChooserLogo />
      {children}
      <div className="help-link-container border-t border-gray-300">
        <button onClick={() => window.open("https://zebrabi.com/help-center/?product=office")} className="help-link">
          Help
        </button>
      </div>
    </aside>
  );
}
