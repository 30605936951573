import { ChartPlotDefinition, ChartInfo, ChartType, VERTICAL_MARGIN, ABSOLUTE, RELATIVE, NUMBER_OF_GROUPS, MINIMUM_CHART_WIDTH, MINIMUM_CHART_WIDTH_NO_LABELS } from "../../definitions";
import { VarianceSettings } from "../../settings/varianceSettings";
import { ChartGroup } from "../chartGroup";
import { ViewModel } from "../../settings/viewModel";
import { HorizontalPlotter } from "./horizontalPlotter";
import { Extreme } from "../extreme";
import * as d3 from "d3";
import * as helpers from "./../../library/helpers";
import { isRelativeMeasure } from "../../helpers";
import { isPercentAdditionalMeasure } from "../charts/chart";

export function horizontalScaling(plotDefitions: ChartPlotDefinition[], groups: ChartGroup[], viewModel: ViewModel,
    minimumChartWidth: number, availableWidth: number, plotter: HorizontalPlotter): number[] {
    let settings = viewModel.settings;
    let chartInfo: ChartInfo[] = [];
    groups.map((g, i) => {
        let shouldSkip = viewModel.settings.shouldSkipGroup(g);
        plotDefitions.map(d => {
            let extreme = g.extremes.getExtreme(d.dataProperty, settings);
            let range = extreme.range;
            let labelOffset = extreme.labelOffsetSum(settings);
            if (isRelativeMeasure(d.dataProperty, settings)) {
                range = g.extremes.getRelativeRange(d.dataProperty, viewModel);
                let minLabelOffset = Math.max(extreme.getMinLabelOffset(settings), extreme.min < 0 ? 5 : 0);
                let maxlabelOffset = Math.max(extreme.getMaxLabelOffset(settings), extreme.max > 0 ? 5 : 0);
                labelOffset = minLabelOffset + maxlabelOffset;
            }
            if (shouldSkip) {
                extreme = new Extreme(0, 0, 0, 0);
                range = 0;
                labelOffset = 0;
            }
            chartInfo.push({
                extreme: extreme,
                range: range,
                labelOffset: labelOffset,
                dataProperty: d.dataProperty,
                // in case of zero values (range === 0) set chartType to Table to have the appropriate column width
                chartType: range === 0 ? ChartType.Table : d.chartType,
                group: g,
                groupIndex: i,
                plotAreaWidth: 0,
                isRemovedFromGroup: viewModel.settings.isColumnHiddenFromGroup(g, d.dataProperty),
                shouldSkipGroup: shouldSkip,
            });
        });
    });
    // We do all the calculations without the removed elements, but at the end we return the widths for all the elements
    // so that other functions that rely on all the widths to be there work as intended.
    let plotableElements = chartInfo.filter(ci => !ci.isRemovedFromGroup && !ci.shouldSkipGroup);
    let tables = plotableElements.filter(s => s.chartType === ChartType.Table);
    tables.forEach(t => t.plotAreaWidth = viewModel.getTableWidth(t.dataProperty, t.groupIndex));

    let absoluteCharts = plotableElements.filter(d => d.chartType !== ChartType.Table && d.chartType !== ChartType.PlusMinusDot && !isPercentAdditionalMeasure(d.dataProperty, settings));
    calculateChartWidthsForGroups(absoluteCharts, minimumChartWidth, settings);

    let relativeCharts = plotableElements.filter(d => d.chartType === ChartType.PlusMinusDot || (d.chartType !== ChartType.Table && isPercentAdditionalMeasure(d.dataProperty, settings)));
    calculateChartWidthsForGroups(relativeCharts, minimumChartWidth, settings);
    let elementWidths = calculateElementWidths(1, chartInfo, settings);
    let additionalMarginWidth = calculateAdditionalMargins(groups, viewModel, plotter);
    let widthForAllElements = availableWidth - plotableElements.length * VERTICAL_MARGIN - additionalMarginWidth;
    let totalWidth = d3.sum(elementWidths);
    if (totalWidth < widthForAllElements) {
        let tableWidths = d3.sum(plotableElements.map(c => c.chartType === ChartType.Table ? c.plotAreaWidth : 0));
        let labelOffsets = plotableElements.map(c => c.chartType === ChartType.Table ? 0 : c.labelOffset);
        let chartPlotAreaWidths = plotableElements.map(c => c.chartType === ChartType.Table ? 0 : c.plotAreaWidth);
        let totalChartPlotAreaWidths = d3.sum(chartPlotAreaWidths);
        let factor = (widthForAllElements - d3.sum(labelOffsets) - tableWidths) / totalChartPlotAreaWidths;
        elementWidths = calculateElementWidths(factor, chartInfo, settings);
    }
    return elementWidths;
}

function calculateChartWidthsForGroups(charts: ChartInfo[], minimumChartWidth: number, settings: VarianceSettings) {
    for (let currentGroup = 1; currentGroup <= NUMBER_OF_GROUPS; currentGroup++) {
        let chartsInGroup = charts.filter(c => settings.getScaleGroup(c.dataProperty) === currentGroup)
        calculateWidths(chartsInGroup, minimumChartWidth, settings);
    }
    let ungrouppedCharts = charts.filter(c => settings.getScaleGroup(c.dataProperty) === -1);
    let dataPropertyMap = new Map<number, ChartInfo[]>();
    ungrouppedCharts.forEach(c => {
        if (dataPropertyMap.has(c.dataProperty)) {
            let current = dataPropertyMap.get(c.dataProperty);
            current.push(c);
        }
        else {
            dataPropertyMap.set(c.dataProperty, [c]);
        }
    });
    for (let dataProperty of dataPropertyMap.keys()) {
        let chartsWithCurrentDataProperty = dataPropertyMap.get(dataProperty);
        calculateWidths(chartsWithCurrentDataProperty, minimumChartWidth, settings);
    }
}

function calculateElementWidths(factor: number, chartInfo: ChartInfo[], settings: VarianceSettings): number[] {
    return chartInfo.map(c => {
        if (c.isRemovedFromGroup || c.shouldSkipGroup) {
            return 0;
        }
        if (c.chartType === ChartType.Table) {
            return c.plotAreaWidth;
        }
        return c.plotAreaWidth * factor + c.labelOffset;
    });
}

function calculateWidths(charts: ChartInfo[], minimumChartWidth: number, settings: VarianceSettings) {
    if (charts.length < 1) {
        return;
    }
    let min = charts[0], max = charts[0];
    charts.forEach(c => {
        if (c.range < min.range) {
            min = c;
        }
        if (c.range > max.range) {
            max = c;
        }
    });
    let maxWidth = [ABSOLUTE, RELATIVE].indexOf(settings.chartType) > -1 ? 130 : 180;
    if (max.range === 0) {
        charts.map(c => c.plotAreaWidth = 0);
    }
    else {
        let maxChartWidth = Math.min(maxWidth, (max.range / min.range) * Math.max(10, (minimumChartWidth - min.labelOffset)));
        charts.map(c => c.plotAreaWidth = (c.range / max.range) * maxChartWidth);
    }
}

function calculateAdditionalMargins(groups: ChartGroup[], viewModel: ViewModel, plotter: HorizontalPlotter): number {
    if (viewModel.settings.shouldShowScenarioSortHeaders(plotter)) {
        return (groups.filter(g => !viewModel.settings.shouldSkipGroup(g)).length - 1) * VERTICAL_MARGIN;
    }
    else if (viewModel.numberOfGroupLevels > 1) {
        return helpers.sum(viewModel.chartGroups.chartGroups.map(g => calculateSubgroupMargins(g, viewModel))) * VERTICAL_MARGIN;
    }
    else {
        return 0;
    }
}

function calculateSubgroupMargins(group: ChartGroup, viewModel: ViewModel): number {
    if (!group.hasChildGroups || group.level >= viewModel.numberOfGroupLevels - 1 || viewModel.settings.shouldSkipGroup(group)) {
        return 0;
    }
    else if (group.level === viewModel.numberOfGroupLevels - 2) {
        return 1;
    }
    else {
        return helpers.sum(group.chartGroups.map(g => calculateSubgroupMargins(g, viewModel)));
    }
}