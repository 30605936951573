import { HorizontalPlotter } from "./horizontalPlotter";
import { ViewModel } from "../../settings/viewModel";
import { PlotDefinition, ChartType } from "../../definitions";
import { DataProperty } from "../../library/constants";

export class TablePlotter extends HorizontalPlotter {
    constructor(viewModel: ViewModel, width: number, height: number, reportArea: d3.Selection<SVGElement, any, any, any>, svg: d3.Selection<SVGElement, any, any, any>,
        locale: string) {
        super(viewModel, width, height, reportArea, svg, locale);
    }

    protected getPlotDefinition(): PlotDefinition {
        let plotDefinition = this.getBlankPlotDefinition(0);
        if (this.viewModel.HasValueColumn) {
            this.addValueColumns(plotDefinition, ChartType.Table);
        }
        if (this.viewModel.HasReferenceColumn) {
            this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.AbsoluteDifference, DataProperty.ReferenceValue, false, false);
            this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.RelativeDifference, DataProperty.ReferenceValue, false, false);
        }
        if (this.viewModel.HasSecondReferenceColumn) {
            this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.SecondAbsoluteDifference, DataProperty.ReferenceValue, false, false);
            this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.SecondRelativeDifference, DataProperty.ReferenceValue, false, false);
        }
        if (this.viewModel.HasThirdReferenceColumn) {
            this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.ThirdAbsoluteDifference, DataProperty.ReferenceValue, false, false);
            this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.ThirdRelativeDifference, DataProperty.ReferenceValue, false, false);
        }
        if (this.viewModel.HasFourthReferenceColumn) {
            this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.FourthAbsoluteDifference, DataProperty.ReferenceValue, false, false);
            this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.FourthRelativeDifference, DataProperty.ReferenceValue, false, false);
        }
        if (this.viewModel.HasFifthReferenceColumn) {
            this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.FifthAbsoluteDifference, DataProperty.ReferenceValue, false, false);
            this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.FifthRelativeDifference, DataProperty.ReferenceValue, false, false);
        }
        if (this.viewModel.HasSixthReferenceColumn) {
            this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.SixthAbsoluteDifference, DataProperty.ReferenceValue, false, false);
            this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.SixthRelativeDifference, DataProperty.ReferenceValue, false, false);
        }
        if (this.viewModel.HasSeventhReferenceColumn) {
            this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.SeventhAbsoluteDifference, DataProperty.ReferenceValue, false, false);
            this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.SeventhRelativeDifference, DataProperty.ReferenceValue, false, false);
        }
        return plotDefinition;
    }
}
