import { ViewModel } from "../../settings/viewModel";
import { PlotDefinition, ChartType } from "../../definitions";
import { DataProperty } from "../../library/constants";
import { HorizontalPlotter } from "./horizontalPlotter";

export class AbsoluteRelativeTablePlotter extends HorizontalPlotter {
    constructor(viewModel: ViewModel, width: number, height: number, reportArea: d3.Selection<SVGElement, any, any, any>, svg: d3.Selection<SVGElement, any, any, any>,
        locale: string) {
        super(viewModel, width, height, reportArea, svg, locale);
    }

    protected getPlotDefinition(): PlotDefinition {
        let plotDefinition = this.getBlankPlotDefinition(0);
        if (this.viewModel.HasReferenceColumn) {
            this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.AbsoluteDifference, DataProperty.ReferenceValue, false, false);
            this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.RelativeDifference, DataProperty.ReferenceValue, false, false);
        }
        if (this.viewModel.HasSecondReferenceColumn) {
            this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.SecondAbsoluteDifference, DataProperty.ReferenceValue, false, false);
            this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.SecondRelativeDifference, DataProperty.ReferenceValue, false, false);
        }
        if (this.viewModel.HasThirdReferenceColumn) {
            this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.ThirdAbsoluteDifference, DataProperty.ReferenceValue, false, false);
            this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.ThirdRelativeDifference, DataProperty.ReferenceValue, false, false);
        }
        return plotDefinition;
    }
}
