import { VarianceSettings } from "../../settings/varianceSettings";
import { PlotDefinition, SAFETY_MARGIN, VERTICAL_MARGIN, ChartPlotDefinition, EXPAND_COLLAPSE_WIDTH, ACT_ABS_REL, INTEGRATED, ABSOLUTE, RELATIVE, ACTUAL, ChartType, ValueChart, AbsoluteChart, NUMBER_OF_GROUPS, PlottableDataPoints, FormulaEditMode, DataPointType } from "../../definitions";
import { DataProperty, RIGHT_SCROLLBAR_MARGIN, CategoryDisplayOptions, NORMAL, EMPTY, LogType, BLACK, DEBUG, BOLD, CATEGORIES_AREA, CATEGORY, RECT, FILL_OPACITY, STROKE_OPACITY, FILL, WHITE, STROKE, STROKE_WIDTH, COLLAPSE_RECTANGLE, POLYLINE, POINTS, GRAY, OPACITY, POINTER_EVENTS, NONE, COLLAPSE_ARROW_ICON, TRANSFORM, ShowTotals, WIDTH, HEIGHT, X, Y, RX, MOUSEOVER, MOUSEOUT, CLICK, CONTEXT_MENU, TEXT, FONT_FAMILY, FONT_SIZE, PX, FONT_WEIGHT, HIGHLIGHTABLE, TOOLTIP, BorderType, DifferenceLabel, Scenario, ColumnFormat, P, ShowAsTableOptions, TOP_N_RECTANGLE, MOUSEENTER, CHART_AREA, FORMULA_CATEGORY, FONT_STYLE, ITALIC, INITIAL, FORMULA_ELEMENT, PATH, D, COMMENT_MARKER, RECT_TOTAL_BACKGROUND, RECT_BACKGROUND, LINE_BORDER, LINE_TOTAL_BORDER, TopNType, ZEBRABI_TABLE_CONTAINER, CHART_GROUP_INDEX, FONT_SIZE_UNIT } from "../../library/constants";
import { Title } from "../title";
import { ViewModel } from "../../settings/viewModel";
import { getOrdinalScale, addBlurIfNeeded, plotGridline, addMouseHandlers } from "../charts/chart";
import { ChartHierarchy } from "../chartHierarchy";
import { Visual } from "../../visual";
import { populateAndDisplayCategoryContextMenu } from "../../ui/contextMenu";
import { DataPoint } from "../dataPoint";
import { Extreme } from "../extreme";
import { ChartGroup } from "../chartGroup";
import { verticalScaling } from "./verticalScaling";
import { plotTable } from "../charts/plotTable";
import { plotPlusMinusDotChart } from "../charts/plotPlusMinusDotChart";
import { plotIntegratedChart } from "../charts/plotIntegratedChart";
import { plotStructureChart } from "../charts/plotStructureChart";
import { plotPinChart } from "../charts/plotPinChart";
import { plotWaterfallChart } from "../charts/plotWaterfallChart";
import { plotPlusMinusChart } from "../charts/plotPlusMinusChart";
import { getVarianceColor } from "../charts/chart";

import * as drawing from "./../../library/drawing";
import * as debug from "./../../library/debug";
import * as helpers from "./../../library/helpers";
import * as d3 from "d3";
import { getAdditionalMeasurePropertyFromIndex, contains, isAbsoluteDifferenceMeasure, isRelativeMeasure } from "../../helpers";
import * as ui from "./../../library/ui/showTooltip";

export abstract class Plotter {
    public totalWidth: number;
    public columnTotalWidth: number;
    protected numberOfCharts: number;
    public multipleCharts: boolean;
    public settings: VarianceSettings;
    public readonly headerHeight: number;
    public readonly groupHeaderHeight: number;
    public grandTotalHeight: number;
    public categoriesWidth: number;
    public readonly bottomMargin: number;
    public titleHeight: number;
    public currentYPosition: number;
    public currentRow: number;
    public currentChart: number;
    public chartHeights: number[];
    public totalVisualHeight: number;
    public rightSideMargin: number;
    protected availableHeight: number;
    public readonly availableWidth: number;
    public plotArea: d3.Selection<SVGElement, any, any, any>;
    protected chartId: number;
    protected currentGroupId: number;
    protected xPosition: number;
    protected xPositionChartGroupTotal: number;
    protected definitionToPlot: PlotDefinition;
    public partialPlot: boolean;
    public fullDataProperties: DataProperty[];
    private title: Title;
    public absoluteScaleGroups: number[];
    public relativeScaleGroups: number[];
    private categoriesMargin = 10;
    public currentlyShowingDataPoints: DataPoint[];
    public foundActual: boolean;
    public actualXPosition: number;
    public actualWidth: number;
    public actualYStart: number;
    public actualYEnd: number;
    public valueChartIntegrated: boolean;
    public dataPropertyForComments: DataProperty = null;
    public columnGrandTotalHeaderBackground: d3.Selection<SVGElement, any, any, any>;
    public columnGrandTotalBodyBackground: d3.Selection<SVGElement, any, any, any>;
    public columnGrandTotalRowTotalBackground: d3.Selection<SVGElement, any, any, any>;

    constructor(public viewModel: ViewModel, public width: number, public height: number, public reportArea: d3.Selection<SVGElement, any, any, any>, public svg: d3.Selection<SVGElement, any, any, any>,
        protected locale: string) {
        this.currentlyShowingDataPoints = [];
        this.fullDataProperties = [];
        this.settings = viewModel.settings;
        this.multipleCharts = this.isMultipleCharts();
        this.initialize();
        this.categoriesWidth = SAFETY_MARGIN;
        this.plotTitle(svg);
        this.titleHeight = this.title.getHeight();
        this.headerHeight = this.getHeaderHeight();
        this.groupHeaderHeight = this.getGroupHeaderHeight();
        if (viewModel.getNumberOfCharts() > 0 && viewModel.chartGroups.hasCategories && this.settings.showCategories) {
            this.categoriesWidth = this.calculateCategoryWidth();
        }
        this.foundActual = false;
        this.bottomMargin = this.getBottomMargin();
        this.currentYPosition = 0;
        this.displayDebugLines();
        this.availableHeight = this.getAvailableHeight();
        this.chartHeights = this.getChartHeights();
        this.grandTotalHeight = this.getGrandTotalHeight();
        this.calculateTotalVisualHeight();
        this.rightSideMargin = this.getRightSideMargin();
        if (this.rightSideMargin === RIGHT_SCROLLBAR_MARGIN && !this.settings.showFrozenTitle()) {
            this.titleHeight = this.title.getHeight();
            this.availableHeight = this.getAvailableHeight();
            this.chartHeights = this.getChartHeights();
        }
        if (!this.settings.titleWrap) {
            this.title.fitTextToAvailableWidth();
        }
        if (this.rightSideMargin === RIGHT_SCROLLBAR_MARGIN && this.settings.plottingHorizontally() && this.settings.showAsTable && this.settings.categoriesDisplayOptions === CategoryDisplayOptions.Auto) {
            let shouldUsePrefix = this.settings.hasInvertOrResult() && this.settings.showPrefixes;
            let prefixWidth = shouldUsePrefix ? drawing.measureTextWidth("=", this.settings.labelFontSize, this.settings.labelFontFamily, BOLD, NORMAL) + 3 : 0;
            if (this.categoriesWidth !== Math.round(this.viewModel.maxCategoryWidth) + prefixWidth + this.categoriesMargin) {
                this.categoriesWidth -= (RIGHT_SCROLLBAR_MARGIN - SAFETY_MARGIN);
            }
        }
        this.availableWidth = Math.floor(this.width - this.rightSideMargin - this.categoriesWidth - VERTICAL_MARGIN);
        this.totalWidth = this.width - this.rightSideMargin - (this.settings.showFrozenCategories() ? this.categoriesWidth : 0);
        this.definitionToPlot = this.getDefinitionThatFits();
        this.settings.onlyOneColumn = this.definitionToPlot.chartPlotDefinitions.length === 1;
        this.definitionToPlot.chartPlotDefinitions = this.definitionToPlot.chartPlotDefinitions.sort((d1, d2) => d1.plotOrder - d2.plotOrder);
        this.setCommentsDataProperty();
        this.settings.plottedDataProperties = this.definitionToPlot.chartPlotDefinitions.map(cpd => cpd.dataProperty.toString());
        this.setUsedScaleGroups();
        this.valueChartIntegrated = false;
        // This helps with the not showing the scrollbars if they are not needed. It seems that getting the bounding box of any element
        // somehow helps the browser figure out the correct svg size and it doesn't show the scrollbars if they are not needed.
        let bb = drawing.getBoundingBox(this.svg);
    }

    private setCommentsDataProperty() {
        let dataPropertiesToPlot = this.definitionToPlot.chartPlotDefinitions.map(cd => cd.dataProperty);
        let orderedProperties = [
            DataProperty.AbsoluteDifference, DataProperty.SecondAbsoluteDifference, DataProperty.ThirdAbsoluteDifference, DataProperty.FourthAbsoluteDifference, DataProperty.FifthAbsoluteDifference, DataProperty.SixthAbsoluteDifference, DataProperty.SeventhAbsoluteDifference,
            DataProperty.Value, DataProperty.ReferenceValue, DataProperty.SecondReferenceValue, DataProperty.ThirdReferenceValue, DataProperty.FourthReferenceValue, DataProperty.FifthReferenceValue, DataProperty.SixthReferenceValue, DataProperty.SeventhReferenceValue
        ];
        for (let index = 0; index < orderedProperties.length; index++) {
            if (contains(dataPropertiesToPlot, orderedProperties[index])) {
                this.dataPropertyForComments = orderedProperties[index];
                break;
            }
        }
    }

    public abstract getRowHeight(): number;
    protected abstract getChartHeights();
    protected abstract calculateTotalVisualHeight(): void;
    public abstract plotReport(partialPlot: boolean): void;
    protected abstract getDefinitionThatFits(): PlotDefinition;
    protected abstract getChartWidth(d: ChartPlotDefinition, groupIndex: number);
    protected abstract initialize();
    protected abstract getMaxCategoryWidth(): number;
    protected abstract isMultipleCharts(): boolean;

    protected getAvailableHeight(): number {
        return this.height - this.bottomMargin - this.getHeadersHeight() - this.titleHeight;
    }

    private getHeaderHeight(): number {
        return drawing.getEstimatedTextHeight("Header", this.settings.labelFontSize, this.settings.labelFontFamily, NORMAL, NORMAL) * 1.2;
    }

    private getGroupHeaderHeight(): number {
        if (this.viewModel.numberOfGroupLevels === 0 || this.oneChartGroupAndGrandTotalGroup()) {
            return 0;
        }
        return drawing.getEstimatedTextHeight("Header", this.settings.groupTitleFontSize, this.settings.groupTitleFontFamily, NORMAL, NORMAL);
    }

    private getGrandTotalHeight(): number {
        if (this.settings.showRowGrandTotal) {
            return this.getRowHeight();
        }
        return 0;
    }

    private oneChartGroupAndGrandTotalGroup(): boolean {
        return this.viewModel.getNumberOfCharts() === 2 &&
            this.viewModel.lastLevelChartGroups[this.viewModel.chartGroups.length - 1].isRowGrandTotal;
    }

    public plotTitle(svg: d3.Selection<SVGElement, any, any, any>) {
        if (this.settings.showTitle) {
            this.title = new Title(this.settings, Visual.height, Visual.width, this.getTitleText(), svg);
            this.title.addTitle();
        }
        else {
            this.title = new Title(this.settings, Visual.height, Visual.width, EMPTY, svg);
            this.title.addTitle();
        }
    }

    public filterRemovedChartsFromDefinitions(plotDefinitions: PlotDefinition[]) {
        if (this.settings.proVersionActive()) {
            let removedColumns = this.settings.getHiddenColumns();
            for (let i = 0; i < plotDefinitions.length; i++) {
                plotDefinitions[i].chartPlotDefinitions = plotDefinitions[i].chartPlotDefinitions.filter(def => removedColumns.indexOf(def.dataProperty) < 0);
            }
        }
    }

    public filterEmptyColumns(plotDefinitions: PlotDefinition[]) {
        if (this.settings.proVersionActive()) {
            for (let i = 0; i < plotDefinitions.length; i++) {
                plotDefinitions[i].chartPlotDefinitions = plotDefinitions[i].chartPlotDefinitions.filter(def => !this.viewModel.emptyColumn[def.dataProperty]);
            }
        }
    }

    private getTitleText() {
        return this.settings.titleText === "" ? this.viewModel.title : this.settings.titleText;
    }

    private displayDebugLines() {
        if (debug.shouldLog(LogType.DisplayBounds)) {
            if (this.settings.showTitle) {
                drawing.drawLine(this.reportArea, 0, this.width, this.titleHeight, this.titleHeight, 1, BLACK, DEBUG);
                drawing.drawLine(this.reportArea, 0, this.width, this.titleHeight + this.headerHeight, this.titleHeight + this.headerHeight, 1, BLACK, DEBUG);
            }
        }
    }

    protected getMinimumCategoryHeight(): number {
        let fontWeight = this.viewModel.hasHierarchy ? BOLD : NORMAL;
        return drawing.getEstimatedTextHeight("Category", this.settings.labelFontSize, this.settings.labelFontFamily, fontWeight, NORMAL);
    }

    protected calculateCategoryWidth(): number {
        if (this.settings.categoriesDisplayOptions === CategoryDisplayOptions.FixedWidth) {
            if (this.settings.categoriesWidth == null) {
                return 0;
            }
            return this.settings.categoriesWidth;
        }
        if (this.settings.categoriesDisplayOptions === CategoryDisplayOptions.Full) {
            return Math.round(this.viewModel.chartGroups.getMaxCategoryWidthNoOutliers(this.viewModel));
        }
        let maxCategoryWidth = this.getMaxCategoryWidth();
        let shouldUsePrefix = this.settings.hasInvertOrResult() && this.settings.showPrefixes;
        let prefixWidth = shouldUsePrefix ? drawing.measureTextWidth("=", this.settings.labelFontSize, this.settings.labelFontFamily, BOLD, NORMAL) + 3 : 0;
        let categoriesWidth = Math.min(this.viewModel.maxCategoryWidth, maxCategoryWidth) + prefixWidth;
        return Math.round(categoriesWidth) + this.categoriesMargin;
    }

    protected getBottomMargin(): number {
        return SAFETY_MARGIN;
    }

    protected getRightSideMargin(): number {
        return this.totalVisualHeight + (this.settings.freezeGrandTotal ? this.grandTotalHeight : 0) > this.availableHeight ? RIGHT_SCROLLBAR_MARGIN : SAFETY_MARGIN;
    }

    protected shouldShowCategories(i: number): boolean {
        return this.settings.showCategories;
    }

    protected plotSingleOtherArrow(categoriesArea: d3.Selection<SVGElement, any, any, any>, otherDP: DataPoint, width: number, xoffset: number, yScale: d3.ScaleBand<string>, settings: VarianceSettings, up: boolean) {
        let topNSetting = settings.getTopNSetting(otherDP.level);
        //let topNSettingNumber = this.options.isInFocus ? topNSetting.numberInFocusMode : topNSetting.number;
        let topNSettingNumber = topNSetting.number;
        if (up && topNSettingNumber === 1) {
            return;
        }
        let tooltipTextType = "";
        switch (topNSetting.type) {
            case TopNType.Top:
                tooltipTextType = "Top "
                break;

            case TopNType.Bottom:
                tooltipTextType = "Bottom "
                break;

            case TopNType.TopAndBottom:
                tooltipTextType = "Top and Bottom "
                break;
        }
        let rectUp = categoriesArea.append(RECT).data([otherDP])
            .attr(FILL_OPACITY, 0.1)
            .attr(STROKE_OPACITY, 0)
            .attr(FILL, WHITE)
            .attr(STROKE, GRAY)
            .attr(STROKE_WIDTH, 1)
            .attr(WIDTH, width)
            .attr(HEIGHT, width / 2)
            .attr(X, xoffset)
            // get centered text height, subtract half the font size to get to middle of band, subtract half of bandwidth to get to the top of the band, add half of the difference between bandwidth and font size center the arrows. 
            .attr(Y, drawing.centerTextVertically(yScale, otherDP.getCategory(this.viewModel), settings.labelFontSize) - settings.labelFontSize / 2 - yScale.bandwidth() / 2 + (yScale.bandwidth() - settings.labelFontSize) / 2 + (up ? 0 : width / 2))
            .attr(RX, 1)
            .classed(TOP_N_RECTANGLE, true)
            .on(MOUSEOVER, () => {
                arrowUp.attr(STROKE, BLACK);
                rectUp.attr(STROKE, BLACK).attr(STROKE_OPACITY, 1);
                let tooltipText = up ? "Decrease to " + tooltipTextType + (topNSettingNumber - 1) : "Increase to " + tooltipTextType + (topNSettingNumber + 1);
                let tooltipYPos = drawing.centerTextVertically(yScale, otherDP.getCategory(this.viewModel), settings.labelFontSize);
                ui.showTooltip(Visual.visualDiv, { x: xoffset + width + 5, y: tooltipYPos + this.getHeadersHeight() }, tooltipText, 250, 1000);
            })
            .on(MOUSEOUT, () => {
                arrowUp.attr(STROKE, GRAY);
                rectUp.attr(STROKE, GRAY).attr(STROKE_OPACITY, 1);
            }).on(CLICK, () => {
                let setting = settings.getTopNSetting(otherDP.level);
                setting.number += up ? -1 : 1;
                setting.number = Math.min(Math.max(1, setting.number), 99);
                settings.setTopNSetting(setting.category, setting.dataProperty, setting.level, setting.number, setting.numberInFocusMode, setting.type);
                settings.persistTopNSettings();
            });

        let arrowUp = categoriesArea
            .append(POLYLINE)
            .attr(POINTS, p => {
                let y = drawing.centerTextVertically(yScale, otherDP.getCategory(this.viewModel), settings.labelFontSize) - settings.labelFontSize / 2 - yScale.bandwidth() / 2 + (yScale.bandwidth() - settings.labelFontSize) / 2 + width / 4 + (up ? 0 : width / 2);
                return `${xoffset + width / 3},${y + (up ? width / 6 : -width / 6)} ${xoffset + width / 2},${y - (up ? width / 6 : -width / 6)} ${xoffset + 2 / 3 * width},${y + (up ? width / 6 : -width / 6)}`;
            })
            .attr(STROKE, GRAY)
            .attr(STROKE_WIDTH, 1)
            .attr(FILL_OPACITY, 0)
            .attr(OPACITY, 0)
            .attr(POINTER_EVENTS, NONE)
            .classed(COLLAPSE_ARROW_ICON, true);
    }

    protected plotOtherNArrows(categoriesArea: d3.Selection<SVGElement, any, any, any>, chartHierarchy: ChartHierarchy, settings: VarianceSettings, yScale: d3.ScaleBand<string>, higherLevelOther: boolean, additionalOffset: number) {
        let otherDP = chartHierarchy.otherDataPoint;
        let xoffset = otherDP.getLeftIndent(0) + additionalOffset + 2 + drawing.measureTextWidth(otherDP.category, settings.labelFontSize, settings.labelFontFamily, BOLD, NORMAL);
        let width = Math.min(drawing.measureTextHeight(otherDP.category, settings.labelFontSize, settings.labelFontFamily, BOLD, NORMAL), 24);

        this.plotSingleOtherArrow(categoriesArea, otherDP, width, xoffset, yScale, settings, true);
        this.plotSingleOtherArrow(categoriesArea, otherDP, width, xoffset, yScale, settings, false);
    }

    public getDataPointsToPlot(chartHierarchy: ChartHierarchy, yScale: d3.ScaleBand<string>): PlottableDataPoints {
        let dataPoints = chartHierarchy.dataPoints();
        if (chartHierarchy.isGrandTotal) {
            return {
                inView: [chartHierarchy.grandTotalDataPoint],
                inViewWithoutFormulas: [chartHierarchy.grandTotalDataPoint]
            };
        }
        let dataPointsInView: DataPoint[] = [];
        let dataPointsInViewWithoutPercentFormulas: DataPoint[] = [];
        dataPoints.forEach(dataPoint => {
            if (this.isDataPointInView(dataPoint, yScale)) {
                dataPointsInView.push(dataPoint);
                if (!dataPoint.isRowDisplayUnitPercent()) {
                    dataPointsInViewWithoutPercentFormulas.push(dataPoint);
                }
            }
        });
        return {
            inView: dataPointsInView,
            inViewWithoutFormulas: dataPointsInViewWithoutPercentFormulas,
        }
    }

    public getDataPointsToPlotChartElements(chartHierarchy: ChartHierarchy, yScale: d3.ScaleBand<string>, dataProperty: DataProperty, filterSuppressedOthers: boolean): PlottableDataPoints {
        let dataPointsInView = this.getDataPointsToPlot(chartHierarchy, yScale);
        if (filterSuppressedOthers && this.settings.getSuppressOthers(dataProperty)) {
            dataPointsInView.inView = dataPointsInView.inView.filter(x => x.dataPointType !== DataPointType.Other && x.dataPointType !== DataPointType.OtherTotal);
            dataPointsInView.inViewWithoutFormulas = dataPointsInView.inViewWithoutFormulas.filter(x => x.dataPointType !== DataPointType.Other && x.dataPointType !== DataPointType.OtherTotal);
        }
        return {
            inView: dataPointsInView.inView,
            inViewWithoutFormulas: dataPointsInView.inViewWithoutFormulas
        }
    }

    public isDataPointInView(dataPoint: DataPoint, yScale: d3.ScaleBand<string>): boolean {
        let scrollTop = (<any>window).currentScrollTop;
        let yPosition = yScale(dataPoint.getCategory(this.viewModel));
        return yPosition + this.getCurrentChartHeight() + Visual.height + 50 > scrollTop
            && yPosition - Visual.height - 50 < scrollTop + this.height;
    }

    // tslint:disable-next-line: max-func-body-length
    protected plotCategories(groupIndex: number, chartHierarchyIndex: number, useInteractions: boolean) {
        if (this.settings.categoriesDisplayOptions === CategoryDisplayOptions.FixedWidth && this.settings.categoriesWidth == null) {
            return;
        }
        let height = this.getCurrentChartHeight();
        let chartHierarchy = this.viewModel.getChartHierarchy(groupIndex, chartHierarchyIndex);
        let settings = this.settings;
        let yScale = getOrdinalScale(chartHierarchy.dataPoints(), this.currentYPosition, this.currentYPosition + height, this.viewModel, settings.getGapBetweenColumns());
        let categoriesArea = drawing.createGroupElement(this.plotArea, `${CATEGORIES_AREA}_${groupIndex}_${chartHierarchyIndex} ${CATEGORIES_AREA}`);
        let categoriesDataPoints = this.getDataPointsToPlot(chartHierarchy, yScale);
        this.currentlyShowingDataPoints = categoriesDataPoints.inView;
        let categories = categoriesArea
            .selectAll(`.${CATEGORY}_${groupIndex}_${chartHierarchyIndex}`)
            .data(categoriesDataPoints.inView);

        let fontSize = settings.labelFontSize;
        let categoriesWidth = this.categoriesWidth;
        let viewModel = this.viewModel;

        let bottomEdge = this.height;
        let expandCollapseEnabled = this.viewModel.hasHierarchy && this.settings.getRealInteractionSettingValue(this.settings.allowExpandCollapseRowsChange);
        let expandCollapseWidth = expandCollapseEnabled ? EXPAND_COLLAPSE_WIDTH : 0;
        let total = (chartHierarchy.isGrandTotal || chartHierarchy.totalDataPoint?.isFormula()) ? null : chartHierarchy.totalDataPoint;
        let allowSettingCalculations = true;

        let otherHierarchyHigherLevel = chartHierarchy.otherDataPoint && chartHierarchy.dataPoints().some(dp => dp.isOtherTotal());

        let shouldUsePrefix = (this.settings.hasInvertOrResult() || this.settings.hasFlatResults) && this.settings.showPrefixes;
        let prefixWidth = shouldUsePrefix ? drawing.measureTextWidth("=", settings.labelFontSize, settings.labelFontFamily, BOLD, NORMAL) + 3 : 0;
        let formulaPoints = [];
        let formulaIndent = 0;
        if (Visual.formulaEditMode !== FormulaEditMode.None) {
            formulaPoints = categoriesDataPoints.inView.filter(d => this.isCurrentDataPointBeingEdited(d));
            formulaIndent = 15;
        }

        if (otherHierarchyHigherLevel) {
            this.plotOtherNArrows(categoriesArea, chartHierarchy, settings, yScale, true, EXPAND_COLLAPSE_WIDTH + prefixWidth);
        }

        if (expandCollapseEnabled && total && (<ChartHierarchy>total.parent).hasNonRepeatingHiearchiesUnderneath()) {
            let x = total.getLeftIndent(0);
            let y = drawing.centerTextVertically(yScale, total.getCategory(this.viewModel), settings.fontSize) - fontSize / 2;

            let arrow_points_up = `${x + 3} ${y + 5}, ${x + 8} ${y}, ${x + 13} ${y + 5}`;
            let arrow_points_down = `${x + 3} ${y}, ${x + 8} ${y + 5}, ${x + 13} ${y}`;
            let arrow_points_right = `${x + 6} ${y + 7}, ${x + 11} ${y + 2}, ${x + 6} ${y - 3}`;
            let rect = categoriesArea.append(RECT).data([total])
                .attr(FILL_OPACITY, 0)
                .attr(STROKE_OPACITY, 0)
                .attr(FILL, WHITE)
                .attr(STROKE, BLACK)
                .attr(STROKE_WIDTH, 1)
                .classed(COLLAPSE_RECTANGLE, true);

            let arrow = categoriesArea
                .append(POLYLINE)
                .attr(POINTS, p => {
                    let rotation = total.isCollapsed ? 0 : ((settings.showTotals === ShowTotals.Above || settings.showTotals === ShowTotals.AboveHideValues) ? 90 : -90);
                    if (rotation === 90) {
                        return arrow_points_down;
                    } else if (rotation === -90) {
                        return arrow_points_up;
                    } else {
                        return arrow_points_right;
                    }
                })
                .attr(STROKE, GRAY)
                .attr(STROKE_WIDTH, 1)
                .attr(FILL_OPACITY, 0)
                .attr(OPACITY, 0.0)
                .attr(POINTER_EVENTS, NONE)
                .classed(COLLAPSE_ARROW_ICON, true);

            rect.attr(WIDTH, 14)
                .attr(HEIGHT, 15)
                .attr(X, x + 1)
                .attr(Y, y - 5)
                .attr(RX, 3);

            rect.on(MOUSEOVER, () => {
                (<any>window).currentScrollTop = Visual.visualDiv.scrollTop;
                arrow.attr(STROKE, BLACK);
                rect.attr(FILL_OPACITY, 0.04).attr(STROKE_OPACITY, 0.4);
            })
                .on(MOUSEOUT, () => {
                    arrow.attr(STROKE, GRAY);
                    rect.attr(FILL_OPACITY, 0).attr(STROKE_OPACITY, 0);
                })
                .on(CLICK, () => {
                    (<any>window).expandCategory = total.fullCategory;
                    if (!settings.isCollapsed(total.fullCategory) && settings.showTotals == ShowTotals.Below) {
                        (<any>window).currentScrollTop = chartHierarchy.getPlotYPosition();
                    }
                    settings.persistCollapsed(total);
                });

            if (chartHierarchy.hasChildHierarchies || chartHierarchy.totalDataPoint !== null) {
                rect.on(CONTEXT_MENU, populateAndDisplayCategoryContextMenu(settings, viewModel, chartHierarchy, bottomEdge, allowSettingCalculations, expandCollapseEnabled));
            }

            if (this.shouldAnimateExpandArrow(total)) {
                let rotation = (settings.showTotals === ShowTotals.Above || this.settings.showTotals === ShowTotals.AboveHideValues) ? 90 : -90;
                let interpol_start = "";
                let interpol_end = "";

                interpol_start = arrow_points_right;
                if (rotation === 90) {
                    interpol_end = arrow_points_down;
                } else {
                    interpol_end = arrow_points_up;
                }
                let interpolateExpand = d3.interpolateString(interpol_start, interpol_end);
                let interpolateCollapse = d3.interpolateString(interpol_end, interpol_start);
                arrow.transition()
                    .duration(300)
                    .attrTween(POINTS, (p) => { return total.isCollapsed ? interpolateCollapse : interpolateExpand });
            }
        }

        if (shouldUsePrefix) {
            let categoriesPrefix = categoriesArea
                .selectAll(`.${CATEGORY}-prefix_${groupIndex}_${chartHierarchyIndex}`)
                .data(categoriesDataPoints.inView);
            let text = categoriesPrefix
                .enter().append(TEXT)
                .text(d => d.getCategoryPrefix())
                .style(FONT_FAMILY, settings.labelFontFamily)
                .style(FONT_SIZE, `${fontSize}${FONT_SIZE_UNIT}`)
                .style(FONT_WEIGHT, d => d.getFontWeight())
                .attr(X, d => d.getLeftIndent(expandCollapseWidth + formulaIndent) + (d.getCategoryPrefix() === "-" ? 1 : 0))
                .attr(Y, d => drawing.centerTextVertically(yScale, d.getCategory(this.viewModel), settings.fontSize))
                .attr(FILL, (d: DataPoint) => d.getLabelColor(settings.labelFontColor))
                .classed(HIGHLIGHTABLE, true)
                .classed(TOOLTIP, true)
                .classed(`${CATEGORY}-prefix_${groupIndex}_${chartHierarchyIndex}`, true)
                .classed(CATEGORY, true);
            categoriesPrefix = categoriesPrefix.merge(text);
            addBlurIfNeeded(categoriesPrefix, settings);
        }

        let totalPrefixWidth = prefixWidth + expandCollapseWidth + formulaIndent;
        if (formulaPoints.length) {
            let triangles = categoriesArea.selectAll(".symbol" + chartHierarchyIndex)
                .data(formulaPoints);
            let path = triangles.enter().append(PATH);
            triangles.exit().remove();
            triangles = triangles.merge(path);

            triangles
                .attr(D, d3.symbol().type(d3.symbolTriangle).size(45))
                .attr(TRANSFORM, d => `translate(5, ${Math.round(yScale(d.getCategory(viewModel)) + yScale.bandwidth() / 2)}) rotate(90)`)
                .attr(FILL, this.settings.colorScheme.highlightColor)
                .classed("symbol" + chartHierarchyIndex, true);
        }

        let text = categories.enter().append(TEXT);
        categories = categories.merge(text);
        categories
            .each(d => {
                if (d.isOther()) {
                    this.plotOtherNArrows(categoriesArea, chartHierarchy, settings, yScale, false, totalPrefixWidth);
                }
            })
            .text((d) => {
                if (d.category == null) {
                    return EMPTY;
                }
                if (settings.categoriesDisplayOptions === CategoryDisplayOptions.Full) {
                    return d.category;
                }
                return drawing.getTailoredText(d.category, fontSize, settings.labelFontFamily, d.getFontWeight(), NORMAL, Math.max(categoriesWidth - d.getLeftIndent(totalPrefixWidth), 0));
            })
            .style(FONT_FAMILY, settings.labelFontFamily)
            .style(FONT_SIZE, `${fontSize}${FONT_SIZE_UNIT}`)
            .style(FONT_WEIGHT, d => d.getFontWeight())
            .style(FONT_STYLE, d => d.italic ? ITALIC : INITIAL)
            .attr(X, d => d.getLeftIndent(totalPrefixWidth))
            .attr(Y, d => drawing.centerTextVertically(yScale, d.getCategory(this.viewModel), settings.labelFontSize))
            .attr(FILL, (d: DataPoint) => d.getLabelColor(settings.labelFontColor))
            .classed(HIGHLIGHTABLE, true)
            .classed(`${CATEGORY}_${groupIndex}_${chartHierarchyIndex}`, true)
            .classed(CATEGORY, true)
            .classed(FORMULA_CATEGORY, d => this.isCurrentDataPointBeingEdited(d));
        categories.exit().remove();
        categories.on(CONTEXT_MENU, populateAndDisplayCategoryContextMenu(this.settings, this.viewModel, chartHierarchy, bottomEdge, allowSettingCalculations, expandCollapseEnabled));
        let skipLastPoint = viewModel.skipLastPointGridline(groupIndex, chartHierarchyIndex);
        plotGridline(categoriesArea, this.chartId.toString(), settings, this.viewModel, chartHierarchy.dataPoints(), 0, 0 + this.width,
            (d: DataPoint) => d.getLeftIndent(totalPrefixWidth), (d: DataPoint) => 0 + this.categoriesWidth, yScale, skipLastPoint);
        if (useInteractions) {
            addMouseHandlers(categories, null, this.svg, this.viewModel);
        }
        addBlurIfNeeded(categories, settings);
    }

    private isCurrentDataPointBeingEdited(d: DataPoint): boolean {
        if (Visual.formulaEditMode === FormulaEditMode.Edit && d.isFormula() && Visual.formulaEditPosition.fullCategory === d.fullCategory) {
            return true;
        }
        else if (Visual.formulaEditMode === FormulaEditMode.Add && d.isFormula() && d.category === EMPTY) {
            return true;
        }
        return false;
    }

    private shouldAnimateExpandArrow(total: DataPoint) {
        let expandCategory = (<any>window).expandCategory;
        return expandCategory && total && total.category === expandCategory;
    }

    public getCurrentChartHeight(): number {
        return this.chartHeights[this.currentChart];
    }

    protected improveCumulativeOffsetsIfNeeded(mm: Extreme, dataProperty: DataProperty, chartWidth: number, group: ChartGroup) {
        if (helpers.isAdditionalMeasure(dataProperty)) {
            return;
        }
        if ((mm.min === 0 || mm.max === 0) && this.settings.showDataLabels && this.isWaterfallChartPlotted()) {
            let { min, max } = this.viewModel.chartGroups.calculateCumulativeLabelOffset(mm, chartWidth, this.locale, dataProperty, group);
            mm.minLabelOffset = Math.max(mm.minLabelOffset, min);
            mm.maxLabelOffset = Math.max(mm.maxLabelOffset, max);
        }
    }

    // public addTooltips(selection: d3.Selection<any, any, any, any>) {
    //     this.tooltipServiceWrapper.addTooltip(selection,
    //         (tooltipEvent: TooltipEventArgs<DataPoint>) => this.getTooltipData(tooltipEvent.data),
    //         (tooltipEvent: TooltipEventArgs<DataPoint>) => this.settings.proVersionActive() || this.settings.isPaidLicenseInViewMode() ? tooltipEvent.data.selectionId : null);
    // }

    // public addCommentMarkerTooltip(container: d3.Selection<SVGElement, any, any, any>) {
    //     this.tooltipServiceWrapper.addTooltip(container.selectAll("." + COMMENT_MARKER),
    //         (tooltipEvent: TooltipEventArgs<DataPoint>) => this.getCommentTooltipData(tooltipEvent.data), () => null, true);
    // }

    // // tslint:disable-next-line: max-func-body-length
    // private getTooltipData(data: any): VisualTooltipDataItem[] {
    //     let dataPoint = <DataPoint>data;
    //     let tooltipDataItems: VisualTooltipDataItem[] = [];
    //     if (!dataPoint || dataPoint.shouldBlur()) {
    //         return null;
    //     }
    //     let activeGroup = dataPoint.parent.firstGroupParent;
    //     let header = getTooltipHeader(dataPoint, this.settings);
    //     if (this.viewModel.HasValueColumn && !this.settings.isColumnHiddenFromGroup(activeGroup, DataProperty.Value)) {
    //         let valueColor = getTooltipColor(dataPoint.getValue(DataProperty.Value), this.viewModel.value.scenario, this.settings);
    //         tooltipDataItems.push({
    //             header: header,
    //             displayName: this.settings.getHeader(DataProperty.Value),
    //             value: dataPoint.getLabel(DataProperty.Value),
    //             color: valueColor,
    //         });
    //     }
    //     if (this.viewModel.HasReferenceColumn && !this.settings.isColumnHiddenFromGroup(activeGroup, DataProperty.ReferenceValue)) {
    //         let referenceValueColor = getTooltipColor(dataPoint.getValue(DataProperty.ReferenceValue), this.viewModel.reference.scenario, this.settings);
    //         tooltipDataItems.push({
    //             header: header,
    //             displayName: this.settings.getHeader(DataProperty.ReferenceValue),
    //             value: dataPoint.getLabel(DataProperty.ReferenceValue),
    //             color: referenceValueColor,
    //         });
    //     }
    //     if (this.viewModel.HasSecondReferenceColumn && !this.settings.isColumnHiddenFromGroup(activeGroup, DataProperty.SecondReferenceValue)) {
    //         let referenceValueColor = getTooltipColor(dataPoint.getValue(DataProperty.SecondReferenceValue), this.viewModel.secondReference.scenario, this.settings);
    //         tooltipDataItems.push({
    //             header: header,
    //             displayName: this.settings.getHeader(DataProperty.SecondReferenceValue),
    //             value: dataPoint.getLabel(DataProperty.SecondReferenceValue),
    //             color: referenceValueColor,
    //         });
    //     }
    //     if (this.viewModel.HasThirdReferenceColumn && !this.settings.isColumnHiddenFromGroup(activeGroup, DataProperty.ThirdReferenceValue)) {
    //         let referenceValueColor = getTooltipColor(dataPoint.getValue(DataProperty.ThirdReferenceValue), this.viewModel.thirdReference.scenario, this.settings);
    //         tooltipDataItems.push({
    //             header: header,
    //             displayName: this.settings.getHeader(DataProperty.ThirdReferenceValue),
    //             value: dataPoint.getLabel(DataProperty.ThirdReferenceValue),
    //             color: referenceValueColor,
    //         });
    //     }
    //     if (this.viewModel.HasReferenceColumn) {
    //         if (!this.settings.isColumnHiddenFromGroup(activeGroup, DataProperty.AbsoluteDifference)) {
    //             tooltipDataItems.push({
    //                 header: header,
    //                 displayName: this.settings.getHeader(DataProperty.AbsoluteDifference),
    //                 value: dataPoint.getLabel(DataProperty.AbsoluteDifference),
    //                 color: getVarianceColor(this.settings, DataProperty.AbsoluteDifference)(dataPoint),
    //             });
    //         }
    //         if (!this.settings.isColumnHiddenFromGroup(activeGroup, DataProperty.RelativeDifference)) {
    //             let value = dataPoint.getLabel(DataProperty.RelativeDifference);
    //             tooltipDataItems.push({
    //                 header: header,
    //                 displayName: this.settings.getHeader(DataProperty.RelativeDifference),
    //                 value: value === undefined ? undefined : "(" + value + (this.settings.showPercentageInLabel ? ")" : "%)"),
    //                 color: BLACK,
    //                 opacity: "0",
    //             });
    //         }
    //     }
    //     if (this.viewModel.HasSecondReferenceColumn) {
    //         if (!this.settings.isColumnHiddenFromGroup(activeGroup, DataProperty.SecondAbsoluteDifference)) {
    //             tooltipDataItems.push({
    //                 header: header,
    //                 displayName: this.settings.getHeader(DataProperty.SecondAbsoluteDifference),
    //                 value: dataPoint.getLabel(DataProperty.SecondAbsoluteDifference),
    //                 color: getVarianceColor(this.settings, DataProperty.SecondAbsoluteDifference)(dataPoint),
    //             });
    //         }
    //         if (!this.settings.isColumnHiddenFromGroup(activeGroup, DataProperty.SecondRelativeDifference)) {
    //             let value = dataPoint.getLabel(DataProperty.SecondRelativeDifference);
    //             tooltipDataItems.push({
    //                 header: header,
    //                 displayName: this.settings.getHeader(DataProperty.SecondRelativeDifference),
    //                 value: value === undefined ? undefined : "(" + value + (this.settings.showPercentageInLabel ? ")" : "%)"),
    //                 color: BLACK,
    //                 opacity: "0",
    //             });
    //         }
    //     }
    //     if (this.viewModel.HasThirdReferenceColumn) {
    //         if (!this.settings.isColumnHiddenFromGroup(activeGroup, DataProperty.ThirdAbsoluteDifference)) {
    //             tooltipDataItems.push({
    //                 header: header,
    //                 displayName: this.settings.getHeader(DataProperty.ThirdAbsoluteDifference),
    //                 value: dataPoint.getLabel(DataProperty.ThirdAbsoluteDifference),
    //                 color: getVarianceColor(this.settings, DataProperty.ThirdAbsoluteDifference)(dataPoint),
    //             });
    //         }
    //         if (!this.settings.isColumnHiddenFromGroup(activeGroup, DataProperty.ThirdRelativeDifference)) {
    //             let value = dataPoint.getLabel(DataProperty.ThirdRelativeDifference);
    //             tooltipDataItems.push({
    //                 header: header,
    //                 displayName: this.settings.getHeader(DataProperty.ThirdRelativeDifference),
    //                 value: value === undefined ? undefined : "(" + value + (this.settings.showPercentageInLabel ? ")" : "%)"),
    //                 color: BLACK,
    //                 opacity: "0",
    //             });
    //         }
    //     }
    //     dataPoint.additionalMeasureLabels.forEach((measure, i) => {
    //         if (dataPoint.additionalMeasures[i]) {
    //             let dp = getAdditionalMeasurePropertyFromIndex(i);
    //             if (!this.settings.isColumnHiddenFromGroup(activeGroup, dp)) {
    //                 let color = this.getAdditionalMeasureColor(i, dataPoint, dp);
    //                 tooltipDataItems.push({
    //                     header: header,
    //                     displayName: this.settings.getHeader(dp),
    //                     value: measure,
    //                     color: color,
    //                 });
    //             }
    //         }
    //     });
    //     if (this.viewModel.HasTooltipsColumn) {
    //         dataPoint.tooltipsValues.forEach((tooltipValue, i) => {
    //             if (dataPoint.getLabel(DataProperty.TooltipsMeasure1 + i) !== null) {
    //                 tooltipDataItems.push({
    //                     header: header,
    //                     displayName: this.viewModel.tooltips[i].fieldName,
    //                     value: getFormattedTooltipMeasureLabel(tooltipValue,
    //                         getTooltipsMeasureFormat(this.viewModel.valueSources, this.viewModel.tooltips, i),
    //                         isTooltipsMeasureTypeDate(this.viewModel.valueSources, this.viewModel.tooltips, i)
    //                     ),
    //                     color: BLACK,
    //                     opacity: "0"
    //                 })
    //             }
    //         })
    //     }
    //     return tooltipDataItems;
    // }

    // private getCommentTooltipData(data: any): VisualTooltipDataItem[] {
    //     let dataPoint = <DataPoint>data;
    //     let tooltipDataItems: VisualTooltipDataItem[] = [];
    //     if (!dataPoint || !dataPoint.hasComment()) {
    //         return null;
    //     }
    //     tooltipDataItems.push({
    //         header: dataPoint.category,
    //         displayName: "",
    //         value: dataPoint.commentsValues.length > 1 ? dataPoint.commentsValues[1].toString() : dataPoint.commentsValues[0].toString(),
    //         color: this.settings.colorScheme.neutralColor,
    //     });
    //     return tooltipDataItems;
    // }

    private getAdditionalMeasureColor(i: number, dataPoint: DataPoint, dataProperty: DataProperty): string {
        let dp = getAdditionalMeasurePropertyFromIndex(i);
        let columnFormat = this.settings.getColumnFormat(dp);
        if (columnFormat === ColumnFormat.Value) {
            return this.settings.colorScheme.neutralColor;
        }
        else {
            return getVarianceColor(this.settings, dataProperty)(dataPoint);
        }
    }

    protected getCategoriesWidth(itemWidths: number[]): number {
        return this.width - this.rightSideMargin - d3.sum(itemWidths) - itemWidths.length * VERTICAL_MARGIN;
    }

    protected notEnoughSpaceForItems(itemWidths: number[]): boolean {
        let spaceNeeded = d3.sum(itemWidths) + itemWidths.length * VERTICAL_MARGIN;
        return spaceNeeded > this.availableWidth;
    }

    protected getSingleChartWidth(knownWidths: number[]): number {
        return this.availableWidth - d3.sum(knownWidths) - (knownWidths.length + 1) * VERTICAL_MARGIN;
    }

    protected increaseXPosition(elementWidth: number) {
        this.xPosition += elementWidth + VERTICAL_MARGIN;
    }

    public setDimension(height: number, width: number): any {
        this.height = height;
        this.width = width;
    }

    public getHeadersHeight(): number {
        let headersHeight = 0;
        let numberOfGroups = this.viewModel.numberOfLastLevelChartGroups;
        if (this.settings.plottingHorizontally()) {
            headersHeight += this.headerHeight + 3;
            if (this.viewModel.showingGroupHeaders() && (numberOfGroups > 1 || this.viewModel.numberOfGroupLevels > 0)) {
                headersHeight += (this.groupHeaderHeight + 2) * this.viewModel.maximumExpandedGroupLevel;
                if (this.showingScenarioHeaders()) {
                    headersHeight += this.groupHeaderHeight + 5;
                }
                else if (this.viewModel.numberOfGroupLevels === 1) {
                    headersHeight += 6;
                }
            }
            if (numberOfGroups === 1) {
                headersHeight += 8;
            }
        }
        else {
            headersHeight = this.headerHeight + 9;
        }
        return Math.ceil(headersHeight);
    }

    private showingScenarioHeaders(): boolean {
        if (this.viewModel.HasAdditionalMeasures) {
            return true;
        }
        else if (this.settings.chartType === ACT_ABS_REL && this.settings.plottingHorizontally() && this.definitionToPlot && this.definitionToPlot.chartPlotDefinitions.length === 1) {
            return false;
        }
        else if (this.settings.showAsTable &&
            [INTEGRATED].indexOf(this.settings.chartType) === -1 &&
            ([ABSOLUTE, RELATIVE].indexOf(this.settings.chartType) === -1 || this.viewModel.HasSecondReferenceColumn) &&
            (this.settings.chartType !== ACTUAL || this.viewModel.HasReferenceColumn) &&
            (this.settings.chartType !== ACT_ABS_REL || this.viewModel.HasReferenceColumn)) {
            return true;
        }
        if ([INTEGRATED].indexOf(this.settings.chartType) > -1 && !this.settings.showAsTable) {
            return false;
        }
        else if (this.settings.plottingHorizontally() && this.viewModel.additionalMeasures.length === 0 && this.definitionToPlot && this.definitionToPlot.chartPlotDefinitions.length === 1) {
            return false;
        }
        return true;
    }

    protected calculateChartWidths() {
        verticalScaling(this.viewModel, this.availableWidth, this.definitionToPlot);
    }

    private initializeTotalColumnBackrounds(chartGroupLevel: number, chartGroupIndex: number) {
        this.xPositionChartGroupTotal = this.xPosition.valueOf();
        this.columnGrandTotalHeaderBackground = drawing.drawBackgroundFill(Visual.headers, this.settings.getColumnTotalBackgroundColor(chartGroupLevel), 0, 1, 0, 0, `${RECT_TOTAL_BACKGROUND}_${chartGroupLevel} ${CHART_GROUP_INDEX}_${chartGroupIndex}`);
        this.columnGrandTotalBodyBackground = drawing.drawBackgroundFill(this.plotArea, this.settings.getColumnTotalBackgroundColor(chartGroupLevel), 0, 0, 0, 0, `${RECT_TOTAL_BACKGROUND}_${chartGroupLevel} ${CHART_GROUP_INDEX}_${chartGroupIndex}`);

        if (this.settings.showRowGrandTotal) {
            this.columnGrandTotalRowTotalBackground = drawing.drawBackgroundFill(Visual.grandTotalRow, this.settings.getColumnTotalBackgroundColor(chartGroupLevel), 0, 0, 0, 0, `${RECT_TOTAL_BACKGROUND}_${chartGroupLevel} ${CHART_GROUP_INDEX}_${chartGroupIndex}`)
        }
    }

    private plotTotalColumnsBackgrounds(chartGroupLevel: number, plottingGrandTotalRow: boolean) {
        let xStartRect = 0;
        let chartGroupTotalWidth = this.definitionToPlot.chartPlotDefinitions.length === 1 ? this.columnTotalWidth + 6 : this.xPosition - this.xPositionChartGroupTotal;
        let yStartRect = chartGroupLevel * (this.groupHeaderHeight) + (chartGroupLevel > 0 ? 3 : 0);
        xStartRect = Math.round(this.xPositionChartGroupTotal - 6);

        this.columnGrandTotalHeaderBackground.attr(X, xStartRect)
            .attr(Y, yStartRect)
            .attr(WIDTH, chartGroupTotalWidth + 2)
            .attr(HEIGHT, this.totalVisualHeight);

        drawing.drawColorBorders(Visual.headers, this.settings.getColumnTotalBorderColor(chartGroupLevel), xStartRect, yStartRect, chartGroupTotalWidth + 2, this.totalVisualHeight, false, BorderType.Header, `${LINE_TOTAL_BORDER}_${chartGroupLevel}`);
        this.columnGrandTotalBodyBackground.attr(X, xStartRect)
            .attr(Y, 0)
            .attr(WIDTH, chartGroupTotalWidth + 2)
            .attr(HEIGHT, this.totalVisualHeight);

        drawing.drawColorBorders(this.plotArea, this.settings.getColumnTotalBorderColor(chartGroupLevel), xStartRect, 0, chartGroupTotalWidth + 2, this.totalVisualHeight, this.settings.showRowGrandTotal, BorderType.Body, `${LINE_TOTAL_BORDER}_${chartGroupLevel}`);

        if (plottingGrandTotalRow) {
            let chartGroupWidth = this.definitionToPlot.chartPlotDefinitions.length === 1 ? this.columnTotalWidth + 6 : this.xPosition - this.xPositionChartGroupTotal;
            if (this.settings.showRowGrandTotal) {
                this.columnGrandTotalRowTotalBackground.attr(X, this.xPositionChartGroupTotal - 6)
                    .attr(WIDTH, chartGroupWidth + 2)
                    .attr(HEIGHT, this.grandTotalHeight);

                drawing.drawColorBorders(Visual.grandTotalRow, this.settings.getColumnTotalBorderColor(chartGroupLevel), this.xPositionChartGroupTotal - 6, 0, chartGroupWidth + 2, this.grandTotalHeight + 1, plottingGrandTotalRow, BorderType.GrandTotal, `${LINE_TOTAL_BORDER}_${chartGroupLevel}`);
            }
        }
    }

    private reallignGrandTotalRowBackgrounds(chartGroupLevel: number) {
        let chartGroupTotalWidth = this.definitionToPlot.chartPlotDefinitions.length === 1 ? this.columnTotalWidth + 6 : this.xPosition - this.xPositionChartGroupTotal;
        if (this.settings.showRowGrandTotal) {
            this.columnGrandTotalRowTotalBackground.attr(X, this.xPositionChartGroupTotal - 6)
                .attr(WIDTH, chartGroupTotalWidth + 2)
                .attr(HEIGHT, this.grandTotalHeight);
            drawing.drawColorBorders(Visual.grandTotalRow, this.settings.getColumnTotalBorderColor(chartGroupLevel), this.xPositionChartGroupTotal - 6, 0, chartGroupTotalWidth + 2, this.grandTotalHeight + 1, true, BorderType.GrandTotal, `${LINE_TOTAL_BORDER}_${chartGroupLevel}`);
        }
    }

    protected plot(groupIndex: number, hierarchyIndex: number, multiples: boolean, isVisible: boolean, plottingGrandTotalRow: boolean, columnGrandTotal: boolean, chartGroupLevel: number) {
        let isColumnGrandTotalDrawing = columnGrandTotal && !this.partialPlot;

        if (isColumnGrandTotalDrawing && hierarchyIndex === 0) {
            this.initializeTotalColumnBackrounds(chartGroupLevel, groupIndex);
        }

        this.definitionToPlot.chartPlotDefinitions.forEach(chartDefinition => {
            this.plotSingleChart(groupIndex, hierarchyIndex, chartDefinition, multiples, isVisible, plottingGrandTotalRow, columnGrandTotal);
        });

        if (isColumnGrandTotalDrawing) {
            if (hierarchyIndex === 0) {
                this.plotTotalColumnsBackgrounds(chartGroupLevel, plottingGrandTotalRow);
            }
            if (plottingGrandTotalRow) {
                this.reallignGrandTotalRowBackgrounds(chartGroupLevel);
            }
        }
    }

    protected plotSingleChart(groupIndex: number, hierarchyIndex: number, d: ChartPlotDefinition, multiples: boolean, isVisible: boolean, plottingGrandTotalRow: boolean, chartGroupSubTotal: boolean) {
        let shouldPlotColumn = !this.settings.isColumnHiddenFromGroup(this.viewModel.lastLevelChartGroups[groupIndex], d.dataProperty) || (this.viewModel.numberOfGroupLevels > 0 && !this.settings.plottingHorizontally());
        if (!shouldPlotColumn) {
            return;
        }

        let width = this.getChartWidth(d, groupIndex);
        if (!this.foundActual && d.dataProperty === this.settings.analyticsDataProperty && d.chartType !== ChartType.Table) {
            this.actualXPosition = this.xPosition;
            this.actualYStart = this.currentYPosition;
            this.actualWidth = width;
            this.foundActual = true;
        }

        if (isVisible) {
            if (this.settings.shouldDrawColumnRect(this) && this.currentRow === 0) {
                drawing.drawBackgroundFill(this.plotArea, this.settings.getColumnBackgroundFill(d.dataProperty), this.xPosition - 3, 0, width + 6, this.totalVisualHeight, `${RECT_BACKGROUND} ${RECT_BACKGROUND}_${this.chartId} ${CHART_GROUP_INDEX}_${groupIndex}`);
                if (this.settings.showRowGrandTotal && Visual.grandTotalRow !== undefined) {
                    drawing.drawBackgroundFill(Visual.grandTotalRow, this.settings.getColumnBackgroundFill(d.dataProperty), this.xPosition - 3, 0, width + 6, this.grandTotalHeight, `${RECT_BACKGROUND} ${RECT_BACKGROUND}_${this.chartId} ${CHART_GROUP_INDEX}_${groupIndex}`);
                }
            }

            if (this.settings.shouldDrawColumnBorders(this)) {
                if (this.currentRow === 0) {
                    drawing.drawColorBorders(this.plotArea, this.settings.getColumnBorder(d.dataProperty), Math.round(this.xPosition) - 3, 0, width + 6, this.totalVisualHeight, this.settings.showRowGrandTotal && this.settings.freezeGrandTotal, BorderType.Body, `${LINE_BORDER}_${this.chartId}`);
                } else if (plottingGrandTotalRow) {
                    drawing.drawColorBorders(this.plotArea, this.settings.getColumnBorder(d.dataProperty), Math.round(this.xPosition) - 3, 0, width + 6, this.grandTotalHeight, true, BorderType.GrandTotal, `${LINE_BORDER}_${this.chartId}`);
                }
            }

            switch (d.chartType) {
                case ChartType.Table:
                    plotTable(width, this.xPosition, groupIndex, hierarchyIndex, this.chartId, d.dataProperty, d.plotOrder, multiples, this);
                    break;
                case ChartType.ValueChart:
                    this.plotValueChart(width, this.xPosition, groupIndex, hierarchyIndex, multiples, d.plotIntegratedChart, d.allowChangingLables, this.settings.integratedDifferenceLabel, d.dataProperty, d.referenceProperty, d.plotOrder);
                    break;
                case ChartType.AbsoluteChart:
                    this.plotAbsoluteChart(width, this.xPosition, groupIndex, hierarchyIndex, d.dataProperty, d.plotOrder, multiples);
                    break;
                case ChartType.PlusMinusDot:
                    plotPlusMinusDotChart(width, this.xPosition, groupIndex, hierarchyIndex, this.chartId, multiples, this, d.dataProperty, d.plotOrder);
                    break;
            }
        }
        this.chartId++;
        if (this.definitionToPlot.chartPlotDefinitions.length > 1 || this.viewModel.isLastHierarchy(groupIndex, hierarchyIndex) || !this.settings.plottingHorizontally() || this.settings.shouldShowScenarioSortHeaders(this)) {
            this.increaseXPosition(width);
        }
        this.columnTotalWidth = width;
    }

    protected getBlankPlotDefinition(absoluteRatio: number): PlotDefinition {
        return {
            absoluteRatio: absoluteRatio,
            chartPlotDefinitions: [],
            plottedCharts: 0,
        };
    }

    protected addChartDefinition(plotDefinition: PlotDefinition, chartType: ChartType, dataProperty: DataProperty, referenceProperty: DataProperty, plotIntegratedChart: boolean,
        allowChangingLabels: boolean) {
        let extreme = this.viewModel.extremes.getExtreme(dataProperty, this.settings);
        let range = extreme.range;
        let labelOffset = this.viewModel.extremes.getLabelOffset(dataProperty, this.viewModel);
        if (isRelativeMeasure(dataProperty, this.settings)) {
            range = this.viewModel.extremes.getRelativeRange(dataProperty, this.viewModel);
            let minLabelOffset = Math.max(extreme.getMinLabelOffset(this.settings), extreme.min < 0 ? 5 : 0);
            let maxlabelOffset = Math.max(extreme.getMaxLabelOffset(this.settings), extreme.max > 0 ? 5 : 0);
            labelOffset = minLabelOffset + maxlabelOffset;
        }
        let currentPlotOrder = this.settings.getColumnOrder(dataProperty);
        let plotOrder = currentPlotOrder === undefined ? plotDefinition.plottedCharts : currentPlotOrder;
        plotDefinition.plottedCharts++;
        plotDefinition.chartPlotDefinitions.push({
            chartType: this.settings.getDisplayType(dataProperty, chartType),
            dataProperty: dataProperty,
            referenceProperty: referenceProperty,
            plotOrder: plotOrder,
            plotIntegratedChart: plotIntegratedChart,
            allowChangingLables: allowChangingLabels,
            range: range,
            labelOffset: labelOffset,
            width: 0,
        });
    }

    protected plotValueChart(chartWidth: number, xPosition: number, groupIndex: number, hierarchyIndex: number, multiples: boolean, integratedChart: boolean,
        allowChangingLabels: boolean, differenceLabel: DifferenceLabel, dataProperty: DataProperty, referenceProperty: DataProperty, plotOrder: number) {
        this.settings.valueChartIntegrated = integratedChart;
        let chartGroup = this.viewModel.lastLevelChartGroups[groupIndex];
        let hierarchy = chartGroup.getAllChartHierarchies()[hierarchyIndex];
        if (!multiples && chartGroup.isRowGrandTotal && this.shouldntShowWaterfallGrandTotal(dataProperty, integratedChart)) { // CHECK: the plot table method calls are identical
            plotTable(chartWidth, xPosition, groupIndex, hierarchyIndex, this.chartId, dataProperty, plotOrder, multiples, this);
        }
        else if (hierarchy.isGrandTotal && this.shouldntShowWaterfallGrandTotal(dataProperty, integratedChart)) { // CHECK: the plot table method calls are identical
            plotTable(chartWidth, xPosition, groupIndex, hierarchyIndex, this.chartId, dataProperty, plotOrder, multiples, this);
        }
        else if (integratedChart) {
            if (dataProperty === DataProperty.Value) {
                this.valueChartIntegrated = true;
            }
            plotIntegratedChart(chartWidth, xPosition, groupIndex, hierarchyIndex, this.chartId, multiples, differenceLabel, allowChangingLabels, this, dataProperty, referenceProperty, plotOrder);
        }
        else {
            if (this.shoudPlotTable(dataProperty)) {
                plotTable(chartWidth, xPosition, groupIndex, hierarchyIndex, this.chartId, dataProperty, plotOrder, multiples, this);
            }
            if (this.shouldPlotStructureChart(dataProperty)) {
                plotStructureChart(chartWidth, xPosition, this.currentYPosition, groupIndex, hierarchyIndex, this.chartId, multiples, this, dataProperty, plotOrder);
            }
            else if (this.shouldPlotPinChart(dataProperty)) {
                plotPinChart(chartWidth, xPosition, groupIndex, hierarchyIndex, this.chartId, multiples, this, dataProperty, plotOrder);
            }
            else {
                plotWaterfallChart(chartWidth, xPosition, groupIndex, hierarchyIndex, this.chartId, multiples, dataProperty, plotOrder, this);
            }
        }
    }

    private shouldntShowWaterfallGrandTotal(dataProperty: DataProperty, integratedChart: boolean): boolean {
        if (helpers.isAdditionalMeasure(dataProperty) || integratedChart) {
            return true;
        }
        if (this.settings.valueChart !== ValueChart.Waterfall && this.settings.valueChart !== ValueChart.TwoWaterfalls && this.settings.valueChart !== ValueChart.CalculationWaterfall) {
            return true;
        }
        if (this.viewModel.numberOfGroupLevels === 0) {
            if (this.viewModel.hasHierarchy) {
                if (helpers.isValueMeasure(dataProperty)) {
                    return this.settings.valueChart !== ValueChart.CalculationWaterfall;
                }
                if (isAbsoluteDifferenceMeasure(dataProperty)) {
                    return this.settings.absoluteChart !== AbsoluteChart.CalculationWaterfall;
                }
            }
            return false;
        }
        if (!this.settings.plottingHorizontally()) {
            return true;
        }
        if (isAbsoluteDifferenceMeasure(dataProperty) && this.settings.absoluteChart === AbsoluteChart.CalculationWaterfall) {
            return false;
        }
        if (helpers.isValueMeasure(dataProperty) && this.settings.valueChart === ValueChart.CalculationWaterfall) {
            return false;
        }
        return this.viewModel.hasHierarchy;
    }

    private shoudPlotTable(dataProperty: DataProperty) {
        let showAsTable = this.settings.getDataPropertyShowAsTable(dataProperty);
        return showAsTable === ShowAsTableOptions.True;
    }

    private shouldPlotStructureChart(dataProperty: DataProperty): boolean {
        if (helpers.isAdditionalMeasure(dataProperty)) {
            return this.settings.getAdditionalMeasureValueChart(dataProperty) === ValueChart.Bar;
        }
        return this.settings.valueChart === ValueChart.Bar || this.settings.valueChart === ValueChart.OverlappedBar;
    }

    private shouldPlotPinChart(dataProperty: DataProperty): boolean {
        if (helpers.isAdditionalMeasure(dataProperty)) {
            return this.settings.getAdditionalMeasureValueChart(dataProperty) === ValueChart.Pin;
        }
        return this.settings.valueChart === ValueChart.Pin;
    }

    protected plotAbsoluteChart(chartWidth: number, xPosition: number, i: number, j: number, dataProperty: DataProperty, plotOrder: number, multiples: boolean) {
        if (this.shouldPlotPlusMinusChart(dataProperty)) {
            plotPlusMinusChart(chartWidth, xPosition, i, j, this.chartId, multiples, this, dataProperty, plotOrder);
        }
        else if (this.shouldPlotWaterfallChart()) {
            plotWaterfallChart(chartWidth, xPosition, i, j, this.chartId, multiples, dataProperty, plotOrder, this);
        }
    }

    private shouldPlotPlusMinusChart(dataProperty: DataProperty): boolean {
        if (helpers.isAdditionalMeasure(dataProperty)) {
            let absoluteChart = this.settings.getAdditionalMeasureAbsoluteChart(dataProperty);
            return absoluteChart === AbsoluteChart.Bar;
        }
        return this.settings.absoluteChart === AbsoluteChart.Bar;
    }

    private shouldPlotWaterfallValueChart(dataProperty: DataProperty): boolean {
        if (helpers.isAdditionalMeasure(dataProperty)) {
            let chart = this.settings.getAdditionalMeasureValueChart(dataProperty);
            return chart === ValueChart.Waterfall || chart === ValueChart.CalculationWaterfall;
        }
        return this.settings.valueChart === ValueChart.Waterfall || this.settings.valueChart === ValueChart.CalculationWaterfall;
    }

    private shouldPlotWaterfallChart(): boolean {
        return this.settings.absoluteChart === AbsoluteChart.Waterfall || this.settings.absoluteChart === AbsoluteChart.CalculationWaterfall;
    }

    private isWaterfallChartPlotted(): boolean {
        let settings = this.settings;
        return this.definitionToPlot.chartPlotDefinitions.some((definition: ChartPlotDefinition): boolean => {
            if (definition.chartType === ChartType.ValueChart &&
                (settings.valueChart === ValueChart.CalculationWaterfall || settings.valueChart === ValueChart.Waterfall)) {
                return true;
            }
            else if (definition.chartType === ChartType.AbsoluteChart &&
                (settings.absoluteChart === AbsoluteChart.CalculationWaterfall || settings.absoluteChart === AbsoluteChart.Waterfall)) {
                return true;
            }
            return false;
        });
    }

    public multipleChartPlotDefinitions(): boolean {
        return this.definitionToPlot.chartPlotDefinitions.length > 1;
    }

    public isShowingScrollbar(): boolean {
        return this.rightSideMargin === RIGHT_SCROLLBAR_MARGIN;
    }

    protected getChartType(dataProperty: DataProperty): ChartType {
        let cd = this.getChartDefinition(dataProperty);
        if (cd) {
            return cd.chartType;
        }
        return ChartType.Table;
    }

    private getChartDefinition(dataProperty: DataProperty) {
        if (!this.definitionToPlot) {
            return null;
        }
        let cds = this.definitionToPlot.chartPlotDefinitions.filter(cd => cd.dataProperty === dataProperty);
        if (cds.length === 1) {
            return cds[0];
        }
        return null;
    }

    public plottingRelativeChart(settings: VarianceSettings) {
        return this.definitionToPlot.chartPlotDefinitions.some(cd => {
            if (cd.dataProperty === DataProperty.RelativeDifference && !settings.shouldShowDataPropertyAsTable(DataProperty.RelativeDifference)) {
                return true;
            }
            if (cd.dataProperty === DataProperty.SecondRelativeDifference && !settings.shouldShowDataPropertyAsTable(DataProperty.SecondRelativeDifference)) {
                return true;
            }
            if (cd.dataProperty === DataProperty.ThirdRelativeDifference && !settings.shouldShowDataPropertyAsTable(DataProperty.ThirdRelativeDifference)) {
                return true;
            }
            if (cd.dataProperty === DataProperty.FourthRelativeDifference && !settings.shouldShowDataPropertyAsTable(DataProperty.FourthRelativeDifference)) {
                return true;
            }
            if (cd.dataProperty === DataProperty.FifthRelativeDifference && !settings.shouldShowDataPropertyAsTable(DataProperty.FifthRelativeDifference)) {
                return true;
            }
            if (cd.dataProperty === DataProperty.SixthRelativeDifference && !settings.shouldShowDataPropertyAsTable(DataProperty.SixthRelativeDifference)) {
                return true;
            }
            if (cd.dataProperty === DataProperty.SeventhRelativeDifference && !settings.shouldShowDataPropertyAsTable(DataProperty.SeventhRelativeDifference)) {
                return true;
            }
            return false;
        });
    }

    public isTableChartDefinition(dataProperty: DataProperty): boolean {
        return this.definitionToPlot.chartPlotDefinitions.some(d => d.dataProperty === dataProperty && d.chartType === ChartType.Table);
    }

    public addValueColumns(plotDefinition: PlotDefinition, chartType: ChartType) {
        if (this.viewModel.HasReferenceColumn) {
            if (this.viewModel.reference.scenario === Scenario.PreviousYear) {
                this.addChartDefinition(plotDefinition, chartType, DataProperty.ReferenceValue, DataProperty.ReferenceValue, false, false);
                this.addChartDefinition(plotDefinition, chartType, DataProperty.Value, DataProperty.ReferenceValue, false, false);
            }
            else {
                this.addChartDefinition(plotDefinition, chartType, DataProperty.Value, DataProperty.ReferenceValue, false, false);
                this.addChartDefinition(plotDefinition, chartType, DataProperty.ReferenceValue, DataProperty.ReferenceValue, false, false);
            }
        }
        else {
            this.addChartDefinition(plotDefinition, chartType, DataProperty.Value, DataProperty.ReferenceValue, false, false);
        }
        if (this.viewModel.HasSecondReferenceColumn) {
            this.addChartDefinition(plotDefinition, chartType, DataProperty.SecondReferenceValue, DataProperty.ReferenceValue, false, false);
        }
        if (this.viewModel.HasThirdReferenceColumn) {
            this.addChartDefinition(plotDefinition, chartType, DataProperty.ThirdReferenceValue, DataProperty.ReferenceValue, false, false);
        }
        if (this.viewModel.HasFourthReferenceColumn) {
            this.addChartDefinition(plotDefinition, chartType, DataProperty.FourthReferenceValue, DataProperty.ReferenceValue, false, false);
        }
        if (this.viewModel.HasFifthReferenceColumn) {
            this.addChartDefinition(plotDefinition, chartType, DataProperty.FifthReferenceValue, DataProperty.ReferenceValue, false, false);
        }
        if (this.viewModel.HasSixthReferenceColumn) {
            this.addChartDefinition(plotDefinition, chartType, DataProperty.SixthReferenceValue, DataProperty.ReferenceValue, false, false);
        }
        if (this.viewModel.HasSeventhReferenceColumn) {
            this.addChartDefinition(plotDefinition, chartType, DataProperty.SeventhReferenceValue, DataProperty.ReferenceValue, false, false);
        }
    }

    public addRelativeColumns(plotDefinition: PlotDefinition, chartType: ChartType) {
        if (this.viewModel.HasReferenceColumn) {
            this.addChartDefinition(plotDefinition, chartType, DataProperty.RelativeDifference, DataProperty.ReferenceValue, false, false);
        }
        if (this.viewModel.HasSecondReferenceColumn) {
            this.addChartDefinition(plotDefinition, chartType, DataProperty.SecondRelativeDifference, DataProperty.ReferenceValue, false, false);
        }
        if (this.viewModel.HasThirdReferenceColumn) {
            this.addChartDefinition(plotDefinition, chartType, DataProperty.ThirdRelativeDifference, DataProperty.ReferenceValue, false, false);
        }
        if (this.viewModel.HasFourthReferenceColumn) {
            this.addChartDefinition(plotDefinition, chartType, DataProperty.FourthRelativeDifference, DataProperty.ReferenceValue, false, false);
        }
        if (this.viewModel.HasFifthReferenceColumn) {
            this.addChartDefinition(plotDefinition, chartType, DataProperty.FifthRelativeDifference, DataProperty.ReferenceValue, false, false);
        }
        if (this.viewModel.HasSixthReferenceColumn) {
            this.addChartDefinition(plotDefinition, chartType, DataProperty.SixthRelativeDifference, DataProperty.ReferenceValue, false, false);
        }
        if (this.viewModel.HasSeventhReferenceColumn) {
            this.addChartDefinition(plotDefinition, chartType, DataProperty.SeventhRelativeDifference, DataProperty.ReferenceValue, false, false);
        }
    }

    public addAbsoluteDifferenceColumns(plotDefinition: PlotDefinition, chartType: ChartType) {
        if (this.viewModel.HasReferenceColumn) {
            this.addChartDefinition(plotDefinition, chartType, DataProperty.AbsoluteDifference, DataProperty.ReferenceValue, false, false);
        }
        if (this.viewModel.HasSecondReferenceColumn) {
            this.addChartDefinition(plotDefinition, chartType, DataProperty.SecondAbsoluteDifference, DataProperty.ReferenceValue, false, false);
        }
        if (this.viewModel.HasThirdReferenceColumn) {
            this.addChartDefinition(plotDefinition, chartType, DataProperty.ThirdAbsoluteDifference, DataProperty.ReferenceValue, false, false);
        }
        if (this.viewModel.HasFourthReferenceColumn) {
            this.addChartDefinition(plotDefinition, chartType, DataProperty.FourthAbsoluteDifference, DataProperty.ReferenceValue, false, false);
        }
        if (this.viewModel.HasFifthReferenceColumn) {
            this.addChartDefinition(plotDefinition, chartType, DataProperty.FifthAbsoluteDifference, DataProperty.ReferenceValue, false, false);
        }
        if (this.viewModel.HasSixthReferenceColumn) {
            this.addChartDefinition(plotDefinition, chartType, DataProperty.SixthAbsoluteDifference, DataProperty.ReferenceValue, false, false);
        }
        if (this.viewModel.HasSeventhReferenceColumn) {
            this.addChartDefinition(plotDefinition, chartType, DataProperty.SeventhAbsoluteDifference, DataProperty.ReferenceValue, false, false);
        }
    }

    private setUsedScaleGroups() {
        this.absoluteScaleGroups = [];
        this.relativeScaleGroups = [];
        for (let index = 0; index < this.definitionToPlot.chartPlotDefinitions.length; index++) {
            let dp = this.definitionToPlot.chartPlotDefinitions[index].dataProperty;
            let scaleGroup = this.settings.getScaleGroup(dp);
            if (isRelativeMeasure(dp, this.settings)) {
                this.relativeScaleGroups.push(scaleGroup)
            }
            else {
                this.absoluteScaleGroups.push(scaleGroup);
            }
        }
    }

    // For some UI elements we draw, we need to take into account the left & up position of tables container (caused by the commentBox, when it is placed on left or above)
    public getCommentBoxOffsetLeft() {
        if (this.settings.showCommentBox) {
            return (<SVGElement>d3.select(`.${ZEBRABI_TABLE_CONTAINER}`).node()).getBoundingClientRect().left;
        }
        return 0;
    }

    public getCommentBoxOffsetTop() {
        if (this.settings.showCommentBox) {
            return (<SVGElement>d3.select(`.${ZEBRABI_TABLE_CONTAINER}`).node()).getBoundingClientRect().top;
        }
        return 0;
    }
}
