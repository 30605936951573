import { DataViewMetadataColumn } from "@zebrabi/matrix-data";

import { ScenarioOptions, IHierarchical, ColumnOptions } from "./../definitions";
import { ColumnFormat, EMPTY } from "./../library/constants";

export function isPercentageDataMatrixDataView(dvMetaDataColumn: DataViewMetadataColumn[]): boolean {
    let format = dvMetaDataColumn && dvMetaDataColumn.length > 0 ? dvMetaDataColumn[0].format : null;
    return format && format.indexOf("%") !== -1;
}

export function isPercentageDataMatrixValueColumns(dvMetaDataColumn: DataViewMetadataColumn[], index: number): boolean {
    let format = dvMetaDataColumn && dvMetaDataColumn.length > index ? dvMetaDataColumn[index].format : null;
    return format && format.indexOf("%") !== -1;
}

export function getDefaultColumnFormat(dvMetaDataColumn: DataViewMetadataColumn[], scenarioOptions: ScenarioOptions, index: number): ColumnFormat {
    let columnFormat = ColumnFormat.Value;
    if (scenarioOptions.additionalMeasures && scenarioOptions.additionalMeasures.length > index) {
        if (isPercentageDataMatrixValueColumns(dvMetaDataColumn, scenarioOptions.additionalMeasures[index].index)) {
            return ColumnFormat.Percent;
        }
    }
    return columnFormat;
}

export function getColumnPercentageFormat(dvMetaDataColumn: DataViewMetadataColumn[], scenarioOptions: ScenarioOptions, index: number): string {
    if (scenarioOptions.additionalMeasures && scenarioOptions.additionalMeasures.length > index) {
        return dvMetaDataColumn[scenarioOptions.additionalMeasures[index].index].format;
    }
    return EMPTY;
}

export function getTooltipsMeasureFormat(dvMetaDataColumn: DataViewMetadataColumn[], tooltips: ColumnOptions[], index: number): string {
    if (tooltips && tooltips.length > index) {
        return dvMetaDataColumn[tooltips[index].index].format;
    }
    return EMPTY;
}

export function isTooltipsMeasureTypeDate(dvMetaDataColumn: DataViewMetadataColumn[], tooltips: ColumnOptions[], index: number): boolean {
    if (tooltips && tooltips.length > index) {
        return dvMetaDataColumn[tooltips[index].index].type.dateTime;
    }
    return false;
}

// export function getSelectionId(child: DataViewMatrixNode, hierarchyLevels: DataViewHierarchyLevel[], parents: IHierarchical[], host: IVisualHost, queryName?: string): ISelectionId {
//     let idSelectionBuilder = host.createSelectionIdBuilder();
//     let startingIndex = hierarchyLevels.length === 1 ? 1 : 0;
//     idSelectionBuilder = idSelectionBuilder.withMatrixNode(child, hierarchyLevels);
//     for (let i = startingIndex; i < parents.length; i++) {
//         let parent = parents[i];
//         if (parent.node && parent.node.identity && parent.hierarchyLevels) {
//             idSelectionBuilder = idSelectionBuilder.withMatrixNode(parents[i].node, parents[i].hierarchyLevels);
//         }
//     }
//     if (queryName) {
//         idSelectionBuilder = idSelectionBuilder.withMeasure(queryName);
//     }
//     return idSelectionBuilder.createSelectionId();
// }

export function getNumberOfFields(valueSources: DataViewMetadataColumn[], field: "Values" | "Tooltips" | "Comments" | "Plan" | "Forecast"): number {
    const filteredValueSources = valueSources?.filter(o => o.roles[field]);
    return filteredValueSources?.length ?? 0;
}