import { Plotter } from "../plotters/plotter";
import { ChartType, NegativeValuesFormat } from "../../definitions";
import { Extreme } from "../extreme";
import { DataPoint } from "../dataPoint";
import { ViewModel } from "../../settings/viewModel";
import {
    DataProperty, RECT, HIGHLIGHTABLE, LABEL, CHART_AREA, LogType, BAR, TOOLTIP, FONT_SIZE, FONT_FAMILY, TEXT_ANCHOR, END, START, FONT_WEIGHT, RELATIVE_SORT, SECOND_RELATIVE_SORT, PATH, TRANSFORM, FILL, MARKER, CIRCLE, BACKGROUND, FONT_STYLE, ITALIC, X, Y, WIDTH, HEIGHT, WHITE, FILL_OPACITY, DATA_SCALE_GROUP, DATA_PROPERTY, D, R, CX, CY, FORMULA_ELEMENT, FONT_SIZE_UNIT, CHART_ID, BOLD
} from "../../library/constants";
import {
    getOrdinalScale, getLeftSideGridlinesEnd, getRightSideGrindlinesStart, plotGridlines, getTextAnchor, plotSortableHeader, addBlurIfNeeded,
    drawRelativeIcons, getMarkerColor, getFormattedSuppressedRelativeDataLabel, getCommentMarkerAttributes, addCommentMarkers
} from "./chart";
import * as drawing from "./../../library/drawing";
import * as styles from "./../../library/styles";
import * as debug from "./../../library/debug";
import * as scenarios from "./../../settings/scenarios";
import * as d3 from "d3";
import { isAdditionalMeasure } from "../../library/helpers";
import { isRelativeMeasure } from "../../helpers";
import { VarianceSettings } from "../../settings/varianceSettings";

// tslint:disable-next-line: max-func-body-length 
export function plotPlusMinusDotChart(width: number, xPosition: number, groupIndex: number, chartHierarchyIndex: number,
    chartId: number, multiples: boolean, plotter: Plotter, dataProperty: DataProperty, plotOrder: number) {
    let { viewModel, settings, plotArea, svg, groupHeaderHeight, currentRow, currentYPosition: currentHeight, dataPropertyForComments } = plotter;
    let totalWidth = plotter.width;
    let height = plotter.getCurrentChartHeight();
    let chartGroup = viewModel.lastLevelChartGroups[groupIndex];
    let extreme: Extreme = null;
    if (settings.plottingHorizontally()) {
        extreme = chartGroup.extremes.getExtreme(dataProperty, settings);
    }
    else {
        extreme = viewModel.extremes.getExtreme(dataProperty, settings);
    }
    let chartHierarchy = viewModel.getChartHierarchy(groupIndex, chartHierarchyIndex);
    // We add some label offset for the markers even if the labels are not shown
    let minLabelOffset = Math.max(extreme.getMinLabelOffset(settings), extreme.min < 0 ? 5 : 0);
    let maxLabelOffset = Math.max(extreme.getMaxLabelOffset(settings), extreme.max > 0 ? 5 : 0);

    let start = xPosition + minLabelOffset;
    let end = Math.max(start, xPosition + width - maxLabelOffset);
    let minValue = Math.max(extreme.min, isNaN(viewModel.minOutlierValue) ? 0 : viewModel.minOutlierValue);
    let maxValue = Math.min(extreme.max, isNaN(viewModel.maxOutlierValue) ? 0 : viewModel.maxOutlierValue);

    let xScale = drawing.getLinearScale(minValue, maxValue, start, end);
    let yScale = getOrdinalScale(chartHierarchy.dataPoints(), currentHeight, currentHeight + height, viewModel, settings.getGapBetweenColumns());
    let plottableDataPoints = plotter.getDataPointsToPlotChartElements(chartHierarchy, yScale, dataProperty, true);
    let gridlinePlottableDataPoints = plotter.getDataPointsToPlotChartElements(chartHierarchy, yScale, dataProperty, false);
    plotter.currentlyShowingDataPoints = plottableDataPoints.inView;
    let chartArea = drawing.createGroupElement(plotArea, `${CHART_AREA}_${groupIndex}_${chartHierarchyIndex}_${chartId} ${CHART_AREA}`);
    let scaleGroup = settings.getScaleGroup(dataProperty);
    chartArea.attr(DATA_SCALE_GROUP, scaleGroup);
    chartArea.attr(DATA_PROPERTY, dataProperty);
    chartArea.attr(CHART_ID, chartId);
    let axisPosition = xScale(0);

    if (debug.shouldLog(LogType.DisplayBounds)) {
        debug.drawBoundingBox(chartArea, xPosition, width, currentHeight, height, chartId);
    }
    let header = settings.getHeader(dataProperty);
    let isHeaderBold = settings.isHeaderBold(dataProperty) || settings.shouldUseBoldGroupHeaders(chartGroup, viewModel.maximumExpandedGroupLevel);
    let sort = dataProperty === DataProperty.RelativeDifference ? RELATIVE_SORT : SECOND_RELATIVE_SORT;
    let leftGridlineEndFunction = getLeftSideGridlinesEnd(xScale, sort, axisPosition);
    let rightGridlineStartFunction = getRightSideGrindlinesStart(xScale, sort, axisPosition, xPosition);
    plotGridlines(yScale, xPosition, width, axisPosition, settings, viewModel, gridlinePlottableDataPoints.inView, chartArea, chartId, leftGridlineEndFunction, rightGridlineStartFunction, groupIndex, chartHierarchyIndex);
    if (settings.shouldPlotVerticalAxis(chartHierarchy)) {
        let semanticAxis = !isAdditionalMeasure(dataProperty);
        drawing.plotVerticalAxis(chartArea, chartId, semanticAxis, axisPosition, currentHeight, height, settings.colorScheme.axisColor, settings.getReferenceScenarioForDataProperty(dataProperty), settings.showTotals, yScale, viewModel.hasHierarchy, chartHierarchy.isGrandTotal, settings.getGapBetweenColumns());
    }
    let headerXPosition = Math.round(axisPosition);
    let anchor = getTextAnchor(extreme.min, extreme.max);
    if (multiples) {
        let yPosition = chartGroup.level * groupHeaderHeight;
        if (chartGroup.isSubtotal) {
            yPosition = viewModel.maximumExpandedGroupLevel * groupHeaderHeight;
        }
        if (settings.shouldShowScenarioSortHeaders(plotter)) {
            let sortableHeader = plotSortableHeader(chartHierarchy, plotter, viewModel, totalWidth, header, currentRow, groupIndex, xPosition, yPosition + groupHeaderHeight + 3, anchor, isHeaderBold, dataProperty, plotOrder, true, width, headerXPosition, false, true, true, true, false, groupIndex, false, chartGroup, false, null, false, chartId);
            sortableHeader.setChartChangeIcons(drawRelativeIcons, ChartType.PlusMinusDot);
        }
    }
    else {
        let sortableHeader = plotSortableHeader(chartHierarchy, plotter, viewModel, totalWidth, header, currentRow, null, xPosition, 3, anchor, isHeaderBold, dataProperty, plotOrder, true, width, headerXPosition, false, true, true, true, false, groupIndex, false, chartGroup, false, null, false, chartId);
        sortableHeader.setChartChangeIcons(drawRelativeIcons, ChartType.PlusMinusDot);
    }
    let dataPoints = plottableDataPoints.inViewWithoutFormulas.filter(d => d.getValue(dataProperty) != null);
    let outlierDataPoints = dataPoints.filter(d => d.isOutlier(viewModel, dataProperty));
    let nonOutlierDataPoints = dataPoints.filter(d => !d.isOutlier(viewModel, dataProperty));
    let symbols = chartArea.selectAll(".symbol" + chartId)
        .data(outlierDataPoints);
    let path = symbols
        .enter().append(PATH);
    symbols = symbols.merge(path);
    symbols
        .attr(D, d3.symbol().type(d3.symbolTriangle).size(50))
        .attr(TRANSFORM, d => getTriangleAttributes(d, xScale, yScale, viewModel, dataProperty))
        .style(FILL, d => styles.getRelativeVarianceColor(settings.invert, d.isInverted, d.hasInvertedGroupAncestor(), settings.getColumnInvert(dataProperty), d.getValue(dataProperty), settings.colorScheme))
        .classed(HIGHLIGHTABLE, true)
        .classed("symbol" + chartId, true);
    symbols.exit().remove();
    addBlurIfNeeded(symbols, settings);

    if (settings.isDrHichertStyle) {
        let rectangleSize = 8;
        let rectangles = drawing.getShapes(chartArea, `.${BAR}${MARKER}${chartId}`, `${BAR}${MARKER}${chartId} ${HIGHLIGHTABLE} ${TOOLTIP}`, RECT, nonOutlierDataPoints);
        rectangles
            .attr(WIDTH, rectangleSize)
            .attr(HEIGHT, rectangleSize)
            .attr(X, d => (d.isNegative(dataProperty) ? xScale(Math.max(viewModel.minOutlierValue, d.getValue(dataProperty))) : xScale(Math.min(viewModel.maxOutlierValue, d.getValue(dataProperty)))) - rectangleSize / 2)
            .attr(Y, d => Math.round(yScale(d.getCategory(viewModel)) + yScale.bandwidth() / 2) - rectangleSize / 2);
        styles.applyToLines(rectangles, getMarkerColor(settings), scenarios.getVarianceScenario(dataProperty, viewModel), settings.chartStyle, false, settings.colorScheme, false);
        // addMouseHandlers(rectangles, selectionManager, svg, viewModel);
        addBlurIfNeeded(rectangles, settings);
        // plotter.addTooltips(rectangles);
    }
    else {
        let circles = drawing.getShapes(chartArea, `.${CIRCLE}${MARKER}${chartId}`, `${CIRCLE}${MARKER}${chartId} ${HIGHLIGHTABLE} ${TOOLTIP}`, CIRCLE, nonOutlierDataPoints);
        circles
            .attr(R, 4)
            .attr(CX, d => d.isNegative(d.getValue(dataProperty)) ? xScale(Math.max(viewModel.minOutlierValue, d.getValue(dataProperty))) : xScale(Math.min(viewModel.maxOutlierValue, d.getValue(dataProperty))))
            .attr(CY, d => Math.round(yScale(d.getCategory(viewModel)) + yScale.bandwidth() / 2));
        styles.applyToLines(circles, getMarkerColor(settings), scenarios.getVarianceScenario(dataProperty, viewModel), settings.chartStyle, false, settings.colorScheme, false);
        // addMouseHandlers(circles, selectionManager, svg, viewModel);
        addBlurIfNeeded(circles, settings);
        // plotter.addTooltips(circles);
    }
    let outlierMargin = 10;
    let negativeOutlierWidth = axisPosition - xPosition - outlierMargin;
    let positiveOutlierWidth = xPosition + width - axisPosition - outlierMargin;
    let bars = drawing.getShapes(chartArea, `.${BAR}${chartId}`, `${BAR}${chartId} ${HIGHLIGHTABLE} ${TOOLTIP}`, RECT, dataPoints);
    bars
        .attr(HEIGHT, 3)
        .attr(WIDTH, (d) => {
            if (d.isNegative(dataProperty)) {
                if (d.isOutlier(viewModel, dataProperty)) {
                    return negativeOutlierWidth;
                }
                else {
                    return Math.abs(axisPosition - xScale(d.getValue(dataProperty)));
                }
            }
            else {
                if (d.isOutlier(viewModel, dataProperty)) {
                    return positiveOutlierWidth;
                }
                else {
                    return Math.abs(axisPosition - xScale(d.getValue(dataProperty)));
                }
            }
        })
        .attr(X, (d) => {
            if (d.isNegative(dataProperty)) {
                if (d.isOutlier(viewModel, dataProperty)) {
                    return xPosition + outlierMargin;
                }
                else {
                    return xScale(d.getValue(dataProperty));
                }
            }
            else {
                return axisPosition;
            }
        })
        .attr(Y, d => Math.round(yScale(d.getCategory(viewModel)) + yScale.bandwidth() / 2) - 1)
        .attr(FILL, (d: DataPoint) => styles.getRelativeVarianceColor(settings.invert, d.isInverted, d.hasInvertedGroupAncestor(), settings.getColumnInvert(dataProperty), d.getValue(dataProperty), settings.colorScheme));
    // addMouseHandlers(bars, selectionManager, svg, viewModel);
    addBlurIfNeeded(bars, settings);
    // plotter.addTooltips(bars);
    let labelDataPoints = plottableDataPoints.inView.filter(d => d.getValue(dataProperty));
    if (!settings.showDataLabels) {
        outlierDataPoints = [];
        labelDataPoints = [];
    }
    chartArea.selectAll(`.${LABEL}${chartId}`).remove();
    let backgrounds = chartArea
        .selectAll(`.${BACKGROUND}${chartId}`)
        .data(outlierDataPoints);
    if (outlierDataPoints.length > 0) {
        let rect = backgrounds
            .enter().append(RECT)
            .classed(`${BACKGROUND}${chartId}`, true);
        backgrounds = backgrounds.merge(rect);
    }
    backgrounds.exit().remove();
    let labels = drawing.getLabels(chartArea, `${LABEL}${chartId}`, labelDataPoints);
    labels
        .text((d: DataPoint) => {
            if (isRelativeMeasure(dataProperty, settings)) {
                if (settings.suppressLargeRelativeVariance) {
                    if (Math.abs(d.getValue(dataProperty)) > (settings.suppressLargeRelativeVarianceValue)) {
                        return getFormattedSuppressedRelativeDataLabel(d.getValue(dataProperty), settings.suppressLargeRelativeVarianceValue, 0, viewModel.locale, settings.negativeValuesFormat === NegativeValuesFormat.Parenthesis, settings.shouldHideDataLabelUnits(), settings.showPercentageInLabel, settings.getPercentageFormat(dataProperty), isAdditionalMeasure(dataProperty), true)
                    }
                }
            }
            return d.getLabel(dataProperty)
        })
        .style(FONT_SIZE, `${settings.labelFontSize}${FONT_SIZE_UNIT}`)
        .style(FONT_STYLE, ITALIC)
        .style(FONT_FAMILY, settings.labelFontFamily)
        .style(TEXT_ANCHOR, d => getDotLabelTextAnchor(d, viewModel, dataProperty))
        .style(FONT_WEIGHT, (d: DataPoint) => isHeaderBold ? BOLD : d.getFontWeight())
        .attr(X, d => getDotLabelXPosition(d, xScale, viewModel, dataProperty))
        .attr(Y, d => drawing.centerTextVertically(yScale, d.getCategory(viewModel), settings.labelFontSize))
        .attr(FILL, (d: DataPoint) => d.getLabelColor(settings.labelFontColor, dataProperty))
        .classed(FORMULA_ELEMENT, (d: DataPoint) => d.isCurrentlyEditedFormula())
        .classed(HIGHLIGHTABLE, true)
        .classed(TOOLTIP, true);
    // addMouseHandlers(labels, selectionManager, svg, viewModel);
    addBlurIfNeeded(labels, settings);
    // plotter.addTooltips(labels);

    if (viewModel.HasCommentsColumn) {
        let commentDataPoints: DataPoint[] = [];
        // comment markers with default data property (not being dragged to another data property):
        if (dataProperty === dataPropertyForComments) {
            commentDataPoints.push(...plottableDataPoints.inViewWithoutFormulas.filter(d => d.hasComment() && d.commentMarkerDataProperty === null));
        }
        commentDataPoints.push(...plottableDataPoints.inViewWithoutFormulas.filter(d => d.hasComment()).filter(d => d.commentMarkerDataProperty === dataProperty));
        plotCommentMarkers(chartArea, commentDataPoints, xScale, yScale, settings, dataProperty, viewModel, plotter.categoriesWidth, groupIndex);
        //plotter.addCommentMarkerTooltip(chartArea);
    }

    if (outlierDataPoints.length > 0) {
        let boundingBoxes = [];
        labels.each(function (d) {
            if (d.isOutlier(viewModel, dataProperty)) {
                boundingBoxes.push((<SVGGraphicsElement>this).getBBox());
            }
        });
        let paddingLeftRight = 4;
        backgrounds
            .attr(X, (d, i) => { return boundingBoxes[i].x - paddingLeftRight / 2; })
            .attr(Y, (d, i) => { return boundingBoxes[i].y + boundingBoxes[i].height / 2 - 4; })
            .attr(WIDTH, (d, i) => { return boundingBoxes[i].width + paddingLeftRight; })
            .attr(HEIGHT, 8)
            .attr(FILL, WHITE)
            .attr(FILL_OPACITY, 1 - (settings.labelBackgroundTransparency / 100));

    }
}

function plotCommentMarkers(container: d3.Selection<SVGElement, any, any, any>, commentDataPoints: DataPoint[], xScale: d3.ScaleLinear<number, number>, yScale: d3.ScaleBand<string>,
    settings: VarianceSettings, dataProperty: DataProperty, viewModel: ViewModel, categoriesWidth: number, groupIndex: number) {
    let scaleBandWidth = yScale.bandwidth();
    let markerAttrs = getCommentMarkerAttributes(scaleBandWidth);
    let getMarkerYPosition = (d: DataPoint): number => yScale(d.getCategory(viewModel)) + scaleBandWidth / 2;
    let getMarkerXPosition = (d: DataPoint): number => xScale(0) + (d.isNegative(dataProperty) ? 1 : -1) * (markerAttrs.radius + markerAttrs.margin);
    addCommentMarkers(container, commentDataPoints, getMarkerXPosition, getMarkerYPosition, markerAttrs.radius, markerAttrs.fontSize, settings.colorScheme.highlightColor, settings.labelFontFamily, viewModel, categoriesWidth, groupIndex);
}

function getTriangleAttributes(d: DataPoint, xScale: d3.ScaleLinear<number, number>, yScale: d3.ScaleBand<string>, viewModel: ViewModel, dataProperty: DataProperty) {
    let x = d.isNegative(dataProperty) ?
        xScale(Math.max(viewModel.minOutlierValue, d.getValue(dataProperty))) - (d.isOutlier(viewModel, dataProperty) ? viewModel.extremes.getExtreme(dataProperty, viewModel.settings).getMinLabelOffset(viewModel.settings) : 0) + 5 :
        xScale(Math.min(viewModel.maxOutlierValue, d.getValue(dataProperty))) + (d.isOutlier(viewModel, dataProperty) ? viewModel.extremes.getExtreme(dataProperty, viewModel.settings).getMaxLabelOffset(viewModel.settings) : 0) - 5;
    let y = Math.round(yScale(d.getCategory(viewModel)) + yScale.bandwidth() / 2);
    return `translate(${x},${y}) rotate(${(d.isNegative(dataProperty) ? "270" : "90")})`;
}

function getDotLabelXPosition(d: DataPoint, xScale: d3.ScaleLinear<number, number>, viewModel: ViewModel, dataProperty: DataProperty): number {
    if (d.isRowDisplayUnitPercent()) {
        return xScale(0) + 5;
    }
    if (d.isOutlier(viewModel, dataProperty)) {
        if (d.isNegative(dataProperty)) {
            return xScale(viewModel.minOutlierValue) + 15 - (d.isOutlier(viewModel, dataProperty) ? viewModel.extremes.getExtreme(dataProperty, viewModel.settings).minLabelOffset : 0);
        }
        else {
            return xScale(viewModel.maxOutlierValue) - 15 + (d.isOutlier(viewModel, dataProperty) ? viewModel.extremes.getExtreme(dataProperty, viewModel.settings).maxLabelOffset : 0);
        }
    }
    else if (d.isNegative(dataProperty)) {
        return xScale(Math.max(viewModel.minOutlierValue, d.getValue(dataProperty))) - 10;
    }
    else {
        return xScale(Math.min(viewModel.maxOutlierValue, d.getValue(dataProperty))) + 10;
    }
}

function getDotLabelTextAnchor(d: DataPoint, viewModel: ViewModel, dataProperty: DataProperty): string {
    if (d.isRowDisplayUnitPercent()) {
        return START;
    }
    if (d.isOutlier(viewModel, dataProperty)) {
        if (d.isNegative(dataProperty)) {
            return START;
        }
        else {
            return END;
        }
    }
    else if (d.isNegative(dataProperty)) {
        return END;
    }
    else {
        return START;
    }
}