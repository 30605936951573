
import { ViewModel } from "../../settings/viewModel";
import { Plotter } from "./plotter";
import { CategoriesPlotter } from "./categoriesPlotter";
import { ABSOLUTE, RELATIVE, ABSOLUTE_RELATIVE, ACTUAL, ACTUAL_ABSOLUTE, ACTUAL_RELATIVE, INTEGRATED, ACT_ABS_REL } from "../../definitions";
import { AbsoluteTablePlotter } from "./absoluteTablePlotter";
import { AbsolutePlotter } from "./absolutePlotter";
import { RelativeTablePlotter } from "./relativeTablePlotter";
import { RelativePlotter } from "./relativePlotter";
import { AbsoluteRelativeTablePlotter } from "./absoluteRelativeTablePlotter";
import { HorizontalAbsoluteRelativePlotter } from "./horizontalAbsoluteRelativePlotter";
import { VerticalAbsoluteRelativePlotter } from "./verticalAbsoluteRelativePlotter";
import { ActualTablePlotter } from "./actualTablePlotter";
import { HorizontalActualPlotter } from "./horizontalActualPlotter";
import { VerticalActualPlotter } from "./verticalActualPlotter";
import { ActualAbsoluteTablePlotter } from "./actualAbsoluteTablePlotter";
import { HorizontalActualAbsolutePlotter } from "./horizontalActualAbsolutePlotter";
import { VerticalActualAbsolutePlotter } from "./verticalActualAbsolutePlotter";
import { ActualRelativeTablePlotter } from "./actualRelativeTablePlotter";
import { HorizontalActualRelativePlotter } from "./horizontalActualRelativePlotter";
import { VerticalActualRelativePlotter } from "./verticalActualRelativePlotter";
import { HorizontalIntegratedPlotter } from "./horizontalIntegratedPlotter";
import { VerticalIntegratedPlotter } from "./verticalIntegratedPlotter";
import { TablePlotter } from "./tablePlotter";
import { HorizontalFullScenarioPlotter } from "./horizontalFullScenarioPlotter";
import { HorizontalFullPlotter } from "./horizontalFullPlotter";
import { ResponsiveScenarioPlotter } from "./responsiveScenarioPlotter";
import { ResponsivePlotter } from "./responsivePlotter";

export function plotterFactory(chartType: string, viewModel: ViewModel, width: number, height: number, reportArea: d3.Selection<SVGElement, any, any, any>, svg: d3.Selection<SVGElement, any, any, any>,
    locale: string): Plotter {
    if (viewModel.plotOnlyCategories) {
        return new CategoriesPlotter(viewModel, width, height, reportArea, svg, locale);
    }
    switch (chartType) {
        case ABSOLUTE:
            if (viewModel.settings.showAsTable) {
                return new AbsoluteTablePlotter(viewModel, width, height, reportArea, svg, locale);
            }
            else {
                return new AbsolutePlotter(viewModel, width, height, reportArea, svg, locale);
            }
        case RELATIVE:
            if (viewModel.settings.showAsTable) {
                return new RelativeTablePlotter(viewModel, width, height, reportArea, svg, locale);
            }
            else {
                return new RelativePlotter(viewModel, width, height, reportArea, svg, locale);
            }
        case ABSOLUTE_RELATIVE:
            if (viewModel.settings.showAsTable) {
                return new AbsoluteRelativeTablePlotter(viewModel, width, height, reportArea, svg, locale);
            }
            else if (viewModel.settings.groupsInColumns) {
                return new HorizontalAbsoluteRelativePlotter(viewModel, width, height, reportArea, svg, locale);
            }
            else {
                return new VerticalAbsoluteRelativePlotter(viewModel, width, height, reportArea, svg, locale);
            }
        case ACTUAL:
            if (viewModel.settings.showAsTable) {
                return new ActualTablePlotter(viewModel, width, height, reportArea, svg, locale);
            }
            else if (viewModel.settings.groupsInColumns) {
                return new HorizontalActualPlotter(viewModel, width, height, reportArea, svg, locale);
            }
            else {
                return new VerticalActualPlotter(viewModel, width, height, reportArea, svg, locale);
            }
        case ACTUAL_ABSOLUTE:
            if (viewModel.settings.showAsTable) {
                return new ActualAbsoluteTablePlotter(viewModel, width, height, reportArea, svg, locale);
            }
            else if (viewModel.settings.groupsInColumns) {
                return new HorizontalActualAbsolutePlotter(viewModel, width, height, reportArea, svg, locale);
            }
            else {
                return new VerticalActualAbsolutePlotter(viewModel, width, height, reportArea, svg, locale);
            }
        case ACTUAL_RELATIVE:
            if (viewModel.settings.showAsTable) {
                return new ActualRelativeTablePlotter(viewModel, width, height, reportArea, svg, locale);
            }
            else if (viewModel.settings.groupsInColumns) {
                return new HorizontalActualRelativePlotter(viewModel, width, height, reportArea, svg, locale);
            }
            else {
                return new VerticalActualRelativePlotter(viewModel, width, height, reportArea, svg, locale);
            }
        case INTEGRATED:
            if (viewModel.settings.showAsTable) {
                return new ActualAbsoluteTablePlotter(viewModel, width, height, reportArea, svg, locale);
            }
            else if (viewModel.settings.groupsInColumns) {
                return new HorizontalIntegratedPlotter(viewModel, width, height, reportArea, svg, locale);
            }
            else {
                return new VerticalIntegratedPlotter(viewModel, width, height, reportArea, svg, locale);
            }
        case ACT_ABS_REL:
            if (viewModel.settings.showAsTable) {
                return new TablePlotter(viewModel, width, height, reportArea, svg, locale);
            }
            else if (viewModel.settings.groupsInColumns) {
                if (viewModel.HasSecondReferenceColumn) {
                    return new HorizontalFullScenarioPlotter(viewModel, width, height, reportArea, svg, locale);
                }
                else {
                    return new HorizontalFullPlotter(viewModel, width, height, reportArea, svg, locale);
                }
            }
            else if (viewModel.HasSecondReferenceColumn) {
                return new ResponsiveScenarioPlotter(viewModel, width, height, reportArea, svg, locale);
            }
            else {
                return new ResponsivePlotter(viewModel, width, height, reportArea, svg, locale);
            }
    }
}