import { Plotter } from "../plotters/plotter";
import { ViewModel } from "../../settings/viewModel";
import { ABSOLUTE, GRIDLINE_OFFSET, ValueChart, ChartType, AbsoluteChart } from "../../definitions";
import {
    DataProperty, RECT, HIGHLIGHTABLE, LABEL, STROKE_WIDTH, CHART_AREA, BOLD,
    LogType, BAR, TOOLTIP, FONT_SIZE, FONT_FAMILY, TEXT_ANCHOR, END, START, STROKE, GRAY, EMPTY, FONT_WEIGHT, CONNECTING, LINE, GRAND_TOTAL, BACKGROUND, HEIGHT, WIDTH, FILL, FILL_OPACITY, X, Y, WHITE, DATA_SCALE_GROUP, DATA_PROPERTY, X1, X2, Y1, Y2, SUBTOTAL, STROKE_DASHARRAY, INITIAL, ITALIC, FONT_STYLE, FORMULA_ELEMENT, DisplayUnits, FONT_SIZE_UNIT, CHART_ID
} from "../../library/constants";
import { DataPoint } from "../dataPoint";
import { ChartHierarchy } from "../chartHierarchy";
import { Extreme } from "../extreme";
import { Extremes } from "../extremes";
import { Visual } from "../../visual";
import { ChartGroup } from "../chartGroup";
import { VarianceSettings } from "../../settings/varianceSettings";
import {
    getOrdinalScale, plotGridlines, plotSortableHeader, addBlurIfNeeded, getVarianceColor, getTextAnchorForWaterfallChart, plottingAbsoluteChart,
    drawValueIcons, drawAbsoluteIcons, plottingValueChart, getNeutralColor, getCommentMarkerAttributes, addCommentMarkers, addMouseHandlers
} from "./chart";
import * as drawing from "./../../library/drawing";
import * as styles from "./../../library/styles";
import * as scenarios from "./../../settings/scenarios";
import * as debug from "./../../library/debug";
import * as d3 from "d3";
import { isAdditionalMeasure, isValueMeasure } from "../../library/helpers";
import { drawLine } from "./../../library/drawing";
import { isAbsoluteDifferenceMeasure } from "../../helpers";
import { CommentMarker } from "@zebrabi/legacy-library-common/interfaces";

// tslint:disable-next-line: max-func-body-length 
export function plotWaterfallChart(width: number, xPosition: number, groupIndex: number, chartHierarchyIndex: number, chartId: number,
    multiples: boolean, dataProperty: DataProperty, plotOrder: number, plotter: Plotter) {
    let { viewModel, settings, svg, plotArea, headerHeight, groupHeaderHeight, currentRow, currentYPosition: currentHeight, dataPropertyForComments } = plotter;
    let totalWidth = plotter.width;
    let height = plotter.getCurrentChartHeight();
    let chartGroup = viewModel.lastLevelChartGroups[groupIndex];
    let mm = getMinsAndMaxes(dataProperty, viewModel.extremes, settings, chartGroup);
    let chartHierarchy = viewModel.getChartHierarchy(groupIndex, chartHierarchyIndex);
    let start = xPosition + mm.getMinLabelOffset(settings);
    let end = Math.max(start, xPosition + width - mm.getMaxLabelOffset(settings));
    let xScale = drawing.getLinearScale(Math.min(mm.min, 0), Math.max(mm.max, 0), start, end);
    let yScale = getOrdinalScale(chartHierarchy.dataPoints(), currentHeight, currentHeight + height, viewModel, settings.getGapBetweenColumns());
    let plottableDataPoints = plotter.getDataPointsToPlotChartElements(chartHierarchy, yScale, dataProperty, true);
    let gridlinePlottableDataPoints = plotter.getDataPointsToPlotChartElements(chartHierarchy, yScale, dataProperty, false);
    plotter.currentlyShowingDataPoints = plottableDataPoints.inView;
    let chartArea = drawing.createGroupElement(plotArea, `${CHART_AREA}_${groupIndex}_${chartHierarchyIndex}_${chartId} ${CHART_AREA}`);
    let scaleGroup = settings.getScaleGroup(dataProperty);
    chartArea.attr(DATA_SCALE_GROUP, scaleGroup);
    chartArea.attr(DATA_PROPERTY, dataProperty);
    chartArea.attr(CHART_ID, chartId);
    let axisPosition = xScale(0);
    let dp = getDataProperties(dataProperty, settings);
    let referenceScenario = settings.getReferenceScenarioForDataProperty(dataProperty);

    if (debug.shouldLog(LogType.DisplayBounds)) {
        debug.drawBoundingBox(chartArea, xPosition, width, currentHeight, height, chartId);
    }
    let leftGridlineEndFunction = (d: DataPoint, end: number) => {
        if (!d.getValue(dataProperty)) {
            return axisPosition;
        }
        return Math.max(xPosition, getBarXPosition(xScale, d, dataProperty, chartHierarchy, settings) - GRIDLINE_OFFSET);
    };
    let rightGridlineStartFunction = (d: DataPoint, start: number) => {
        if (!d.getValue(dataProperty)) {
            return axisPosition;
        }
        return Math.min(xPosition + width, getBarXPositionPlusWidth(xScale, d, dataProperty, chartHierarchy, axisPosition, settings) + GRIDLINE_OFFSET);
    };
    plotGridlines(yScale, xPosition, width, axisPosition, settings, viewModel, gridlinePlottableDataPoints.inView, chartArea, chartId, leftGridlineEndFunction, rightGridlineStartFunction, groupIndex, chartHierarchyIndex);

    let headerXPosition = Math.round(axisPosition);
    let anchor = getTextAnchorForWaterfallChart(mm);
    let header = settings.getHeader(dataProperty);
    let isHeaderBold = settings.isHeaderBold(dataProperty) || settings.shouldUseBoldGroupHeaders(chartGroup, viewModel.maximumExpandedGroupLevel);
    if (multiples) {
        let yPosition = currentHeight + viewModel.numberOfGroupLevels * headerHeight;
        if (viewModel.hasMultipleGroups() && settings.chartType === ABSOLUTE) {
            drawing.drawLine(Visual.headers, xPosition, xPosition + width, yPosition + headerHeight - 3, yPosition + headerHeight - 3, 1, GRAY, EMPTY);
        }
        if (settings.shouldShowScenarioSortHeaders(plotter)) {
            let yPosition = chartGroup.level * groupHeaderHeight;
            if (chartGroup.isSubtotal) {
                yPosition = viewModel.maximumExpandedGroupLevel * groupHeaderHeight;
            }
            let sortableHeader = plotSortableHeader(chartHierarchy, plotter, viewModel, totalWidth, header, currentRow, groupIndex, xPosition, yPosition + groupHeaderHeight + 3, anchor, isHeaderBold, dataProperty, plotOrder, true, width, headerXPosition, false, true, true, true, false, groupIndex, false, chartGroup, false, null, false, chartId);
            sortableHeader.setChartChangeIcons(dp.drawIconFunction, dp.chartType);
        }
    }
    else {
        let sortableHeader = plotSortableHeader(chartHierarchy, plotter, viewModel, totalWidth, header, currentRow, null, xPosition, 3, anchor, isHeaderBold, dataProperty, plotOrder, true, width, headerXPosition, false, true, true, true, false, groupIndex, false, chartGroup, false, null, false, chartId);
        if (settings.getRealInteractionSettingValue(settings.allowChartChange) && !(settings.valueChart === ValueChart.TwoWaterfalls && dataProperty === DataProperty.ReferenceValue) && currentRow === 0) {
            sortableHeader.setChartChangeIcons(dp.drawIconFunction, dp.chartType);
        }
    }
    if (settings.shouldPlotVerticalAxis(chartHierarchy)) {
        let semanticAxis = plottingAbsoluteChart(dataProperty) && !isAdditionalMeasure(dataProperty);
        drawing.plotVerticalAxis(chartArea, chartId, semanticAxis, axisPosition, currentHeight, height, settings.colorScheme.axisColor, referenceScenario, settings.showTotals, yScale, viewModel.hasHierarchy, chartHierarchy.isGrandTotal, settings.getGapBetweenColumns());
    }
    plotConnectingLines(chartGroup, chartHierarchy, settings, dataProperty, viewModel, chartHierarchyIndex, chartArea, xScale, yScale, chartId);
    plotSubtotalLines(viewModel, chartHierarchy, plottableDataPoints.inViewWithoutFormulas, xScale, dataProperty, chartArea, chartId, yScale);

    let bars = drawing.getShapes(chartArea, `.${BAR}${chartId}`, `${BAR}${chartId} ${HIGHLIGHTABLE} ${TOOLTIP}`, RECT, plottableDataPoints.inViewWithoutFormulas.filter(d => d.getValue(dataProperty)));
    bars
        .attr(HEIGHT, (d: DataPoint) => (d.isFormula() && d.formula?.units === DisplayUnits.Percent) ? 0 : Math.round(yScale.bandwidth()))
        .attr(WIDTH, d => getBarWidth(xScale, d, dataProperty, axisPosition))
        .attr(X, d => getBarXPosition(xScale, d, dataProperty, chartHierarchy, settings))
        .attr(Y, d => Math.round(yScale(d.getCategory(viewModel))));
    addMouseHandlers(bars, null, svg, viewModel);
    addBlurIfNeeded(bars, settings);
    // plotter.addTooltips(bars);
    styles.applyToBars(bars, dp.colorFunction, scenarios.getVarianceScenario(dataProperty, viewModel), settings.chartStyle, plottingAbsoluteChart(dataProperty), settings.colorScheme);
    let dataLabelPoints = plottableDataPoints.inView.filter(d => d.getValue(dataProperty) && d.getValue(dataProperty) !== 0);
    if (!settings.showDataLabels) {
        dataLabelPoints = [];
    }
    let backgrounds: d3.Selection<any, DataPoint, any, any>;
    backgrounds = chartArea
        .selectAll(`.${BACKGROUND}${chartId}`)
        .data(dataLabelPoints);

    let rect = backgrounds
        .enter().append(RECT)
        .classed(`${BACKGROUND}${chartId}`, true);
    backgrounds = backgrounds.merge(rect);
    let labels = drawing.getLabels(chartArea, `${LABEL}${chartId}`, dataLabelPoints);
    labels
        .text(d => d.getLabel(dataProperty))
        .style(FONT_SIZE, `${settings.labelFontSize}${FONT_SIZE_UNIT}`)
        .style(TEXT_ANCHOR, (d: DataPoint) => getLabelAnchor(d, dataProperty))
        .style(FONT_FAMILY, settings.labelFontFamily)
        .style(FONT_WEIGHT, (d: DataPoint) => isHeaderBold ? BOLD : d.getFontWeight())
        .style(FONT_STYLE, d => d.italic ? ITALIC : INITIAL)
        .attr(X, (d: DataPoint) => {
            if (d.isNegative(dataProperty)) {
                if (d.isInverted && (!d.isTotal() || d.isSkipped)) {
                    let offset = ((chartHierarchy.oneDataPointWithSameCategoryAsTotal() && !d.isSkipped)) ? 5 : -5;
                    return Math.max(xPosition, xScale(d.getCumulative(dataProperty)) + offset);
                }
                else {
                    return Math.max(xPosition, xScale(d.getCumulative(dataProperty) + d.getValue(dataProperty)) + -5);
                }
            }
            else {
                if (d.isTotal() && ((d.isInverted && d.invertedSubtotalCalculation[dataProperty]))) {
                    return Math.min(xPosition + width, xScale(d.getCumulative(dataProperty)) - 5);
                }
                if (d.isInverted && (!d.isTotal() || d.isSkipped)) {
                    let offset = ((chartHierarchy.oneDataPointWithSameCategoryAsTotal() && !d.isSkipped)) ? 5 : -5;
                    return Math.min(xPosition + width, xScale(d.getCumulative(dataProperty) - d.getValue(dataProperty)) + offset);
                }
                else {
                    return Math.min(xPosition + width, xScale(d.getCumulative(dataProperty) + d.getValue(dataProperty)) + 5);
                }
            }
        })
        .attr(Y, d => drawing.centerTextVertically(yScale, d.getCategory(viewModel), settings.labelFontSize))
        .attr(FILL, (d: DataPoint) => d.getLabelColor(settings.labelFontColor, dataProperty))
        .classed(FORMULA_ELEMENT, (d: DataPoint) => d.isCurrentlyEditedFormula())
        .classed(HIGHLIGHTABLE, true)
        .classed(TOOLTIP, true);
    // addMouseHandlers(labels, selectionManager, svg, viewModel);
    addBlurIfNeeded(labels, settings);
    // plotter.addTooltips(labels);
    let boundingBoxes = [];
    labels.each(function () {
        boundingBoxes.push((<SVGGraphicsElement>this).getBBox());
    });
    let paddingLeftRight = 4;
    let paddingTopBottom = 0;
    backgrounds
        .attr(X, (d, i) => { return boundingBoxes[i].x - paddingLeftRight / 2; })
        .attr(Y, (d, i) => { return boundingBoxes[i].y - paddingTopBottom / 2; })
        .attr(WIDTH, (d, i) => { return boundingBoxes[i].width + paddingLeftRight; })
        .attr(HEIGHT, (d, i) => { return boundingBoxes[i].height + paddingTopBottom; })
        .attr(FILL, WHITE)
        .attr(FILL_OPACITY, 1 - (settings.labelBackgroundTransparency / 100));
    backgrounds.exit().remove();

    if (viewModel.HasCommentsColumn) {
        let commentDataPoints: DataPoint[] = [];
        // comment markers with default data property (not being dragged to another data property):
        if (dataProperty === dataPropertyForComments) {
            commentDataPoints.push(...plottableDataPoints.inViewWithoutFormulas.filter(d => d.hasComment() && d.commentMarkerDataProperty === null));
        }
        commentDataPoints.push(...plottableDataPoints.inViewWithoutFormulas.filter(d => d.hasComment()).filter(d => d.commentMarkerDataProperty === dataProperty));
        plotCommentMarkers(chartArea, viewModel, commentDataPoints, xScale, yScale, settings, dataProperty, plotter.categoriesWidth, groupIndex);
        //plotter.addCommentMarkerTooltip(chartArea);
    }
}

function plotCommentMarkers(container: d3.Selection<SVGElement, any, any, any>, viewModel: ViewModel, commentDataPoints: DataPoint[], xScale: d3.ScaleLinear<number, number>, yScale: d3.ScaleBand<string>,
    settings: VarianceSettings, dataProperty: DataProperty, categoriesWidth: number, groupIndex: number) {
    let scaleBandWidth = yScale.bandwidth();
    let markerAttrs = getCommentMarkerAttributes(scaleBandWidth);
    let getMarkerYPosition = (d: DataPoint): number => yScale(d.getCategory(viewModel)) + scaleBandWidth / 2;
    addCommentMarkers(container, commentDataPoints, getMarkerXPosition(dataProperty, xScale, markerAttrs), getMarkerYPosition, markerAttrs.radius, markerAttrs.fontSize, settings.colorScheme.highlightColor, settings.labelFontFamily, viewModel, categoriesWidth, groupIndex);
}

function getMarkerXPosition(dataProperty: DataProperty, xScale: d3.ScaleLinear<number, number>, markerAttrs: CommentMarker) {
    return (d: DataPoint): number => {
        if (d.isNegative(dataProperty)) {
            if (d.isInverted) {
                return xScale(d.getCumulative(dataProperty) - d.getValue(dataProperty)) + (markerAttrs.margin + markerAttrs.radius)
            }
            return xScale(d.getCumulative(dataProperty)) + (markerAttrs.margin + markerAttrs.radius)
        }
        else {
            if (d.isInverted) {
                return xScale(d.getCumulative(dataProperty)) + (markerAttrs.margin + markerAttrs.radius)
            }
            return xScale(d.getCumulative(dataProperty)) - (markerAttrs.margin + markerAttrs.radius)
        }
    };
}

function getLabelAnchor(d: DataPoint, dp: DataProperty): string {
    if (d.isTotal && d.isInverted && d.invertedSubtotalCalculation[dp]) {
        if (d.isNegative(dp)) {
            return START;
        }
        else {
            return END;
        }
    }
    else if (d.isNegative(dp)) {
        return END;
    }
    if (d.isInverted && (!d.isTotal() || d.isSkipped)) {
        return END;
    }
    return START;
}

function plotSubtotalLines(viewModel: ViewModel, chartHierarchy: ChartHierarchy, dataPoints: DataPoint[], xScale: d3.ScaleLinear<number, number>,
    dataProperty: DataProperty, chartArea: d3.Selection<SVGElement, any, HTMLElement, any>, chartId: number, yScale: d3.ScaleBand<string>) {
    if (viewModel.hasHierarchy && !chartHierarchy.isGrandTotal && chartHierarchy.totalDataPoint) {
        let totalPoint = chartHierarchy.totalDataPoint;
        chartHierarchy.connectingLinePositions[dataProperty] = {
            x: xScale(totalPoint.getCumulative(dataProperty) + (totalPoint.invertedSubtotalCalculation[dataProperty] ? totalPoint.getValue(dataProperty) : 0)),
            y: yScale(totalPoint.getCategory(viewModel))
        };
        let previousHierarchy = chartHierarchy.getPreviousHierarchy();
        if (previousHierarchy && !totalPoint.isResult && !(chartHierarchy.isCollapsed() && previousHierarchy.isCollapsed())) {
            let upperPosition = previousHierarchy.connectingLinePositions[dataProperty];
            let axisPosition = xScale(0);
            let x = chartHierarchy.connectingLinePositions[dataProperty].x;
            if (upperPosition && axisPosition !== x) {
                let y1 = chartHierarchy.connectingLinePositions[dataProperty].y + yScale.bandwidth();
                let y2 = upperPosition.y;
                let line = drawLine(chartArea, x, x, y1, y2, 1, GRAY, `${CONNECTING}-${LINE}-${SUBTOTAL}${chartId}`);
                line.style(STROKE_DASHARRAY, ("2,2"));
            }
        }
    }
}

function getDataProperties(dataProperty: DataProperty, settings: VarianceSettings): DataProperties {
    switch (dataProperty) {
        case DataProperty.Value:
        case DataProperty.ReferenceValue:
        case DataProperty.SecondReferenceValue:
        case DataProperty.ThirdReferenceValue:
        case DataProperty.FourthReferenceValue:
        case DataProperty.FifthReferenceValue:
        case DataProperty.SixthReferenceValue:
        case DataProperty.SeventhReferenceValue:
            return {
                drawIconFunction: drawValueIcons,
                colorFunction: getNeutralColor(settings),
                chartType: ChartType.ValueChart,
            };
        case DataProperty.AbsoluteDifference:
        case DataProperty.SecondAbsoluteDifference:
        case DataProperty.ThirdAbsoluteDifference:
        case DataProperty.FourthAbsoluteDifference:
        case DataProperty.FifthAbsoluteDifference:
        case DataProperty.SixthAbsoluteDifference:
        case DataProperty.SeventhAbsoluteDifference:
            return {
                drawIconFunction: drawAbsoluteIcons,
                colorFunction: getVarianceColor(settings, dataProperty),
                chartType: ChartType.AbsoluteChart,
            };
    }
}

function getMinsAndMaxes(dataProperty: DataProperty, minsAndMaxes: Extremes, settings: VarianceSettings, group: ChartGroup): Extreme {
    let extremes = settings.plottingHorizontally() ? group.extremes : minsAndMaxes;
    let extreme: Extreme;
    switch (dataProperty) {
        case DataProperty.Value:
            extreme = extremes.valueCumulative;
            break;
        case DataProperty.ReferenceValue:
            extreme = extremes.referenceValueCumulative;
            break;
        case DataProperty.SecondReferenceValue:
            extreme = extremes.secondReferenceValueCumulative;
            break;
        case DataProperty.ThirdReferenceValue:
            extreme = extremes.thirdReferenceValueCumulative;
            break;
        case DataProperty.FourthReferenceValue:
            extreme = extremes.fourthReferenceValueCumulative;
            break;
        case DataProperty.FifthReferenceValue:
            extreme = extremes.fifthReferenceValueCumulative;
            break;
        case DataProperty.SixthReferenceValue:
            extreme = extremes.sixthReferenceValueCumulative;
            break;
        case DataProperty.SeventhReferenceValue:
            extreme = extremes.seventhReferenceValueCumulative;
            break;
        case DataProperty.AbsoluteDifference:
            extreme = extremes.absoluteCumulative;
            break;
        case DataProperty.SecondAbsoluteDifference:
            extreme = extremes.secondAbsoluteCumulative;
            break;
        case DataProperty.ThirdAbsoluteDifference:
            extreme = extremes.thirdAbsoluteCumulative;
            break;
        case DataProperty.FourthAbsoluteDifference:
            extreme = extremes.fourthAbsoluteCumulative;
            break;
        case DataProperty.FifthAbsoluteDifference:
            extreme = extremes.fifthAbsoluteCumulative;
            break;
        case DataProperty.SixthAbsoluteDifference:
            extreme = extremes.sixthAbsoluteCumulative;
            break;
        case DataProperty.SeventhAbsoluteDifference:
            extreme = extremes.seventhAbsoluteCumulative;
            break;
    }
    if (settings.showRowGrandTotal) {
        let totalValue = group.getTotal(dataProperty);
        extreme.max = totalValue > extreme.max ? totalValue : extreme.max;
        extreme.min = totalValue < extreme.min ? totalValue : extreme.min;
    }
    return extreme;
}

function getBarXPosition(xScale: d3.ScaleLinear<number, number>, d: DataPoint, dataProperty: DataProperty, chartHierarchy: ChartHierarchy, settings: VarianceSettings): number {
    if (d.isFlatResult()) {
        if (d.isNegative(dataProperty)) {
            return xScale(d.getCumulative(dataProperty) + d.getValue(dataProperty));
        }
        else {
            return xScale(0);
        }
    }
    if (d.isNegative(dataProperty)) {
        if (d.isInverted && (d.isSkipped || !(d.isTotal() || d.isOtherTotal()))) {
            return xScale(d.getCumulative(dataProperty));
        }
        else {
            return xScale(d.getCumulative(dataProperty) + d.getValue(dataProperty));
        }
    }
    else {
        if (d.isInverted && (d.isSkipped || !(d.isTotal() || d.isOtherTotal()))) {
            return xScale(d.getCumulative(dataProperty) - d.getValue(dataProperty));
        }
        else {
            return xScale(d.getCumulative(dataProperty));
        }
    }
}

function getBarWidth(xScale: d3.ScaleLinear<number, number>, d: DataPoint, dataProperty: DataProperty, axisPosition: number): number {
    return Math.abs(xScale(d.getValue(dataProperty)) - axisPosition);
}

function getBarXPositionPlusWidth(xScale: d3.ScaleLinear<number, number>, d: DataPoint, dataProperty: DataProperty, chartHierarchy: ChartHierarchy, axisPosition: number, settings: VarianceSettings): number {
    return getBarXPosition(xScale, d, dataProperty, chartHierarchy, settings) + getBarWidth(xScale, d, dataProperty, axisPosition);
}

interface DataProperties {
    drawIconFunction: (chartType: ChartType, columnSettingsDiv: HTMLElement, icon: d3.Selection<SVGElement, any, any, any>, viewModel: ViewModel, iconSize: number, dataProperty: DataProperty, plotter: Plotter) => number;
    colorFunction: (d: DataPoint) => string;
    chartType: ChartType;
}

function plotConnectingLines(chartGroup: ChartGroup, chartHierarchy: ChartHierarchy, settings: VarianceSettings,
    dataProperty: DataProperty, viewModel: ViewModel, chartHierarchyIndex: number, chartArea: d3.Selection<SVGElement, any, any, any>,
    xScale: d3.ScaleLinear<number, number>, yScale: d3.ScaleBand<string>, id: number) {
    if (!chartGroup.isRowGrandTotal) {
        let connectingLinesDataPoints = getConnectingLinesDataPoints(chartHierarchy, settings, dataProperty, viewModel);
        let lines = drawing.getShapes(chartArea, `.${CONNECTING}-${LINE}${id}`, `${CONNECTING}-${LINE}${id} ${CONNECTING}-${LINE}`, LINE, connectingLinesDataPoints);
        let allPoints = chartHierarchy.dataPoints();
        let lineFactors = new Map<string, number>();
        for (let i = 1; i < allPoints.length; i++) {
            let j = i;
            while (j < allPoints.length && (allPoints[j].getValue(dataProperty) === null || allPoints[j].isSkipped)) {
                lineFactors.set(allPoints[i - 1].fullCategory, lineFactors.has(allPoints[i - 1].fullCategory) ? lineFactors.get(allPoints[i - 1].fullCategory) + 1 : 1);
                j++;
            }
        }
        let rowHeight = yScale.bandwidth() / (1 - settings.getGapBetweenColumns());

        lines
            .attr(X1, d => getConnectingLinesXPosition(dataProperty, xScale, chartHierarchy, d, connectingLinesDataPoints))
            .attr(X2, d => getConnectingLinesXPosition(dataProperty, xScale, chartHierarchy, d, connectingLinesDataPoints))
            .attr(Y1, d => chartHierarchy.isGrandTotal && settings.showFrozenRowGrandTotal() ? 0 : yScale(d.getCategory(viewModel)))
            .attr(Y2, d => chartHierarchy.isGrandTotal && settings.showFrozenRowGrandTotal() ? yScale(d.getCategory(viewModel)) + yScale.bandwidth() : yScale(d.getCategory(viewModel)) + (lineFactors.has(d.fullCategory) ? lineFactors.get(d.fullCategory) * rowHeight : 0) + (2 - settings.getGapBetweenColumns()) * rowHeight)
            .attr(STROKE_WIDTH, 1)
            .attr(STROKE, GRAY);
    }
    else if (chartGroup.isRowGrandTotal &&
        ((settings.absoluteChart === AbsoluteChart.CalculationWaterfall && isAbsoluteDifferenceMeasure(dataProperty) ||
            (settings.valueChart === ValueChart.CalculationWaterfall && isValueMeasure(dataProperty))) ||
            !viewModel.hasHierarchy)) {
        let lines = drawing.getShapes(chartArea, `.${GRAND_TOTAL}-${CONNECTING}-${LINE}${id}`, `${GRAND_TOTAL}-${CONNECTING}-${LINE}${id}`, LINE, chartHierarchy.dataPoints());
        lines
            .attr(X1, d => xScale(d.getCumulative(dataProperty) + d.getValue(dataProperty) * (d.isInverted ? -1 : 1)))
            .attr(X2, d => xScale(d.getCumulative(dataProperty) + d.getValue(dataProperty) * (d.isInverted ? -1 : 1)))
            .attr(Y1, d => Math.round(yScale(d.getCategory(viewModel))) - 30)
            .attr(Y2, d => Math.round(yScale(d.getCategory(viewModel))) + yScale.bandwidth())
            .attr(STROKE_WIDTH, 1)
            .attr(STROKE, GRAY);
    }
}

function getConnectingLinesXPosition(dataProperty: DataProperty, xScale: d3.ScaleLinear<number, number>, chartHierarchy: ChartHierarchy, d: DataPoint, dataPoints: DataPoint[]): number {
    if (!d.isTotal() || d.isOtherTotal()) {
        return xScale(d.getCumulative(dataProperty) + d.getValue(dataProperty) * (d.isInverted ? -1 : 1));
    }
    else if (d.isResult) {
        return xScale(d.getCumulative(dataProperty) + d.getValue(dataProperty));
    }
    let useJustCumulative = chartHierarchy.oneDataPointWithSameCategoryAsTotal() && !d.isFormula() || d.isInverted;
    return xScale(d.getCumulative(dataProperty) + (useJustCumulative ? 0 : d.getValue(dataProperty)) * (d.isInverted && !d.isTotal() ? -1 : 1));
}

function getConnectingLinesDataPoints(chartHierarchy: ChartHierarchy, settings: VarianceSettings, dataProperty: DataProperty, viewModel: ViewModel): DataPoint[] {
    let dataPointsForLines = chartHierarchy.dataPoints().filter(d => d.getValue(dataProperty) && !d.isSkipped);
    if (shouldntShowLineOnLastPoint(chartHierarchy, viewModel, dataProperty)) {
        dataPointsForLines = dataPointsForLines.slice(0, dataPointsForLines.length - 1);
    }
    if (shouldSkipDoubleFlatResultPoints(viewModel, dataProperty)) {
        let dp = [];
        let wasPreviousPointFlatResult = false;
        for (let i = dataPointsForLines.length - 1; i >= 0; i--) {
            let currentPoint = dataPointsForLines[i];
            if ((!wasPreviousPointFlatResult || !currentPoint.isFlatResult()) && !currentPoint.isSkipped) {
                dp.push(currentPoint);
            }
            wasPreviousPointFlatResult = currentPoint.isFlatResult();
        }
    }
    return dataPointsForLines;
}

function shouldntShowLineOnLastPoint(chartHierarchy: ChartHierarchy, viewModel: ViewModel, dataProperty: DataProperty): boolean {
    if (viewModel.numberOfLevels === 1 && !viewModel.settings.plottingHorizontally() && viewModel.numberOfGroupLevels > 0) {
        return true;
    }
    let nextHiearchy = chartHierarchy.getNextHierarchy();
    if (!nextHiearchy) {
        return (((isAbsoluteDifferenceMeasure(dataProperty) && viewModel.settings.absoluteChart === AbsoluteChart.Waterfall ||
            isValueMeasure(dataProperty) && viewModel.settings.valueChart === ValueChart.Waterfall)) && chartHierarchy.level === 0 && viewModel.hasHierarchy) || !viewModel.settings.showRowGrandTotal;
    }
    if (chartHierarchy.level === 0 && viewModel.hasHierarchy &&
        ((isAbsoluteDifferenceMeasure(dataProperty) && viewModel.settings.absoluteChart === AbsoluteChart.Waterfall) ||
            (isValueMeasure(dataProperty) && viewModel.settings.valueChart === ValueChart.Waterfall))) {
        return true;
    }
    return viewModel.hasHierarchy && chartHierarchy.level === 0 && nextHiearchy.isCollapsed() && nextHiearchy.totalDataPoint.isResult;
}

function shouldSkipDoubleFlatResultPoints(viewModel: ViewModel, dataProperty: DataProperty): boolean {
    return plottingValueChart(dataProperty) && !viewModel.hasHierarchy;
}