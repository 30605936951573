import { ViewModel } from "../../settings/viewModel";
import { PlotDefinition, ChartType, VERTICAL_MARGIN, ChartPlotDefinition } from "../../definitions";
import * as d3 from "d3";
import { VarianceSettings } from "../../settings/varianceSettings";
import { isPercentAdditionalMeasure } from "../charts/chart";

export function verticalScaling(viewModel: ViewModel, availableWidth: number, plotDefinition: PlotDefinition) {
    let chartDefinitions = plotDefinition.chartPlotDefinitions;
    let tables = chartDefinitions.filter(d => d.chartType === ChartType.Table);
    tables.forEach(t => t.width = viewModel.getTableWidth(t.dataProperty, -1));
    let tableWidths = tables.map(t => t.width);
    let absoluteCharts = chartDefinitions.filter(d => d.chartType !== ChartType.Table && d.chartType !== ChartType.PlusMinusDot && !isPercentAdditionalMeasure(d.dataProperty, viewModel.settings));
    absoluteCharts.forEach(c => c.labelOffset = viewModel.extremes.getLabelOffset(c.dataProperty, viewModel));
    let absoluteOffsets = d3.sum(absoluteCharts.map(c => c.labelOffset));
    let relativeCharts = chartDefinitions.filter(d => d.chartType === ChartType.PlusMinusDot || (d.chartType !== ChartType.Table && isPercentAdditionalMeasure(d.dataProperty, viewModel.settings)));
    let relativeOffsets = d3.sum(relativeCharts.map(c => c.labelOffset));
    let spaceForCharts = availableWidth
        - d3.sum(tableWidths) - tableWidths.length * VERTICAL_MARGIN
        - absoluteOffsets - absoluteCharts.length * VERTICAL_MARGIN
        - relativeOffsets - relativeCharts.length * VERTICAL_MARGIN;
    spaceForCharts = Math.max(0, spaceForCharts);
    if (plotDefinition.absoluteRatio === 1 && relativeCharts.length > 0) {
        plotDefinition.absoluteRatio = absoluteCharts.length / (absoluteCharts.length + relativeCharts.length);
    }

    let spaceForAbsoluteCharts = spaceForCharts * plotDefinition.absoluteRatio;
    calculateChartWidths(absoluteCharts, spaceForAbsoluteCharts, viewModel)

    let spaceForRelativeCharts = (spaceForCharts * (1 - plotDefinition.absoluteRatio));
    calculateChartWidths(relativeCharts, spaceForRelativeCharts, viewModel);
}

function calculateChartWidths(charts: ChartPlotDefinition[], space: number, viewModel: ViewModel) {
    let groupMap = new Map<number, ChartPlotDefinition[]>();
    charts.forEach(c => {
        let group = viewModel.settings.getScaleGroup(c.dataProperty);
        if (groupMap.has(group)) {
            let current = groupMap.get(group);
            current.push(c);
        }
        else {
            groupMap.set(group, [c]);
        }
    });
    let numberOfCharts = charts.length;
    for (let group of groupMap.keys()) {
        let groupCharts = groupMap.get(group);
        if (group === -1) {
            let dataPropertyMap = new Map<number, ChartPlotDefinition[]>();
            groupCharts.forEach(c => {
                if (dataPropertyMap.has(c.dataProperty)) {
                    let current = dataPropertyMap.get(c.dataProperty);
                    current.push(c);
                }
                else {
                    dataPropertyMap.set(c.dataProperty, [c]);
                }
            });
            for (let dataProperty of dataPropertyMap.keys()) {
                let chartsWithCurrentDataProperty = dataPropertyMap.get(dataProperty);
                calculateWidths(chartsWithCurrentDataProperty, numberOfCharts, space, viewModel);
            }
        }
        else {
            calculateWidths(groupCharts, numberOfCharts, space, viewModel);
        }
    }
}
function calculateWidths(groupCharts: ChartPlotDefinition[], numberOfCharts: number, space: number, viewModel: ViewModel) {
    let factor = groupCharts.length / numberOfCharts;
    let groupSpace = factor * space;
    let totalRange = d3.sum(groupCharts.map(c => c.range));
    groupCharts.forEach(c => {
        if (totalRange === 0) {
            c.width = viewModel.getTableWidth(c.dataProperty, -1)
        }
        else {
            c.width = c.labelOffset + groupSpace * (c.range / totalRange);
        }
    });
}

