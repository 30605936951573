import { SheetData } from "x-data-spreadsheet";

export const CrossTableSimpleSampleData: SheetData = {
  rows: {
    0: {
      cells: {
        0: { text: "Fruit" },
        1: { text: "Europe" },
        2: { text: "America" },
        3: { text: "Asia" },
        4: { text: "Africa" },
        5: { text: "Others" },
      },
    },
    1: {
      cells: {
        0: { text: "Oranges" },
        1: { text: "91" },
        2: { text: "47" },
        3: { text: "16" },
        4: { text: "10" },
        5: { text: "15" },
      },
    },
    2: {
      cells: {
        0: { text: "Lemons" },
        1: { text: "80" },
        2: { text: "50" },
        3: { text: "20" },
        4: { text: "24" },
        5: { text: "17" },
      },
    },
    3: {
      cells: {
        0: { text: "Bananas" },
        1: { text: "78" },
        2: { text: "45" },
        3: { text: "18" },
        4: { text: "56" },
        5: { text: "9" },
      },
    },
    4: {
      cells: {
        0: { text: "Kiwi" },
        1: { text: "73" },
        2: { text: "42" },
        3: { text: "17" },
        4: { text: "32" },
        5: { text: "8" },
      },
    },
    5: {
      cells: {
        0: { text: "Apples" },
        1: { text: "74" },
        2: { text: "32" },
        3: { text: "17" },
        4: { text: "42" },
        5: { text: "15" },
      },
    },
    6: {
      cells: {
        0: { text: "Melons" },
        1: { text: "79" },
        2: { text: "44" },
        3: { text: "14" },
        4: { text: "42" },
        5: { text: "8" },
      },
    },
    7: {
      cells: {
        0: { text: "Pears" },
        1: { text: "71" },
        2: { text: "21" },
        3: { text: "14" },
        4: { text: "62" },
        5: { text: "14" },
      },
    },
    8: {
      cells: {
        0: { text: "Grapes" },
        1: { text: "57" },
        2: { text: "33" },
        3: { text: "11" },
        4: { text: "24" },
        5: { text: "9" },
      },
    },
  },
};
