import GlobalToolbarObserver from "@zebrabi/global-toolbar-old/observers/GlobalToolbarObserver";

/**
 * Type defined observer used to isolate the notifications
 */
abstract class SettingsTablesSwitcherObserver extends GlobalToolbarObserver {
    static readonly CLASS_NAME = `SettingsTablesSwitcherObserver`;

    getClassName(): string {
        return SettingsTablesSwitcherObserver.CLASS_NAME;
    }

    /**
     * Triggered on general actions (open, close, save, etc.)
     * @param action
     * @param message
     */
    action(action: string, message: string): void {return;}
    update: (message: Map<string, any>) => void;
}

export default SettingsTablesSwitcherObserver;
