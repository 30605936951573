import { Plotter } from "../plotters/plotter";
import { DataProperty, CHART_AREA, LogType, VALUE_SORT, BAR, HIGHLIGHTABLE, TOOLTIP, RECT, STROKE_OPACITY, Scenario, FILL_OPACITY, LABEL, RECTANGLE, PATH, TRANSFORM, STROKE_WIDTH, STROKE, WHITE, FONT_SIZE, TEXT_ANCHOR, END, START, FONT_FAMILY, FONT_STYLE, BOLD, OPACITY, DATA_SCALE_GROUP, DATA_PROPERTY, HEIGHT, WIDTH, X, Y, D, FILL, RX, ITALIC, INITIAL, FORMULA_ELEMENT, FONT_SIZE_UNIT, CHART_ID, FONT_WEIGHT } from "../../library/constants";
import { getOrdinalScale, getLeftSideGridlinesEnd, getRightSideGrindlinesStart, plotGridlines, getTextAnchor, plotSortableHeader, drawValueIcons, addBlurIfNeeded, getNeutralColor, getCommentMarkerAttributes, addCommentMarkers, addMouseHandlers } from "./chart";
import { ChartType, ValueChart } from "../../definitions";
import { ViewModel } from "../../settings/viewModel";
import { ChartGroup } from "../chartGroup";
import { Extreme } from "../extreme";
import { Extremes } from "../extremes";
import * as drawing from "./../../library/drawing";
import * as styles from "./../../library/styles";
import * as debug from "./../../library/debug";
import * as scenarios from "./../../settings/scenarios";
import * as d3 from "d3";
import { DataPoint } from "../dataPoint";
import { VarianceSettings } from "../../settings/varianceSettings";
import { isAdditionalMeasure } from "../../library/helpers";
import { getScenarioFromMarkerStyle, isPlanScenario } from "../../helpers";

// tslint:disable-next-line: max-func-body-length 
export function plotStructureChart(width: number, xPosition: number, yPosition: number, groupIndex: number, chartHierarchyIndex: number, chartId: number, multiples: boolean,
    plotter: Plotter, dataProperty: DataProperty, plotOrder: number) {
    let { viewModel, settings, plotArea, svg, groupHeaderHeight, currentRow, dataPropertyForComments } = plotter;
    let totalWidth = plotter.width;
    let height = plotter.getCurrentChartHeight();
    let chartGroup = viewModel.lastLevelChartGroups[groupIndex];
    let chartHierarchy = viewModel.getChartHierarchy(groupIndex, chartHierarchyIndex);
    let minMax = getMinMax(viewModel, xPosition, width, dataProperty, chartGroup);
    let start = xPosition + minMax.getMinLabelOffset(settings);
    let end = Math.max(xPosition + width - minMax.getMaxLabelOffset(settings), start);
    let xScale = drawing.getLinearScale(minMax.min, minMax.max, start, end);
    let yScale = getOrdinalScale(chartHierarchy.dataPoints(), yPosition, yPosition + height, viewModel, settings.getGapBetweenColumns());
    let plottableDataPoints = plotter.getDataPointsToPlotChartElements(chartHierarchy, yScale, dataProperty, true);
    let gridlinePlottableDataPoints = plotter.getDataPointsToPlotChartElements(chartHierarchy, yScale, dataProperty, false);
    plotter.currentlyShowingDataPoints = plottableDataPoints.inView;
    let chartArea = drawing.createGroupElement(plotArea, `${CHART_AREA}_${groupIndex}_${chartHierarchyIndex}_${chartId} ${CHART_AREA}`);
    let scaleGroup = settings.getScaleGroup(dataProperty);
    chartArea.attr(DATA_SCALE_GROUP, scaleGroup);
    chartArea.attr(DATA_PROPERTY, dataProperty);
    chartArea.attr(CHART_ID, chartId);
    let axisPosition = xScale(0);
    let isShowingOverlappedChart = showingOverlappedChart(viewModel, dataProperty);

    if (debug.shouldLog(LogType.DisplayBounds)) {
        debug.drawBoundingBox(chartArea, xPosition, width, yPosition, height, chartId);
    }

    let leftGridlineEndFunction = getLeftSideGridlinesEnd(xScale, VALUE_SORT, axisPosition);
    let rightGridlineStartFunction = getRightSideGrindlinesStart(xScale, VALUE_SORT, axisPosition, xPosition);
    plotGridlines(yScale, xPosition, width, axisPosition, settings, viewModel, gridlinePlottableDataPoints.inView, chartArea, chartId, leftGridlineEndFunction, rightGridlineStartFunction, groupIndex, chartHierarchyIndex);
    if (settings.shouldPlotVerticalAxis(chartHierarchy)) {
        drawing.plotVerticalAxis(chartArea, chartId, false, axisPosition, yPosition, height, settings.colorScheme.axisColor, viewModel.reference.scenario, settings.showTotals, yScale, viewModel.hasHierarchy, chartHierarchy.isGrandTotal, settings.getGapBetweenColumns());
    }
    let anchor = getTextAnchor(minMax.min, minMax.max);
    let headerXPosition = anchor === "center" ? axisPosition : 0;
    let header = settings.getHeader(dataProperty);
    let isHeaderBold = settings.isHeaderBold(dataProperty) || settings.shouldUseBoldGroupHeaders(chartGroup, viewModel.maximumExpandedGroupLevel);
    if (multiples && chartGroup.group) {
        let yPosition = chartGroup.level * groupHeaderHeight;
        if (chartGroup.isSubtotal) {
            yPosition = viewModel.maximumExpandedGroupLevel * groupHeaderHeight;
        }
        if (settings.shouldShowScenarioSortHeaders(plotter)) {
            let sortableHeader = plotSortableHeader(chartHierarchy, plotter, viewModel, totalWidth, header, currentRow, groupIndex, xPosition, yPosition + groupHeaderHeight + 3, anchor, isHeaderBold, dataProperty, plotOrder, true, width, headerXPosition, false, true, true, true, false, groupIndex, false, chartGroup, false, null, false, chartId);
            sortableHeader.setChartChangeIcons(drawValueIcons, ChartType.ValueChart);
        }
    }
    else if (!multiples) {
        let sortableHeader = plotSortableHeader(chartHierarchy, plotter, viewModel, totalWidth, header, currentRow, null, xPosition, 3, anchor, isHeaderBold, dataProperty, plotOrder, true, width, headerXPosition, false, true, true, true, false, groupIndex, false, chartGroup, false, null, false, chartId);
        if (settings.getRealInteractionSettingValue(settings.allowChartChange) && currentRow === 0) {
            sortableHeader.setChartChangeIcons(drawValueIcons, ChartType.ValueChart);
        }
    }

    let overlappedScenarioToUse = viewModel.HasSecondReferenceColumn ? viewModel.secondReference.scenario : viewModel.reference.scenario;
    if (showingOverlappedChart(viewModel, dataProperty)) {
        let referenceToUse = viewModel.HasSecondReferenceColumn ? DataProperty.SecondReferenceValue : DataProperty.ReferenceValue;
        let overlappedBars = drawing.getShapes(chartArea, `.overlapped${BAR}${chartId}`, `overlapped${BAR}${chartId} ${HIGHLIGHTABLE} ${TOOLTIP}`, RECT, plottableDataPoints.inViewWithoutFormulas);
        overlappedBars
            .attr(HEIGHT, Math.round(yScale.bandwidth()))
            .attr(WIDTH, d => Math.abs(axisPosition - xScale(d.getValue(referenceToUse))))
            .attr(X, d => d.getValue(referenceToUse) < 0 ? xScale(d.getValue(referenceToUse)) : axisPosition)
            .attr(Y, d => Math.round(yScale(d.getCategory(viewModel))) - 3);
        // addMouseHandlers(overlappedBars, selectionManager, svg, viewModel);
        addBlurIfNeeded(overlappedBars, settings);
        // plotter.addTooltips(overlappedBars);
        styles.applyToBars(overlappedBars, getNeutralColor(settings), overlappedScenarioToUse, settings.chartStyle, false, settings.colorScheme);
        if (settings.lightenOverlapped) {
            overlappedBars.attr(STROKE_OPACITY, 0.5);
            let overlappedFillOpacity = isPlanScenario(overlappedScenarioToUse) && overlappedBars.node() !== null ? +overlappedBars.attr(FILL_OPACITY) * 0.5 : 0.5;
            overlappedBars.attr(FILL_OPACITY, overlappedFillOpacity);
        }
    }
    let labelRectangles: d3.Selection<any, DataPoint, SVGElement, DataPoint> = null;
    if (isShowingOverlappedChart) {
        labelRectangles = drawing.getShapes(chartArea, `.${LABEL}${RECTANGLE}`, `${LABEL}${RECTANGLE}`, RECT, plottableDataPoints.inViewWithoutFormulas);
    }

    let bars = drawing.getShapes(chartArea, `.${BAR}${chartId}`, `${BAR}${chartId} ${HIGHLIGHTABLE} ${TOOLTIP}`, RECT, plottableDataPoints.inViewWithoutFormulas);
    bars
        .attr(HEIGHT, Math.round(yScale.bandwidth()))
        .attr(WIDTH, d => Math.abs(axisPosition - xScale(d.getValue(dataProperty))))
        .attr(X, d => d.isNegative(dataProperty) ? xScale(d.getValue(dataProperty)) : axisPosition)
        .attr(Y, d => Math.round(yScale(d.getCategory(viewModel))));
    addMouseHandlers(bars, null, svg, viewModel);
    addBlurIfNeeded(bars, settings);
    // plotter.addTooltips(bars);
    let markerScenario = scenarios.getScenario(dataProperty, viewModel);
    if (isAdditionalMeasure(dataProperty)) {
        markerScenario = getScenarioFromMarkerStyle(settings.getMarkerStyle(dataProperty));
    }
    styles.applyToBars(bars, getNeutralColor(settings), markerScenario, settings.chartStyle, false, settings.colorScheme);

    if (viewModel.HasSecondReferenceColumn && isShowingOverlappedChart) {
        let triangles = chartArea.selectAll(".symbol" + chartId)
            .data(plottableDataPoints.inViewWithoutFormulas);
        let path = triangles.enter().append(PATH);
        triangles.exit().remove();
        triangles = triangles.merge(path);

        triangles
            .attr(D, d3.symbol().type(d3.symbolTriangle).size(45))
            .attr(TRANSFORM, d => `translate(${xScale(d.getValue(DataProperty.ReferenceValue))}, ${Math.round(yScale(d.getCategory(viewModel)))}) rotate(180)`)
            .classed("symbol" + chartId, true)
            .classed(HIGHLIGHTABLE, true)
            .classed(TOOLTIP, true);
        styles.applyToBars(triangles, getNeutralColor(settings), viewModel.reference.scenario, settings.chartStyle, false, settings.colorScheme);
        triangles.attr(STROKE_WIDTH, 1);
        triangles.attr(STROKE, WHITE);
        // plotter.addTooltips(triangles);
    }

    if (settings.showDataLabels) {
        let labels = drawing.getLabels(chartArea, `${LABEL}${chartId}`, plottableDataPoints.inView);
        labels
            .text(d => d.getLabel(dataProperty))
            .style(FONT_SIZE, `${settings.labelFontSize}${FONT_SIZE_UNIT}`)
            .style(TEXT_ANCHOR, d => d.isNegative(dataProperty) ? END : START)
            .style(FONT_STYLE, d => d.italic ? ITALIC : INITIAL)
            .style(FONT_FAMILY, settings.labelFontFamily)
            .style(FONT_WEIGHT, (d: DataPoint) => isHeaderBold ? BOLD : d.getFontWeight())
            .attr(X, d => getLabelXPosition(d, dataProperty, xScale, axisPosition))
            .attr(Y, d => drawing.centerTextVertically(yScale, d.getCategory(viewModel), settings.labelFontSize))
            .attr(FILL, (d: DataPoint) => d.getLabelColor(settings.labelFontColor, dataProperty))
            .classed(FORMULA_ELEMENT, (d: DataPoint) => d.isCurrentlyEditedFormula())
            .classed(HIGHLIGHTABLE, true)
            .classed(TOOLTIP, true);
        // plotter.addTooltips(labels);
        // addMouseHandlers(labels, selectionManager, svg, viewModel);
        addBlurIfNeeded(labels, settings);
        if (isShowingOverlappedChart && !(settings.lightenOverlapped && overlappedScenarioToUse === Scenario.PreviousYear)) {
            let labelsBBs = labels.nodes().map(label => (<any>label).getBBox());
            labelRectangles
                .attr(X, (d, i) => labelsBBs[i].x - 1)
                .attr(Y, (d, i) => labelsBBs[i].y - 1)
                .attr(WIDTH, (d, i) => labelsBBs[i].width + 2)
                .attr(HEIGHT, (d, i) => labelsBBs[i].height + 2)
                .attr(FILL, WHITE)
                .attr(RX, 3)
                .classed(TOOLTIP, true);
            // plotter.addTooltips(labelRectangles);
            labelRectangles.attr(OPACITY, 1 - (settings.labelBackgroundTransparency / 100));
        }
    }
    if (viewModel.HasCommentsColumn) {
        let commentDataPoints: DataPoint[] = [];
        // comment markers with default data property (not being dragged to another data property):
        if (dataProperty === dataPropertyForComments) {
            commentDataPoints.push(...plottableDataPoints.inViewWithoutFormulas.filter(d => d.hasComment() && d.commentMarkerDataProperty === null));
        }
        commentDataPoints.push(...plottableDataPoints.inViewWithoutFormulas.filter(d => d.hasComment()).filter(d => d.commentMarkerDataProperty === dataProperty));
        plotCommentMarkers(chartArea, viewModel, commentDataPoints, xScale, yScale, settings, dataProperty, minMax.getMaxLabelOffset(settings), plotter.categoriesWidth, groupIndex);
        //plotter.addCommentMarkerTooltip(chartArea);
    }
}

function plotCommentMarkers(container: d3.Selection<SVGElement, any, any, any>, viewModel: ViewModel, commentDataPoints: DataPoint[], xScale: d3.ScaleLinear<number, number>, yScale: d3.ScaleBand<string>,
    settings: VarianceSettings, dataProperty: DataProperty, labelMargin: number, categoriesWidth: number, groupIndex: number) {
    let scaleBandWidth = yScale.bandwidth();
    let markerAttrs = getCommentMarkerAttributes(scaleBandWidth);
    let getMarkerYPosition = (d: DataPoint): number => yScale(d.getCategory(viewModel)) + scaleBandWidth / 2;
    let getMarkerXPosition = (d: DataPoint): number => d.getValue(dataProperty) < 0 ?
        xScale(d.getValue(dataProperty)) - (labelMargin + (viewModel.HasReferenceColumn ? markerAttrs.radius : -markerAttrs.radius)) :
        xScale(d.getValue(dataProperty)) + (labelMargin + (viewModel.HasReferenceColumn ? markerAttrs.radius : -markerAttrs.radius));
    addCommentMarkers(container, commentDataPoints, getMarkerXPosition, getMarkerYPosition, markerAttrs.radius, markerAttrs.fontSize, settings.colorScheme.highlightColor, settings.labelFontFamily, viewModel, categoriesWidth, groupIndex);
}

function getLabelXPosition(d: DataPoint, dataProperty: DataProperty, xScale: d3.ScaleLinear<number, number>, axisPosition: number): number {
    if (d.isRowDisplayUnitPercent()) {
        return axisPosition + 5;
    }
    return d.isNegative(dataProperty) ? xScale(d.getValue(dataProperty)) - 5 : xScale(d.getValue(dataProperty)) + 5;
}

export function getMinMax(viewModel: ViewModel, xPosition: number, width: number, dataProperty: DataProperty, chartGroup: ChartGroup): Extreme {
    if (viewModel.settings.plottingHorizontally()) {
        return chartGroup.extremes.getExtreme(dataProperty, viewModel.settings);
    }
    let extreme = viewModel.extremes.getExtreme(dataProperty, viewModel.settings);
    let mm = viewModel.extremes;
    if (showingOverlappedChart(viewModel, dataProperty)) {
        let min = Math.min(0, mm.integratedMin);
        let max = Math.max(0, mm.integratedMax);
        if (viewModel.HasSecondReferenceColumn) {
            min = Math.min(min, mm.secondReferenceValue.min);
            max = Math.max(max, mm.secondReferenceValue.max);
        }
        let minLabelOffset = Math.max(0, extreme.getMinLabelOffset(viewModel.settings));
        let maxLabelOffset = Math.max(0, extreme.getMaxLabelOffset(viewModel.settings));
        if (shouldConsiderReferenceValues(mm, extreme)) {
            let xScale = drawing.getLinearScale(min, max, xPosition, xPosition + width);
            let maximumLabelEnd = xScale(extreme.max) + extreme.maxLabelOffset;
            let maxReferenceValueEnd = xScale(viewModel.extremes.integratedMax);
            // Maximum value label extends past the end of the maximum reference value and we need to add some label offset
            if (max !== 0 && maximumLabelEnd > maxReferenceValueEnd) {
                maxLabelOffset = maximumLabelEnd - maxReferenceValueEnd;
            }
            else {
                if (max === 0) {
                    maxLabelOffset = 0;
                }
                else {
                    maxLabelOffset = 10;
                }
            }
            let minimumLabelStart = xScale(viewModel.extremes.value.min) - viewModel.extremes.value.getMinLabelOffset(viewModel.settings);
            let minReferenceValueStart = xScale(viewModel.extremes.integratedMin);
            // Minimum value label extends past the end of the minimum reference value and we need to add some label offset
            if (min !== 0 && minimumLabelStart < minReferenceValueStart) {
                minLabelOffset = minReferenceValueStart - minimumLabelStart;
            }
            else {
                if (min === 0) {
                    minLabelOffset = 0;
                }
                else {
                    minLabelOffset = 10;
                }
            }
        }
        if (viewModel.HasSecondReferenceColumn && shouldConsiderSecondReferenceValues(mm)) {
            let xScale = drawing.getLinearScale(min, max, xPosition, xPosition + width);
            let maximumLabelEnd = xScale(extreme.max) + extreme.maxLabelOffset;
            let maxSecondReferenceEnd = xScale(viewModel.extremes.secondReferenceValue.max);
            // Maximum value label extends past the end of the maximum reference value and we need to add some label offset
            if (max !== 0 && maximumLabelEnd > maxSecondReferenceEnd) {
                maxLabelOffset = maximumLabelEnd - maxSecondReferenceEnd;
            }
            else {
                if (max === 0) {
                    maxLabelOffset = 0;
                }
                else {
                    maxLabelOffset = 5;
                }
            }
        }
        return new Extreme(min, max, minLabelOffset, maxLabelOffset);
    }
    else {
        return new Extreme(
            Math.min(0, extreme.min),
            Math.max(0, extreme.max),
            Math.max(0, extreme.getMinLabelOffset(viewModel.settings)),
            Math.max(0, extreme.getMaxLabelOffset(viewModel.settings)));
    }
}

function showingOverlappedChart(viewModel: ViewModel, dataProperty: DataProperty): boolean {
    if (isAdditionalMeasure(dataProperty)) {
        return false;
    }
    return viewModel.HasReferenceColumn && viewModel.settings.valueChart === ValueChart.OverlappedBar;
}

function shouldConsiderReferenceValues(mm: Extremes, extreme: Extreme): boolean {
    return mm.integratedMin < extreme.min || mm.integratedMax > extreme.max;
}

function shouldConsiderSecondReferenceValues(mm: Extremes): boolean {
    return mm.secondReferenceValue.min < mm.integratedMin || mm.secondReferenceValue.max > mm.integratedMax;
}