import {
    Scenario, DataProperty, TEXT, FONT_SIZE, FONT_FAMILY, TEXT_ANCHOR, FONT_WEIGHT, PX, BOLD,
    AC, PY, PL, FC, ColumnFormat, X, Y, FILL, FC2, PL2, FC3, PL3, MarkerStyle, FONT_SIZE_UNIT
} from "./library/constants";
import { ChartGroup } from "./charting/chartGroup";
import { TextOptions } from "./definitions";
import { VarianceSettings } from "./settings/varianceSettings";
import { isAdditionalMeasure } from "./library/helpers";

export function getAdditionalMeasurePropertyFromIndex(index: number): DataProperty {
    if (index >= 0 && index < 10) {
        return DataProperty.AdditionalMeasure1 + index;
    }
    else {
        return DataProperty.AdditionalMeasure11 + index - 10;
    }
}

export function getIndexFromAdditionalMeasureDataProperty(dataProperty: DataProperty): number {
    if (dataProperty >= DataProperty.AdditionalMeasure1 && dataProperty <= DataProperty.AdditionalMeasure10) {
        return dataProperty - DataProperty.AdditionalMeasure1;
    }
    else if (dataProperty >= DataProperty.AdditionalMeasure11 && dataProperty <= DataProperty.AdditionalMeasure20) {
        return dataProperty - DataProperty.AdditionalMeasure11 + 10;
    }
    return 0;
}

export function calculateRange(min: number, max: number): number {
    return max - min;
}

export function dayInMiliseconds() {
    return 1000 * 60 * 60 * 24;
}

export function drawTextWithOptions(options: TextOptions): d3.Selection<SVGElement, any, any, any> {
    let text = options
        .container
        .append(TEXT)
        .text(options.text)
        .attr(X, Math.round(options.x))
        .attr(Y, Math.round(options.y + options.expectedHeight * 0.73))
        .attr(FILL, options.color)
        .style(FONT_SIZE, `${options.fontSize}${FONT_SIZE_UNIT}`)
        .style(FONT_FAMILY, options.fontFamily)
        .style(TEXT_ANCHOR, options.textAnchor)
        .classed(options.textClass, true);

    if (options.bold) {
        text.style(FONT_WEIGHT, BOLD);
    }
    return text;
}

export function getHeaderForScenario(scenario: Scenario): string {
    switch (scenario) {
        case Scenario.Actual:
            return AC;
        case Scenario.PreviousYear:
            return PY;
        case Scenario.Plan:
            return PL;
        case Scenario.Plan2:
            return PL2;
        case Scenario.Plan3:
            return PL3;
        case Scenario.Forecast:
            return FC;
        case Scenario.Forecast2:
            return FC2;
        case Scenario.Forecast3:
            return FC3;
    }
}

export function isPlanScenario(scenario: Scenario) {
    return scenario === Scenario.Plan || scenario === Scenario.Plan2 || scenario === Scenario.Plan3;
}

export function isForecastScenario(scenario: Scenario) {
    return scenario === Scenario.Forecast || scenario === Scenario.Forecast2 || scenario === Scenario.Forecast3;
}

export function isVariance(dataProperty: DataProperty): boolean {
    return isFirstComparison(dataProperty) || isSecondComparison(dataProperty) || isThirdComparison(dataProperty) || isFourthComparison(dataProperty) || isFifthComparison(dataProperty) || isSixthComparison(dataProperty) || isSeventhComparison(dataProperty);
}

export function isFirstComparison(dataProperty: DataProperty): boolean {
    return dataProperty === DataProperty.AbsoluteDifference || dataProperty === DataProperty.RelativeDifference;
}

export function isSecondComparison(dataProperty: DataProperty): boolean {
    return dataProperty === DataProperty.SecondAbsoluteDifference || dataProperty === DataProperty.SecondRelativeDifference;
}

export function isThirdComparison(dataProperty: DataProperty): boolean {
    return dataProperty === DataProperty.ThirdAbsoluteDifference || dataProperty === DataProperty.ThirdRelativeDifference;
}

export function isFourthComparison(dataProperty: DataProperty): boolean {
    return dataProperty === DataProperty.FourthAbsoluteDifference || dataProperty === DataProperty.FourthRelativeDifference;
}

export function isFifthComparison(dataProperty: DataProperty): boolean {
    return dataProperty === DataProperty.FifthAbsoluteDifference || dataProperty === DataProperty.FifthRelativeDifference;
}

export function isSixthComparison(dataProperty: DataProperty): boolean {
    return dataProperty === DataProperty.SixthAbsoluteDifference || dataProperty === DataProperty.SixthRelativeDifference;
}

export function isSeventhComparison(dataProperty: DataProperty): boolean {
    return dataProperty === DataProperty.SeventhAbsoluteDifference || dataProperty === DataProperty.SeventhRelativeDifference;
}

export function getCalculationIndexPerComparison(dataProperty: DataProperty): number {
    if (isFirstComparison(dataProperty)) {
        return 0;
    }
    else if (isSecondComparison(dataProperty)) {
        return 1;
    }
    else if (isThirdComparison(dataProperty)) {
        return 2;
    }
    else if (isFourthComparison(dataProperty)) {
        return 3;
    }
    else if (isFifthComparison(dataProperty)) {
        return 4;
    }
    else if (isSixthComparison(dataProperty)) {
        return 5;
    }
    else if (isSeventhComparison(dataProperty)) {
        return 6;
    }
}

export function getParents(group: ChartGroup): ChartGroup[] {
    let parents = [];
    let parent = group.parent;
    while (parent) {
        parents.push(parent);
        parent = parent.parent;
    }
    return parents;
}

export function isAbsoluteDifferenceMeasure(dataProperty: DataProperty): boolean {
    return dataProperty === DataProperty.AbsoluteDifference || dataProperty === DataProperty.SecondAbsoluteDifference || dataProperty === DataProperty.ThirdAbsoluteDifference ||
        dataProperty === DataProperty.FourthAbsoluteDifference || dataProperty === DataProperty.FifthAbsoluteDifference || dataProperty === DataProperty.SixthAbsoluteDifference ||
        dataProperty === DataProperty.SeventhAbsoluteDifference;
}

export function isRelativeMeasure(dataProperty: DataProperty, settings: VarianceSettings): boolean {
    return isRelativeDataProperty(dataProperty) || (isAdditionalMeasure(dataProperty) && settings.getColumnFormat(dataProperty) === ColumnFormat.RelativeDifference);
}

export function isRelativeDataProperty(dataProperty: DataProperty): boolean {
    return dataProperty === DataProperty.RelativeDifference || dataProperty === DataProperty.SecondRelativeDifference || dataProperty === DataProperty.ThirdRelativeDifference ||
        dataProperty === DataProperty.FourthRelativeDifference || dataProperty === DataProperty.FifthRelativeDifference || dataProperty === DataProperty.SixthRelativeDifference ||
        dataProperty === DataProperty.SeventhRelativeDifference;
}

export function getReferenceValueFromRelativeDifference(dataProperty: DataProperty) {
    switch (dataProperty) {
        case DataProperty.RelativeDifference:
            return DataProperty.ReferenceValue;
        case DataProperty.SecondRelativeDifference:
            return DataProperty.SecondReferenceValue;
        case DataProperty.ThirdRelativeDifference:
            return DataProperty.ThirdReferenceValue;
        case DataProperty.FourthRelativeDifference:
            return DataProperty.FourthReferenceValue;
        case DataProperty.FifthRelativeDifference:
            return DataProperty.FifthReferenceValue;
        case DataProperty.SixthRelativeDifference:
            return DataProperty.SixthReferenceValue;
        case DataProperty.SeventhRelativeDifference:
            return DataProperty.SeventhReferenceValue;
    }
}

export function contains<T>(array: T[], element: T): boolean {
    return array.indexOf(element) > -1;
}

export function getScenarioFromMarkerStyle(markerStyle: MarkerStyle): Scenario {
    switch (markerStyle) {
        case MarkerStyle.Actual:
            return Scenario.Actual;
        case MarkerStyle.PreviousYear:
            return Scenario.PreviousYear;
        case MarkerStyle.Plan:
            return Scenario.Plan;
        case MarkerStyle.Plan2:
            return Scenario.Plan2;
        case MarkerStyle.Plan3:
            return Scenario.Plan3;
        case MarkerStyle.Forecast:
            return Scenario.Forecast;
        case MarkerStyle.Forecast2:
            return Scenario.Forecast2;
        case MarkerStyle.Forecast3:
            return Scenario.Forecast3;
        default:
            return Scenario.Actual;
    }
}

export function getFCNumberFromScenario(scenario: Scenario): number {
    if (scenario === Scenario.Forecast2) {
        return 2;
    }
    else if (scenario === Scenario.Forecast3) {
        return 3
    }
    return 1;
}

export function stringifyMap(map: Map<string, any>): string {
    let object = {};
    for (let [key, value] of map) {
        object[key] = value;
    }
    return JSON.stringify(object);
}

export function parseMapFromString<T>(mapAsString: string): Map<string, any> {
    let result = new Map<string, T>();
    if (mapAsString) {
        let object = JSON.parse(mapAsString);
        for (let key of Object.keys(object)) {
            result.set(key, object[key]);
        }
    }
    return result;
}

export function onlyUniqueFilter(value: string, index: number, self: string[]): boolean {
    return self.indexOf(value) === index;
}

export function delay(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
}
