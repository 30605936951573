import { DataView, DataViewHierarchyLevel, PrimitiveValue, DataViewMatrixNode, DataViewMatrixNodeValue, DataViewHierarchy, DataViewMatrix } from "@zebrabi/matrix-data";
import { ViewModel } from "./viewModel";
import { DataPoint } from "./../charting/dataPoint";
import { ChartHierarchy } from "./../charting/chartHierarchy";
import {
    DataProperty, EMPTY, ShowTotals, LogType, ColumnFormat, TopNType, DisplayUnits
} from "./../library/constants";
import { ChartGroup } from "./../charting/chartGroup";
import {
    DataPointType, SortDirection, Value, AbsoluteChart, ICreateGroups, IHierarchical,
    ACTUAL, ACT_ABS_REL, INTEGRATED, ACTUAL_ABSOLUTE, ACTUAL_RELATIVE, ABSOLUTE_RELATIVE, ABSOLUTE, RELATIVE, ValueChart, Formula, FormulaEditMode,
} from "./../definitions";
import * as scenarios from "./scenarios";
import * as helpers from "./../helpers";
import * as calculations from "./../calculations";
import * as matrixViewModelHelpers from "./matrixViewModelHelpers";
import * as formatting from "./../library/formatting";
import * as debug from "./../library/debug";
import * as statistics from "@zebrabi/legacy-library-common/outliersCalculation";
import * as translations from "@zebrabi/legacy-library-common/translations";
import * as viewModelHelpers from "./../library/viewModelHelpers";
import { evaluateFormulas, getFormulaAddedAfterDataPoint as getFormulasFor } from "./formulaHelpers";
import { Visual } from "../visual";
import { VarianceSettings } from "./varianceSettings";
import { getMeasureRole, MeasureRoles } from "@zebrabi/data-helpers/fieldAssignment";

export class VarianceViewModel extends ViewModel {
    private rowHierarchyLevels: DataViewHierarchyLevel[];
    private columnHierarchyLevels: DataViewHierarchyLevel[];
    private grandTotalValues: Value;
    // tslint:disable-next-line: max-func-body-length
    constructor(dv: DataView[], locale: string, showCompanyStyle: boolean, updateSettings?: VarianceSettings) {
        super(showCompanyStyle, updateSettings, dv[0], locale);
        let dataView = dv[0];
        let view = dataView.matrix;
        this.valueSources = view.valueSources;
        this.numberOfAddedFields = view.valueSources.length;
        let settings = this.settings;

        // set dataview roles from settings (switched)
        const useAutoAssignedMeasureRoles = !updateSettings || view.valueSources.length !== updateSettings.usedMeasuresCount;
        if (!useAutoAssignedMeasureRoles) {
            let roles = [];
            roles = updateSettings.measureRoles;
            if (!roles || roles.length === 0 || roles.length !== updateSettings.usedMeasuresCount) {
                // migrate legacy settings
                roles = [];
                for (let i = 1; i <= updateSettings.usedMeasuresCount; i++) {
                    roles.push(updateSettings[`measure${i}Role`]);
                }
            }

            dataView.matrix.valueSources.forEach((col, i) => {
                let role = roles[i]
                if (role) {
                    col.roles = { [role]: true };
                    col.type = role === MeasureRoles.Comments ? { "text": true } : { "numeric": true };
                }
            });
        }
        else {
            // (re)set settings measure roles from auto-assigned roles from data view
            this.settings.chartType = ACT_ABS_REL;
            const dataViewRoles = dataView.metadata.measureRoles ? dataView.metadata.measureRoles :
                dataView.matrix.valueSources.map(vs => getMeasureRole(vs.roles));

            this.settings.measureRoles = dataViewRoles;
            this.settings.usedMeasuresCount = dataViewRoles.length;
        }

        this.numberOfValueFields = dataView.matrix.valueSources.filter(v => v.roles.Values).length;
        this.numberOfTooltipFields = 0; //dataView.matrix.valueSources.filter(v => v.roles.Tooltips).length;
        this.numberOfCommentFields = dataView.matrix.valueSources.filter(v => v.roles.Comments).length;
        this.numberOfPlanFields = matrixViewModelHelpers.getNumberOfFields(dataView.matrix.valueSources, MeasureRoles.Plan);
        this.numberOfForecastFields = matrixViewModelHelpers.getNumberOfFields(dataView.matrix.valueSources, MeasureRoles.Forecast);
        const storedScenarioOptions = useAutoAssignedMeasureRoles || Visual.getInstance().resetPLFCReorder ? null : (updateSettings?.scenarioOptions || updateSettings?.viewModel?.scenarioOptions);
        this.scenarioOptions = scenarios.getScenariosOptionsOffice(dataView, this.numberOfValueFields, settings, this.numberOfTooltipFields, this.numberOfCommentFields, this.numberOfPlanFields, this.numberOfForecastFields, storedScenarioOptions);
        this.settings.scenarioOptions = this.scenarioOptions;
        Visual.getInstance().resetPLFCReorder = false;

        this.value = this.scenarioOptions.value;
        this.reference = this.scenarioOptions.reference;
        this.secondReference = this.scenarioOptions.secondReference;
        this.thirdReference = this.scenarioOptions.thirdReference;
        this.fourthReference = this.scenarioOptions.fourthReference;
        this.fifthReference = this.scenarioOptions.fifthReference;
        this.sixthReference = this.scenarioOptions.sixthReference;
        this.seventhReference = this.scenarioOptions.seventhReference;
        this.additionalMeasures = this.scenarioOptions.additionalMeasures;
        this.tooltips = this.scenarioOptions.tooltips;
        this.comments = this.scenarioOptions.comments;

        settings.setSortColumn();
        settings.isPercentageData = matrixViewModelHelpers.isPercentageDataMatrixDataView(view.valueSources);
        settings.showCommentBox = this.comments.length == 0 ? false : true;
        if (this.numberOfAddedFields === 0) {
            this.plotOnlyCategories = true;
        }
        else if (this.HasValueColumn && !this.HasReferenceColumn && !this.settings.showAsTable) {
            this.settings.chartType = ACTUAL;
        }
        let rows: DataViewHierarchy;
        if (view.rows == null || view.rows.levels[0] == null) {
            rows = null;
        }
        else {
            rows = view.rows;
            if (rows.levels.length > 1) {
                this.hasHierarchy = true;
            }
        }
        let hasCategories = rows == null ? false : true;
        if (hasCategories) {
            this.numberOfLevels = rows.levels.length;
        }
        this.percentageFormat = formatting.getPercentageFormatOrNull(settings.isPercentageData, settings.labelPercentagePointUnit, false, true);
        this.rowHierarchyLevels = view.rows.levels;
        this.columnHierarchyLevels = view.columns.levels;
        this.getGroups(view.columns.root, this.chartGroups, hasCategories);
        this.lastLevelChartGroups = this.chartGroups.getChartGroups();
        this.settings.groupsInColumns = true;
        if (helpers.contains([ACTUAL, ACT_ABS_REL, INTEGRATED, ACTUAL_ABSOLUTE, ACTUAL_RELATIVE, ABSOLUTE_RELATIVE], this.settings.chartType) && this.lastLevelChartGroups.length === 1) {
            // If we only have one chart we want it to use the vertical plotter since it responds to the resizing.
            this.settings.groupsInColumns = false;
        }
        if (helpers.contains([ABSOLUTE, RELATIVE], settings.chartType)) {
            this.settings.groupsInColumns = true;
        }
        settings.parseColumnsSettings();
        settings.parseCategoryFormatSettings();
        if (this.hasHierarchy) {
            this.parseRows(view.rows.root, this.lastLevelChartGroups, this.lastLevelChartGroups, [], view.rows.levels, this.lastLevelChartGroups.length > 1, view);
            if (this.numberOfAddedFields === 1) {
                this.settings.chartType = ACTUAL;
            }
        }
        else {
            let children = view.rows.root.children;
            if (children) {
                let hierarchyIdentity = this.getRowIdentity(children[0].level, view);
                let hierarchies = this.lastLevelChartGroups.map(g => new ChartHierarchy(this, false, false, EMPTY, EMPTY, g, children[0], this.rowHierarchyLevels, children[0].level, g, hierarchyIdentity, false));
                hierarchies.map((h, i) => this.lastLevelChartGroups[i].addHierarchy(h));
                this.parseLastLevelRow(view.rows.root, hierarchies, [], view.rows.levels, this.lastLevelChartGroups.length > 1, view);
            }
        }
        this.maximumExpandedHierarchyLevel = this.chartGroups.getMaximumExpandedLevel();
        if (this.settings.plottingHorizontally() && this.lastLevelChartGroups.length > 1) {
            // go through last level chart groups, map all the datapoints according to their category
            let pointsByCategory: Map<string, DataPoint[]> = new Map();
            this.lastLevelChartGroups.forEach(cg => {
                cg.getAllDataPoints().forEach(dp => {
                    if (pointsByCategory.has(dp.fullCategory)) {
                        pointsByCategory.get(dp.fullCategory).push(dp);
                    } else {
                        pointsByCategory.set(dp.fullCategory, [dp]);
                    }
                });
            });
            // go through all datapoints for every category, see if they are all deletable
            let categoriesToDelete: Map<string, boolean> = new Map();
            pointsByCategory.forEach((dataPoints, category) => {
                categoriesToDelete.set(category, dataPoints.every(dp => !dp.isFormula() && this.shouldSkipDataPoint({
                    value: dp.value, referenceValue: dp.referenceValue,
                    secondReferenceValue: dp.secondReferenceValue, thirdReferenceValue: dp.thirdReferenceValue, fourthReferenceValue: dp.fourthReferenceValue, fifthReferenceValue: dp.fifthReferenceValue,
                    sixthReferenceValue: dp.sixthReferenceValue, seventhReferenceValue: dp.seventhReferenceValue, additionalMeasures: dp.additionalMeasures,
                    tooltipsValues: dp.tooltipsValues,
                    commentsValues: dp.commentsValues,
                }, false)));
            });
            // delete the deletable datapoints
            this.lastLevelChartGroups.forEach(cg => {
                cg.removeDataPointsByCategory(categoriesToDelete);
            });
        }
        this.calculateExtremes();
        let [minOutlierValue, maxOutlierValue] = statistics.outliersCalculation(this.extremes.relativeValues);
        if (settings.limitOutliers && settings.minOutlierValue !== null) {
            minOutlierValue = -1 * Math.abs(settings.minOutlierValue);
        }
        if (settings.limitOutliers && settings.maxOutlierValue !== null) {
            maxOutlierValue = Math.abs(settings.maxOutlierValue);
        }
        if (this.HasValueColumn) {
            let minOutlierCandidates = [];
            let maxOutlierCandidates = [];
            if (this.HasReferenceColumn) {
                minOutlierCandidates.push(this.extremes.relative.min);
                maxOutlierCandidates.push(this.extremes.relative.max);
            }
            if (this.HasSecondReferenceColumn) {
                minOutlierCandidates.push(this.extremes.secondRelative.min);
                maxOutlierCandidates.push(this.extremes.secondRelative.max);
            }
            if (this.HasThirdReferenceColumn) {
                minOutlierCandidates.push(this.extremes.thirdRelative.min);
                maxOutlierCandidates.push(this.extremes.thirdRelative.max);
            }
            if (this.HasFourthReferenceColumn) {
                minOutlierCandidates.push(this.extremes.fourthRelative.min);
                maxOutlierCandidates.push(this.extremes.fourthRelative.max);
            }
            if (this.HasFifthReferenceColumn) {
                minOutlierCandidates.push(this.extremes.fifthRelative.min);
                maxOutlierCandidates.push(this.extremes.fifthRelative.max);
            }
            if (this.HasSixthReferenceColumn) {
                minOutlierCandidates.push(this.extremes.sixthRelative.min);
                maxOutlierCandidates.push(this.extremes.sixthRelative.max);
            }
            if (this.HasSeventhReferenceColumn) {
                minOutlierCandidates.push(this.extremes.seventhRelative.min);
                maxOutlierCandidates.push(this.extremes.seventhRelative.max);
            }
            for (let index = 0; index < this.additionalMeasures.length; index++) {
                let dp = helpers.getAdditionalMeasurePropertyFromIndex(index);
                if (helpers.isRelativeMeasure(dp, this.settings)) {
                    minOutlierCandidates.push(this.extremes.additionalMeasures[index].min);
                    maxOutlierCandidates.push(this.extremes.additionalMeasures[index].max);
                }
            }
            this.minOutlierValue = Math.max(Math.min(...minOutlierCandidates), minOutlierValue);
            this.maxOutlierValue = Math.min(Math.max(...maxOutlierCandidates), maxOutlierValue);
        }
        else {
            this.minOutlierValue = minOutlierValue;
            this.maxOutlierValue = maxOutlierValue;
        }
        if (this.lastLevelChartGroups[0].hasCategories) {
            if (this.hasHierarchy) {
                this.maxCategoryWidth = this.chartGroups.getMaxCategoryWidthByLevel(this);
            }
            else {
                this.maxCategoryWidth = this.chartGroups.getMaxCategoryWidth(this);
            }
        }

        if (this.HasValueColumn && view.valueSources[this.value.index]) {
            this.value.fieldName = view.valueSources[this.value.index].displayName;
        }
        if (this.HasReferenceColumn && view.valueSources[this.reference.index]) {
            this.reference.fieldName = view.valueSources[this.reference.index].displayName;
        }
        if (this.HasSecondReferenceColumn && view.valueSources[this.secondReference.index]) {
            this.secondReference.fieldName = view.valueSources[this.secondReference.index].displayName;
        }
        if (this.HasThirdReferenceColumn && view.valueSources[this.thirdReference.index]) {
            this.thirdReference.fieldName = view.valueSources[this.thirdReference.index].displayName;
        }
        if (this.HasFourthReferenceColumn && view.valueSources[this.fourthReference.index]) {
            this.fourthReference.fieldName = view.valueSources[this.fourthReference.index].displayName;
        }
        if (this.HasFifthReferenceColumn && view.valueSources[this.fifthReference.index]) {
            this.fifthReference.fieldName = view.valueSources[this.fifthReference.index].displayName;
        }
        if (this.HasSixthReferenceColumn && view.valueSources[this.sixthReference.index]) {
            this.sixthReference.fieldName = view.valueSources[this.sixthReference.index].displayName;
        }
        if (this.HasSeventhReferenceColumn && view.valueSources[this.seventhReference.index]) {
            this.seventhReference.fieldName = view.valueSources[this.seventhReference.index].displayName;
        }

        this.setTitle(dv, view);
        if (settings.shouldSort()) {
            this.sort();
        }
        for (let i = 0; i < settings.topNSettings.length; i++) {
            let setting = settings.topNSettings[i];
            if (setting.type != TopNType.Off) {
                if (!this.settings.plottingHorizontally()) {
                    this.chartGroups.setTopN(setting, locale, this.percentageFormat, false);
                } else {
                    this.chartGroups.setTopNByCategory(setting, locale, this.percentageFormat, this.hasHierarchy, this.lastLevelChartGroups, false);
                }
            }
            this.calculateExtremes();
        }
        if (this.lastLevelChartGroups[0].hasCategories) {
            if (this.hasHierarchy) {
                this.maxCategoryWidth = this.chartGroups.getMaxCategoryWidthByLevel(this);
            }
            else {
                let setting = this.settings.getTopNSetting(0);
                this.maxCategoryWidth = setting && setting.type !== TopNType.Off ? this.chartGroups.getMaxCategoryWidthNoOutliers(this) : this.chartGroups.getMaxCategoryWidth(this);
            }
        }
        if (settings.showRowGrandTotal) {
            // trying to get grandTotal dataViewMatrixNode from the dataView, otherwise calculate from the chartGroup
            // TODO - check if the grandTotal subtotal actually ever gets included in the dataView
            let grandTotal = view.rows.root.children[view.rows.root.children.length - 1];
            if (settings.noInvertsOrResultsOrSkips() && settings.allFormulasSkipped() && grandTotal.isSubtotal) {
                if (this.settings.plottingHorizontally()) {
                    for (let i = 0; i < this.lastLevelChartGroups.length; i++) {
                        let value = this.getValues(grandTotal.values, i * this.numberOfAddedFields);
                        this.lastLevelChartGroups[i].addGrandTotalPointFromApi(value, this);
                    }
                }
                else {
                    let emptyGroup: DataViewMatrixNode = {
                        value: EMPTY,
                        children: [],
                        // identity: null,
                    };
                    let total = this.getValues(grandTotal.values, (this.lastLevelChartGroups.length - 1) * this.numberOfAddedFields);
                    let totalGroup = new ChartGroup(hasCategories, emptyGroup, [], EMPTY, this, true, false, false, null, 0, null);
                    this.chartGroups.addGroup(totalGroup);
                    totalGroup.addGrandTotalPoint(total.value, total.referenceValue, total.secondReferenceValue, total.thirdReferenceValue, total.fourthReferenceValue, total.fifthReferenceValue, total.sixthReferenceValue, total.seventhReferenceValue, total.additionalMeasures, total.tooltipsValues);
                    let groupTotalExtreme = totalGroup.calculate(locale, this.percentageFormat);
                    this.extremes = calculations.reduceMinsAndMaxes([this.extremes, groupTotalExtreme]);
                    this.lastLevelChartGroups = this.chartGroups.getChartGroups();
                }
            }
            else {
                if (this.settings.plottingHorizontally()) {
                    this.chartGroups.addGrandTotals(this);
                }
                else {
                    let valueTotal = this.chartGroups.chartGroups.map(g => g.getTotal(DataProperty.Value, true)).reduce((sum, curr) => sum + curr, 0);
                    let referenceTotal = this.chartGroups.chartGroups.map(g => g.getTotal(DataProperty.ReferenceValue, true)).reduce((sum, curr) => sum + curr, 0);
                    let secondReferenceValueTotal = 0;
                    let thirdReferenceValueTotal = 0;
                    let fourthReferenceValueTotal = 0;
                    let fifthReferenceValueTotal = 0;
                    let sixthReferenceValueTotal = 0;
                    let seventhReferenceValueTotal = 0;
                    if (this.HasSecondReferenceColumn) {
                        secondReferenceValueTotal = this.chartGroups.chartGroups.map(g => g.getTotal(DataProperty.SecondReferenceValue, true)).reduce((sum, curr) => sum + curr, 0);
                    }
                    if (this.HasThirdReferenceColumn) {
                        thirdReferenceValueTotal = this.chartGroups.chartGroups.map(g => g.getTotal(DataProperty.ThirdReferenceValue, true)).reduce((sum, curr) => sum + curr, 0);
                    }
                    if (this.HasFourthReferenceColumn) {
                        fourthReferenceValueTotal = this.chartGroups.chartGroups.map(g => g.getTotal(DataProperty.FourthReferenceValue, true)).reduce((sum, curr) => sum + curr, 0);
                    }
                    if (this.HasFifthReferenceColumn) {
                        fifthReferenceValueTotal = this.chartGroups.chartGroups.map(g => g.getTotal(DataProperty.FifthReferenceValue, true)).reduce((sum, curr) => sum + curr, 0);
                    }
                    if (this.HasSixthReferenceColumn) {
                        sixthReferenceValueTotal = this.chartGroups.chartGroups.map(g => g.getTotal(DataProperty.SixthReferenceValue, true)).reduce((sum, curr) => sum + curr, 0);
                    }
                    if (this.HasSeventhReferenceColumn) {
                        seventhReferenceValueTotal = this.chartGroups.chartGroups.map(g => g.getTotal(DataProperty.SeventhReferenceValue, true)).reduce((sum, curr) => sum + curr, 0);
                    }
                    let emptyGroup: DataViewMatrixNode = {
                        value: EMPTY,
                        children: [],
                        // identity: null,
                    };
                    let totalGroup = new ChartGroup(hasCategories, emptyGroup, [], EMPTY, this, true, false, false, null, 0, null);
                    this.chartGroups.addGroup(totalGroup);
                    const additionalMeasures = (this.grandTotalValues?.additionalMeasures || this.additionalMeasures || [])
                        .map((v, ix) => this.chartGroups.chartGroups
                            .map(g => g.getTotal(helpers.getAdditionalMeasurePropertyFromIndex(ix), true))
                            .reduce((sum, curr) => sum + curr, 0));
                    totalGroup.addGrandTotalPoint(valueTotal, referenceTotal, secondReferenceValueTotal, thirdReferenceValueTotal, fourthReferenceValueTotal, fifthReferenceValueTotal, sixthReferenceValueTotal, seventhReferenceValueTotal, additionalMeasures, []);
                    let groupTotalExtreme = totalGroup.calculate(locale, this.percentageFormat);
                    this.extremes = calculations.reduceMinsAndMaxes([this.extremes, groupTotalExtreme]);
                    this.lastLevelChartGroups = this.chartGroups.getChartGroups();
                }
            }
        }

        this.calculateCumulativeExtremes();
        if (!this.hasHierarchy) {
            if (settings.absoluteChart === AbsoluteChart.CalculationWaterfall) {
                settings.absoluteChart = AbsoluteChart.Waterfall;
            }
            if (settings.valueChart === ValueChart.CalculationWaterfall) {
                settings.valueChart = ValueChart.Waterfall;
            }
        }
        this.lastLevelChartGroups.forEach(g => g.setAllHierarchies());
        this.maximumExpandedGroupLevel = this.chartGroups.getMaxExpandedGroupLevel();

        this.logging();
    }

    private recalculateSubtotals() {
        this.chartGroups.recalculateSubtotals();
    }

    private logPositions() {
        console.log({
            "positions": [
                { "value 1 position:": this.settings.valuePosition },
                { "value 2 position:": this.settings.secondValuePosition },
                { "value 3 position:": this.settings.thirdValuePosition },
                { "value 4 position:": this.settings.fourthValuePosition },
                { "value 5 position:": this.settings.fifthValuePosition },
                { "value 6 position:": this.settings.sixthValuePosition },
                { "value 7 position:": this.settings.seventhValuePosition },
                { "reference 1 position:": this.settings.referencePosition },
                { "reference 2 position:": this.settings.secondReferencePosition },
                { "reference 3 position:": this.settings.thirdReferencePosition },
                { "reference 4 position:": this.settings.fourthReferencePosition },
                { "reference 5 position:": this.settings.fifthReferencePosition },
                { "reference 6 position:": this.settings.sixthReferencePosition },
                { "reference 7 position:": this.settings.seventhReferencePosition },
            ]
        })
    }

    private getGroups(node: DataViewMatrixNode, parent: ICreateGroups, hasCategories: boolean) {
        if (node && node.children && node.children.length > 0 && (node.children[0].value !== undefined || node.children[0].value === null)) {
            node.children.forEach(n => {
                this.numberOfGroupLevels = Math.max(n.level + 1, this.numberOfGroupLevels);
                let isColumnGrandTotal = n.level === 0 && n.isSubtotal === true;
                let isSubtotal = n.level !== 0 && n.isSubtotal === true;
                let label = "";
                if (isColumnGrandTotal) {
                    label = this.settings.columnGrandTotalLabel;
                }
                else if (isSubtotal) {
                    label = this.settings.getColumnTotalLabel(node.value == null ? "" : node.value.toString());
                }
                let group = parent.createGroup(hasCategories, n, this.columnHierarchyLevels, false, isColumnGrandTotal, n.level, label);
                if (n.children && n.children.length > 0 && n.children[0].value !== undefined) {
                    this.getGroups(n, group, hasCategories);
                }
            });
        }
        else {
            let emptyGroup: DataViewMatrixNode = {
                value: EMPTY,
                children: [],
                // identity: null,
            };
            parent.createGroup(hasCategories, emptyGroup, [], false, false, 0, this.settings.rowGrandTotalLabel);
        }
    }

    private parseRows(row: DataViewMatrixNode, chartGroups: ChartGroup[], hierarchyHolders: IHierarchical[], parentCategoriesArray: string[],
        levels: DataViewHierarchyLevel[], multipleCharts: boolean, view: DataViewMatrix) {
        if (row.children && row.children[0].children) {
            let children = row.children;
            for (let i = 0; i < children.length; i++) {
                let child = children[i];
                let hierarchyIdentity = this.getRowIdentity(child.level, view);
                let hierarchies: ChartHierarchy[] = [];
                for (let j = 0; j < hierarchyHolders.length; j++) {
                    let hierarchyHolder = hierarchyHolders[j];
                    let values = child.values;
                    // If we have a subtotal we have to add its values to the parent total dataPoint.
                    if (child.isSubtotal && values) {
                        let totalValues = this.getValues(values, j * this.numberOfAddedFields);
                        if (child.level === 0) {
                            this.grandTotalValues = totalValues;
                        }
                        else if ((<ChartHierarchy>hierarchyHolder).addTotalDataPointValues && this.settings.showTotals !== ShowTotals.AboveHideValues) {
                            (<ChartHierarchy>hierarchyHolder).addTotalDataPointValues(totalValues.value, totalValues.referenceValue, totalValues.secondReferenceValue, totalValues.thirdReferenceValue, totalValues.fourthReferenceValue, totalValues.fifthReferenceValue, totalValues.sixthReferenceValue, totalValues.seventhReferenceValue, totalValues.additionalMeasures, totalValues.tooltipsValues);
                        }
                    }
                    else {
                        let category = this.getCategoryValue(levels, child.level, child.value);
                        let parentCategories = parentCategoriesArray.join(" ");
                        let fullCategory = `${parentCategories} ${category}`;
                        fullCategory = fullCategory.trim();
                        let parentGroup = hierarchyHolder;
                        while (parentGroup && !(parentGroup instanceof ChartGroup)) {
                            parentGroup = parentGroup.parent;
                        }
                        let hierarchy = new ChartHierarchy(this, this.hasHierarchy, false, category, fullCategory, hierarchyHolder, child, this.rowHierarchyLevels, child.level, <ChartGroup>parentGroup, hierarchyIdentity, false);
                        hierarchyHolder.addHierarchy(hierarchy);
                        hierarchies.push(hierarchy);
                        if (this.settings.shouldAddToExpressionElements(category, parentCategories, hierarchyIdentity)) {
                            this.settings.addExpressionElement(hierarchy.firstGroupParent.fullGroup, parentCategories, hierarchyIdentity, hierarchy.totalDataPoint);
                        }
                        this.addFormulasBelowHierarchy(hierarchy, hierarchyHolder, parentCategories, child, <ChartGroup>parentGroup, hierarchyIdentity);
                    }
                }
                this.parseRows(child, chartGroups, hierarchies, [...parentCategoriesArray, <string>child.value], levels, multipleCharts, view);
            }
        }
        else {
            this.parseLastLevelRow(row, hierarchyHolders, parentCategoriesArray, levels, multipleCharts, view);
        }
    }

    private addFormulasBelowHierarchy(hierarchy: ChartHierarchy, parent: IHierarchical, parentCategories: string, child: DataViewMatrixNode, parentGroup: ChartGroup, hierarchyIdentity: string) {
        let dataPoint = hierarchy.totalDataPoint;
        if (Visual.formulaEditMode === FormulaEditMode.Add) {
            if (Visual.formulaEditPosition && Visual.formulaEditPosition.fullCategory === dataPoint.fullCategory) {
                let hierarchy = new ChartHierarchy(this, this.hasHierarchy, false, EMPTY, EMPTY, parent, child, this.rowHierarchyLevels, child.level, <ChartGroup>parentGroup, hierarchyIdentity, true);
                parent.addHierarchy(hierarchy);
            }
        }
        let formulas = getFormulasFor(dataPoint, this.settings.formulaCalculation.formulas, dataPoint.hierarchyIdentity);
        if (formulas.length) {
            formulas.forEach(formula => {
                let formulaHierarchy = new ChartHierarchy(this, this.hasHierarchy, false, formula.identity, `${parentCategories} ${formula.identity}`, parent, child, this.rowHierarchyLevels, child.level, <ChartGroup>parentGroup, hierarchyIdentity, true);
                formulaHierarchy.addDataPoint(formulaHierarchy.totalDataPoint);
                parent.addHierarchy(formulaHierarchy);
                this.addIdentityPoint(formulaHierarchy.firstGroupParent.fullGroup, parentCategories, formula, formulaHierarchy.totalDataPoint);
                if (this.settings.shouldAddToExpressionElements(formula.identity, parentCategories, hierarchyIdentity)) {
                    this.settings.addExpressionElement(hierarchy.firstGroupParent.fullGroup, parentCategories, hierarchyIdentity, formulaHierarchy.totalDataPoint);
                }
                this.addFormulasBelowHierarchy(formulaHierarchy, parent, parentCategories, child, <ChartGroup>parentGroup, hierarchyIdentity);
            });
        }

    }

    private getValues(values: DataViewMatrixNodeValue, index: number): Value {
        let additionalMeasures: number[] = [];
        let value = null;
        if (this.HasValueColumn) {
            if (values[index + this.value.index]) {
                value = this.getNumberOrNull(values[index + this.value.index].value);
            }
        }
        for (let i = 0; i < this.additionalMeasures.length; i++) {
            if (values[index + this.additionalMeasures[i].index]) {
                additionalMeasures.push(this.getNumberOrNull(values[index + this.additionalMeasures[i].index].value));
            }
        }
        let referenceValue = null;
        let secondReferenceValue = null;
        let thirdReferenceValue = null;
        let fourthReferenceValue = null;
        let fifthReferenceValue = null;
        let sixthReferenceValue = null;
        let seventhReferenceValue = null;

        let tooltipsValues = [];
        let commentsValues = [];
        if (this.HasReferenceColumn && values[index + this.reference.index]) {
            referenceValue = this.getNumberOrNull(values[index + this.reference.index].value);
        }
        if (this.HasSecondReferenceColumn && values[index + this.secondReference.index]) {
            secondReferenceValue = this.getNumberOrNull(values[index + this.secondReference.index].value);
        }
        if (this.HasThirdReferenceColumn && values[index + this.thirdReference.index]) {
            thirdReferenceValue = this.getNumberOrNull(values[index + this.thirdReference.index].value);
        }
        if (this.HasFourthReferenceColumn && values[index + this.fourthReference.index]) {
            fourthReferenceValue = this.getNumberOrNull(values[index + this.fourthReference.index].value);
        }
        if (this.HasFifthReferenceColumn && values[index + this.fifthReference.index]) {
            fifthReferenceValue = this.getNumberOrNull(values[index + this.fifthReference.index].value);
        }
        if (this.HasSixthReferenceColumn && values[index + this.sixthReference.index]) {
            sixthReferenceValue = this.getNumberOrNull(values[index + this.sixthReference.index].value);
        }
        if (this.HasSeventhReferenceColumn && values[index + this.seventhReference.index]) {
            seventhReferenceValue = this.getNumberOrNull(values[index + this.seventhReference.index].value);
        }
        for (let i = 0; i < this.tooltips.length; i++) {
            if (values[index + this.tooltips[i].index]) {
                tooltipsValues.push(values[index + this.tooltips[i].index].value)
            }
        }
        for (let i = 0; i < this.comments.length; i++) {
            if (values[index + this.comments[i].index]) {
                commentsValues.push(values[index + this.comments[i].index].value);
            }
        }
        return {
            value: value,
            referenceValue: referenceValue,
            secondReferenceValue: secondReferenceValue,
            thirdReferenceValue: thirdReferenceValue,
            fourthReferenceValue: fourthReferenceValue,
            fifthReferenceValue: fifthReferenceValue,
            sixthReferenceValue: sixthReferenceValue,
            seventhReferenceValue: seventhReferenceValue,
            additionalMeasures: additionalMeasures,
            tooltipsValues: tooltipsValues,
            commentsValues: commentsValues,
        };
    }

    private parseLastLevelRow(row: DataViewMatrixNode, hierarchyHolders: IHierarchical[], parentCategoriesArray: string[],
        levels: DataViewHierarchyLevel[], multipleCharts: boolean, view: DataViewMatrix) {
        if (!row.children) {
            return;
        }
        let children = row.children;
        let hierarchies = <ChartHierarchy[]>hierarchyHolders;
        for (let i = 0; i < children.length; i++) {
            let child = children[i];
            let hierarchyIdentity = this.getRowIdentity(child.level, view);
            let values = child.values;
            if (values) {
                let totalValues = Object.keys(values).length;
                let currentHierarchyIndex = 0;
                for (let j = 0; j < totalValues; j = j + this.numberOfAddedFields) {
                    let v = this.getValues(values, j);
                    if (this.shouldSkipDataPoint(v, multipleCharts)) {
                        currentHierarchyIndex++;
                        continue;
                    }
                    let currentHierarchy = hierarchies[currentHierarchyIndex++];
                    if (child.isSubtotal) {
                        if (child.level === 0) {
                            this.grandTotalValues = v;
                        }
                        else if (this.settings.showTotals !== ShowTotals.AboveHideValues) {
                            currentHierarchy.addTotalDataPointValues(v.value, v.referenceValue, v.secondReferenceValue, v.thirdReferenceValue, v.fourthReferenceValue, v.fifthReferenceValue, v.sixthReferenceValue, v.seventhReferenceValue, v.additionalMeasures, v.tooltipsValues);
                        }
                        continue;
                    }
                    let selectionId = null;
                    let category: string = EMPTY;
                    let fullCategory: string = EMPTY;
                    let parentCategories: string = EMPTY;
                    category = this.getCategoryValue(levels, child.level, child.value);
                    category = category == null ? EMPTY : category.toString();
                    parentCategories = parentCategoriesArray.join(" ");
                    fullCategory = `${parentCategories} ${category}`;
                    fullCategory = fullCategory.trim();
                    //let parents = currentHierarchy ? [currentHierarchy, ...currentHierarchy.getParents()] : [];
                    // selectionId = matrixViewModelHelpers.getSelectionId(child, this.rowHierarchyLevels, parents, this.host);
                    // let measureSelectionId = this.settings.enableMeasureDrillThrough ? matrixViewModelHelpers.getSelectionId(child, this.rowHierarchyLevels, parents, this.host, view.valueSources[0].queryName) : null;
                    let dataPoint: DataPoint;
                    if (this.settings.isFlatResult(category) && this.allowFlatResults()) {
                        dataPoint = new DataPoint(v.value, v.referenceValue, v.secondReferenceValue, v.thirdReferenceValue, v.fourthReferenceValue, v.fifthReferenceValue, v.sixthReferenceValue, v.seventhReferenceValue, v.additionalMeasures, v.tooltipsValues, v.commentsValues, category, fullCategory, selectionId, null, DataPointType.FlatResult, this, child.level, currentHierarchy, hierarchyIdentity);
                    }
                    else {
                        dataPoint = new DataPoint(v.value, v.referenceValue, v.secondReferenceValue, v.thirdReferenceValue, v.fourthReferenceValue, v.fifthReferenceValue, v.sixthReferenceValue, v.seventhReferenceValue, v.additionalMeasures, v.tooltipsValues, v.commentsValues, category, fullCategory, selectionId, null, DataPointType.Normal, this, child.level, currentHierarchy, hierarchyIdentity);
                    }
                    currentHierarchy.addDataPoint(dataPoint);
                    if (this.settings.shouldAddToExpressionElements(category, parentCategories, hierarchyIdentity)) {
                        this.settings.addExpressionElement(currentHierarchy.firstGroupParent.fullGroup, parentCategories, hierarchyIdentity, dataPoint);
                    }
                    this.addFormulasBelowDataPoint(dataPoint, parentCategories, currentHierarchy, child.level);
                }
            }
            else {
                for (let i = 0; i < hierarchies.length; i++) {
                    let currentHierarchy = hierarchies[i];
                    let category = EMPTY;
                    let fullCategory = EMPTY;
                    if (child.value) {
                        category = this.getCategoryValue(levels, child.level, child.value);
                        if (category == null) {
                            category = EMPTY;
                        }
                        fullCategory = parentCategoriesArray.join(" ") + " " + category;
                        fullCategory = fullCategory.trim();
                    }
                    let dataPoint = new DataPoint(null, null, null, null, null, null, null, null, [], [], [], category, fullCategory, null, null, DataPointType.Normal, this, child.level, hierarchies[0], hierarchyIdentity);
                    currentHierarchy.addDataPoint(dataPoint);
                }
            }
        }
    }

    private addFormulasBelowDataPoint(dataPoint: DataPoint, parentCategories: string, currentHierarchy: ChartHierarchy, childLevel: number) {
        if (Visual.formulaEditMode === FormulaEditMode.Add) {
            if (Visual.formulaEditPosition && Visual.formulaEditPosition.fullCategory === dataPoint.fullCategory) {
                let dp = new DataPoint(null, null, null, null, null, null, null, null, [], [], [], EMPTY, null, null, null, DataPointType.Formula, this, childLevel, currentHierarchy, dataPoint.hierarchyIdentity);
                currentHierarchy.addDataPoint(dp)
            }
        }
        let formulas = getFormulasFor(dataPoint, this.settings.formulaCalculation.formulas, dataPoint.hierarchyIdentity);
        if (formulas.length) {
            formulas.forEach(formula => {
                let formulaDataPoint = new DataPoint(null, null, null, null, null, null, null, null, [], [], [], formula.identity, formula.identity, null, null, DataPointType.Formula, this, childLevel, currentHierarchy, dataPoint.hierarchyIdentity);
                currentHierarchy.addDataPoint(formulaDataPoint)
                this.addIdentityPoint(currentHierarchy.firstGroupParent.fullGroup, parentCategories, formula, formulaDataPoint);
                if (this.settings.shouldAddToExpressionElements(formula.identity, parentCategories, dataPoint.hierarchyIdentity)) {
                    this.settings.addExpressionElement(currentHierarchy.firstGroupParent.fullGroup, parentCategories, dataPoint.hierarchyIdentity, formulaDataPoint);
                }
                this.addFormulasBelowDataPoint(formulaDataPoint, parentCategories, currentHierarchy, childLevel);
            });
        }
    }

    private addIdentityPoint(groupName: string, parentCategories: string, formula: Formula, dp: DataPoint) {
        let identityPoints = this.settings.formulaCalculation.identityDataPoints;
        let hierarchyIdentityPoints = identityPoints.get(dp.hierarchyIdentity);
        if (!hierarchyIdentityPoints) {
            hierarchyIdentityPoints = new Map<string, Map<string, Map<string, DataPoint>>>();
            identityPoints.set(dp.hierarchyIdentity, hierarchyIdentityPoints);
        }
        let groupIdentityPoints = hierarchyIdentityPoints.get(groupName);
        if (!groupIdentityPoints) {
            groupIdentityPoints = new Map<string, Map<string, DataPoint>>();
            hierarchyIdentityPoints.set(groupName, groupIdentityPoints);
        }

        let parentCategoryIdentityPoints = groupIdentityPoints.get(parentCategories);
        if (!parentCategoryIdentityPoints) {
            parentCategoryIdentityPoints = new Map<string, DataPoint>();
            groupIdentityPoints.set(parentCategories, parentCategoryIdentityPoints);
        }
        parentCategoryIdentityPoints.set(formula.identity, dp);
    }

    public calculateExtremes() {
        this.extremes = this.chartGroups.calculate(this.locale, this.percentageFormat);
        // Repeated line in order to fix bugs related to order of formula evaluation (formulas referencing other not yet evaluated formulas). A more optimal fix and cycles should be considered in future.
        evaluateFormulas(this.settings.formulaCalculation, this);
        evaluateFormulas(this.settings.formulaCalculation, this);
        // Recalculate subtotals, which must account for child formula datapoints
        if (!this.settings.allFormulasSkipped()) {
            this.recalculateSubtotals();
        }
        this.extremes = this.chartGroups.calculate(this.locale, this.percentageFormat);
        this.settings.formulaCalculation.identityDataPoints.forEach(groupIdentity => {
            groupIdentity.forEach(hierarchyIdentity => {
                hierarchyIdentity.forEach(parents => {
                    parents.forEach(formulaDataPoint => {
                        let extremes = formulaDataPoint.calculate(this.locale, this.percentageFormat);
                        this.extremes = calculations.reduceMinsAndMaxes([this.extremes, extremes]);
                        formulaDataPoint.parent.firstGroupParent.extremes = calculations.reduceMinsAndMaxes([formulaDataPoint.parent.firstGroupParent.extremes, extremes]);
                    });
                });
            });
        });
    }

    public calculateCumulativeExtremes() {
        let cmmo = this.chartGroups.calculateCumulative(this.locale, this.percentageFormat, this.maximumExpandedHierarchyLevel, this.maximumExpandedGroupLevel);
        this.extremes.valueCumulative = cmmo.value;
        this.extremes.referenceValueCumulative = cmmo.referenceValue;
        this.extremes.secondReferenceValueCumulative = cmmo.secondReferenceValue;
        this.extremes.thirdReferenceValueCumulative = cmmo.thirdReferenceValue;
        this.extremes.fourthReferenceValueCumulative = cmmo.fourthReferenceValue;
        this.extremes.fifthReferenceValueCumulative = cmmo.fifthReferenceValue;
        this.extremes.sixthReferenceValueCumulative = cmmo.sixthReferenceValue;
        this.extremes.seventhReferenceValueCumulative = cmmo.seventhReferenceValue;
        this.extremes.absoluteCumulative = cmmo.absolute;
        this.extremes.secondAbsoluteCumulative = cmmo.secondAbsolute;
        this.extremes.thirdAbsoluteCumulative = cmmo.thirdAbsolute;
        this.extremes.fourthAbsoluteCumulative = cmmo.fourthAbsolute;
        this.extremes.fifthAbsoluteCumulative = cmmo.fifthAbsolute;
        this.extremes.sixthAbsoluteCumulative = cmmo.sixthAbsolute;
        this.extremes.seventhAbsoluteCumulative = cmmo.seventhAbsolute;
    }

    // tslint:disable-next-line: max-func-body-length
    private setTitle(dv: DataView[], view: DataViewMatrix) {
        // get all measures's display names and their dataProperties - but only additional measures actually
        let measures = [];
        let offset = 0;
        if (this.HasValueColumn) {
            if (!this.settings.suppressEmptyColumns || !this.emptyColumn[DataProperty.Value]) {
                measures.push(new Object({ name: this.value.fieldName, dataProperty: DataProperty.Value }));
            }
            offset = 1;
        }
        if (this.HasReferenceColumn) {
            if (!this.settings.suppressEmptyColumns || !this.emptyColumn[DataProperty.ReferenceValue]) {
                measures.push(new Object({ name: this.reference.fieldName, dataProperty: DataProperty.ReferenceValue }));
            }
            offset = 1;
        }
        if (this.HasSecondReferenceColumn) {
            if (!this.settings.suppressEmptyColumns || !this.emptyColumn[DataProperty.SecondReferenceValue]) {
                measures.push(new Object({ name: this.secondReference.fieldName, dataProperty: DataProperty.SecondReferenceValue }));
            }
            offset = 1;
        }
        if (this.HasThirdReferenceColumn) {
            if (!this.settings.suppressEmptyColumns || !this.emptyColumn[DataProperty.ThirdReferenceValue]) {
                measures.push(new Object({ name: this.thirdReference.fieldName, dataProperty: DataProperty.ThirdReferenceValue }));
            }
            offset = 1;
        }
        if (this.HasFourthReferenceColumn) {
            if (!this.settings.suppressEmptyColumns || !this.emptyColumn[DataProperty.FourthReferenceValue]) {
                measures.push(new Object({ name: this.fourthReference.fieldName, dataProperty: DataProperty.FourthReferenceValue }));
            }
            offset = 1;
        }
        if (this.HasFifthReferenceColumn) {
            if (!this.settings.suppressEmptyColumns || !this.emptyColumn[DataProperty.FifthReferenceValue]) {
                measures.push(new Object({ name: this.fifthReference.fieldName, dataProperty: DataProperty.FifthReferenceValue }));
            }
            offset = 1;
        }
        if (this.HasSixthReferenceColumn) {
            if (!this.settings.suppressEmptyColumns || !this.emptyColumn[DataProperty.SixthReferenceValue]) {
                measures.push(new Object({ name: this.sixthReference.fieldName, dataProperty: DataProperty.SixthReferenceValue }));
            }
            offset = 1;
        }
        if (this.HasSeventhReferenceColumn) {
            if (!this.settings.suppressEmptyColumns || !this.emptyColumn[DataProperty.SeventhReferenceValue]) {
                measures.push(new Object({ name: this.seventhReference.fieldName, dataProperty: DataProperty.SeventhReferenceValue }));
            }
            offset = 1;
        }

        if (this.HasAdditionalMeasures) {
            this.additionalMeasures.forEach((am, index) => {
                let dataProperty = helpers.getAdditionalMeasurePropertyFromIndex(index);
                if (!this.settings.suppressEmptyColumns || !this.emptyColumn[dataProperty]) {
                    measures.push(new Object({ name: am.fieldName, dataProperty: dataProperty }));
                }
            });
        }
        let baseMeasures = measures.filter(m => m["dataProperty"] === DataProperty.Value || m["dataProperty"] === DataProperty.ReferenceValue ||
            m["dataProperty"] === DataProperty.SecondReferenceValue || m["dataProperty"] === DataProperty.ThirdReferenceValue || m["dataProperty"] === DataProperty.FourthReferenceValue ||
            m["dataProperty"] === DataProperty.FifthReferenceValue || m["dataProperty"] === DataProperty.SixthReferenceValue || m["dataProperty"] === DataProperty.SeventhReferenceValue);
        measures = measures.filter(m => baseMeasures.indexOf(m) === -1);

        //create maps for all units - for additional measures and base measures seperatly
        let options: string[] = [DisplayUnits.Auto, DisplayUnits.Billions, DisplayUnits.Millions, DisplayUnits.Thousands, DisplayUnits.None, DisplayUnits.Percent];
        let unitMap: Map<string, string[]> = new Map();
        let baseUnitMap: Map<string, string[]> = new Map();
        options.forEach(o => {
            unitMap.set(o, []);
            baseUnitMap.set(o, []);
        })

        baseMeasures.forEach(measure => {
            let key = this.settings.getColumnDisplayUnits(measure["dataProperty"]);
            let columnFormat = this.settings.getColumnFormat(measure["dataProperty"]);
            if (columnFormat === ColumnFormat.Percent || columnFormat === ColumnFormat.RelativeDifference) {
                baseUnitMap.get(options[5]).push(measure["name"]);
            } else {
                baseUnitMap.get(key).push(measure["name"]);
            }
        })

        measures.forEach(measure => {
            let key = this.settings.getColumnDisplayUnits(measure["dataProperty"]);
            let columnFormat = this.settings.getColumnFormat(measure["dataProperty"]);
            if (columnFormat === ColumnFormat.Percent || columnFormat === ColumnFormat.RelativeDifference) {
                unitMap.get(options[5]).push(measure["name"]);
            } else {
                unitMap.get(key).push(measure["name"]);
            }
        })

        if (this.numberOfAddedFields === 0) {
            this.title = "";
        }
        else if (this.numberOfAddedFields === 1) {
            this.title = view.valueSources[0].displayName;
        }
        else {
            this.title = "";
            let baseCounter = 0;
            for (let i = 0; i < Array.from(baseUnitMap.keys()).length; i++) {
                let key = Array.from(baseUnitMap.keys())[i];
                let valuesInCurrentUnit = baseUnitMap.get(key);
                for (let j = 0; j < valuesInCurrentUnit.length; j++) {
                    this.title += valuesInCurrentUnit[j];
                    //Add  " (in UNIT)"
                    if (j === valuesInCurrentUnit.length - 1 && this.settings.shouldShowUnitsInTitle()) {
                        if (unitMap.get(key).length) {
                            // replace additional measures map with a new map with correctly sorted keys
                            unitMap = new Map(Array.from(unitMap).sort((a, b) =>
                                a[0] === key ? -1 : 1
                            ));
                        } else {
                            let unit = key;
                            if (unit === DisplayUnits.Billions) { unit = "bn"; }
                            if (unit === DisplayUnits.Percent) { unit = "%"; }
                            if (unit !== DisplayUnits.Auto && unit !== DisplayUnits.None) {
                                this.title += ` (${translations.getTranslatedInString(this.locale)} ${unit})`;
                            }
                        }
                    }
                    //Add  " and "
                    if (baseCounter < baseMeasures.length - 1 || measures.length) {
                        if (this.numberOfAddedFields > 2) {
                            this.title += `, `;
                        } else {
                            this.title += ` ${translations.getTranslatedAndString(this.locale)} `;
                        }
                    }
                    //Increment count of added measures to title
                    baseCounter++;
                }
            }

            let counter = 0;
            for (let i = 0; i < Array.from(unitMap.keys()).length; i++) {
                let key = Array.from(unitMap.keys())[i];
                let valuesInCurrentUnit = unitMap.get(key);
                for (let j = 0; j < valuesInCurrentUnit.length; j++) {
                    this.title += valuesInCurrentUnit[j];
                    //Add  " (in UNIT)"
                    if (j === valuesInCurrentUnit.length - 1 && this.settings.shouldShowUnitsInTitle()) {
                        let unit = key;
                        if (unit === DisplayUnits.Billions) { unit = "bn"; }
                        if (unit === DisplayUnits.Percent) { unit = "%"; }
                        if (unit !== DisplayUnits.Auto && unit !== "None") {
                            this.title += ` (${translations.getTranslatedInString(this.locale)} ${unit})`;
                        }
                    }
                    //Add  " and "
                    if (counter < measures.length - 1) {
                        if (this.numberOfAddedFields > 2) {
                            this.title += `, `;
                        } else {
                            this.title += ` ${translations.getTranslatedAndString(this.locale)} `;
                        }
                    }
                    //Increment count of added measures to title
                    counter++;
                }
            }
        }
        this.title += viewModelHelpers.getCategoriesAndGroups(dv, this.locale);
    }

    private shouldSkipDataPoint(v: Value, multipleCharts: boolean): boolean {
        // For horizontally plotted charts we should not suppress nulls or zeroes, since they are all displayed in a single row.
        // If we did suppress the values the categories would not align, since categories are only displayed once (some charts would have more categories than others)
        if (this.settings.plottingHorizontally() && multipleCharts) {
            return false;
        }
        if (this.settings.suppressNulls && v.value == null && v.referenceValue == null && v.secondReferenceValue == null && v.thirdReferenceValue == null &&
            v.fourthReferenceValue == null && v.fifthReferenceValue == null && v.sixthReferenceValue == null && v.seventhReferenceValue == null && this.allAdditionalMeasuresAreNull(v.additionalMeasures)) {
            return true;
        }
        else if (this.settings.suppressZeros &&
            (v.value === 0 || v.value == null) &&
            (v.referenceValue === 0 || v.referenceValue == null) &&
            (v.secondReferenceValue === 0 || v.secondReferenceValue == null) &&
            (v.thirdReferenceValue === 0 || v.thirdReferenceValue == null) &&
            (v.fourthReferenceValue === 0 || v.fourthReferenceValue == null) &&
            (v.fifthReferenceValue === 0 || v.fifthReferenceValue == null) &&
            (v.sixthReferenceValue === 0 || v.sixthReferenceValue == null) &&
            (v.seventhReferenceValue === 0 || v.seventhReferenceValue == null) &&
            this.allAdditionalMeasuresAreNullOrZero(v.additionalMeasures)) {
            return true;
        }
        return false;
    }

    private allAdditionalMeasuresAreNull(additionalMeasures: number[]): boolean {
        for (let i = 0; i < additionalMeasures.length; i++) {
            if (additionalMeasures[i] !== null) {
                return false;
            }
        }
        return true;
    }

    private allAdditionalMeasuresAreNullOrZero(additionalMeasures: number[]): boolean {
        for (let i = 0; i < additionalMeasures.length; i++) {
            if (additionalMeasures[i] !== null || additionalMeasures[i] !== 0) {
                return false;
            }
        }
        return true;
    }

    private sort() {
        if (!this.settings.sortColumnName) {
            return;
        }
        let settings = this.settings.columnSettings.get(this.settings.sortColumnName)
        if (!settings) {
            return;
        }
        let dataProperty = this.settings.getDataPropertyFromColumnSettings(settings);
        if (this.settings.plottingHorizontally()) {
            if (this.settings.categorySort !== SortDirection.None) {
                let referenceIndex = this.settings.sortReferenceChart;
                // If the reference index is null it means we have to sort the multiples based on the summing the values per category
                if (referenceIndex == null) {
                    this.chartGroups.sortByCategory(this.settings.categorySort, dataProperty, this.hasHierarchy, this.lastLevelChartGroups);
                }
                else {
                    this.chartGroups.sortByGroup(this.settings.chartSort, this.settings.categorySort, dataProperty, referenceIndex, this.hasHierarchy, this.lastLevelChartGroups);
                }
            }
        }
        else {
            this.chartGroups.sort(this.settings.chartSort, this.settings.categorySort, dataProperty);
        }
    }

    private getCategoryValue(levels: DataViewHierarchyLevel[], childLevel: number, childValue: PrimitiveValue) {
        if (levels === undefined || levels.length === 0) {
            return <string>childValue;
        }
        let source = levels[childLevel].sources[0];
        return source.type.dateTime ? formatting.fixDateTime(childValue, source.format, this.locale) : <string>childValue;
    }

    public isMultiples(): boolean {
        return this.chartGroups.chartGroups.filter(g => g.group).length > 0;
    }

    private getRowIdentity(level: number, view: DataViewMatrix): string {
        if (view.rows.levels.length > level) {
            let hierarchyLevel = view.rows.levels[level];
            return hierarchyLevel.sources[0].queryName;
        }
        return null;
    }

    private logging() {
        if (debug.shouldLog(LogType.MinsAndMaxes)) {
            console.log("Maxes:");
            console.log(`Value: min ${this.extremes.value.min} max ${this.extremes.value.max}`);
            console.log(`Absolute: min ${this.extremes.absolute.min} max ${this.extremes.absolute.max}`);
            console.log(`Relative: min ${this.extremes.relative.min} max ${this.extremes.relative.max}`);
            console.log(`Value cumulative: min ${this.extremes.valueCumulative.min} max ${this.extremes.valueCumulative.max}`);
            console.log(`Absolute cumulative: min ${this.extremes.absoluteCumulative.min} max ${this.extremes.absoluteCumulative.max}`);
            console.log(`Second absolute cumulative: min ${this.extremes.secondAbsoluteCumulative.min} max ${this.extremes.secondAbsoluteCumulative.max}`);
        }
        if (debug.shouldLog(LogType.ViewModel)) {
            console.log("View model:");
            console.log(this);
        }
    }

    private getNumberOrNull(value: PrimitiveValue) {
        return value === null || value === "" || isNaN(+value) ? null : Number(value);
    }
}
