import { Plotter } from "../plotters/plotter";
import { ViewModel } from "../../settings/viewModel";
import { ABSOLUTE, FormulaEditMode, RELATIVE } from "../../definitions";
import {
    DataProperty, G, PATH, LINE, D, Scenario, CLICK, MOUSEOUT, MOUSEOVER, BOLD, NORMAL, RECT, HIGHLIGHTABLE, LABEL, LEFT, WHITE, X, X1, X2, Y, Y1, Y2, STROKE_WIDTH, DifferenceLabel, CHART_AREA,
    LogType, VALUE_SORT, BAR, TOOLTIP, VARIANCE, BACKGROUND, FONT_SIZE, PX, FONT_FAMILY, TEXT_ANCHOR, MIDDLE, WIDTH, HEIGHT, FILL, FILL_OPACITY, BLACK, ITALIC, FONT_STYLE,
    END, START, STROKE_OPACITY, STROKE, RX, DATA_SCALE_GROUP, DATA_PROPERTY, VarianceDisplayType, TRANSFORM, STROKE_DASHARRAY, INITIAL, EMPTY, FORMULA_ELEMENT, FONT_SIZE_UNIT, CHART_ID
} from "../../library/constants";
import { DataPoint } from "../dataPoint";
import { ChartHierarchy } from "../chartHierarchy";
import { Extremes } from "../extremes";
import { VarianceSettings } from "../../settings/varianceSettings";
import {
    getOrdinalScale, getLeftSideGridlinesEnd, getRightSideGrindlinesStart, plotGridlines, getTextAnchor, plotSortableHeader, addBlurIfNeeded,
    getNeutralColor, getVarianceColor, getCommentMarkerAttributes, addCommentMarkers, addMouseHandlers
} from "./chart";

import * as drawing from "./../../library/drawing";
import * as styles from "./../../library/styles";
import * as scenarios from "./../../settings/scenarios";
import * as debug from "./../../library/debug";
import * as d3 from "d3";
import { BaseType } from "d3";
import { Visual } from "../../visual";
import { isForecastScenario, isPlanScenario } from "../../helpers";

// tslint:disable-next-line: max-func-body-length
export function plotIntegratedChart(width: number, xPosition: number, groupIndex: number, chartHierarchyIndex: number, chartId: number, multiples: boolean,
    differenceLabel: DifferenceLabel, allowChangingLabels: boolean, plotter: Plotter, dataProperty: DataProperty, referenceProperty: DataProperty, plotOrder: number) {
    let { viewModel, settings, svg, plotArea, groupHeaderHeight, currentRow, currentYPosition: currentHeight, dataPropertyForComments } = plotter;
    let totalWidth = plotter.width;
    let absoluteDifference = dataProperty === DataProperty.Value ? DataProperty.AbsoluteDifference : DataProperty.SecondAbsoluteDifference;
    let referenceScenario = referenceProperty === DataProperty.ReferenceValue ? viewModel.reference.scenario : viewModel.secondReference.scenario;
    let height = plotter.getCurrentChartHeight();
    let chartGroup = viewModel.lastLevelChartGroups[groupIndex];
    let mm = settings.plottingHorizontally() ? chartGroup.extremes : viewModel.extremes;
    let chartHierarchy = viewModel.getChartHierarchy(groupIndex, chartHierarchyIndex);
    let relative = differenceLabel === DifferenceLabel.Relative;
    let start = xPosition + getLabelOffset(mm, settings, true);
    let end = xPosition + width - getLabelOffset(mm, settings, false);
    end = Math.max(end, start);
    let xScale = drawing.getLinearScale(Math.min(mm.integratedMin, 0), Math.max(mm.integratedMax, 0), start, end);
    let yScale = getOrdinalScale(chartHierarchy.dataPoints(), currentHeight, currentHeight + height, viewModel, settings.getGapBetweenColumns());
    let plottableDataPoints = plotter.getDataPointsToPlotChartElements(chartHierarchy, yScale, dataProperty, true);
    let gridlinePlottableDataPoints = plotter.getDataPointsToPlotChartElements(chartHierarchy, yScale, dataProperty, false);
    plotter.currentlyShowingDataPoints = plottableDataPoints.inView;
    let chartArea = drawing.createGroupElement(plotArea, `${CHART_AREA}_${groupIndex}_${chartHierarchyIndex}_${chartId} ${CHART_AREA}`);
    let scaleGroup = settings.getScaleGroup(dataProperty);
    chartArea.attr(DATA_SCALE_GROUP, scaleGroup);
    chartArea.attr(DATA_PROPERTY, dataProperty);
    chartArea.attr(CHART_ID, chartId);
    let axisPosition = xScale(0);
    if (debug.shouldLog(LogType.DisplayBounds)) {
        debug.drawBoundingBox(chartArea, xPosition, width, currentHeight, height, chartId);
    }
    let leftGridlineEndFunction = getLeftSideGridlinesEnd(xScale, VALUE_SORT, axisPosition);
    let rightGridlineStartFunction = getRightSideGrindlinesStart(xScale, VALUE_SORT, axisPosition, xPosition);
    plotGridlines(yScale, xPosition, width, axisPosition, settings, viewModel, gridlinePlottableDataPoints.inView, chartArea, chartId, leftGridlineEndFunction, rightGridlineStartFunction, groupIndex, chartHierarchyIndex);
    if (settings.shouldPlotVerticalAxis(chartHierarchy)) {
        drawing.plotVerticalAxis(chartArea, chartId, false, axisPosition, currentHeight, height, settings.colorScheme.axisColor, referenceScenario, settings.showTotals, yScale, viewModel.hasHierarchy, chartHierarchy.isGrandTotal, settings.getGapBetweenColumns());
    }
    let columnHeaderXPosition = Math.round(axisPosition);
    if (mm.integratedMax === 0) {
        columnHeaderXPosition -= 15;
    }
    let anchor = getTextAnchor(mm.integratedMin, mm.integratedMax);
    let headerXPosition = anchor === "center" ? axisPosition : 0;
    if (!multiples) {
        let valueHeaderText = settings.getHeader(DataProperty.Value);
        let isValueHeaderBold = settings.isHeaderBold(DataProperty.Value) || settings.shouldUseBoldGroupHeaders(chartGroup, viewModel.maximumExpandedGroupLevel);
        let valueHeaderFontWeight = isValueHeaderBold ? BOLD : NORMAL;
        let headerWidth = drawing.measureTextWidth(valueHeaderText, settings.labelFontSize, settings.labelFontFamily, valueHeaderFontWeight, NORMAL);
        let header = plotSortableHeader(chartHierarchy, plotter, viewModel, totalWidth, valueHeaderText, currentRow, null, columnHeaderXPosition, 3, anchor, isValueHeaderBold, DataProperty.Value, plotOrder, false, headerWidth, headerXPosition, false, true, true, true, false, groupIndex, false, chartGroup, false, null, false, chartId);
        if (!header.isGroupHeader) {
            if (settings.shouldDrawColumnRect(plotter)) {
                header.fixBackgroundRect(Math.ceil(width));
            }
            if (settings.shouldDrawBorders(plotter, dataProperty)) {
                header.fixBorderLines(Math.ceil(width));
            }
        }

        let differenceHeaderXValue: number = xPosition + width;
        if (currentRow === 0 && plottableDataPoints.inView[0] && plottableDataPoints.inView[0].getValue(dataProperty)) {
            let bb = header.getBoundingBox();
            let varianceDataProperty = relative ? DataProperty.RelativeDifference : DataProperty.AbsoluteDifference;
            if (plottableDataPoints.inView[0].isNegative(dataProperty)) {
                differenceHeaderXValue = bb == null ? xScale(plottableDataPoints.inView[0].getValue(dataProperty)) : Math.min(xScale(plottableDataPoints.inView[0].getValue(dataProperty)), bb.left - 35);
            }
            else {
                differenceHeaderXValue = bb == null ? xScale(plottableDataPoints.inView[0].getValue(dataProperty)) - 20 : Math.max(bb.left + bb.width + 15, xScale(plottableDataPoints.inView[0].getValue(dataProperty)) - 20);
                differenceHeaderXValue = Math.max(differenceHeaderXValue, headerXPosition + headerWidth + 20);
            }
            let differenceHeader = settings.getHeader(varianceDataProperty);
            let isDifferenceHeaderBold = settings.isHeaderBold(varianceDataProperty) || settings.shouldUseBoldGroupHeaders(chartGroup, viewModel.maximumExpandedGroupLevel);
            let differenceHeaderFontWeight = isDifferenceHeaderBold ? BOLD : NORMAL;
            let differenceHeaderWidth = drawing.measureTextWidth(differenceHeader, settings.labelFontSize, settings.labelFontFamily, differenceHeaderFontWeight, NORMAL);
            plotSortableHeader(chartHierarchy, plotter, viewModel, totalWidth, differenceHeader, currentRow, null, differenceHeaderXValue, 3, LEFT, isDifferenceHeaderBold, varianceDataProperty, plotOrder, false, differenceHeaderWidth, 0, false, false, true, true, false, groupIndex, true, chartGroup, false, null, false, chartId);
        }
    }
    if (multiples) {
        let header = settings.getHeader(dataProperty);
        let isHeaderBold = settings.isHeaderBold(dataProperty) || settings.shouldUseBoldGroupHeaders(chartGroup, viewModel.maximumExpandedGroupLevel);
        let yPosition = chartGroup.level * groupHeaderHeight;
        if (chartGroup.isSubtotal) {
            yPosition = viewModel.maximumExpandedGroupLevel * groupHeaderHeight;
        }
        if (settings.shouldShowScenarioSortHeaders(plotter)) {
            plotSortableHeader(chartHierarchy, plotter, viewModel, totalWidth, header, currentRow, groupIndex, xPosition, yPosition + groupHeaderHeight, anchor, isHeaderBold, dataProperty, plotOrder, true, width, headerXPosition, false, true, true, false, false, groupIndex, false, chartGroup, false, null, false, chartId);
        }
    }
    let bars = drawing.getShapes(chartArea, `.${BAR}${chartId}`, `${BAR}${chartId} ${HIGHLIGHTABLE} ${TOOLTIP}`, RECT, plottableDataPoints.inViewWithoutFormulas);
    bars
        .attr(HEIGHT, Math.round(yScale.bandwidth()))
        .attr(WIDTH, d => Math.abs(xScale(d.getValue(dataProperty)) - axisPosition))
        .attr(X, d => d.getValue(dataProperty) < 0 ? xScale(d.getValue(dataProperty)) : axisPosition)
        .attr(Y, d => Math.round(yScale(d.getCategory(viewModel))));
    addMouseHandlers(bars, null, svg, viewModel);
    addBlurIfNeeded(bars, settings);
    // plotter.addTooltips(bars);
    styles.applyToBars(bars, getNeutralColor(settings), scenarios.getScenario(dataProperty, viewModel), settings.chartStyle, false, settings.colorScheme);

    let variances = getVariances(settings, viewModel, plottableDataPoints.inViewWithoutFormulas, chartArea, chartId, xScale, yScale, dataProperty, referenceProperty, plotter, absoluteDifference, referenceScenario);
    // addMouseHandlers(variances, selectionManager, svg, viewModel);
    addBlurIfNeeded(variances, settings);
    // plotter.addTooltips(variances);
    styles.applyToBars(variances, getVarianceColor(settings, absoluteDifference), scenarios.getScenario(dataProperty, viewModel), settings.chartStyle, true, settings.colorScheme);

    if (settings.showDataLabels) {
        plotVarianceLabels(settings, viewModel, allowChangingLabels, chartArea, svg, chartHierarchy, chartId, xScale, yScale, differenceLabel, dataProperty, referenceProperty, plotter);
        let labelsToPlot: DataPoint[];
        if (settings.suppressSmallValues) {
            let barBB = [];
            bars.each(function (d) { barBB.push((<SVGGraphicsElement>this).getBBox()); });
            labelsToPlot = plottableDataPoints.inViewWithoutFormulas.filter((d, i) => barBB[i].width > drawing.measureTextWidth(d.getLabel(dataProperty), settings.labelFontSize, settings.labelFontFamily, NORMAL, NORMAL));
        }
        else {
            labelsToPlot = plottableDataPoints.inViewWithoutFormulas;
        }

        let backgrounds: d3.Selection<any, DataPoint, any, any>;
        // If we have Forecast scenario we need to add white rectangle behind the labels for them to be more visible
        if (isForecastScenario(viewModel.value.scenario)) {
            backgrounds = chartArea
                .selectAll(`.${BACKGROUND}${chartId}`)
                .data(labelsToPlot);

            let rect = backgrounds
                .enter().append(RECT)
                .classed(`${BACKGROUND}${chartId}`, true);
            backgrounds = backgrounds.merge(rect);
        }
        let labels = drawing.getLabels(chartArea, `${LABEL}${chartId}`, labelsToPlot);
        labels
            .text(d => d.getLabel(dataProperty))
            .style(FONT_SIZE, `${settings.labelFontSize}${FONT_SIZE_UNIT}`)
            .style(FONT_FAMILY, settings.labelFontFamily)
            .style(TEXT_ANCHOR, MIDDLE)
            .style(FONT_STYLE, d => d.italic ? ITALIC : INITIAL)
            .attr(X, d => xScale(d.getValue(dataProperty) / 2))
            .attr(Y, d => drawing.centerTextVertically(yScale, d.getCategory(viewModel), settings.labelFontSize))
            .attr(FILL, (d: DataPoint) => d.getLabelColor(styles.getLabelColor(getNeutralColor(settings)(d)), dataProperty))
            .classed(FORMULA_ELEMENT, (d: DataPoint) => d.isCurrentlyEditedFormula())
            .classed(HIGHLIGHTABLE, true)
            .classed(TOOLTIP, true);

        // addMouseHandlers(labels, selectionManager, svg, viewModel);
        addBlurIfNeeded(labels, settings);
        // plotter.addTooltips(labels);
        if (labelsToPlot.length > 0 && isForecastScenario(viewModel.value.scenario)) {
            let boundingBoxes = [];
            labels.each(function (d, i) {
                boundingBoxes.push((<SVGGraphicsElement>this).getBBox());
            });
            let paddingLeftRight = 4;
            let paddingTopBottom = 0;

            backgrounds
                .attr(X, (d, i) => { return boundingBoxes[i].x - paddingLeftRight / 2; })
                .attr(Y, (d, i) => { return boundingBoxes[i].y - paddingTopBottom / 2; })
                .attr(WIDTH, (d, i) => { return boundingBoxes[i].width + paddingLeftRight; })
                .attr(HEIGHT, (d, i) => { return boundingBoxes[i].height + paddingTopBottom; })
                .attr(FILL, WHITE)
                .attr(FILL_OPACITY, 1 - (settings.labelBackgroundTransparency / 100));
            labels
                .attr(FILL, BLACK);
            backgrounds.exit().remove();
        }
    }

    if (viewModel.HasCommentsColumn) {
        let commentDataPoints: DataPoint[] = [];
        // comment markers with default data property (not being dragged to another data property):
        if (dataProperty === dataPropertyForComments) {
            commentDataPoints.push(...plottableDataPoints.inViewWithoutFormulas.filter(d => d.hasComment() && d.commentMarkerDataProperty === null));
        }
        commentDataPoints.push(...plottableDataPoints.inViewWithoutFormulas.filter(d => d.hasComment()).filter(d => d.commentMarkerDataProperty === dataProperty));
        plotCommentMarkers(chartArea, viewModel, commentDataPoints, xScale, yScale, settings, dataProperty, referenceProperty, settings.showDataLabels ? getLabelOffset(mm, settings, false) : 0, plotter.categoriesWidth, groupIndex);
        //plotter.addCommentMarkerTooltip(chartArea);
    }
}

function plotCommentMarkers(container: d3.Selection<SVGElement, any, any, any>, viewModel: ViewModel, commentDataPoints: DataPoint[], xScale: d3.ScaleLinear<number, number>, yScale: d3.ScaleBand<string>,
    settings: VarianceSettings, dataProperty: DataProperty, referenceProperty: DataProperty, labelMargin: number, categoriesWidth: number, groupIndex: number) {
    let scaleBandWidth = yScale.bandwidth();
    let markerAttrs = getCommentMarkerAttributes(scaleBandWidth);
    let getMarkerYPosition = (d: DataPoint): number => yScale(d.getCategory(viewModel)) + scaleBandWidth / 2;
    let getMarkerXPosition = (d: DataPoint): number => d.getValue(dataProperty) < 0 ?
        xScale(Math.min(d.getValue(dataProperty), d.getValue(referenceProperty))) - (labelMargin + markerAttrs.radius) :
        xScale(Math.max(d.getValue(dataProperty), d.getValue(referenceProperty))) + (labelMargin + markerAttrs.radius);
    addCommentMarkers(container, commentDataPoints, getMarkerXPosition, getMarkerYPosition, markerAttrs.radius, markerAttrs.fontSize, settings.colorScheme.highlightColor, settings.labelFontFamily, viewModel, categoriesWidth, groupIndex);
}

export function getLabelOffset(mm: Extremes, settings: VarianceSettings, min: boolean): number {
    if ((min && mm.integratedMin < 0) || (!min && mm.integratedMax > 0)) {
        if (settings.integratedDifferenceLabel === DifferenceLabel.RelativeAndAbsolute) {
            let absolute = mm.integratedAbsolute;
            let relative = mm.integratedRelative;
            return Math.max(absolute.getLabelOffset(settings, min), relative.getLabelOffset(settings, min)) + 5;
        }
        else {
            let labelOffsetToUse = settings.integratedDifferenceLabel === DifferenceLabel.Relative ? mm.integratedRelative : mm.integratedAbsolute;
            return labelOffsetToUse.getLabelOffset(settings, min);
        }
    }
    return 0;
}

function plotVarianceLabels(settings: VarianceSettings, viewModel: ViewModel, allowChangingLabels: boolean, chartArea: d3.Selection<SVGElement, any, any, any>, svg: d3.Selection<SVGElement, any, any, any>, chartHierarchy: ChartHierarchy, id: number,
    xScale: d3.ScaleLinear<number, number>, yScale: d3.ScaleBand<string>, differenceLabel: DifferenceLabel, dataProperty: DataProperty, referenceProperty: DataProperty, plotter: Plotter) {
    let absoluteLabels: d3.Selection<any, DataPoint, any, any> = null;
    let relativeLabels: d3.Selection<any, DataPoint, any, any> = null;
    let absoluteDifference = dataProperty === DataProperty.Value ? DataProperty.AbsoluteDifference : DataProperty.SecondAbsoluteDifference;
    let relativeDifference = dataProperty === DataProperty.Value ? DataProperty.RelativeDifference : DataProperty.SecondRelativeDifference;
    if (differenceLabel !== DifferenceLabel.Relative) {
        let absoluteLabelAdjustment = differenceLabel === DifferenceLabel.RelativeAndAbsolute ? -Math.floor(settings.labelFontSize / 2) : 0;
        absoluteLabels = drawing.getLabels(chartArea, `${ABSOLUTE}${LABEL}${id}`, chartHierarchy.dataPoints());
        plotLabels(absoluteLabels, absoluteLabelAdjustment, (d => d.getLabel(absoluteDifference)), (d: DataPoint) => d.italic ? ITALIC : INITIAL, settings, viewModel, xScale, yScale, dataProperty, referenceProperty, plotter);
    }
    if (differenceLabel !== DifferenceLabel.Absolute) {
        let relativeLabelAdjustment = differenceLabel === DifferenceLabel.RelativeAndAbsolute ? Math.ceil(settings.labelFontSize / 2) : 0;
        relativeLabels = drawing.getLabels(chartArea, `${RELATIVE}${LABEL}${id}`, chartHierarchy.dataPoints().filter(d => d.getValue(relativeDifference)));
        if (settings.showNegativeValuesInParenthesis()) {
            plotLabels(relativeLabels, relativeLabelAdjustment, (d => {
                return d.relativeDifference >= 0 ? d.getLabel(relativeDifference) + "%" : d.getLabel(relativeDifference).replace(")", "%)");
            }), (d: DataPoint) => ITALIC, settings, viewModel, xScale, yScale, dataProperty, referenceProperty, plotter);
        }
        else {
            if (differenceLabel === DifferenceLabel.RelativeAndAbsolute) {
                plotLabels(relativeLabels, relativeLabelAdjustment, (d => `(${d.getLabel(relativeDifference)}%)`), (d: DataPoint) => ITALIC, settings, viewModel, xScale, yScale, dataProperty, referenceProperty, plotter);
            }
            else {
                plotLabels(relativeLabels, relativeLabelAdjustment, (d => d.getLabel(relativeDifference) + "%"), (d: DataPoint) => ITALIC, settings, viewModel, xScale, yScale, dataProperty, referenceProperty, plotter);
            }
        }
    }
    if (allowChangingLabels && settings.getRealInteractionSettingValue(settings.allowVarianceCalculationChange) && settings.proFeaturesUnlocked) {
        addLabelsInteractions(absoluteLabels, relativeLabels, chartArea, settings, dataProperty);
    }
}

function plotLabels(labels: d3.Selection<any, DataPoint, any, any>, adjustment: number, labelFunction: (d: DataPoint) => string, fontStyle: (d: DataPoint) => string,
    settings: VarianceSettings, viewModel: ViewModel, xScale: d3.ScaleLinear<number, number>, yScale: d3.ScaleBand<string>, dataProperty: DataProperty, referenceProperty: DataProperty, plotter: Plotter) {
    labels
        .text(d => labelFunction(d))
        .style(FONT_SIZE, `${settings.labelFontSize}${FONT_SIZE_UNIT}`)
        .style(FONT_STYLE, fontStyle)
        .style(FONT_FAMILY, settings.labelFontFamily)
        .style(TEXT_ANCHOR, d => d.getValue(dataProperty) < 0 ? END : START)
        .attr(X, d => getLabelXPosition(d, dataProperty, xScale, referenceProperty))
        .attr(Y, d => drawing.centerTextVertically(yScale, d.getCategory(viewModel), settings.labelFontSize) + adjustment)
        .attr(FILL, (d: DataPoint) => d.getLabelColor(settings.labelFontColor, dataProperty))
        .classed(HIGHLIGHTABLE, true)
        .classed(TOOLTIP, true);
    // plotter.addTooltips(labels);
}

function getLabelXPosition(d: DataPoint, dataProperty: DataProperty, xScale: d3.ScaleLinear<number, number>, referenceProperty: DataProperty): number {
    if (d.isRowDisplayUnitPercent()) {
        return xScale(0) + 5;
    }
    return d.getValue(dataProperty) < 0 ? xScale(Math.min(d.getValue(referenceProperty), d.getValue(dataProperty))) - 5 : xScale(Math.max(d.getValue(dataProperty), d.getValue(referenceProperty))) + 5;
}

function addLabelsInteractions(absoluteLabels: d3.Selection<any, DataPoint, any, any>, relativeLabels: d3.Selection<any, DataPoint, any, any>,
    container: d3.Selection<SVGElement, any, any, any>, settings: VarianceSettings, dataProperty: DataProperty) {
    let labels: d3.Selection<any, DataPoint, any, any>;
    let additionalLabels: d3.Selection<any, DataPoint, any, any>;
    if (absoluteLabels !== null) {
        labels = absoluteLabels;
        additionalLabels = relativeLabels;
    }
    else {
        labels = relativeLabels;
        additionalLabels = null;
    }
    if (true && (<any>window).animateLabels) {
        animateLabels(labels, additionalLabels, settings.labelFontSize);
    }
    labels.on(MOUSEOVER, (dp, i) => {
        let label = labels.filter((d, j) => { return j === i; });
        let labelBoundingClientRect = (<SVGElement>label.node()).getBoundingClientRect();
        let rectWidth = labelBoundingClientRect.width, rectHeight = labelBoundingClientRect.height, rectX = dp.isNegative(dataProperty) ? Number(label.attr(X)) - labelBoundingClientRect.width : Number(label.attr(X)), rectY = Number(label.attr(Y)) - settings.labelFontSize - 1;

        if (additionalLabels != null) {
            let label2 = additionalLabels.filter((d, j) => { return j === i; });
            let label2BoundingClientRect = (<SVGElement>label2.node()).getBoundingClientRect();
            rectWidth = Math.max(rectWidth, label2BoundingClientRect.width);
            if (dp.isNegative(dataProperty))
                rectX = Number(label.attr(X)) - label2BoundingClientRect.width;
            rectHeight += label2BoundingClientRect.height;
        }

        let labelRect = getMouseOverRectangle(container, rectHeight, rectWidth, rectX, rectY, "variance-label-rect");
        labelRect.on(CLICK, () => {
            settings.persistDataLabels();
        });
    });
}

function getMouseOverRectangle(container: d3.Selection<SVGElement, any, any, any>, height: number, width: number, x: number, y: number, className: string): d3.Selection<SVGElement, any, any, any> {
    let labelRect = container.append(RECT).classed(className, true)
        .attr(WIDTH, width + 2)
        .attr(HEIGHT, height + 2)
        .attr(X, x - 1)
        .attr(Y, y - 1)
        .attr(FILL_OPACITY, 0)
        .attr(STROKE_OPACITY, 0)
        .attr(FILL, BLACK)
        .attr(STROKE, BLACK)
        .attr(STROKE_WIDTH, 1)
        .attr(RX, 3);

    labelRect.on(MOUSEOVER, () => {
        labelRect.attr(FILL_OPACITY, 0.04);
        labelRect.attr(STROKE_OPACITY, 0.4);
    });
    labelRect.on(MOUSEOUT, () => {
        labelRect.attr(FILL_OPACITY, 0);
        labelRect.attr(STROKE_OPACITY, 0);
    });
    return labelRect;
}

function getVariances(settings: VarianceSettings, viewModel: ViewModel, dataPoints: DataPoint[], chartArea: d3.Selection<SVGElement, any, any, any>, id: number,
    xScale: d3.ScaleLinear<number, number>, yScale: d3.ScaleBand<string>, dataProperty: DataProperty, referenceProperty: DataProperty, plotter: Plotter, absoluteDifference: DataProperty, referenceScenario: Scenario): d3.Selection<d3.BaseType, any, SVGElement, any> {
    let variances: d3.Selection<BaseType, any, SVGElement, any>;

    if (settings.varianceDisplayType === VarianceDisplayType.Bar) {
        variances = drawing.getShapes(chartArea, `.${VARIANCE}${id}`, `${VARIANCE}${id} ${HIGHLIGHTABLE} ${TOOLTIP}`, RECT, dataPoints);
        variances
            .attr(HEIGHT, Math.round(yScale.bandwidth() * 0.75))
            .attr(WIDTH, d => Math.abs(xScale(d.getValue(dataProperty)) - xScale(d.getValue(referenceProperty))))
            .attr(X, d => d.isNegative(absoluteDifference) ? xScale(d.getValueFromComparison(settings.valueScenario, d.value)) : xScale(d.getValueFromComparison(settings.referenceScenario, d.referenceValue)))
            .attr(Y, d => Math.round(yScale(d.getCategory(viewModel))));
    }
    else {
        variances = drawing.getShapes(chartArea, `.${VARIANCE}${id}`, `${VARIANCE}${id} ${HIGHLIGHTABLE} ${TOOLTIP}`, G, dataPoints);
        variances
            .append(PATH)
            .attr(D, (d) => drawing.getChevronArrowPath(Math.abs(xScale(d.getValue(dataProperty)) - xScale(d.getValue(referenceProperty))),
                yScale.bandwidth(), d.isNegative(absoluteDifference), true))
            .attr(TRANSFORM, (d) => {
                let X = d.isNegative(absoluteDifference) ?
                    xScale(d.getValueFromComparison(settings.valueScenario, d.value)) :
                    xScale(d.getValueFromComparison(settings.referenceScenario, d.referenceValue));
                let Y = Math.round(yScale(d.getCategory(viewModel)));
                return "translate(" + X + "," + Y + ")";
            })

        variances
            .append(LINE)
            .attr(X1, d => xScale(d.getValueFromComparison(settings.referenceScenario, d.referenceValue)))
            .attr(Y1, d => Math.round(yScale(d.getCategory(viewModel))))
            .attr(X2, d => xScale(d.getValueFromComparison(settings.referenceScenario, d.referenceValue)))
            .attr(Y2, d => Math.round(yScale(d.getCategory(viewModel)) + Math.round(yScale.bandwidth())))
            .attr(STROKE, referenceScenario === Scenario.PreviousYear ? styles.getLighterColor(settings.colorScheme.axisColor) : settings.colorScheme.axisColor)
            .attr(STROKE_WIDTH, "1")
            .attr(STROKE_DASHARRAY, isForecastScenario(referenceScenario) ? "2" : "0")
    }

    return variances;
}

function animateLabels(labels: d3.Selection<any, DataPoint, any, any>, additionalLabels: d3.Selection<any, DataPoint, any, any>, fontSize: number) {
    labels.style(FONT_SIZE, (fontSize + 2) + FONT_SIZE_UNIT)
        .transition()
        .style(FONT_SIZE, fontSize + FONT_SIZE_UNIT);
    if (additionalLabels != null) {
        additionalLabels.style(FONT_SIZE, 1 + FONT_SIZE_UNIT)
            .transition()
            .style(FONT_SIZE, (fontSize + 2) + FONT_SIZE_UNIT)
            .transition()
            .style(FONT_SIZE, fontSize + FONT_SIZE_UNIT);
    }
}
