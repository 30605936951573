import { Categories } from "../category.type";

import StructureTableNumbersImage from "./images/structure-table-numbers.svg";
import StructureTableChartImage from "./images/structure-table-chart.svg";
import structureTableWaterfallImage from "./images/structure-table-waterfall.svg";
import {
  StructureTableChartCommentData,
  StructureTableChartSampleData,
  StructureTableNumbersCommentData,
  StructureTableNumbersSampleData,
} from "./structureCategories.mock";

export const structureCategories: Categories = [
  {
    displayName: "Single series",
    tables: [
      {
        id: "structure-table-numbers",
        title: "Simple table with numbers",
        image: StructureTableNumbersImage,
        sampleData: StructureTableNumbersSampleData,
        commentData: StructureTableNumbersCommentData,
        tooltip: "A table you are used to with all the additional Zebra BI features",
        settings: {
          showAsTable: true,
        },
      },
      {
        id: "structure-table-chart",
        title: "Simple table with a chart",
        image: StructureTableChartImage,
        sampleData: StructureTableChartSampleData,
        commentData: StructureTableChartCommentData,
        tooltip:
          "Compare values, rank data categories, look up specific values, and recognize patterns in data. Use when you have discrete items like products or customers.",
        settings: {
          showAsTable: false,
          valueChart: 0,
        },
      },
      /*
      {
        id: "structure-table-waterfall",
        title: "Vertical waterfall chart",
        image: structureTableWaterfallImage,
      },
      */
    ],
  },
];
