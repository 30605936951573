import { VarianceSettings } from "../settings/varianceSettings";
import { TextAlign, ChartType, ACT_ABS_REL, ACTUAL, SortDirection, NUMBER_OF_GROUPS, RELATIVE, INVERT, ICON_TOOLTIP_Y_OFFSET } from "../definitions";
import { ViewModel } from "../settings/viewModel";
import {
    DataProperty, Scenario, BOLD, NORMAL, ITALIC, BorderType, MarkerStyle, DIV, HEADER, PX, P, ARROW_DOWN, LEFT, TOP, CONTEXT_MENU, MOUSEENTER, INPUT, DISPLAY,
    NONE, RIGHT, MOUSELEAVE, HEADER_TOOLTIP, MOUSEOVER, SEGOE_UI, CLICK, ARROW_DOWN_SLIM, ARROW_UP_SLIM, BLOCK, TEXT, LABEL, SELECT, ColumnFormat, CHANGE, HEIGHT, EMPTY, BLACK, G, RECT, BACKGROUND, WHITE, LINE, GRAY, DRAG, DRAGSTART, DRAGEND, NumberOfDataProperties, DRAG_TARGET, TEXT_ANCHOR, INDICATOR, OPTION, ShowAsTableOptions, OPACITY, CHART_AREA, CATEGORIES_AREA, TRANSFORM, POSITION, WIDTH, BORDER_RADIUS, COLOR, DATA_SCALE_GROUP, DATA_PROPERTY, FONT_SIZE, PADDING, MARGIN, X2, X1, FLOAT, BOX_SHADOW, Z_INDEX, MARGIN_TOP, ID, X, Y, FILL, MARGIN_LEFT, TYPE, STEP, MIN, MAX, VALUE, STROKE, STROKE_WIDTH, Y1, Y2, SELECTED, TEXT_ALIGN, CENTER, LINE_HEIGHT, CHECKBOX, ELASTIC, TOGGLE, POLYLINE, POINTS, COLLAPSE_ARROW_ICON, FILL_OPACITY, INITIAL, HEADER_DROPDOWN_SETTINGS_ARROW, PADDING_LEFT, VISIBILITY, RECT_BACKGROUND, LINE_BORDER, RECT_TOTAL_BACKGROUND, LINE_TOTAL_BORDER, CATEGORY, DisplayUnits, ZEBRABI_TABLE_CONTAINER, FONT_SIZE_UNIT, ICON
} from "../library/constants";
import { Position } from "@zebrabi/legacy-library-common/interfaces";
import { ChartGroup } from "./chartGroup";
import { Plotter } from "./plotters/plotter";
import * as d3 from "d3";
import { Visual } from "../visual";

import * as helpers from "./../helpers";
import * as helpersLib from "./../library/helpers";
import * as drawing from "./../library/drawing";
import * as styles from "./../library/styles";
import * as uiLib from "./../library/ui/showTooltip";
import * as ui from "./../ui/popups";

import Pickr from "@simonwep/pickr";

export class Header {
    private settings: VarianceSettings;
    private sortColumn: string;
    private currentColumnOrder: number;
    private readonly startingColumnOrder: number;
    private startingHeaderPosition: Position;
    private currentHeaderPosition: Position;
    private headerPeekingLeft: boolean;
    private textWidth: number;
    private startingTextAlign: TextAlign;
    private text: string;
    private fontFamily: string;
    private fontWeight: string;
    private fontStyle: string;
    private fontSize: number;
    private italic: boolean;
    private dragStart: number;
    private dragEnd: number;
    private expectedHeight: number;
    private headerTextElement: d3.Selection<HTMLElement, any, any, any>;
    private headerTextChanged: boolean;
    private headerTextElementLeft: number;
    private columnHeader: d3.Selection<HTMLElement, any, any, any>;
    private columnHeaderElement: HTMLElement;
    private settingsArrow: d3.Selection<SVGElement, any, any, any>;
    private headerInput: HTMLInputElement;
    private drawIcon: (chartType: ChartType, columnSettingsDiv: HTMLElement, icon: d3.Selection<SVGElement, any, any, any>, viewModel: ViewModel, iconSize: number, dataProperty: DataProperty, plotter: Plotter) => number;
    private showSortTooltip: boolean;
    private headerBackground: d3.Selection<HTMLElement, any, any, any>;
    private previousDragPosition: number;
    private fillrect: d3.Selection<SVGElement, any, any, any>;
    private bordergroup: d3.Selection<SVGElement, any, any, any>;
    private chartType: ChartType;
    private showColumnSettings: boolean;
    private scaleGroup: number;
    private headerContainer: d3.Selection<any, any, any, any>;

    constructor(private plotter: Plotter, private container: d3.Selection<SVGElement, any, any, any>, private viewModel: ViewModel, private totalWidth: number, private dataProperty: DataProperty, plotOrder: number,
        private referenceId: number, private xPosition: number, private yPosition: number, private readonly headerText: string, private currentTextAlign: TextAlign, private bold: boolean,
        private shouldShorten: boolean, private width: number, private textXPosition: number, public isGroupHeader: boolean,
        private useDragAndDrop: boolean, private allowSort: boolean, private showDropDownArrow: boolean, private onlyShowChartChangeIcons: boolean, public groupIndex: number,
        public isIntegratedSecondHeader: boolean, private chartGroup: ChartGroup, private scenario: Scenario, private isCategoriesHeader: boolean, private columnOrder?: number) {

        this.settings = viewModel.settings;
        this.sortColumn = this.settings.getColumnName(dataProperty);
        this.currentColumnOrder = plotOrder;
        this.startingColumnOrder = plotOrder;
        this.startingTextAlign = currentTextAlign;
        this.fontFamily = this.isGroupHeader ? this.settings.groupTitleFontFamily : this.settings.labelFontFamily;
        this.fontSize = this.isGroupHeader ? this.settings.groupTitleFontSize : this.settings.labelFontSize;
        this.fontWeight = this.bold ? BOLD : NORMAL;
        this.italic = this.settings.shouldUseItalicText(dataProperty);
        this.fontStyle = this.italic ? ITALIC : NORMAL;
        this.text = this.getColumnHeaderText(this.headerText);
        this.headerTextElementLeft = 0;
        if (isGroupHeader) {
            this.textWidth = drawing.measureTextWidth(this.text, this.settings.groupTitleFontSize, this.settings.groupTitleFontFamily, this.fontWeight, this.fontStyle);
            this.width = Math.min(this.width, this.textWidth + 10);
        }
        else {
            this.textWidth = drawing.measureTextWidth(this.text, this.settings.labelFontSize, this.settings.labelFontFamily, this.fontWeight, this.fontStyle);
            this.width = Math.max(this.textWidth + 5, this.width);
            this.scaleGroup = this.settings.getScaleGroup(dataProperty);
        }
        this.xPosition = Math.round(this.xPosition);
        this.textXPosition = Math.round(this.textXPosition);
        this.expectedHeight = drawing.getEstimatedTextHeight(headerText, this.fontSize, this.fontFamily, this.fontWeight, this.fontStyle);
        this.headerTextChanged = false;
        this.headerPeekingLeft = false;
        this.showSortTooltip = true;
        this.showColumnSettings = this.showDropDownArrow;
        this.headerInput = null;
    }

    public addHeader() {
        this.plotHeaderBackgroundAndBorder();
        this.plotHeader();
        if (this.allowSort || this.showColumnSettings) {
            this.addEventHandlers();
        }
        if (this.settings.getRealInteractionSettingValue(this.settings.allowColumnOrderChange) && this.useDragAndDrop && !this.isGroupHeader) {
            this.addDragAndDropHandlers();
        }
        if (this.useDragAndDrop && !this.isGroupHeader) {
            this.settings.headers.push(this);
        }
        if (this.scenario !== null && !helpersLib.isAdditionalMeasure(this.dataProperty)) {
            this.plotScenarioIndicator(this.scenario, this.dataProperty, this.scaleGroup);
        } else if (helpersLib.isAdditionalMeasure(this.dataProperty)) {
            if (this.scenario !== null) {
                this.scenario = helpers.getScenarioFromMarkerStyle(this.settings.getMarkerStyle(this.dataProperty));
                this.plotMarkerIndicator(this.settings.getMarkerStyle(this.dataProperty));
            }
        }
    }

    public plotHeaderBackgroundAndBorder() {
        if (!this.isIntegratedSecondHeader && !this.isGroupHeader) {
            if (this.settings.shouldDrawColumnRect(this.plotter)) {
                this.fillrect = drawing.drawBackgroundFill(this.container, this.settings.getColumnBackgroundFill(this.dataProperty), this.xPosition - 3, this.yPosition, Math.max(this.width, this.textWidth) + 6, 100, `${RECT_BACKGROUND} ${RECT_BACKGROUND}_${this.columnOrder}`);
            }
            if (this.settings.shouldDrawColumnBorders(this.plotter)) {
                this.bordergroup = drawing.drawColorBorders(this.container, this.settings.getColumnBorder(this.dataProperty), this.xPosition - 3, this.yPosition, Math.max(this.width, this.textWidth) + 6, 100, false, BorderType.Header, `${LINE_BORDER}_${this.columnOrder}`)
            }
        }
    }

    public plotHeader() {
        this.headerContainer = this.container === Visual.categoriesHeaders ? d3.select(Visual.categoriesHeaderDiv) : d3.select(Visual.headersDiv);
        this.columnHeader = this.headerContainer.append(DIV).classed(HEADER, true);
        this.columnHeaderElement = <HTMLElement>this.columnHeader.node();
        let width = this.getHeaderWidth();
        this.headerBackground = this.headerContainer.append(DIV)
            .style(POSITION, "absolute")
            .style(WIDTH, width + FONT_SIZE_UNIT)
            .style(LEFT, this.xPosition + PX)
            .style(TOP, Math.round(this.yPosition) + PX)
            .style(HEIGHT, this.expectedHeight + 5 + PX)
            .style(BORDER_RADIUS, "3px");

        let columnHeaderCustomColor = this.settings.labelFontColor;
        if (!this.isGroupHeader && !helpersLib.isDifferenceMeasure(this.dataProperty, this.settings.getColumnFormat(this.dataProperty))) {
            columnHeaderCustomColor = this.settings.getColumnTextColor(this.dataProperty, this.settings.labelFontColor);
        }
        if (this.isGroupHeader && this.startingTextAlign === "center") {
            this.xPosition -= this.textWidth / 2;
        }
        this.columnHeader
            .style(WIDTH, width + FONT_SIZE_UNIT)
            .style(LEFT, this.xPosition + PX)
            .style(TOP, Math.round(this.yPosition) + PX)
            .style(COLOR, this.isGroupHeader ? this.settings.groupTitleFontColor : columnHeaderCustomColor)
            .style(HEIGHT, this.expectedHeight + 5 + PX);

        this.columnHeader.attr(DATA_SCALE_GROUP, this.scaleGroup);
        this.columnHeader.attr(DATA_PROPERTY, this.dataProperty);
        this.headerTextElement = this.columnHeader.append(P).classed("headerText", true).text(this.text);
        this.headerTextElement
            .style(FONT_SIZE, this.fontSize + FONT_SIZE_UNIT)
            .style(HEIGHT, this.fontSize + 10 + FONT_SIZE_UNIT)
            .style(LINE_HEIGHT, this.fontSize + 10 + FONT_SIZE_UNIT)
            .style(PADDING, "0")
            .style(LEFT, 0)
            .style(PADDING_LEFT, 0)
            .style(MARGIN, "0 auto")
            .classed((this.isCategoriesHeader ? `${HEADER} ${CATEGORY}` : `${HEADER}${this.columnOrder}`), true)
        drawing.applyFontFamily(this.headerTextElement, this.fontFamily, this.bold, this.italic);

        if (!this.isGroupHeader) {
            if (this.startingTextAlign === "center") {
                this.headerTextElementLeft = Math.round(this.textXPosition - (this.textWidth / 2) - this.xPosition - 5);
                if (this.headerTextElementLeft + this.textWidth > this.width) {
                    this.headerTextElementLeft = this.width - this.textWidth - 8;
                }
            }
            else if (this.startingTextAlign === "right") {
                let textWidth = drawing.measureTextWidth(this.headerTextElement.text(), this.fontSize, this.fontFamily, this.fontWeight, this.fontStyle);
                this.headerTextElementLeft = Math.round(this.width - textWidth - 4);
            }
        }

        this.headerTextElement
            .style(POSITION, "absolute")
            .style(PADDING_LEFT, this.headerTextElementLeft + PX)
            .style(WIDTH, (d) => {
                return width + FONT_SIZE_UNIT
            })
        if (this.showColumnSettings) {
            this.settingsArrow =
                drawing.createSvgElement(this.columnHeader.node(), HEADER_DROPDOWN_SETTINGS_ARROW);
            this.settingsArrow
                .attr(WIDTH, 12)
                .attr(HEIGHT, this.expectedHeight)
                .append(POLYLINE)
                .attr(POINTS, p => {
                    let y = (this.settings.labelFontSize / 2);
                    return `${1},${y - 1} ${6},${y + 4} ${11},${y - 1}`;
                })
                .attr(STROKE, GRAY)
                .attr(STROKE_WIDTH, 1)
                .attr(FILL_OPACITY, 0)
                .attr(OPACITY, 1.0)
        }
        this.currentHeaderPosition = {
            x: +this.columnHeader.style(LEFT).replace(PX, ""),
            y: +this.columnHeader.style(TOP).replace(PX, ""),
        };
        this.startingHeaderPosition = {
            x: this.currentHeaderPosition.x,
            y: this.currentHeaderPosition.y,
        };
    }

    private isSubtotalGroup(): boolean {
        return this.chartGroup && this.chartGroup.isSubtotal;
    }

    private isColumnTotalGroup(): boolean {
        return this.isSubtotalGroup() && this.groupIndex === -1;
    }

    private isColumnGrandTotalGroup(): boolean {
        return this.chartGroup && this.chartGroup.isColumnGrandTotal;
    }

    private getHeaderWidth() {
        if (this.settings.shouldShowScenarioSortHeaders(this.plotter) && this.groupIndex === -1 && (this.isSubtotalGroup() || this.isColumnGrandTotalGroup())) {
            return Math.round(this.textWidth + 20);
        }
        return Math.round(Math.max(this.width, this.textWidth));
    }

    public addEventHandlers() {
        this.setButtonHoverHandlers();
        if (this.showColumnSettings) {
            this.setColumnSettingsForm();
        }
    }

    public fixBackgroundRect(width: number) {
        this.fillrect
            .attr(WIDTH, width + PX);
    }

    public fixBorderLines(width: number) {
        this.bordergroup.select(".border-upper")
            .attr(X2, this.xPosition + width);
        this.bordergroup.select(".border-right")
            .attr(X1, this.xPosition + width)
            .attr(X2, this.xPosition + width);
    }

    // tslint:disable-next-line: max-func-body-length
    private setButtonHoverHandlers() {
        this.columnHeader
            .on(MOUSEENTER, () => {
                if (this.showColumnSettings) {
                    this.settingsArrow.style(VISIBILITY, "visible");
                    this.settingsArrow.style(DISPLAY, "inline");
                    this.settingsArrow.style(FLOAT, RIGHT);
                }
                let input = <HTMLInputElement>Visual.columnSettings.select(INPUT).node();
                if (input && this.headerTextChanged) {
                    if (input.value === this.headerText) {
                        this.headerTextChanged = false;
                    }
                    else {
                        this.persistHeader(input);
                    }
                }
                Visual.columnSettings.style(DISPLAY, NONE);
                Visual.columnRemoveList.style(DISPLAY, NONE);
                (<any>window).switchingDecimals = false;
                let headerWidth = this.getHeaderWidth();
                let additionalWidth = 0;
                let headerAndTextDiff = headerWidth - this.textWidth;
                let headerTextElementWidth = headerWidth - this.headerTextElementLeft - 16;

                if (this.textWidth + this.headerTextElementLeft + 12 > headerWidth) {
                    headerTextElementWidth = this.textWidth + 2;
                    additionalWidth += this.showColumnSettings ? 15 : 0;
                    if (this.isGroupHeader) {
                        additionalWidth -= 5;
                        if (this.text.endsWith("...")) {
                            additionalWidth += 8;
                        } else if (headerAndTextDiff < 2) {
                            additionalWidth += 7.5;
                        }
                    }

                    if (!this.isGroupHeader || this.isGroupHeader && this.text.endsWith("...")) {
                        this.headerPeekingLeft = true;
                        this.columnHeader.style(LEFT, (this.currentHeaderPosition.x - 6) + PX);
                    }
                }
                this.headerTextElement.style(WIDTH, headerTextElementWidth + PX);
                this.columnHeader
                    .style(WIDTH, Math.round(headerWidth + additionalWidth) + PX)
                    .style(BOX_SHADOW, "0 8px 10px 1px rgba(0, 0, 0, 0.10), 0 3px 14px 2px rgba(0, 0, 0, 0.09), 0 5px 5px -3px rgba(0, 0, 0, 0.15)")
                    .style(BACKGROUND, "rgba(255,255,255,0.6)")
                    .style(Z_INDEX, 3);
            })
            .on(MOUSELEAVE, () => {
                if (this.showColumnSettings) {
                    this.settingsArrow.style(DISPLAY, NONE);
                }
                if (this.headerTextChanged && this.headerInput) {
                    if (this.headerInput.value === this.headerText) {
                        this.headerTextChanged = false;
                    }
                    else {
                        this.persistHeader(this.headerInput);
                    }
                }
                let width = this.getHeaderWidth();
                this.columnHeader
                    .style(BOX_SHADOW, NONE)
                    .style(BACKGROUND, null)
                    .style(WIDTH, width + PX)
                    .style(Z_INDEX, 2);
                if (this.showColumnSettings) {
                    this.columnHeader.style(LEFT, this.currentHeaderPosition.x + PX);
                    if (this.headerTextElementLeft + this.textWidth + 7 > this.width) {
                        this.headerTextElement
                            .style(PADDING_LEFT, Math.round(this.headerTextElementLeft) + PX);
                    }
                }
                this.showSortTooltip = true;
                d3.selectAll("." + HEADER_TOOLTIP).remove();
            });
        this.headerTextElement.on(MOUSEOVER, () => {
            if (this.showSortTooltip && this.allowSort) {
                let isSortChangeUpdate = (<any>window).sortChangeUpdate;
                (<any>window).sortChangeUpdate = false;
                let sortLabel = isSortChangeUpdate ? this.getSortLabel() : "Click to sort";
                let width = drawing.measureTextWidth(sortLabel, 12, SEGOE_UI, NORMAL, this.fontStyle);
                uiLib.showTooltip(this.getTooltipContainer(), {
                    x: this.xPosition + this.headerTextElementLeft + this.textWidth / 2 - width / 2 - 10 + this.getExtraTranslation(true),
                    y: this.yPosition + 30,
                }, sortLabel, 250, 1750);
                this.showSortTooltip = false;
            }
        });
        if (this.settings.hasNoResults && this.settings.getRealInteractionSettingValue(this.settings.allowSortChange) && this.allowSort) {
            this.columnHeader.on(CLICK, () => {
                (<any>d3.event).stopPropagation();
                if (this.settings.dragStarted) {
                    this.settings.dragStarted = false;
                    return;
                }
                let dragDuration = this.dragEnd - this.dragStart;

                if (dragDuration > 200) {
                    return;
                }
                (<any>window).sortChangeUpdate = true;
                this.settings.handleSortHeaderClick(this.sortColumn, this.referenceId);
                Visual.getInstance().constructViewModelAndUpdate(this.settings);
                // this.settings.persistSorting();
            });
        }
    }

    private getTooltipContainer(): HTMLElement {
        if (Visual.categoriesHeaders === this.container) {
            return Visual.categoriesHeaderDiv;
        } else if (this.settings.showFrozenCategories()) {
            return Visual.headersDoubleOuterDiv;
        } else {
            return Visual.headersDiv;
        }
    }

    private getExtraTranslation(header: boolean): number {
        if (this.settings.showFrozenCategories()) {
            return -(<any>window).currentScrollLeft - (header ? 0 : this.plotter.categoriesWidth);
        }
        else return 0;
    }

    private getSortLabel() {
        if (this.text.indexOf(ARROW_DOWN_SLIM) > -1) {
            return "Sort descending";
        }
        else if (this.headerTextElement.text().indexOf(ARROW_UP_SLIM) > -1) {
            return "Sort ascending";
        }
        return "Custom sort";
    }

    // tslint:disable-next-line: max-func-body-length
    private setColumnSettingsForm() {
        this.settingsArrow
            // tslint:disable-next-line: max-func-body-length
            .on(CLICK, () => {
                d3.selectAll(".pcr-app").remove();
                d3.selectAll(".message-tooltip").remove();
                window.dispatchEvent(new Event("visualClickEvent"));
                (<any>d3.event).stopPropagation();
                Visual.columnSettings.selectAll("*").remove();
                Visual.columnSettings.style(HEIGHT, null);
                let columnSettingsDiv = <HTMLElement>Visual.columnSettings.node();
                let isShown = Visual.columnSettings.style(DISPLAY);
                if (isShown === BLOCK) {
                    Visual.columnSettings.style(DISPLAY, NONE);
                    (<any>window).switchingDecimals = false;
                    return;
                }
                Visual.columnSettings.style(DISPLAY, BLOCK);
                let bb = (<HTMLDivElement>this.columnHeader.node()).getBoundingClientRect();
                let offset = this.isGroupHeader ? 0 : 2;
                Visual.columnSettings
                    .style(LEFT, bb.left - 1 + PX)
                    .style(TOP, bb.top + bb.height + offset + PX);
                if (!(<any>window).switchingDecimals) {
                    (<any>window).columnSettingsLeft = bb.left - 1;
                }
                this.headerInput = document.createElement(INPUT);
                this.headerInput.type = TEXT;
                this.headerInput.value = this.headerText;
                d3.select(this.headerInput).on(INPUT, () => {
                    let event = <any>d3.event;
                    if (event.stopPropagation) {
                        event.stopPropagation();
                    }
                    this.headerTextElement.text(this.getColumnHeaderText(this.headerInput.value));
                    this.headerTextChanged = true;
                });
                columnSettingsDiv.appendChild(this.headerInput);
                if (this.settings.proVersionActive() && (<any>window).switchingDecimals !== true) {
                    this.headerInput.focus();
                }
                d3.select(this.headerInput).on("keyup", () => {
                    if ((<any>d3.event).keyCode === 13) {
                        this.persistHeader(this.headerInput);
                    }
                });
                Visual.columnSettings.on(CLICK, () => { (<any>d3.event).stopPropagation(); });
                let callback = (e: Event) => {
                    if (this.headerInput) {
                        this.persistHeader(this.headerInput);
                        (<HTMLElement>d3.select(".slide").node()).removeEventListener(CLICK, callback);
                        e.stopPropagation();
                    }
                };
                (<HTMLElement>d3.select(".slide").node()).addEventListener(CLICK, callback);

                let line = document.createElement(DIV);
                line.className = "line";
                columnSettingsDiv.appendChild(line);

                let columnSettingsContent = document.createElement(DIV);
                columnSettingsContent.className = "column-settings-content";
                columnSettingsDiv.appendChild(columnSettingsContent);

                if (this.drawIcon && this.settings.getRealInteractionSettingValue(this.settings.allowChartChange)) {
                    this.addChartIcons(columnSettingsContent, this.dataProperty);
                }

                if (!(this.isGroupHeader || this.onlyShowChartChangeIcons) && !this.isColumnTotalGroup()) {
                    this.addFormatColumnIcons(columnSettingsContent);
                }

                if (this.isColumnTotalGroup()) {
                    this.addColumnTotalFormatIcons(columnSettingsContent, this.chartGroup.level)
                }

                if (helpersLib.isAdditionalMeasure(this.dataProperty)) {
                    this.plotAdditionalMeasureUI(columnSettingsContent);
                }
                if (helpers.isVariance(this.dataProperty)) {
                    this.addComparisonDropDown(columnSettingsContent);
                }
                if (!this.isGroupHeader && (!this.onlyShowChartChangeIcons || this.onlyShowChartChangeIcons && this.isCategoriesHeader) && this.settings.topNOtherShown) {
                    this.addSuppressOthersCheckbox(columnSettingsContent);
                }
                if (this.chartGroup && this.headerText === this.settings.getGroupName(this.chartGroup)) {
                    if (this.chartGroup.level < this.viewModel.numberOfGroupLevels - 1 && this.settings.getRealInteractionSettingValue(this.settings.allowExpandCollapseColumnsChange)) {
                        let expandGroup = <HTMLDivElement>document.createElement(DIV);
                        columnSettingsContent.appendChild(expandGroup)
                        let expandText = document.createElement(P);
                        expandText.textContent = this.chartGroup.level === 0 ? "Expand entire field" : "Expand group";
                        expandText.className = TOGGLE;
                        expandText.addEventListener(CLICK, () => this.settings.expandEntireGroup(this.chartGroup.level));
                        expandGroup.appendChild(expandText);
                        let collapseGroup = <HTMLDivElement>document.createElement(DIV);
                        columnSettingsContent.appendChild(collapseGroup)
                        let collapseText = document.createElement(P);
                        collapseText.className = TOGGLE;
                        collapseText.textContent = this.chartGroup.level === 0 ? "Collapse entire field" : "Collapse group";
                        collapseText.addEventListener(CLICK, () => this.settings.collapseEntireGroup(this.chartGroup.level));
                        collapseGroup.appendChild(collapseText);
                    }
                    let invertedDiv = <HTMLDivElement>document.createElement(DIV);
                    invertedDiv.className = ELASTIC;
                    invertedDiv.style.marginBottom = "8px";
                    invertedDiv.style.marginLeft = "-3px";
                    invertedDiv.style.marginRight = "-3px";
                    if (this.isGroupHeader) {
                        invertedDiv.style.marginTop = "8px";
                    }
                    columnSettingsContent.appendChild(invertedDiv);
                    let invertCheckbox = <HTMLInputElement>document.createElement(INPUT);
                    invertCheckbox.type = CHECKBOX;
                    invertCheckbox.id = "invert";
                    invertCheckbox.checked = this.chartGroup.isInverted;
                    invertCheckbox.style.height = "13px";
                    invertCheckbox.style.width = "13px";
                    invertCheckbox.style.margin = "0 5px 0 0";
                    invertCheckbox.style.display = "inline";
                    invertedDiv.addEventListener(CLICK, (e) => {
                        e.preventDefault();
                        this.settings.persistInvertedGroup(this.chartGroup)
                    }
                    );
                    invertedDiv.appendChild(invertCheckbox);
                    let label = <HTMLLabelElement>document.createElement(LABEL);
                    label.htmlFor = "invert";
                    label.textContent = INVERT;
                    invertedDiv.appendChild(label);
                }
                if (helpersLib.isAdditionalMeasure(this.dataProperty)) {
                    let columnFormat = this.settings.getColumnFormat(this.dataProperty);
                    if (columnFormat === ColumnFormat.AbsoluteDifference || columnFormat === ColumnFormat.RelativeDifference) {
                        this.plotInvert(columnSettingsContent);
                    }
                }
                if ((this.settings.chartType === ACT_ABS_REL && !this.settings.onlyOneColumn && !this.isGroupHeader) ||
                    ((this.isSubtotalGroup() || this.isColumnGrandTotalGroup()) && this.groupIndex === -1) ||
                    (this.settings.chartType === ACTUAL && !this.viewModel.HasValueColumn)) {
                    this.addRemoveColumnIcon(columnSettingsContent);
                }
                if (bb.top + parseInt(d3.select(columnSettingsDiv).style(HEIGHT)) + 10 > this.plotter.height) {
                    d3.select(columnSettingsDiv)
                        .style(HEIGHT, (Math.max(this.plotter.height - bb.top - 10, 20)) + PX);
                }
                // Move window to the left when shown outside on the right side of the visuals div.
                bb = columnSettingsDiv.getBoundingClientRect();
                let tableContainerBB = (<SVGElement>d3.select(`.${ZEBRABI_TABLE_CONTAINER}`).node()).getBoundingClientRect();
                if (bb.left + bb.width + 2 >= this.totalWidth + tableContainerBB.left) {
                    let left = this.totalWidth + tableContainerBB.left - bb.width - 5;
                    Visual.columnSettings
                        .style(LEFT, left + PX);
                    if (!(<any>window).switchingDecimals) {
                        (<any>window).columnSettingsLeft = left;
                    }
                }
                if (!this.settings.proVersionActive()) {
                    bb = columnSettingsDiv.getBoundingClientRect();
                    let overlay = d3.select(columnSettingsDiv).append(DIV);
                    overlay.classed("overlay-div", true).style(HEIGHT, columnSettingsDiv.scrollHeight + PX);
                    let overlayContent = overlay.append(DIV).classed("overlay-content", true);
                    let icon = overlayContent.append(DIV).classed("lock-icon", true);
                    let availableText = overlayContent.append(P).text("Available with Pro license");
                    if (columnSettingsDiv.scrollHeight < 200) {
                        availableText
                            .style(MARGIN_TOP, "10px");
                    }
                    let buyButton = overlayContent.append(P).text("Start Pro trial").classed("learn-more", true);
                    buyButton.on(CLICK, () => {
                        // this.settings.host.launchUrl(licensingUI.FREE_TRIAL_URL);
                    });
                    drawing.applyBlur([], [columnSettingsContent, this.headerInput, line]);
                }
            });
        this.settingsArrow
            .on(MOUSEENTER, () => {
                this.columnHeader.style(BACKGROUND, null)
                uiLib.showTooltip(this.getTooltipContainer(), {
                    x: this.xPosition + this.width - 30 + this.getExtraTranslation(true),
                    y: this.yPosition + 30,
                }, "Settings", 250, 1000);
                this.showSortTooltip = true;
            });
    }

    private persistHeader(headerInput: HTMLInputElement) {
        if (headerInput.value === this.headerText) {
            return;
        }
        if (this.isColumnGrandTotalGroup() && this.groupIndex === -1) {
            this.settings.persistColumnGrandTotalLabel(headerInput.value);
        }
        else if (this.isSubtotalGroup() && this.groupIndex === -1) {
            let parent = this.chartGroup.parent;
            if (parent) {
                this.settings.persistColumnTotalLabels((<ChartGroup>parent).group, headerInput.value);
            }
        }
        else if (this.chartGroup && this.headerText === this.settings.getGroupName(this.chartGroup)) {
            this.settings.persistGroupName(this.chartGroup, headerInput.value);
        }
        else {
            this.settings.persistHeader(this.dataProperty, headerInput.value !== "" ? headerInput.value : " ");
        }
        Visual.getInstance().constructViewModelAndUpdate(this.settings);
    }

    private plotAdditionalMeasureUI(columnSettingsContent: HTMLDivElement) {
        this.addMeasureType(columnSettingsContent);
        if (this.settings.getDataPropertyShowAsTable(this.dataProperty) === ShowAsTableOptions.False) {
            this.addScaleGroup(columnSettingsContent);
        }
        this.addMarkerIcons(columnSettingsContent);
    }

    private addMeasureType(columnSettingsContent: HTMLDivElement) {
        let label = document.createElement(LABEL);
        label.textContent = "Measure type";
        label.style.display = BLOCK;
        columnSettingsContent.appendChild(label);
        let dropDown = document.createElement(SELECT);
        let columnFormat = this.settings.getColumnFormat(this.dataProperty);
        let value = new Option("Value", ColumnFormat.Value.toString(), true);
        value.selected = columnFormat === ColumnFormat.Value;
        dropDown.options.add(value);
        let percent = new Option("Percent (%)", ColumnFormat.Percent.toString(), false);
        percent.selected = columnFormat === ColumnFormat.Percent;
        dropDown.options.add(percent);
        let absolute = new Option("Absolute variance", ColumnFormat.AbsoluteDifference.toString(), false);
        absolute.selected = columnFormat === ColumnFormat.AbsoluteDifference;
        dropDown.options.add(absolute);
        let relative = new Option("Relative variance", ColumnFormat.RelativeDifference.toString(), false);
        relative.selected = columnFormat === ColumnFormat.RelativeDifference;
        dropDown.options.add(relative);
        columnSettingsContent.appendChild(dropDown);
        dropDown.style.display = BLOCK;
        label.style.display = BLOCK;
        if (this.settings.proVersionActive()) {
            d3.select(dropDown).on(CHANGE, () => {
                let selectedValue = <ColumnFormat>+dropDown.value;
                this.settings.persistColumnFormatChange(this.dataProperty, selectedValue);
            });
        }
    }

    private addScaleGroup(columnSettingsContent: HTMLDivElement) {
        let label = document.createElement(LABEL);
        label.textContent = "Scale group";
        label.style.display = BLOCK;
        columnSettingsContent.append(label);
        let optionsDiv = d3.select(columnSettingsContent).append(DIV).classed("options-div", true);
        let visualDiv = d3.select(Visual.visualDiv);
        let chartAreaGroups = visualDiv.selectAll(`.${CHART_AREA}`);
        let headers = this.headerContainer.selectAll(`.${HEADER}`);
        let categoriesArea = visualDiv.selectAll(`.${CATEGORIES_AREA}`);
        let grandTotalGroups: d3.Selection<any, any, any, any>;
        let grandTotalCategories: d3.Selection<any, any, any, any>;
        let indicators = this.headerContainer.selectAll(`${RECT}.${INDICATOR}`);
        if (this.settings.showFrozenRowGrandTotal()) {
            let grandTotal = Visual.grandTotalRow;
            if (grandTotal) {
                grandTotalGroups = Visual.grandTotalRow.selectAll(`.${CHART_AREA}`);
                grandTotalCategories = Visual.grandTotalRow.selectAll(`.${CATEGORIES_AREA}`);
            }
        }
        for (let group = 1; group <= NUMBER_OF_GROUPS; group++) {
            let groupIcon = optionsDiv
                .append(DIV)
                .classed("scale-group", true)
                .classed("icon-selected", this.settings.getScaleGroup(this.dataProperty) === group)
                .text(`${group}`)
                .style(LEFT, `${(group - 1) * 20 + 10}${PX}`);
            let relative = helpers.isRelativeMeasure(this.dataProperty, this.settings);
            let usedScaleGroups = relative ? this.plotter.relativeScaleGroups : this.plotter.absoluteScaleGroups;
            groupIcon.style(OPACITY, usedScaleGroups.indexOf(group) > -1 ? 0.6 : 0.2);
            groupIcon
                .on(CLICK, () => {
                    if (this.settings.getScaleGroup(this.dataProperty) === group) {
                        this.settings.persistScaleGroup(this.dataProperty, -1);
                    }
                    else {
                        this.settings.persistScaleGroup(this.dataProperty, group);
                    }
                })
                .on(MOUSEENTER, () => {
                    this.highlightElementsFromGroup(chartAreaGroups, group);
                    this.highlightElementsFromGroup(headers, group);
                    this.highlightElementsFromGroup(indicators, group);
                    categoriesArea.style(OPACITY, 0.1);
                    if (this.settings.showFrozenRowGrandTotal()) {
                        this.highlightElementsFromGroup(grandTotalGroups, group);
                        grandTotalCategories.style(OPACITY, 0);
                    }
                })
                .on(MOUSELEAVE, () => {
                    chartAreaGroups.style(OPACITY, 1);
                    headers.style(OPACITY, 1);
                    categoriesArea.style(OPACITY, 1);
                    indicators.style(OPACITY, 1);
                    if (this.settings.showFrozenRowGrandTotal()) {
                        grandTotalGroups.style(OPACITY, 1);
                        grandTotalCategories.style(OPACITY, 1);
                    }
                });
        }
    }

    private highlightElementsFromGroup(elements: d3.Selection<any, any, any, any>, group: number) {
        if (!elements || !elements.nodes()) {
            return;
        }
        elements.nodes().forEach((e: any) => {
            let element = <HTMLElement>e;
            let attributes = element.attributes;
            let dataPropertyAttribute = attributes.getNamedItem("dataProperty");
            let scaleGroupAttribute = attributes.getNamedItem("dataScaleGroup");
            let isCurrentElementRelative = helpers.isRelativeMeasure(this.dataProperty, this.settings);

            if (scaleGroupAttribute && (+scaleGroupAttribute.value) === group && dataPropertyAttribute &&
                isCurrentElementRelative === helpers.isRelativeMeasure(<DataProperty>+dataPropertyAttribute.value, this.settings)) {
                return;
            }
            element.style.opacity = "0.1";
        });
    }

    private addComparisonDropDown(columnSettingsDiv: HTMLElement) {
        let label = document.createElement(LABEL);
        label.textContent = "Calculation";
        label.style.display = BLOCK;
        columnSettingsDiv.appendChild(label);
        let dropDown = document.createElement(SELECT);
        this.addComparisonDropDownOptions(dropDown);
        columnSettingsDiv.appendChild(dropDown);
        dropDown.style.display = BLOCK;
        dropDown.style.borderRadius = "3px";
        label.style.display = BLOCK;
        if (this.settings.proVersionActive()) {
            d3.select(dropDown).on(CHANGE, () => {
                let selected = dropDown.value.split(" ");
                let valueScenario = <Scenario>parseInt(selected[0]);
                let referenceScenario = <Scenario>parseInt(selected[1]);
                let valuePosition = parseInt(selected[0].split("*")[1]);
                let referencePosition = parseInt(selected[1].split("*")[1]);
                this.settings.persistCalculationChange(this.dataProperty, valueScenario, referenceScenario, valuePosition, referencePosition);
            });
        }
    }

    private addComparisonDropDownOptions(dropDown: HTMLSelectElement) {
        let possibleElements = [this.viewModel.value.scenario, this.viewModel.reference.scenario];
        let possiblePositions = [this.viewModel.value.position, this.viewModel.reference.position];
        if (this.viewModel.HasSecondReferenceColumn) {
            possibleElements.push(this.viewModel.secondReference.scenario);
            possiblePositions.push(this.viewModel.secondReference.position);
        }
        if (this.viewModel.HasThirdReferenceColumn) {
            possibleElements.push(this.viewModel.thirdReference.scenario);
            possiblePositions.push(this.viewModel.thirdReference.position);
        }
        if (this.viewModel.HasFourthReferenceColumn) {
            possibleElements.push(this.viewModel.fourthReference.scenario);
            possiblePositions.push(this.viewModel.fourthReference.position);
        }
        if (this.viewModel.HasFifthReferenceColumn) {
            possibleElements.push(this.viewModel.fifthReference.scenario);
            possiblePositions.push(this.viewModel.fifthReference.position);
        }
        if (this.viewModel.HasSixthReferenceColumn) {
            possibleElements.push(this.viewModel.sixthReference.scenario);
            possiblePositions.push(this.viewModel.sixthReference.position);
        }
        if (this.viewModel.HasSeventhReferenceColumn) {
            possibleElements.push(this.viewModel.seventhReference.scenario);
            possiblePositions.push(this.viewModel.seventhReference.position);
        }
        for (let i = 0; i < possibleElements.length; i++) {
            for (let j = i + 1; j < possibleElements.length; j++) {
                if (i === j) {
                    continue;
                }
                let value = possibleElements[i];
                let reference = possibleElements[j];
                let valuePosition = possiblePositions[i];
                let referencePosition = possiblePositions[j];
                let valueHeader = helpers.getHeaderForScenario(value);
                let referenceHeader = helpers.getHeaderForScenario(reference);
                if (this.shouldAddItem(value, reference, valuePosition, referencePosition)) {
                    this.addComparisonOption(value, reference, valueHeader, referenceHeader, dropDown, valuePosition, referencePosition);
                }
                if (this.shouldAddItem(reference, value, referencePosition, valuePosition)) {
                    this.addComparisonOption(reference, value, referenceHeader, valueHeader, dropDown, referencePosition, valuePosition);
                }
            }
        }
    }

    private addComparisonOption(value: Scenario, reference: Scenario, valueHeader: string, referenceHeader: string, dropDown: HTMLSelectElement, valuePosition: number, referencePosition: number) {
        let isSelected = false;
        if (helpers.isFirstComparison(this.dataProperty) && this.settings.valueScenario === value && this.settings.referenceScenario === reference
            && this.settings.samePosition(this.settings.valuePosition, valuePosition) && this.settings.samePosition(this.settings.referencePosition, referencePosition)) {
            isSelected = true;
        }
        if (helpers.isSecondComparison(this.dataProperty) && this.settings.secondValueScenario === value && this.settings.secondReferenceScenario === reference
            && this.settings.samePosition(this.settings.secondValuePosition, valuePosition) && this.settings.samePosition(this.settings.secondReferencePosition, referencePosition)) {
            isSelected = true;
        }
        if (helpers.isThirdComparison(this.dataProperty) && this.settings.thirdValueScenario === value && this.settings.thirdReferenceScenario === reference
            && this.settings.samePosition(this.settings.thirdValuePosition, valuePosition) && this.settings.samePosition(this.settings.thirdReferencePosition, referencePosition)) {
            isSelected = true;
        }
        if (helpers.isFourthComparison(this.dataProperty) && this.settings.fourthValueScenario === value && this.settings.fourthReferenceScenario === reference
            && this.settings.samePosition(this.settings.fourthValuePosition, valuePosition) && this.settings.samePosition(this.settings.fourthReferencePosition, referencePosition)) {
            isSelected = true;
        }
        if (helpers.isFifthComparison(this.dataProperty) && this.settings.fifthValueScenario === value && this.settings.fifthReferenceScenario === reference
            && this.settings.samePosition(this.settings.fifthValuePosition, valuePosition) && this.settings.samePosition(this.settings.fifthReferencePosition, referencePosition)) {
            isSelected = true;
        }
        if (helpers.isSixthComparison(this.dataProperty) && this.settings.sixthValueScenario === value && this.settings.sixthReferenceScenario === reference
            && this.settings.samePosition(this.settings.sixthValuePosition, valuePosition) && this.settings.samePosition(this.settings.sixthReferencePosition, referencePosition)) {
            isSelected = true;
        }
        if (helpers.isSeventhComparison(this.dataProperty) && this.settings.seventhValueScenario === value && this.settings.seventhReferenceScenario === reference
            && this.settings.samePosition(this.settings.seventhValuePosition, valuePosition) && this.settings.samePosition(this.settings.seventhReferencePosition, referencePosition)) {
            isSelected = true;
        }

        let option = new Option(`${valueHeader} - ${referenceHeader}`, `${value}*${valuePosition ? valuePosition.toString() : "0"} ${reference}*${referencePosition ? referencePosition.toString() : "0"}`, false);
        option.selected = isSelected;
        dropDown.add(option);
    }

    private checkReferenceColumn(dp: DataProperty, value: Scenario, reference: Scenario, valuePosition: number, referencePosition: number) {
        if (dp === DataProperty.ReferenceValue) {
            if (this.settings.valueScenario === value && this.settings.referenceScenario === reference && this.settings.samePosition(valuePosition, this.settings.valuePosition)
                && this.settings.samePosition(referencePosition, this.settings.referencePosition) ||
                (this.settings.valueScenario === reference && this.settings.referenceScenario === value
                    && this.settings.samePosition(referencePosition, this.settings.valuePosition) && this.settings.samePosition(valuePosition, this.settings.referencePosition))) {
                return true;
            }
        }
        else if (dp === DataProperty.SecondReferenceValue) {
            if (this.settings.secondValueScenario === value && this.settings.secondReferenceScenario === reference && this.settings.samePosition(valuePosition, this.settings.secondValuePosition)
                && this.settings.samePosition(referencePosition, this.settings.secondReferencePosition) ||
                (this.settings.secondValueScenario === reference && this.settings.secondReferenceScenario === value
                    && this.settings.samePosition(referencePosition, this.settings.secondValuePosition) && this.settings.samePosition(valuePosition, this.settings.secondReferencePosition))) {
                return true;
            }
        }
        else if (dp === DataProperty.ThirdReferenceValue) {
            if (this.settings.thirdValueScenario === value && this.settings.thirdReferenceScenario === reference && this.settings.samePosition(valuePosition, this.settings.thirdValuePosition)
                && this.settings.samePosition(referencePosition, this.settings.thirdReferencePosition) ||
                (this.settings.thirdValueScenario === reference && this.settings.thirdReferenceScenario === value
                    && this.settings.samePosition(referencePosition, this.settings.thirdValuePosition) && this.settings.samePosition(valuePosition, this.settings.thirdReferencePosition))) {
                return true;
            }
        }
        else if (dp === DataProperty.FourthReferenceValue) {
            if (this.settings.fourthValueScenario === value && this.settings.fourthReferenceScenario === reference && this.settings.samePosition(valuePosition, this.settings.fourthValuePosition)
                && this.settings.samePosition(referencePosition, this.settings.fourthReferencePosition) ||
                (this.settings.fourthValueScenario === reference && this.settings.fourthReferenceScenario === value
                    && this.settings.samePosition(referencePosition, this.settings.fourthValuePosition) && this.settings.samePosition(valuePosition, this.settings.fourthReferencePosition))) {
                return true;
            }
        }
        else if (dp === DataProperty.FifthReferenceValue) {
            if (this.settings.fifthValueScenario === value && this.settings.fifthReferenceScenario === reference && this.settings.samePosition(valuePosition, this.settings.fifthValuePosition)
                && this.settings.samePosition(referencePosition, this.settings.fifthReferencePosition) ||
                (this.settings.fifthValueScenario === reference && this.settings.fifthReferenceScenario === value
                    && this.settings.samePosition(referencePosition, this.settings.fifthValuePosition) && this.settings.samePosition(valuePosition, this.settings.fifthReferencePosition))) {
                return true;
            }
        }
        else if (dp === DataProperty.SixthReferenceValue) {
            if (this.settings.sixthValueScenario === value && this.settings.sixthReferenceScenario === reference && this.settings.samePosition(valuePosition, this.settings.sixthValuePosition)
                && this.settings.samePosition(referencePosition, this.settings.sixthReferencePosition) ||
                (this.settings.sixthValueScenario === reference && this.settings.sixthReferenceScenario === value
                    && this.settings.samePosition(referencePosition, this.settings.sixthValuePosition) && this.settings.samePosition(valuePosition, this.settings.sixthReferencePosition))) {
                return true;
            }
        }
        else if (dp === DataProperty.SeventhReferenceValue) {
            if (this.settings.seventhValueScenario === value && this.settings.seventhReferenceScenario === reference && this.settings.samePosition(valuePosition, this.settings.seventhValuePosition)
                && this.settings.samePosition(referencePosition, this.settings.seventhReferencePosition) ||
                (this.settings.seventhValueScenario === reference && this.settings.seventhReferenceScenario === value
                    && this.settings.samePosition(referencePosition, this.settings.seventhValuePosition) && this.settings.samePosition(valuePosition, this.settings.seventhReferencePosition))) {
                return true;
            }
        }
    }


    private shouldAddItem(value: Scenario, reference: Scenario, valuePosition: number, referencePosition: number): boolean {
        let dataPropertiesToCheckUp = [DataProperty.ReferenceValue, DataProperty.SecondReferenceValue, DataProperty.ThirdReferenceValue, DataProperty.FourthReferenceValue,
        DataProperty.FifthReferenceValue, DataProperty.SixthReferenceValue, DataProperty.SeventhReferenceValue];

        let viewModelReferenceColumnsToCheck = [!this.viewModel.HasReferenceColumn,
        !this.viewModel.HasSecondReferenceColumn, !this.viewModel.HasThirdReferenceColumn, !this.viewModel.HasFourthReferenceColumn,
        !this.viewModel.HasFifthReferenceColumn, !this.viewModel.HasSixthReferenceColumn, !this.viewModel.HasSeventhReferenceColumn];
        if (helpers.isFirstComparison(this.dataProperty)) {
            dataPropertiesToCheckUp.splice(0, 1);
            viewModelReferenceColumnsToCheck.splice(0, 1);
        }
        else if (helpers.isSecondComparison(this.dataProperty)) {
            dataPropertiesToCheckUp.splice(1, 1);
            viewModelReferenceColumnsToCheck.splice(1, 1);
        }
        else if (helpers.isThirdComparison(this.dataProperty)) {
            dataPropertiesToCheckUp.splice(2, 1);
            viewModelReferenceColumnsToCheck.splice(2, 1);
        }
        else if (helpers.isFourthComparison(this.dataProperty)) {
            dataPropertiesToCheckUp.splice(3, 1);
            viewModelReferenceColumnsToCheck.splice(3, 1);
        }
        else if (helpers.isFifthComparison(this.dataProperty)) {
            dataPropertiesToCheckUp.splice(4, 1);
            viewModelReferenceColumnsToCheck.splice(4, 1);
        }
        else if (helpers.isSixthComparison(this.dataProperty)) {
            dataPropertiesToCheckUp.splice(5, 1);
            viewModelReferenceColumnsToCheck.splice(5, 1);
        }
        else if (helpers.isSeventhComparison(this.dataProperty)) {
            dataPropertiesToCheckUp.splice(6, 1);
            viewModelReferenceColumnsToCheck.splice(6, 1);
        }

        for (let i = 0; i < dataPropertiesToCheckUp.length; i++) {
            let dataProperty = dataPropertiesToCheckUp[i];
            let viewModelProp = viewModelReferenceColumnsToCheck[i];
            if (viewModelProp) {
                return true;
            }
            else if (this.checkReferenceColumn(dataProperty, value, reference, valuePosition, referencePosition)) {
                return false;
            }
            else {
                return true;
            }
        }
    }

    public addRemoveColumnIcon(columnSettingsDiv: HTMLElement) {
        let text = this.getRemoveColumnButtonText();
        let hideDiv = d3.select(columnSettingsDiv).append(DIV).classed("remove-div", true).append(DIV).text(text);
        if (this.settings.plottingHorizontally() && this.viewModel.numberOfGroupLevels > 0 && this.groupIndex !== -1) {
            hideDiv.text("Hide column" + ` ${ARROW_DOWN}`);
            let group = this.viewModel.lastLevelChartGroups[this.groupIndex];
            hideDiv.on(CLICK, () => {
                this.showHideColumnDropdown(hideDiv, group);
            });
        }
        else {
            hideDiv.on(CLICK, () => {
                if (this.isColumnGrandTotalGroup() && this.groupIndex === -1) {
                    this.settings.showColumnGrandTotal = false;
                    this.settings.persistShowColumnGrandTotal();
                }
                else if (this.isSubtotalGroup() && this.groupIndex === -1) {
                    this.settings.showColumnTotals = false;
                    this.settings.persistShowColumnTotals();
                }
                else {
                    this.settings.hiddenColumnsChange(<number>this.dataProperty);
                }
            });
        }
    }

    public getRemoveColumnButtonText() {
        if (this.isColumnGrandTotalGroup() && this.groupIndex === -1) {
            return "Hide grand total";
        }
        else if (this.isSubtotalGroup() && this.groupIndex === -1) {
            return "Hide subtotals";
        }
        else {
            return "Hide column";
        }
    }

    private showHideColumnDropdown(removeDiv: d3.Selection<HTMLElement, any, any, any>, group: ChartGroup) {
        Visual.columnRemoveList.selectAll("*").remove();
        let display = Visual.columnRemoveList.style(DISPLAY);
        if (display === BLOCK) {
            Visual.columnRemoveList.style(DISPLAY, NONE);
            return;
        }
        Visual.columnRemoveList.style(DISPLAY, BLOCK);
        let columnSettingBB = drawing.getBoundingBoxHtml(<HTMLElement>Visual.columnSettings.node());
        let removeDivElement = (<HTMLElement>removeDiv.node());
        let bb = removeDivElement.getBoundingClientRect();
        let top = bb.top + 30;
        if (top + bb.height + 30 > this.plotter.height) {
            top = bb.top - 47;
        }
        Visual.columnRemoveList
            .style(LEFT, columnSettingBB.left + 64 + PX)
            .style(TOP, top + PX);
        let parents = helpers.getParents(group);
        let groups: ChartGroup[];
        if (this.isColumnGrandTotalGroup()) {
            groups = [group];
        }
        else if (this.isSubtotalGroup()) {
            groups = parents.reverse();
        }
        else {
            groups = [group].concat(parents).reverse();
        }
        groups.forEach(g => {
            let hideFromGroupOptions = Visual.columnRemoveList.append(DIV).classed("column-remove-option", true).text("Hide from " + g.group);
            hideFromGroupOptions.on(CLICK, () => {
                this.settings.removedColumnsChangeGroup(g.group, <number>this.dataProperty);
                this.settings.persistColumnsHiddenFromGroups();
            });
        });
        let hideFromAllOption = Visual.columnRemoveList.append(DIV).classed("column-remove-option", true).text("Hide from all groups");
        hideFromAllOption.on(CLICK, () => {
            this.settings.hiddenColumnsChange(<number>this.dataProperty);
        });
    }

    public addChartIcons(columnSettingsDiv: HTMLElement, dataProperty: DataProperty) {
        let label = document.createElement(LABEL);
        label.textContent = "Chart type";
        label.style.display = BLOCK;
        if (this.onlyShowChartChangeIcons) {
            label.style.marginTop = 5 + PX;
        }
        columnSettingsDiv.appendChild(label);
        let chartTypeDiv = document.createElement(DIV);
        chartTypeDiv.className = "options-div";
        columnSettingsDiv.appendChild(chartTypeDiv);
        let svg = drawing.createSvgElement(chartTypeDiv, EMPTY);
        let width = this.drawIcon(this.chartType, columnSettingsDiv, svg, this.viewModel, 20, dataProperty, this.plotter);
        svg
            .style(WIDTH, width + PX)
            .style(HEIGHT, 20 + PX);

    }

    // tslint:disable-next-line: max-func-body-length
    public addFormatColumnIcons(columnSettingsDiv: HTMLElement) {
        let columnTextColor = this.settings.getColumnTextColor(this.dataProperty, BLACK);
        let columnFormattingLabel = document.createElement(LABEL);
        columnFormattingLabel.textContent = "Format column";
        columnFormattingLabel.style.display = BLOCK;
        columnSettingsDiv.appendChild(columnFormattingLabel);
        let optionsDiv = d3.select(columnSettingsDiv).append(DIV).classed("options-div", true);

        //append labels
        let numberFormattingLabel = document.createElement(LABEL);
        numberFormattingLabel.textContent = "Decimal places";
        numberFormattingLabel.style.display = NONE;
        columnSettingsDiv.appendChild(numberFormattingLabel);
        let formatOptionsDiv = d3.select(columnSettingsDiv).append(DIV);
        let bb = drawing.getBoundingBoxHtml(<HTMLElement>optionsDiv.node());
        let textColorHiddenMargin = 30;

        // Add text color option
        if (!helpersLib.isDifferenceMeasure(this.dataProperty, this.settings.getColumnFormat(this.dataProperty)) || this.chartType === ChartType.Table) {
            textColorHiddenMargin = 0;
            let textColorIcon = optionsDiv
                .append(DIV)
                .classed("special", true)
                .classed(ICON, true)
                .style(WIDTH, 20 + PX)
                .style(HEIGHT, 20 + PX)
                .style(DISPLAY, "inline")
                .style(FLOAT, "left")
                .style(POSITION, RELATIVE)
                .append(DIV).attr(ID, "color-picker-container-btn");
            textColorIcon.append(P)
                .style(TEXT_ALIGN, CENTER)
                .style(FONT_SIZE, 18 + FONT_SIZE_UNIT)
                .style(LINE_HEIGHT, 18 + FONT_SIZE_UNIT)
                .style(MARGIN, 0)
                .text("A");
            textColorIcon.on(MOUSEOVER, () => {
                let bb = drawing.getBoundingBoxHtml(<HTMLElement>optionsDiv.node());
                uiLib.showTooltip(this.getTooltipContainer(), {
                    x: bb.left + (<any>window).currentScrollLeft + this.getExtraTranslation(false) - this.plotter.getCommentBoxOffsetLeft(),
                    y: bb.top + ICON_TOOLTIP_Y_OFFSET - this.plotter.titleHeight - this.plotter.getCommentBoxOffsetTop()
                }, "Font color", 250, 1000);
                this.showSortTooltip = true;
            }).on(MOUSELEAVE, () => {
                d3.selectAll("." + HEADER_TOOLTIP).remove();
            });

            let textColorIconRect = textColorIcon.append(DIV);
            textColorIconRect.classed("fill-icon-rect", true)
                .style(BACKGROUND, columnTextColor);


            const fontColorPicker = Pickr.create({
                el: '#color-picker-container-btn',
                container: '#sandbox-host',
                theme: 'monolith',
                swatches: null,
                closeWithKey: 'Escape',
                appClass: 'fontpickr',
                adjustableNumbers: true,
                lockOpacity: true,
                default: columnTextColor,
                useAsButton: true,
                autoReposition: true,
                defaultRepresentation: 'RGBA',

                components: {
                    preview: true,
                    hue: true,
                    interaction: {
                        save: true,
                        clear: true,
                        input: true,
                    },
                },
            });

            let fontColorPickerChanged = false;
            let fontColorPickerRevertBack = true;
            let labelsSelection = d3.selectAll(`.${LABEL}${this.columnOrder}`);

            fontColorPicker.on("save", (color) => {
                fontColorPickerRevertBack = false;
                fontColorPicker.hide();
                this.settings.persistTextColor(this.dataProperty, color);
            }).on("clear", () => {
                fontColorPickerRevertBack = true;
                fontColorPicker.hide();
                this.settings.persistTextColor(this.dataProperty, null);
            }).on("show", () => {
                fontColorPickerChanged = false;
                fontColorPickerRevertBack = true;
                this.fixPickerPosition(".fontpickr");
            }).on("change", (color) => {
                fontColorPickerChanged = true;
                labelsSelection.attr(FILL, this.settings.getColorString(color));
                textColorIconRect.style(BACKGROUND, this.settings.getColorString(color))
            }).on("hide", () => {
                if (fontColorPickerChanged && fontColorPickerRevertBack) {
                    labelsSelection.attr(FILL, columnTextColor)
                    textColorIconRect.style(BACKGROUND, columnTextColor)
                }
            });
        }

        // Add background fill option
        let fillIcon = optionsDiv.append(DIV);
        fillIcon
            .attr(ID, "color-picker-container-fill")
            .attr(WIDTH, 20)
            .attr(HEIGHT, 20)
            .style(MARGIN_LEFT, (15 + textColorHiddenMargin / 10) + PX)
            .style(LEFT, 33 - textColorHiddenMargin + PX)
            .on(MOUSEOVER, () => {
                let bb = drawing.getBoundingBoxHtml(<HTMLElement>optionsDiv.node());
                uiLib.showTooltip(this.getTooltipContainer(), {
                    x: bb.left + 20 + (<any>window).currentScrollLeft - textColorHiddenMargin + this.getExtraTranslation(false) - this.plotter.getCommentBoxOffsetLeft(),
                    y: bb.top + ICON_TOOLTIP_Y_OFFSET - this.plotter.titleHeight - this.plotter.getCommentBoxOffsetTop()
                }, "Background fill", 250, 1000);
                this.showSortTooltip = true;
            }).on(MOUSELEAVE, () => {
                d3.selectAll("." + HEADER_TOOLTIP).remove();
            })
            .classed("fill-rect", true);
        let headerBackgroundFill = this.settings.getColumnBackgroundFill(this.dataProperty);
        headerBackgroundFill = headerBackgroundFill === "" ? WHITE : headerBackgroundFill;
        let fillIconRect = fillIcon.append(DIV);
        fillIconRect.classed("fill-icon-rect", true).style(BACKGROUND, headerBackgroundFill);

        const backgroundColorPicker = Pickr.create({
            el: '#color-picker-container-fill',
            container: '#sandbox-host',
            theme: 'monolith',
            swatches: null,
            appClass: 'fillpickr',
            closeWithKey: 'Escape',
            adjustableNumbers: true,
            default: headerBackgroundFill,
            useAsButton: true,
            autoReposition: true,
            defaultRepresentation: 'RGBA',

            components: {
                preview: true,
                hue: true,
                opacity: true,
                interaction: {
                    save: true,
                    clear: true,
                    input: true,
                },
            },
        });
        let backgroundColorPickerColorChanged = false;
        let backgroundColorPickerRevertBack = true;
        let backgroundColorPickerSelection = d3.selectAll(`.${RECT_BACKGROUND}_${this.columnOrder}`);

        backgroundColorPicker.on("save", (color) => {
            backgroundColorPickerRevertBack = false;
            backgroundColorPicker.hide();
            this.settings.persistBackgroundFill(this.dataProperty, color);
        }).on("clear", () => {
            backgroundColorPickerRevertBack = true;
            this.settings.persistBackgroundFill(this.dataProperty, null);
        }).on("show", () => {
            backgroundColorPickerColorChanged = false;
            backgroundColorPickerRevertBack = true;
            this.fixPickerPosition(".fillpickr");
        }).on("change", (color) => {
            backgroundColorPickerColorChanged = true;
            backgroundColorPickerSelection.attr(FILL, this.settings.getColorString(color)).attr(OPACITY, 1);
            fillIconRect.style(BACKGROUND, this.settings.getColorString(color))
        }).on("hide", () => {
            if (backgroundColorPickerColorChanged && backgroundColorPickerRevertBack) {
                backgroundColorPickerSelection.attr(FILL, headerBackgroundFill)
                    .attr(OPACITY, headerBackgroundFill === WHITE ? 0 : 1)
                    .attr(FILL, headerBackgroundFill);
                fillIconRect.style(BACKGROUND, headerBackgroundFill)
            }
        });

        // Add bold text option
        let boldIcon = optionsDiv.append(DIV).classed("special", this.settings.isHeaderBold(this.dataProperty));
        boldIcon
            .attr(WIDTH, 20)
            .attr(HEIGHT, 20)
            .classed("bold-rect", true)
            .classed(ICON, true)
            .classed("icon-selected", this.settings.isHeaderBold(this.dataProperty)).text("B")
            .style(LEFT, 63 - textColorHiddenMargin + PX)
            .style(MARGIN_LEFT, (10 + textColorHiddenMargin / 10) + PX);
        boldIcon.on(CLICK, () => {
            this.settings.toggleAndPersistColumnBold(this.dataProperty);
        })
            .on(MOUSEOVER, () => {
                let bb = drawing.getBoundingBoxHtml(<HTMLElement>optionsDiv.node());
                uiLib.showTooltip(this.getTooltipContainer(), {
                    x: bb.left + 40 + (<any>window).currentScrollLeft - textColorHiddenMargin + this.getExtraTranslation(false) - this.plotter.getCommentBoxOffsetLeft(),
                    y: bb.top + ICON_TOOLTIP_Y_OFFSET - this.plotter.titleHeight - this.plotter.getCommentBoxOffsetTop()
                }, "Font weight", 250, 1000);
                this.showSortTooltip = true;
            }).on(MOUSELEAVE, () => {
                d3.selectAll("." + HEADER_TOOLTIP).remove();
            });

        // Add border color icon
        let headerBorderColor = this.settings.getColumnBorder(this.dataProperty);
        headerBorderColor === "" ? WHITE : headerBorderColor
        let borderIcon = optionsDiv.append(DIV);
        borderIcon
            .attr(ID, "color-picker-container-border")
            .attr(WIDTH, 20)
            .attr(HEIGHT, 20)
            .style(MARGIN_LEFT, (5 + textColorHiddenMargin / 10) + PX)
            .style(LEFT, 93 - textColorHiddenMargin + PX)
            .on(MOUSEOVER, () => {
                let bb = drawing.getBoundingBoxHtml(<HTMLElement>optionsDiv.node());
                uiLib.showTooltip(this.getTooltipContainer(), {
                    x: bb.left + 70 + (<any>window).currentScrollLeft - textColorHiddenMargin + this.getExtraTranslation(false) - this.plotter.getCommentBoxOffsetLeft(),
                    y: bb.top + ICON_TOOLTIP_Y_OFFSET - this.plotter.titleHeight - this.plotter.getCommentBoxOffsetTop()
                }, "Border color", 250, 1000);
                this.showSortTooltip = true;
            }).on(MOUSELEAVE, () => {
                d3.selectAll("." + HEADER_TOOLTIP).remove();
            })
            .classed("border-rect", true);

        const borderpickr = Pickr.create({
            el: '#color-picker-container-border',
            container: '#sandbox-host',
            theme: 'monolith',
            swatches: null,
            appClass: 'borderpickr',
            closeWithKey: 'Escape',
            adjustableNumbers: true,
            default: headerBorderColor,
            useAsButton: true,
            autoReposition: true,
            defaultRepresentation: 'RGBA',

            components: {
                preview: true,
                hue: true,
                interaction: {
                    save: true,
                    clear: true,
                    input: true,
                },
            },
        });

        let borderPickerChanged = false;
        let borderPickerRevertBack = true;
        let borderSelection = d3.selectAll(`.${LINE_BORDER}_${this.columnOrder}`)

        borderpickr.on("save", (color) => {
            borderPickerRevertBack = false;
            borderpickr.hide();
            this.settings.persistBorder(this.dataProperty, color);
        }).on("clear", () => {
            borderPickerRevertBack = true;
            this.settings.persistBorder(this.dataProperty, null);
        }).on("show", () => {
            borderPickerChanged = false;
            borderPickerRevertBack = true;
            this.fixPickerPosition(".borderpickr");
        }).on("change", (color) => {
            borderPickerChanged = true;
            borderSelection.attr(STROKE, this.settings.getColorString(color))
        }).on("hide", () => {
            if (borderPickerChanged && borderPickerRevertBack) {
                borderSelection.attr(STROKE, this.settings.getColorString(headerBorderColor))
                    .attr(OPACITY, 1);
            }
        });

        // Add number formatting options
        if (helpersLib.isAdditionalMeasure(this.dataProperty)) {
            let numberFormattingIcon = optionsDiv.append(DIV).classed("special", true);
            numberFormattingIcon
                .attr(WIDTH, 20)
                .attr(HEIGHT, 20)
                .classed("formatting-rect", true).text("1 2")
                .style(LEFT, 123 - 9 / 10 * textColorHiddenMargin + PX);

            numberFormattingIcon.on(CLICK, () => {
                this.handleNumberFormattingClick(numberFormattingIcon, formatOptionsDiv, numberFormattingLabel);
            })
                .on(MOUSEOVER, () => {
                    let bb = drawing.getBoundingBoxHtml(<HTMLElement>optionsDiv.node());
                    uiLib.showTooltip(this.getTooltipContainer(), {
                        x: bb.left + 90 + (<any>window).currentScrollLeft - textColorHiddenMargin + this.getExtraTranslation(false) - this.plotter.getCommentBoxOffsetLeft(),
                        y: bb.top + ICON_TOOLTIP_Y_OFFSET - this.plotter.titleHeight - this.plotter.getCommentBoxOffsetTop()
                    }, "Number format", 250, 1000);
                    this.showSortTooltip = true;
                }).on(MOUSELEAVE, () => {
                    d3.selectAll("." + HEADER_TOOLTIP).remove();
                });

            if ((<any>window).switchingDecimals && this.dataProperty === (<any>window).switchDataProperty) {
                this.handleNumberFormattingClick(numberFormattingIcon, formatOptionsDiv, numberFormattingLabel);
            }
        }
    }

    private addSuppressOthersCheckbox(columnSettingsDiv: HTMLElement) {
        let suppressOthersDiv = <HTMLDivElement>document.createElement(DIV);
        suppressOthersDiv.className = ELASTIC;
        suppressOthersDiv.style.marginBottom = "8px";
        suppressOthersDiv.style.marginLeft = "-3px";
        suppressOthersDiv.style.marginRight = "-3px";
        if (this.isGroupHeader) {
            suppressOthersDiv.style.marginTop = "8px";
        }

        let suppressOthersCheckbox = <HTMLInputElement>document.createElement(INPUT);
        let suppressOthersState = this.settings.getSuppressOthers(this.dataProperty);
        suppressOthersCheckbox.type = CHECKBOX;
        suppressOthersCheckbox.id = "suppressOthers";
        suppressOthersCheckbox.checked = suppressOthersState;
        suppressOthersCheckbox.style.height = "13px";
        suppressOthersCheckbox.style.width = "13px";
        suppressOthersCheckbox.style.margin = "10px 0 0 0";
        suppressOthersCheckbox.style.display = "inline";
        suppressOthersDiv.appendChild(suppressOthersCheckbox);

        let label = document.createElement(LABEL);
        label.textContent = "Suppress others";
        label.setAttribute("for", "suppressOthers");
        label.style.marginLeft = "5px";
        label.style.position = RELATIVE;
        label.style.top = "-2px";
        suppressOthersDiv.appendChild(label);

        suppressOthersDiv.addEventListener(CLICK, (e) => {
            e.preventDefault();
            this.settings.changeSuppressOthersAndPersist(this.dataProperty, !suppressOthersState);
        });
        columnSettingsDiv.appendChild(suppressOthersDiv);
    }

    // tslint:disable-next-line: max-func-body-length
    public addColumnTotalFormatIcons(columnSettingsDiv: HTMLElement, level: number) {
        let isColumnGrandTotal = level === 0 ? true : false;
        let columnTotalBackgroundColor = this.settings.getColumnTotalBackgroundColor(level);
        columnTotalBackgroundColor = columnTotalBackgroundColor === "" ? BLACK : columnTotalBackgroundColor;
        let columnFormattingLabel = document.createElement(LABEL);
        columnFormattingLabel.textContent = isColumnGrandTotal ? "Format grand total" : "Format subtotal";
        columnFormattingLabel.style.display = BLOCK;
        columnSettingsDiv.appendChild(columnFormattingLabel);
        let optionsDiv = d3.select(columnSettingsDiv).append(DIV).classed("options-div", true);

        //append labels
        let numberFormattingLabel = document.createElement(LABEL);
        numberFormattingLabel.textContent = "Decimal places";
        numberFormattingLabel.style.display = NONE;
        columnSettingsDiv.appendChild(numberFormattingLabel);
        let textColorHiddenMargin = 30;

        let fillIcon = optionsDiv.append(DIV);
        fillIcon
            .attr(ID, "color-picker-container-fill")
            .attr(WIDTH, 20)
            .attr(HEIGHT, 20)
            .style(MARGIN_LEFT, (15 + textColorHiddenMargin / 10) + PX)
            .style(LEFT, 33 - textColorHiddenMargin + PX)
            .on(MOUSEOVER, () => {
                let bb = drawing.getBoundingBoxHtml(<HTMLElement>optionsDiv.node());
                uiLib.showTooltip(this.getTooltipContainer(), {
                    x: bb.left + 20 + (<any>window).currentScrollLeft - textColorHiddenMargin + this.getExtraTranslation(false) - this.plotter.getCommentBoxOffsetLeft(),
                    y: bb.top + ICON_TOOLTIP_Y_OFFSET - this.plotter.titleHeight - this.plotter.getCommentBoxOffsetTop()
                }, "Background fill", 250, 1000);
                this.showSortTooltip = true;
            }).on(MOUSELEAVE, () => {
                d3.selectAll("." + HEADER_TOOLTIP).remove();
            })
            .classed("fill-rect", true);
        let fillIconRect = fillIcon.append(DIV);
        fillIconRect.classed("fill-icon-rect", true).style(BACKGROUND, columnTotalBackgroundColor);

        const backgroundColorPicker = Pickr.create({
            el: '#color-picker-container-fill',
            container: '#sandbox-host',
            theme: 'monolith',
            swatches: null,
            appClass: 'fillpickr',
            closeWithKey: 'Escape',
            adjustableNumbers: true,
            default: columnTotalBackgroundColor,
            useAsButton: true,
            autoReposition: true,
            defaultRepresentation: 'RGBA',

            components: {
                preview: true,
                hue: true,
                opacity: true,
                interaction: {
                    save: true,
                    clear: true,
                    input: true
                },
            },
        });

        let backgroundColorPickrColorChanged = false;
        let backgroundColorPickrColorRevertBack = true;
        let backgroundSelection = d3.selectAll(`.${RECT_TOTAL_BACKGROUND}_${level}`)

        backgroundColorPicker.on("save", (color) => {
            backgroundColorPickrColorRevertBack = false;
            backgroundColorPicker.hide();
            this.settings.persistColumnTotalBackgroundColor(level, color);
        }).on("clear", () => {
            backgroundColorPickrColorRevertBack = true;
            backgroundColorPicker.hide();
            this.settings.persistColumnTotalBackgroundColor(level, null);
        }).on("show", () => {
            backgroundColorPickrColorChanged = false;
            backgroundColorPickrColorRevertBack = true;
            this.fixPickerPosition(".fillpickr");
        }).on("change", (color) => {
            backgroundColorPickrColorChanged = true;
            backgroundSelection.attr(FILL, this.settings.getColorString(color)).attr(OPACITY, 1);
            fillIconRect.style(BACKGROUND, this.settings.getColorString(color))
        }).on("hide", () => {
            if (backgroundColorPickrColorChanged && backgroundColorPickrColorRevertBack) {
                backgroundSelection.attr(FILL, columnTotalBackgroundColor)
                    .attr(OPACITY, columnTotalBackgroundColor === BLACK ? 0 : 1)
                fillIconRect.style(BACKGROUND, columnTotalBackgroundColor)
            }
        });

        // Add border color icon
        let grandTotalBorderColor = this.settings.getColumnTotalBorderColor(level);
        grandTotalBorderColor = grandTotalBorderColor === "" ? BLACK : grandTotalBorderColor;
        let borderIcon = optionsDiv.append(DIV);
        borderIcon
            .attr(ID, "color-picker-container-border")
            .attr(WIDTH, 20)
            .attr(HEIGHT, 20)
            .style(MARGIN_LEFT, (5 + textColorHiddenMargin / 10) + PX)
            .style(LEFT, 73 - textColorHiddenMargin + PX)
            .on(MOUSEOVER, () => {
                let bb = drawing.getBoundingBoxHtml(<HTMLElement>optionsDiv.node());
                uiLib.showTooltip(this.getTooltipContainer(), {
                    x: bb.left + 70 + (<any>window).currentScrollLeft - textColorHiddenMargin + this.getExtraTranslation(false) - this.plotter.getCommentBoxOffsetLeft(),
                    y: bb.top + ICON_TOOLTIP_Y_OFFSET - this.plotter.titleHeight - this.plotter.getCommentBoxOffsetTop()
                }, "Border color", 250, 1000);
                this.showSortTooltip = true;
            }).on(MOUSELEAVE, () => {
                d3.selectAll("." + HEADER_TOOLTIP).remove();
            })
            .classed("border-rect", true);

        const borderpickr = Pickr.create({
            el: '#color-picker-container-border',
            container: '#sandbox-host',
            theme: 'monolith',
            swatches: null,
            appClass: 'borderpickr',
            closeWithKey: 'Escape',
            adjustableNumbers: true,
            default: grandTotalBorderColor === "" ? BLACK : grandTotalBorderColor,
            useAsButton: true,
            autoReposition: true,
            defaultRepresentation: 'RGBA',

            components: {
                preview: true,
                hue: true,
                interaction: {
                    save: true,
                    clear: true,
                    input: true,
                },
            },
        });

        let borderpickrColorChanged = false;
        let borderpickrRevertBack = true;
        let borderTotalSelection = d3.selectAll(`.${LINE_TOTAL_BORDER}_${level}`)

        borderpickr.on("save", (color) => {
            borderpickrRevertBack = false;
            borderpickr.hide();
            this.settings.persistColumnTotalBorderColor(level, color);
        }).on("clear", () => {
            borderpickrRevertBack = true;
            borderpickr.hide();
            this.settings.persistColumnTotalBorderColor(level, null);
        }).on("show", () => {
            borderpickrColorChanged = false;
            borderpickrRevertBack = true;
            this.fixPickerPosition(".borderpickr");
        }).on("change", (color) => {
            borderpickrColorChanged = true;
            borderTotalSelection.attr(STROKE, this.settings.getColorString(color)).attr(OPACITY, 1);
        }).on("hide", () => {
            if (borderpickrColorChanged && borderpickrRevertBack) {
                borderTotalSelection.attr(STROKE, grandTotalBorderColor)
                    .attr(OPACITY, columnTotalBackgroundColor === BLACK ? 0 : 1);
            }
        });
    }

    public handleNumberFormattingClick(numberFormattingIcon: d3.Selection<any, any, any, any>, formatOptionsDiv: d3.Selection<any, any, any, any>, numberFormattingLabel: HTMLLabelElement) {
        if (numberFormattingIcon.classed("special")) {
            formatOptionsDiv.classed("options-div", true).style(WIDTH, 140 + PX);
            numberFormattingIcon.classed("special", false);
            numberFormattingLabel.style.display = BLOCK;

            //append decimal select
            let decSelector = formatOptionsDiv.append(SELECT).classed("decimal-input", true);
            let optionsDecimalPlaces = [
                { "-": "-1" },
                { "0": "0" },
                { "1": "1" },
                { "2": "2" },
                { "3": "3" },
                { "4": "4" }];
            let columnDecimalPlaces = this.settings.getColumnViewSetting(this.dataProperty, "decimalPlaces");
            optionsDecimalPlaces.forEach((element) => {
                let key = Object.keys(element)[0];
                let value = Object.values(element)[0];
                decSelector.append("option")
                    .attr(VALUE, value)
                    .text(key)
                    .attr(SELECTED, value === columnDecimalPlaces.toString() ? "selected" : null);
                decSelector.on(CHANGE, () => {
                    this.settings.persistColumnDecimalPlaces(this.dataProperty, parseInt((<HTMLSelectElement>decSelector.node()).value));
                })
            });

            //append units selector
            let columnFormat = this.settings.getColumnFormat(this.dataProperty);
            if (columnFormat === ColumnFormat.Value || columnFormat === ColumnFormat.AbsoluteDifference) {
                numberFormattingLabel.textContent = "Decimal places, units";
                let options = ["-", "Auto", "None", "Thousands", "Millions", "Billions", "Percent (%)"];
                let values = [DisplayUnits.Default, DisplayUnits.Auto, DisplayUnits.None, DisplayUnits.Thousands, DisplayUnits.Millions, DisplayUnits.Billions, DisplayUnits.Percent];
                let unitSelector = formatOptionsDiv.append(SELECT).classed("unit-select", true);

                for (let i = 0; i < options.length; i++) {
                    unitSelector.append(OPTION)
                        .attr(VALUE, values[i])
                        .attr(SELECTED, this.settings.getColumnViewSetting(this.dataProperty, "displayUnits") === values[i] ? "selected" : null)
                        .text(options[i]);
                }
                unitSelector.on(CHANGE, () => {
                    this.settings.persistColumnDisplayUnits(this.dataProperty, (<HTMLSelectElement>unitSelector.node()).value);
                })
            } else if (columnFormat === ColumnFormat.Percent || columnFormat === ColumnFormat.RelativeDifference) {
                numberFormattingLabel.textContent = "Decimal places, % symbol";
                let options = ["", "%"];
                let values = [0, 1];
                let percentSelector = formatOptionsDiv.append(SELECT).classed("unit-select", true);

                for (let i = 0; i < options.length; i++) {
                    percentSelector.append(OPTION)
                        .attr(VALUE, values[i])
                        .attr(SELECTED, this.settings.getAdditionalMeasurePercentageInLabel(this.dataProperty) === values[i] ? "selected" : null)
                        .text(options[i]);
                }
                percentSelector.on(CHANGE, () => {
                    this.settings.persistAdditionalMeasurePercentageInLabel(this.dataProperty, parseInt((<HTMLSelectElement>percentSelector.node()).value));
                })
            }
        } else {
            numberFormattingLabel.style.display = NONE;
            formatOptionsDiv.classed("options-div", false);
            numberFormattingIcon.classed("special", true);
            d3.selectAll('.decimal-input').remove();
            d3.selectAll('.unit-select').remove();
        }
    }

    public addMarkerIcons(columnSettingsDiv: HTMLElement) {
        let label = document.createElement(LABEL);
        label.textContent = "Scenario patterns";
        label.style.display = BLOCK;
        columnSettingsDiv.appendChild(label);
        let svg = drawing.createSvgElement(columnSettingsDiv, EMPTY)
            .attr(WIDTH, "140px")
            .attr(HEIGHT, "40px");
        this.drawMarkerIcons(svg);
    }

    public drawMarkerIcons(svg: d3.Selection<SVGElement, any, any, any>) {
        let currentselected = svg.append(G);
        let selectedMarkerStyle = this.settings.getMarkerStyle(this.dataProperty);
        let isSecondRow = selectedMarkerStyle > 5;
        currentselected.append(RECT)
            .attr(X, (isSecondRow ? selectedMarkerStyle - 6 : selectedMarkerStyle) * 28 + 1)
            .attr(Y, isSecondRow ? 21 : 1)
            .attr(WIDTH, 24)
            .attr(HEIGHT, 14)
            .attr(STROKE, "blue")
            .attr(FILL, WHITE)
            .attr(STROKE_WIDTH, 2);

        this.addSingleMarkerIcon(svg, MarkerStyle.Actual, "Actual", 0, 1);
        this.addSingleMarkerIcon(svg, MarkerStyle.PreviousYear, "Previous year", 28, 1);
        this.addSingleMarkerIcon(svg, MarkerStyle.Plan, "Plan", 56, 1);
        this.addSingleMarkerIcon(svg, MarkerStyle.Forecast, "Forecast", 84, 1);
        this.addSingleMarkerIcon(svg, MarkerStyle.Gridline, "Gridline", 112, 1);

        this.addSingleMarkerIcon(svg, MarkerStyle.Plan2, "Plan 2", 0, 2);
        this.addSingleMarkerIcon(svg, MarkerStyle.Plan3, "Plan 3", 28, 2);
        this.addSingleMarkerIcon(svg, MarkerStyle.Forecast2, "Forecast 2", 56, 2);
        this.addSingleMarkerIcon(svg, MarkerStyle.Forecast3, "Forecast 3", 84, 2);
    }

    public fixPickerPosition(classname: string) {
        let fontPickerSelection = d3.select(classname);
        let pickerheight = parseInt(fontPickerSelection.style(HEIGHT));
        if (this.plotter.height < pickerheight + parseInt(fontPickerSelection.style(TOP))) {
            fontPickerSelection
                .style(TOP, (this.plotter.height - pickerheight) / 2 - 10 + PX);
        }
    }

    public addSingleMarkerIcon(svg: d3.Selection<SVGElement, any, any, any>, markerStyle: MarkerStyle, text: string, xPos: number, row: number) {
        let icon = svg.append(G);
        icon.append(RECT)
            .attr(X, 3 + xPos)
            .attr(Y, 3 + (row - 1) * 20)
            .attr(WIDTH, 20)
            .attr(HEIGHT, 10);
        icon.on(CLICK, () => {
            if (markerStyle === this.settings.getMarkerStyle(this.dataProperty)) {
                this.settings.persistMarkerStyleChange(this.dataProperty, MarkerStyle.None);
            } else {
                this.settings.persistMarkerStyleChange(this.dataProperty, markerStyle);
            }
        })
            .on(MOUSEOVER, () => {
                let bb = drawing.getBoundingBoxHtml(<HTMLElement>(<unknown>svg.node()));
                uiLib.showTooltip(this.getTooltipContainer(), {
                    x: bb.left + xPos + 10 + (<any>window).currentScrollLeft + this.getExtraTranslation(false) - this.plotter.getCommentBoxOffsetLeft(),
                    y: bb.top + ICON_TOOLTIP_Y_OFFSET - this.plotter.titleHeight - this.plotter.getCommentBoxOffsetTop()
                }, text, 250, 1000);
                this.showSortTooltip = true;
            }).on(MOUSELEAVE, () => {
                d3.selectAll("." + HEADER_TOOLTIP).remove();
            });
        if (markerStyle !== MarkerStyle.Gridline) {
            styles.applyMarkerStyleToElement(icon, this.settings.colorScheme.neutralColor, markerStyle, this.settings.chartStyle, this.settings.colorScheme);
        } else {
            this.plotGridlineMarkerIcon(icon, this.settings.colorScheme.neutralColor);
        }
    }

    private plotGridlineMarkerIcon(icon: d3.Selection<SVGElement, any, any, any>, color: string) {
        icon.select(RECT)
            .attr(FILL, "#E8E8E8");
        icon.append(LINE)
            .attr(X1, 115)
            .attr(X2, 135)
            .attr(Y1, 11)
            .attr(Y2, 11)
            .attr(STROKE, GRAY)
            .attr(STROKE_WIDTH, 3);
    }

    // tslint:disable-next-line: max-func-body-length
    private addDragAndDropHandlers() {
        let settings = this.settings;
        let columnHeader = this.columnHeader;
        let currentHeader = this;
        let startingHeaderPosition = this.startingHeaderPosition;
        let swaps = [];
        let tooltipMessagePosition: Position = { x: this.currentHeaderPosition.x, y: this.currentHeaderPosition.y + this.expectedHeight + 12 };
        let draggable = d3.drag()
            .subject(function () {
                let t = d3.select(this);
                return { x: +t.attr(X), y: +t.attr(Y) };
            })
            .on(DRAG, drg)
            .on(DRAGSTART, () => {
                swaps = [];
                this.settings.dragStarted = true;
                this.dragStart = performance.now();
                columnHeader
                    .style(BACKGROUND, "rgba(255,255,255,0.9)")
                    .style(BOX_SHADOW, "0 12px 10px 1px rgba(0, 0, 0, 0.14), 0 5px 14px 2px rgba(0, 0, 0, 0.12), 0 7px 5px -3px rgba(0, 0, 0, 0.2)");
                columnHeader
                    .on(MOUSEENTER, null)
                    .on(MOUSELEAVE, null)
                    .on(MOUSEOVER, null)
                    .on(CLICK, null);
                currentHeader.previousDragPosition = +(<any>d3.event).x + startingHeaderPosition.x;
            })
            .on(DRAGEND, () => {
                let currentGroupHeaders = settings.getHeadersForCurrentGroup(currentHeader);
                if (currentGroupHeaders.some(header => header.currentColumnOrder !== header.startingColumnOrder)) {
                    if (this.settings.proVersionActive()) {
                        this.settings.columnSettings.forEach(setting => {
                            if (setting.order === undefined) {
                                setting.order = null;
                            }
                        });

                        // iterating through the sorting orders and incrementing on duplicates (and all the other remaining orders) 
                        // e.g.: [0, 1, 0, 2, 3] => [0, 2, 1, 3, 4] 
                        let headersCurrentColumnOrders = currentGroupHeaders.map(header => header.currentColumnOrder);
                        for (let i = 0; i < headersCurrentColumnOrders.length - 1 && (new Set(headersCurrentColumnOrders)).size !== headersCurrentColumnOrders.length; i++) {
                            let currentOrder = headersCurrentColumnOrders[i];
                            if (headersCurrentColumnOrders.slice(i + 1).indexOf(currentOrder) >= 0) {
                                headersCurrentColumnOrders = (headersCurrentColumnOrders.map((order, index) => {
                                    return order >= currentOrder && index > i ? ++order : order;
                                }))
                            }
                        }
                        for (let i = 0; i < headersCurrentColumnOrders.length; i++) {
                            let header = currentGroupHeaders[i];
                            settings.getColumnSettings(header.dataProperty).order = headersCurrentColumnOrders[i];
                        }
                        settings.persistColumnOrder();
                    }
                    else {
                        for (let i = swaps.length - 1; i >= 0; i--) {
                            swaps[i][0].swapWith(swaps[i][1]);
                        }
                        currentGroupHeaders.forEach((header) => {
                            header.headerBackground.classed(DRAG_TARGET, false);
                            header.columnHeader.style(BACKGROUND, NONE);
                            header.currentHeaderPosition.x = header.startingHeaderPosition.x;
                            header.columnHeader.transition().duration(100).style(LEFT, header.currentHeaderPosition.x + PX);
                            header.setButtonHoverHandlers();
                        });
                        if (this.showColumnSettings) {
                            this.settingsArrow.style(DISPLAY, NONE);
                        }
                        window.setTimeout(() => {
                            this.columnHeader
                                .style(WIDTH, Math.round(Math.max(this.width, this.textWidth)) + PX)
                                .style(BOX_SHADOW, NONE);
                        }, 100);
                        ui.showMessage(Visual.headersDiv, tooltipMessagePosition, "Reordering columns available with Pro license");
                    }
                    settings.persistColumnOrder();
                }
                else {
                    if (!this.settings.proVersionActive()) {
                        ui.showMessage(Visual.headersDiv, tooltipMessagePosition, "Reordering columns available with Pro license");
                    }
                    columnHeader
                        .style(LEFT, startingHeaderPosition.x - (this.headerPeekingLeft ? 6 : 0) + PX)
                        .style(TOP, startingHeaderPosition.y + PX);
                    this.headerBackground.classed(DRAG_TARGET, false);
                    columnHeader.style(BACKGROUND, NONE);
                    this.dragEnd = performance.now();
                    this.settings.dragStarted = false;
                    currentHeader.setButtonHoverHandlers();
                }
            });
        function drg(d) {
            let xPosition = +(<any>d3.event).x + startingHeaderPosition.x - (currentHeader.headerPeekingLeft ? 6 : 0);
            columnHeader
                .style(LEFT, xPosition + PX);
            if (Math.abs(xPosition - currentHeader.previousDragPosition) < 5) {
                return;
            }
            let delta = xPosition - currentHeader.previousDragPosition;
            currentHeader.previousDragPosition = xPosition;
            settings.getHeadersForCurrentGroup(currentHeader).forEach((header: Header) => {
                if (currentHeader.shouldSwapPlaces(header, xPosition, delta)) {
                    header.swapWith(currentHeader);
                    swaps.push([header, currentHeader]);
                }
            });
        }
        this.columnHeader.data([{ x: startingHeaderPosition.x, y: startingHeaderPosition.y }]);
        this.columnHeader.call(draggable);
    }

    private shouldSwapPlaces(header: Header, xPosition: number, delta: number): boolean {
        return (delta < 0 && this.moveToTheLeft(header, xPosition))
            || (delta > 0 && this.moveToTheRight(header, xPosition));
    }

    private moveToTheLeft(header: Header, xPosition: number): boolean {
        return header.currentColumnOrder < this.currentColumnOrder && xPosition < (header.currentHeaderPosition.x + header.width / 2);
    }

    private moveToTheRight(header: Header, xPosition: number): boolean {
        return header.currentColumnOrder > this.currentColumnOrder && (xPosition + this.width) > (header.currentHeaderPosition.x + header.width / 2);
    }

    private swapWith(draggingHeader: Header) {
        let tempPosition = draggingHeader.currentHeaderPosition.x;
        draggingHeader.currentHeaderPosition.x = this.currentHeaderPosition.x;
        this.currentHeaderPosition.x = tempPosition;
        this.columnHeader.transition().duration(100).style(LEFT, tempPosition + PX);

        let tempTextLeft = draggingHeader.headerTextElementLeft;
        draggingHeader.headerTextElementLeft = this.headerTextElementLeft;
        this.headerTextElementLeft = tempTextLeft;
        this.headerTextElement.transition().duration(100).style(PADDING_LEFT, this.headerTextElementLeft + PX);

        let tempColumnOrder = draggingHeader.currentColumnOrder;
        draggingHeader.currentColumnOrder = this.currentColumnOrder;
        this.currentColumnOrder = tempColumnOrder;

        if (this.currentColumnOrder === this.startingColumnOrder) {
            draggingHeader.currentTextAlign = this.currentTextAlign;
            this.currentTextAlign = this.startingTextAlign;
            this.columnHeader.style(TEXT_ANCHOR, this.currentTextAlign);
            if (draggingHeader.currentColumnOrder === draggingHeader.startingColumnOrder) {
                draggingHeader.currentTextAlign = draggingHeader.startingTextAlign;
            }
        }
        else {
            let tempAnchor = this.currentTextAlign;
            this.currentTextAlign = draggingHeader.currentTextAlign;
            this.columnHeader.style(TEXT_ANCHOR, this.currentTextAlign);
            draggingHeader.currentTextAlign = tempAnchor;
        }
        this.settings.headers.filter(h => h.groupIndex === draggingHeader.groupIndex).map(h => {
            if (h.startingColumnOrder === draggingHeader.currentColumnOrder) {
                h.headerBackground.classed(DRAG_TARGET, true);
            }
            else {
                h.headerBackground.classed(DRAG_TARGET, false);
            }
        });
    }

    protected getColumnHeaderText(header: string): string {
        let headerText = header;
        if (this.shouldShorten) {
            headerText = drawing.getTailoredText(header, this.fontSize, this.fontFamily, NORMAL, this.fontStyle, this.width);
        }
        if (this.settings.sortColumnName !== this.sortColumn || this.settings.sortReferenceChart !== this.referenceId) {
            return headerText ? headerText.toString() : headerText;
        }
        if (this.settings.shouldSort() && this.allowSort && this.settings.chartSort === SortDirection.Ascending && this.settings.sortReferenceChart === this.referenceId) {
            if (this.shouldShorten) {
                headerText = drawing.getTailoredText(header, this.fontSize, this.fontFamily, NORMAL, this.fontStyle, this.width - 5);
            }
            headerText += ` ${ARROW_UP_SLIM}`;
        }
        else if (this.settings.shouldSort() && this.allowSort && this.settings.chartSort === SortDirection.Descending && this.settings.sortReferenceChart === this.referenceId) {
            if (this.shouldShorten) {
                headerText = drawing.getTailoredText(header, this.fontSize, this.fontFamily, NORMAL, this.fontStyle, this.width - 5);
            }
            headerText += ` ${ARROW_DOWN_SLIM}`;
        }
        return headerText ? headerText.toString() : headerText;
    }

    private plotScenarioIndicator(scenario: Scenario, dataProperty: DataProperty, scaleGroup: number) {
        let indicator = this.container
            .append(RECT)
            .attr(X, Math.round(this.xPosition))
            .attr(Y, Math.round(this.yPosition + this.expectedHeight + 5))
            .attr(WIDTH, Math.round(this.width))
            .attr(HEIGHT, 3)
            .attr(DATA_PROPERTY, dataProperty)
            .attr(DATA_SCALE_GROUP, scaleGroup)
            .classed(INDICATOR, true);
        styles.applyToElement(indicator, this.settings.colorScheme.neutralColor, scenario, this.settings.chartStyle, this.settings.colorScheme);
    }

    private plotMarkerIndicator(markerStyle: MarkerStyle) {
        let indicator = this.container
            .append(RECT)
            .attr(X, Math.round(this.xPosition))
            .attr(Y, Math.round(this.yPosition + this.expectedHeight + 5))
            .attr(WIDTH, Math.round(this.width))
            .attr(HEIGHT, markerStyle === MarkerStyle.Gridline ? 1 : 3)
            .classed(INDICATOR, true);
        styles.applyMarkerStyleToElement(indicator, markerStyle === MarkerStyle.Gridline ? this.settings.colorScheme.gridlineColor : this.settings.colorScheme.neutralColor, markerStyle, this.settings.chartStyle, this.settings.colorScheme);
    }

    private plotInvert(parent: HTMLDivElement) {
        let invertCheckbox = <HTMLInputElement>document.createElement(INPUT);
        invertCheckbox.type = CHECKBOX;
        invertCheckbox.id = "invert";
        invertCheckbox.checked = this.settings.getColumnInvert(this.dataProperty);
        invertCheckbox.style.height = "13px";
        invertCheckbox.style.width = "13px";
        invertCheckbox.style.margin = "10px 0 0 0";
        invertCheckbox.style.display = "inline";
        invertCheckbox.addEventListener(CLICK, (e) => {
            e.preventDefault();
            this.settings.persistColumnInvert(this.dataProperty);
        });
        parent.appendChild(invertCheckbox);

        let label = document.createElement(LABEL);
        label.textContent = INVERT;
        label.setAttribute("for", "invert");
        label.style.marginLeft = "5px";
        label.style.position = RELATIVE;
        label.style.top = "-2px";
        parent.appendChild(label);
    }

    public setChartChangeIcons(drawIcon: (chartType: ChartType, columnSettingsDiv: HTMLElement, icon: d3.Selection<SVGElement, any, any, any>, viewModel: ViewModel, iconSize: number, dataProperty: DataProperty, plotter: Plotter) => number,
        chartType: ChartType) {
        if (this.viewModel.hasHierarchy) {
            if (helpersLib.isAdditionalMeasure(this.dataProperty)) {
                let columnFormat = this.settings.getColumnFormat(this.dataProperty);
                if (columnFormat === ColumnFormat.Value) {
                    if (this.settingsArrow && (<any>window).switchingDecimals && (<any>window).switchDataProperty === this.dataProperty && (<any>window).switchingDecimalsReferenceId === this.referenceId) {
                        this.settingsArrow.dispatch(CLICK);
                        (<HTMLElement>Visual.columnSettings.node()).scrollTop = (<any>window).columnSettingsScroll;
                        (<any>window).shouldKeepPickers = true;
                    }
                }
            }
        }
        this.drawIcon = drawIcon;
        this.chartType = chartType;
        if (this.settingsArrow && (<any>window).switchingDecimals && (<any>window).switchDataProperty === this.dataProperty && (<any>window).switchingDecimalsReferenceId === this.referenceId) {
            this.settingsArrow.dispatch(CLICK);
            (<HTMLElement>Visual.columnSettings.node()).scrollTop = (<any>window).columnSettingsScroll;
            (<any>window).shouldKeepPickers = true;
        }
    }

    public getBoundingBox() {
        return drawing.getBoundingBoxHtml(this.columnHeaderElement);
    }

    public positionHeaderTextToAxis(groupId: number) {
        try {
            let axis = d3.select(Visual.visualDiv).select(`.chartArea_${groupId}_0_0`).select(".axis");
            if (axis) {
                let left = +axis.attr(X1) - this.xPosition - this.textWidth / 2;
                left = Math.max(0, left);
                left = Math.min(this.width - this.textWidth, left);
                this.headerTextElementLeft = left;
                this.headerTextElement.style(PADDING_LEFT, (+left) + PX);
                this.headerTextElement.style(WIDTH, (this.width - left - 20) + PX);
            }
        } catch (error) {
            // It's hard to check if selection succeeded: .select returns a strange array that hard to
            // check for existing elements. So I wrapped this in try / catch just in case.
        }
    }
}
