import { DataView, DataViewMetadataColumn } from "@zebrabi/matrix-data";
import {
    DataProperty, VALUE_SCENARIO, REFERENCE_SCENARIO, SECOND_REFERENCE_SCENARIO, THIRD_REFERENCE_SCENARIO, EMPTY,
    AC, PY, PL, FC, VALUE_HEADER, ABSOLUTE_DIFFERENCE_HEADER, RELATIVE_DIFFERENCE_HEADER, SECOND_ABSOLUTE_DIFFERENCE_HEADER, SECOND_RELATIVE_DIFFERENCE_HEADER, THIRD_ABSOLUTE_DIFFERENCE_HEADER, THIRD_RELATIVE_DIFFERENCE_HEADER,
    REFERENCE_HEADER, SECOND_REFERENCE_HEADER, THIRD_REFERENCE_HEADER, DELTA_AC, DELTA_PY, DELTA_PL, DELTA_FC, DELTA_AC_PERCENT, DELTA_FC_PERCENT, DELTA_PL_PERCENT, DELTA_PY_PERCENT, FOURTH_REFERENCE_HEADER, FOURTH_REFERENCE_SCENARIO, FOURTH_ABSOLUTE_DIFFERENCE_HEADER, FOURTH_RELATIVE_DIFFERENCE_HEADER, FIFTH_REFERENCE_HEADER, FIFTH_REFERENCE_SCENARIO, FIFTH_ABSOLUTE_DIFFERENCE_HEADER, FIFTH_RELATIVE_DIFFERENCE_HEADER, SIXTH_REFERENCE_HEADER, SIXTH_REFERENCE_SCENARIO, SIXTH_ABSOLUTE_DIFFERENCE_HEADER, SIXTH_RELATIVE_DIFFERENCE_HEADER, SEVENTH_REFERENCE_HEADER, SEVENTH_REFERENCE_SCENARIO, SEVENTH_ABSOLUTE_DIFFERENCE_HEADER, SEVENTH_RELATIVE_DIFFERENCE_HEADER, MarkerStyle, FC2, FC3, PL2, PL3
} from "./../library/constants";
import { VarianceSettings } from "./varianceSettings";
import { ColumnOptions, ScenarioOptions } from "./../definitions";
import { Scenario } from "./../library/constants";
import { ViewModel } from "./viewModel";
import { isForecastScenario, isPlanScenario } from "../helpers";
import { MeasureRoles } from "@zebrabi/data-helpers/fieldAssignment";

export function hasRoleInDataView(dataView: DataView, role: string) {
    if (dataView.matrix.valueSources.some(vs => vs.roles[role])) {
        return true;
    }
    return false;
}

// tslint:disable-next-line: max-func-body-length
export function getScenariosOptionsOffice(dataView: DataView, numberOfValueFields: number, settings: VarianceSettings, numberOfTooltipFields: number, numberOfCommentsFields: number, numberOfPlanFields: number, numberOfForecastFields: number, storedScenarioOptions: ScenarioOptions): ScenarioOptions {
    let hasActual = hasRoleInDataView(dataView, MeasureRoles.Values);
    const hasPreviousYear = hasRoleInDataView(dataView, MeasureRoles.PreviousYear);
    const hasPlan = hasRoleInDataView(dataView, MeasureRoles.Plan);
    const hasForecast = hasRoleInDataView(dataView, MeasureRoles.Forecast);
    const hasComments = hasRoleInDataView(dataView, MeasureRoles.Comments);

    if (storedScenarioOptions) {
        validatePLFCConfiguration(storedScenarioOptions, numberOfPlanFields, numberOfForecastFields);
    }
    // If we only have multiple elements in the value field we just show additional measures.
    // In this case the field that is usually used as actual is just another additional measure.
    if (!hasPreviousYear && !hasPlan && !hasForecast && numberOfValueFields > 1) {
        hasActual = false;
    }
    let scenarioOptions: ScenarioOptions = getEmptyScenarioOptions();

    //
    //  Prepare additional measures
    //
    scenarioOptions.additionalMeasures = [];
    // Clear all current header settings
    for (let i = 1; i <= 20; i++) {
        settings[`additionalMeasure${i}Header`] = null;
    }

    // Add all "Values" roles as additional measures, except the first one
    const valueSources = dataView.matrix.valueSources;
    let skippedFirstValue = !hasActual;
    let additionalMeasureCount = 0;
    for (let i = 0; i < valueSources.length; i++) {
        if (valueSources[i].roles[MeasureRoles.Values]) {
            if (!skippedFirstValue) {
                skippedFirstValue = true;
                continue;
            }
            additionalMeasureCount += 1;
            scenarioOptions.additionalMeasures.push({ scenario: Scenario.Actual, index: i, fieldName: valueSources[i].displayName });
            settings[`additionalMeasure${additionalMeasureCount}Header`] = valueSources[i].displayName;
        }
    }

    if (hasActual) {
        const metadataValueCol = getScenarioMeasureMetadataColumns(valueSources, MeasureRoles.Values);
        setColumnScenarioProperties(scenarioOptions.value, Scenario.Actual, metadataValueCol.index, metadataValueCol.displayName);
        if (hasPlan) {
            if (hasPreviousYear && hasForecast) {
                const metadataReferenceCol = getScenarioMeasureMetadataColumns(valueSources, MeasureRoles.PreviousYear);
                setColumnScenarioProperties(scenarioOptions.reference, Scenario.PreviousYear, metadataReferenceCol.index, metadataReferenceCol.displayName);
                setPLFCScenarioOptions(MeasureRoles.Plan, valueSources, scenarioOptions, storedScenarioOptions);
                setPLFCScenarioOptions(MeasureRoles.Forecast, valueSources, scenarioOptions, storedScenarioOptions);
            }
            else if (hasPreviousYear) {
                const metadataReferenceCol = getScenarioMeasureMetadataColumns(valueSources, MeasureRoles.PreviousYear);
                setColumnScenarioProperties(scenarioOptions.reference, Scenario.PreviousYear, metadataReferenceCol.index, metadataReferenceCol.displayName);
                setPLFCScenarioOptions(MeasureRoles.Plan, valueSources, scenarioOptions, storedScenarioOptions);
            }
            else if (hasForecast) {
                setPLFCScenarioOptions(MeasureRoles.Forecast, valueSources, scenarioOptions, storedScenarioOptions);
                setPLFCScenarioOptions(MeasureRoles.Plan, valueSources, scenarioOptions, storedScenarioOptions);
            }
            else {
                setPLFCScenarioOptions(MeasureRoles.Plan, valueSources, scenarioOptions, storedScenarioOptions);
            }
        }
        else if (hasForecast) {
            if (hasPreviousYear) {
                const metadataReferenceCol = getScenarioMeasureMetadataColumns(valueSources, MeasureRoles.PreviousYear);
                setColumnScenarioProperties(scenarioOptions.reference, Scenario.PreviousYear, metadataReferenceCol.index, metadataReferenceCol.displayName);
            }
            setPLFCScenarioOptions(MeasureRoles.Forecast, valueSources, scenarioOptions, storedScenarioOptions);
        }
        else if (hasPreviousYear) {
            const metadataReferenceCol = getScenarioMeasureMetadataColumns(valueSources, MeasureRoles.PreviousYear);
            setColumnScenarioProperties(scenarioOptions.reference, Scenario.PreviousYear, metadataReferenceCol.index, metadataReferenceCol.displayName);
        }
        else {
            scenarioOptions.reference.scenario = null;
            scenarioOptions.reference.index = null;
        }
    }
    else if (hasPlan) {
        if (hasForecast) {
            if (hasPreviousYear) {
                setPLFCScenarioOptions(MeasureRoles.Forecast, valueSources, scenarioOptions, storedScenarioOptions);
                setNextScenarioProperties(scenarioOptions, Scenario.PreviousYear, getScenarioMeasureIndex(valueSources, MeasureRoles.PreviousYear));
            }
            else {
                setPLFCScenarioOptions(MeasureRoles.Forecast, valueSources, scenarioOptions, storedScenarioOptions);
            }
        }
        else if (hasPreviousYear) {
            const metadataValueCol = getScenarioMeasureMetadataColumns(valueSources, MeasureRoles.PreviousYear);
            setColumnScenarioProperties(scenarioOptions.value, Scenario.PreviousYear, metadataValueCol.index, metadataValueCol.displayName);
        }
        setPLFCScenarioOptions(MeasureRoles.Plan, valueSources, scenarioOptions, storedScenarioOptions);
    }
    else if (hasForecast) {
        setPLFCScenarioOptions(MeasureRoles.Forecast, valueSources, scenarioOptions, storedScenarioOptions);
        if (hasPreviousYear) {
            setNextScenarioProperties(scenarioOptions, Scenario.PreviousYear, getScenarioMeasureIndex(valueSources, MeasureRoles.PreviousYear));
        }
    }
    else if (hasPreviousYear) {
        const metadataValueCol = getScenarioMeasureMetadataColumns(valueSources, MeasureRoles.PreviousYear);
        setColumnScenarioProperties(scenarioOptions.value, Scenario.PreviousYear, metadataValueCol.index, metadataValueCol.displayName);
    }

    if (hasComments) {
        const metadataColumn = getScenarioMeasureMetadataColumns(valueSources, MeasureRoles.Comments);
        scenarioOptions.comments.push({ scenario: null, index: metadataColumn.index, fieldName: metadataColumn?.displayName || "Comments" });
    }

    settings.readCalculationScenarios(scenarioOptions);

    setSettingsScenarioHeaders(scenarioOptions, settings);

    return scenarioOptions;
}

function setSettingsScenarioHeaders(scenarioOptions: ScenarioOptions, settings: VarianceSettings) {
    if (scenarioOptions.value.index !== null) {
        setHeaderValue(settings, scenarioOptions, VALUE_SCENARIO);
    }
    if (scenarioOptions.reference.index !== null) {
        setHeaderValue(settings, scenarioOptions, REFERENCE_SCENARIO);
        setHeaderValueFromScenario(settings, ABSOLUTE_DIFFERENCE_HEADER, settings.valueScenario, settings.referenceScenario, settings.valuePosition, settings.referencePosition, DELTA_AC, DELTA_PL, DELTA_FC, DELTA_PY);
        setHeaderValueFromScenario(settings, RELATIVE_DIFFERENCE_HEADER, settings.valueScenario, settings.referenceScenario, settings.valuePosition, settings.referencePosition, DELTA_AC_PERCENT, DELTA_PL_PERCENT, DELTA_FC_PERCENT, DELTA_PY_PERCENT);
    }
    if (scenarioOptions.secondReference.index !== null) {
        setHeaderValue(settings, scenarioOptions, SECOND_REFERENCE_SCENARIO);
        setHeaderValueFromScenario(settings, SECOND_ABSOLUTE_DIFFERENCE_HEADER, settings.secondValueScenario, settings.secondReferenceScenario, settings.secondValuePosition, settings.secondReferencePosition, DELTA_AC, DELTA_PL, DELTA_FC, DELTA_PY);
        setHeaderValueFromScenario(settings, SECOND_RELATIVE_DIFFERENCE_HEADER, settings.secondValueScenario, settings.secondReferenceScenario, settings.secondValuePosition, settings.secondReferencePosition, DELTA_AC_PERCENT, DELTA_PL_PERCENT, DELTA_FC_PERCENT, DELTA_PY_PERCENT);
    }
    if (scenarioOptions.thirdReference.index !== null) {
        setHeaderValue(settings, scenarioOptions, THIRD_REFERENCE_SCENARIO);
        setHeaderValueFromScenario(settings, THIRD_ABSOLUTE_DIFFERENCE_HEADER, settings.thirdValueScenario, settings.thirdReferenceScenario, settings.thirdValuePosition, settings.thirdReferencePosition, DELTA_AC, DELTA_PL, DELTA_FC, DELTA_PY);
        setHeaderValueFromScenario(settings, THIRD_RELATIVE_DIFFERENCE_HEADER, settings.thirdValueScenario, settings.thirdReferenceScenario, settings.thirdValuePosition, settings.thirdReferencePosition, DELTA_AC_PERCENT, DELTA_PL_PERCENT, DELTA_FC_PERCENT, DELTA_PY_PERCENT);
    }
    if (scenarioOptions.fourthReference.index !== null) {
        setHeaderValue(settings, scenarioOptions, FOURTH_REFERENCE_SCENARIO);
        setHeaderValueFromScenario(settings, FOURTH_ABSOLUTE_DIFFERENCE_HEADER, settings.fourthValueScenario, settings.fourthReferenceScenario, settings.fourthValuePosition, settings.fourthReferencePosition, DELTA_AC, DELTA_PL, DELTA_FC, DELTA_PY);
        setHeaderValueFromScenario(settings, FOURTH_RELATIVE_DIFFERENCE_HEADER, settings.fourthValueScenario, settings.fourthReferenceScenario, settings.fourthValuePosition, settings.fourthReferencePosition, DELTA_AC_PERCENT, DELTA_PL_PERCENT, DELTA_FC_PERCENT, DELTA_PY_PERCENT);
    }
    if (scenarioOptions.fifthReference.index !== null) {
        setHeaderValue(settings, scenarioOptions, FIFTH_REFERENCE_SCENARIO);
        setHeaderValueFromScenario(settings, FIFTH_ABSOLUTE_DIFFERENCE_HEADER, settings.fifthValueScenario, settings.fifthReferenceScenario, settings.fifthValuePosition, settings.fifthReferencePosition, DELTA_AC, DELTA_PL, DELTA_FC, DELTA_PY);
        setHeaderValueFromScenario(settings, FIFTH_RELATIVE_DIFFERENCE_HEADER, settings.fifthValueScenario, settings.fifthReferenceScenario, settings.fifthValuePosition, settings.fifthReferencePosition, DELTA_AC_PERCENT, DELTA_PL_PERCENT, DELTA_FC_PERCENT, DELTA_PY_PERCENT);
    }
    if (scenarioOptions.sixthReference.index !== null) {
        setHeaderValue(settings, scenarioOptions, SIXTH_REFERENCE_SCENARIO);
        setHeaderValueFromScenario(settings, SIXTH_ABSOLUTE_DIFFERENCE_HEADER, settings.sixthValueScenario, settings.sixthReferenceScenario, settings.sixthValuePosition, settings.sixthReferencePosition, DELTA_AC, DELTA_PL, DELTA_FC, DELTA_PY);
        setHeaderValueFromScenario(settings, SIXTH_RELATIVE_DIFFERENCE_HEADER, settings.sixthValueScenario, settings.sixthReferenceScenario, settings.sixthValuePosition, settings.sixthReferencePosition, DELTA_AC_PERCENT, DELTA_PL_PERCENT, DELTA_FC_PERCENT, DELTA_PY_PERCENT);
    }
    if (scenarioOptions.seventhReference.index !== null) {
        setHeaderValue(settings, scenarioOptions, SEVENTH_REFERENCE_SCENARIO);
        setHeaderValueFromScenario(settings, SEVENTH_ABSOLUTE_DIFFERENCE_HEADER, settings.seventhValueScenario, settings.seventhReferenceScenario, settings.seventhValuePosition, settings.seventhReferencePosition, DELTA_AC, DELTA_PL, DELTA_FC, DELTA_PY);
        setHeaderValueFromScenario(settings, SEVENTH_RELATIVE_DIFFERENCE_HEADER, settings.seventhValueScenario, settings.seventhReferenceScenario, settings.seventhValuePosition, settings.seventhReferencePosition, DELTA_AC_PERCENT, DELTA_PL_PERCENT, DELTA_FC_PERCENT, DELTA_PY_PERCENT);
    }
}

function setColumnScenarioProperties(columnOptions: ColumnOptions, scenario: Scenario, index: number, fieldName: string, position: number = null, storedScenarioOptions?: ScenarioOptions) {
    columnOptions.scenario = scenario;
    columnOptions.index = index;
    columnOptions.fieldName = fieldName;
    columnOptions.position = position;
}

function getPLFCScenarioFromPosition(position: number, scenario: Scenario): Scenario {
    if (isForecastScenario(scenario)) {
        if (position === 1) {
            return Scenario.Forecast2;
        }
        else if (position === 2) {
            return Scenario.Forecast3;
        }
        else {
            return Scenario.Forecast;
        }
    }
    else if (isPlanScenario(scenario)) {
        if (position === 1) {
            return Scenario.Plan2;
        }
        else if (position === 2) {
            return Scenario.Plan3;
        }
        else {
            return Scenario.Plan;
        }
    }
    else {
        return scenario;
    }
}

function setPLFCScenarioOptions(measureRole: MeasureRoles.Plan | MeasureRoles.Forecast, valueSources: DataViewMetadataColumn[], scenarioOptions: ScenarioOptions, storedScenarioOptions: ScenarioOptions) {
    const metadataColumns = valueSources.filter(c => c.roles[measureRole] === true);
    const basicScenario = measureRole === MeasureRoles.Plan ? Scenario.Plan : Scenario.Forecast;

    let columnsPositions: { col: DataViewMetadataColumn, position: number }[] = [];
    metadataColumns.forEach((col, i) => {
        const position = storedScenarioOptions ? getStoredColumnsPosition(storedScenarioOptions, col, basicScenario) : i;
        columnsPositions.push({ col: col, position: position });
    });

    columnsPositions.sort((a, b) => a.position - b.position);

    columnsPositions.forEach(cp => {
        setNextScenarioProperties(scenarioOptions, getPLFCScenarioFromPosition(cp.position, basicScenario), cp.col.index, cp.position);
    });
}

function getStoredColumnsPosition(storedScenarioOptions: ScenarioOptions, metadataColumn: DataViewMetadataColumn, scenario: Scenario): number {
    let position = 0;

    const storedColumnOptionKey = Object.keys(storedScenarioOptions).find(key => {
        const column = <ColumnOptions>storedScenarioOptions[key];
        return column.fieldName === metadataColumn.displayName && column.index === metadataColumn.index
            && (isPlanScenario(column.scenario) && isPlanScenario(scenario) || isForecastScenario(column.scenario) && isForecastScenario(scenario));
    });

    if (storedColumnOptionKey) {    // handle reordering within buckets
        const settingscolumnOption = storedScenarioOptions[storedColumnOptionKey];
        position = settingscolumnOption ? settingscolumnOption.position : 0;
    }
    return position;
}

function setNextScenarioProperties(options: ScenarioOptions, scenario: Scenario, index: number, position?: number) {
    const columnOptionToSet = getNextNullScenarioOption(options);    //getNextScenarioColumnOption(options, scenario);
    if (!columnOptionToSet) {
        return;
    }
    columnOptionToSet.index = index;
    columnOptionToSet.scenario = scenario;
    columnOptionToSet.position = position;
}

export function setNextScenarioIndex(options: ScenarioOptions, scenario: Scenario, index?: number, position?: number) {
    if (scenario === Scenario.Forecast) {
        if (position === 1) {
            scenario = Scenario.Forecast2;
        }
        else if (position === 2) {
            scenario = Scenario.Forecast3;
        }
    }
    else if (scenario === Scenario.Plan) {
        if (position === 1) {
            scenario = Scenario.Plan2;
        }
        else if (position === 2) {
            scenario = Scenario.Plan3;
        }
    }

    // assuming value scenario is always set.
    if (options.reference.index === null) {
        options.reference.index = index !== null ? index : options.value.index + 1;
        options.reference.scenario = scenario;
        options.reference.position = position;
    }
    else if (options.secondReference.index === null) {
        options.secondReference.index = index !== null ? index : options.reference.index + 1;
        options.secondReference.scenario = scenario;
        options.secondReference.position = position;
    } else if (options.thirdReference.index === null) {
        options.thirdReference.index = index !== null ? index : options.secondReference.index + 1;
        options.thirdReference.scenario = scenario;
        options.thirdReference.position = position;
    } else if (options.fourthReference.index === null) {
        options.fourthReference.index = index !== null ? index : options.thirdReference.index + 1;
        options.fourthReference.scenario = scenario;
        options.fourthReference.position = position;
    } else if (options.fifthReference.index === null) {
        options.fifthReference.index = index !== null ? index : options.fourthReference.index + 1;
        options.fifthReference.scenario = scenario;
        options.fifthReference.position = position;
    } else if (options.sixthReference.index === null) {
        options.sixthReference.index = index !== null ? index : options.fifthReference.index + 1;
        options.sixthReference.scenario = scenario;
        options.sixthReference.position = position;
    } else if (options.seventhReference.index === null) {
        options.seventhReference.index = index !== null ? index : options.sixthReference.index + 1;
        options.seventhReference.scenario = scenario;
        options.seventhReference.position = position;
    }
}

function getNextNullScenarioOption(options: ScenarioOptions): ColumnOptions {
    if (options.value.index === null) {
        return options.value;
    }
    if (options.reference.index === null) {
        return options.reference;
    }
    if (options.secondReference.index === null) {
        return options.secondReference;
    }
    if (options.thirdReference.index === null) {
        return options.thirdReference;
    }
    if (options.fourthReference.index === null) {
        return options.fourthReference;
    }
    if (options.fifthReference.index === null) {
        return options.fifthReference;
    }
    if (options.sixthReference.index === null) {
        return options.sixthReference;
    }
    if (options.seventhReference.index === null) {
        return options.seventhReference;
    }
    return null;
}

function getScenarioIndex(dataView: DataView, role: string): number {
    return dataView.matrix.valueSources.find(c => c.roles[role] === true)?.index;
}

function getScenarioMeasureIndex(valueSources: DataViewMetadataColumn[], role: MeasureRoles, occurance: number = null): number {
    return occurance === null ?
        valueSources.find(c => c.roles[role] === true)?.index :
        valueSources.filter(c => c.roles[role] === true)[occurance]?.index;
}

function getScenarioMeasureMetadataColumns(valueSources: DataViewMetadataColumn[], role: MeasureRoles, occurance: number = null): DataViewMetadataColumn {
    return occurance === null ?
        valueSources.find(c => c.roles[role] === true) :
        valueSources.filter(c => c.roles[role] === true)[occurance];
}

function getScenarioRoleString(scenario: Scenario): string {
    switch (scenario) {
        case Scenario.Actual: return MeasureRoles.Values;
        case Scenario.PreviousYear: return MeasureRoles.PreviousYear;
        case Scenario.Plan: return MeasureRoles.Plan;
        case Scenario.Forecast: return MeasureRoles.Forecast;
    }
}

export function getEmptyScenarioOptions(): ScenarioOptions {
    return {
        value: { index: null, scenario: null, fieldName: EMPTY, position: null },
        reference: { index: null, scenario: null, fieldName: EMPTY, position: null },
        secondReference: { index: null, scenario: null, fieldName: EMPTY, position: null },
        thirdReference: { index: null, scenario: null, fieldName: EMPTY, position: null },
        fourthReference: { index: null, scenario: null, fieldName: EMPTY, position: null },
        fifthReference: { index: null, scenario: null, fieldName: EMPTY, position: null },
        sixthReference: { index: null, scenario: null, fieldName: EMPTY, position: null },
        seventhReference: { index: null, scenario: null, fieldName: EMPTY, position: null },
        additionalMeasures: [],
        tooltips: [],
        comments: [],
    };
}

export function shouldSetHeader(settings: VarianceSettings, settingName: string): boolean {
    if (settings[settingName] === null) {
        return true;
    }
    return false;
}

export function setHeaderValue(settings: VarianceSettings, options: ScenarioOptions, scenarioProp: string) {
    let scenario = options[scenarioProp].scenario;
    let settingName = settings.getNameFromScenario(scenario);
    if (shouldSetHeader(settings, settingName)) {
        if (scenario === Scenario.Actual) {
            settings.actual = AC;
        }
        else if (scenario === Scenario.Plan) {
            settings.plan = PL;
        }
        else if (scenario === Scenario.Plan2) {
            settings.plan2 = PL2;
        }
        else if (scenario === Scenario.Plan3) {
            settings.plan3 = PL3;
        }
        else if (scenario === Scenario.Forecast) {
            settings.forecast = FC;
        }
        else if (scenario === Scenario.Forecast2) {
            settings.forecast2 = FC2;
        }
        else if (scenario === Scenario.Forecast3) {
            settings.forecast3 = FC3;
        }
        else if (scenario === Scenario.PreviousYear) {
            settings.previousYear = PY;
        }
    }
}

export function setHeaderValueFromScenario(settings: VarianceSettings, headerProp: string, valueScenario: Scenario, referenceScenario: Scenario, valuePosition: number, referencePosition: number, ac: string, pl: string, fc: string, py: string) {
    let isRelative = headerProp.toLowerCase().indexOf("relative") > -1;
    let settingName = getVarianceSettingName(settings, valueScenario, referenceScenario, isRelative);
    if (shouldSetHeader(settings, settingName)) {
        let result = EMPTY;
        if (valueScenario === Scenario.Actual && isPlanScenario(referenceScenario)) {
            result = pl;
        }
        else if (valueScenario === Scenario.Actual && isForecastScenario(referenceScenario)) {
            result = fc;
        }
        else if (valueScenario === Scenario.Actual && referenceScenario === Scenario.PreviousYear) {
            result = py;
        }
        else if (isForecastScenario(valueScenario) && isPlanScenario(referenceScenario)) {
            result = pl;
        }
        else if (isForecastScenario(valueScenario) && referenceScenario === Scenario.PreviousYear) {
            result = py;
        }

        if (referencePosition && result) {
            if (result.endsWith("%")) {
                result = result.replace("%", (referencePosition + 1).toString() + "%");
            } else {
                result = result + (referencePosition + 1).toString();
            }
        }
        if (!result) {
            let valueHeader = getHeader(valueScenario, ac, pl, fc, py, true);
            if (valuePosition) {
                valueHeader += (valuePosition + 1).toString();
            }
            let referenceHeader = getHeader(referenceScenario, ac, pl, fc, py, false);
            if (referencePosition) {
                if (referenceHeader.endsWith("%")) {
                    referenceHeader = referenceHeader.replace("%", (referencePosition + 1).toString() + "%");
                } else {
                    referenceHeader = referenceHeader + (referencePosition + 1).toString();
                }
            }
            result = valueHeader + " - " + referenceHeader;
        }
        settings.setHeader(settingName, result);
    }
}

function getVarianceSettingName(settings: VarianceSettings, value: Scenario, reference: Scenario, isRelative: boolean): string {
    return `${settings.getNameFromScenario(value)}-${settings.getNameFromScenario(reference)}${isRelative ? "-percent" : EMPTY}`;
}

function getHeader(scenario: Scenario, ac: string, pl: string, fc: string, py: string, replacePercent: boolean) {
    let result: string = null;
    switch (scenario) {
        case Scenario.Actual:
            result = ac.replace("Δ", "");
            break;
        case Scenario.Plan:
        case Scenario.Plan2:
        case Scenario.Plan3:
            result = pl.replace("Δ", "");
            break;
        case Scenario.Forecast:
        case Scenario.Forecast2:
        case Scenario.Forecast3:
            result = fc.replace("Δ", "");
            break;
        case Scenario.PreviousYear:
            result = py.replace("Δ", "");
            break;
    }
    return replacePercent ? result.replace("%", "") : result;
}

export function getScenario(dataProperty: DataProperty, viewModel: ViewModel): Scenario {
    switch (dataProperty) {
        case DataProperty.Value:
        case DataProperty.AbsoluteDifference:
        case DataProperty.RelativeDifference:
        case DataProperty.SecondAbsoluteDifference:
        case DataProperty.SecondRelativeDifference:
        case DataProperty.ThirdAbsoluteDifference:
        case DataProperty.ThirdRelativeDifference:
        case DataProperty.FourthAbsoluteDifference:
        case DataProperty.FourthRelativeDifference:
        case DataProperty.FifthAbsoluteDifference:
        case DataProperty.FifthRelativeDifference:
        case DataProperty.SixthAbsoluteDifference:
        case DataProperty.SixthRelativeDifference:
        case DataProperty.SeventhAbsoluteDifference:
        case DataProperty.SeventhRelativeDifference:
            return viewModel.value.scenario;
        case DataProperty.ReferenceValue:
            return viewModel.reference.scenario;
        case DataProperty.SecondReferenceValue:
            return viewModel.secondReference.scenario;
        case DataProperty.ThirdReferenceValue:
            return viewModel.thirdReference.scenario;
        case DataProperty.FourthReferenceValue:
            return viewModel.fourthReference.scenario;
        case DataProperty.FifthReferenceValue:
            return viewModel.fifthReference.scenario;
        case DataProperty.SixthReferenceValue:
            return viewModel.sixthReference.scenario;
        case DataProperty.SeventhReferenceValue:
            return viewModel.seventhReference.scenario;
        case DataProperty.AdditionalMeasure1:
        case DataProperty.AdditionalMeasure2:
        case DataProperty.AdditionalMeasure3:
        case DataProperty.AdditionalMeasure4:
        case DataProperty.AdditionalMeasure5:
        case DataProperty.AdditionalMeasure6:
        case DataProperty.AdditionalMeasure7:
        case DataProperty.AdditionalMeasure8:
        case DataProperty.AdditionalMeasure9:
        case DataProperty.AdditionalMeasure10:
        case DataProperty.AdditionalMeasure11:
        case DataProperty.AdditionalMeasure12:
        case DataProperty.AdditionalMeasure13:
        case DataProperty.AdditionalMeasure14:
        case DataProperty.AdditionalMeasure15:
        case DataProperty.AdditionalMeasure16:
        case DataProperty.AdditionalMeasure17:
        case DataProperty.AdditionalMeasure18:
        case DataProperty.AdditionalMeasure19:
        case DataProperty.AdditionalMeasure20:
            return Scenario.Actual;
    }
}

export function getVarianceScenario(dataProperty: DataProperty, viewModel: ViewModel): Scenario {
    switch (dataProperty) {
        case DataProperty.Value:
            return viewModel.value.scenario;
        case DataProperty.AbsoluteDifference:
        case DataProperty.RelativeDifference:
            return viewModel.settings.valueScenario;
        case DataProperty.SecondAbsoluteDifference:
        case DataProperty.SecondRelativeDifference:
            return viewModel.settings.secondValueScenario;
        case DataProperty.ThirdAbsoluteDifference:
        case DataProperty.ThirdRelativeDifference:
            return viewModel.settings.thirdValueScenario;
        case DataProperty.FourthAbsoluteDifference:
        case DataProperty.FourthRelativeDifference:
            return viewModel.settings.fourthValueScenario;
        case DataProperty.FifthAbsoluteDifference:
        case DataProperty.FifthRelativeDifference:
            return viewModel.settings.fifthValueScenario;
        case DataProperty.SixthAbsoluteDifference:
        case DataProperty.SixthRelativeDifference:
            return viewModel.settings.sixthValueScenario;
        case DataProperty.SeventhAbsoluteDifference:
        case DataProperty.SeventhRelativeDifference:
            return viewModel.settings.seventhValueScenario;
        case DataProperty.ReferenceValue:
            return viewModel.reference.scenario;
        case DataProperty.SecondReferenceValue:
            return viewModel.secondReference.scenario;
        case DataProperty.ThirdReferenceValue:
            return viewModel.thirdReference.scenario;
        case DataProperty.FourthReferenceValue:
            return viewModel.fourthReference.scenario;
        case DataProperty.FifthReferenceValue:
            return viewModel.fifthReference.scenario;
        case DataProperty.SixthReferenceValue:
            return viewModel.sixthReference.scenario;
        case DataProperty.SeventhReferenceValue:
            return viewModel.seventhReference.scenario;
    }
}
function validatePLFCConfiguration(scenarioOptions: ScenarioOptions, numberOfPlanFields: number, numberOfForecastFields: number) {
    // handle cases of scenarios and positions for multi PL/FC when the reordered measure is moved out of the PL or FC
    if (numberOfPlanFields > 0) {
        const plansKeys = Object.keys(scenarioOptions).filter(key => isPlanScenario((<ColumnOptions>scenarioOptions[key]).scenario));
        const plansOptions: ColumnOptions[] = plansKeys.map(key => <ColumnOptions>scenarioOptions[key]);
        if (plansOptions.length === 1) {
            plansOptions[0].scenario = Scenario.Plan
            plansOptions[0].position = 0;
        }
        else if (plansOptions.length === 2) {
            const isValidConfiguration = plansOptions.filter(option => option.scenario === Scenario.Plan)?.length === 1
                && plansOptions.filter(option => option.scenario === Scenario.Plan2)?.length === 1;

            if (!isValidConfiguration) {
                plansOptions[0].scenario = Scenario.Plan;
                plansOptions[0].position = 0;
                plansOptions[1].scenario = Scenario.Plan2;
                plansOptions[1].position = 1;
            }
        }
        else if (plansOptions.length === 3) {
            const isValidConfiguration = plansOptions.filter(option => option.scenario === Scenario.Plan)?.length === 1
                && plansOptions.filter(option => option.scenario === Scenario.Plan2)?.length === 1
                && plansOptions.filter(option => option.scenario === Scenario.Plan3)?.length === 1;

            if (!isValidConfiguration) {
                plansOptions[0].scenario = Scenario.Plan;
                plansOptions[0].position = 0;
                plansOptions[1].scenario = Scenario.Plan2;
                plansOptions[1].position = 1;
                plansOptions[2].scenario = Scenario.Plan3;
                plansOptions[2].position = 2;
            }
        }
    }

    if (numberOfForecastFields > 0) {
        const fcKeys = Object.keys(scenarioOptions).filter(key => isForecastScenario((<ColumnOptions>scenarioOptions[key]).scenario));
        const fcOptions: ColumnOptions[] = fcKeys.map(key => <ColumnOptions>scenarioOptions[key]);
        if (fcOptions.length === 1) {
            fcOptions[0].scenario = Scenario.Forecast
            fcOptions[0].position = 0;
        }
        else if (fcOptions.length === 2) {
            const isValidConfiguration = fcOptions.filter(option => option.scenario === Scenario.Forecast)?.length === 1
                && fcOptions.filter(option => option.scenario === Scenario.Forecast2)?.length === 1;
            if (!isValidConfiguration) {
                fcOptions[0].scenario = Scenario.Forecast;
                fcOptions[0].position = 0;
                fcOptions[1].scenario = Scenario.Forecast2;
                fcOptions[1].position = 1;
            }
        }
        else if (fcOptions.length === 3) {
            const isValidConfiguration = fcOptions.filter(option => option.scenario === Scenario.Forecast)?.length === 1
                && fcOptions.filter(option => option.scenario === Scenario.Forecast2)?.length === 1
                && fcOptions.filter(option => option.scenario === Scenario.Forecast3)?.length === 1;

            if (!isValidConfiguration) {
                fcOptions[0].scenario = Scenario.Forecast;
                fcOptions[0].position = 0;
                fcOptions[1].scenario = Scenario.Forecast2;
                fcOptions[1].position = 1;
                fcOptions[2].scenario = Scenario.Forecast3;
                fcOptions[2].position = 2;
            }
        }
    }
}

