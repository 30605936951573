
import { VarianceSettings } from "./varianceSettings";
import { DataPoint } from "./../charting/dataPoint";
import { ChartHierarchy } from "./../charting/chartHierarchy";
import {
    Scenario, DataProperty, BOLD, EMPTY, NORMAL, ITALIC, NumberOfDataProperties, ChartStyle
} from "./../library/constants";
import { ChartGroup } from "./../charting/chartGroup";
import { ChartGroups } from "./../charting/chartGroups";
import { Extremes } from "./../charting/extremes";
import { CategoryFormatSettings, ColumnOptions, ColumnSettings, ColumnTotalEmphasize, DataPointType, ScenarioOptions, SortDirection } from "./../definitions";
import * as helpers from "./../helpers";
import * as drawing from "./../library/drawing";
import * as d3 from "d3";
import { parseFormulas } from "./formulaHelpers";
import * as styles from "./../library/styles";

import { DataView, DataViewMetadataColumn } from "@zebrabi/matrix-data";
import { OrganizationStyleSettings } from "@zebrabi/data-helpers/organizationStyles";
import { Visual } from "../visual";

export class ViewModel {
    // public host: IVisualHost;
    public chartGroups: ChartGroups;
    public settings: VarianceSettings;
    public organizationStyleSettings: OrganizationStyleSettings;
    public extremes: Extremes;
    public maxCategoryWidth: number;
    public maxOutlierValue: number;
    public minOutlierValue: number;
    public title: string;
    public reference: ColumnOptions;
    public secondReference: ColumnOptions;
    public thirdReference: ColumnOptions;
    public fourthReference: ColumnOptions;
    public fifthReference: ColumnOptions;
    public sixthReference: ColumnOptions;
    public seventhReference: ColumnOptions;
    public value: ColumnOptions;
    public tooltips: ColumnOptions[];
    public comments: ColumnOptions[];
    public additionalMeasures: ColumnOptions[];
    public plotOnlyCategories: boolean;
    public hasHierarchy: boolean;
    public numberOfLevels: number;
    public numberOfAddedFields: number;
    public numberOfValueFields: number;
    public numberOfTooltipFields: number;
    public numberOfCommentFields: number;
    public maximumExpandedHierarchyLevel: number;
    public maximumExpandedGroupLevel: number;
    public numberOfGroupLevels: number;
    public locale: string;
    public percentageFormat: string;
    public lastLevelChartGroups: ChartGroup[];
    public valueSources?: DataViewMetadataColumn[];
    public scenarioOptions: ScenarioOptions;
    public emptyColumn: boolean[];
    public numberOfForecastFields: number;
    public numberOfPlanFields: number;

    get HasValueColumn(): boolean {
        return this.value.index !== null;
    }

    get HasReferenceColumn(): boolean {
        return this.reference.index !== null;
    }

    get HasSecondReferenceColumn(): boolean {
        return this.secondReference.index !== null;
    }

    get HasThirdReferenceColumn(): boolean {
        return this.thirdReference.index !== null;
    }

    get HasFourthReferenceColumn(): boolean {
        return this.fourthReference.index !== null;
    }

    get HasFifthReferenceColumn(): boolean {
        return this.fifthReference.index !== null;
    }

    get HasSixthReferenceColumn(): boolean {
        return this.sixthReference.index !== null;
    }

    get HasSeventhReferenceColumn(): boolean {
        return this.seventhReference.index !== null;
    }

    get HasTooltipsColumn(): boolean {
        return this.tooltips.length > 0;
    }

    get HasCommentsColumn(): boolean {
        return this.comments.length > 0;
    }

    get HasAdditionalMeasures(): boolean {
        return this.additionalMeasures.length > 0;
    }

    get numberOfLastLevelChartGroups(): number {
        return this.lastLevelChartGroups.length;
    }

    public calculateExtremes() {
        return;
    }

    public calculateCumulativeExtremes() {
        return;
    }

    constructor(showCompanyStyle: boolean, updateSettings?: VarianceSettings, dataView?: DataView, locale?: string) {
        this.maxCategoryWidth = 0;

        this.settings = this.getSettings(true);
        if (updateSettings) {
            Object.keys(updateSettings).forEach(key => {
                if (this.shouldOverwriteDefaultSettingWithStoredSettingValue(key)) {
                    // deserialization of Map<> types settings needs to be handled in a special way
                    if (key == "formulaCalculation") {
                        this.settings.formulaCalculation.formulas = updateSettings.formulaCalculation.formulas;
                    }
                    else if (key === "commentMarkersDataProperties") {
                        if (updateSettings.commentMarkersDataPropertiesString) {
                            this.settings.commentMarkersDataProperties = helpers.parseMapFromString(updateSettings.commentMarkersDataPropertiesString);
                        }
                    }
                    else if (key === "groupNames") {
                        if (updateSettings.groupNamesString) {
                            this.settings.groupNames = helpers.parseMapFromString(updateSettings.groupNamesString);
                        }
                    }
                    else if (key === "columnTotalEmphasizeTable") {
                        if (updateSettings.columnTotalEmphasizeTableString) {
                            this.settings.columnTotalEmphasizeTable = helpers.parseMapFromString(updateSettings.columnTotalEmphasizeTableString);
                        }
                    }
                    else if (key === "columnTotalEmphasize") {
                        if (updateSettings.columnTotalEmphasizeString) {
                            this.settings.columnTotalEmphasize = helpers.parseMapFromString(updateSettings.columnTotalEmphasizeString);
                        }
                    }
                    else {
                        this.settings[key] = updateSettings[key];
                    }
                }
            })
        }

        //this.settings.showRowGrandTotal = dataView?.rowGrandTotal;
        this.chartGroups = new ChartGroups(this);
        this.extremes = null;
        this.minOutlierValue = 0;
        this.maxOutlierValue = 0;
        this.title = EMPTY;
        this.reference = { index: null, scenario: null, fieldName: EMPTY };
        this.value = { index: null, scenario: null, fieldName: EMPTY };
        this.plotOnlyCategories = false;
        this.hasHierarchy = false;
        this.locale = locale || "en-US";
        this.numberOfGroupLevels = 0;
        this.lastLevelChartGroups = [];
        this.maximumExpandedGroupLevel = 0;
        this.emptyColumn = [];
        for (let index = 0; index < NumberOfDataProperties; index++) {
            this.emptyColumn.push(true);
        }
        parseFormulas(this.settings.formulaCalculation);

        let isOrganizationStyle = this.settings.chartStyle === ChartStyle.Company
        if (isOrganizationStyle) {
            // Check if the selected and loaded organization styles are the same 
            if (this.settings.selectedOrganizationStyleId != Visual.getInstance().getOrganizationStyleSettings()?.id) {
                Visual.getInstance().applyOrganizationStyleById(this.settings.selectedOrganizationStyleId);
            }
        } else if (this.settings.chartStyle !== ChartStyle.Custom) {
            this.settings.colorScheme = styles.getColorSchemeFromStyle(this.settings.chartStyle)
        }

        if (!isOrganizationStyle) {
            Visual.getInstance().removeOrganizationStyle();
        }
    }

    // public getChildernSelectionIds(category: string): ISelectionId[] {
    //     return [];
    // }

    private shouldOverwriteDefaultSettingWithStoredSettingValue(key: string): boolean {
        return key !== "headers" && key !== "showTotals" && key !== "hasNoResults" && key !== "hasNoFlatResults"
            && key !== "hasFlatResults" && key !== "hasNoInverts" && key !== "shouldSort" && key !== "hasAutoResults"
            && key !== "hasAutoInverts" && key !== "viewModel" && key !== "columnSettings" && key !== "categoryFormatSettings";
    }

    public isMultiples(): boolean {
        return false;
    }

    protected createDataPoint(category: string, chartHierarchy: ChartHierarchy): DataPoint {
        return new DataPoint(0, 0, 0, 0, 0, 0, 0, 0, [], [], [], category, EMPTY, null, null, DataPointType.Normal, this, 0, chartHierarchy, null);
    }

    private getSettings(showCompanyStyle: boolean): VarianceSettings {
        let settings = new VarianceSettings(this);
        // TODO: new read settings...
        // settings.readSettings(showCompanyStyle);
        return settings;
    }

    public allowFlatResults(): boolean {
        return !this.hasHierarchy;
    }

    public getChartHeights(categoryHeight: number): number[] {
        return this.chartGroups.getChartHeights(categoryHeight);
    }

    public getTableWidth(dataProperty: DataProperty, groupIndex: number): number {
        let extreme = this.extremes.getTableExtreme(dataProperty);
        let header = this.settings.getHeader(dataProperty);
        let headerWidth = drawing.measureTextWidth(header, this.settings.labelFontSize, this.settings.labelFontFamily, this.settings.isHeaderBold(dataProperty) ? BOLD : NORMAL, this.settings.shouldUseItalicText(dataProperty) ? ITALIC : NORMAL) + 5;
        let settings = this.settings.columnSettings.get(this.settings.sortColumnName);
        if (settings) {
            if (this.settings.getDataPropertyFromColumnSettings(settings) === dataProperty && this.settings.chartSort !== SortDirection.None) {
                if (this.settings.plottingHorizontally()) {
                    if (this.settings.sortReferenceChart === null || this.settings.sortReferenceChart === groupIndex) {
                        headerWidth += 10;
                    }
                }
                else {
                    headerWidth += 10;
                }
            }
        }
        return extreme.getTableWidth(headerWidth);
    }

    public isLastHierarchy(groupIndex: number, hierarchyIndex: number): boolean {
        let group = this.lastLevelChartGroups[groupIndex];
        let allHierarchies = group.getAllChartHierarchies();
        return allHierarchies.length - 1 === hierarchyIndex;
    }

    public hasMultipleGroups(): boolean {
        return this.numberOfGroupLevels > 1;
    }

    public skipLastPointGridline(groupIndex: number, chartHierarchyIndex: number): boolean {
        let group = this.lastLevelChartGroups[groupIndex];
        let hierachy = this.getChartHierarchy(groupIndex, chartHierarchyIndex);
        let groupHierarchies = group.getAllChartHierarchies();
        let isLastHierarchyBeforeGrandTotalCollapsed = groupHierarchies.length > 1 && chartHierarchyIndex === groupHierarchies.length - 2 && groupHierarchies[groupHierarchies.length - 1].isGrandTotal && groupHierarchies[groupHierarchies.length - 2].isCollapsed();
        return !group.isRowGrandTotal && !hierachy.isGrandTotal && (this.isLastHierarchy(groupIndex, chartHierarchyIndex) || isLastHierarchyBeforeGrandTotalCollapsed) && this.settings.plottingHorizontally() && this.settings.showRowGrandTotal;
    }

    public showingGroupHeaders(): boolean {
        return this.settings.plottingHorizontally() && this.numberOfGroupLevels > 0;
    }

    public getNumberOfCharts(): number {
        return d3.sum(this.lastLevelChartGroups.map(g => g.getAllChartHierarchies().length));
    }

    public getNumberOfCategories(skipSubtotals: boolean): number[] {
        let charts: ChartHierarchy[] = [];
        this.lastLevelChartGroups.forEach(chartGroup => {
            if (chartGroup.isSubtotal && skipSubtotals) {
                return;
            }
            charts = charts.concat(chartGroup.getAllChartHierarchies());
        });
        return charts.map(d => d.dataPoints().length);
    }

    public getChartHierarchy(chartGroupIndex: number, chartHierarchyIndex: number): ChartHierarchy {
        if (chartGroupIndex >= 0 && chartGroupIndex < this.lastLevelChartGroups.length) {
            let chartGroup = this.lastLevelChartGroups[chartGroupIndex];
            return chartGroup.getChartHierarchy(chartHierarchyIndex);
        }
        return null;
    }

    public getValueFromComparison(scenario: Scenario, value: number, referenceValue: number, secondReferenceValue: number, thirdReferenceValue: number, fourthReferenceValue: number, fifthReferenceValue: number, sixthReferenceValue: number, seventhReferenceValue: number, defaultValue: number): number {
        if (this.value.scenario === scenario) {
            return value;
        }
        else if (this.reference.scenario === scenario) {
            return referenceValue;
        }
        else if (this.secondReference.scenario === scenario) {
            return secondReferenceValue;
        }
        else if (this.thirdReference.scenario === scenario) {
            return thirdReferenceValue;
        }
        else if (this.fourthReference.scenario === scenario) {
            return fourthReferenceValue;
        }
        else if (this.fifthReference.scenario === scenario) {
            return fifthReferenceValue;
        }
        else if (this.sixthReference.scenario === scenario) {
            return sixthReferenceValue;
        }
        else if (this.seventhReference.scenario === scenario) {
            return seventhReferenceValue;
        }

        return defaultValue;
    }

    public getDataPropertyFromComparison(scenario: Scenario, defaultDP: DataProperty): DataProperty {
        if (this.value.scenario === scenario) {
            return DataProperty.Value;
        }
        else if (this.reference.scenario === scenario) {
            return DataProperty.ReferenceValue;
        }
        else if (this.secondReference.scenario === scenario) {
            return DataProperty.SecondReferenceValue;
        }
        else if (this.thirdReference.scenario === scenario) {
            return DataProperty.ThirdReferenceValue;
        }
        else if (this.fourthReference.scenario === scenario) {
            return DataProperty.FourthReferenceValue;
        }
        else if (this.fifthReference.scenario === scenario) {
            return DataProperty.FifthReferenceValue;
        }
        else if (this.sixthReference.scenario === scenario) {
            return DataProperty.SixthReferenceValue;
        }
        else if (this.seventhReference.scenario === scenario) {
            return DataProperty.SeventhReferenceValue;
        }
        return defaultDP;
    }

    public getCommentsMap(): DataPoint[] {
        const commentDataPoints: DataPoint[] = [];
        this.lastLevelChartGroups.forEach(cg => {
            cg.getAllDataPoints().forEach(dp => {
                if (dp.hasComment() && !commentDataPoints.includes(dp)) {
                    commentDataPoints.push(dp);
                }
            });
        });

        // Remove data points with empty strings/comments
        return commentDataPoints.filter(dp => dp.commentsValues.some(cv => cv));
    }
}
