import { ABSOLUTE } from "../definitions";
import { VarianceSettings } from "../settings/varianceSettings";
import { ViewModel } from "../settings/viewModel";
import { DataProperty, NORMAL, DIV, PX, NONE, MOUSEENTER, BLOCK, MOUSELEAVE, DISPLAY, CLICK, CENTER, LABEL, HEADER_TOOLTIP, TOP, FONT_FAMILY, BORDER_BOTTOM, LEFT, POSITION, HEIGHT, WIDTH, FONT_SIZE, LINE_HEIGHT, OPACITY, ALIGN_ITEMS, EMPTY, OVERFLOW_Y, AUTO, OVERFLOW_X, HIDDEN, FONT_SIZE_UNIT } from "../library/constants";
import { Visual } from "../visual";
import * as d3 from "d3";
import * as drawing from "./../library/drawing";
import * as ui from "./../library/ui/showTooltip";
import { Plotter } from "./plotters/plotter";

export class ColumnAdder {

    private columnAdderSelector: d3.Selection<HTMLElement, any, any, any>;
    private columnAdderContainer: d3.Selection<HTMLElement, any, any, any>;
    private settings: VarianceSettings;
    private frozen: boolean

    constructor(private viewModel: ViewModel, private fullDataProperties: DataProperty[], private categoriesWidth: number, private plotter: Plotter) {
        this.settings = viewModel.settings;
        this.columnAdderContainer = d3.select(Visual.headersDiv);
        if (this.settings.showFrozenCategories()) {
            this.columnAdderContainer = d3.select(Visual.categoriesHeaderDiv);
            this.frozen = true;
        } else {
            this.frozen = false;
        }
    }

    public plotColumnAdder(position: number) {
        let container = this.columnAdderContainer;
        d3.selectAll(".column-adder").remove();
        this.columnAdderSelector = container.append(DIV).classed("column-adder", true);
        let height = drawing.measureTextHeight("+", this.settings.labelFontSize, this.settings.labelFontFamily, NORMAL, NORMAL);
        let left = Math.max(this.categoriesWidth - height, 0);
        if (this.viewModel.numberOfGroupLevels > 1 && (this.viewModel.numberOfGroupLevels - 1) > this.viewModel.maximumExpandedGroupLevel) {
            left = Math.max(0, left - 13);
        }
        this.columnAdderSelector
            .style(LEFT, this.frozen ? this.categoriesWidth - 18 + PX : left + PX)
            .style(DISPLAY, NONE)
            .style(POSITION, ABSOLUTE)
            .style(TOP, "0px")
            .append(DIV).classed("column-adder-btn", true).text("+")
            .style(TOP, position + 6 + PX)
            .style(HEIGHT, height + PX)
            .style(WIDTH, height + PX)
            .style(FONT_SIZE, this.settings.labelFontSize + FONT_SIZE_UNIT)
            .style(FONT_FAMILY, this.settings.labelFontFamily + PX)
            .style(LINE_HEIGHT, this.settings.labelFontSize + 5 + PX);
        this.setMouseEventHandlers();
        if (this.settings.firstTimeShowingColumnAdder) {
            this.animateColumnAdder();
        }
        window.setTimeout(() => {
            d3.select(Visual.headersDiv).on(MOUSEENTER, () => {
                this.columnAdderSelector
                    .style(DISPLAY, BLOCK);
            }).on(MOUSELEAVE, () => {
                this.columnAdderSelector
                    .style(DISPLAY, NONE);
            })
            if (this.frozen) {
                this.columnAdderContainer.on(MOUSEENTER, () => {
                    this.columnAdderSelector
                        .style(DISPLAY, BLOCK);
                }).on(MOUSELEAVE, () => {
                    this.columnAdderSelector
                        .style(DISPLAY, NONE);
                });
            }
        }, this.settings.firstTimeShowingColumnAdder ? 1000 : 0);
    }

    public animateColumnAdder() {
        this.columnAdderSelector
            .style(DISPLAY, BLOCK)
            .style(OPACITY, 1);
        let buttn = d3.select(".column-adder-btn").classed("shake-animation", true);

        window.setTimeout(() => {
            buttn.style(OPACITY, "0");
        }, 800);
        window.setTimeout(() => {
            buttn.classed("shake-animation", false);
            this.columnAdderSelector.style(DISPLAY, NONE);
            buttn.style(OPACITY, "1");
            this.settings.persistColumnAdderFirstTime();
        }, 1000);
    }

    // tslint:disable-next-line: max-func-body-length
    public setMouseEventHandlers() {
        this.columnAdderSelector.on(CLICK, () => {
            (<any>d3.event).stopPropagation();
            Visual.columnAdderList.style(HEIGHT, null);
            Visual.columnAdderList.selectAll("*").remove();

            let isShown = Visual.columnAdderList.style(DISPLAY);
            if (isShown === BLOCK) {
                Visual.columnAdderList.style(DISPLAY, NONE);
                return;
            }
            Visual.columnAdderList.style(DISPLAY, BLOCK);
            let bb = (<HTMLElement>d3.select(".column-adder-btn").node()).getBoundingClientRect();
            Visual.columnAdderList
                .style(LEFT, bb.left + PX)
                .style(TOP, bb.top + bb.height + PX)
                .style(OVERFLOW_Y, "overlay")
                .style(OVERFLOW_X, HIDDEN);
            Visual.columnAdderList.append(DIV).classed("column-adder-label", true).text("Add column");
            let optionDivs: d3.Selection<HTMLElement, any, any, any>[] = [];
            this.settings.getHiddenColumns().forEach(dp => {
                if (this.fullDataProperties.indexOf(dp) > -1) {
                    let header = this.settings.getHeader(dp);
                    if (header) {
                        let el = Visual.columnAdderList.append(DIV).classed("column-adder-option", true).text(header);
                        optionDivs.push(el);
                        el.on(CLICK, () => {
                            this.settings.hiddenColumnsChange(dp);
                        });
                    }
                }
            });
            if (this.settings.plottingHorizontally()) {
                this.settings.columnSettings.forEach((setting, name) => {
                    let removedFromGroups = this.settings.getColumnViewSettingsByColumnName(name, "hiddenFromGroups");
                    let dp = this.settings.getDataPropertyFromColumnSettings(setting);
                    let header = this.settings.getHeader(dp)
                    if (header) {
                        removedFromGroups.forEach(group => {
                            let el = Visual.columnAdderList.append(DIV).classed("column-adder-option", true).text(group + "/" + header);
                            optionDivs.push(el);
                            el.on(CLICK, () => {
                                this.settings.removedColumnsChangeGroup(group, dp);
                                this.settings.persistColumnsHiddenFromGroups();
                            });
                        });
                    }
                });
            }
            if (this.viewModel.numberOfGroupLevels >= 1) {
                let el: d3.Selection<HTMLElement, any, any, any>;
                if (this.viewModel.numberOfGroupLevels > 1 && !this.settings.showColumnTotals) {
                    el = Visual.columnAdderList
                        .append(DIV)
                        .classed("column-adder-option", true)
                        .style(DISPLAY, "flex")
                        .style(ALIGN_ITEMS, CENTER);
                    el.append(LABEL).text("Show subtotals");
                    optionDivs.push(el);
                    el.on(CLICK, () => {
                        this.settings.showColumnTotals = !this.settings.showColumnTotals;
                        this.settings.persistShowColumnTotals();
                    });
                }
                if (!this.settings.showColumnGrandTotal) {
                    el = Visual.columnAdderList
                        .append(DIV)
                        .classed("column-adder-option", true)
                        .style(DISPLAY, "flex")
                        .style(ALIGN_ITEMS, CENTER);
                    el.append(LABEL).text("Show grand total");
                    optionDivs.push(el);
                    el.on(CLICK, () => {
                        this.settings.showColumnGrandTotal = true;
                        this.settings.persistShowColumnGrandTotal();
                    });
                }
            }
            optionDivs.forEach((el, i) => {
                if (i !== optionDivs.length - 1) {
                    el
                        .style(BORDER_BOTTOM, "1px solid grey");
                }
            });
            let adderList = (<HTMLDivElement>Visual.columnAdderList.node()).getBoundingClientRect();
            if (adderList.height > this.plotter.height) {
                Visual.columnAdderList.style(HEIGHT, (Math.max(this.plotter.height - adderList.top, 20)) + PX);
            }
            else {
                Visual.columnAdderList.style(HEIGHT, null);
            }
        })
            .on(MOUSEENTER, () => {
                let bb = (<HTMLElement>d3.select(".column-adder-btn").node()).getBoundingClientRect();
                ui.showTooltip(Visual.visualDiv, {
                    x: Math.max(bb.left - 10, 0),
                    y: bb.top + bb.height + 10,
                }, "Add columns", 250, 1000);
                let isShown = Visual.columnAdderList.style(DISPLAY);
                if (isShown === BLOCK) {
                    Visual.columnAdderList.style(DISPLAY, NONE);
                }
            })
            .on(MOUSELEAVE, () => {
                d3.selectAll("." + HEADER_TOOLTIP).remove();
            });
    }
}