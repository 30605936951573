import React from "react";
import ReactDOM from "react-dom/client";
import { TablesChooserScreen } from "./screen";
import "../tailwind.css";

const ROOT_ELEMENT_ID = "tables-chooser-react";

function addRootDOMElement() {
  const element = document.createElement("div");
  element.setAttribute("id", ROOT_ELEMENT_ID);
  document.body.prepend(element);
}

export function mountReactTablesChooser() {
  addRootDOMElement();
  const reactRoot = ReactDOM.createRoot(document.getElementById(ROOT_ELEMENT_ID) as HTMLElement);
  reactRoot.render(
    <React.StrictMode>
      <TablesChooserScreen />
    </React.StrictMode>
  );
}
