import { Categories } from "../category.type";

import ComparisonTableOneImage from "./images/comparison-table-one.svg";
import ComparisonTableTwoImage from "./images/comparison-table-two.svg";
import ComparisonTableThreeImage from "./images/comparison-table-three.svg";
import {
  ComparisonTableOneCommentData,
  ComparisonTableOneSampleData,
  ComparisonTableThreeCommentData,
  ComparisonTableThreeSampleData,
  ComparisonTableTwoCommentData,
  ComparisonTableTwoSampleData,
} from "./comparisonCategories.mock";
import { ACT_ABS_REL } from "../../../../../../visuals/tables/definitions";

export const comparisonCategories: Categories = [
  {
    displayName: "Multiple series",
    tables: [
      {
        id: "comparison-table-one",
        title: "Table with one comparison",
        image: ComparisonTableOneImage,
        sampleData: ComparisonTableOneSampleData,
        commentData: ComparisonTableOneCommentData,
        tooltip:
          "Compare two values using automatically generated variance, rank data categories, look up specific values, and recognize patterns in data. Use when you have discrete items like products or customers.",
        settings: {
          showAsTable: false,
          chartType: ACT_ABS_REL,
          valueChart: 0,
        },
      },
      {
        id: "comparison-table-two",
        title: "Table with two comparisons",
        image: ComparisonTableTwoImage,
        sampleData: ComparisonTableTwoSampleData,
        commentData: ComparisonTableTwoCommentData,
        tooltip:
          "Compare three values using automatically generated variance, rank data categories, look up specific values, and recognize patterns in data. Use when you have discrete items like products or customers.",
        settings: {
          showAsTable: false,
          chartType: ACT_ABS_REL,
        },
      },
      {
        id: "comparison-table-three",
        title: "Table with three comparisons",
        image: ComparisonTableThreeImage,
        sampleData: ComparisonTableThreeSampleData,
        commentData: ComparisonTableThreeCommentData,
        tooltip:
          "Compare four values using automatically generated variance, rank data categories, look up specific values, and recognize patterns in data. Use when you have discrete items like products or customers.",
        settings: {
          showAsTable: false,
          chartType: ACT_ABS_REL,
        },
      },
    ],
  },
];
