import * as d3 from "d3";
import { ZOOM_VALUE_POPUP, DIV, PX, NONE, BLOCK } from "../library/constants";
import { Position } from "@zebrabi/legacy-library-common/interfaces";

export function showZoomValuePopup(element: HTMLElement, zoom: number) {
    d3.selectAll("." + ZOOM_VALUE_POPUP).remove();
    let zoomDiv = document.createElement(DIV);
    zoomDiv.className = ZOOM_VALUE_POPUP;
    let popupContent = "Zoom: " + zoom + "%";
    zoomDiv.textContent = popupContent;
    element.appendChild(zoomDiv);
    window.setTimeout(() => {
        zoomDiv.classList.add("is-visible");
    }, 50);
    window.setTimeout(() => {
        zoomDiv.classList.remove("is-visible");
    }, 1500);
}

export function showMessage(element: HTMLElement, position: Position, text: string) {
    d3.selectAll(".message-tooltip").remove();
    let sortDiv = document.createElement(DIV);
    sortDiv.className = "message-tooltip";
    sortDiv.style.left = Math.max(0, position.x) + PX;
    sortDiv.style.top = position.y + PX;
    let popupContent = text;
    sortDiv.textContent = popupContent;
    element.appendChild(sortDiv);
    sortDiv.style.display = NONE;
    window.setTimeout(() => {
        sortDiv.style.display = BLOCK;
    }, 100);

    window.setTimeout(() => {
        sortDiv.style.display = NONE;
        if (element.contains(sortDiv)) {
            element.removeChild(sortDiv);
        }
    }, 1600);
}