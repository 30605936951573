import React from "react";
import "./index.css";
import useStore from "@zebrabi/tables-chooser/useStore";
import TablesChooserAside from "../TablesChooserAside/TablesChooserAside";
import { TablesDimensionList } from "../../tables/dimensions/components/TablesDimensionList";
import TablesChooserMain from "../TablesChooserMain/TablesChooserMain";

export default function TablesChooserScreen() {
  const [isVisible] = useStore<boolean>("isVisible");

  if (!isVisible) return null;

  return (
    <div className="tables-chooser-container flex items-stretch h-full">
      <TablesChooserAside>
        <TablesDimensionList />
      </TablesChooserAside>
      <TablesChooserMain />
    </div>
  );
}
