import { ViewModel } from "./viewModel"
import { VarianceViewModel } from "./varianceViewModel"
import { DataView } from "@zebrabi/matrix-data";
import { VarianceSettings } from "./varianceSettings";

export function viewModelFactory(dv: DataView[], locale: string, showCompanyStyle: boolean, settings?: VarianceSettings): ViewModel {
    let viewModel : ViewModel = null;

    if (!dv || !dv[0] || !dv[0].matrix) {
        viewModel = new ViewModel(showCompanyStyle);
    }
    else if (!dv[0].matrix.valueSources || dv[0].matrix.valueSources.length === 0) {
        if (!dv[0].matrix.rows) {
            viewModel = new ViewModel(showCompanyStyle);
        }
        else {
            viewModel = new VarianceViewModel(dv, locale, showCompanyStyle, settings);
        }
    }
    else {
        viewModel = new VarianceViewModel(dv, locale, showCompanyStyle, settings);
    }

    return viewModel;
}
