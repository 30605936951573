import { MainDimension } from "@zebrabi/chart-chooser/react/charts/dimensions/types";

import structureImage from "./images/dimension-structure.svg";
import comparisonTablesImage from "./images/dimension-comparison-tables.svg";
import crossTablesImage from "./images/dimension-cross-tables.svg";
import calculationTablesImage from "./images/dimension-calculation-tables.svg";
import hierarchicalTablesImage from "./images/dimension-hierarchical-tables.svg";

export const mainDimensions: MainDimension[] = [
  {
    id: "structure",
    title: "Structure",
    image: structureImage,
  },
  {
    id: "comparison",
    title: "Comparison tables",
    image: comparisonTablesImage,
  },
  {
    id: "cross",
    title: "Cross tables",
    image: crossTablesImage,
  },
  {
    id: "calculation",
    title: "Calculation tables",
    image: calculationTablesImage,
  },
  {
    id: "hierarchy",
    title: "Hierarchical tables",
    image: hierarchicalTablesImage,
    showNewBadge: true,
  },
];
