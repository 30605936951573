import { Plotter } from "./plotter";
import { PlotDefinition, MAXIMUM_CATEGORY_HEIGHT, SAFETY_MARGIN, ChartType, ACT_ABS_REL, ACTUAL, INTEGRATED, VERTICAL_MARGIN, ABSOLUTE, RELATIVE, ChartPlotDefinition, TOTAL } from "../../definitions";
import { ViewModel } from "../../settings/viewModel";
import { CategoryRowHeight, BOTTOM_SCROLLBAR_MARGIN, DataProperty, GroupTitleDisplayOptions, NORMAL, EMPTY, LEFT, CENTER, RIGHT_SCROLLBAR_MARGIN, RECT, FILL_OPACITY, STROKE_OPACITY, FILL, WHITE, STROKE, BLACK, STROKE_WIDTH, COLLAPSE_RECTANGLE, POINTER_EVENTS, INITIAL, POLYLINE, POINTS, GRAY, OPACITY, NONE, COLLAPSE_ARROW_ICON, TRANSFORM, WIDTH, HEIGHT, X, Y, RX, MOUSEOVER, MOUSEOUT, CLICK, CONTEXT_MENU, BOLD } from "../../library/constants";
import { horizontalScaling } from "./horizontalScaling";
import { plotSortableHeader, drawValueIcons, drawAbsoluteIcons, drawRelativeIcons } from "../charts/chart";
import { ColumnAdder } from "../columnAdder";
import { ChartGroup } from "../chartGroup";
import { Visual } from "../../visual";
import { ChartHierarchy } from "../chartHierarchy";
import { expandCollapseGroupMenu } from "../../ui/contextMenu";

import * as d3 from "d3";
import * as drawing from "./../../library/drawing";
import * as helpers from "./../../helpers";
import * as debug from "./../../library/debug";
import { getAdditionalMeasurePropertyFromIndex } from "../../helpers";
import { isValueMeasure, isAdditionalMeasure } from "../../library/helpers";
import { plotAnalytics } from "./analyticsPlotter";

export abstract class HorizontalPlotter extends Plotter {
    protected chartWidth;
    protected chartWidths: number[];
    private previousGroupWidth: number;

    protected abstract getPlotDefinition(): PlotDefinition;

    constructor(viewModel: ViewModel, width: number, height: number, reportArea: d3.Selection<SVGElement, any, any, any>, svg: d3.Selection<SVGElement, any, any, any>,
        /*selectionManager: ISelectionManager, options: VisualUpdateOptions,*/ locale: string) {
        super(viewModel, width, height, reportArea, svg, locale);
    }

    protected initialize(): void {
        this.definitionToPlot = this.getPlotDefinition();
        this.previousGroupWidth = 0;
    }

    public getRowHeight(): number {
        let minimumCategoryHeight = this.getMinimumCategoryHeight();
        let maxNumberOfCategories = this.viewModel.chartGroups.getMaxNumberOfCategories();
        let chartGroupHeight: number;
        let categoryHeight: number;

        const availableHeightActual = this.availableHeight - (this.settings.grandTotalGap ? VERTICAL_MARGIN : 0);

        switch (this.settings.categoriesRowHeight) {
            case CategoryRowHeight.Auto:
                chartGroupHeight = Math.max(this.minimumChartHeight(maxNumberOfCategories, minimumCategoryHeight), availableHeightActual);
                categoryHeight = chartGroupHeight / maxNumberOfCategories;
                categoryHeight = Math.min(categoryHeight, 2 * minimumCategoryHeight);
                break;
            case CategoryRowHeight.FontSized:
                categoryHeight = this.getMinimumCategoryHeight();
                categoryHeight = Math.min(categoryHeight, MAXIMUM_CATEGORY_HEIGHT);
                break;
            case CategoryRowHeight.FontSized_1_1:
                categoryHeight = this.getMinimumCategoryHeight() * 1.1;
                categoryHeight = Math.min(categoryHeight, MAXIMUM_CATEGORY_HEIGHT);
                break;
            case CategoryRowHeight.FontSized_1_5:
                categoryHeight = this.getMinimumCategoryHeight() * 1.5;
                categoryHeight = Math.min(categoryHeight, MAXIMUM_CATEGORY_HEIGHT);
                break;
            case CategoryRowHeight.Stretch:
                chartGroupHeight = Math.max(this.minimumChartHeight(maxNumberOfCategories, minimumCategoryHeight), availableHeightActual);
                categoryHeight = chartGroupHeight / maxNumberOfCategories;
                break;
            case CategoryRowHeight.Fixed:
                categoryHeight = this.settings.categoriesHeight;
                break;
        }

        return categoryHeight;
    }

    protected getChartHeights(): number[] {
        let numberOfCategories = this.viewModel.getNumberOfCategories(false);
        const categoryHeight = this.getRowHeight();
        return numberOfCategories.map(c => c * categoryHeight);
    }

    private minimumChartHeight(numberOfCategories: number, minimumCategoryHeight: number): number {
        return minimumCategoryHeight * numberOfCategories;
    }

    protected calculateTotalVisualHeight(): void {
        let chartsInAGroup = this.viewModel.chartGroups.getNumberOfChartsInAGroup();
        let grandTotalGap = 0; //(this.getRowHeight() / 2);
        if (chartsInAGroup > 1 && this.settings.showFrozenRowGrandTotal()) {
            chartsInAGroup--;
        }
        if (this.settings.grandTotalGap) {
            grandTotalGap += VERTICAL_MARGIN;
        }
        this.totalVisualHeight = Math.floor(d3.sum(this.chartHeights.slice(0, chartsInAGroup))) + grandTotalGap;
    }

    protected getDefinitionThatFits(): PlotDefinition {
        let plotDefinition = this.getPlotDefinition();
        this.viewModel.additionalMeasures.forEach((c, i) => {
            let dp = getAdditionalMeasurePropertyFromIndex(i);
            let chartType = this.settings.getAdditionalMeasureChartType(dp);
            this.addChartDefinition(plotDefinition, chartType, dp, DataProperty.ReferenceValue, false, false);
        });
        if (this.settings.chartType === ACT_ABS_REL) {
            this.fullDataProperties = plotDefinition.chartPlotDefinitions.map(cd => cd.dataProperty);
            this.filterRemovedChartsFromDefinitions([plotDefinition]);
        }
        if (this.settings.suppressEmptyColumns) {
            this.filterEmptyColumns([plotDefinition]);
        }
        return plotDefinition;
    }

    protected calculateChartWidths() {
        if (this.settings.groupTitleDisplayOptions === GroupTitleDisplayOptions.FixedWidth) {
            this.chartWidth = this.settings.groupTitleWidth;
        }
        else if (this.displayingTables()) {
            if (this.settings.groupTitleDisplayOptions === GroupTitleDisplayOptions.Auto || this.settings.groupTitleDisplayOptions === GroupTitleDisplayOptions.SizeToData) {
                let showingScenarioHeaders = this.settings.shouldShowScenarioSortHeaders(this);
                let dataProperties = this.definitionToPlot.chartPlotDefinitions.map(d => d.dataProperty);
                let sizeToData = this.settings.groupTitleDisplayOptions === GroupTitleDisplayOptions.SizeToData;
                this.chartWidths = this.viewModel.chartGroups.getColumnWidths(dataProperties, this.viewModel, showingScenarioHeaders, sizeToData, this.viewModel.lastLevelChartGroups);
            }
        }
        else {
            this.chartWidths = horizontalScaling(this.definitionToPlot.chartPlotDefinitions, this.viewModel.lastLevelChartGroups, this.viewModel, this.getMinimumChartWidth(), this.availableWidth, this);
        }
    }

    private displayingTables(): boolean {
        return this.definitionToPlot.chartPlotDefinitions.every(cd => cd.chartType === ChartType.Table) &&
            (this.settings.showAsTable ||
                this.viewModel.hasHierarchy && [ACTUAL, INTEGRATED].indexOf(this.settings.chartType) > -1);
    }

    @debug.measureDuration("Horizontal plot")
    public plotReport(partialPlot: boolean): void {
        this.partialPlot = partialPlot;
        this.calculateChartWidths();
        if (this.shouldPlotRowLevelSortHeader(partialPlot)) {
            let cd = this.definitionToPlot.chartPlotDefinitions[0];
            let header = this.settings.getHeader(cd.dataProperty);
            let headerWidth = drawing.measureTextWidth(header, this.settings.labelFontSize, this.settings.labelFontFamily, NORMAL, NORMAL) + 10;
            let sortableHeader = plotSortableHeader(null, this, this.viewModel, this.totalWidth, header, 0, null, 3, 3, "left", false, cd.dataProperty, cd.plotOrder, false, headerWidth, 0, false, false, true, true, true, this.currentGroupId, false, null, true, null, this.settings.showFrozenCategories(), this.chartId);
            sortableHeader.setChartChangeIcons(drawValueIcons, this.getChartType(cd.dataProperty));
        }
        if (!this.viewModel.plotOnlyCategories) {
            this.viewModel.lastLevelChartGroups.forEach((group, i) => {
                this.definitionToPlot.chartPlotDefinitions.forEach(cd => {
                    let extreme = group.extremes.getExtreme(cd.dataProperty, this.settings);
                    let chartWidth = this.getChartWidth(cd, i);
                    this.improveCumulativeOffsetsIfNeeded(extreme, cd.dataProperty, chartWidth, group);
                });
            });
            this.calculateChartWidths();
        }
        this.xPosition = this.settings.showUnfrozenCategories() ? this.categoriesWidth + VERTICAL_MARGIN : SAFETY_MARGIN;
        this.currentChart = 0;
        this.chartId = 0;
        this.currentGroupId = 0;
        let scrollLeft = (<any>window).currentScrollLeft;
        let areAllGroupsPlotted = true;
        for (let i = 0; i < this.viewModel.chartGroups.length; i++) {
            if (!this.isGroupFullyPlotted(this.viewModel.chartGroups.chartGroups[i])) {
                areAllGroupsPlotted = false;
                break;
            }
        }
        if (!areAllGroupsPlotted) {
            for (let i = 0; i < this.viewModel.chartGroups.length; i++) {
                let chartGroup = this.viewModel.chartGroups.chartGroups[i];
                this.plotChartGroup(i, chartGroup, this.isCurrentGroupInView(scrollLeft, chartGroup));
            }
            this.totalWidth = this.xPosition;
        }
        if (this.settings.shouldDrawColumnAdder(this.fullDataProperties, this.viewModel.numberOfGroupLevels)) {
            let columnAdder = new ColumnAdder(this.viewModel, this.fullDataProperties, this.categoriesWidth, this);
            let numberOfGroupHeaders = this.viewModel.maximumExpandedGroupLevel + 1;
            if (!this.settings.shouldShowScenarioSortHeaders(this)) {
                numberOfGroupHeaders--;
            }
            numberOfGroupHeaders = Math.max(0, numberOfGroupHeaders);
            columnAdder.plotColumnAdder(numberOfGroupHeaders * this.groupHeaderHeight - 1);
        }
    }

    public shouldPlotRowLevelSortHeader(partialPlot: boolean) {
        return !partialPlot && this.viewModel.showingGroupHeaders() && !this.settings.shouldShowScenarioSortHeaders(this);
    }

    // tslint:disable-next-line: max-func-body-length
    private plotChartGroup(groupIndex: number, chartGroup: ChartGroup, isInView: boolean) {
        if (chartGroup.hasChildGroups) {
            let yPosition = chartGroup.level * this.groupHeaderHeight;
            let startXPosition = this.xPosition;
            let scrollLeft = (<any>window).currentScrollLeft;
            let showingScenarioHeaders = this.settings.shouldShowScenarioSortHeaders(this);
            if (this.settings.getRealInteractionSettingValue(this.settings.allowExpandCollapseColumnsChange) &&
                !this.settings.isGroupOrParentCollapsed(<ChartGroup>chartGroup.parent)) {
                let left = this.xPosition - 14;
                if (this.viewModel.maximumExpandedGroupLevel === 0 && !showingScenarioHeaders) {
                    left += 3;
                }
                this.drawGroupCollapseArrow(chartGroup, left, yPosition + this.groupHeaderHeight / 2 + 1);
            }
            for (let i = 0; i < chartGroup.chartGroups.length; i++) {
                this.plotChartGroup(i, chartGroup.chartGroups[i], this.isCurrentGroupInView(scrollLeft, chartGroup));
            }
            if (this.viewModel.showingGroupHeaders() && isInView && !chartGroup.isPlotted && !chartGroup.hasCollapsedAncestor()) {
                let bold = chartGroup.level === 0;
                let groupTitleAlignment = this.settings.groupTitleAlignment;
                let margin = VERTICAL_MARGIN;
                if (!showingScenarioHeaders && chartGroup.isCollapsed && this.definitionToPlot.chartPlotDefinitions.length === 1 && this.definitionToPlot.chartPlotDefinitions[0].chartType === ChartType.Table) {
                    groupTitleAlignment = "right";
                }
                if (this.settings.shouldShowScenarioSortHeaders(this)) {
                    margin += VERTICAL_MARGIN;
                }
                let headerText = this.settings.getGroupName(chartGroup);
                let headerWidth = drawing.measureTextWidth(headerText, this.settings.groupTitleFontSize, this.settings.groupTitleFontFamily, BOLD, NORMAL)
                let xPosition = this.getGroupHeaderXPosition(startXPosition, headerWidth) - (this.settings.groupTitleAlignment === LEFT ? 0 : VERTICAL_MARGIN);
                let header = plotSortableHeader(null, this, this.viewModel, this.width, headerText, 0, null, xPosition, yPosition, groupTitleAlignment, bold, null, 0, true, this.xPosition - startXPosition - margin, xPosition, true, false, false, true, false, -1, false, chartGroup, true, null, false, this.chartId);

                if (this.shouldAlignHeaderNextToAxis(chartGroup)) {
                    let groupId = chartGroup.getTotalGroupId();
                    if (groupId !== -1) {
                        header.positionHeaderTextToAxis(groupId);
                    }
                }
                let bb = header.getBoundingBox();
                let lineYPosition = Math.round(yPosition + bb.height * 0.83) + 3;
                drawing.drawLine(Visual.headers, startXPosition, this.xPosition - margin, lineYPosition, lineYPosition, 1, this.settings.colorScheme.majorGridlineColor, EMPTY);
            }
            let isGroupOrParentCollapsed = chartGroup.parent && this.settings.isGroupOrParentCollapsed(<ChartGroup>chartGroup.parent);
            if (chartGroup.level <= this.viewModel.maximumExpandedGroupLevel - 1 && !showingScenarioHeaders && !isGroupOrParentCollapsed) {
                this.xPosition += VERTICAL_MARGIN;
            }
        }
        else {
            let skipGroup = this.settings.shouldSkipGroup(chartGroup);
            let hierarchies = chartGroup.getAllChartHierarchies();
            this.currentRow = 0;
            let startingHeight = this.currentYPosition;
            let startingXPosition = this.xPosition;
            for (let j = 0; j < hierarchies.length; j++) {
                let scrollTop = (<any>window).currentScrollTop;
                this.chartId = 0;
                let plottingGrandTotalRow = false;
                if (this.settings.shouldShowScenarioSortHeaders(this)) {
                    this.xPosition = startingXPosition;
                }
                if (hierarchies[j].isGrandTotal && this.settings.showFrozenRowGrandTotal()) {
                    plottingGrandTotalRow = true;
                    this.plotArea = Visual.grandTotalRow;
                    this.actualYEnd = this.currentYPosition;
                    this.currentYPosition = 0;
                } else if (hierarchies[j].isGrandTotal && this.settings.grandTotalGap) {
                    this.currentYPosition += VERTICAL_MARGIN;
                } else {
                    this.plotArea = this.reportArea;
                }

                let shouldBePlotted = isInView && this.shouldPlotHierachy(scrollTop, hierarchies[j], chartGroup, j);
                hierarchies[j].plotYPosition = this.currentYPosition;
                if ((<any>window).collapseScroll && hierarchies[j].fullCategory === (<any>window).collapseCategory) {
                    (<any>window).currentScrollTop = this.currentYPosition;
                    (<any>window).collapseScroll = false;
                }
                if (shouldBePlotted) {
                    if (this.shouldShowCategories(this.currentGroupId) && chartGroup.hasCategories) {
                        let tempPlotArea = this.plotArea;
                        if (this.settings.showFrozenCategories()) {
                            if (this.plotArea === Visual.grandTotalRow) {
                                this.plotArea = Visual.grandTotalCategoriesSelection;
                            } else {
                                this.plotArea = Visual.categoriesSelection;
                            }
                        }
                        this.plotCategories(this.currentGroupId, j, !this.viewModel.plotOnlyCategories);
                        this.plotArea = tempPlotArea;
                    }
                }
                if (!skipGroup) {
                    let isParentCollapsed = false;
                    if (chartGroup.parent !== null) {
                        isParentCollapsed = ((<ChartGroup>(chartGroup.parent)).isCollapsed);
                    }
                    this.plot(this.currentGroupId, j, this.viewModel.showingGroupHeaders(), shouldBePlotted, plottingGrandTotalRow, chartGroup.isSubtotal && !isParentCollapsed, chartGroup.level);
                    this.currentlyShowingDataPoints.forEach(dp => dp.isPlotted = true);
                }
                if (shouldBePlotted) {
                    hierarchies[j].plottedHeader = true;
                }
                if (isInView && !this.shouldPlotHierachy(scrollTop, hierarchies[j], chartGroup, j) && hierarchies[j].allDataPointsPlotted()) {
                    hierarchies[j].isPlotted = true;
                }
                this.currentYPosition += this.getCurrentChartHeight();
                this.currentChart++;
                this.currentRow++;

                if (!((chartGroup.isRowGrandTotal || hierarchies[j].isGrandTotal) && this.settings.showFrozenRowGrandTotal())) {
                    this.actualYEnd = this.currentYPosition;

                    if (this.settings.showUnfrozenRowGrandTotal()) {
                        this.actualYEnd = this.actualYEnd - this.chartHeights[this.chartHeights.length - 1];
                    }
                }
            }

            if (this.foundActual) {
                plotAnalytics(this.actualWidth, this.actualXPosition, this.actualYStart, groupIndex, this, this.settings.analyticsDataProperty,
                    chartGroup.extremes.analyticsValues, this.valueChartIntegrated);
                this.foundActual = false;
            }
            this.currentYPosition = startingHeight;
            if (this.viewModel.showingGroupHeaders()) {
                if (isInView && !chartGroup.isPlotted && !skipGroup) {
                    let showingScenarioHeaders = this.settings.shouldShowScenarioSortHeaders(this);
                    let isGroupHeader = (this.viewModel.numberOfGroupLevels - 1) !== chartGroup.level || showingScenarioHeaders;
                    let yPosition = chartGroup.level * this.groupHeaderHeight;
                    if (!isGroupHeader) {
                        yPosition += 3;
                    }
                    if (chartGroup.group !== null && !(chartGroup.parent && (<ChartGroup>chartGroup.parent).isCollapsed)) {
                        let textAlignment = this.settings.groupTitleAlignment;
                        let headerText = this.settings.getGroupName(chartGroup);
                        let headerWidth = drawing.measureTextWidth(headerText, this.settings.groupTitleFontSize, this.settings.groupTitleFontFamily, BOLD, NORMAL)
                        let xPosition = this.getGroupHeaderXPosition(startingXPosition, headerWidth);
                        if (!isGroupHeader) {
                            if (!showingScenarioHeaders && this.definitionToPlot.chartPlotDefinitions.length === 1 && this.definitionToPlot.chartPlotDefinitions[0].chartType === ChartType.Table) {
                                textAlignment = "right";
                            }
                            xPosition = startingXPosition;
                        }
                        if ((chartGroup.isSubtotal || chartGroup.isColumnGrandTotal) &&
                            !showingScenarioHeaders &&
                            this.definitionToPlot.chartPlotDefinitions.length === 1 &&
                            this.definitionToPlot.chartPlotDefinitions[0].chartType === ChartType.Table &&
                            chartGroup.level === this.viewModel.maximumExpandedGroupLevel) {
                            textAlignment = "right";
                        }
                        let allowSort = !showingScenarioHeaders || (chartGroup.isSubtotal || chartGroup.isColumnGrandTotal);
                        let dataProperty = this.getDataProperty();
                        let shouldAllowChartChange = this.shouldAllowChartChange(showingScenarioHeaders, dataProperty, chartGroup.isSubtotal, chartGroup.isColumnGrandTotal);
                        let bold = (chartGroup.level === 0 && this.settings.shouldShowScenarioSortHeaders(this))
                            || this.settings.shouldUseBoldGroupHeaders(chartGroup, this.viewModel.maximumExpandedGroupLevel);
                        let onlyShowChartChangeIcons = !chartGroup.isSubtotal;
                        let header = plotSortableHeader(null, this, this.viewModel, this.width, headerText, 0, this.currentGroupId, xPosition, yPosition, textAlignment, bold, dataProperty, 0, true, this.xPosition - startingXPosition - VERTICAL_MARGIN - 15, xPosition, isGroupHeader, false, allowSort, true, onlyShowChartChangeIcons, -1, false, chartGroup, false, null, false, this.chartId);
                        if (shouldAllowChartChange && !chartGroup.isSubtotal) {
                            let chartType = this.getChartType(dataProperty);
                            if (this.settings.chartType === ACT_ABS_REL) {
                                if (isValueMeasure(dataProperty)) {
                                    header.setChartChangeIcons(drawValueIcons, chartType);
                                }
                                else if (helpers.isAbsoluteDifferenceMeasure(dataProperty)) {
                                    header.setChartChangeIcons(drawAbsoluteIcons, chartType);
                                }
                                else if (helpers.isRelativeMeasure(dataProperty, this.settings)) {
                                    header.setChartChangeIcons(drawRelativeIcons, chartType);
                                }
                            }
                            else if (this.settings.chartType === ACTUAL) {
                                header.setChartChangeIcons(drawValueIcons, chartType);
                            }
                            else if (this.settings.chartType === ABSOLUTE) {
                                header.setChartChangeIcons(drawAbsoluteIcons, chartType);
                            }
                            else if (this.settings.chartType === RELATIVE) {
                                header.setChartChangeIcons(drawRelativeIcons, chartType);
                            }
                        }
                        if (!isGroupHeader) {
                            yPosition += 3;
                            header.positionHeaderTextToAxis(this.currentGroupId);
                        }
                        else if (chartGroup.isSubtotal && !showingScenarioHeaders) {
                            header.positionHeaderTextToAxis(this.currentGroupId);
                        }
                        let height = this.groupHeaderHeight;
                        if (header) {
                            height = header.getBoundingBox().height;
                        }
                        let lineYPosition = Math.round(yPosition + 0.83 * height) + 3;
                        drawing.drawLine(Visual.headers, startingXPosition, this.xPosition - VERTICAL_MARGIN, lineYPosition, lineYPosition, 1, this.settings.colorScheme.majorGridlineColor, EMPTY);
                    }
                }
                this.previousGroupWidth = this.xPosition - startingXPosition;
                if (this.settings.plottingHorizontally() && this.settings.shouldShowScenarioSortHeaders(this) && !skipGroup) {
                    this.xPosition += VERTICAL_MARGIN;
                }
                this.currentGroupId++;
            }
        }
        if (isInView) { chartGroup.isPlotted = true; }
    }

    private shouldAlignHeaderNextToAxis(chartGroup: ChartGroup) {
        return chartGroup.isCollapsed && this.definitionToPlot.chartPlotDefinitions.length === 1 && this.definitionToPlot.chartPlotDefinitions[0].chartType !== ChartType.Table;
    }

    private getGroupHeaderXPosition(startXPosition: number, headerWidth: number): number {
        if (this.settings.groupTitleAlignment === LEFT) {
            return startXPosition;
        }
        else if (this.settings.groupTitleAlignment === CENTER) {
            return Math.round(startXPosition + (this.xPosition - startXPosition) / 2);
        }
        else {
            return this.xPosition - 2 * VERTICAL_MARGIN - headerWidth;
        }
    }

    protected shouldShowCategories(i: number): boolean {
        return super.shouldShowCategories(i) && i === 0;
    }

    private isCurrentGroupInView(scrollLeft: number, chartGroup: ChartGroup) {
        return (this.xPosition < this.width + 300) || (this.xPosition < scrollLeft + this.width + 300 && this.xPosition > scrollLeft - this.width - this.previousGroupWidth) || (chartGroup.isRowGrandTotal && this.settings.showFrozenRowGrandTotal());
    }

    private shouldPlotHierachy(scrollTop: number, hierarchy: ChartHierarchy, chartGroup: ChartGroup, hierarchyIndex: number) {
        return (hierarchyIndex === 0 && this.settings.freezeHeaders && !hierarchy.anyDataPointPlotted()) || (this.isCurrentHierarchyInView(scrollTop) && !hierarchy.allDataPointsPlotted()) || ((chartGroup.isRowGrandTotal || hierarchy.isGrandTotal) && this.settings.showFrozenRowGrandTotal());
    }

    private isCurrentHierarchyInView(scrollTop: number): boolean {
        return this.currentYPosition + this.getCurrentChartHeight() + 300 > scrollTop
            && this.currentYPosition - 300 < scrollTop + this.height;
    }

    private isGroupFullyPlotted(chartGroup: ChartGroup): boolean {
        if (!chartGroup.isPlotted) {
            return false;
        }
        if (chartGroup.hasChildGroups) {
            for (let i = 0; i < chartGroup.chartGroups.length; i++) {
                if (!this.isGroupFullyPlotted(chartGroup.chartGroups[i])) {
                    return false;
                }
            }
        }
        else {
            let hierarchies = chartGroup.getAllChartHierarchies();
            for (let i = 0; i < hierarchies.length; i++) {
                if (!hierarchies[i].isPlotted) {
                    return false;
                }
            }
        }
        return true;
    }

    protected getMinimumChartWidth(): number {
        if (this.viewModel.getNumberOfCharts() === 1) {
            return 0;
        }
        if (this.settings.showAsTable && this.definitionToPlot.chartPlotDefinitions.every(cd => cd.chartType === ChartType.Table)) {
            return 30;
        }
        else {
            return this.settings.minChartWidth;
        }
    }

    protected getChartWidth(d: ChartPlotDefinition, groupIndex: number): number {
        if (this.settings.groupTitleDisplayOptions === GroupTitleDisplayOptions.FixedWidth) {
            return this.settings.groupTitleWidth;
        }
        else if (this.displayingTables()) {
            let propertyIndex = this.definitionToPlot.chartPlotDefinitions.indexOf(d);
            return this.chartWidths[groupIndex * this.definitionToPlot.chartPlotDefinitions.length + propertyIndex];
        }
        else {
            let numOfDataProperties = this.definitionToPlot.chartPlotDefinitions.length;
            let index = this.definitionToPlot.chartPlotDefinitions.indexOf(d);
            return this.chartWidths[groupIndex * numOfDataProperties + index];
        }
    }

    protected getDataProperty(): DataProperty {
        if (this.definitionToPlot.chartPlotDefinitions.length === 1) {
            return this.definitionToPlot.chartPlotDefinitions[0].dataProperty;
        }
        return DataProperty.Value;
    }

    protected getMaxCategoryWidth(): number {
        if (this.settings.showAsTable) {
            this.calculateChartWidths();
            let allWidths: number;
            if (this.settings.groupTitleDisplayOptions === GroupTitleDisplayOptions.FixedWidth) {
                allWidths = this.viewModel.numberOfLastLevelChartGroups * this.definitionToPlot.chartPlotDefinitions.length * (this.settings.groupTitleWidth + VERTICAL_MARGIN);
            }
            else {
                allWidths = d3.sum(this.chartWidths) + (this.chartWidths.length) * VERTICAL_MARGIN;
            }
            if (allWidths > this.width && this.multipleCharts && this.width > 300) {
                return this.viewModel.maxCategoryWidth;
            }
            return Math.max(this.viewModel.maxCategoryWidth / 2, this.width - allWidths - RIGHT_SCROLLBAR_MARGIN);
        }
        else {
            return this.width / 4;
        }
    }

    private drawGroupCollapseArrow(chartGroup: ChartGroup, xPosition: number, yPosition: number) {
        let container = Visual.headers;
        let x = xPosition;
        let y = yPosition;
        let settings = this.settings;
        let height = this.height;
        let level = chartGroup.level;
        let viewModel = this.viewModel;
        let rect = container.append(RECT)
            .attr(FILL_OPACITY, 0)
            .attr(STROKE_OPACITY, 0)
            .attr(FILL, WHITE)
            .attr(STROKE, BLACK)
            .attr(STROKE_WIDTH, 1)
            .classed(COLLAPSE_RECTANGLE, true)
            .style(POINTER_EVENTS, INITIAL);
        let arrow = container
            .append(POLYLINE)
            .attr(POINTS, p => {
                if (chartGroup.isCollapsed) {
                    return `${x + 3} ${y + 7}, ${x + 8} ${y + 2}, ${x + 3} ${y - 3}`;
                }
                else {
                    return `${x} ${y}, ${x + 5} ${y + 5}, ${x + 10} ${y}`;
                }
            })
            .attr(STROKE, GRAY)
            .attr(STROKE_WIDTH, 1)
            .attr(FILL_OPACITY, 0)
            .attr(OPACITY, 0.0)
            .attr(POINTER_EVENTS, NONE)
            .style(POINTER_EVENTS, INITIAL)
            .classed(COLLAPSE_ARROW_ICON, true);

        rect.attr(WIDTH, 14)
            .attr(HEIGHT, 15)
            .attr(X, x - 2)
            .attr(Y, y - 5)
            .attr(RX, 3);

        arrow
            .on(MOUSEOVER, () => {
                arrow.attr(STROKE, BLACK);
                rect.attr(FILL_OPACITY, 0.04).attr(STROKE_OPACITY, 0.4);
            })
            .on(MOUSEOUT, () => {
                arrow.attr(STROKE, GRAY);
                rect.attr(FILL_OPACITY, 0).attr(STROKE_OPACITY, 0);
            })
            .on(CLICK, () => {
                settings.collapsedChangeGroup(chartGroup);
                settings.persistGroupsCollapsed();
            })
            .on(CONTEXT_MENU, expandCollapseGroupMenu(viewModel, yPosition + 22, height, level));

        rect
            .on(MOUSEOVER, () => {
                arrow.attr(STROKE, BLACK);
                rect.attr(FILL_OPACITY, 0.04).attr(STROKE_OPACITY, 0.4);
            })
            .on(MOUSEOUT, () => {
                arrow.attr(STROKE, GRAY);
                rect.attr(FILL_OPACITY, 0).attr(STROKE_OPACITY, 0);
            })
            .on(CLICK, () => {
                settings.collapsedChangeGroup(chartGroup);
                settings.persistGroupsCollapsed();
            })
            .on(CONTEXT_MENU, expandCollapseGroupMenu(viewModel, yPosition + 22, height, level));
    }

    private shouldAllowChartChange(showingScenarioHeaders: boolean, dataProperty: DataProperty, isSubtotal: boolean, isColumnGrandTotal: boolean): boolean {
        if (isSubtotal || isColumnGrandTotal) {
            return true;
        }
        if (this.settings.chartType === ACT_ABS_REL) {
            if (showingScenarioHeaders) {
                return false;
            }
            if (isAdditionalMeasure(dataProperty)) {
                return false;
            }
            return true;
        }
        else {
            return this.settings.chartType === ACTUAL || this.settings.chartType === ABSOLUTE || this.settings.chartType === RELATIVE;
        }
    }

    protected isMultipleCharts(): boolean {
        return (!this.settings.showRowGrandTotal && this.viewModel.getNumberOfCharts() > 1)
            || (this.settings.showRowGrandTotal && this.viewModel.getNumberOfCharts() > 1);
    }
}
