import { SheetData } from "x-data-spreadsheet";

export const ComparisonTableOneSampleData: SheetData = {
  rows: {
    0: { cells: { 0: { text: "Fruit" }, 1: { text: "AC" }, 2: { text: "PY" } } },
    1: { cells: { 0: { text: "Oranges" }, 1: { text: "170" }, 2: { text: "150" } } },
    2: { cells: { 0: { text: "Lemons" }, 1: { text: "168" }, 2: { text: "160" } } },
    3: { cells: { 0: { text: "Bananas" }, 1: { text: "150" }, 2: { text: "160" } } },
    4: { cells: { 0: { text: "Kiwi" }, 1: { text: "140" }, 2: { text: "170" } } },
    5: { cells: { 0: { text: "Apples" }, 1: { text: "138" }, 2: { text: "155" } } },
    6: { cells: { 0: { text: "Melons" }, 1: { text: "145" }, 2: { text: "160" } } },
    7: { cells: { 0: { text: "Pears" }, 1: { text: "120" }, 2: { text: "110" } } },
    8: { cells: { 0: { text: "Grapes" }, 1: { text: "110" }, 2: { text: "105" } } },
  },
};

export const ComparisonTableOneCommentData: SheetData = {
  rows: {
    0: { cells: { 0: { text: "Comments" } } },
    1: {
      cells: {
        0: {
          text: "Oranges volume will be increased in the next quarter. Contracts signed with new suppliers in Southern Europe to meet the increased demand.",
        },
      },
    },
    3: { cells: { 0: { text: "South American supply has dropped by 10% due to a temporary export ban." } } },
    8: {
      cells: {
        0: { text: "The demand is gradually increasing just before spring/summer season. Evaluate new suppliers." },
      },
    },
  },
};

export const ComparisonTableTwoSampleData: SheetData = {
  rows: {
    0: { cells: { 0: { text: "Fruit" }, 1: { text: "AC" }, 2: { text: "PY" }, 3: { text: "PL" } } },
    1: { cells: { 0: { text: "Oranges" }, 1: { text: "170" }, 2: { text: "155" }, 3: { text: "150" } } },
    2: { cells: { 0: { text: "Lemons" }, 1: { text: "168" }, 2: { text: "170" }, 3: { text: "160" } } },
    3: { cells: { 0: { text: "Bananas" }, 1: { text: "150" }, 2: { text: "160" }, 3: { text: "160" } } },
    4: { cells: { 0: { text: "Kiwi" }, 1: { text: "140" }, 2: { text: "150" }, 3: { text: "170" } } },
    5: { cells: { 0: { text: "Apples" }, 1: { text: "138" }, 2: { text: "145" }, 3: { text: "155" } } },
    6: { cells: { 0: { text: "Melons" }, 1: { text: "145" }, 2: { text: "130" }, 3: { text: "160" } } },
    7: { cells: { 0: { text: "Pears" }, 1: { text: "120" }, 2: { text: "115" }, 3: { text: "110" } } },
    8: { cells: { 0: { text: "Grapes" }, 1: { text: "110" }, 2: { text: "105" }, 3: { text: "105" } } },
  },
};

export const ComparisonTableTwoCommentData: SheetData = {
  rows: {
    0: { cells: { 0: { text: "Comments" } } },
    1: {
      cells: {
        0: {
          text: "Oranges volume will be increased in the next quarter. Contracts signed with new suppliers in Southern Europe to meet the increased demand.",
        },
      },
    },
    3: { cells: { 0: { text: "South American supply has dropped by 10% due to a temporary export ban." } } },
    8: {
      cells: {
        0: { text: "The demand is gradually increasing just before spring/summer season. Evaluate new suppliers." },
      },
    },
  },
};

export const ComparisonTableThreeSampleData: SheetData = {
  rows: {
    0: {
      cells: { 0: { text: "Fruit" }, 1: { text: "AC" }, 2: { text: "PY" }, 3: { text: "PL" }, 4: { text: "FC" } },
    },
    1: {
      cells: { 0: { text: "Oranges" }, 1: { text: "170" }, 2: { text: "155" }, 3: { text: "150" }, 4: { text: "160" } },
    },
    2: {
      cells: { 0: { text: "Lemons" }, 1: { text: "168" }, 2: { text: "170" }, 3: { text: "160" }, 4: { text: "145" } },
    },
    3: {
      cells: { 0: { text: "Bananas" }, 1: { text: "150" }, 2: { text: "160" }, 3: { text: "160" }, 4: { text: "145" } },
    },
    4: {
      cells: { 0: { text: "Kiwi" }, 1: { text: "140" }, 2: { text: "150" }, 3: { text: "170" }, 4: { text: "160" } },
    },
    5: {
      cells: { 0: { text: "Apples" }, 1: { text: "138" }, 2: { text: "145" }, 3: { text: "155" }, 4: { text: "145" } },
    },
    6: {
      cells: { 0: { text: "Melons" }, 1: { text: "145" }, 2: { text: "130" }, 3: { text: "160" }, 4: { text: "130" } },
    },
    7: {
      cells: { 0: { text: "Pears" }, 1: { text: "120" }, 2: { text: "115" }, 3: { text: "110" }, 4: { text: "130" } },
    },
    8: {
      cells: { 0: { text: "Grapes" }, 1: { text: "110" }, 2: { text: "105" }, 3: { text: "105" }, 4: { text: "120" } },
    },
  },
};

export const ComparisonTableThreeCommentData: SheetData = {
  rows: {
    0: { cells: { 0: { text: "Comments" } } },
    1: {
      cells: {
        0: {
          text: "Oranges volume will be increased in the next quarter. Contracts signed with new suppliers in Southern Europe to meet the increased demand.",
        },
      },
    },
    3: { cells: { 0: { text: "South American supply has dropped by 10% due to a temporary export ban." } } },
    8: {
      cells: {
        0: { text: "The demand is gradually increasing just before spring/summer season. Evaluate new suppliers." },
      },
    },
  },
};
