
import { Plotter } from "../plotters/plotter";
import { VarianceSettings } from "../../settings/varianceSettings";
import { DataProperty, AXIS, STROKE_DASHARRAY, STROKE_OPACITY, SEGOE_UI, NORMAL, HorizontalLabelPostion, VerticalLabelPosition, LabelTextOption, DifferenceLabel, ANALYTICS_AREA, DisplayUnits } from "../../library/constants";
import { ViewModel } from "../../settings/viewModel";
import { getMinMax } from "../charts/plotStructureChart";

import * as drawing from "./../../library/drawing";
import * as d3 from "d3";
import { getLabelOffset } from "../charts/plotIntegratedChart";
import { getFormattedDataLabel } from "../charts/chart";

export function plotAnalytics(width: number, xPosition: number, yPosition: number, groupIndex: number,
    plotter: Plotter, dataProperty: DataProperty, analyticsValues: number[], integrated: boolean) {
    let { viewModel, settings, actualYEnd } = plotter;
    let height = actualYEnd - yPosition;
    let chartGroup = viewModel.lastLevelChartGroups[groupIndex];
    let xScale: d3.ScaleLinear<number, number> = null;
    let minLabelOffset: number, maxLabelOffset: number, start: number, end: number;
    if (integrated) {
        let mm = settings.plottingHorizontally() ? chartGroup.extremes : viewModel.extremes;
        minLabelOffset = getLabelOffset(mm, settings, true);
        maxLabelOffset = getLabelOffset(mm, settings, false);
        start = xPosition + minLabelOffset;
        end = Math.max(xPosition + width - maxLabelOffset, start);
        xScale = drawing.getLinearScale(Math.min(mm.integratedMin, 0), Math.max(mm.integratedMax, 0), start, end);
    } else {
        let minMax = getMinMax(viewModel, xPosition, width, dataProperty, chartGroup);
        minLabelOffset = minMax.getMinLabelOffset(settings);
        maxLabelOffset = minMax.getMaxLabelOffset(settings);
        start = xPosition + minLabelOffset;
        end = Math.max(xPosition + width - maxLabelOffset, start);
        xScale = drawing.getLinearScale(minMax.min, minMax.max, start, end);
    }
    plotter.reportArea.select(`.${ANALYTICS_AREA}_${groupIndex}`).remove();
    let chartArea = plotter.reportArea.append("g").classed(`${ANALYTICS_AREA}_${groupIndex}`, true);
    chartArea.selectAll("*").remove();
    if (settings.showMedianLine) {
        plotMedianLine(xScale, height, chartArea, yPosition, settings, viewModel, d3.median(analyticsValues), true);
    }
    if (settings.showAverageLine) {
        plotAverageLine(xScale, height, chartArea, yPosition, settings, viewModel, d3.mean(analyticsValues), true);
    }
    if (settings.showConstantLine && xScale(settings.constantLineValue) >= start - minLabelOffset - 1 && xScale(settings.constantLineValue) <= end + maxLabelOffset) {
        plotConstantLine(xScale, height, chartArea, yPosition, settings, viewModel, true);
    }
    if (settings.showPercentileLine) {
        plotPercentileLine(xScale, height, chartArea, yPosition, settings, viewModel, d3.quantile(analyticsValues.filter(v => v !== null).sort(d3.ascending), settings.percentileLinePercent / 100), true);
    }
}

export function plotGTAnalytics(width: number, xPosition: number, yPosition: number, groupIndex: number,
    plotter: Plotter, dataProperty: DataProperty, mean: number, median: number, percentile: number) {

    let { viewModel, settings, plotArea, currentYPosition: currentHeight } = plotter;

    let height = currentHeight - yPosition;
    let chartGroup = viewModel.lastLevelChartGroups[groupIndex];
    let minMax = getMinMax(viewModel, xPosition, width, dataProperty, chartGroup);
    let start = xPosition + minMax.getMinLabelOffset(settings);
    let end = Math.max(xPosition + width - minMax.getMaxLabelOffset(settings), start);
    let xScale = drawing.getLinearScale(minMax.min, minMax.max, start, end);
    plotArea.select(`.${ANALYTICS_AREA}_${groupIndex}_GT`).remove();
    let chartArea = plotArea.append("g").classed(`${ANALYTICS_AREA}_${groupIndex}_GT`, true);
    chartArea.selectAll("*").remove();
    if (settings.showMedianLine) {
        plotMedianLine(xScale, height, chartArea, yPosition, settings, viewModel, median, true);
    }
    if (settings.showAverageLine) {
        plotAverageLine(xScale, height, chartArea, yPosition, settings, viewModel, mean, false);
    }
    if (settings.showConstantLine) {
        plotConstantLine(xScale, height, chartArea, yPosition, settings, viewModel, false);
    }
    if (settings.showPercentileLine) {
        plotPercentileLine(xScale, height, chartArea, yPosition, settings, viewModel, percentile, false);
    }
}

function plotAverageLine(xScale: d3.ScaleLinear<number, number>,
    height: number, chartArea: d3.Selection<SVGElement, any, any, any>,
    yPosition: number, settings: VarianceSettings, viewModel: ViewModel, mean: number, shouldPlotLabel: boolean) {

    let x = xScale(mean);
    let y = yPosition;

    x = Math.round(x);
    y = Math.round(y);
    if (isNaN(x) || isNaN(y)) {
        return;
    }
    height = Math.round(height);
    let line = drawing.drawLine(chartArea, x, x, y + 5, y + height - 5, 2, settings.averageLineFill, `${AXIS}`).classed("analytics", true);
    line.style(STROKE_DASHARRAY, settings.averageLineStyle).style(STROKE_OPACITY, (100 - settings.averageLineTransparency) / 100);

    if (settings.averageLabelShow && shouldPlotLabel) {
        plotLabel(mean, x, y, height, viewModel.locale, chartArea, settings.averageLabelColor, settings.averageLabelDecimalPlaces, settings.averageLabelHorizontalPosition,
            settings.averageLabelVerticalPosition, settings.averageLabelUnits, settings.averageLabelTextOption, settings.averageLabelText, "average");
    }
}

function plotMedianLine(xScale: d3.ScaleLinear<number, number>,
    height: number, chartArea: d3.Selection<SVGElement, any, any, any>,
    yPosition: number, settings: VarianceSettings, viewModel: ViewModel, median: number, shouldPlotLabel: boolean) {

    let x = xScale(median);
    let y = yPosition;

    x = Math.round(x);
    y = Math.round(y);
    if (isNaN(x) || isNaN(y)) {
        return;
    }
    height = Math.round(height);
    let line = drawing.drawLine(chartArea, x, x, y + 5, y + height - 5, 2, settings.medianLineFill, `${AXIS}`).classed("analytics", true);
    line.style(STROKE_DASHARRAY, settings.medianLineStyle).style(STROKE_OPACITY, (100 - settings.medianLineTransparency) / 100);

    if (settings.medianLabelShow && shouldPlotLabel) {
        plotLabel(median, x, y, height, viewModel.locale, chartArea, settings.medianLabelColor, settings.medianLabelDecimalPlaces, settings.medianLabelHorizontalPosition,
            settings.medianLabelVerticalPosition, settings.medianLabelUnits, settings.medianLabelTextOption, settings.medianLabelText, "median");
    }
}

function plotPercentileLine(xScale: d3.ScaleLinear<number, number>,
    height: number, chartArea: d3.Selection<SVGElement, any, any, any>,
    yPosition: number, settings: VarianceSettings, viewModel: ViewModel, percentile: number, shouldPlotLabel: boolean) {

    let x = xScale(percentile);
    let y = yPosition;

    x = Math.round(x);
    y = Math.round(y);
    if (isNaN(x) || isNaN(y)) {
        return;
    }
    height = Math.round(height);
    let line = drawing.drawLine(chartArea, x, x, y + 5, y + height - 5, 2, settings.percentileLineFill, `${AXIS}`).classed("analytics", true);
    line.style(STROKE_DASHARRAY, settings.percentileLineStyle).style(STROKE_OPACITY, (100 - settings.percentileLineTransparency) / 100);

    if (settings.percentileLabelShow && shouldPlotLabel) {
        plotLabel(percentile, x, y, height, viewModel.locale, chartArea, settings.percentileLabelColor, settings.percentileLabelDecimalPlaces, settings.percentileLabelHorizontalPosition,
            settings.percentileLabelVerticalPosition, settings.percentileLabelUnits, settings.percentileLabelTextOption, settings.percentileLabelText, "percentile");
    }
}

function plotConstantLine(xScale: d3.ScaleLinear<number, number>,
    height: number, chartArea: d3.Selection<SVGElement, any, any, any>,
    yPosition: number, settings: VarianceSettings, viewModel: ViewModel, shouldPlotLabel: boolean) {

    let x = xScale(settings.constantLineValue);
    let y = yPosition;

    x = Math.round(x);
    y = Math.round(y);
    if (isNaN(x) || isNaN(y)) {
        return;
    }
    height = Math.round(height);
    let line = drawing.drawLine(chartArea, x, x, y + 5, y + height - 5, 2, settings.constantLineFill, `${AXIS}`).classed("analytics", true);
    line.style(STROKE_DASHARRAY, settings.constantLineStyle).style(STROKE_OPACITY, (100 - settings.constantLineTransparency) / 100);

    if (settings.constantLabelShow && shouldPlotLabel) {
        plotLabel(settings.constantLineValue, x, y, height, viewModel.locale, chartArea, settings.constantLabelColor, settings.constantLabelDecimalPlaces, settings.constantLabelHorizontalPosition,
            settings.constantLabelVerticalPosition, settings.constantLabelUnits, settings.constantLabelTextOption, settings.constantLabelText, "constant");
    }
}

function plotLabel(value: number, x: number, y: number, height: number, locale: string, chartArea: d3.Selection<SVGElement, any, any, any>, color: string, decimalPlaces: number,
    horizontalPosition: HorizontalLabelPostion, verticalPosition: VerticalLabelPosition, units: DisplayUnits, textOption: LabelTextOption, titleText: string, addedClass: string) {
    let label = getFormattedDataLabel(value, decimalPlaces, units, locale, false, false);
    let labelWidth = drawing.measureTextWidth(label, 12, SEGOE_UI, NORMAL, NORMAL);
    let labelTitleWidth = drawing.measureTextWidth(titleText, 12, SEGOE_UI, NORMAL, NORMAL);
    let labelTitleHeight = drawing.measureTextHeight(titleText, 12, SEGOE_UI, NORMAL, NORMAL);

    let extraHeight = textOption === LabelTextOption.Value || textOption === LabelTextOption.Name ? 0 : labelTitleHeight;

    let drawX = x - labelTitleWidth / 2 - 5;
    if (horizontalPosition === HorizontalLabelPostion.Right) {
        drawX = x + labelTitleWidth / 2 + 5;
    }
    let drawY = y + 11;
    if (verticalPosition === VerticalLabelPosition.Below) {
        drawY = y + height - 12 - extraHeight;
    }

    if (textOption === LabelTextOption.Name || textOption === LabelTextOption.NameAndValue) {
        drawing.drawText(chartArea, titleText, drawX, drawY, 12, color)
            .classed("above", verticalPosition === VerticalLabelPosition.Above)
            .classed("below", verticalPosition === VerticalLabelPosition.Below)
            .classed(`line-titlelabel-${addedClass}`, true);
    }

    if (textOption === LabelTextOption.Value || textOption === LabelTextOption.NameAndValue) {
        drawX = horizontalPosition === HorizontalLabelPostion.Left ? x - labelWidth / 2 - 5 : x + labelWidth / 2 + 5;
        drawY += extraHeight;
        drawing.drawText(chartArea, label, drawX, drawY, 12, color)
            .classed("above", verticalPosition === VerticalLabelPosition.Above)
            .classed("below", verticalPosition === VerticalLabelPosition.Below)
            .classed(`line-label-${addedClass}`, true);
    }
}

export function fixLabelHeights(visualDiv: HTMLElement, grandTotalDiv: HTMLElement, settings: VarianceSettings) {
    if (d3.selectAll(".analytics").nodes().length) {
        let height = parseInt(d3.select(".analytics").attr("y2")) - parseInt(d3.select(".analytics").attr("y1"));
        let visualDivHeight = drawing.getBoundingBoxHtml(visualDiv).height;

        let visualHeight = visualDivHeight;
        if (grandTotalDiv) {
            visualHeight += drawing.getBoundingBoxHtml(grandTotalDiv).height;
        }

        if (height + 28 > visualHeight) {
            let analyticsLineEnd = parseInt(d3.select(".analytics").attr("y2"));
            let extraHeight = settings.averageLabelTextOption === LabelTextOption.Name || settings.averageLabelTextOption === LabelTextOption.Value ? 0 : 15
            fixLabelHeightsInstance(true, ".above.line-titlelabel-average", ".above.line-label-average", visualDiv.scrollTop + 15, visualDiv.scrollTop + 15);
            fixLabelHeightsInstance(false, ".below.line-titlelabel-average", ".below.line-label-average", Math.min(visualDiv.scrollTop + visualDivHeight - 10 - extraHeight, analyticsLineEnd - extraHeight), Math.min(visualDiv.scrollTop + visualDivHeight - 10, analyticsLineEnd));
            extraHeight = settings.constantLabelTextOption === LabelTextOption.Name || settings.constantLabelTextOption === LabelTextOption.Value ? 0 : 15
            fixLabelHeightsInstance(true, ".above.line-titlelabel-constant", ".above.line-label-constant", visualDiv.scrollTop + 15, visualDiv.scrollTop + 15);
            fixLabelHeightsInstance(false, ".below.line-titlelabel-constant", ".below.line-label-constant", Math.min(visualDiv.scrollTop + visualDivHeight - 10 - extraHeight, analyticsLineEnd - extraHeight), Math.min(visualDiv.scrollTop + visualDivHeight - 10, analyticsLineEnd));
            extraHeight = settings.medianLabelTextOption === LabelTextOption.Name || settings.medianLabelTextOption === LabelTextOption.Value ? 0 : 15
            fixLabelHeightsInstance(true, ".above.line-titlelabel-median", ".above.line-label-median", visualDiv.scrollTop + 15, visualDiv.scrollTop + 15);
            fixLabelHeightsInstance(false, ".below.line-titlelabel-median", ".below.line-label-median", Math.min(visualDiv.scrollTop + visualDivHeight - 10 - extraHeight, analyticsLineEnd - extraHeight), Math.min(visualDiv.scrollTop + visualDivHeight - 10, analyticsLineEnd));
            extraHeight = settings.percentileLabelTextOption === LabelTextOption.Name || settings.percentileLabelTextOption === LabelTextOption.Value ? 0 : 15
            fixLabelHeightsInstance(true, ".above.line-titlelabel-percentile", ".above.line-label-percentile", visualDiv.scrollTop + 15, visualDiv.scrollTop + 15);
            fixLabelHeightsInstance(false, ".below.line-titlelabel-percentile", ".below.line-label-percentile", Math.min(visualDiv.scrollTop + visualDivHeight - 10 - extraHeight, analyticsLineEnd - extraHeight), Math.min(visualDiv.scrollTop + visualDivHeight - 10, analyticsLineEnd));
        }
    }
}

function fixLabelHeightsInstance(above: boolean, cls: string, cls2: string, titleHeight: number, labelHeight: number) {
    let titlesAbove = d3.selectAll(cls);
    if (titlesAbove.nodes().length) {
        titlesAbove.attr("y", titleHeight);
        if (above) {
            labelHeight += 15;
        }
    }
    d3.selectAll(cls2).attr("y", labelHeight);
}
