import { VerticalPlotter } from "./verticalPlotter";
import { ViewModel } from "../../settings/viewModel";
import { PlotDefinition, ChartType } from "../../definitions";
import { DataProperty } from "../../library/constants";

export class ResponsiveScenarioPlotter extends VerticalPlotter {
    constructor(viewModel: ViewModel, width: number, height: number, reportArea: d3.Selection<SVGElement, any, any, any>, svg: d3.Selection<SVGElement, any, any, any>,
        locale: string) {
        super(viewModel, width, height, reportArea, svg, locale);
    }

    // tslint:disable-next-line: max-func-body-length
    protected getPlotDefinitions(): PlotDefinition[] {
        let plotDefinitions: PlotDefinition[] = [];
        let plotDefinition: PlotDefinition;
        if (this.viewModel.hasHierarchy) {
            plotDefinition = this.getBlankPlotDefinition(0.7);
            this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.ReferenceValue, DataProperty.ReferenceValue, false, false);
            this.addChartDefinition(plotDefinition, ChartType.AbsoluteChart, DataProperty.AbsoluteDifference, DataProperty.ReferenceValue, false, false);
            this.addChartDefinition(plotDefinition, ChartType.PlusMinusDot, DataProperty.RelativeDifference, DataProperty.ReferenceValue, false, false);
            this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.Value, DataProperty.ReferenceValue, false, false);
            this.addChartDefinition(plotDefinition, ChartType.AbsoluteChart, DataProperty.SecondAbsoluteDifference, DataProperty.ReferenceValue, false, false);
            this.addChartDefinition(plotDefinition, ChartType.PlusMinusDot, DataProperty.SecondRelativeDifference, DataProperty.ReferenceValue, false, false);
            this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.SecondReferenceValue, DataProperty.ReferenceValue, false, false);
            if (this.viewModel.HasThirdReferenceColumn) {
                this.addChartDefinition(plotDefinition, ChartType.AbsoluteChart, DataProperty.ThirdAbsoluteDifference, DataProperty.ReferenceValue, false, false);
                this.addChartDefinition(plotDefinition, ChartType.PlusMinusDot, DataProperty.ThirdRelativeDifference, DataProperty.ReferenceValue, false, false);
                this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.ThirdReferenceValue, DataProperty.ReferenceValue, false, false);
            }
            if (this.viewModel.HasFourthReferenceColumn) {
                this.addChartDefinition(plotDefinition, ChartType.AbsoluteChart, DataProperty.FourthAbsoluteDifference, DataProperty.ReferenceValue, false, false);
                this.addChartDefinition(plotDefinition, ChartType.PlusMinusDot, DataProperty.FourthRelativeDifference, DataProperty.ReferenceValue, false, false);
                this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.FourthReferenceValue, DataProperty.ReferenceValue, false, false);
            }
            if (this.viewModel.HasFifthReferenceColumn) {
                this.addChartDefinition(plotDefinition, ChartType.AbsoluteChart, DataProperty.FifthAbsoluteDifference, DataProperty.ReferenceValue, false, false);
                this.addChartDefinition(plotDefinition, ChartType.PlusMinusDot, DataProperty.FifthRelativeDifference, DataProperty.ReferenceValue, false, false);
                this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.FifthReferenceValue, DataProperty.ReferenceValue, false, false);
            }
            if (this.viewModel.HasSixthReferenceColumn) {
                this.addChartDefinition(plotDefinition, ChartType.AbsoluteChart, DataProperty.SixthAbsoluteDifference, DataProperty.ReferenceValue, false, false);
                this.addChartDefinition(plotDefinition, ChartType.PlusMinusDot, DataProperty.SixthRelativeDifference, DataProperty.ReferenceValue, false, false);
                this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.SixthReferenceValue, DataProperty.ReferenceValue, false, false);
            }
            if (this.viewModel.HasSeventhReferenceColumn) {
                this.addChartDefinition(plotDefinition, ChartType.AbsoluteChart, DataProperty.SeventhAbsoluteDifference, DataProperty.ReferenceValue, false, false);
                this.addChartDefinition(plotDefinition, ChartType.PlusMinusDot, DataProperty.SeventhRelativeDifference, DataProperty.ReferenceValue, false, false);
                this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.SeventhReferenceValue, DataProperty.ReferenceValue, false, false);
            }
            plotDefinitions.push(plotDefinition);

            plotDefinition = this.getBlankPlotDefinition(1);
            this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.ReferenceValue, DataProperty.ReferenceValue, false, false);
            this.addChartDefinition(plotDefinition, ChartType.AbsoluteChart, DataProperty.AbsoluteDifference, DataProperty.ReferenceValue, false, false);
            this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.RelativeDifference, DataProperty.ReferenceValue, false, false);
            this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.Value, DataProperty.ReferenceValue, false, false);
            this.addChartDefinition(plotDefinition, ChartType.AbsoluteChart, DataProperty.SecondAbsoluteDifference, DataProperty.ReferenceValue, false, false);
            this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.SecondRelativeDifference, DataProperty.ReferenceValue, false, false);
            this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.SecondReferenceValue, DataProperty.ReferenceValue, false, false);
            if (this.viewModel.HasThirdReferenceColumn) {
                this.addChartDefinition(plotDefinition, ChartType.AbsoluteChart, DataProperty.ThirdAbsoluteDifference, DataProperty.ReferenceValue, false, false);
                this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.ThirdRelativeDifference, DataProperty.ReferenceValue, false, false);
                this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.ThirdReferenceValue, DataProperty.ReferenceValue, false, false);
            }
            if (this.viewModel.HasFourthReferenceColumn) {
                this.addChartDefinition(plotDefinition, ChartType.AbsoluteChart, DataProperty.FourthAbsoluteDifference, DataProperty.ReferenceValue, false, false);
                this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.FourthRelativeDifference, DataProperty.ReferenceValue, false, false);
                this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.FourthReferenceValue, DataProperty.ReferenceValue, false, false);
            }
            if (this.viewModel.HasFifthReferenceColumn) {
                this.addChartDefinition(plotDefinition, ChartType.AbsoluteChart, DataProperty.FifthAbsoluteDifference, DataProperty.ReferenceValue, false, false);
                this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.FifthRelativeDifference, DataProperty.ReferenceValue, false, false);
                this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.FifthReferenceValue, DataProperty.ReferenceValue, false, false);
            }
            if (this.viewModel.HasSixthReferenceColumn) {
                this.addChartDefinition(plotDefinition, ChartType.AbsoluteChart, DataProperty.SixthAbsoluteDifference, DataProperty.ReferenceValue, false, false);
                this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.SixthRelativeDifference, DataProperty.ReferenceValue, false, false);
                this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.SixthReferenceValue, DataProperty.ReferenceValue, false, false);
            }
            if (this.viewModel.HasSeventhReferenceColumn) {
                this.addChartDefinition(plotDefinition, ChartType.AbsoluteChart, DataProperty.SeventhAbsoluteDifference, DataProperty.ReferenceValue, false, false);
                this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.SeventhRelativeDifference, DataProperty.ReferenceValue, false, false);
                this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.SeventhReferenceValue, DataProperty.ReferenceValue, false, false);
            }

            plotDefinitions.push(plotDefinition);

            plotDefinition = this.getBlankPlotDefinition(1);
            this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.ReferenceValue, DataProperty.ReferenceValue, false, false);
            this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.AbsoluteDifference, DataProperty.ReferenceValue, false, false);
            this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.RelativeDifference, DataProperty.ReferenceValue, false, false);
            this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.Value, DataProperty.ReferenceValue, false, false);
            this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.SecondAbsoluteDifference, DataProperty.ReferenceValue, false, false);
            this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.SecondRelativeDifference, DataProperty.ReferenceValue, false, false);
            this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.SecondReferenceValue, DataProperty.ReferenceValue, false, false);
            if (this.viewModel.HasThirdReferenceColumn) {
                this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.ThirdAbsoluteDifference, DataProperty.ReferenceValue, false, false);
                this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.ThirdRelativeDifference, DataProperty.ReferenceValue, false, false);
                this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.ThirdReferenceValue, DataProperty.ReferenceValue, false, false);
            }
            if (this.viewModel.HasFourthReferenceColumn) {
                this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.FourthAbsoluteDifference, DataProperty.ReferenceValue, false, false);
                this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.FourthRelativeDifference, DataProperty.ReferenceValue, false, false);
                this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.FourthReferenceValue, DataProperty.ReferenceValue, false, false);
            }
            if (this.viewModel.HasFifthReferenceColumn) {
                this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.FifthAbsoluteDifference, DataProperty.ReferenceValue, false, false);
                this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.FifthRelativeDifference, DataProperty.ReferenceValue, false, false);
                this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.FifthReferenceValue, DataProperty.ReferenceValue, false, false);
            }
            if (this.viewModel.HasSixthReferenceColumn) {
                this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.SixthAbsoluteDifference, DataProperty.ReferenceValue, false, false);
                this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.SixthRelativeDifference, DataProperty.ReferenceValue, false, false);
                this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.SixthReferenceValue, DataProperty.ReferenceValue, false, false);
            }
            if (this.viewModel.HasSeventhReferenceColumn) {
                this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.SeventhAbsoluteDifference, DataProperty.ReferenceValue, false, false);
                this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.SeventhRelativeDifference, DataProperty.ReferenceValue, false, false);
                this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.SeventhReferenceValue, DataProperty.ReferenceValue, false, false);
            }
            plotDefinitions.push(plotDefinition);

            plotDefinition = this.getBlankPlotDefinition(1);
            this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.ReferenceValue, DataProperty.ReferenceValue, false, false);
            this.addChartDefinition(plotDefinition, ChartType.AbsoluteChart, DataProperty.AbsoluteDifference, DataProperty.ReferenceValue, false, false);
            this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.Value, DataProperty.ReferenceValue, false, false);
            this.addChartDefinition(plotDefinition, ChartType.AbsoluteChart, DataProperty.SecondAbsoluteDifference, DataProperty.ReferenceValue, false, false);
            this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.SecondReferenceValue, DataProperty.ReferenceValue, false, false);
            if (this.viewModel.HasThirdReferenceColumn) {
                this.addChartDefinition(plotDefinition, ChartType.AbsoluteChart, DataProperty.ThirdAbsoluteDifference, DataProperty.ReferenceValue, false, false);
                this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.ThirdReferenceValue, DataProperty.ReferenceValue, false, false);
            }
            if (this.viewModel.HasFourthReferenceColumn) {
                this.addChartDefinition(plotDefinition, ChartType.AbsoluteChart, DataProperty.FourthAbsoluteDifference, DataProperty.ReferenceValue, false, false);
                this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.FourthReferenceValue, DataProperty.ReferenceValue, false, false);
            }
            if (this.viewModel.HasFifthReferenceColumn) {
                this.addChartDefinition(plotDefinition, ChartType.AbsoluteChart, DataProperty.FifthAbsoluteDifference, DataProperty.ReferenceValue, false, false);
                this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.FifthReferenceValue, DataProperty.ReferenceValue, false, false);
            }
            if (this.viewModel.HasSixthReferenceColumn) {
                this.addChartDefinition(plotDefinition, ChartType.AbsoluteChart, DataProperty.SixthAbsoluteDifference, DataProperty.ReferenceValue, false, false);
                this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.SixthReferenceValue, DataProperty.ReferenceValue, false, false);
            }
            if (this.viewModel.HasSeventhReferenceColumn) {
                this.addChartDefinition(plotDefinition, ChartType.AbsoluteChart, DataProperty.SeventhAbsoluteDifference, DataProperty.ReferenceValue, false, false);
                this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.SeventhReferenceValue, DataProperty.ReferenceValue, false, false);
            }
            plotDefinitions.push(plotDefinition);

            plotDefinition = this.getBlankPlotDefinition(1);
            this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.ReferenceValue, DataProperty.ReferenceValue, false, false);
            this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.AbsoluteDifference, DataProperty.ReferenceValue, false, false);
            this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.Value, DataProperty.ReferenceValue, false, false);
            this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.SecondAbsoluteDifference, DataProperty.ReferenceValue, false, false);
            this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.SecondReferenceValue, DataProperty.ReferenceValue, false, false);
            if (this.viewModel.HasThirdReferenceColumn) {
                this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.ThirdAbsoluteDifference, DataProperty.ReferenceValue, false, false);
                this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.ThirdReferenceValue, DataProperty.ReferenceValue, false, false);
            }
            if (this.viewModel.HasFourthReferenceColumn) {
                this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.FourthAbsoluteDifference, DataProperty.ReferenceValue, false, false);
                this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.FourthReferenceValue, DataProperty.ReferenceValue, false, false);
            }
            if (this.viewModel.HasFifthReferenceColumn) {
                this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.FifthAbsoluteDifference, DataProperty.ReferenceValue, false, false);
                this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.FifthReferenceValue, DataProperty.ReferenceValue, false, false);
            }
            if (this.viewModel.HasSixthReferenceColumn) {
                this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.SixthAbsoluteDifference, DataProperty.ReferenceValue, false, false);
                this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.SixthReferenceValue, DataProperty.ReferenceValue, false, false);
            }
            if (this.viewModel.HasSeventhReferenceColumn) {
                this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.SeventhAbsoluteDifference, DataProperty.ReferenceValue, false, false);
                this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.SeventhReferenceValue, DataProperty.ReferenceValue, false, false);
            }
            plotDefinitions.push(plotDefinition);

            plotDefinition = this.getBlankPlotDefinition(1);
            this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.ReferenceValue, DataProperty.ReferenceValue, false, false);
            this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.Value, DataProperty.ReferenceValue, false, false);
            this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.SecondReferenceValue, DataProperty.SecondReferenceValue, false, false);
            if (this.viewModel.HasThirdReferenceColumn) {
                this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.ThirdReferenceValue, DataProperty.ReferenceValue, false, false);
            }
            if (this.viewModel.HasFourthReferenceColumn) {
                this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.FourthReferenceValue, DataProperty.ReferenceValue, false, false);
            }
            if (this.viewModel.HasFifthReferenceColumn) {
                this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.FifthReferenceValue, DataProperty.ReferenceValue, false, false);
            }
            if (this.viewModel.HasSixthReferenceColumn) {
                this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.SixthReferenceValue, DataProperty.ReferenceValue, false, false);
            }
            if (this.viewModel.HasSeventhReferenceColumn) {
                this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.SeventhReferenceValue, DataProperty.ReferenceValue, false, false);
            }
            plotDefinitions.push(plotDefinition);

            plotDefinition = this.getBlankPlotDefinition(1);
            this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.Value, DataProperty.ReferenceValue, false, false);
            plotDefinitions.push(plotDefinition);
        }
        else {
            plotDefinition = this.getBlankPlotDefinition(0.7);
            this.addValueColumns(plotDefinition, ChartType.Table);
            this.addAbsoluteDifferenceColumns(plotDefinition, ChartType.AbsoluteChart);
            this.addRelativeColumns(plotDefinition, ChartType.PlusMinusDot);
            plotDefinitions.push(plotDefinition);

            plotDefinition = this.getBlankPlotDefinition(1);
            this.addValueColumns(plotDefinition, ChartType.Table);
            this.addAbsoluteDifferenceColumns(plotDefinition, ChartType.AbsoluteChart);
            this.addRelativeColumns(plotDefinition, ChartType.Table);
            plotDefinitions.push(plotDefinition);

            plotDefinition = this.getBlankPlotDefinition(1);
            this.addValueColumns(plotDefinition, ChartType.Table);
            this.addAbsoluteDifferenceColumns(plotDefinition, ChartType.AbsoluteChart);
            plotDefinitions.push(plotDefinition);

            plotDefinition = this.getBlankPlotDefinition(1);
            this.addValueColumns(plotDefinition, ChartType.Table);
            this.addAbsoluteDifferenceColumns(plotDefinition, ChartType.Table);
            plotDefinitions.push(plotDefinition);

            plotDefinition = this.getBlankPlotDefinition(1);
            this.addValueColumns(plotDefinition, ChartType.Table);
            plotDefinitions.push(plotDefinition);
        }
        return plotDefinitions;
    }
}