import { Extreme } from "./charting/extreme";
import { VarianceSettings } from "./settings/varianceSettings";
import { DataPoint } from "./charting/dataPoint";
import { Scenario, DataProperty, ShowAsTableOptions, MarkerStyle, EMPTY, ColumnFormat, DisplayUnits } from "./library/constants";
import { ChartGroup } from "./charting/chartGroup";
import { ChartHierarchy } from "./charting/chartHierarchy";
import { DataViewMatrixNode, DataViewHierarchyLevel } from "@zebrabi/matrix-data";

export const SHOW_WATERMARK = false;

// Layout settings
export const GRIDLINE_OFFSET = 5;
export const VERTICAL_MARGIN = 8;
export const OFFSET_PER_CHARACTER = 8;
export const SAFETY_MARGIN = 8;
export const MINIMUM_CHART_WIDTH = 140;
export const MINIMUM_CHART_WIDTH_NO_LABELS = 30;
export const MINIMUM_CATEGORY_HEIGHT = 20;
export const MAXIMUM_CATEGORY_HEIGHT = 50;
export const NUMBER_OF_GROUPS = 6;

// Chart styles
export const ABSOLUTE = "Absolute";
export const RELATIVE = "Relative";
export const ABSOLUTE_RELATIVE = "Absolute / Relative";
export const ACTUAL = "Actual";
export const ACTUAL_ABSOLUTE = "Actual / Absolute";
export const ACTUAL_RELATIVE = "Actual / Relative";
export const INTEGRATED = "Integrated";
export const ACT_ABS_REL = "Actual / Absolute / Relative";
export const TABLE = "Table";
export const CATEGORIES = "Categories";

// Capabilities
export const DESIGN_SETTING = "designSettings";
export const SORT_SETTINGS = "sortSettings";
// export const PRODUCT_TYPE = VISUAL_TYPE_POWER_TABLES;

// Hierarchy categories
export const FULL_CATEGORY = "fullCategory";

// Settings
export const TOTAL = "Total";
export const INVERT = "Invert";
export const RESULT = "Result";
export const SKIP = "Skip";

// Other constants
export const EXPAND_COLLAPSE_WIDTH = 16;
export const ICON_TOOLTIP_Y_OFFSET = 25;

// Migrations
// export const ALL_MIGRATIONS = [
//     new Migration220(),
//     new Migration313(),
//     new Migration320(),
//     new Migration340(),
//     new Migration440(),
//     new Migration450(),
//     new Migration460(),
//     new Migration500(),
// ];

export enum AbsoluteChart {
    Bar = 0,
    Waterfall = 1,
    CalculationWaterfall = 2,
}

export enum ValueChart {
    Bar = 0,
    OverlappedBar = 1,
    Waterfall = 2,
    TwoWaterfalls = 3,
    Pin = 4,
    CalculationWaterfall = 5,
}

export enum SortDirection {
    None,
    Descending,
    Ascending,
}

export interface CategoryOrder {
    category: string;
    value: number;
}

export interface CategoryTreeNode {
    category: string;
    value: number;
    referenceValue: number;
    secondReferenceValue: number;
    thirdReferenceValue: number;
    fourthReferenceValue: number;
    fifthReferenceValue: number;
    sixthReferenceValue: number;
    seventhReferenceValue: number;
    result: number;
    children: CategoryTreeNode[];
    level: number;
}

export interface CategoryOrderRelative {
    category: string;
    value: number;
    referenceValue: number;
    secondReferenceValue: number;
    thirdReferenceValue: number;
    fourthReferenceValue: number;
    fifthReferenceValue: number;
    sixthReferenceValue: number;
    seventhReferenceValue: number;
}

export interface MinMax {
    min: number;
    max: number;
}

export interface BoundingBox {
    x: number;
    y: number;
    width: number;
    height: number;
}

export enum DataPointType {
    Normal = 0,
    Total = 1,
    GrandTotal = 2,
    FlatResult = 3,
    Other = 4,
    OtherTotal = 5,
    Formula = 6,
    FormulaTotal = 7,
}

export interface CumulativeMinMaxAndOffsets {
    value: Extreme;
    referenceValue: Extreme;
    secondReferenceValue: Extreme;
    thirdReferenceValue: Extreme;
    fourthReferenceValue: Extreme;
    fifthReferenceValue: Extreme;
    sixthReferenceValue: Extreme;
    seventhReferenceValue: Extreme;
    absolute: Extreme;
    secondAbsolute: Extreme;
    thirdAbsolute: Extreme;
    fourthAbsolute: Extreme;
    fifthAbsolute: Extreme;
    sixthAbsolute: Extreme;
    seventhAbsolute: Extreme;
}

export interface ScenarioOptions {
    value: ColumnOptions;
    reference: ColumnOptions;
    secondReference: ColumnOptions;
    thirdReference: ColumnOptions;
    fourthReference: ColumnOptions;
    fifthReference: ColumnOptions;
    sixthReference: ColumnOptions;
    seventhReference: ColumnOptions;
    additionalMeasures: ColumnOptions[];
    tooltips: ColumnOptions[];
    comments: ColumnOptions[];
}

export interface MenuItem {
    title: string;
    action: () => void;
}

export interface CategoryMenuItem {
    title: string;
    action?: (d: DataPoint, index: number) => void;
    isChecked?: (d: DataPoint, settings: VarianceSettings) => boolean;
    isBold?: boolean;
    isItalic?: boolean;
}

export interface CategoryFormatMenuItem {
    title: string;
    mouseenterAction: (d: DataPoint, index: number) => void;
}

export interface Sums {
    value: number;
    referenceValue: number;
    secondReferenceValue: number;
    thirdReferenceValue: number;
    fourthReferenceValue: number;
    fifthReferenceValue: number;
    sixthReferenceValue: number;
    seventhReferenceValue: number;
}

export interface PlotDefinition {
    absoluteRatio: number;
    chartPlotDefinitions: ChartPlotDefinition[];
    plottedCharts: number;
}

export interface ChartPlotDefinition {
    chartType: ChartType;
    dataProperty: DataProperty;
    referenceProperty: DataProperty;
    plotOrder: number;
    plotIntegratedChart: boolean;
    allowChangingLables: boolean;
    range: number;
    labelOffset: number;
    width: number;
}

export interface Position {
    x: number;
    y: number;
}

export enum ChartType {
    Table,
    ValueChart,
    AbsoluteChart,
    PlusMinusDot
}

export type TextAlign = "left" | "right" | "center";

export interface IHierarchical {
    getHierarchies(): ChartHierarchy[];
    parent: IHierarchical;
    firstGroupParent: ChartGroup;
    node: DataViewMatrixNode;
    hierarchyLevels: DataViewHierarchyLevel[];
    addHierarchy(hierarchy: ChartHierarchy);
    chartHierarchies: ChartHierarchy[];
}

export interface ICreateGroups {
    createGroup(hasCategories: boolean, node: DataViewMatrixNode, hierarchyLevels: DataViewHierarchyLevel[], isRowGrandTotal: boolean, isColumnGrandTotal: boolean, level: number, totalLabel: string);
}

export interface ColumnOptions {
    index: number;
    scenario: Scenario;
    fieldName: string;
    // position of FC/PL field in its respective "bucket"
    position?: number;
}

export interface Value {
    value: number;
    referenceValue: number;
    secondReferenceValue: number;
    thirdReferenceValue: number;
    fourthReferenceValue: number;
    fifthReferenceValue: number;
    sixthReferenceValue: number;
    seventhReferenceValue: number;
    tooltipsValues: string[];
    commentsValues: string[];
    additionalMeasures: number[];
}

export enum NegativeValuesFormat {
    MinusSign,
    Parenthesis,
}

export interface ChartInfo {
    extreme: Extreme;
    range: number;
    labelOffset: number;
    dataProperty: DataProperty;
    chartType: ChartType;
    group: ChartGroup;
    groupIndex: number;
    plotAreaWidth: number;
    isRemovedFromGroup: boolean;
    shouldSkipGroup: boolean;
}

export interface TextOptions {
    container: d3.Selection<SVGElement, any, any, any>;
    text: string;
    x: number;
    y: number;
    color: string;
    fontSize: number;
    textAnchor: string;
    fontFamily: string;
    textClass: string;
    expectedHeight: number;
    bold: boolean;
}

export interface FormulaCalculation {
    formulas: Formula[];
    expressionMappings: Map<string, Map<string, string>>;
    expressionElements: Map<string, Map<string, Map<string, Map<string, DataPoint>>>>;
    identityDataPoints: Map<string, Map<string, Map<string, Map<string, DataPoint>>>>;
}

export interface Formula {
    identity: string;
    hierarchyIdentity: string;
    expression: string;
    position: string;
    bold: boolean;
    tempBold?: boolean;
    italic: boolean;
    tempItalic?: boolean;
    decimalPlaces: number;
    tempDecimalPlaces?: number;
    units: DisplayUnits;
    tempUnits?: DisplayUnits;
    fontColor: string;
    tempFontColor?: string;
    /** 
     * @deprecated Property deprecated since 5.0 RC.
     * 
     * Instead use formula.units (percent === true => formula.units === DisplayUnits.Percent)
    */
    percent?: boolean;
}

export interface FormulaValidation {
    isValid: boolean;
    validationMsg?: string;
}

export interface PlottableDataPoints {
    inView: DataPoint[];
    inViewWithoutFormulas: DataPoint[];
}

export enum FormulaEditMode {
    None,
    Add,
    Edit,
}

export interface ColumnSettings {
    invert: boolean;
    order: number;
    scaleGroup: number;
    format: ColumnFormat;
    suppressOthers: boolean;
    chartView: ViewSettings | AdditionalMeasureViewSettings;
    tableView: ViewSettings | AdditionalMeasureViewSettings;
}

export interface ColumnTotalEmphasize {
    backgroundColor: string;
    borderColor: string;
}

export interface AdditionalMeasureColumnSettings extends ColumnSettings {
    header: string;
    percentageFormat: string;
    showPercentageInLabel: number;
}
export interface ViewSettings {
    bold: boolean;
    textColor: string;
    backgroundFill: string;
    markerStyle: MarkerStyle;
    border: string;
    showAsTable: ShowAsTableOptions;
    hidden: boolean;
    hiddenFromGroups: string[];
}

export interface AdditionalMeasureViewSettings extends ViewSettings {
    valueChart: ValueChart;
    absoluteChart: AbsoluteChart;
    decimalPlaces: number;
    displayUnits: string;
}

export interface CategoryFormatSettings {
    isBold: boolean;
    isItalic: boolean;
    textColor: string;
    highlightColor: string;
    topBorder: boolean;
    displayUnits: DisplayUnits;
    decimalPlaces: number;
}

/*
export const TUTORIALS: ui.TutorialContent[] = [
    {
        id: 0,
        infoText: "3 things you must know about Zebra BI",
        buttonText: "Get started",
        url: ui.GETTING_STARTED_URL,
        importance: 3,
    },
    {
        id: 1,
        infoText: "Do you know the top 10 features of Zebra BI?",
        buttonText: "Take quiz",
        url: ui.QUIZ_URL,
        importance: 1,
    },
    {
        id: 2,
        infoText: "Set up user-friendly income statements",
        buttonText: "Learn more",
        url: ui.INCOME_STATEMENTS_URL,
        importance: 1,
    },
    {
        id: 3,
        infoText: 'Learn reporting best practices and tips&tricks',
        buttonText: "Check webinars",
        url: ui.WEBINARS_URL,
        importance: 1,
    },
    {
        id: 4,
        infoText: 'Get our "wow" Power BI examples',
        buttonText: "Download PBIX",
        url: ui.PBIX_URL,
        importance: 1,
    },
    {
        id: 5,
        infoText: 'Latest new features of Zebra BI',
        buttonText: "What's new",
        url: ui.LATEST_URL,
        importance: 1,
    },
    {
        id: 6,
        infoText: 'Top 5 DAX tricks for effective dashboards (includes DAX code)',
        buttonText: "Watch video",
        url: ui.DAX_WEBINAR_URL,
        importance: 1,
    },
];
*/