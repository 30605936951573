import { ViewModel } from "../../settings/viewModel";
import { PlotDefinition, ChartType } from "../../definitions";
import { DataProperty, Scenario } from "../../library/constants";
import { HorizontalPlotter } from "./horizontalPlotter";

export class HorizontalFullPlotter extends HorizontalPlotter {
    constructor(viewModel: ViewModel, width: number, height: number, reportArea: d3.Selection<SVGElement, any, any, any>, svg: d3.Selection<SVGElement, any, any, any>,
        locale: string) {
        super(viewModel, width, height, reportArea, svg, locale);
    }

    protected getPlotDefinition(): PlotDefinition {
        let plotDefinition = this.getBlankPlotDefinition(1);
        if (!this.viewModel.HasValueColumn) {
            return plotDefinition;
        }
        if (this.viewModel.hasHierarchy) {
            if (this.viewModel.HasReferenceColumn && this.viewModel.reference.scenario === Scenario.PreviousYear) {
                this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.ReferenceValue, DataProperty.ReferenceValue, false, false);
            }
            this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.Value, DataProperty.ReferenceValue, false, false);
            if (this.viewModel.HasReferenceColumn && this.viewModel.reference.scenario !== Scenario.PreviousYear) {
                this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.ReferenceValue, DataProperty.ReferenceValue, false, false);
            }
            this.addChartDefinition(plotDefinition, ChartType.AbsoluteChart, DataProperty.AbsoluteDifference, DataProperty.ReferenceValue, false, false);
            this.addChartDefinition(plotDefinition, ChartType.PlusMinusDot, DataProperty.RelativeDifference, DataProperty.ReferenceValue, false, false);
        }
        else {
            if (this.viewModel.reference.scenario === Scenario.PreviousYear) {
                this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.ReferenceValue, DataProperty.ReferenceValue, false, false);
            }
            this.addChartDefinition(plotDefinition, ChartType.ValueChart, DataProperty.Value, DataProperty.ReferenceValue, false, false);
            if (this.viewModel.reference.scenario !== Scenario.PreviousYear) {
                this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.ReferenceValue, DataProperty.ReferenceValue, false, false);
            }
            this.addChartDefinition(plotDefinition, ChartType.AbsoluteChart, DataProperty.AbsoluteDifference, DataProperty.ReferenceValue, false, false);
            this.addChartDefinition(plotDefinition, ChartType.PlusMinusDot, DataProperty.RelativeDifference, DataProperty.ReferenceValue, false, false);
        }
        return plotDefinition;
    }
}