import { analyticsHandler } from "@zebrabi/analytics-tracking/AnaliticsHandler";
import React, { useEffect } from "react";
import useStore from "@zebrabi/tables-chooser/useStore";
import { TablesCategoryList } from "../TablesCategoryList";
import { TablesOptionCard } from "../TablesOptionCard";
import { categories, tablesMap } from "../../dimensions";
import { updateSpreadsheet } from "@zebrabi/table-data-editor/x-spreadsheet";
import { concatCommentsToSheetData, setXSpreadSheetData, xSpreadsheetToMatrixData } from "@zebrabi/table-data-editor";
import { xSpreadsheetToMatrixDataForSmallMultiples } from "@zebrabi/table-data-editor";
import { TABLE_SETTINGS_NAME } from "../../../../../../visuals/tables/settings/varianceSettings";
import { persistManagerInstance } from "@zebrabi/office-settings/PersistManager";
import { getOfficeSettings } from "@zebrabi/office-settings";
import { Visual } from "../../../../../../../packages/visuals/tables/visual";
/* global Office */

export default function TablesCategories() {
  const [selectedDimension] = useStore<string>("selectedDimension");
  const [selectedTable, setSelectedTable] = useStore<string>("selectedTable");
  const [isVisible, setIsVisible] = useStore<boolean>("isVisible");
  const [areComments, setAreComments] = useStore<boolean>("areComments");

  useEffect(() => {
    if (!selectedTable) return;
    setVisualSettings();

    const sheetData =
      areComments && !tablesMap[selectedTable].disabledComments
        ? concatCommentsToSheetData(tablesMap[selectedTable].sampleData, tablesMap[selectedTable].commentData)
        : tablesMap[selectedTable].sampleData;
    setXSpreadSheetData(
      tablesMap[selectedTable].isMultiples
        ? xSpreadsheetToMatrixDataForSmallMultiples(sheetData)
        : xSpreadsheetToMatrixData(sheetData)
    );

    setTimeout(() => updateSpreadsheet(sheetData), 200);
    setIsVisible(false);
    analyticsHandler.report(`chooser/${selectedTable}`);
  }, [selectedTable]);

  const setVisualSettings = () => {
    const newSettings = {
      ...getOfficeSettings(TABLE_SETTINGS_NAME),
      showCommentBox: !tablesMap[selectedTable].disabledComments,
    };

    Object.keys(tablesMap[selectedTable].settings).forEach((key) => {
      newSettings[key] = tablesMap[selectedTable].settings[key];
    });

    persistManagerInstance.update({
      objectName: TABLE_SETTINGS_NAME,
      properties: newSettings,
    });
    Visual.getInstance().settings = newSettings;
  };

  return categories[selectedDimension].map((series: any, index: number) => (
    <TablesCategoryList title={series.displayName} key={`TablesCategoryList-${index}`}>
      {series.tables.map((table: any, index: number) => (
        <TablesOptionCard
          table={table}
          onSelect={setSelectedTable}
          isSelected={false}
          key={`ChartOptionCard-${index}`}
        />
      ))}
    </TablesCategoryList>
  ));
}
