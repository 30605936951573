import { LogType, DEBUG, LOG_LEVEL, LINE_BREAK } from "./constants";
import { drawLine } from "./drawing";
import { Visual } from "./../visual";

export function drawBoundingBox(svg: d3.Selection<SVGElement, any, any, any>, x: number, width: number, y: number, height: number, id: number) {
    let colors = ["red", "green", "blue", "yellow", "purple", "pink", "gray", "black"];
    let color = colors[id % colors.length];
    drawLine(svg, x + width, x + width, y, y + height, 1, color, DEBUG);
    drawLine(svg, x, x, y, y + height, 1, color, DEBUG);
    drawLine(svg, x, x + width, y + height, y + height, 1, color, DEBUG);
    drawLine(svg, x, x + width, y, y, 1, color, DEBUG);
}

export function logExceptions() {
    return (target: Object, propertyKey: string, descriptor: TypedPropertyDescriptor<Function>) => {
        return {
            value: function () {
                try {
                    return descriptor.value.apply(this, arguments);
                } catch (e) {
                    console.error(e);
                    throw e;
                }
            },
        };
    };
}

export function measureDuration(functionName: string) {
    return (target: Object, propertyKey: string, descriptor: TypedPropertyDescriptor<Function>) => {
        return {
            value: function () {
                if (shouldLog(LogType.Timings)) {
                    console.time(functionName + " duration");
                }
                let result = descriptor.value.apply(this, arguments);
                if (shouldLog(LogType.Timings)) {
                    console.timeEnd(functionName + " duration");
                }
                return result;
            },
        };
    };
}

export function measureAndLogDuration(functionName: string): MethodDecorator {
    return (target: Object, propertyKey: string, descriptor: TypedPropertyDescriptor<any>): TypedPropertyDescriptor<any> => {
        return {
            value: function () {
                if (shouldLog(LogType.Timings)) {
                    let start = performance.now();
                    let result = descriptor.value.apply(this, arguments);
                    let end = performance.now();
                    let log = `${functionName}: ${Math.round(end - start)} ms`;
                    Visual.performanceLogs.push(log);
                    return result;
                }
                else {
                    return descriptor.value.apply(this, arguments);
                }
            },
        };
    };
}

export function shouldLog(logLevel: LogType): boolean {
    return LOG_LEVEL.indexOf(logLevel) > -1;
}
