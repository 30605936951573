import { Extreme } from "./extreme";
import { DataProperty } from "../library/constants";
import { VarianceSettings } from "../settings/varianceSettings";
import { ValueChart, AbsoluteChart } from "../definitions";
import { ViewModel } from "../settings/viewModel";
import * as helpers from "./../helpers";
import { isAdditionalMeasure } from "../library/helpers";

export class Extremes {
    constructor(
        public value: Extreme, public referenceValue: Extreme, public secondReferenceValue: Extreme, public thirdReferenceValue: Extreme, public fourthReferenceValue: Extreme, public fifthReferenceValue: Extreme, public sixthReferenceValue: Extreme, public seventhReferenceValue: Extreme,
        public absolute: Extreme, public secondAbsolute: Extreme, public thirdAbsolute: Extreme, public fourthAbsolute: Extreme, public fifthAbsolute: Extreme, public sixthAbsolute: Extreme, public seventhAbsolute: Extreme,
        public relative: Extreme, public secondRelative: Extreme, public thirdRelative: Extreme, public fourthRelative: Extreme, public fifthRelative: Extreme, public sixthRelative: Extreme, public seventhRelative: Extreme,
        public integratedAbsolute: Extreme, public secondIntegratedAbsolute: Extreme,
        public integratedRelative: Extreme, public secondIntegratedRelative: Extreme,
        public valueCumulative: Extreme, public referenceValueCumulative: Extreme, public secondReferenceValueCumulative: Extreme, public fourthReferenceValueCumulative: Extreme, public fifthReferenceValueCumulative: Extreme, public sixthReferenceValueCumulative: Extreme, public seventhReferenceValueCumulative: Extreme, public thirdReferenceValueCumulative: Extreme,
        public absoluteCumulative: Extreme, public secondAbsoluteCumulative: Extreme, public thirdAbsoluteCumulative: Extreme, public fourthAbsoluteCumulative: Extreme, public fifthAbsoluteCumulative: Extreme, public sixthAbsoluteCumulative: Extreme, public seventhAbsoluteCumulative: Extreme,
        public additionalMeasures: Extreme[], public relativeValues: number[], public analyticsValues: number[]) {
    }

    get integratedMin(): number {
        return Math.min(this.value.min, this.referenceValue.min);
    }
    get integratedMax(): number {
        return Math.max(this.value.max, this.referenceValue.max);
    }
    get overlappedMin(): number {
        return Math.min(this.value.min, this.referenceValue.min, this.secondReferenceValue.min);
    }
    get overlappedMax(): number {
        return Math.max(this.value.max, this.referenceValue.max, this.secondReferenceValue.max);
    }

    get integratedMinLabelOffset(): number {
        return Math.max(this.value.minLabelOffset, this.referenceValue.minLabelOffset);
    }

    get integratedMaxLabelOffset(): number {
        return Math.max(this.value.maxLabelOffset, this.referenceValue.maxLabelOffset);
    }

    get overlappedMinLabelOffset(): number {
        return Math.max(this.value.minLabelOffset, this.secondReferenceValue.minLabelOffset);
    }

    get overlappedMaxLabelOffset(): number {
        return Math.max(this.value.maxLabelOffset, this.secondReferenceValue.maxLabelOffset);
    }

    get integratedRange(): number {
        return helpers.calculateRange(this.integratedMin, this.integratedMax);
    }

    public getExtreme(dataProperty: DataProperty, settings: VarianceSettings): Extreme {
        switch (dataProperty) {
            case DataProperty.Value:
            case DataProperty.ReferenceValue:
            case DataProperty.SecondReferenceValue:
            case DataProperty.ThirdReferenceValue:
            case DataProperty.FourthReferenceValue:
            case DataProperty.FifthReferenceValue:
            case DataProperty.SixthReferenceValue:
            case DataProperty.SeventhReferenceValue:
                return this.getValueExtreme(dataProperty, settings.valueChart);
            case DataProperty.AbsoluteDifference:
            case DataProperty.SecondAbsoluteDifference:
            case DataProperty.ThirdAbsoluteDifference:
            case DataProperty.FourthAbsoluteDifference:
            case DataProperty.FifthAbsoluteDifference:
            case DataProperty.SixthAbsoluteDifference:
            case DataProperty.SeventhAbsoluteDifference:
                return this.getAbsoluteExtreme(dataProperty, settings.absoluteChart);
            case DataProperty.RelativeDifference:
                return this.relative;
            case DataProperty.SecondRelativeDifference:
                return this.secondRelative;
            case DataProperty.ThirdRelativeDifference:
                return this.thirdRelative;
            case DataProperty.FourthRelativeDifference:
                return this.fourthRelative
            case DataProperty.FifthRelativeDifference:
                return this.fifthRelative;
            case DataProperty.SixthRelativeDifference:
                return this.sixthRelative;
            case DataProperty.SeventhRelativeDifference:
                return this.seventhRelative;
            case DataProperty.AdditionalMeasure1:
            case DataProperty.AdditionalMeasure2:
            case DataProperty.AdditionalMeasure3:
            case DataProperty.AdditionalMeasure4:
            case DataProperty.AdditionalMeasure5:
            case DataProperty.AdditionalMeasure6:
            case DataProperty.AdditionalMeasure7:
            case DataProperty.AdditionalMeasure8:
            case DataProperty.AdditionalMeasure9:
            case DataProperty.AdditionalMeasure10:
                return this.additionalMeasures[dataProperty - DataProperty.AdditionalMeasure1];
            case DataProperty.AdditionalMeasure11:
            case DataProperty.AdditionalMeasure12:
            case DataProperty.AdditionalMeasure13:
            case DataProperty.AdditionalMeasure14:
            case DataProperty.AdditionalMeasure15:
            case DataProperty.AdditionalMeasure16:
            case DataProperty.AdditionalMeasure17:
            case DataProperty.AdditionalMeasure18:
            case DataProperty.AdditionalMeasure19:
            case DataProperty.AdditionalMeasure20:
                return this.additionalMeasures[10 + dataProperty - DataProperty.AdditionalMeasure11];
        }
    }

    public getValueExtreme(dataProperty: DataProperty, valueChart: ValueChart): Extreme {
        switch (dataProperty) {
            case DataProperty.Value:
                if (valueChart === ValueChart.Bar || valueChart === ValueChart.Pin) {
                    return this.value;
                }
                else if (valueChart === ValueChart.OverlappedBar) {
                    return new Extreme(this.overlappedMin, this.overlappedMax, this.overlappedMinLabelOffset, this.overlappedMaxLabelOffset);
                }
                else {
                    return this.valueCumulative;
                }
            case DataProperty.ReferenceValue:
                if (valueChart === ValueChart.Bar || valueChart === ValueChart.Pin) {
                    return this.referenceValue;
                }
                else if (valueChart === ValueChart.OverlappedBar) {
                    return new Extreme(this.overlappedMin, this.overlappedMax, this.overlappedMinLabelOffset, this.overlappedMaxLabelOffset);
                }
                else {
                    return this.referenceValueCumulative;
                }
            case DataProperty.SecondReferenceValue:
                if (valueChart === ValueChart.Bar || valueChart === ValueChart.Pin) {
                    return this.secondReferenceValue;
                }
                else if (valueChart === ValueChart.OverlappedBar) {
                    return new Extreme(this.overlappedMin, this.overlappedMax, this.overlappedMinLabelOffset, this.overlappedMaxLabelOffset);
                }
                else {
                    return this.secondReferenceValueCumulative;
                }
            case DataProperty.ThirdReferenceValue:
                if (valueChart === ValueChart.Bar || valueChart === ValueChart.Pin) {
                    return this.thirdReferenceValue;
                }
                else if (valueChart === ValueChart.OverlappedBar) {
                    return new Extreme(this.integratedMin, this.integratedMax, this.integratedMinLabelOffset, this.integratedMaxLabelOffset);
                }
                else {
                    return this.thirdReferenceValueCumulative;
                }
            case DataProperty.FourthReferenceValue:
                if (valueChart === ValueChart.Bar || valueChart === ValueChart.Pin) {
                    return this.fourthReferenceValue;
                }
                else if (valueChart === ValueChart.OverlappedBar) {
                    return new Extreme(this.integratedMin, this.integratedMax, this.integratedMinLabelOffset, this.integratedMaxLabelOffset);
                }
                else {
                    return this.fourthReferenceValueCumulative;
                }
            case DataProperty.FifthReferenceValue:
                if (valueChart === ValueChart.Bar || valueChart === ValueChart.Pin) {
                    return this.fifthReferenceValue;
                }
                else if (valueChart === ValueChart.OverlappedBar) {
                    return new Extreme(this.integratedMin, this.integratedMax, this.integratedMinLabelOffset, this.integratedMaxLabelOffset);
                }
                else {
                    return this.fifthReferenceValueCumulative;
                }

            case DataProperty.SixthReferenceValue:
                if (valueChart === ValueChart.Bar || valueChart === ValueChart.Pin) {
                    return this.sixthReferenceValue;
                }
                else if (valueChart === ValueChart.OverlappedBar) {
                    return new Extreme(this.integratedMin, this.integratedMax, this.integratedMinLabelOffset, this.integratedMaxLabelOffset);
                }
                else {
                    return this.sixthReferenceValueCumulative;
                }

            case DataProperty.SeventhReferenceValue:
                if (valueChart === ValueChart.Bar || valueChart === ValueChart.Pin) {
                    return this.seventhReferenceValue;
                }
                else if (valueChart === ValueChart.OverlappedBar) {
                    return new Extreme(this.integratedMin, this.integratedMax, this.integratedMinLabelOffset, this.integratedMaxLabelOffset);
                }
                else {
                    return this.seventhReferenceValueCumulative;
                }
        }
    }

    public getAbsoluteExtreme(dataProperty: DataProperty, absoluteChart: AbsoluteChart): Extreme {
        switch (dataProperty) {
            case DataProperty.AbsoluteDifference:
                if (absoluteChart === AbsoluteChart.Bar) {
                    return this.absolute;
                }
                else {
                    return this.absoluteCumulative;
                }
            case DataProperty.SecondAbsoluteDifference:
                if (absoluteChart === AbsoluteChart.Bar) {
                    return this.secondAbsolute;
                }
                else {
                    return this.secondAbsoluteCumulative;
                }
            case DataProperty.ThirdAbsoluteDifference:
                if (absoluteChart === AbsoluteChart.Bar) {
                    return this.thirdAbsolute;
                }
                else {
                    return this.thirdAbsoluteCumulative;
                }
            case DataProperty.FourthAbsoluteDifference:
                if (absoluteChart === AbsoluteChart.Bar) {
                    return this.fourthAbsolute;
                }
                else {
                    return this.fourthAbsoluteCumulative;
                }
            case DataProperty.FifthAbsoluteDifference:
                if (absoluteChart === AbsoluteChart.Bar) {
                    return this.fifthAbsolute;
                }
                else {
                    return this.fifthAbsoluteCumulative;
                }
            case DataProperty.SixthAbsoluteDifference:
                if (absoluteChart === AbsoluteChart.Bar) {
                    return this.sixthAbsolute;
                }
                else {
                    return this.sixthAbsoluteCumulative;
                }
            case DataProperty.SeventhAbsoluteDifference:
                if (absoluteChart === AbsoluteChart.Bar) {
                    return this.seventhAbsolute;
                }
                else {
                    return this.seventhAbsoluteCumulative;
                }
            case DataProperty.AdditionalMeasure1:
            case DataProperty.AdditionalMeasure2:
            case DataProperty.AdditionalMeasure3:
            case DataProperty.AdditionalMeasure4:
            case DataProperty.AdditionalMeasure5:
            case DataProperty.AdditionalMeasure6:
            case DataProperty.AdditionalMeasure7:
            case DataProperty.AdditionalMeasure8:
            case DataProperty.AdditionalMeasure9:
            case DataProperty.AdditionalMeasure10:
                return this.additionalMeasures[dataProperty - DataProperty.AdditionalMeasure1];
            case DataProperty.AdditionalMeasure11:
            case DataProperty.AdditionalMeasure12:
            case DataProperty.AdditionalMeasure13:
            case DataProperty.AdditionalMeasure14:
            case DataProperty.AdditionalMeasure15:
            case DataProperty.AdditionalMeasure16:
            case DataProperty.AdditionalMeasure17:
            case DataProperty.AdditionalMeasure18:
            case DataProperty.AdditionalMeasure19:
            case DataProperty.AdditionalMeasure20:
                return this.additionalMeasures[10 + dataProperty - DataProperty.AdditionalMeasure11];
        }
    }

    public getTableExtreme(dataProperty: DataProperty): Extreme {
        switch (dataProperty) {
            case DataProperty.Value:
                return this.value;
            case DataProperty.ReferenceValue:
                return this.referenceValue;
            case DataProperty.SecondReferenceValue:
                return this.secondReferenceValue;
            case DataProperty.ThirdReferenceValue:
                return this.thirdReferenceValue;
            case DataProperty.FourthReferenceValue:
                return this.fourthReferenceValue;
            case DataProperty.FifthReferenceValue:
                return this.fifthReferenceValue;
            case DataProperty.SixthReferenceValue:
                return this.sixthReferenceValue;
            case DataProperty.SeventhReferenceValue:
                return this.seventhReferenceValue;
            case DataProperty.AbsoluteDifference:
                return this.absolute;
            case DataProperty.SecondAbsoluteDifference:
                return this.secondAbsolute;
            case DataProperty.ThirdAbsoluteDifference:
                return this.thirdAbsolute;
            case DataProperty.FourthAbsoluteDifference:
                return this.fourthAbsolute;
            case DataProperty.FifthAbsoluteDifference:
                return this.fifthAbsolute;
            case DataProperty.SixthAbsoluteDifference:
                return this.sixthAbsolute;
            case DataProperty.SeventhAbsoluteDifference:
                return this.seventhAbsolute;
            case DataProperty.RelativeDifference:
                return this.relative;
            case DataProperty.SecondRelativeDifference:
                return this.secondRelative;
            case DataProperty.ThirdRelativeDifference:
                return this.thirdRelative;
            case DataProperty.FourthRelativeDifference:
                return this.fourthRelative;
            case DataProperty.FifthRelativeDifference:
                return this.fifthRelative;
            case DataProperty.SixthRelativeDifference:
                return this.sixthRelative;
            case DataProperty.SeventhRelativeDifference:
                return this.seventhRelative;
            case DataProperty.AdditionalMeasure1:
            case DataProperty.AdditionalMeasure2:
            case DataProperty.AdditionalMeasure3:
            case DataProperty.AdditionalMeasure4:
            case DataProperty.AdditionalMeasure5:
            case DataProperty.AdditionalMeasure6:
            case DataProperty.AdditionalMeasure7:
            case DataProperty.AdditionalMeasure8:
            case DataProperty.AdditionalMeasure9:
            case DataProperty.AdditionalMeasure10:
                return this.additionalMeasures[dataProperty - DataProperty.AdditionalMeasure1];
            case DataProperty.AdditionalMeasure11:
            case DataProperty.AdditionalMeasure12:
            case DataProperty.AdditionalMeasure13:
            case DataProperty.AdditionalMeasure14:
            case DataProperty.AdditionalMeasure15:
            case DataProperty.AdditionalMeasure16:
            case DataProperty.AdditionalMeasure17:
            case DataProperty.AdditionalMeasure18:
            case DataProperty.AdditionalMeasure19:
            case DataProperty.AdditionalMeasure20:
                return this.additionalMeasures[10 + dataProperty - DataProperty.AdditionalMeasure11];
        }
    }

    public getRange(dataProperty: DataProperty, viewModel: ViewModel): number {
        switch (dataProperty) {
            case DataProperty.Value:
            case DataProperty.ReferenceValue:
            case DataProperty.SecondReferenceValue:
            case DataProperty.ThirdReferenceValue:
            case DataProperty.FourthReferenceValue:
            case DataProperty.FifthReferenceValue:
            case DataProperty.SixthReferenceValue:
            case DataProperty.SeventhReferenceValue:
                return this.getValueRange(dataProperty, viewModel);
            case DataProperty.RelativeDifference:
            case DataProperty.SecondRelativeDifference:
            case DataProperty.ThirdRelativeDifference:
            case DataProperty.FourthRelativeDifference:
            case DataProperty.FifthRelativeDifference:
            case DataProperty.SixthRelativeDifference:
            case DataProperty.SeventhRelativeDifference:
                return this.getRelativeRange(dataProperty, viewModel);
            case DataProperty.AdditionalMeasure1:
            case DataProperty.AdditionalMeasure2:
            case DataProperty.AdditionalMeasure3:
            case DataProperty.AdditionalMeasure4:
            case DataProperty.AdditionalMeasure5:
            case DataProperty.AdditionalMeasure6:
            case DataProperty.AdditionalMeasure7:
            case DataProperty.AdditionalMeasure8:
            case DataProperty.AdditionalMeasure9:
            case DataProperty.AdditionalMeasure10:
            case DataProperty.AdditionalMeasure11:
            case DataProperty.AdditionalMeasure12:
            case DataProperty.AdditionalMeasure13:
            case DataProperty.AdditionalMeasure14:
            case DataProperty.AdditionalMeasure15:
            case DataProperty.AdditionalMeasure16:
            case DataProperty.AdditionalMeasure17:
            case DataProperty.AdditionalMeasure18:
            case DataProperty.AdditionalMeasure19:
            case DataProperty.AdditionalMeasure20:
                return this.getValueRange(dataProperty, viewModel);
            default:
                return this.getExtreme(dataProperty, viewModel.settings).range;
        }
    }

    public getLabelOffset(dataProperty: DataProperty, viewModel: ViewModel): number {
        switch (dataProperty) {
            case DataProperty.Value:
            case DataProperty.ReferenceValue:
            case DataProperty.SecondReferenceValue:
            case DataProperty.ThirdReferenceValue:
            case DataProperty.FourthReferenceValue:
            case DataProperty.FifthReferenceValue:
            case DataProperty.SixthReferenceValue:
            case DataProperty.SeventhReferenceValue:
                return this.getValueLabelOffset(dataProperty, viewModel);
            default:
                return this.getExtreme(dataProperty, viewModel.settings).labelOffsetSum(viewModel.settings);
        }
    }

    public getValueRange(dataProperty: DataProperty, viewModel: ViewModel): number {
        switch (viewModel.settings.valueChart) {
            case ValueChart.Bar:
                if (dataProperty === DataProperty.Value) {
                    return this.value.range;
                }
                else if (dataProperty === DataProperty.ReferenceValue) {
                    return this.referenceValue.range;
                }
                else if (dataProperty === DataProperty.SecondReferenceValue) {
                    return this.secondReferenceValue.range;
                }
                else if (dataProperty === DataProperty.ThirdReferenceValue) {
                    return this.thirdReferenceValue.range;
                }
                else if (dataProperty === DataProperty.FourthReferenceValue) {
                    return this.fourthReferenceValue.range;
                }
                else if (dataProperty === DataProperty.FifthReferenceValue) {
                    return this.fifthReferenceValue.range;
                }
                else if (dataProperty === DataProperty.SixthReferenceValue) {
                    return this.sixthReferenceValue.range;
                }
                else if (dataProperty === DataProperty.SeventhReferenceValue) {
                    return this.seventhReferenceValue.range;
                }
                else if (isAdditionalMeasure(dataProperty)) {
                    if (dataProperty >= DataProperty.AdditionalMeasure1 && dataProperty <= DataProperty.AdditionalMeasure10) {
                        return this.additionalMeasures[dataProperty - DataProperty.AdditionalMeasure1].range;
                    }
                    else {
                        return this.additionalMeasures[10 + dataProperty - DataProperty.AdditionalMeasure11].range;
                    }
                }
            case ValueChart.OverlappedBar:
                let ranges_min = [];
                let ranges_max = [];
                if (viewModel.HasSecondReferenceColumn) {
                    ranges_min.push(this.secondReferenceValue.min);
                    ranges_max.push(this.secondReferenceValue.max);
                }
                if (viewModel.HasThirdReferenceColumn) {
                    ranges_min.push(this.thirdReferenceValue.min);
                    ranges_max.push(this.thirdReferenceValue.max);
                }
                if (viewModel.HasFourthReferenceColumn) {
                    ranges_min.push(this.fourthReferenceValue.min);
                    ranges_max.push(this.fourthReferenceValue.max);
                }
                if (viewModel.HasFifthReferenceColumn) {
                    ranges_min.push(this.fifthReferenceValue.min);
                    ranges_max.push(this.fifthReferenceValue.max);
                }
                if (viewModel.HasSixthReferenceColumn) {
                    ranges_min.push(this.sixthReferenceValue.min);
                    ranges_max.push(this.sixthReferenceValue.max);
                }
                if (viewModel.HasSeventhReferenceColumn) {
                    ranges_min.push(this.seventhReferenceValue.min);
                    ranges_max.push(this.seventhReferenceValue.max);
                }
                return helpers.calculateRange(
                    Math.min(this.integratedMin, ...ranges_min),
                    Math.max(this.integratedMax, ...ranges_max));

            case ValueChart.Waterfall:
            case ValueChart.TwoWaterfalls:
            case ValueChart.CalculationWaterfall:
                if (dataProperty === DataProperty.Value) {
                    return this.valueCumulative.range;
                }
                else if (dataProperty === DataProperty.ReferenceValue) {
                    return this.referenceValueCumulative.range;
                }
                else if (dataProperty === DataProperty.SecondReferenceValue) {
                    return this.secondReferenceValueCumulative.range;
                }
                else if (dataProperty === DataProperty.ThirdReferenceValue) {
                    return this.thirdReferenceValueCumulative.range;
                }
                else if (dataProperty === DataProperty.FourthReferenceValue) {
                    return this.fourthReferenceValueCumulative.range;
                }
                else if (dataProperty === DataProperty.FifthReferenceValue) {
                    return this.fifthReferenceValueCumulative.range;
                }
                else if (dataProperty === DataProperty.SixthReferenceValue) {
                    return this.sixthReferenceValueCumulative.range;
                }
                else if (dataProperty === DataProperty.SeventhReferenceValue) {
                    return this.seventhReferenceValueCumulative.range;
                }
        }
    }

    public getValueLabelOffset(dataProperty: DataProperty, viewModel: ViewModel): number {
        switch (viewModel.settings.valueChart) {
            case ValueChart.Bar:
            case ValueChart.Pin:
                return this.value.labelOffsetSum(viewModel.settings);
            case ValueChart.OverlappedBar:
                let ranges_min = [];
                let ranges_max = [];
                if (viewModel.HasSecondReferenceColumn) {
                    ranges_min.push(this.secondReferenceValue.minLabelOffset);
                    ranges_max.push(this.secondReferenceValue.maxLabelOffset);
                }
                if (viewModel.HasThirdReferenceColumn) {
                    ranges_min.push(this.thirdReferenceValue.minLabelOffset);
                    ranges_max.push(this.thirdReferenceValue.maxLabelOffset);
                }
                if (viewModel.HasFourthReferenceColumn) {
                    ranges_min.push(this.fourthReferenceValue.minLabelOffset);
                    ranges_max.push(this.fourthReferenceValue.maxLabelOffset);
                }
                if (viewModel.HasFifthReferenceColumn) {
                    ranges_min.push(this.fifthReferenceValue.minLabelOffset);
                    ranges_max.push(this.fifthReferenceValue.maxLabelOffset);
                }
                if (viewModel.HasSixthReferenceColumn) {
                    ranges_min.push(this.sixthReferenceValue.minLabelOffset);
                    ranges_max.push(this.sixthReferenceValue.maxLabelOffset);
                }
                if (viewModel.HasSeventhReferenceColumn) {
                    ranges_min.push(this.seventhReferenceValue.minLabelOffset);
                    ranges_max.push(this.seventhReferenceValue.maxLabelOffset);
                }
                return helpers.calculateRange(
                    Math.min(this.value.minLabelOffset, this.referenceValue.minLabelOffset, ...ranges_min),
                    Math.max(this.value.maxLabelOffset, this.referenceValue.maxLabelOffset, ...ranges_max));

            case ValueChart.Waterfall:
            case ValueChart.TwoWaterfalls:
            case ValueChart.CalculationWaterfall:
                if (dataProperty === DataProperty.Value) {
                    return this.valueCumulative.labelOffsetSum(viewModel.settings);
                }
                else if (dataProperty === DataProperty.ReferenceValue) {
                    return this.referenceValueCumulative.labelOffsetSum(viewModel.settings);
                }
                else if (dataProperty === DataProperty.SecondReferenceValue) {
                    return this.secondReferenceValueCumulative.labelOffsetSum(viewModel.settings);
                }
                else if (dataProperty === DataProperty.ThirdReferenceValue) {
                    return this.thirdReferenceValueCumulative.labelOffsetSum(viewModel.settings);
                }
                else if (dataProperty === DataProperty.FourthReferenceValue) {
                    return this.fourthReferenceValueCumulative.labelOffsetSum(viewModel.settings);
                }
                else if (dataProperty === DataProperty.FifthReferenceValue) {
                    return this.fifthReferenceValueCumulative.labelOffsetSum(viewModel.settings);
                }
                else if (dataProperty === DataProperty.SixthReferenceValue) {
                    return this.sixthReferenceValueCumulative.labelOffsetSum(viewModel.settings);
                }
                else if (dataProperty === DataProperty.SeventhReferenceValue) {
                    return this.seventhReferenceValueCumulative.labelOffsetSum(viewModel.settings);
                }
                return this.valueCumulative.labelOffsetSum(viewModel.settings);
        }
    }

    public getRelativeRange(dataProperty: DataProperty, viewModel: ViewModel): number {
        let extreme = this.getExtreme(dataProperty, viewModel.settings);
        return Math.abs(Math.max(viewModel.minOutlierValue, extreme.min)) + Math.min(viewModel.maxOutlierValue, extreme.max);
    }
}