import * as d3 from "d3";
import { Visual } from "../visual";
import { DIV, PX, SEGOE_UI, NORMAL, HIDDEN, WIDTH, HEIGHT, POINTS, INFO_BOX_CONTAINER, CommentBoxPlacement, ZEBRABI_TABLE_CONTAINER, COMMENT_BOX_RESIZE_LINE, ASIDE, CONTEXT_MENU } from "../library/constants";
import { VarianceSettings } from "../settings/varianceSettings";
import * as drawing from "./../library/drawing";
import * as ui from "./../library/ui/showTooltip";
import { Viewport } from "packages/visuals/tables/interfaces";

export class SliderHandler {
    public slider: HTMLElement;
    public sliderButtonRight: HTMLElement;
    private sliderButtonsCreated: boolean;

    constructor(private visual: Visual, private parentElement: HTMLElement) {
        this.slider = document.createElement(DIV);
        this.slider.id = 'slide1';
        this.slider.className = 'slide';
        this.slider.style.left = 0 + PX;
        parentElement.appendChild(this.slider);
        this.sliderButtonsCreated = false;
    }

    public addSliderButtons() {
        let clientRect = this.parentElement.getBoundingClientRect();
        let x0 = clientRect.left + clientRect.width - 25;
        let y0 = clientRect.top + clientRect.height / 2 - 43;

        this.sliderButtonRight = this.createSliderButton(x0, y0 - 16);
        this.parentElement.appendChild(this.sliderButtonRight);

        d3.select(this.sliderButtonRight).append("svg")
            .attr(WIDTH, "100%")
            .attr(HEIGHT, "100%")
            .append("polyline")
            .classed("slider-arrow", true)
            .attr(POINTS, "6,13 21,33 6,53");

        this.sliderButtonsCreated = true;
    }

    public addSliderEventHandlers(settings: VarianceSettings) {
        if (!this.sliderButtonsCreated) {
            return;
        }
        this.sliderButtonRight.onclick = () => {
            settings.switchTableOption();
            this.switchSlider(false, settings.showAdSlide);
            // if (this.visual.selectionManager.hasSelection()) {
            //     this.visual.selectionManager.clear();
            // }
            (<any>window).currentScrollTop = 0;
            (<any>window).currentScrollLeft = 0;

            let tooltipText = settings.showAsTable ? "Table view" : "Chart view";
            let tooltipTextWidth = drawing.measureTextWidth(tooltipText, 12, SEGOE_UI, "500", NORMAL);
            let bb = this.sliderButtonRight.getBoundingClientRect();
            let popupPosition = {
                x: bb.left / 2 - tooltipTextWidth / 2,
                y: bb.top + bb.height / 2 - 12,
            };
            if (!settings.showAdSlide) {
                ui.showTooltip((<any>window).visualHtmlElement, popupPosition, tooltipText, 250, 1000);
            }

            // settings.persistChartType();
            this.visual.constructViewModelAndUpdate(settings);
        };
        this.sliderButtonRight.onmouseenter = () => {
            this.sliderButtonRight.style.opacity = "0.4";
            let tooltipText = "Change view";
            let tooltipTextWidth = drawing.measureTextWidth(tooltipText, 12, SEGOE_UI, "500", NORMAL);
            let bb = this.sliderButtonRight.getBoundingClientRect();
            let popupPosition = {
                x: bb.left - tooltipTextWidth - 5,
                y: bb.top + bb.height / 2 - 12,
            };
            ui.showTooltip((<any>window).visualHtmlElement, popupPosition, tooltipText, 250, 1000);
        };
        this.sliderButtonRight.onmouseleave = () => {
            this.sliderButtonRight.style.opacity = "0";
        };
    }

    public createSliderButton(x: number, y: number): HTMLElement {
        let button = document.createElement(DIV);
        button.className = "slider-button";
        button.style.left = x + PX;
        button.style.top = y + PX;
        button.style.opacity = "0";
        button.addEventListener(CONTEXT_MENU, e => e.preventDefault());
        return button;
    }

    public hideSliderButtons() {
        if (!this.sliderButtonsCreated) {
            return;
        }
        this.sliderButtonRight.classList.add(HIDDEN);
    }

    public showSliderButtons() {
        if (!this.sliderButtonsCreated) {
            return;
        }
        this.sliderButtonRight.classList.remove(HIDDEN);
    }

    public switchSlider(isTransitionLeft: boolean, showAdSlide?: boolean) {
        let width = Visual.viewport.width;
        let directionFactor = isTransitionLeft ? 1 : -1;
        let newSlideId = this.slider.id === "slide1" ? "slide2" : "slide1";
        let currentSlide = document.getElementById(this.slider.id);
        let commentSettings: HTMLElement = document.querySelector(".comment-settings")
        if (commentSettings) {
            commentSettings.parentNode.removeChild(commentSettings);
        }
        currentSlide.style.transition = "200ms";
        let parentEl = currentSlide.parentElement;

        currentSlide.addEventListener('transitionend', () => {
            parentEl.removeChild(currentSlide);
        });

        let newSlide = document.createElement(DIV);
        newSlide.id = newSlideId;
        newSlide.className = "slide";
        newSlide.style.transition = "200ms";

        // TODO: check if this call is needed
        // this.visual.setVisualElements(this.visual.settings);

        const hostWrapperElement = document.createElement('main');
        hostWrapperElement.classList.add('zebrabi-tables-wrapper');
        const hostElement = document.createElement(DIV);
        hostElement.classList.add(ZEBRABI_TABLE_CONTAINER);
        hostWrapperElement.appendChild(hostElement);

        if (!showAdSlide) {
            const infoBoxElement = document.createElement(ASIDE);
            infoBoxElement.classList.add(INFO_BOX_CONTAINER);

            const commentBoxDragLineElement = document.createElement(DIV);
            commentBoxDragLineElement.classList.add(COMMENT_BOX_RESIZE_LINE);

            hostWrapperElement.appendChild(commentBoxDragLineElement);
            hostWrapperElement.appendChild(infoBoxElement);
        }

        newSlide.appendChild(hostWrapperElement);
        newSlide.style.width = width + PX;
        parentEl.insertBefore(newSlide, currentSlide);

        currentSlide.style.left = (width * directionFactor) + PX;
        newSlide.style.left = (-width * directionFactor) + PX;

        this.slider = newSlide;
        this.visual.hostElement = hostElement;
        window.setTimeout(() => {
            newSlide.style.transition = "0ms";
            let commentBoudingBox = newSlide.querySelector(".zebrabi-info-container")?.getBoundingClientRect();
            let tableCommentSettings: HTMLElement = document.querySelector(".comment-settings");
            if (tableCommentSettings && commentBoudingBox) {
                tableCommentSettings.style.left = `${commentBoudingBox.left + 5}px`;
            }
        }, 700);
    }

    public refreshUIelements(width: number, viewport: Viewport, settings: VarianceSettings) {
        if (settings != null && settings.getRealInteractionSettingValue(settings.allowChartChange)) {
            this.sliderButtonRight.style.left = (width - 25) + PX;
            // this.sliderButtonLeft.style.left = 0 + PX;
            this.sliderButtonRight.style.top = (viewport.height / 2 - 43) + PX;
            // this.sliderButtonLeft.style.top = this.sliderButtonRight.style.top;
        }

        this.slider.style.left = `0${PX}`;
        this.slider.style.height = viewport.height + PX;
        this.slider.style.width = viewport.width + PX;

        (<any>window).animateLabels = false;
    }
}