import { VerticalPlotter } from "./verticalPlotter";
import { ViewModel } from "../../settings/viewModel";
import { PlotDefinition, ChartType } from "../../definitions";

export class VerticalAbsoluteRelativePlotter extends VerticalPlotter {
    constructor(viewModel: ViewModel, width: number, height: number, reportArea: d3.Selection<SVGElement, any, any, any>, svg: d3.Selection<SVGElement, any, any, any>,
        locale: string) {
        super(viewModel, width, height, reportArea, svg, locale);
    }

    protected getPlotDefinitions(): PlotDefinition[] {
        let plotDefinitions: PlotDefinition[] = [];

        let plotDefinition = this.getBlankPlotDefinition(0.5);
        this.addAbsoluteDifferenceColumns(plotDefinition, ChartType.AbsoluteChart);
        this.addRelativeColumns(plotDefinition, ChartType.PlusMinusDot);
        plotDefinitions.push(plotDefinition);

        plotDefinition = this.getBlankPlotDefinition(1);
        this.addAbsoluteDifferenceColumns(plotDefinition, ChartType.Table);
        this.addRelativeColumns(plotDefinition, ChartType.Table);
        plotDefinitions.push(plotDefinition);

        return plotDefinitions;
    }
}