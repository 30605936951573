import { Plotter } from "../plotters/plotter";
import { ChartType } from "../../definitions";
import { Extreme } from "../extreme";
import {
    DataProperty, RECT, HIGHLIGHTABLE, LABEL, CHART_AREA,
    LogType, VALUE_SORT, BAR, TOOLTIP, FONT_SIZE, FONT_FAMILY, TEXT_ANCHOR, ITALIC, FONT_STYLE,
    END, START, MARKER, CIRCLE, FONT_WEIGHT, DATA_SCALE_GROUP, DATA_PROPERTY, HEIGHT, WIDTH, X, Y, FILL, R, CX, CY, ShowTotals, FORMULA_ELEMENT, FONT_SIZE_UNIT, CHART_ID, BOLD
} from "../../library/constants";
import {
    getOrdinalScale, getLeftSideGridlinesEnd, getRightSideGrindlinesStart, plotGridlines, getTextAnchor, plotSortableHeader, addBlurIfNeeded,
    drawValueIcons, getMarkerColor, getCommentMarkerAttributes, addCommentMarkers
} from "./chart";
import * as d3 from "d3";
import * as drawing from "./../../library/drawing";
import * as styles from "./../../library/styles";
import * as scenarios from "./../../settings/scenarios";
import * as debug from "./../../library/debug";
import { DataPoint } from "../dataPoint";
import { VarianceSettings } from "../../settings/varianceSettings";
import { ScaleLinear } from "d3";
import { ViewModel } from "../../settings/viewModel";
import { isAdditionalMeasure } from "../../library/helpers";
import { getScenarioFromMarkerStyle } from "../../helpers";

// tslint:disable-next-line: max-func-body-length 
export function plotPinChart(width: number, xPosition: number, groupIndex: number, chartHierarchyIndex: number,
    chartId: number, multiples: boolean, plotter: Plotter, dataProperty: DataProperty, plotOrder: number) {
    let { viewModel, settings, plotArea, svg, groupHeaderHeight, currentRow, currentYPosition: currentHeight, dataPropertyForComments } = plotter;
    let totalWidth = plotter.width;
    let height = plotter.getCurrentChartHeight();
    let chartGroup = viewModel.lastLevelChartGroups[groupIndex];
    let extreme: Extreme = null;
    if (settings.plottingHorizontally()) {
        extreme = chartGroup.extremes.getExtreme(dataProperty, settings);
    }
    else {
        extreme = viewModel.extremes.getExtreme(dataProperty, settings);
    }
    let chartHierarchy = viewModel.getChartHierarchy(groupIndex, chartHierarchyIndex);

    let start = xPosition + extreme.minLabelOffset;
    let end = Math.max(start, xPosition + width - extreme.maxLabelOffset);

    let dataPoints = chartHierarchy.dataPoints();
    let xScale = drawing.getLinearScale(extreme.min, extreme.max, start, end);
    let yScale = getOrdinalScale(dataPoints, currentHeight, currentHeight + height, viewModel, settings.getGapBetweenColumns());
    let plottableDataPoints = plotter.getDataPointsToPlotChartElements(chartHierarchy, yScale, dataProperty, true);
    let gridlinePlottableDataPoints = plotter.getDataPointsToPlotChartElements(chartHierarchy, yScale, dataProperty, false);
    plotter.currentlyShowingDataPoints = plottableDataPoints.inView;
    let chartArea = drawing.createGroupElement(plotArea, `${CHART_AREA}_${groupIndex}_${chartHierarchyIndex}_${chartId} ${CHART_AREA}`);
    let scaleGroup = settings.getScaleGroup(dataProperty);
    chartArea.attr(DATA_SCALE_GROUP, scaleGroup);
    chartArea.attr(DATA_PROPERTY, dataProperty);
    chartArea.attr(CHART_ID, chartId);
    let axisPosition = xScale(0);

    if (debug.shouldLog(LogType.DisplayBounds)) {
        debug.drawBoundingBox(chartArea, xPosition, width, currentHeight, height, chartId);
    }
    let header = settings.getHeader(dataProperty);
    let isHeaderBold = settings.isHeaderBold(dataProperty) || settings.shouldUseBoldGroupHeaders(chartGroup, viewModel.maximumExpandedGroupLevel);
    let leftGridlineEndFunction = getLeftSideGridlinesEnd(xScale, VALUE_SORT, axisPosition);
    let rightGridlineStartFunction = getRightSideGrindlinesStart(xScale, VALUE_SORT, axisPosition, xPosition);
    plotGridlines(yScale, xPosition, width, axisPosition, settings, viewModel, gridlinePlottableDataPoints.inView, chartArea, chartId, leftGridlineEndFunction, rightGridlineStartFunction, groupIndex, chartHierarchyIndex);

    if (settings.shouldPlotVerticalAxis(chartHierarchy)) {
        drawing.plotVerticalAxis(chartArea, chartId, false, axisPosition, currentHeight, height, settings.colorScheme.axisColor, settings.getReferenceScenarioForDataProperty(dataProperty), settings.showTotals, yScale, viewModel.hasHierarchy, chartHierarchy.isGrandTotal, settings.getGapBetweenColumns());
    }
    let headerXPosition = Math.round(axisPosition);
    let anchor = getTextAnchor(extreme.min, extreme.max);
    if (multiples) {
        let yPosition = chartGroup.level * groupHeaderHeight;
        if (chartGroup.isSubtotal) {
            yPosition = viewModel.maximumExpandedGroupLevel * groupHeaderHeight;
        }
        // If we are plotting multiples we only plot one column header for all the charts
        if (settings.shouldShowScenarioSortHeaders(plotter)) {
            let sortableHeader = plotSortableHeader(chartHierarchy, plotter, viewModel, totalWidth, header, currentRow, groupIndex, xPosition, yPosition + groupHeaderHeight + 3, anchor, isHeaderBold, dataProperty, plotOrder, true, width, headerXPosition, false, true, true, true, false, groupIndex, false, chartGroup, false, null, false, chartId);
            sortableHeader.setChartChangeIcons(drawValueIcons, ChartType.ValueChart);
        }
    }
    else {
        let sortableHeader = plotSortableHeader(chartHierarchy, plotter, viewModel, totalWidth, header, currentRow, null, xPosition, 3, anchor, isHeaderBold, dataProperty, plotOrder, true, width, headerXPosition, false, true, true, true, false, groupIndex, false, chartGroup, false, null, false, chartId);
        if (settings.getRealInteractionSettingValue(settings.allowChartChange) && currentRow === 0) {
            sortableHeader.setChartChangeIcons(drawValueIcons, ChartType.ValueChart);
        }
    }

    let bars = drawing.getShapes(chartArea, `.${BAR}${chartId}`, `${BAR}${chartId} ${HIGHLIGHTABLE} ${TOOLTIP}`, RECT, plottableDataPoints.inViewWithoutFormulas);
    bars
        .attr(HEIGHT, 1)
        .attr(WIDTH, d => Math.abs(axisPosition - xScale(d.getValue(dataProperty))))
        .attr(X, d => d.isNegative(dataProperty) ? xScale(d.getValue(dataProperty)) : axisPosition)
        .attr(Y, d => Math.round(yScale(d.getCategory(viewModel)) + yScale.bandwidth() / 2) - 1)
        .attr(FILL, d => d.isHighlighted ? settings.getCategoryHighlightColor(d.category) : settings.colorScheme.neutralColor);
    // plotter.addTooltips(bars);
    let shapePoints = plottableDataPoints.inViewWithoutFormulas.filter(d => !((d.isTotal() || d.isOtherTotal()) && settings.showTotals === ShowTotals.AboveHideValues));
    if (settings.isDrHichertStyle) {
        let rectangleSize = 8;
        let rectangles = drawing.getShapes(chartArea, `.${BAR}${MARKER}${chartId}`, `${BAR}${MARKER}${chartId} ${HIGHLIGHTABLE} ${TOOLTIP}`, RECT, shapePoints);
        rectangles
            .attr(WIDTH, rectangleSize)
            .attr(HEIGHT, rectangleSize)
            .attr(X, d => xScale(Math.min(d.getValue(dataProperty))) - rectangleSize / 2)
            .attr(Y, d => Math.round(yScale(d.getCategory(viewModel)) + yScale.bandwidth() / 2) - rectangleSize / 2);
        styles.applyToLines(rectangles, getMarkerColor(settings), scenarios.getVarianceScenario(dataProperty, viewModel), settings.chartStyle, false, settings.colorScheme, false);
        // addMouseHandlers(rectangles, selectionManager, svg, viewModel);
        addBlurIfNeeded(rectangles, settings);
        // plotter.addTooltips(rectangles);
    }
    else {
        let circles = drawing.getShapes(chartArea, `.${CIRCLE}${MARKER}${chartId}`, `${CIRCLE}${MARKER}${chartId} ${HIGHLIGHTABLE} ${TOOLTIP}`, CIRCLE, shapePoints);
        circles
            .attr(R, 4)
            .attr(CX, d => xScale(Math.min(d.getValue(dataProperty))))
            .attr(CY, d => Math.round(yScale(d.getCategory(viewModel)) + yScale.bandwidth() / 2));
        // addMouseHandlers(circles, selectionManager, svg, viewModel);
        let markerScenario = scenarios.getVarianceScenario(dataProperty, viewModel)
        if (isAdditionalMeasure(dataProperty)) {
            markerScenario = getScenarioFromMarkerStyle(settings.getMarkerStyle(dataProperty));
        }
        styles.applyToLines(circles, getMarkerColor(settings), markerScenario, settings.chartStyle, false, settings.colorScheme, false);
        addBlurIfNeeded(circles, settings);
        // plotter.addTooltips(circles);
    }
    // addMouseHandlers(bars, selectionManager, svg, viewModel);
    addBlurIfNeeded(bars, settings);
    chartArea.selectAll(`.${LABEL}${chartId}`).remove();
    if (settings.showDataLabels) {
        let labels = drawing.getLabels(chartArea, `${LABEL}${chartId}`, plottableDataPoints.inView);
        labels
            .text(d => d.getLabel(dataProperty))
            .style(FONT_SIZE, `${settings.labelFontSize}${FONT_SIZE_UNIT}`)
            .style(FONT_STYLE, ITALIC)
            .style(FONT_FAMILY, settings.labelFontFamily)
            .style(TEXT_ANCHOR, d => d.isNegative(dataProperty) ? END : START)
            .style(FONT_WEIGHT, (d: DataPoint) => isHeaderBold ? BOLD : d.getFontWeight())
            .attr(X, d => getLabelXPosition(d, dataProperty, xScale))
            .attr(Y, d => drawing.centerTextVertically(yScale, d.getCategory(viewModel), settings.labelFontSize))
            .attr(FILL, (d: DataPoint) => d.getLabelColor(settings.labelFontColor, dataProperty))
            .classed(FORMULA_ELEMENT, (d: DataPoint) => d.isCurrentlyEditedFormula())
            .classed(HIGHLIGHTABLE, true)
            .classed(TOOLTIP, true);
        // plotter.addTooltips(labels);
        // addMouseHandlers(labels, selectionManager, svg, viewModel);
        addBlurIfNeeded(labels, settings);
    }

    if (viewModel.HasCommentsColumn) {
        let commentDataPoints: DataPoint[] = [];
        // comment markers with default data property (not being dragged to another data property):
        if (dataProperty === dataPropertyForComments) {
            commentDataPoints.push(...plottableDataPoints.inViewWithoutFormulas.filter(d => d.hasComment() && d.commentMarkerDataProperty === null));
        }
        commentDataPoints.push(...plottableDataPoints.inViewWithoutFormulas.filter(d => d.hasComment()).filter(d => d.commentMarkerDataProperty === dataProperty));
        plotCommentMarkers(chartArea, viewModel, commentDataPoints, xScale, yScale, settings, dataProperty, extreme.getMaxLabelOffset(settings), plotter.categoriesWidth, groupIndex);
        //plotter.addCommentMarkerTooltip(chartArea);
    }
}

function getLabelXPosition(d: DataPoint, dataProperty: DataProperty, xScale: ScaleLinear<number, number>): number {
    if (d.isRowDisplayUnitPercent()) {
        return xScale(0) + 5;
    }
    return d.isNegative(dataProperty) ? xScale(d.getValue(dataProperty)) - 10 : xScale(d.getValue(dataProperty)) + 10;
}

function plotCommentMarkers(container: d3.Selection<SVGElement, any, any, any>, viewModel: ViewModel, commentDataPoints: DataPoint[], xScale: d3.ScaleLinear<number, number>, yScale: d3.ScaleBand<string>,
    settings: VarianceSettings, dataProperty: DataProperty, labelMargin: number, categoriesWidth: number, groupIndex: number) {
    let scaleBandWidth = yScale.bandwidth();
    let markerAttrs = getCommentMarkerAttributes(scaleBandWidth);
    let getMarkerYPosition = (d: DataPoint): number => yScale(d.getCategory(viewModel)) + scaleBandWidth / 2;
    let getMarkerXPosition = (d: DataPoint): number => d.getValue(dataProperty) < 0 ?
        xScale(d.getValue(dataProperty)) - (labelMargin + (viewModel.HasReferenceColumn ? markerAttrs.radius : -markerAttrs.radius)) :
        xScale(d.getValue(dataProperty)) + (labelMargin + (viewModel.HasReferenceColumn ? markerAttrs.radius : -markerAttrs.radius));
    addCommentMarkers(container, commentDataPoints, getMarkerXPosition, getMarkerYPosition, markerAttrs.radius, markerAttrs.fontSize, settings.colorScheme.highlightColor, settings.labelFontFamily, viewModel, categoriesWidth, groupIndex);
}
