import { ViewModel } from "./../settings/viewModel";
import { DataPoint } from "./../charting/dataPoint";
import { ChartHierarchy } from "./../charting/chartHierarchy";
import { VarianceSettings } from "./../settings/varianceSettings";
import { TopNSetting } from "../settings/topNSetting";
import {
    CONTEXT_MENU_DIV, BODY, CLICK, NONE, DISPLAY, OPACITY, LEFT, TOP, PX, BLOCK, DIV, POINTER_EVENTS, FILTER, ELASTIC, INPUT, TYPE, CHECKBOX, ID, CHECKED, LABEL, FOR, BACKGROUND, ALIGN_ITEMS, PADDING_TOP, MARGIN_LEFT, POSITION, PATH, P, SELECT, BUTTON, CHANGE, TopNType, VALUE, MIN, MAX, MARGIN_TOP, SELECTED, DataProperty, NumberOfDataProperties, NumberOfTooltipMeasures, DisplayUnits, OPTION, MOUSEOVER, MOUSEENTER, CONTEXT_MENU, MOUSELEAVE, WIDTH, COLOR, LIGHTGRAY, MARGIN, HEIGHT, GRAY, DARK_GREY_TEXT, YELLOW, BORDER, POLYLINE, POINTS, SETTINGS_ICON, STROKE, STROKE_WIDTH, FILL_OPACITY, PADDING_LEFT, MARGIN_BOTTOM, RIGHT, FONT_FAMILY, FONT_SIZE, FONT_WEIGHT, BOLD, TEXT_ALIGN, CENTER, NORMAL, FONT_STYLE, ITALIC, WHITE, PADDING, RECT, HIERARCHY, LINE, X, FILL, HEADER_TOOLTIP, BLACK, EMPTY, TEXT, G, CHART_AREA, CIRCLE, HIGHLIGHTABLE, D, REPORT_AREA, BACKGROUND_COLOR, BLACK_RGBA, FONT_SIZE_UNIT
} from "./../library/constants";
import { CategoryMenuItem as CategoryMenuItem, INVERT, RESULT, MenuItem, SKIP, FormulaEditMode, CategoryFormatMenuItem, ABSOLUTE } from "./../definitions";
import * as d3 from "d3";
import { Visual } from "./../visual";
import * as uiLib from "./../library/ui/showTooltip";
import * as ui from "./../library/ui/showTooltip";
import Pickr from "@simonwep/pickr";
import * as drawing from "./../library/drawing";
import { getBoundingBoxHtml } from "../library/drawing";
import { getAdditionalMeasurePropertyFromIndex, contains } from "../helpers";

function initContextMenuSelection(isCategoryContextMenu: boolean): d3.Selection<any, any, any, any> {
    let contextMenu = d3.select(document.body).select("." + CONTEXT_MENU_DIV);
    contextMenu.selectAll("*").remove();
    if (!isCategoryContextMenu) {
        // this handler causes issues with context menu if color picker is used so it is not added for category menu
        d3.select(BODY).on(`${CLICK}.${CONTEXT_MENU_DIV}`, () => {
            contextMenu.style(DISPLAY, NONE);
        });
    }
    contextMenu.style(OPACITY, 0);
    (<any>d3).event.preventDefault();
    contextMenu.transition().duration(100).styleTween(OPACITY, () => { return d3.interpolateString(0, 1); })
    return contextMenu;
}

function getContextMenuSelection(): d3.Selection<any, any, any, any> {
    let contextMenu = (d3.select(document.body).select(`.${CONTEXT_MENU_DIV}`));
    let contextMenuRect = (<HTMLElement>(contextMenu.node())).getBoundingClientRect();
    let contextMenuFormatRect = (<HTMLElement>contextMenu.select(".format").node()).getBoundingClientRect();

    let submenuLeft = contextMenuRect.width;
    let submenuTop = contextMenuFormatRect.y - contextMenuRect.y;

    let subMenuDiv = contextMenu.append(DIV);
    subMenuDiv
        .classed("context-submenu", true)
        .style(DISPLAY, BLOCK)
        .style(LEFT, `${(submenuLeft - 2)}px`)
        .style(TOP, `${(submenuTop - 5)}px`)
        .style(PADDING, "4px 2px")
    subMenuDiv.style(OPACITY, 0);
    (<any>d3).event.preventDefault();
    subMenuDiv.transition().duration(100).styleTween(OPACITY, () => { return d3.interpolateString(0, 1); })
    return subMenuDiv;
}

export function populateAndDisplayCategoryContextMenu(settings: VarianceSettings, viewModel: ViewModel, chartHierarchy: ChartHierarchy,
    bottomEdge: number, allowSettingCalculations: boolean, allowExpandCollapse: boolean): (d: DataPoint, index: number) => void {
    return (d: DataPoint, i: number) => {
        const mouseEvent: MouseEvent = <MouseEvent>d3.event;
        if (!allowSettingCalculations && !allowExpandCollapse) {
            return;
        }

        let context = initContextMenuSelection(true);
        context
            .style(LEFT, Math.max(((<any>d3).event.pageX) - 5, 0) + PX)
            .style(TOP, ((<any>d3).event.pageY) - 26 + PX)
            .style(DISPLAY, BLOCK);

        addCategoryName(context, d.category, settings)
        addMenuElementsLineDivider(context, true);
        if (allowSettingCalculations) {
            addCategoriesContextMenu(d, viewModel, context);
            addRowFormatMenu(d, viewModel, bottomEdge, context);
            addFormulaContextMenu(d, viewModel, context)
        }
        if (allowExpandCollapse) {
            addExpandCollapseMenu(d, viewModel, context, chartHierarchy);
        }
        if (!d.isGrandTotal()) {
            addTopNMenu(settings, d, viewModel, context, chartHierarchy);
        }
        let bb = (<any>context.nodes()[0]).getBoundingClientRect();
        if (bb.bottom > bottomEdge) {
            context
                .style(TOP, `${bottomEdge - bb.height}${PX}`);
        }
        if (!settings.proVersionActive()) {
            let left = Math.max(((<any>d3).event.pageX) - 5, 0);
            let top = (<any>d3).event.pageY - 30;
            ui.showTooltip(Visual.visualDiv, { x: left, y: top }, "Right click only available in Pro version", 0, 2000);
            context.style(FILTER, "url(#blur)").style(OPACITY, "0.4");
            context.selectAll("*").style(POINTER_EVENTS, "none");
        }
        mouseEvent.preventDefault();
    };
}

export function populateAndDisplayCategoryContextSubmenu(viewModel: ViewModel, d: DataPoint, bottomEdge: number, contextParent: d3.Selection<any, any, any, any>) {
    const mouseEvent: MouseEvent = <MouseEvent>d3.event;
    let context = getContextMenuSelection();
    addCategoriesFormatSubmenu(d, viewModel, contextParent, bottomEdge, context)
    mouseEvent.preventDefault();
}

function hideCategoryContextMenuFormat() {
    d3.select(document.body).select(".context-submenu").remove();
}

export function expandCollapseGroupMenu(viewModel: ViewModel, headerBottomEdge: number, visualBottomEdge: number, level: number): () => void {
    return () => {
        const mouseEvent: MouseEvent = <MouseEvent>d3.event;
        let context = initContextMenuSelection(false);
        context.style(LEFT, Math.max(((<any>d3).event.pageX) - 5, 0) + PX)
            .style(TOP, headerBottomEdge + 3 + PX)
            .style(DISPLAY, BLOCK);
        addExpandCollapseGroupMenu(level, context, viewModel);
        let bb = (<any>context.nodes()[0]).getBoundingClientRect();
        if (bb.bottom > visualBottomEdge) {
            context
                .style(TOP, `${visualBottomEdge - bb.height}${PX}`);
        }
        if (!viewModel.settings.proVersionActive()) {
            let left = Math.max(((<any>d3).event.pageX) - 5, 0);
            let top = (<any>d3).event.pageY - 30;
            ui.showTooltip(Visual.visualDiv, { x: left, y: top }, "Right click only available in Pro version", 0, 2000);
            context.style(FILTER, "url(#blur)").style(OPACITY, "0.4");
            context.selectAll("*").style(POINTER_EVENTS, "none");
        }
        mouseEvent.preventDefault();
    };
}

function addCategoriesContextMenu(d: DataPoint, viewModel: ViewModel, context: d3.Selection<any, any, any, any>) {
    let menu = getCategoriesMenuItems(d, viewModel);
    let menuElements = context
        .selectAll(".invert-result")
        .data(menu);
    let div =
        menuElements.enter().append(DIV)
            .on(CLICK, (menuItem, index) => {
                (<any>d3).event.preventDefault();
                menuItem.action(d, index);
            })
            .on(MOUSEENTER, () => {
                hideCategoryContextMenuFormat();
            })
            .classed(ELASTIC, true);
    menuElements = menuElements.merge(div);
    menuElements
        .append(INPUT)
        .attr(TYPE, CHECKBOX)
        .attr(ID, (menuItem, index) => "a" + index)
        .property(CHECKED, menuItem => menuItem.isChecked(d, viewModel.settings));
    menuElements
        .append(LABEL)
        .attr(FOR, (menuItem, index) => "a" + index)
        .text(menuItem => menuItem.title);

    addMenuElementsLineDivider(context, false);

}

// ADD CONTEXT (SUB)MENUS 
function addFormulaContextMenu(d: DataPoint, viewModel: ViewModel, context: d3.Selection<any, any, any, any>) {
    let menu = getFormulaMenuItems(d, viewModel);
    let menuElements = context
        .selectAll(".formula")
        .data(menu)
        .enter()
        .append(DIV)
        .classed(ELASTIC, true)
        .classed("formula", true);
    menuElements
        .append(LABEL)
        .text(menuItem => menuItem.title)
        .on(CLICK, menuItem => menuItem.action())
        .on(MOUSEENTER, () => {
            hideCategoryContextMenuFormat();
        })
    addMenuElementsLineDivider(context, false);
}

function addRowFormatMenu(d: DataPoint, viewModel: ViewModel, visualBottomEdge: number, context: d3.Selection<any, any, any, any>) {
    let menuItem = getRowFormatMenuItem(d, viewModel, visualBottomEdge, context);
    let menuElements = context
        .selectAll(".custom-row-format")
        .data([menuItem]);
    let div =
        menuElements.enter().append(DIV)
            .classed("format", true)
            .classed(ELASTIC, true);
    menuElements = menuElements.merge(div);
    menuElements
        .append(LABEL)
        .style(TOP, "-1px")
        .attr(FOR, (menuItem, index) => "a" + index)
        .text(menuItem => menuItem.title);
    menuElements.style(DISPLAY, "block");
    menuElements.style(ALIGN_ITEMS, "center");
    menuElements.on(CLICK, (menuItem, index) => {
        menuItem.mouseenterAction(d, index)
    });
    menuElements.on(MOUSEENTER, (menuItem, index) => {
        menuItem.mouseenterAction(d, index)
    });

    let arrow = menuElements.append("svg");
    arrow.attr(WIDTH, 12)
        .attr(HEIGHT, 18)
        .style(DISPLAY, "inline")
        .style(POSITION, ABSOLUTE)
        .style(RIGHT, "5px")
        .style(TOP, "2px")
        .append(POLYLINE)
        .attr(POINTS, p => {
            let y = 7;
            let offset = 0;
            return `${offset + 4},${y - 3} ${offset + 9},${y + 2} ${offset + 4},${y + 7}`;
        })
        .attr(STROKE, GRAY)
        .attr(STROKE_WIDTH, 1)
        .attr(FILL_OPACITY, 0)
        .attr(OPACITY, 1.0)

    addMenuElementsLineDivider(context, false);
}

function showTooltip(el: HTMLElement, text: string) {
    let bb = getBoundingBoxHtml(el);

    uiLib.showTooltip(Visual.visualDiv, {
        x: bb.left,
        y: bb.top + 25,
    }, text, 250, 1000);
}

function hideTooltip() {
    d3.selectAll("." + HEADER_TOOLTIP).remove();
}

// tslint:disable-next-line: max-func-body-length 
function addCategoriesFormatSubmenu(d: DataPoint, viewModel: ViewModel, contextParent: d3.Selection<any, any, any, any>,
    bottomEdge: number, context: d3.Selection<any, any, any, any>) {
    let menuItemsFormatLabelStylesDiv = context
        .append(DIV)
        .classed("labelStylesElements", true)

    let boldSelectIcon = menuItemsFormatLabelStylesDiv.append(DIV)
        .text("B")
        .style(FONT_WEIGHT, BOLD)
        .classed("rowFormatElement", true)
        .classed("icon-selected", () => {
            return d.getRowBold();
        }).on(CLICK, () => {
            d.setRowBold();
        }).on(MOUSEOVER, function () {
            showTooltip(this, "Bold");
        }).on(MOUSELEAVE, () => {
            hideTooltip();
        })

    let italicSelectIcon = menuItemsFormatLabelStylesDiv.append(DIV)
        .text("I")
        .style(FONT_STYLE, ITALIC)
        .classed("icon-selected", d.getRowItalic())
        .classed("rowFormatElement", true)
        .on(CLICK, () => {
            d.setRowItalic()
        }).on(MOUSEOVER, function () {
            showTooltip(this, "Italic");
        }).on(MOUSELEAVE, () => {
            hideTooltip();
        })

    let fontColorIcon = menuItemsFormatLabelStylesDiv.append(DIV)
        .text("A")
        .classed("rowFormatElement", true)
        .attr(ID, "customTextColor")
        .on(CLICK, () => {
            getCustomTextColorPicker(viewModel.settings, d.category, "customTextColor", d).show();
        })

    fontColorIcon.append(DIV)
        .style(WIDTH, "16px")
        .style(HEIGHT, "4px")
        .style(TOP, "18px")
        .style(LEFT, "3px")
        .style(BORDER, "1px solid gray")
        .style(BACKGROUND, d.getRowFontColor())
        .style(POSITION, "absolute")
        .style("box-sizing", "border-box");
    fontColorIcon.on(MOUSEOVER, function () {
        showTooltip(d3.select(this).node(), "Font color");
    }).on(MOUSELEAVE, () => {
        hideTooltip();
    })

    // vertical line 1
    menuItemsFormatLabelStylesDiv.append(DIV)
        .classed("separationLine", true)

    // highlight color select
    let chartIconDiv = menuItemsFormatLabelStylesDiv
        .append(DIV)
        .classed("rowFormatElement", true)
        .attr(ID, "customHighlightColor");

    let chartIcon = chartIconDiv
        .append("svg")
        .style(POSITION, "relative")
        .style(FILL, "gray")
        .style(WIDTH, "16px")
        .style(HEIGHT, "18px")
        .style(PADDING, "2px 0px")

    chartIcon.append(RECT).attr(ID, "rect1").attr(WIDTH, "11px").attr(HEIGHT, "6px").attr("y", "2px");
    chartIcon
        .append(RECT).attr(ID, "rect2").attr(WIDTH, "8px").attr(HEIGHT, "6px").attr("y", "10px")
        .attr(FILL, d.getRowHighlightColor());
    chartIcon.append(RECT).attr(ID, "axis").attr(WIDTH, "1px").attr(HEIGHT, "18px");

    chartIconDiv.on(MOUSEOVER, function () {
        showTooltip(d3.select(this).node(), "Highlight color");
    }).on(MOUSELEAVE, () => {
        hideTooltip();
    }).on(CLICK, () => {
        getCustomHighlightColorPicker(viewModel.settings, "customHighlightColor", d).show()
    })

    // vertical line 2
    menuItemsFormatLabelStylesDiv.append(DIV)
        .classed("separationLine", true)

    // top border
    let topBorderIcon = menuItemsFormatLabelStylesDiv.append(DIV)
        .style(TEXT_ALIGN, "center")
        .style(WIDTH, "16px")
        .on(MOUSEOVER, function () {
            showTooltip(d3.select(this).node(), "Top border");
        }).on(MOUSELEAVE, () => {
            hideTooltip();
        })
        .on(CLICK, () => {
            d.setRowTopBorder()
        })
        .classed("rowFormatElement", true)
        .classed("icon-selected", d.getRowTopBorder())
        .classed("gridline", true)

    context.append(DIV)
        .style(FONT_SIZE, "12px")
        .style(MARGIN_TOP, "6px")
        .style(MARGIN_LEFT, "2px")
        .style(PADDING_LEFT, "6.5px")
        .append(LABEL)
        .text("Number format");

    let menuElementCustomRowFormat = context
        .selectAll(".custom-row-format")
        .data(["menuItemCustomRowFormat"]);

    let divCustomRowFormat =
        menuElementCustomRowFormat.enter().append(DIV)
            .classed("custom-row-format", true);
    menuElementCustomRowFormat = menuElementCustomRowFormat.merge(divCustomRowFormat);
    menuElementCustomRowFormat.style(ALIGN_ITEMS, "center");

    let numberFormatSelect = menuElementCustomRowFormat.append(SELECT);
    let pickerID = "select-displayunits";
    numberFormatSelect.attr(ID, pickerID)
        .classed("units", true)
        .style(POSITION, "relative")
    let options = [
        { "-": DisplayUnits.Default },
        { "Auto": DisplayUnits.Auto },
        { "None": DisplayUnits.None },
        { "Thousands": DisplayUnits.Thousands },
        { "Millions": DisplayUnits.Millions },
        { "Billions": DisplayUnits.Billions },
        { "Percent (%)": DisplayUnits.Percent }];

    options.forEach((element) => {
        let key = Object.keys(element)[0];
        let value = Object.values(element)[0];
        numberFormatSelect.append("option")
            .attr(VALUE, value)
            .text(key)
            .attr(SELECTED, value === d.getRowNumberFormat() ? "selected" : null)
    });

    numberFormatSelect.on(CHANGE, () => {
        (<any>d3).event.preventDefault();
        let selectedRowFormatOption = ((<HTMLSelectElement>numberFormatSelect.node()).value);
        d.setRowNumberFormat(<DisplayUnits>selectedRowFormatOption);
    })

    menuElementCustomRowFormat.append("span").text(",").classed("comma", true);
    let decimalPlacesSelect = menuElementCustomRowFormat.append(SELECT);
    decimalPlacesSelect.attr(ID, pickerID)
        .classed("decimal-places", true)
        .style(POSITION, "relative")
    let optionsDecimalPlaces = [
        { "-": "-1" },
        { "0": "0" },
        { "1": "1" },
        { "2": "2" },
        { "3": "3" },
        { "4": "4" }];

    optionsDecimalPlaces.forEach((element) => {
        let key = Object.keys(element)[0];
        let value = Object.values(element)[0];
        decimalPlacesSelect.append("option")
            .attr(VALUE, value)
            .text(key)
            .attr(SELECTED, value === d.getRowDecimalPlaces().toString() ? "selected" : null)
    });

    decimalPlacesSelect.on(CHANGE, () => {
        let selectedDecimalPlacesOption = Number((<HTMLSelectElement>decimalPlacesSelect.node()).value);
        d.setRowDecimalPlaces(selectedDecimalPlacesOption);
    }).on(MOUSEOVER, function () {
        showTooltip(d3.select(this).node(), "Decimal places");
    });

    let contextMenuRect = (<DOMRect>(<any>contextParent.nodes()[0]).getBoundingClientRect());
    let contextSubmenuRect = (<DOMRect>(<any>context.nodes()[0]).getBoundingClientRect());

    if (contextSubmenuRect.bottom > bottomEdge) {
        context.style(TOP, `${contextMenuRect.height - contextSubmenuRect.height - 5}${PX}`);
    }
}


function getRowFormatMenuItem(d: DataPoint, viewModel: ViewModel, bottomEdge: number, contextParent: d3.Selection<any, any, any, any>): CategoryFormatMenuItem {
    return ({
        title: "Format",
        mouseenterAction: (d: DataPoint, index: number) => {
            populateAndDisplayCategoryContextSubmenu(viewModel, d, bottomEdge, contextParent);
        }
    });
}

function getCategoriesMenuItems(d: DataPoint, viewModel: ViewModel): CategoryMenuItem[] {
    let menuItems: CategoryMenuItem[] = [
        {
            title: INVERT,
            action: (d: DataPoint, index: number) => {
                viewModel.settings.persistInvertedCategory(d);
            },
            isChecked: (d: DataPoint, settings: VarianceSettings) => {
                return d.isInverted;
            },
        },
    ];
    if (d.isTotal() || d.isOtherTotal()) {
        menuItems.push(
            {
                title: `${INVERT} all children`,
                action: (d: DataPoint, index: number) => {
                    viewModel.settings.persistInvertedTotalCategory(d);
                },
                isChecked: (d: DataPoint, settings: VarianceSettings) => {
                    return d.isInvertedTotal;
                },
            });
    }
    if (viewModel.allowFlatResults()) {
        menuItems.push(
            {
                title: RESULT,
                action: (d: DataPoint, index: number) => {
                    viewModel.settings.persistFlatResults(d);
                },
                isChecked: (d: DataPoint, settings: VarianceSettings) => {
                    return d.isFlatResult();
                },
            }
        );
    }
    menuItems.push(
        {
            title: SKIP,
            action: (d: DataPoint, index: number) => {
                viewModel.settings.persistSkipped(d.category, d.isSkipped);
            },
            isChecked: (d: DataPoint, settings: VarianceSettings) => {
                return d.isSkipped;
            },
        }
    );
    if (d.isTotal() || d.isOtherTotal()) {
        menuItems.push(
            {
                title: RESULT,
                action: (d: DataPoint, index: number) => {
                    viewModel.settings.persistResults(d);
                },
                isChecked: (d: DataPoint, settings: VarianceSettings) => {
                    return d.isResult;
                },
            });
    }
    return menuItems;
}

function getFormulaMenuItems(d: DataPoint, viewModel: ViewModel): MenuItem[] {
    if (Visual.formulaEditMode !== FormulaEditMode.None) {
        return [{
            title: "Add to formula",
            action: () => {
                let inputEl = <HTMLInputElement>d3.select("input.awesomplete").node();
                inputEl.value += " [" + d.category + "] ";
                inputEl.focus();
            },
        }];
    }
    else {
        let menuItems = [];
        if (d.isFormula()) {
            menuItems.push({
                title: "Edit formula",
                action: () => {
                    Visual.formulaEditMode = FormulaEditMode.Edit;
                    Visual.formulaEditPosition = d;
                    Visual.isInFocus = true;
                    Visual.shouldChangeSize = true;
                    Visual.getInstance().constructViewModelAndUpdate(Visual.getInstance().settings);
                },
            });
        }
        menuItems.push({
            title: "Add formula",
            action: () => {
                Visual.formulaEditPosition = d;
                Visual.formulaEditMode = FormulaEditMode.Add;
                Visual.isInFocus = true;
                Visual.shouldChangeSize = true;
                Visual.getInstance().constructViewModelAndUpdate(Visual.getInstance().settings);
            },
        });
        return menuItems;
    }
}

function addTopNMenu(settings: VarianceSettings, d: DataPoint, viewModel: ViewModel, context: d3.Selection<any, any, any, any>, hierarchy: ChartHierarchy) {
    let menu = getTopNMenuItems(settings, d, viewModel, hierarchy);
    let menuElements = context
        .selectAll(".top-n")
        .data(menu)
        .enter()
        .append(DIV)
        .classed(ELASTIC, true)
        .classed("top-n", true);
    menuElements
        .append(LABEL)
        .text(menuItem => menuItem.title)
        .on(CLICK, menuItem => menuItem.action());
}

function getTopNMenuItems(settings: VarianceSettings, d: DataPoint, viewModel: ViewModel, hierarchy: ChartHierarchy): MenuItem[] {
    return [{
        title: "Top/Bottom N",
        action: (): void => {
            let contextMenu = d3.select(document.body).select("." + CONTEXT_MENU_DIV);
            contextMenu.selectAll("*").remove();
            contextMenu.style(DISPLAY, NONE);
            drawTopNForm(settings, d, viewModel, hierarchy);
        }
    }]
}

export function drawTopNForm(settings: VarianceSettings, d: DataPoint, viewModel: ViewModel, hierarchy: ChartHierarchy) {
    Visual.topNForm.style(DISPLAY, BLOCK);
    drawing.applyBlur([Visual.headers, Visual.grandTotalRow, Visual.categoriesSelection, Visual.grandTotalCategoriesSelection], [Visual.visualDiv, Visual.title, Visual.headersDiv]);

    let headerDiv = Visual.topNForm.append(DIV).classed("form-header", true);
    headerDiv.append(DIV).classed("topn-title", true).text("Top/Bottom N");
    let closeCross = headerDiv.append("svg").classed("close-cross", true);
    closeCross.append(PATH).attr("d", "M0.0,0.0,12.0,12.0");
    closeCross.append(PATH).attr("d", "M0.0,12.0,12.0,0.0");
    Visual.topNForm.append(DIV).classed("line", true);

    let topNSetting = settings.getTopNSetting(d ? d.level : 0);

    let innerForm = Visual.topNForm.append(DIV).classed("inner-form", true);

    if (hierarchy.hierarchyLevels.length > 1) {
        innerForm.append(P).text("Apply to");
        let topNFieldSelect = innerForm.append(SELECT);
        let fieldOptions = hierarchy.hierarchyLevels.map(level => level.sources[0].displayName);
        for (let i = 0; i < fieldOptions.length; i++) {
            topNFieldSelect.append("option")
                .attr(VALUE, i)
                .attr(SELECTED, (d ? d.level : 0) === i ? "selected" : null)
                .text(fieldOptions[i]);
        }

        topNFieldSelect.on(CHANGE, () => {
            let lvl = parseInt((<HTMLSelectElement>topNFieldSelect.node()).value);
            let setting = settings.getTopNSetting(lvl);
            drawTopNDetails(settings, setting, viewModel, hierarchy, innerForm, closeCross, lvl);
        });
    }

    drawTopNDetails(settings, topNSetting, viewModel, hierarchy, innerForm, closeCross, d ? d.level : 0);
}

function getPlottedDataPropertiesCustomOrder(settings: VarianceSettings): DataProperty[] {
    let dataProperties = [];

    let measureDataProperties = [DataProperty.Value, DataProperty.ReferenceValue, DataProperty.AbsoluteDifference, DataProperty.RelativeDifference,
    DataProperty.SecondReferenceValue, DataProperty.SecondAbsoluteDifference, DataProperty.SecondRelativeDifference,
    DataProperty.ThirdReferenceValue, DataProperty.ThirdAbsoluteDifference, DataProperty.ThirdRelativeDifference,
    DataProperty.FourthReferenceValue, DataProperty.FourthAbsoluteDifference, DataProperty.FourthRelativeDifference,
    DataProperty.FifthReferenceValue, DataProperty.FifthAbsoluteDifference, DataProperty.FifthRelativeDifference,
    DataProperty.SixthReferenceValue, DataProperty.SixthAbsoluteDifference, DataProperty.SixthRelativeDifference,
    DataProperty.SeventhReferenceValue, DataProperty.SeventhAbsoluteDifference, DataProperty.SeventhRelativeDifference];
    measureDataProperties.forEach(dataProperty => {
        if (contains(settings.plottedDataProperties, dataProperty.toString())) {
            dataProperties.push(dataProperty);
        }
    });
    for (let i = 0; i < 20; i++) {
        let dp = getAdditionalMeasurePropertyFromIndex(i);
        if (contains(settings.plottedDataProperties, dp.toString())) {
            dataProperties.push(dp);
        }
    }
    return dataProperties;
}

function drawTopNDetails(settings: VarianceSettings, topNSetting: TopNSetting, viewModel: ViewModel, hierarchy: ChartHierarchy, innerForm: d3.Selection<HTMLElement, any, any, any>, closeCross: d3.Selection<SVGElement, any, any, any>, lvl: number) {
    innerForm.selectAll(".removable").remove();
    innerForm.append(P).text("Top/bottom N").classed("removable", true);
    let topNTypeSelect = innerForm.append(SELECT).classed("removable", true);
    let options = ["Off", "Top N", "Bottom N", "Top + bottom N"];
    for (let i = 0; i < options.length; i++) {
        topNTypeSelect.append("option")
            .attr(VALUE, i)
            .attr(SELECTED, topNSetting && topNSetting.type === i ? "selected" : null)
            .text(options[i]);
    }

    let lab1 = innerForm.append(P).text("Filter by").classed("removable", true);
    let topNDataPropertySelect = innerForm.append(SELECT).classed("removable", true);
    let selectableDataProperties = getPlottedDataPropertiesCustomOrder(settings);
    for (let i = 0; i < selectableDataProperties.length; i++) {
        topNDataPropertySelect.append("option")
            .attr(VALUE, selectableDataProperties[i])
            .attr(SELECTED, topNSetting && topNSetting.dataProperty === selectableDataProperties[i] ? "selected" : null)
            .text(viewModel.settings.getHeader(selectableDataProperties[i]));
    }
    let labItems = innerForm.append(P).text("Items").classed("removable", true);
    let itemsContainerDiv = innerForm.append(DIV).style(DISPLAY, "flex");
    let itemsContainerEditMode = itemsContainerDiv.append(DIV).classed("mode-items", true);
    let itemsContainerFocusMode = itemsContainerDiv.append(DIV).classed("mode-items", true);
    let labItemsEditMode = itemsContainerEditMode.append(P).text("Edit mode").classed("removable", true)
    let labItemsFocusMode = itemsContainerFocusMode.append(P).text("Focus mode").classed("removable", true)
    let topNNumberInput = itemsContainerEditMode.append(INPUT).classed("removable", true)
        .attr(TYPE, "number")
        .attr(VALUE, topNSetting ? topNSetting.number : 5)
        .attr(MIN, 1)
        .attr(MAX, 99)
        .on(INPUT, () => {
            let val = parseInt((<HTMLInputElement>topNNumberInput.node()).value);
            if (val < 1 || val > 99 || Number.isNaN(val)) {
                confirmButton.attr('disabled', true)
            }
            else {
                confirmButton.attr('disabled', null)
            }
        })

    let topNNumberInFocusModeInput = itemsContainerFocusMode.append(INPUT).classed("removable", true)
        .attr(TYPE, "number")
        .attr(VALUE, topNSetting ? topNSetting.numberInFocusMode : 20)
        .attr(MIN, 1)
        .attr(MAX, 99)
        .on(INPUT, () => {
            let val = parseInt((<HTMLInputElement>topNNumberInFocusModeInput.node()).value);
            if (val < 1 || val > 99 || Number.isNaN(val)) {
                confirmButton.attr('disabled', true)
            }
            else {
                confirmButton.attr('disabled', null)
            }
        })

    let confirmButton = innerForm.append(BUTTON).text("OK").classed("removable", true);
    confirmButton.on(CLICK, () => {
        settings.setTopNSetting(hierarchy.hierarchyLevels[lvl].sources[0].displayName, parseInt((<HTMLSelectElement>topNDataPropertySelect.node()).value), lvl,
            parseInt((<HTMLInputElement>topNNumberInput.node()).value), parseInt((<HTMLInputElement>topNNumberInFocusModeInput.node()).value), parseInt((<HTMLSelectElement>topNTypeSelect.node()).value));
        settings.persistTopNSettings();

        drawing.removeBlur([Visual.headers, Visual.grandTotalRow, Visual.categoriesSelection, Visual.grandTotalCategoriesSelection], [Visual.visualDiv, Visual.title, Visual.headersDiv, Visual.infoBox]);
        Visual.topNForm.selectAll("*").remove();
        Visual.topNForm.style(DISPLAY, NONE);
    });

    showHideFormOptions(topNTypeSelect, lab1, labItems, labItemsEditMode, labItemsFocusMode, topNDataPropertySelect, topNNumberInput, topNNumberInFocusModeInput);
    topNTypeSelect.on(CHANGE, () => {
        showHideFormOptions(topNTypeSelect, lab1, labItems, labItemsEditMode, labItemsFocusMode, topNDataPropertySelect, topNNumberInput, topNNumberInFocusModeInput);
        let bb = getBoundingBoxHtml(<HTMLElement>Visual.topNForm.node());
        Visual.topNForm
            .style(LEFT, "50%")
            .style(TOP, "50%")
            .style(MARGIN_LEFT, - bb.width / 2 + PX)
            .style(MARGIN_TOP, - bb.height / 2 + PX);
    });

    // set position
    let bb = getBoundingBoxHtml(<HTMLElement>Visual.topNForm.node());
    Visual.topNForm
        .style(LEFT, "50%")
        .style(TOP, "50%")
        .style(MARGIN_LEFT, - bb.width / 2 + PX)
        .style(MARGIN_TOP, - bb.height / 2 + PX);

    closeCross.on(CLICK, () => {
        drawing.removeBlur([Visual.headers, Visual.grandTotalRow, Visual.categoriesSelection, Visual.grandTotalCategoriesSelection], [Visual.visualDiv, Visual.title, Visual.headersDiv, Visual.infoBox]);
        Visual.topNForm.selectAll("*").remove();
        Visual.topNForm
            .style(DISPLAY, NONE);
        settings.persistTopNForm(false);
    });
}

function showHideFormOptions(topNTypeSelect: d3.Selection<HTMLElement, any, any, any>, ...htmlElements: d3.Selection<HTMLElement, any, any, any>[]) {
    if (parseInt((<HTMLSelectElement>topNTypeSelect.node()).value) == TopNType.Off) {
        htmlElements.forEach(el => el.style(DISPLAY, NONE))
    } else {
        htmlElements.forEach(el => el.style(DISPLAY, BLOCK))
    }
}

function addMenuElementsLineDivider(context: d3.Selection<any, any, any, any>, fullWidth: boolean) {
    context.append("HR")
        .style(BACKGROUND_COLOR, LIGHTGRAY)
        .style(MARGIN, "0 auto")
        .style(WIDTH, fullWidth ? "100%" : "90%")
        .style(HEIGHT, "0.5px")
        .style(MARGIN_TOP, "1px")
        .style(MARGIN_BOTTOM, "1px")
        .style(BORDER, NONE);
}

function addCategoryName(context: d3.Selection<any, any, any, any>, name: string, settings: VarianceSettings) {
    context.append(LABEL)
        .text(name)
        .style(FONT_FAMILY, settings.labelFontFamily)
        .style(FONT_SIZE, `${settings.fontSize}${FONT_SIZE_UNIT}`)
        .style(PADDING, `4px 8px`)
        .style(BACKGROUND, WHITE)
        .style(DISPLAY, BLOCK)
}

function addExpandCollapseMenu(d: DataPoint, viewModel: ViewModel, context: d3.Selection<any, any, any, any>, hierarchy: ChartHierarchy) {
    let menu = getExpandCollapseMenuItems(d, viewModel, hierarchy);
    let menuElements = context
        .selectAll(".expand-collapse")
        .data(menu);

    let div = menuElements
        .enter().append(DIV)
        .classed(ELASTIC, true)
        .classed("expand-collapse", true);
    menuElements = menuElements.merge(div);
    menuElements
        .append(LABEL)
        .text(menuItem => menuItem.title)
        .on(CLICK, menuItem => menuItem.action());
    if (menu.length !== 0) {
        addMenuElementsLineDivider(context, false);
    }
}

function getExpandCollapseMenuItems(d: DataPoint, viewModel: ViewModel, hierarchy: ChartHierarchy): MenuItem[] {
    if (d.isTotal()) {
        return [{
            title: "Expand entire field",
            action: (): void => { viewModel.settings.expandAll(hierarchy.level); },
        },
        {
            title: "Collapse entire field",
            action: (): void => { viewModel.settings.collapseAll(d.fullCategory, hierarchy.level); },
        }];
    }
    return [];
}

function fixPickerPosition(classname: string) {
    let fontPickerSelection = d3.select(classname);
    let pickerheight = parseInt(fontPickerSelection.style(HEIGHT));
    if (this.plotter.height < pickerheight + parseInt(fontPickerSelection.style(TOP))) {
        fontPickerSelection
            .style(TOP, (this.plotter.height - pickerheight) / 2 - 10 + PX);
    }
}

function addExpandCollapseGroupMenu(level: number, context: d3.Selection<any, any, any, any>, viewModel: ViewModel) {
    let menu = getExpandCollapseGroupMenuItems(level, viewModel);
    let menuElements = context
        .selectAll(".expand-collapse-group")
        .data(menu)
    let div = menuElements
        .enter().append(DIV)
        .classed(ELASTIC, true)
        .classed("expand-collapse-group", true);
    menuElements = menuElements.merge(div);
    menuElements
        .append(LABEL)
        .text(menuItem => menuItem.title)
        .on(CLICK, menuItem => menuItem.action());
}

function getExpandCollapseGroupMenuItems(level: number, viewModel: ViewModel): MenuItem[] {
    return [{
        title: "Expand group",
        action: (): void => { viewModel.settings.expandEntireGroup(level); },
    },
    {
        title: "Collapse group",
        action: (): void => { viewModel.settings.collapseEntireGroup(level); },
    }];
}



function getCustomHighlightColorPicker(settings: VarianceSettings, pickerID: string, d: DataPoint) {
    let customColor = d.getRowHighlightColor();
    customColor = customColor === null || customColor === EMPTY ? BLACK_RGBA : customColor;
    let category = d.category;
    let chartAreas =
        d3.selectAll(`${G}.${CHART_AREA}`).filter(function () {
            let el = d3.select(this);
            return (el !== undefined && el.attr("dataProperty") === "1" || el.attr("dataProperty") === "0")
        });

    let rects = chartAreas.selectAll(`${RECT}`).filter(function (d: DataPoint) {
        return (d.category !== undefined && d.category === category && d3.select(this).classed(HIGHLIGHTABLE))
    })

    let circles = chartAreas.selectAll(`${CIRCLE}`).filter(function (d: DataPoint) {
        return (d.category !== undefined && d3.select(this).classed(HIGHLIGHTABLE))
    })

    let highlightFillPicker = Pickr.create({
        el: '#' + pickerID,
        container: '#sandbox-host',
        theme: 'monolith',
        swatches: null,
        appClass: 'fillpickr',
        closeWithKey: 'Escape',
        showAlways: false,
        adjustableNumbers: true,
        default: customColor,
        useAsButton: true,
        autoReposition: false,
        defaultRepresentation: 'RGBA',
        components: {
            preview: true,
            hue: true,
            opacity: true,
            interaction: {
                save: true,
                clear: true,
                input: true,
            },
        },
    });
    highlightFillPicker.on("save", (color) => {
        highlightFillPicker.hide();
        d.setRowHighlightColor(settings.getColorString(color));
    }).on("clear", () => {
        highlightFillPicker.hide();
        d.setRowHighlightColor(null);
    }).on('change', (color) => {
        rects.attr(FILL, settings.getColorString(color));
        circles.attr(FILL, settings.getColorString(color));
    });

    return highlightFillPicker;
}

function getCustomTextColorPicker(settings: VarianceSettings, category: string, pickerID: string, d: DataPoint) {
    let customColor = d.getRowFontColor();
    customColor = customColor === null || customColor === EMPTY || customColor === undefined ? BLACK_RGBA : customColor;

    let fillpickr = Pickr.create({
        el: '#' + pickerID,
        container: '#sandbox-host',
        theme: 'monolith',
        swatches: null,
        appClass: 'fillpickr',
        closeWithKey: 'Escape',
        showAlways: false,
        adjustableNumbers: true,
        default: customColor,
        useAsButton: true,
        autoReposition: false,
        defaultRepresentation: 'RGBA',
        components: {
            preview: true,
            hue: true,
            opacity: true,
            interaction: {
                save: true,
                clear: true,
                input: true,
            },
        },
    });

    let labels = d3
        .selectAll(TEXT).filter((d: DataPoint) => {
            return (d !== undefined && d.category === category);
        });
    fillpickr.on("save", (color) => {
        fillpickr.hide();
        d.setRowFontColor(settings.getColorString(color));
    }).on("clear", () => {
        fillpickr.hide();
        d.setRowFontColor(null);
    }).on('change', (color) => {
        labels.attr(FILL, settings.getColorString(color));
    })
    return fillpickr;
}

