import * as d3 from "d3";
import { BLOCK, CLICK, DIV, DRAG, DRAGEND, DRAGSTART, NONE, P, PX } from "../../library/constants";
import { ToolbarOptions } from "@zebrabi/global-toolbar-old/interface/ToolbarOption";
import { Visual } from "../../visual";
import { VarianceSettings } from "../../settings/varianceSettings";
import { ViewModel } from "../../settings/viewModel";
import { getOfficeSettings } from "@zebrabi/office-settings";
import { DATA_SOURCE, DataSource, createDialog } from "@zebrabi/data-helpers/editData";
import { MeasureRoles, CategoricalRoles } from "@zebrabi/data-helpers/fieldAssignment";
import { getColumnRole } from "@zebrabi/data-helpers/visualHelpers";
import { DataView, DataViewMetadataColumn } from "@zebrabi/matrix-data";
import BaseSwitcherWithHeaderOld from "../../../charts/toolbar/components/BaseSwitcherWithHeaderOld";
import { ColumnOptions } from "../../definitions";
import { isPlanScenario, isForecastScenario } from "../../helpers";
import { flagHandler } from "@zebrabi/zebrabi-core";

class FieldsTablesSwitcher extends BaseSwitcherWithHeaderOld {
    static readonly CLASS_NAME = `FieldsTablesSwitcher`;

    toolbarOptions: ToolbarOptions = {
        collapsed: true,
        elementName: "Data fields",
        icon: "fields-icon-base64",
        type: "button",
        actions: [],
    };

    // Value buckets
    public bucketActual: d3.Selection<HTMLDivElement, any, any, any>;
    public bucketPY: d3.Selection<HTMLDivElement, any, any, any>;
    public bucketPL: d3.Selection<HTMLDivElement, any, any, any>;
    public bucketFC: d3.Selection<HTMLDivElement, any, any, any>;
    public bucketComment: d3.Selection<HTMLDivElement, any, any, any>;

    public bucketCategory: d3.Selection<HTMLDivElement, any, any, any>;
    public bucketGroup: d3.Selection<HTMLDivElement, any, any, any>;

    constructor(private settings: VarianceSettings, private dataView: DataView, private viewModel: ViewModel) {
        super();
    }

    /**
     * Transpiling will lose the type of the class once minimised, method is used the type is preserved
     */
    public getClassName(): string {
        return FieldsTablesSwitcher.CLASS_NAME;
    }

    buttonAction(action: string, message: string): void {
    }

    update(message: Map<string, any>): void {
        if (message.has("viewer")) {
            if (message.get("viewer") == "true") {
                this.switcherContainer.style.display = NONE;
            } else {
                this.switcherContainer.style.display = BLOCK;
            }
        }

        if (message.has("settings")) {
            this.settings = message.get("settings");
        }

        if (message.has("viewModel")) {
            this.viewModel = message.get("viewModel");
        }

        if (message.has("dataView")) {
            this.dataView = message.get("dataView");
        }

        return;
    }

    createMenuItems(switcherMenuContainer: HTMLElement, active: string = "") {
        super.createMenuItems(switcherMenuContainer, active);
        const body = switcherMenuContainer.querySelector('.body');
        const fieldsForm = d3.select(body).append(DIV).classed("fields-settings-form-2", true);
        let innerForm = fieldsForm.append(DIV).classed("inner-form", true);
        this.createFieldsForm(innerForm);
    }

    public drawValueFieldsItems(innerForm: d3.Selection<HTMLDivElement, any, any, any>) {
        this.dataView?.metadata?.columns.forEach((dataColumn) => {
            const currentRole = getColumnRole(dataColumn);
            if (!flagHandler.has("office-tables-category-group-ui-bucket") && (currentRole in CategoricalRoles)) {
                return;
            }

            let item = innerForm.append(DIV).classed("item-vf", true).text(dataColumn.displayName);

            // use position from scenario options to set PL/FC columns order
            const scenarioOptions = Visual.getInstance().viewModel?.scenarioOptions;
            if (scenarioOptions) {
                const scenarioOptionKey = Object.keys(scenarioOptions).find(key => scenarioOptions[key].fieldName === dataColumn.displayName && scenarioOptions[key].index === dataColumn.index);
                const scenarioOption: ColumnOptions = scenarioOptionKey ? scenarioOptions[scenarioOptionKey] : null;
                if (scenarioOption && (dataColumn.roles[MeasureRoles.Plan] || dataColumn.roles[MeasureRoles.Forecast])) {
                    item.style("order", scenarioOption.position ?? 0); //scenarioOption.position);
                }
            }

            const bucket = this.getBucketForColumnRole(dataColumn);
            if (!currentRole || !bucket) {
                return;
            }
            bucket.node().append(item.node());
            item.data([dataColumn]);

            //to-check: index for categorical roles
            if (dataColumn.index === undefined && (currentRole in MeasureRoles)) {
                return;
            }

            let draggable = d3.drag()
                .on(DRAGSTART, () => {
                    const itemNode = item.node();
                    const topPosition = d3.mouse(itemNode.parentElement)[1]
                        + itemNode.parentElement.offsetTop
                        - itemNode.getBoundingClientRect().height / 2;
                    item.style("top", + topPosition + PX);
                    item.style("position", "absolute");
                    item.style("pointer-events", "none");
                    item.style("z-index", 10);
                })
                .on(DRAG, function () {
                    const itemNode = item.node();
                    const topPosition = d3.mouse(itemNode.parentElement)[1]
                        + itemNode.parentElement.offsetTop
                        - itemNode.getBoundingClientRect().height / 2;
                    item.style("top", + topPosition + PX);
                }).on(DRAGEND, () => {
                    const returnData = new Map<string, any>();
                    item.style("position", "relative");
                    item.style("pointer-events", "auto");
                    item.style("z-index", null);
                    item.style("top", null);

                    let newRole = this.findRoleUnderCursor(d3.event);
                    if (!newRole || newRole in CategoricalRoles) {  // for now don't allow dragging to categorical roles
                        newRole = currentRole;
                    }
                    else {
                        const newBucket = this.getBucketForRole(newRole);
                        newBucket.node().append(item.node());
                    }


                    // handle reordering of PL/FC columns within buckets, use position from scenario options
                    if (newRole === currentRole && (newRole === MeasureRoles.Plan || newRole === MeasureRoles.Forecast)) {
                        const scenarioOptions = Visual.getInstance().viewModel?.scenarioOptions;
                        let columnOption: ColumnOptions = null;
                        if (scenarioOptions) {
                            const scenarioOptionKey = Object.keys(scenarioOptions).find(key => scenarioOptions[key].fieldName === dataColumn.displayName && scenarioOptions[key].index === dataColumn.index);
                            const scenarioOption: ColumnOptions = scenarioOptionKey ? scenarioOptions[scenarioOptionKey] : null;
                            if (scenarioOption && (dataColumn.roles[MeasureRoles.Plan] || dataColumn.roles[MeasureRoles.Forecast])) {
                                columnOption = scenarioOption;
                            }
                        }

                        if (columnOption) {
                            const topPosition = Number(item.style("top").replace("px", ""));
                            //get measure role position from topPosition
                            const newRolePosition = this.getPositionWithinBucket(topPosition, newRole);
                            if (columnOption.position !== newRolePosition) {
                                // Reorder items in bucket
                                const scenarioOptionRefKey = Object.keys(scenarioOptions).find(key => {
                                    const column = <ColumnOptions>scenarioOptions[key];
                                    return column?.position === newRolePosition && (
                                        currentRole === MeasureRoles.Plan && isPlanScenario(column.scenario)
                                        ||
                                        currentRole === MeasureRoles.Forecast && isForecastScenario(column.scenario));
                                });

                                if (scenarioOptionRefKey) {
                                    const scenarioOptionRef = scenarioOptions[scenarioOptionRefKey];
                                    scenarioOptionRef.position = columnOption.position;
                                    columnOption.position = newRolePosition;
                                }
                            }
                        }
                    }
                    else if (newRole === MeasureRoles.Plan || newRole === MeasureRoles.Forecast || currentRole === MeasureRoles.Plan || currentRole === MeasureRoles.Forecast) {
                        Visual.getInstance().resetPLFCReorder = true;
                    }

                    if (newRole in MeasureRoles && currentRole in MeasureRoles) {
                        // Don't switch roles for "Values" role, allow multiple values in bucket
                        if (this.shouldSwitchRoles(<MeasureRoles>newRole, this.dataView.metadata.columns)) {
                            let existingColumn = this.dataView.metadata.columns.find(column => column.roles[newRole]);
                            if (existingColumn) {
                                this.setNewMeasureRoleSettings(<MeasureRoles>currentRole, existingColumn.index, returnData);
                                existingColumn.roles = {
                                    [currentRole]: true
                                };
                            }
                        }

                        this.setNewMeasureRoleSettings(<MeasureRoles>newRole, dataColumn.index, returnData);
                        let columnToSwitch = this.dataView.metadata.columns.find(valsrc => valsrc.index === dataColumn.index);  // just set new role for the dataColumn itself?
                        columnToSwitch.roles = {
                            [newRole]: true
                        };
                    }

                    this.notify(returnData, `${this.getClassName()}Observer`);
                });

            if (currentRole in MeasureRoles) {
                item.classed("draggable", true);
                draggable(item);
            }
        });
    }

    private getPositionWithinBucket(top: number, role: MeasureRoles): number {
        if (role === MeasureRoles.Plan) {
            const startPos = this.bucketPL.node().offsetTop;
            const diff = top - startPos;
            if (diff < 12) {
                return 0;
            }
            else if (diff < 28) {
                return 1;
            }
            else {  //if (diff < 72) {
                return 2;
            }
        }
        else if (role === MeasureRoles.Forecast) {
            const startPos = this.bucketFC.node().offsetTop; //getBoundingClientRect().top;
            const diff = top - startPos;
            if (diff < 12) {
                return 0;
            }
            else if (diff < 28) {
                return 1;
            }
            else {  //if (diff < 72) {
                return 2;
            }
        }

        return 0;
    }

    private shouldSwitchRoles(newRole: MeasureRoles, columns: DataViewMetadataColumn[]): boolean {
        return newRole === MeasureRoles.PreviousYear || newRole === MeasureRoles.Comments ||
            newRole === MeasureRoles.Plan && columns.filter(column => column.roles[MeasureRoles.Plan]).length > 2 ||
            newRole === MeasureRoles.Forecast && columns.filter(column => column.roles[MeasureRoles.Forecast]).length > 2;
    }

    public setNewMeasureRoleSettings(role: MeasureRoles, index: number, returnData: Map<string, any>) {
        if (index < this.settings.measureRoles.length) {
            this.settings.measureRoles[index] = role;
            returnData.set("measureRoles", this.settings.measureRoles);
        }
    }

    private createFieldsForm(innerForm: d3.Selection<HTMLDivElement, any, any, any>) {
        if (flagHandler.has("office-tables-category-group-ui-bucket")) {
            innerForm.append(P).text("Category");
            this.bucketCategory = innerForm.append(DIV).classed("bucket category", true);
            innerForm.append(P).text("Group");
            this.bucketGroup = innerForm.append(DIV).classed("bucket group", true);
        }
        innerForm.append(P).text("Actual");
        this.bucketActual = innerForm.append(DIV).classed("bucket actual", true);
        innerForm.append(P).text("Previous Year");
        this.bucketPY = innerForm.append(DIV).classed("bucket py", true);
        innerForm.append(P).text("Plan");
        this.bucketPL = innerForm.append(DIV).classed("bucket pl", true);
        innerForm.append(P).text("Forecast");
        this.bucketFC = innerForm.append(DIV).classed("bucket fc", true);

        innerForm.append(P).text("Comments");
        this.bucketComment = innerForm.append(DIV).classed("bucket comment", true);

        this.drawValueFieldsItems(innerForm);

        if (Office.context.host === Office.HostType.Excel) {
            this.createExcelEditDataSourceUI(innerForm);
        }
    }

    private createExcelEditDataSourceUI(innerForm: d3.Selection<HTMLDivElement, any, any, any>) {
        const formParent = innerForm.node().parentElement;
        const editDataDiv = document.createElement("div");
        formParent.insertBefore(editDataDiv, innerForm.node());
        editDataDiv.classList.add("edit-source-data");
        const editDataDivSelection = d3.select(editDataDiv);

        const dataSource = getOfficeSettings(DATA_SOURCE);
        const dataSourceDisplayString = this.getDataSourceDisplayString(dataSource);

        editDataDivSelection.append(P).classed("data-source-label", true)
            .text("Data source");
        const editDataSourceDiv = editDataDivSelection.append(DIV);
        editDataSourceDiv.append(P).classed("data-source", true)
            .text(dataSourceDisplayString);

        if (dataSource) {
            const editDatBtn = editDataSourceDiv.append("button")
                .classed("edit-data-source-btn", true);

            const dataEditHandler = () => {
                Visual.getInstance().update(Visual.visualSettings);
            };
            editDatBtn.on(CLICK, () => {
                if (d3?.event?.detail > 1) {    // prevent double click
                    return;
                }
                createDialog(dataSource, dataEditHandler);
            });
        }
    }

    getDataSourceDisplayString(dataSource: DataSource): string {
        if (!dataSource) {
            return "";
        }
        else if (dataSource.range) {
            return "Range: " + dataSource.range;
        }
        else if (dataSource.table) {
            return "Excel table: " + dataSource.table;
        }
        else if (dataSource.pivotTable) {
            return "Pivot table: " + dataSource.pivotTable;
        }
    }

    getBucketForColumnRole(column: DataViewMetadataColumn): d3.Selection<HTMLDivElement, any, any, any> {
        if (column.roles[MeasureRoles.Values]) {
            return this.bucketActual;
        } else if (column.roles[MeasureRoles.PreviousYear]) {
            return this.bucketPY
        } else if (column.roles[MeasureRoles.Plan]) {
            return this.bucketPL;
        } else if (column.roles[MeasureRoles.Forecast]) {
            return this.bucketFC;
        } else if (column.roles[MeasureRoles.Comments]) {
            return this.bucketComment;
        } else if (column.roles[CategoricalRoles.Category]) {
            return this.bucketCategory;
        } else if (column.roles[CategoricalRoles.Group]) {
            return this.bucketGroup;
        } else {
            return null;
        }
    }

    getBucketForRole(role: MeasureRoles | CategoricalRoles): d3.Selection<HTMLDivElement, any, any, any> {
        switch (role) {
            case MeasureRoles.Values:
                return this.bucketActual;
            case MeasureRoles.PreviousYear:
                return this.bucketPY;
            case MeasureRoles.Plan:
                return this.bucketPL;
            case MeasureRoles.Forecast:
                return this.bucketFC;
            case MeasureRoles.Comments:
                return this.bucketComment;
            case CategoricalRoles.Category:
                return this.bucketCategory;
            case CategoricalRoles.Group:
                return this.bucketGroup;
            default:
                return null;
        }
    }

    findRoleUnderCursor(d3Event): MeasureRoles | CategoricalRoles {
        let foundRole = null;

        if (flagHandler.has("office-field-drag-drop-target-selection")) {
            const target = d3Event?.sourceEvent?.target ?? document.elementFromPoint(d3Event?.sourceEvent?.clientX, d3Event?.sourceEvent?.clientY);
            if (target) {
                switch (target) {
                    case this.bucketActual.node():
                        foundRole = MeasureRoles.Values;
                        break;
                    case this.bucketPY.node():
                        foundRole = MeasureRoles.PreviousYear;
                        break;
                    case this.bucketPL.node():
                        foundRole = MeasureRoles.Plan;
                        break;
                    case this.bucketFC.node():
                        foundRole = MeasureRoles.Forecast;
                        break;
                    case this.bucketComment.node():
                        foundRole = MeasureRoles.Comments;
                        break;
                    case this.bucketCategory.node():
                        foundRole = CategoricalRoles.Category;
                        break;
                    case this.bucketGroup.node():
                        foundRole = CategoricalRoles.Group;
                        break;
                }

                if (foundRole) {
                    return foundRole;
                }
            }
        }

        // if target is not found, try to find it by using the :hover class (not working on Mac Os)
        document.querySelectorAll(".bucket:hover").forEach(hoveredItem => {
            switch (hoveredItem.className) {
                case this.bucketActual.node().className:
                    foundRole = MeasureRoles.Values;
                    break;
                case this.bucketPY.node().className:
                    foundRole = MeasureRoles.PreviousYear;
                    break;
                case this.bucketPL.node().className:
                    foundRole = MeasureRoles.Plan;
                    break;
                case this.bucketFC.node().className:
                    foundRole = MeasureRoles.Forecast;
                    break;
                case this.bucketComment.node().className:
                    foundRole = MeasureRoles.Comments;
                    break;
                case this.bucketCategory.node().className:
                    foundRole = CategoricalRoles.Category;
                    break;
                case this.bucketGroup.node().className:
                    foundRole = CategoricalRoles.Group;
                    break;
            }
        });
        return foundRole;
    }
}

export default FieldsTablesSwitcher;
