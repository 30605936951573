
import { DataProperty, ColumnFormat, NumberOfDataProperties } from "./constants";

export function sum(array: number[]) {
    return array.reduce(sumTwoNumbers, 0);
}

function sumTwoNumbers(a: number, b: number): number {
    return a + b;
}

export function longestString(array: string[]) {
    return array.reduce((a, b) => a > b ? a : b).length;
}

export function getLineAngle(x1: number, x0: number, y1: number, y0: number) {
    let dx = x1 - x0;
    let dy = y1 - y0;
    return Math.atan2(-dy, dx) * 180 / Math.PI;
}

export function isValueMeasure(dataProperty: DataProperty) {
    return dataProperty === DataProperty.Value || dataProperty === DataProperty.ReferenceValue || dataProperty === DataProperty.SecondReferenceValue || dataProperty === DataProperty.ThirdReferenceValue || dataProperty === DataProperty.FourthReferenceValue || dataProperty === DataProperty.FifthReferenceValue || dataProperty === DataProperty.SixthReferenceValue || dataProperty === DataProperty.SeventhReferenceValue;
}

export function isAdditionalMeasure(dataProperty: DataProperty): boolean {
    return dataProperty >= DataProperty.AdditionalMeasure1 && dataProperty <= DataProperty.AdditionalMeasure10 ||
        dataProperty >= DataProperty.AdditionalMeasure11 && dataProperty <= DataProperty.AdditionalMeasure20;
}

export function isDifferenceMeasure(dataProperty: DataProperty, columnFormat: ColumnFormat): boolean {
    if (isAdditionalMeasure(dataProperty)) {
        if (columnFormat === ColumnFormat.AbsoluteDifference || columnFormat === ColumnFormat.RelativeDifference) {
            return true;
        }
        else {
            return false;
        }
    }
    return (dataProperty === DataProperty.AbsoluteDifference || dataProperty === DataProperty.RelativeDifference
        || dataProperty === DataProperty.SecondAbsoluteDifference || dataProperty === DataProperty.SecondRelativeDifference
        || dataProperty === DataProperty.ThirdAbsoluteDifference || dataProperty === DataProperty.ThirdRelativeDifference
        || dataProperty === DataProperty.FourthAbsoluteDifference || dataProperty === DataProperty.FourthRelativeDifference
        || dataProperty === DataProperty.FifthAbsoluteDifference || dataProperty === DataProperty.FifthRelativeDifference
        || dataProperty === DataProperty.SixthAbsoluteDifference || dataProperty === DataProperty.SixthRelativeDifference
        || dataProperty === DataProperty.SeventhAbsoluteDifference || dataProperty === DataProperty.SeventhRelativeDifference);
}

export function joinElementsIntoString(arr: any[]): string {
    return JSON.stringify(arr);
}

export function splitStringIntoArray(arr: string): any[] {
    return JSON.parse(arr);
}

export function splitStringFillDefaults(str: string, defaultVal: any) {
    let arr = JSON.parse(str);
    if (arr.length < NumberOfDataProperties) {
        for (let i = 0; i < NumberOfDataProperties; i++) {
            if (arr[i] === undefined) {
                arr[i] = defaultVal;
            }
        }
    }
    return arr;
}

export function sanitizeString(s: string) {
    const map = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#x27;',
        "/": '&#x2F;',
    };
    const reg = /[&<>"'/]/ig;
    return s.replace(reg, (match) => (map[match]));
}

export function dayInMiliseconds() {
    return 1000 * 60 * 60 * 24;
}

export function isIEorEdge(): boolean {
    // Internet Explorer 6-11 check, Non-chromium Edge check
    return false || !!((<any>document).documentMode) || (<any>window).navigator.userAgent.indexOf("Edge") > -1;
}
