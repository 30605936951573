import { DataProperty, TopNType } from "../library/constants"

export class TopNSetting {
    public numberInFocusMode: number;
    public category: string;
    public dataProperty: DataProperty;
    public columnName: string;
    public level: number;
    public number: number;
    public type: TopNType;
}