import { ViewModel } from "../../settings/viewModel";
import { PlotDefinition, ChartType } from "../../definitions";
import { DataProperty } from "../../library/constants";
import { HorizontalPlotter } from "./horizontalPlotter";

export class HorizontalActualRelativePlotter extends HorizontalPlotter {
    constructor(viewModel: ViewModel, width: number, height: number, reportArea: d3.Selection<SVGElement, any, any, any>, svg: d3.Selection<SVGElement, any, any, any>,
        locale: string) {
        super(viewModel, width, height, reportArea, svg, locale);
    }

    protected getPlotDefinition(): PlotDefinition {
        let plotDefinition = this.getBlankPlotDefinition(1);
        if (this.viewModel.hasHierarchy) {
            this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.Value, DataProperty.ReferenceValue, false, false);
            this.addChartDefinition(plotDefinition, ChartType.PlusMinusDot, DataProperty.RelativeDifference, DataProperty.ReferenceValue, false, false);
            if (this.viewModel.HasSecondReferenceColumn) {
                this.addChartDefinition(plotDefinition, ChartType.PlusMinusDot, DataProperty.SecondRelativeDifference, DataProperty.ReferenceValue, false, false);
            }
            if (this.viewModel.HasThirdReferenceColumn) {
                this.addChartDefinition(plotDefinition, ChartType.PlusMinusDot, DataProperty.ThirdRelativeDifference, DataProperty.ReferenceValue, false, false);
            }
            if (this.viewModel.HasFourthReferenceColumn) {
                this.addChartDefinition(plotDefinition, ChartType.PlusMinusDot, DataProperty.FourthRelativeDifference, DataProperty.ReferenceValue, false, false);
            }
            if (this.viewModel.HasFifthReferenceColumn) {
                this.addChartDefinition(plotDefinition, ChartType.PlusMinusDot, DataProperty.FifthRelativeDifference, DataProperty.ReferenceValue, false, false);
            }
            if (this.viewModel.HasSixthReferenceColumn) {
                this.addChartDefinition(plotDefinition, ChartType.PlusMinusDot, DataProperty.SixthRelativeDifference, DataProperty.ReferenceValue, false, false);
            }
            if (this.viewModel.HasSeventhReferenceColumn) {
                this.addChartDefinition(plotDefinition, ChartType.PlusMinusDot, DataProperty.SeventhRelativeDifference, DataProperty.ReferenceValue, false, false);
            }
        }
        else {
            this.addChartDefinition(plotDefinition, ChartType.ValueChart, DataProperty.Value, DataProperty.ReferenceValue, false, false);
            this.addChartDefinition(plotDefinition, ChartType.PlusMinusDot, DataProperty.RelativeDifference, DataProperty.ReferenceValue, false, false);
            if (this.viewModel.HasSecondReferenceColumn) {
                this.addChartDefinition(plotDefinition, ChartType.PlusMinusDot, DataProperty.SecondRelativeDifference, DataProperty.ReferenceValue, false, false);
            }
            if (this.viewModel.HasThirdReferenceColumn) {
                this.addChartDefinition(plotDefinition, ChartType.PlusMinusDot, DataProperty.ThirdRelativeDifference, DataProperty.ReferenceValue, false, false);
            }
            if (this.viewModel.HasFourthReferenceColumn) {
                this.addChartDefinition(plotDefinition, ChartType.PlusMinusDot, DataProperty.FourthRelativeDifference, DataProperty.ReferenceValue, false, false);
            }
            if (this.viewModel.HasFifthReferenceColumn) {
                this.addChartDefinition(plotDefinition, ChartType.PlusMinusDot, DataProperty.FifthRelativeDifference, DataProperty.ReferenceValue, false, false);
            }
            if (this.viewModel.HasSixthReferenceColumn) {
                this.addChartDefinition(plotDefinition, ChartType.PlusMinusDot, DataProperty.SixthRelativeDifference, DataProperty.ReferenceValue, false, false);
            }
            if (this.viewModel.HasSeventhReferenceColumn) {
                this.addChartDefinition(plotDefinition, ChartType.PlusMinusDot, DataProperty.SeventhRelativeDifference, DataProperty.ReferenceValue, false, false);
            }
        }
        return plotDefinition;
    }
}