import BaseObserver from "../observers/BaseObserver";

/**
 * This class defines the basic structure of the subject, it's in use wherever a object needs to let other objects know
 * that something has changed
 */
abstract class BaseSubject<T,O extends BaseObserver<T>> {
    protected observers: O[] = [];

    /**
     * Attach a observer that will be notified when the conditions are met
     * @param {BaseObserver} observer
     * @throws Error
     */
    attach(observer: O): void{
        if (!observer.update) throw new Error("Cannot attach Observer without a update method! Object: " + observer.constructor.name);

        if (!this.observers.includes(observer)){
            this.observers.push(observer);
        }
    }

    /**
     * Detach a observer that is no longer needed
     * @param observer {Type}
     */
    detach(observer: O): void {
        this.observers = this.observers.filter(
            (item) => {
                if (item !== observer) {
                    return item; // return all but the subject beign removed
                }
            }
        );
    }

    /**
     * detaches all observer currently registered
     */
    detachAll(){
        this.observers = [];
    }

    /**
     * notifies all BaseObserver objects of the change
     * @param message {Type}
     * @param target {string}
     */
    notify(message:any){
        for (let i = 0; i < this.observers.length; i++){
            this.observers[i].update(message);
        }
    }
}

export default BaseSubject;
