import { HorizontalPlotter } from "./horizontalPlotter";
import { ViewModel } from "../../settings/viewModel";
import { PlotDefinition, ChartType } from "../../definitions";
import { DataProperty } from "../../library/constants";

export class RelativePlotter extends HorizontalPlotter {
    constructor(viewModel: ViewModel, width: number, height: number, reportArea: d3.Selection<SVGElement, any, any, any>, svg: d3.Selection<SVGElement, any, any, any>,
        locale: string) {
        super(viewModel, width, height, reportArea, svg, locale);
    }

    protected getPlotDefinition(): PlotDefinition {
        let plotDefinition = this.getBlankPlotDefinition(0);
        this.addRelativeColumns(plotDefinition, ChartType.PlusMinusDot);
        return plotDefinition;
    }

    protected getDataProperty(): DataProperty {
        return DataProperty.RelativeDifference;
    }
}