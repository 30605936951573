import { SheetData } from "x-data-spreadsheet";

// Structural variance hierarchy
export const StructuralVarianceSampleData: SheetData = {
    rows: {
        0: { cells: { 0: { text: "Division" }, 1: { text: "BusinessUnit" }, 2: { text: "AC" }, 3: { text: "PL" } } },
        1: { cells: { 0: { text: "Eletronics" }, 1: { text: "Speakers" }, 2: { text: "827135" }, 3: { text: "773612" } } },
        2: { cells: { 0: { text: "" }, 1: { text: "Accessories" }, 2: { text: "308446" }, 3: { text: "306926" } } },
        3: { cells: { 0: { text: "" }, 1: { text: "Desktop PC" }, 2: { text: "10949" }, 3: { text: "10557" } } },
        4: { cells: { 0: { text: "" }, 1: { text: "Laptops" }, 2: { text: "31064" }, 3: { text: "29789" } } },
        5: { cells: { 0: { text: "" }, 1: { text: "Monitors" }, 2: { text: "13083" }, 3: { text: "16374" } } },
        6: { cells: { 0: { text: "" }, 1: { text: "Smartphones" }, 2: { text: "728774" }, 3: { text: "772624" } } },
        7: { cells: { 0: { text: "" }, 1: { text: "Tablets" }, 2: { text: "819823" }, 3: { text: "755882" } } },
        8: { cells: { 0: { text: "" }, 1: { text: "Cameras" }, 2: { text: "48038" }, 3: { text: "52918" } } },
        9: { cells: { 0: { text: "" }, 1: { text: "Games" }, 2: { text: "3056" }, 3: { text: "3477" } } },
        10: { cells: { 0: { text: "" }, 1: { text: "Lighting" }, 2: { text: "299847" }, 3: { text: "325657" } } },
        11: { cells: { 0: { text: "" }, 1: { text: "Recorders" }, 2: { text: "13825" }, 3: { text: "6847" } } },
        12: { cells: { 0: { text: "" }, 1: { text: "TV" }, 2: { text: "6943" }, 3: { text: "4864" } } },
        13: { cells: { 0: { text: "" }, 1: { text: "Wearables" }, 2: { text: "2195823" }, 3: { text: "2105365" } } },
        14: { cells: { 0: { text: "Personal care" }, 1: { text: "Baby Care BU" }, 2: { text: "2489256" }, 3: { text: "2632267" } } },
        15: { cells: { 0: { text: "" }, 1: { text: "Hair Care BU" }, 2: { text: "826777" }, 3: { text: "732041" } } },
        16: { cells: { 0: { text: "" }, 1: { text: "Oral Care BU" }, 2: { text: "86197" }, 3: { text: "72114" } } },
        17: { cells: { 0: { text: "" }, 1: { text: "Skin Care BU" }, 2: { text: "57075" }, 3: { text: "48040" } } },
    },
};

export const StructuralVarianceSampleData3Levels: SheetData = {
    rows: {
        0: { cells: { 0: { text: "Division" }, 1: { text: "Group" }, 2: { text: "Business Unit" }, 3: { text: "AC" }, 4: { text: "PL" } } },
        1: { cells: { 0: { text: "Eletronics" }, 1: { text: "Audio" }, 2: { text: "Speakers" }, 3: { text: "827135" }, 4: { text: "773612" } } },
        2: { cells: { 0: { text: "" }, 1: { text: "Comp" }, 2: { text: "Accessories" }, 3: { text: "308446" }, 4: { text: "306926" } } },
        3: { cells: { 0: { text: "" }, 1: { text: "" }, 2: { text: "Desktop PC" }, 3: { text: "10949" }, 4: { text: "10557" } } },
        4: { cells: { 0: { text: "" }, 1: { text: "" }, 2: { text: "Laptops" }, 3: { text: "31064" }, 4: { text: "29789" } } },
        5: { cells: { 0: { text: "" }, 1: { text: "" }, 2: { text: "Monitors" }, 3: { text: "13083" }, 4: { text: "16374" } } },
        6: { cells: { 0: { text: "" }, 1: { text: "Mobile" }, 2: { text: "Smartphones" }, 3: { text: "728774" }, 4: { text: "772624" } } },
        7: { cells: { 0: { text: "" }, 1: { text: "" }, 2: { text: "Tablets" }, 3: { text: "819823" }, 4: { text: "755882" } } },
        8: { cells: { 0: { text: "" }, 1: { text: "Video" }, 2: { text: "Cameras" }, 3: { text: "48038" }, 4: { text: "52918" } } },
        9: { cells: { 0: { text: "" }, 1: { text: "" }, 2: { text: "Games" }, 3: { text: "3056" }, 4: { text: "3477" } } },
        10: { cells: { 0: { text: "" }, 1: { text: "" }, 2: { text: "Lighting" }, 3: { text: "299847" }, 4: { text: "325657" } } },
        11: { cells: { 0: { text: "" }, 1: { text: "" }, 2: { text: "Recorders" }, 3: { text: "13825" }, 4: { text: "6847" } } },
        12: { cells: { 0: { text: "" }, 1: { text: "" }, 2: { text: "TV" }, 3: { text: "6943" }, 4: { text: "4864" } } },
        13: { cells: { 0: { text: "" }, 1: { text: "Wearable" }, 2: { text: "Wearables" }, 3: { text: "2195823" }, 4: { text: "2105365" } } },
        14: { cells: { 0: { text: "Personal care" }, 1: { text: "Baby Care" }, 2: { text: "Baby Care BU" }, 3: { text: "2489256" }, 4: { text: "2632267" } } },
        15: { cells: { 0: { text: "" }, 1: { text: "Hair Care" }, 2: { text: "Hair Care BU" }, 3: { text: "826777" }, 4: { text: "732041" } } },
        16: { cells: { 0: { text: "" }, 1: { text: "Oral Care" }, 2: { text: "Oral Care BU" }, 3: { text: "86197" }, 4: { text: "72114" } } },
    },
};

export const StructuralVarianceCommentData: SheetData = {
    rows: {
        0: { cells: { 0: { text: "Comments" } } },
        14: {
            cells: {
                0: {
                    text: "The decrease is due to the closing of the product line",
                },
            },
        },
        15: {
            cells: {
                0: {
                    text: "Our best performing business unit this year",
                },
            },
        },
    },
};
