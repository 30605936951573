import { VerticalPlotter } from "./verticalPlotter";
import { ViewModel } from "../../settings/viewModel";
import { PlotDefinition, ChartType, ValueChart } from "../../definitions";
import { DataProperty, Scenario } from "../../library/constants";

export class VerticalActualRelativePlotter extends VerticalPlotter {
    constructor(viewModel: ViewModel, width: number, height: number, reportArea: d3.Selection<SVGElement, any, any, any>, svg: d3.Selection<SVGElement, any, any, any>,
        locale: string) {
        super(viewModel, width, height, reportArea, svg, locale);
    }

    // tslint:disable-next-line: max-func-body-length
    protected getPlotDefinitions(): PlotDefinition[] {
        let plotDefinitions: PlotDefinition[] = [];
        let actualRatio = this.viewModel.hasHierarchy ? 0 : 0.7;

        // Full layout
        let plotDefinition = this.getBlankPlotDefinition(actualRatio);
        if (this.viewModel.hasHierarchy) {
            this.addValueColumns(plotDefinition, ChartType.Table);
        }
        else {
            this.addValueColumns(plotDefinition, ChartType.ValueChart);
        }
        this.addRelativeColumns(plotDefinition, ChartType.PlusMinusDot);
        plotDefinitions.push(plotDefinition);

        // Turn relative columns into table
        plotDefinition = this.getBlankPlotDefinition(1);
        if (this.viewModel.hasHierarchy) {
            this.addValueColumns(plotDefinition, ChartType.Table);
        }
        else {
            this.addValueColumns(plotDefinition, ChartType.ValueChart);
        }
        this.addRelativeColumns(plotDefinition, ChartType.Table)
        plotDefinitions.push(plotDefinition);

        // Turn value charts into table. This is only needed on non hierarchical layout, since hierarchies
        // don't show value charts at all.
        if (!this.viewModel.hasHierarchy) {
            plotDefinition = this.getBlankPlotDefinition(1);
            this.addValueColumns(plotDefinition, ChartType.Table);
            this.addRelativeColumns(plotDefinition, ChartType.Table);
            plotDefinitions.push(plotDefinition);
        }

        plotDefinition = this.getBlankPlotDefinition(1);
        if (this.viewModel.reference.scenario === Scenario.PreviousYear) {
            if (this.settings.valueChart === ValueChart.OverlappedBar) {
                this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.ReferenceValue, DataProperty.ReferenceValue, false, false);
            }
            this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.Value, DataProperty.ReferenceValue, false, false);
        }
        else {
            this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.Value, DataProperty.ReferenceValue, false, false);
            if (this.settings.valueChart === ValueChart.OverlappedBar) {
                this.addChartDefinition(plotDefinition, ChartType.Table, DataProperty.ReferenceValue, DataProperty.ReferenceValue, false, false);
            }
        }
        this.addRelativeColumns(plotDefinition, ChartType.Table);
        plotDefinitions.push(plotDefinition);

        return plotDefinitions;
    }
}