
import { VarianceSettings } from "../settings/varianceSettings";
import { NORMAL, DIV, HEADER, PX, P, FONT_FAMILY, BACKGROUND, CHANGE, MOUSEENTER, INPUT, DISPLAY, NONE, MOUSELEAVE, HEADER_TOOLTIP, CLICK, BLOCK, TEXT, WIDTH, LEFT, COLOR, FONT_SIZE, WHITE_SPACE, TEXT_ALIGN, Z_INDEX, BOX_SHADOW, HEIGHT, TOP, HEADER_DROPDOWN_SETTINGS_ARROW, POLYLINE, POINTS, STROKE, GRAY, STROKE_WIDTH, FILL_OPACITY, VISIBILITY, POINTER_EVENTS, HIDDEN, ZEBRABI_TABLE_CONTAINER, CENTER, FLEX_END, RIGHT, START, FLEX, BOX_SIZING, BORDER_BOX, JUSTIFY_CONTENT, FIT_CONTENT, NOWRAP, VISIBLE, BACKGROUND_COLOR, PADDING_RIGHT, FLEX_START, FONT_SIZE_UNIT, DEFAULT_FONT, BLACK, EMPTY, KEYUP } from "../library/constants";
import * as d3 from "d3";
import { Visual } from "../visual";
import * as drawing from "./../library/drawing";
import { TitleMenu } from "@zebrabi/design-library";

// TODO: check if this is actually needed
enum IconType {
    BigLogo = 0,
    BigIcon = 1,
    SmallIcon = 2,
}
const BIG_LOGO_WIDTH = 76;
const BIG_LOGO_HEIGHT = 19;
const BIG_ICON_WIDTH = 21;
const BIG_ICON_HEIGHT = 19;
const SMALL_ICON_WIDTH = 17;
const SMALL_ICON_HEIGHT = 15;

export class Title {
    private startingTextAlign: string;
    private text: string;
    private fontFamily: string;
    private fontWeight: string;
    private fontSize: number;
    private fontColor: string;
    private titleTextElement: d3.Selection<HTMLElement, any, any, any>;
    private titleTextChanged: boolean;
    private titleFontFamilyChanged: boolean;
    private titleFontColorChanged: boolean;
    private titleFontSizeChanged: boolean;
    private titleSelection: d3.Selection<HTMLElement, any, any, any>;
    private titleElement: HTMLElement;
    private titleContainer: d3.Selection<HTMLElement, any, any, any>;
    private titleContainerWidth: number;
    private arrow: d3.Selection<SVGElement, any, any, any>;
    private titleInput: HTMLInputElement;
    private isWrapping: boolean;
    private estimatedWatermarkWidth: number;
    private svg: d3.Selection<SVGElement, any, any, any>;
    private clickOutsideListener: any;

    constructor(private settings: VarianceSettings, private availableVisualHeight: number, private availableVisualWidth: number, private titleText: string, svg: d3.Selection<SVGElement, any, any, any>) {
        this.startingTextAlign = settings.titleAlignment;
        this.fontFamily = this.settings.titleFontFamily;
        this.fontSize = this.settings.titleFontSize;
        this.isWrapping = settings.titleWrap;
        this.text = this.titleText;
        this.fontWeight = NORMAL;
        this.fontColor = this.settings.titleFontColor;
        this.estimatedWatermarkWidth = this.getWatermarkIconWidth(this.availableVisualWidth, this.availableVisualHeight, this.settings.proVersionActive()) + 15;
        this.titleTextChanged = false;
        this.titleFontFamilyChanged = false;
        this.titleFontColorChanged = false;
        this.titleFontSizeChanged = false;
        this.svg = svg;
        this.titleContainer = d3.select(Visual.title);
        this.titleContainer.style(DISPLAY, FLEX);
        this.titleContainer.style(BOX_SIZING, BORDER_BOX);
        this.titleContainer.style(JUSTIFY_CONTENT, this.getFlexPositionValue());
        if (!this.settings.showTitle) {
            this.titleContainer.style(DISPLAY, NONE);
        }
    }

    private getWatermarkIconWidth(viewportWidth: number, viewportHeight: number, proFeaturesUnlocked: boolean): number {
        let iconType = this.getWatermarkIconType(viewportWidth, viewportHeight, proFeaturesUnlocked);
        switch (iconType) {
            case IconType.BigLogo:
                return BIG_LOGO_WIDTH;
            case IconType.BigIcon:
                return BIG_ICON_WIDTH;
            case IconType.SmallIcon:
                return SMALL_ICON_WIDTH;
        }
    }

    private getWatermarkIconType(viewportWidth: number, viewportHeight: number, proFeaturesUnlocked: boolean): IconType {
        if (viewportWidth > 240 || viewportHeight > 160) {
            if (!proFeaturesUnlocked && viewportWidth > 400) {
                return IconType.BigLogo;
            }
            return IconType.BigIcon;
        }
        else {
            return IconType.SmallIcon;
        }
    }

    public addTitle() {
        this.plotTitle();
        if (true && this.settings.proFeaturesUnlocked) {
            this.addEventHandlers();
        }
    }

    public plotTitle() {
        this.titleSelection = this.titleContainer.append(DIV).classed(HEADER, true);
        this.titleElement = <HTMLElement>this.titleSelection.node();
        this.titleSelection
            .style(WIDTH, FIT_CONTENT)
            .style(COLOR, this.settings.titleFontColor);

        this.titleTextElement = this.titleSelection.append(P).text(this.text);
        this.titleTextElement
            .style(FONT_SIZE, this.fontSize + FONT_SIZE_UNIT)
            .style(WHITE_SPACE, this.isWrapping ? NORMAL : NOWRAP)
            .style(POINTER_EVENTS, NONE)
            .style(TEXT_ALIGN, this.startingTextAlign);

        drawing.applyFontFamily(this.titleTextElement, this.fontFamily, false, false);
        this.titleContainerWidth = Visual.title.getBoundingClientRect().width;

        this.arrow = drawing.createSvgElement(this.titleSelection.node(), HEADER_DROPDOWN_SETTINGS_ARROW);
        this.arrow
            .attr(WIDTH, 12)
            .attr(HEIGHT, this.getHeight())
            .style(DISPLAY, NONE)
            .append(POLYLINE)
            .attr(POINTS, p => {
                let y = (this.getHeight() / 2);
                return `${3} ${y}, ${8} ${y + 5}, ${13} ${y}`;
            })
            .attr(STROKE, GRAY)
            .attr(STROKE_WIDTH, 1)
            .attr(FILL_OPACITY, 0)
            .attr(VISIBILITY, VISIBLE);
    }

    public addEventHandlers() {
        this.setButtonHoverHandlers();
        this.setTitleSettingsForm();
    }

    private setButtonHoverHandlers() {
        (this.isOverlappingWatermarkIcon() ? this.titleContainer : this.titleSelection)
            .on(MOUSEENTER, () => {
                let input = <HTMLInputElement>(Visual.titleMenuSettings?.select(INPUT).node());

                if (input && this.titleTextChanged && input.value !== this.titleText) {
                    this.settings.persistTitle(input.value);
                    this.titleText = input.value;
                }
                if (this.titleTextChanged || this.titleFontFamilyChanged || this.titleFontSizeChanged || this.titleFontColorChanged) {
                    this.settings.persistTitleStyle(this.titleText, this.fontColor, this.fontFamily, this.fontSize);
                }
                Visual.columnSettings.style(DISPLAY, NONE);
                (<any>window).switchingDecimals = false;
                this.arrow.style(DISPLAY, BLOCK);
                this.titleSelection
                    .style(Z_INDEX, 2)
                    .style(BOX_SHADOW, "0 8px 10px 1px rgba(0, 0, 0, 0.10), 0 3px 14px 2px rgba(0, 0, 0, 0.09), 0 5px 5px -3px rgba(0, 0, 0, 0.15)")
                Visual.title.style.overflow = VISIBLE;
                if (this.isOverlappingWatermarkIcon()) {
                    this.titleContainer.style(PADDING_RIGHT, "50px");
                }
            })
            .on(MOUSELEAVE, () => {
                this.arrow.style(DISPLAY, NONE);
                this.arrow.style(BACKGROUND_COLOR, NONE);
                if (this.titleTextChanged && this.titleInput.value !== this.titleText) {
                    this.titleText = this.titleInput.value;
                }
                this.titleSelection
                    .style(BOX_SHADOW, NONE)
                    .style(Z_INDEX, 1)
                d3.selectAll("." + HEADER_TOOLTIP).remove();
                Visual.title.style.overflow = HIDDEN;
                this.titleContainer.style(PADDING_RIGHT, "0px");
            });
    }
    private closeTitleSettingsForm() {
        document.querySelector(".zebrabi-table-container")?.removeEventListener(CLICK, this.clickOutsideListener, true);
        Visual.columnSettings.style(DISPLAY, NONE);
        Visual.titleMenuSettings?.remove();
        Visual.titleMenuSettings = null;
        (<any>window).switchingDecimals = false;
    }

    private setTitleSettingsForm() {
        this.titleSelection
            .on(CLICK, () => {
                (<any>d3.event).stopPropagation();
                Visual.columnSettings.selectAll("*").remove();
                let titleSettingsDiv = <HTMLElement>Visual.columnSettings.node();
                let isShown = Visual.titleMenuSettings !== null;
                if (isShown) {
                    Visual.columnSettings.style(DISPLAY, NONE);
                    Visual.titleMenuSettings?.remove();
                    Visual.titleMenuSettings = null;
                    return;
                }
                Visual.columnSettings.style(DISPLAY, BLOCK);
                const textElement = (<HTMLElement>this.titleContainer.node()).querySelector(".header");
                let bb = textElement.getBoundingClientRect();

                // Get title menu from design library
                let titleMenu = new TitleMenu(titleSettingsDiv, this.titleText, this.fontColor, this.fontSize, this.fontFamily);
                let [menuElement, inputElement, dropdownElement, fontSizeElement, colorSquare, resetButton, pickr] = titleMenu.openItemMenu(bb.left, bb.top + bb.height + 2);

                // Register listeners
                Visual.titleMenuSettings = d3.select(menuElement);
                d3.select(inputElement).on(INPUT, () => {
                    let event = <any>d3.event;
                    if (event.stopPropagation) {
                        event.stopPropagation();
                    }
                    this.setTitleText((<HTMLInputElement>event.target).value);
                });
                inputElement.focus();
                (<HTMLInputElement>inputElement).setSelectionRange(this.titleText.length, this.titleText.length);

                d3.select(inputElement).on("keyup", () => {
                    if ((<any>d3.event).key === 'Enter') {
                        this.setTitleText((<HTMLInputElement>event.target).value, true)
                    }
                });
                d3.select(dropdownElement).on(CHANGE, () => {
                    let event = <any>d3.event;
                    event.preventDefault();
                    this.setFontFamily((<HTMLSelectElement>event.target).value);
                }).on("keyup", () => {
                    (<KeyboardEvent>event).preventDefault();
                    if ((<KeyboardEvent>event).key === 'Enter') {
                        this.setFontFamily((<HTMLSelectElement>event.target).value);
                    }
                });

                d3.select(fontSizeElement).on(CHANGE, () => {
                    let event = <any>d3.event;
                    let valueAsANumber = (<HTMLInputElement>event.target).valueAsNumber;

                    if (valueAsANumber > 60) {
                        valueAsANumber = 60;
                        (<HTMLInputElement>event.target).value = '60';
                    } else if (valueAsANumber < 8) {
                        valueAsANumber = 8;
                        (<HTMLInputElement>event.target).value = '8';
                    }

                    this.setFontSize(valueAsANumber);
                }).on("keyup", () => {
                    if ((<KeyboardEvent>event).key === 'Enter') {
                        this.setFontSize((<HTMLInputElement>event.target).valueAsNumber, true);
                    }
                })
                d3.select(resetButton).on("click", () => {
                    this.settings.persistTitleStyle(EMPTY, BLACK, DEFAULT_FONT, 12);
                    this.closeTitleSettingsForm();
                });

                let fontColorPickerRevertBack = true;
                pickr.on("save", (color) => {
                    this.setFontColor(color ? color.toHEXA().toString() : null, colorSquare);
                    pickr.hide();
                }).on("clear", () => {
                    this.setFontColor(BLACK, colorSquare);
                    pickr.hide();
                }).on("show", () => {
                    fontColorPickerRevertBack = true;
                }).on("change", (color) => {
                    this.setFontColor(color ? color.toHEXA().toString() : null, colorSquare);
                }).on("hide", () => {
                    // delay setting this variable for clickOutsideListener to run before it - this will prevent the tooltip from closing after selection
                    setTimeout(() => {
                        fontColorPickerRevertBack = true;
                    }, 20);
                });

                this.clickOutsideListener = (event: PointerEvent) => {
                    const wasClickedOutside = !Visual.titleMenuSettings?.node()?.contains(<Node>event.target);
                    if (fontColorPickerRevertBack && wasClickedOutside) {
                        event.stopPropagation();
                        this.setFontColor(this.fontColor, colorSquare, true);
                        this.titleSelection.node().click();
                        this.closeTitleSettingsForm();
                    }
                }
                document.querySelector(".zebrabi-table-container")?.addEventListener(CLICK, this.clickOutsideListener, true);

                // Move window to the left when shown outside on the right side of the visuals div.
                bb = titleSettingsDiv.getBoundingClientRect();
                let tableContainerBB = (<SVGElement>d3.select(`.${ZEBRABI_TABLE_CONTAINER}`).node()).getBoundingClientRect();
                let bbtotal = drawing.getBoundingBoxHtml(this.titleElement.parentElement);
                if (bb.left + bb.width > bbtotal.width + tableContainerBB.left) {
                    let left = tableContainerBB.left + bbtotal.width - bb.width - 2;
                    Visual.columnSettings
                        .style(LEFT, left + PX);
                }
            });
    }

    public getHeight(): number {
        return drawing.getBoundingBoxHtml(this.titleElement).height;
    }

    public fitTextToAvailableWidth() {
        let arrowWidth = 21;
        let width = this.availableVisualWidth - this.estimatedWatermarkWidth - arrowWidth;
        this.titleTextElement.text(drawing.getTailoredText(this.text, this.fontSize, this.fontFamily, this.fontWeight, NORMAL, width));
    }

    private isOverlappingWatermarkIcon(): boolean {
        let bbHeader = (<HTMLElement>this.titleContainer.node()).querySelector(".header").getBoundingClientRect();
        let bbTitle = (<HTMLElement>this.titleContainer.node()).getBoundingClientRect();
        return (bbHeader.left + bbHeader.width - bbTitle.left > this.availableVisualWidth - this.estimatedWatermarkWidth);
    }

    private getFlexPositionValue(): string {
        switch (this.startingTextAlign) {
            case CENTER:
                return CENTER;
            case LEFT:
                return FLEX_START;
            case RIGHT:
                return FLEX_END;
        }
    }

    public setTitleText(titleText: string, persist = false): void {
        this.titleText = titleText;
        // make sure the element doesn't lose it's height when user removes all text - otherwise could lead to focusout event triggering
        if (!titleText) {
            this.titleTextElement.style('min-height', this.titleTextElement.style('height'))
        }
        this.titleTextElement.text(titleText);

        this.attemptToPersistChanges(persist);
    }

    public setFontSize(fontSize: number, persist = false): void {
        this.fontSize = fontSize;
        this.titleTextElement.style(FONT_SIZE, `${fontSize}${FONT_SIZE_UNIT}`);

        this.attemptToPersistChanges(persist);
    }

    public setFontFamily(fontFamily: string, persist = false): void {
        this.fontFamily = fontFamily;
        this.titleTextElement.style(FONT_FAMILY, fontFamily);

        this.attemptToPersistChanges(persist);
    }

    public setFontColor(fontColor: string, colorSquare: HTMLElement, persist = false): void {
        this.fontColor = fontColor;
        this.titleTextElement.style(COLOR, fontColor);
        d3.select(colorSquare).style(BACKGROUND, fontColor);

        this.attemptToPersistChanges(persist);
    }

    public attemptToPersistChanges = (persist: boolean) => {
        if (persist) {
            this.settings.persistTitleStyle(this.titleText, this.fontColor, this.fontFamily, this.fontSize);
            this.closeTitleSettingsForm();
        }
    }
}
