import React from "react";
import { mainDimensions } from "../../tablesDimensionList";
import TablesDimensionItem from "../TablesDimensionItem/TablesDimensionItem";
import useStore from "@zebrabi/tables-chooser/useStore";

export default function TablesDimensionList() {
  const [selectedDimension, setSelectedDimension] = useStore<string>("selectedDimension");

  const handleActive = (dimension) => setSelectedDimension(dimension.id);

  return (
    <div className="w-full">
      {mainDimensions.map((dimension, index) => (
        <div key={index}>
          <TablesDimensionItem
            dimension={dimension}
            isActive={dimension.id === selectedDimension}
            onActive={handleActive}
          />
        </div>
      ))}
    </div>
  );
}
