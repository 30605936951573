import { VerticalPlotter } from "./verticalPlotter";
import { ViewModel } from "../../settings/viewModel";
import { PlotDefinition, ChartType } from "../../definitions";
import { DataProperty, Scenario } from "../../library/constants";

export class VerticalActualPlotter extends VerticalPlotter {
    constructor(viewModel: ViewModel, width: number, height: number, reportArea: d3.Selection<SVGElement, any, any, any>, svg: d3.Selection<SVGElement, any, any, any>,
        locale: string) {
        super(viewModel, width, height, reportArea, svg, locale);
    }

    protected getPlotDefinitions(): PlotDefinition[] {
        let chartPlotDefinitions: PlotDefinition[] = [];
        let plotDefinition = this.getBlankPlotDefinition(1);
        chartPlotDefinitions.push(plotDefinition);
        if (this.viewModel.hasHierarchy) {
            this.addValueColumns(plotDefinition, ChartType.Table);
        }
        else {
            this.addValueColumns(plotDefinition, ChartType.ValueChart);
        }
        return chartPlotDefinitions;
    }
}