import { DataViewHierarchyLevel, DataViewMatrixNode } from "@zebrabi/matrix-data";


import { IHierarchical, ICreateGroups, DataPointType, Value, SortDirection, CategoryOrder, Sums, CumulativeMinMaxAndOffsets, AbsoluteChart, MinMax, CategoryTreeNode, ValueChart } from "../definitions";
import { ChartHierarchy } from "./chartHierarchy";
import { VarianceSettings } from "../settings/varianceSettings";
import { Extremes } from "./extremes";
import { ViewModel } from "../settings/viewModel";
import { DataProperty, EMPTY, NORMAL, TopNType } from "../library/constants";
import { DataPoint } from "./dataPoint";
import { Extreme } from "./extreme";
import { getEmptyMinsAndMaxes } from "./charts/chart"
import { getAdditionalMeasurePropertyFromIndex, getReferenceValueFromRelativeDifference, isRelativeDataProperty, isRelativeMeasure } from "../helpers";
import * as d3 from "d3";
import * as calculations from "./../calculations";
import * as drawing from "./../library/drawing";
import * as formatting from "./../library/formatting";
import { TopNSetting } from "../settings/topNSetting";

export class ChartGroup implements IHierarchical, ICreateGroups {
    public chartHierarchies: ChartHierarchy[];
    public otherHierarchy: ChartHierarchy;
    public chartGroups: ChartGroup[];
    private settings: VarianceSettings;
    public isPlotted: boolean;
    public parent: IHierarchical;
    public level: number;
    public extremes: Extremes;
    public fullGroup: string;
    private allHierarchies: ChartHierarchy[];
    public isCollapsed: boolean;
    public isInverted: boolean;

    constructor(public hasCategories: boolean, public node: DataViewMatrixNode, public hierarchyLevels: DataViewHierarchyLevel[], public group: string, private viewModel: ViewModel,
        public isRowGrandTotal: boolean, public isColumnGrandTotal: boolean, public isSubtotal: boolean, public firstGroupParent: ChartGroup, level: number, parent: ChartGroup) {
        this.chartHierarchies = [];
        this.chartGroups = [];
        this.settings = viewModel.settings;
        this.isPlotted = false;
        this.level = level;
        this.parent = parent;
        this.fullGroup = parent && parent.fullGroup ? `${parent.fullGroup}/${this.group}` : this.group;
        this.allHierarchies = [];
        this.isCollapsed = this.settings.isGroupCollapsed(this);
        this.isInverted = this.settings.isGroupInverted(this);
    }

    get hasChildGroups(): boolean {
        return this.chartGroups.length > 0;
    }

    public isColumnTotalGroup(): boolean {
        return this.isSubtotal && this.level !== 0;
    }

    public createGroup(hasCategories: boolean, node: DataViewMatrixNode, hierarchyLevels: DataViewHierarchyLevel[], isRowGrandTotal: boolean, isColumnGrandTotal: boolean, level: number, totalLabel: string) {
        let group = <ChartGroup>this.parent;
        let nodeName = node.isSubtotal ? totalLabel : (node.value == null ? "" : node.value);
        if (!node.isSubtotal && hierarchyLevels && hierarchyLevels[level] && hierarchyLevels[level].sources && hierarchyLevels[level].sources.length && hierarchyLevels[level].sources[0].type.dateTime) {
            nodeName = formatting.fixDateTime(nodeName, hierarchyLevels[level].sources[0].format, this.viewModel.locale);
        }
        let chartGroup = new ChartGroup(hasCategories, node, hierarchyLevels, <string>nodeName, this.viewModel, isRowGrandTotal, isColumnGrandTotal, node.isSubtotal === true, group, level, this);
        this.chartGroups.push(chartGroup);
        return chartGroup;
    }

    public getChartGroups(): ChartGroup[] {
        let chartGroups = [];
        if (this.hasChildGroups) {
            this.chartGroups.forEach(g => chartGroups = chartGroups.concat(g.getChartGroups()));
            return chartGroups;
        }
        return [this];
    }

    public addHierarchy(hierarchy: ChartHierarchy) {
        this.chartHierarchies.push(hierarchy);
    }

    public addGrandTotals(viewModel: ViewModel) {
        if (this.hasChildGroups) {
            for (let i = 0; i < this.chartGroups.length; i++) {
                this.chartGroups[i].addGrandTotals(viewModel);
            }
        }
        else {
            let valueTotal = this.getTotal(DataProperty.Value, true);
            let referenceTotal = this.getTotal(DataProperty.ReferenceValue, true);
            let secondReferenceValueTotal = 0;
            let thirdReferenceValueTotal = 0;
            let fourthReferenceValueTotal = 0;
            let fifthReferenceValueTotal = 0;
            let sixthReferenceValueTotal = 0;
            let seventhReferenceValueTotal = 0;
            if (viewModel.HasSecondReferenceColumn) {
                secondReferenceValueTotal = this.getTotal(DataProperty.SecondReferenceValue, true);
            }
            if (viewModel.HasThirdReferenceColumn) {
                thirdReferenceValueTotal = this.getTotal(DataProperty.ThirdReferenceValue, true);
            }
            if (viewModel.HasFourthReferenceColumn) {
                fourthReferenceValueTotal = this.getTotal(DataProperty.FourthReferenceValue, true);
            }
            if (viewModel.HasFifthReferenceColumn) {
                fifthReferenceValueTotal = this.getTotal(DataProperty.FifthReferenceValue, true);
            }
            if (viewModel.HasSixthReferenceColumn) {
                sixthReferenceValueTotal = this.getTotal(DataProperty.SixthReferenceValue, true);
            }
            if (viewModel.HasSeventhReferenceColumn) {
                seventhReferenceValueTotal = this.getTotal(DataProperty.SeventhReferenceValue, true);
            }
            let additionalMeasures: number[] = [];
            for (let i = 0; i < viewModel.additionalMeasures.length; i++) {
                additionalMeasures.push(this.getTotal(getAdditionalMeasurePropertyFromIndex(i), true));
            }
            this.addGrandTotalPoint(valueTotal, referenceTotal, secondReferenceValueTotal, thirdReferenceValueTotal, fourthReferenceValueTotal, fifthReferenceValueTotal, sixthReferenceValueTotal, seventhReferenceValueTotal, additionalMeasures, []);
            let groupTotalExtreme = this.calculate(viewModel.locale, viewModel.percentageFormat);
            viewModel.extremes = calculations.reduceMinsAndMaxes([viewModel.extremes, groupTotalExtreme]);
        }
    }

    public addGrandTotalPoint(value: number, referenceValue: number, secondReferenceValue: number, thirdReferenceValue: number, fourthReferenceValue: number, fifthReferenceValue: number, sixthReferenceValue: number, seventhReferenceValue: number, additionalMeasures: number[], tooltipsValues: string[]) {
        let emptyNode: DataViewMatrixNode = {
            value: EMPTY,
            children: [],
            // identity: null,
        };
        let hierarchy = new ChartHierarchy(this.viewModel, false, true, EMPTY, EMPTY, null, emptyNode, [], -1, this, null, false);
        let dataPoint = new DataPoint(value, referenceValue, secondReferenceValue, thirdReferenceValue, fourthReferenceValue, fifthReferenceValue, sixthReferenceValue, seventhReferenceValue, additionalMeasures, tooltipsValues, [], this.settings.rowGrandTotalLabel, EMPTY, null, null, DataPointType.GrandTotal, this.viewModel, 0, hierarchy, null);
        hierarchy.grandTotalDataPoint = dataPoint;
        this.chartHierarchies.push(hierarchy);
    }

    public addGrandTotalPointFromApi(v: Value, viewModel: ViewModel) {
        let emptyNode: DataViewMatrixNode = {
            value: EMPTY,
            children: [],
            // identity: null,
        };
        let hierarchy = new ChartHierarchy(this.viewModel, false, true, EMPTY, EMPTY, null, emptyNode, [], -1, this, null, false);
        let dataPoint = new DataPoint(v.value, v.referenceValue, v.secondReferenceValue, v.thirdReferenceValue, v.fourthReferenceValue, v.fifthReferenceValue, v.sixthReferenceValue, v.seventhReferenceValue, v.additionalMeasures, v.tooltipsValues, [], this.settings.rowGrandTotalLabel, EMPTY, null, null, DataPointType.GrandTotal, this.viewModel, 0, hierarchy, null);
        hierarchy.grandTotalDataPoint = dataPoint;
        this.chartHierarchies.push(hierarchy);
        let groupTotalExtreme = this.calculate(viewModel.locale, viewModel.percentageFormat);
        viewModel.extremes = calculations.reduceMinsAndMaxes([viewModel.extremes, groupTotalExtreme]);
    }

    public getAllChartHierarchies(): ChartHierarchy[] {
        let result = [];
        if (this.hasChildGroups) {
            for (let i = 0; i < this.chartGroups.length; i++) {
                result = result.concat(this.chartGroups[i].getAllChartHierarchies());
            }
        }
        else {
            for (let i = 0; i < this.chartHierarchies.length; i++) {
                result = result.concat(this.chartHierarchies[i].getAllChartHierarchies());
            }
        }
        return result;
    }

    public setAllHierarchies() {
        this.allHierarchies = this.getAllChartHierarchies();
    }

    public getChartHierarchy(index: number): ChartHierarchy {
        if (index >= 0 && index < this.allHierarchies.length) {
            return this.allHierarchies[index];
        }
        return null;
    }

    public getChartHeights(heightPerCategory: number): number[] {
        let chartHeights = [];
        this.chartHierarchies.map(hierarchy => chartHeights.push(heightPerCategory * hierarchy.numberOfAllPoints()));
        return chartHeights;
    }

    public getNumberOfCategories(): number {
        if (this.hasChildGroups) {
            return this.chartGroups.reduce((max, chartGroup) => {
                return Math.max(max, chartGroup.getNumberOfCategories());
            }, 0);
        }
        else {
            let charts: ChartHierarchy[] = [];
            this.chartHierarchies.forEach(hierarchy => {
                charts = charts.concat(hierarchy.getAllChartHierarchies());
            });
            return d3.sum(charts.map(d => d.dataPoints().length));
        }
    }

    public getCategories(category: string): string[] {
        let categories: string[] = [];
        this.chartHierarchies.map(hierarchy => {
            categories.push(...hierarchy.getCategories(category));
        });
        return categories;
    }

    public getNumberOfChartsInGroup(): number {
        if (this.hasChildGroups) {
            return this.chartGroups[0].getNumberOfChartsInGroup();
        }
        return this.getAllChartHierarchies().length;
    }

    public recalculateSubtotals(): void {
        if (this.hasChildGroups) {
            this.chartGroups.forEach(cg => cg.recalculateSubtotals())
        } else {
            this.chartHierarchies.forEach(ch => ch.recalculateSubtotals())
        }
    }

    public getTotal(dataProperty: DataProperty, considerSkippingFormula?: boolean): number {
        if (this.hasChildGroups) {
            if (isRelativeDataProperty(dataProperty)) {
                let valueTotal = d3.sum(this.chartGroups.map(group => group.getTotal(DataProperty.Value, considerSkippingFormula)));
                let referenceProperty = getReferenceValueFromRelativeDifference(dataProperty);
                let referenceTotal = d3.sum(this.chartGroups.map(group => group.getTotal(referenceProperty, considerSkippingFormula)));
                return calculations.calculateRelativeValue(valueTotal, referenceTotal);
            }
            else {
                return d3.sum(this.chartGroups.map(group => group.getTotal(dataProperty, considerSkippingFormula)));
            }
        }
        else {
            if (isRelativeDataProperty(dataProperty)) {
                let valueTotal = d3.sum(this.chartHierarchies.map(hierarchy => hierarchy.getTotal(DataProperty.Value, considerSkippingFormula)));
                let referenceProperty = getReferenceValueFromRelativeDifference(dataProperty);
                let referenceTotal = d3.sum(this.chartHierarchies.map(hierarchy => hierarchy.getTotal(referenceProperty, considerSkippingFormula)));
                return calculations.calculateRelativeValue(valueTotal, referenceTotal);
            }
            else {
                return d3.sum(this.chartHierarchies.map(hierarchy => hierarchy.getTotal(dataProperty, considerSkippingFormula)));
            }
        }
    }

    public sort(chartSort: SortDirection, categorySort: SortDirection, dataProperty: DataProperty) {
        if (this.hasChildGroups) {
            this.chartGroups.map(g => g.sort(chartSort, categorySort, dataProperty));
        }
        else {
            if (chartSort === SortDirection.Descending) {
                this.chartHierarchies.sort((a, b) => {
                    if (a.isGrandTotal) {
                        return 1;
                    }
                    else if (b.isGrandTotal) {
                        return -1;
                    }
                    return b.getTotal(dataProperty) - a.getTotal(dataProperty);
                });
            }
            else if (chartSort === SortDirection.Ascending) {
                this.chartHierarchies.sort((a, b) => {
                    if (a.isGrandTotal) {
                        return 1;
                    }
                    else if (b.isGrandTotal) {
                        return -1;
                    }
                    return a.getTotal(dataProperty) - b.getTotal(dataProperty);
                });
            }
            this.chartHierarchies.forEach(hierarchy => {
                hierarchy.sort(categorySort, dataProperty);
            });
        }
    }

    public sortByReferenceCategories(referenceCategories: string[], category: string) {
        if (this.hasChildGroups) {
            this.chartGroups.forEach(group => group.sortByReferenceCategories(referenceCategories, category));
        }
        else {
            this.chartHierarchies.forEach(hierarchy => hierarchy.sortByReferenceCategories(referenceCategories, category));
        }
    }

    public sortByCategoryTree(categoryTree: CategoryTreeNode, category: string) {
        if (this.hasChildGroups) {
            this.chartGroups.forEach(group => group.sortByCategoryTree(categoryTree, category));
        }
        else {
            let categories = categoryTree.children.map(c => c.category);
            this.chartHierarchies.sort((a, b) => {
                if (a.isGrandTotal) {
                    return 1;
                }
                if (b.isGrandTotal) {
                    return -1;
                }
                return categories.indexOf(a.getCategory()) - categories.indexOf(b.getCategory());
            });
            this.chartHierarchies.filter(h => !h.isGrandTotal).forEach((hierarchy, i) => hierarchy.sortByCategoryTree(categoryTree.children[i], category));
        }
    }

    public cutTopNByCategory(setting: TopNSetting, locale: string, percentageFormat: string, isHierarchical: boolean, summedCategoriesTree: CategoryTreeNode, isFocusMode: boolean) {
        if (this.hasChildGroups) {
            this.chartGroups.forEach(group => group.cutTopNByCategory(setting, locale, percentageFormat, isHierarchical, summedCategoriesTree, isFocusMode));
        } else {
            if (setting.level === 0 && this.chartHierarchies[0].hierarchyLevels.length > 1) {
                let points: DataPoint[] = [];
                this.chartHierarchies.forEach(ch => {
                    points.push(ch.totalDataPoint);
                })
                let filteredPoints = this.addOtherHierarchy(locale, percentageFormat, setting, points, isFocusMode);
                this.chartHierarchies = this.chartHierarchies.filter(ch => filteredPoints.map(fp => fp.fullCategory).indexOf(ch.totalDataPoint.fullCategory) > -1)
                this.chartHierarchies.push(this.otherHierarchy);
            } else {
                this.chartHierarchies.forEach(hierarchy => hierarchy.cutTopNByCategory(setting, locale, percentageFormat, isHierarchical, summedCategoriesTree, isFocusMode));
            }
        }
    }

    public sortByReferenceHierarchies(referenceHierarchies: string[]) {
        if (this.hasChildGroups) {
            this.chartGroups.forEach(group => group.sortByReferenceHierarchies(referenceHierarchies));
        }
        else {
            this.chartHierarchies.sort((a, b) => referenceHierarchies.indexOf(a.getCategory()) - referenceHierarchies.indexOf(b.getCategory()));
            this.chartHierarchies.forEach(hiearchy => hiearchy.sortByReferenceHiearchies(referenceHierarchies));
        }
    }

    public calculate(locale: string, percentageFormat: string): Extremes {
        let sums: Sums = {
            value: null,
            referenceValue: null,
            secondReferenceValue: null,
            thirdReferenceValue: null,
            fourthReferenceValue: null,
            fifthReferenceValue: null,
            sixthReferenceValue: null,
            seventhReferenceValue: null
        };
        if (this.settings.shouldSkipGroup(this)) {
            this.extremes = getEmptyMinsAndMaxes();
            if (this.hasChildGroups) {
                let minsAndMaxes = this.chartGroups.map(g => g.calculate(locale, percentageFormat));
                this.extremes = calculations.reduceMinsAndMaxes(minsAndMaxes);
            }
        }
        else if (this.hasChildGroups) {
            let minsAndMaxes = this.chartGroups.map(g => g.calculate(locale, percentageFormat));
            this.extremes = calculations.reduceMinsAndMaxes(minsAndMaxes);
        }
        else {
            let minsAndMaxes = this.chartHierarchies.map(hierarchy => {
                return hierarchy.calculate(locale, percentageFormat, sums);
            });
            this.extremes = calculations.reduceMinsAndMaxes(minsAndMaxes);
        }
        if (this.isColumnGrandTotal) {
            this.extremes.analyticsValues = [];
        }
        return this.extremes
    }

    public setTopN(setting: TopNSetting, locale: string, percentageFormat: string, isFocusMode: boolean) {
        if (this.hasChildGroups) {
            this.chartGroups.forEach(chartGroup => chartGroup.setTopN(setting, locale, percentageFormat, isFocusMode));
        }
        else {
            //if we want to do top N at the 0th level with 2 or more categories in hierarchy, we need to do it from here..
            if (setting.level === 0 && this.chartHierarchies[0].hierarchyLevels.length > 1) {
                let points: DataPoint[] = [];
                this.chartHierarchies.forEach(ch => {
                    points.push(ch.totalDataPoint);
                })
                let filteredPoints = this.addOtherHierarchy(locale, percentageFormat, setting, points, isFocusMode);
                this.chartHierarchies = this.chartHierarchies.filter(ch => filteredPoints.map(fp => fp.fullCategory).indexOf(ch.totalDataPoint.fullCategory) > -1)
                this.chartHierarchies.push(this.otherHierarchy);
            }
            // else we go into the chart hierarchy
            else {
                this.chartHierarchies.forEach(chartHierarchy => {
                    chartHierarchy.setTopN(setting, locale, percentageFormat, isFocusMode);
                });
            }
        }
    }

    public addOtherHierarchyByCategory(locale: string, percentageFormat: string, setting: TopNSetting, points: DataPoint[], summedCategoriesTree: CategoryTreeNode, isFocusMode: boolean) {
        let emptyNode: DataViewMatrixNode = {
            value: EMPTY,
            children: [],
            // identity: null,
        };
        let hierarchy = new ChartHierarchy(this.viewModel, false, false, EMPTY, EMPTY, this, emptyNode, this.chartHierarchies[0].hierarchyLevels, 0, this, null, false);

        let n = Math.min(isFocusMode ? setting.numberInFocusMode : setting.number, points.length);
        hierarchy.shouldDrawOther = true;
        if (n === points.length) {
            hierarchy.shouldDrawOther = false;
        }
        let filteredValues = summedCategoriesTree.children.filter(co => points.map(dp => dp.fullCategory).indexOf(co.category) > -1);
        let sortedValues: CategoryOrder[] = [];
        if (setting.type === TopNType.Top) {
            sortedValues = filteredValues.sort((a, b) => b.result - a.result);
        } else if (setting.type === TopNType.Bottom) {
            sortedValues = filteredValues.sort((a, b) => a.result - b.result);
        } else {
            sortedValues = filteredValues.sort((a, b) => Math.abs(b.result) - Math.abs(a.result));
        }
        let topNvalues = sortedValues.slice(0, n);
        let topNCategories = topNvalues.map(co => co.category);
        let topNDataPoints = points.filter(dp => topNCategories.indexOf(dp.fullCategory) > -1);

        let otherDPs = points.filter(function (e) { return this.indexOf(e) < 0; }, topNDataPoints);
        points = points.filter(function (e) { return this.indexOf(e) >= 0; }, topNDataPoints);
        let otherDPadditionalMeasures: number[] = [
            hierarchy.sum(DataProperty.AdditionalMeasure1, true, otherDPs, true),
            hierarchy.sum(DataProperty.AdditionalMeasure2, true, otherDPs, true),
            hierarchy.sum(DataProperty.AdditionalMeasure3, true, otherDPs, true),
            hierarchy.sum(DataProperty.AdditionalMeasure4, true, otherDPs, true),
            hierarchy.sum(DataProperty.AdditionalMeasure5, true, otherDPs, true),
            hierarchy.sum(DataProperty.AdditionalMeasure6, true, otherDPs, true),
            hierarchy.sum(DataProperty.AdditionalMeasure7, true, otherDPs, true),
            hierarchy.sum(DataProperty.AdditionalMeasure8, true, otherDPs, true),
            hierarchy.sum(DataProperty.AdditionalMeasure9, true, otherDPs, true),
            hierarchy.sum(DataProperty.AdditionalMeasure10, true, otherDPs, true),
            hierarchy.sum(DataProperty.AdditionalMeasure11, true, otherDPs, true),
            hierarchy.sum(DataProperty.AdditionalMeasure12, true, otherDPs, true),
            hierarchy.sum(DataProperty.AdditionalMeasure13, true, otherDPs, true),
            hierarchy.sum(DataProperty.AdditionalMeasure14, true, otherDPs, true),
            hierarchy.sum(DataProperty.AdditionalMeasure15, true, otherDPs, true),
            hierarchy.sum(DataProperty.AdditionalMeasure16, true, otherDPs, true),
            hierarchy.sum(DataProperty.AdditionalMeasure17, true, otherDPs, true),
            hierarchy.sum(DataProperty.AdditionalMeasure18, true, otherDPs, true),
            hierarchy.sum(DataProperty.AdditionalMeasure19, true, otherDPs, true),
            hierarchy.sum(DataProperty.AdditionalMeasure20, true, otherDPs, true),
        ];
        if (points.length > 0) {
            let fullCategory = points[0].fullCategory.substring(0, points[0].fullCategory.length - points[0].category.length) + this.settings.topNOtherLabel;
            hierarchy.otherDataPoint = new DataPoint(hierarchy.sum(DataProperty.Value, true, otherDPs, true), hierarchy.sum(DataProperty.ReferenceValue, true, otherDPs, true), hierarchy.sum(DataProperty.SecondReferenceValue, true, otherDPs, true),
                hierarchy.sum(DataProperty.ThirdReferenceValue, true, otherDPs, true), hierarchy.sum(DataProperty.FourthReferenceValue, true, otherDPs, true), hierarchy.sum(DataProperty.FifthReferenceValue, true, otherDPs, true),
                hierarchy.sum(DataProperty.SixthReferenceValue, true, otherDPs, true), hierarchy.sum(DataProperty.SeventhReferenceValue, true, otherDPs, true),
                otherDPadditionalMeasures, [], [], this.settings.topNOtherLabel, fullCategory, null, null, DataPointType.OtherTotal, this.viewModel, points[0].level, hierarchy, null);
        }
        else {
            hierarchy.otherDataPoint = new DataPoint(hierarchy.sum(DataProperty.Value, true, otherDPs, true), hierarchy.sum(DataProperty.ReferenceValue, true, otherDPs, true), hierarchy.sum(DataProperty.SecondReferenceValue, true, otherDPs, true),
                hierarchy.sum(DataProperty.ThirdReferenceValue, true, otherDPs, true), hierarchy.sum(DataProperty.FourthReferenceValue, true, otherDPs, true), hierarchy.sum(DataProperty.FifthReferenceValue, true, otherDPs, true),
                hierarchy.sum(DataProperty.SixthReferenceValue, true, otherDPs, true), hierarchy.sum(DataProperty.SeventhReferenceValue, true, otherDPs, true),
                otherDPadditionalMeasures, [], [], this.settings.topNOtherLabel, this.settings.topNOtherLabel, null, null, DataPointType.OtherTotal, this.viewModel, hierarchy.level + 1, hierarchy, null);
        }
        hierarchy.otherDataPoint.calculate(locale, percentageFormat);
        hierarchy.otherDataPoint.associatedDataPoints = otherDPs;
        hierarchy.calculationsFinished = true;

        return points;
    }

    public addOtherHierarchy(locale: string, percentageFormat: string, setting: TopNSetting, points: DataPoint[], isFocusMode: boolean): DataPoint[] {
        let emptyNode: DataViewMatrixNode = {
            value: EMPTY,
            children: [],
            // identity: null,
        };
        let hierarchy = new ChartHierarchy(this.viewModel, false, false, EMPTY, EMPTY, this, emptyNode, this.chartHierarchies[0].hierarchyLevels, 0, this, null, false);
        let n = Math.min(isFocusMode ? setting.numberInFocusMode : setting.number, points.length);
        hierarchy.shouldDrawOther = true;
        if (n === points.length) {
            hierarchy.shouldDrawOther = false;
        }
        let topNarray = [];
        if (setting.type === TopNType.Top) {
            topNarray = points.concat().sort((a, b) => (b.getValue(setting.dataProperty)) - (a.getValue(setting.dataProperty))).slice(0, n);
        }
        else if (setting.type === TopNType.TopAndBottom) {
            topNarray = points.concat().sort((a, b) => Math.abs(b.getValue(setting.dataProperty)) - Math.abs(a.getValue(setting.dataProperty))).slice(0, n);
        } else {
            topNarray = points.concat().sort((a, b) => (a.getValue(setting.dataProperty)) - (b.getValue(setting.dataProperty))).slice(0, n);
        }
        let otherDPs = points.filter(function (e) { return this.indexOf(e) < 0; }, topNarray);
        points = points.filter(function (e) { return this.indexOf(e) >= 0; }, topNarray);
        let otherDPadditionalMeasures: number[] = [];
        for (let index = 0; index < this.viewModel.additionalMeasures.length; index++) {
            let dp = getAdditionalMeasurePropertyFromIndex(index);
            otherDPadditionalMeasures.push(hierarchy.sum(dp, true, otherDPs, true));
        }

        let dataPoint = new DataPoint(hierarchy.sum(DataProperty.Value, true, otherDPs, true), hierarchy.sum(DataProperty.ReferenceValue, true, otherDPs, true), hierarchy.sum(DataProperty.SecondReferenceValue, true, otherDPs, true),
            hierarchy.sum(DataProperty.ThirdReferenceValue, true, otherDPs, true), hierarchy.sum(DataProperty.FourthReferenceValue, true, otherDPs, true), hierarchy.sum(DataProperty.FifthReferenceValue, true, otherDPs, true),
            hierarchy.sum(DataProperty.SixthReferenceValue, true, otherDPs, true), hierarchy.sum(DataProperty.SeventhReferenceValue, true, otherDPs, true),
            otherDPadditionalMeasures, [], [], this.settings.topNOtherLabel, this.settings.topNOtherLabel, null, null, DataPointType.OtherTotal, this.viewModel, 0, hierarchy, null);
        dataPoint.calculate(locale, percentageFormat);
        dataPoint.associatedDataPoints = otherDPs;

        hierarchy.otherDataPoint = dataPoint;
        this.otherHierarchy = hierarchy;
        return points;
    }

    // tslint:disable-next-line: max-func-body-length
    public calculateCumulative(locale: string, percentageFormat: string, maximumExpandedHierarchyLevel: number, maximumExpandedGroupLevel: number): CumulativeMinMaxAndOffsets {
        let e = this.extremes;
        if (this.settings.shouldSkipGroup(this)) {
            e.valueCumulative = new Extreme(0, 0, 0, 0);
            e.referenceValueCumulative = new Extreme(0, 0, 0, 0);
            e.secondReferenceValueCumulative = new Extreme(0, 0, 0, 0);
            e.thirdReferenceValueCumulative = new Extreme(0, 0, 0, 0);
            e.fourthReferenceValueCumulative = new Extreme(0, 0, 0, 0);
            e.fifthReferenceValueCumulative = new Extreme(0, 0, 0, 0);
            e.sixthReferenceValueCumulative = new Extreme(0, 0, 0, 0);
            e.seventhReferenceValueCumulative = new Extreme(0, 0, 0, 0);
            e.absoluteCumulative = new Extreme(0, 0, 0, 0);
            e.secondAbsoluteCumulative = new Extreme(0, 0, 0, 0);
            e.thirdAbsoluteCumulative = new Extreme(0, 0, 0, 0);
            e.fourthAbsoluteCumulative = new Extreme(0, 0, 0, 0);
            e.fifthAbsoluteCumulative = new Extreme(0, 0, 0, 0);
            e.sixthAbsoluteCumulative = new Extreme(0, 0, 0, 0);
            e.seventhAbsoluteCumulative = new Extreme(0, 0, 0, 0);

            if (this.hasChildGroups) {
                let minsAndMaxes = this.chartGroups.map(chartGroup => chartGroup.calculateCumulative(locale, percentageFormat, maximumExpandedHierarchyLevel, maximumExpandedGroupLevel));
                e.valueCumulative = calculations.reduceMinMax(minsAndMaxes.map(mm => mm.value));
                e.referenceValueCumulative = calculations.reduceMinMax(minsAndMaxes.map(mm => mm.referenceValue));
                e.secondReferenceValueCumulative = calculations.reduceMinMax(minsAndMaxes.map(mm => mm.secondReferenceValue));
                e.thirdReferenceValueCumulative = calculations.reduceMinMax(minsAndMaxes.map(mm => mm.thirdReferenceValue));
                e.fourthReferenceValueCumulative = calculations.reduceMinMax(minsAndMaxes.map(mm => mm.fourthReferenceValue));
                e.fifthReferenceValueCumulative = calculations.reduceMinMax(minsAndMaxes.map(mm => mm.fifthReferenceValue));
                e.sixthReferenceValueCumulative = calculations.reduceMinMax(minsAndMaxes.map(mm => mm.sixthReferenceValue));
                e.seventhReferenceValueCumulative = calculations.reduceMinMax(minsAndMaxes.map(mm => mm.seventhReferenceValue));
                e.absoluteCumulative = calculations.reduceMinMax(minsAndMaxes.map(mm => mm.absolute));
                e.secondAbsoluteCumulative = calculations.reduceMinMax(minsAndMaxes.map(mm => mm.secondAbsolute));
                e.thirdAbsoluteCumulative = calculations.reduceMinMax(minsAndMaxes.map(mm => mm.thirdAbsolute));
                e.fourthAbsoluteCumulative = calculations.reduceMinMax(minsAndMaxes.map(mm => mm.fourthAbsolute));
                e.fifthAbsoluteCumulative = calculations.reduceMinMax(minsAndMaxes.map(mm => mm.fifthAbsolute));
                e.sixthAbsoluteCumulative = calculations.reduceMinMax(minsAndMaxes.map(mm => mm.sixthAbsolute));
                e.seventhAbsoluteCumulative = calculations.reduceMinMax(minsAndMaxes.map(mm => mm.seventhAbsolute));
            }
            return {
                value: e.valueCumulative,
                referenceValue: e.referenceValueCumulative,
                secondReferenceValue: e.secondReferenceValueCumulative,
                thirdReferenceValue: e.thirdReferenceValueCumulative,
                fourthReferenceValue: e.fourthReferenceValueCumulative,
                fifthReferenceValue: e.fifthReferenceValueCumulative,
                sixthReferenceValue: e.sixthReferenceValueCumulative,
                seventhReferenceValue: e.seventhReferenceValueCumulative,
                absolute: e.absoluteCumulative,
                secondAbsolute: e.secondAbsoluteCumulative,
                thirdAbsolute: e.thirdAbsoluteCumulative,
                fourthAbsolute: e.fourthAbsoluteCumulative,
                fifthAbsolute: e.fifthAbsoluteCumulative,
                sixthAbsolute: e.sixthAbsoluteCumulative,
                seventhAbsolute: e.seventhAbsoluteCumulative
            };
        }
        else if (this.hasChildGroups) {
            let minsAndMaxes = this.chartGroups.map(chartGroup => chartGroup.calculateCumulative(locale, percentageFormat, maximumExpandedHierarchyLevel, maximumExpandedGroupLevel));
            e.valueCumulative = calculations.reduceMinMax(minsAndMaxes.map(mm => mm.value));
            e.referenceValueCumulative = calculations.reduceMinMax(minsAndMaxes.map(mm => mm.referenceValue));
            e.secondReferenceValueCumulative = calculations.reduceMinMax(minsAndMaxes.map(mm => mm.secondReferenceValue));
            e.thirdReferenceValueCumulative = calculations.reduceMinMax(minsAndMaxes.map(mm => mm.thirdReferenceValue));
            e.fourthReferenceValueCumulative = calculations.reduceMinMax(minsAndMaxes.map(mm => mm.fourthReferenceValue));
            e.fifthReferenceValueCumulative = calculations.reduceMinMax(minsAndMaxes.map(mm => mm.fifthReferenceValue));
            e.sixthReferenceValueCumulative = calculations.reduceMinMax(minsAndMaxes.map(mm => mm.sixthReferenceValue));
            e.seventhReferenceValueCumulative = calculations.reduceMinMax(minsAndMaxes.map(mm => mm.seventhReferenceValue));
            e.absoluteCumulative = calculations.reduceMinMax(minsAndMaxes.map(mm => mm.absolute));
            e.secondAbsoluteCumulative = calculations.reduceMinMax(minsAndMaxes.map(mm => mm.secondAbsolute));
            e.thirdAbsoluteCumulative = calculations.reduceMinMax(minsAndMaxes.map(mm => mm.thirdAbsolute));
            e.fourthAbsoluteCumulative = calculations.reduceMinMax(minsAndMaxes.map(mm => mm.fourthAbsolute));
            e.fifthAbsoluteCumulative = calculations.reduceMinMax(minsAndMaxes.map(mm => mm.fifthAbsolute));
            e.sixthAbsoluteCumulative = calculations.reduceMinMax(minsAndMaxes.map(mm => mm.sixthAbsolute));
            e.seventhAbsoluteCumulative = calculations.reduceMinMax(minsAndMaxes.map(mm => mm.seventhAbsolute));
            return {
                value: e.valueCumulative,
                referenceValue: e.referenceValueCumulative,
                secondReferenceValue: e.secondReferenceValueCumulative,
                thirdReferenceValue: e.thirdReferenceValueCumulative,
                fourthReferenceValue: e.fourthReferenceValueCumulative,
                fifthReferenceValue: e.fifthReferenceValueCumulative,
                sixthReferenceValue: e.sixthReferenceValueCumulative,
                seventhReferenceValue: e.seventhReferenceValueCumulative,
                absolute: e.absoluteCumulative,
                secondAbsolute: e.secondAbsoluteCumulative,
                thirdAbsolute: e.thirdAbsoluteCumulative,
                fourthAbsolute: e.fourthAbsoluteCumulative,
                fifthAbsolute: e.fifthAbsoluteCumulative,
                sixthAbsolute: e.sixthAbsoluteCumulative,
                seventhAbsolute: e.seventhAbsoluteCumulative,
            };
        }
        else {
            e.valueCumulative = new Extreme(0, 0, 0, 0);
            e.referenceValueCumulative = new Extreme(0, 0, 0, 0);
            e.secondReferenceValueCumulative = new Extreme(0, 0, 0, 0);
            e.thirdReferenceValueCumulative = new Extreme(0, 0, 0, 0);
            e.fourthReferenceValueCumulative = new Extreme(0, 0, 0, 0);
            e.fifthReferenceValueCumulative = new Extreme(0, 0, 0, 0);
            e.sixthReferenceValueCumulative = new Extreme(0, 0, 0, 0);
            e.seventhReferenceValueCumulative = new Extreme(0, 0, 0, 0);
            e.absoluteCumulative = new Extreme(0, 0, 0, 0);
            e.secondAbsoluteCumulative = new Extreme(0, 0, 0, 0);
            e.thirdAbsoluteCumulative = new Extreme(0, 0, 0, 0);
            e.fourthAbsoluteCumulative = new Extreme(0, 0, 0, 0);
            e.fifthAbsoluteCumulative = new Extreme(0, 0, 0, 0);
            e.sixthAbsoluteCumulative = new Extreme(0, 0, 0, 0);
            e.seventhAbsoluteCumulative = new Extreme(0, 0, 0, 0);
            let valueCumulative = 0;
            let referenceValueCumulative = 0;
            let secondReferenceValueCumulative = 0;
            let thirdReferenceValueCumulative = 0;
            let fourthReferenceValueCumulative = 0;
            let fifthReferenceValueCumulative = 0;
            let sixthReferenceValueCumulative = 0;
            let seventhReferenceValueCumulative = 0;
            let absoluteCumulative = 0;
            let secondAbsoluteCumulative = 0;
            let thirdAbsoluteCumulative = 0;
            let fourthAbsoluteCumulative = 0;
            let fifthAbsoluteCumulative = 0;
            let sixthAbsoluteCumulative = 0;
            let seventhAbsoluteCumulative = 0;
            for (let i = 0; i < this.chartHierarchies.length; i++) {
                let hierarchy = this.chartHierarchies[i];
                let cmmo = hierarchy.calculateCumulative(valueCumulative, referenceValueCumulative, secondReferenceValueCumulative, thirdReferenceValueCumulative, fourthReferenceValueCumulative, fifthReferenceValueCumulative, sixthReferenceValueCumulative, seventhReferenceValueCumulative,
                    absoluteCumulative, secondAbsoluteCumulative, thirdAbsoluteCumulative, fourthAbsoluteCumulative, fifthAbsoluteCumulative, sixthAbsoluteCumulative, seventhAbsoluteCumulative, locale, percentageFormat, maximumExpandedHierarchyLevel, maximumExpandedGroupLevel);
                calculations.calculateExtremes(cmmo.value, e.valueCumulative);
                calculations.calculateExtremes(cmmo.referenceValue, e.referenceValueCumulative);
                calculations.calculateExtremes(cmmo.secondReferenceValue, e.secondReferenceValueCumulative);
                calculations.calculateExtremes(cmmo.thirdReferenceValue, e.thirdReferenceValueCumulative);
                calculations.calculateExtremes(cmmo.fourthReferenceValue, e.fourthReferenceValueCumulative);
                calculations.calculateExtremes(cmmo.fifthReferenceValue, e.fifthReferenceValueCumulative);
                calculations.calculateExtremes(cmmo.sixthReferenceValue, e.sixthReferenceValueCumulative);
                calculations.calculateExtremes(cmmo.seventhReferenceValue, e.seventhReferenceValueCumulative);
                calculations.calculateExtremes(cmmo.absolute, e.absoluteCumulative);
                calculations.calculateExtremes(cmmo.secondAbsolute, e.secondAbsoluteCumulative);
                calculations.calculateExtremes(cmmo.thirdAbsolute, e.thirdAbsoluteCumulative);
                calculations.calculateExtremes(cmmo.fourthAbsolute, e.fourthAbsoluteCumulative);
                calculations.calculateExtremes(cmmo.fifthAbsolute, e.fifthAbsoluteCumulative);
                calculations.calculateExtremes(cmmo.sixthAbsolute, e.sixthAbsoluteCumulative);
                calculations.calculateExtremes(cmmo.seventhAbsolute, e.seventhAbsoluteCumulative);
                if (this.settings.valueChart === ValueChart.CalculationWaterfall) {
                    valueCumulative = hierarchy.valueCumulative;
                    referenceValueCumulative = hierarchy.referenceValueCumulative;
                    secondReferenceValueCumulative = hierarchy.secondReferenceValueCumulative;
                    thirdReferenceValueCumulative = hierarchy.thirdReferenceValueCumulative;
                    fourthReferenceValueCumulative = hierarchy.fourthReferenceValueCumulative;
                    fifthReferenceValueCumulative = hierarchy.fifthReferenceValueCumulative;
                    sixthReferenceValueCumulative = hierarchy.sixthReferenceValueCumulative;
                    seventhReferenceValueCumulative = hierarchy.seventhReferenceValueCumulative;
                }
                if (this.settings.absoluteChart === AbsoluteChart.CalculationWaterfall) {
                    absoluteCumulative = hierarchy.absoluteCumulative;
                    secondAbsoluteCumulative = hierarchy.secondAbsoluteCumulative;
                    thirdAbsoluteCumulative = hierarchy.thirdAbsoluteCumulative;
                    fourthAbsoluteCumulative = hierarchy.fourthAbsoluteCumulative;
                    fifthAbsoluteCumulative = hierarchy.fifthAbsoluteCumulative;
                    sixthAbsoluteCumulative = hierarchy.sixthAbsoluteCumulative;
                    seventhAbsoluteCumulative = hierarchy.seventhAbsoluteCumulative;
                }
            }
            return {
                value: e.valueCumulative,
                referenceValue: e.referenceValueCumulative,
                secondReferenceValue: e.secondReferenceValueCumulative,
                thirdReferenceValue: e.thirdReferenceValueCumulative,
                fourthReferenceValue: e.fourthReferenceValueCumulative,
                fifthReferenceValue: e.fifthReferenceValueCumulative,
                sixthReferenceValue: e.sixthReferenceValueCumulative,
                seventhReferenceValue: e.seventhReferenceValueCumulative,
                absolute: e.absoluteCumulative,
                secondAbsolute: e.secondAbsoluteCumulative,
                thirdAbsolute: e.thirdAbsoluteCumulative,
                fourthAbsolute: e.fourthAbsoluteCumulative,
                fifthAbsolute: e.fifthAbsoluteCumulative,
                sixthAbsolute: e.sixthAbsoluteCumulative,
                seventhAbsolute: e.seventhAbsoluteCumulative,
            };
        }
    }

    public calculateCumulativeLabelOffset(xScale: d3.ScaleLinear<number, number>, width: number, locale: string, dataProperty: DataProperty): MinMax {
        if (this.hasChildGroups) {
            return this.chartGroups.reduce((result, current) => {
                let mm = current.calculateCumulativeLabelOffset(xScale, width, locale, dataProperty);
                return { min: Math.max(result.min, mm.min), max: Math.max(result.max, mm.max) };
            }, { min: 0, max: 0 });
        }
        else {
            return this.chartHierarchies.reduce((result, current) => {
                let mm = current.calculateCumulativeLabelOffset(xScale, width, locale, dataProperty);
                return { min: Math.max(result.min, mm.min), max: Math.max(result.max, mm.max) };
            }, { min: 0, max: 0 });
        }
    }

    public isLastElementOfHierarchy(index: number) {
        return index === this.getAllChartHierarchies().length - 1;
    }

    public getAllHierarchiesByLevel(level: number): ChartHierarchy[] {
        if (this.hasChildGroups) {
            return this.chartGroups.reduce((result, g) => result.concat(g.getAllHierarchiesByLevel(level)), []);
        }
        return this.chartHierarchies.reduce((result, h) => result.concat(h.getAllHierarchiesByLevel(level)), []);
    }

    public getAllGroupsByLevel(level: number): ChartGroup[] {
        if (this.level === level) {
            return [this];
        }
        else if (this.hasChildGroups) {
            return this.chartGroups.reduce((result, g) => result.concat(g.getAllGroupsByLevel(level)), []);
        } else {
            return [];
        }
    }

    public getMaximumExpandedLevel(): number {
        let chartHierarchies = this.getAllChartHierarchies().filter(h => !h.isGrandTotal && !h.oneDataPointWithSameCategoryAsTotal());
        return chartHierarchies.reduce((result: number, h: ChartHierarchy): number => {
            let currentLevel = 0;
            if (h.totalDataPoint) {
                if (h.totalDataPoint.isFormula()) {
                    currentLevel = -1;
                }
                else {
                    currentLevel = h.isCollapsed() ? h.totalDataPoint.level - 1 : h.totalDataPoint.level;
                }
            }
            return Math.max(currentLevel, result);
        }, -1);
    }

    public getColumnWidth(dataProperties: DataProperty[], viewModel: ViewModel, showingScenarioHeaders: boolean, sizeToData: boolean, groupIndex: number): number[] {
        let fontSize = showingScenarioHeaders ? viewModel.settings.groupTitleFontSize : viewModel.settings.labelFontSize;
        let groupHeaderWidth = drawing.measureTextWidth(this.group, fontSize, viewModel.settings.groupTitleFontFamily, NORMAL, NORMAL) + 5;
        if (this.isSubtotal) {
            let parent = <ChartGroup>this.parent;
            if (parent && parent.isCollapsed) {
                groupHeaderWidth = drawing.measureTextWidth(parent.group, fontSize, viewModel.settings.groupTitleFontFamily, NORMAL, NORMAL) + 5;
            }
        }
        let tableWidths = dataProperties.map(p => viewModel.getTableWidth(p, groupIndex));
        let totalTableWidth = d3.sum(tableWidths);
        if (totalTableWidth > groupHeaderWidth || sizeToData) {
            return tableWidths;
        }
        else {
            return tableWidths.map(t => t * groupHeaderWidth / totalTableWidth);
        }
    }

    public getAllDataPoints(): DataPoint[] {
        if (this.hasChildGroups) {
            return this.chartGroups.reduce((result, group) => {
                return result.concat(group.getAllDataPoints());
            }, []);
        }
        else {
            return this.chartHierarchies.reduce((result, hierarchy) => {
                return result.concat(hierarchy.getAllDataPoints());
            }, []);
        }
    }

    public markElementsAsNotPlotted(): void {
        this.isPlotted = false;
        if (this.hasChildGroups) {
            this.chartGroups.forEach(g => g.markElementsAsNotPlotted());
        }
        else {
            this.getAllChartHierarchies().forEach(h => {
                h.isPlotted = false;
                h.plottedHeader = false;
                h.dataPoints(true, true)
                    .filter(d => d !== undefined)
                    .forEach(d => d.isPlotted = false);
            });
        }
    }

    public markSelectedPoints(selectionIds: string[]): void {
        if (this.hasChildGroups) {
            this.chartGroups.forEach(g => g.markSelectedPoints(selectionIds));
        }
        else {
            this.chartHierarchies.forEach(h => h.markSelectedPoints(selectionIds));
        }
    }

    public getMaxExpandedGroupLevel(): number {
        if (this.hasChildGroups) {
            if (this.isCollapsed) {
                return 0;
            }
            else {
                return d3.max(this.chartGroups.map(g => g.getMaxExpandedGroupLevel()));
            }
        }
        return this.isCollapsed ? 0 : this.level;
    }

    public hasCollapsedAncestor(): boolean {
        let parent = this.parent;
        while (parent) {
            if ((<ChartGroup>parent).isCollapsed) {
                return true;
            }
            parent = parent.parent;
        }
        return false;
    }

    public getTotalGroupId(): number {
        if (!this.hasChildGroups) {
            return -1;
        }
        for (let i = 0; i < this.chartGroups.length; i++) {
            let group = this.chartGroups[i];
            if (group.isSubtotal) {
                for (let j = 0; j < this.viewModel.lastLevelChartGroups.length; j++) {
                    if (this.viewModel.lastLevelChartGroups[j] === group) {
                        return j;
                    }
                }
            }
        }
        return -1;
    }

    public removeDataPointsByCategory(categoriesToDelete: Map<string, boolean>) {
        this.chartHierarchies.forEach(ch => {
            ch.removeDataPointsByCategory(categoriesToDelete);
        });
    }

    getHierarchies(): ChartHierarchy[] {
        return this.chartHierarchies;
    }
}