import { Categories } from "../category.type";
import { StructuralVarianceCommentData, StructuralVarianceSampleData, StructuralVarianceSampleData3Levels } from "./hierarchyCategories.mock";
import { HierarchyPandLCommentData, HierarchyPandLSampleData } from "./../calculation/calculationCategories.mock";


import StructuralHieararchyImage from "./images/structual-variance-hierarchy.png";
import HieararchicalPandLImage from "./images/hierarchy-p-and-l-statement.png";

export const hierarchyCategories: Categories = [
    {
        displayName: "Multiple series",
        tables: [
            {
                id: "structural-variance-hierarchy",
                title: "Structural variance hierarchy",
                image: StructuralHieararchyImage,
                //sampleData: StructuralVarianceSampleData,
                sampleData: StructuralVarianceSampleData3Levels,
                commentData: StructuralVarianceCommentData,
                tooltip:
                    "Structural multi hierarchy variance table with comments.",
                settings: {
                    showAsTable: false,
                    categorySort: 1,
                    chartSort: 1,
                },
                showNewBadge: true,
            },
            {
                id: "hierarchy-p-and-l",
                title: "Hierarchical P&L statement",
                image: HieararchicalPandLImage,
                sampleData: HierarchyPandLSampleData,
                commentData: HierarchyPandLCommentData,
                tooltip:
                    "A table with numbers, waterfall charts and automatically calculated variance. Shows a company’s ability to generate sales, manage expenses, and create profits.",
                showNewBadge: true,
                settings: {
                    showAsTable: false,
                    valueChart: 5,
                    chartSort: 0,
                    categorySort: 0,
                    resultCategories: ["Operating profit", "Profit before tax", "Profit after tax", "Group profit"],
                    invertedCategories: ["Income tax", "Profit to other interests"],
                    userOverrideCategories: ['Expenses', 'Operating profit', 'Profit before tax', 'Income tax', 'Profit after tax', 'Group profit', 'Profit to other interests'],
                    invertedTotalCategories: ['Account group:Expenses'],
                    columnSettingsString: '{ "actual": { "invert": false, "scaleGroup": 1, "format": 0, "suppressOthers": false, "tableView": { "bold": false, "textColor": "", "backgroundFill": "", "markerStyle": 5, "border": "", "showAsTable": 0, "hidden": false, "hiddenFromGroups": [] }, "chartView": { "bold": false, "textColor": "", "backgroundFill": "", "markerStyle": 5, "border": "", "showAsTable": 2, "hidden": false, "hiddenFromGroups": [] } }, "previousYear": { "invert": false, "scaleGroup": 1, "format": 0, "suppressOthers": false, "tableView": { "bold": false, "textColor": "", "backgroundFill": "", "markerStyle": 5, "border": "", "showAsTable": 0, "hidden": false, "hiddenFromGroups": [] }, "chartView": { "bold": false, "textColor": "", "backgroundFill": "", "markerStyle": 5, "border": "", "showAsTable": 2, "hidden": false, "hiddenFromGroups": [] } }, "actual-previousYear": { "invert": false, "scaleGroup": 1, "format": 1, "suppressOthers": false, "tableView": { "bold": false, "textColor": "", "backgroundFill": "", "markerStyle": 5, "border": "", "showAsTable": 0, "hidden": false, "hiddenFromGroups": [] }, "chartView": { "bold": false, "textColor": "", "backgroundFill": "", "markerStyle": 5, "border": "", "showAsTable": 0, "hidden": false, "hiddenFromGroups": [] } }, "actual-previousYear-percent": { "invert": false, "scaleGroup": 1, "format": 2, "suppressOthers": false, "tableView": { "bold": false, "textColor": "", "backgroundFill": "", "markerStyle": 5, "border": "", "showAsTable": 0, "hidden": false, "hiddenFromGroups": [] }, "chartView": { "bold": false, "textColor": "", "backgroundFill": "", "markerStyle": 5, "border": "", "showAsTable": 0, "hidden": false, "hiddenFromGroups": [] } } }',
                    plottedDataProperties: ["1", "0", "2"]
                },
            },
        ],
    },
];
