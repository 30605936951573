import { DataPoint } from "../charting/dataPoint";
import { Formula, FormulaCalculation } from "../definitions";
import * as Calc from "expression-calculator";
import { DisplayUnits, NumberOfDataProperties } from "../library/constants";
import { isAdditionalMeasure, isValueMeasure } from "../library/helpers";
import { getIndexFromAdditionalMeasureDataProperty } from "../helpers";
import { ViewModel } from "./viewModel";

export function parseFormulas(formulaCalculation: FormulaCalculation) {
    formulaCalculation.formulas.forEach(formula => {
        if (formula.units === undefined) {
            formula.units = DisplayUnits.Auto;
        }
        if (formula.percent) {
            formula.units = DisplayUnits.Percent;
            formula.percent = false;
        }
        let fields = getFieldsFromFormula(formula);
        if (fields) {
            fields.forEach(field => {
                let formulaExpression = formulaCalculation.expressionMappings.get(formula.hierarchyIdentity);
                if (!formulaExpression) {
                    formulaExpression = new Map<string, string>();
                    formulaCalculation.expressionMappings.set(formula.hierarchyIdentity, formulaExpression);
                }
                if (!formulaExpression.has(field)) {
                    formulaExpression.set(field, "expression" + formulaExpression.size);
                }
            });
        }
    });
}

function getFieldsFromFormula(formula: Formula) {
    let fieldRegex = new RegExp(/\[.+?\]/, "g"); // /\[.+?\]/g;  ///\(([^\]]+)\)/; // "";
    return formula.expression.match(fieldRegex);
}

export function getFormulaAddedAfterDataPoint(dataPoint: DataPoint, formulas: Formula[], hierarchyIdentity: string): Formula[] {
    let result = []
    for (let index = 0; index < formulas.length; index++) {
        let formula = formulas[index];
        if (formula.position === dataPoint.category && formula.hierarchyIdentity === hierarchyIdentity) {
            result.push(formula);
        }
    }
    return result;
}

export function getFormula(dataPoint: DataPoint, formulas: Formula[], hierarchyIdentity: string): Formula {
    return formulas.find(f => f.identity === dataPoint.fullCategory?.trim() && f.hierarchyIdentity === hierarchyIdentity);
}

export function evaluateFormulas(formulaCalculation: FormulaCalculation, viewModel: ViewModel) {
    formulaCalculation.formulas.forEach(formula => {
        let formulaHierarchies = formulaCalculation.identityDataPoints.get(formula.hierarchyIdentity);
        if (!formulaHierarchies) {
            return;
        }
        formulaHierarchies.forEach((groupMap, groupName) => {
            groupMap.forEach((parentCategoriesMap, parentCategories) => {
                let dp = parentCategoriesMap.get(formula.identity);
                if (!dp) {
                    return;
                }
                let sanitizedFormulaString = formula.expression;
                let fields = getFieldsFromFormula(formula);
                let context = [];
                if (fields) {
                    fields.forEach(field => {
                        let sanitizedField = formulaCalculation.expressionMappings.get(formula.hierarchyIdentity).get(field);
                        sanitizedFormulaString = sanitizedFormulaString.split(field).join(sanitizedField);
                        let fieldDp = formulaCalculation.expressionElements.get(formula.hierarchyIdentity)?.get(groupName)?.get(parentCategories)?.get(field);
                        if (!fieldDp) {
                            return;
                        }
                        for (let dataProperty = 0; dataProperty < NumberOfDataProperties; dataProperty++) {
                            if (!context[dataProperty]) {
                                context[dataProperty] = {};
                            }
                            if (isValueMeasure(dataProperty) || isAdditionalMeasure(dataProperty)) {
                                context[dataProperty][sanitizedField] = fieldDp.getValue(dataProperty);
                            }
                        }
                    });
                    let calc = new Calc();
                    try {
                        calc.compile(sanitizedFormulaString);
                        for (let dataProperty = 0; dataProperty < NumberOfDataProperties; dataProperty++) {
                            if (isValueMeasure(dataProperty) || isAdditionalMeasure(dataProperty)) {
                                if (isAdditionalMeasure(dataProperty)) {
                                    let index = getIndexFromAdditionalMeasureDataProperty(dataProperty);
                                    if (index >= viewModel.additionalMeasures.length) {
                                        continue;
                                    }
                                }
                                let value = calc.calc(context[dataProperty]);
                                if (typeof value !== "number" || isNaN(value) || value === Number.POSITIVE_INFINITY || value === Number.NEGATIVE_INFINITY) {
                                    value = null;
                                }
                                else if (Object.keys(context[dataProperty]).every(expr => context[dataProperty][expr] === null || context[dataProperty][expr] === undefined)) {
                                    value = null;
                                }
                                else if (Object.keys(context[dataProperty]).some(expr => context[dataProperty][expr] === null || context[dataProperty][expr] === undefined)) {
                                    let rpn = <any[]>calc.getRPN();
                                    // set value to null if there are null values in the data and we have * or / operators
                                    if (rpn.some(ex => ex.type === Calc.TOKEN_OPER && (ex.value === "/" || ex.value === "*"))) {
                                        value = null;
                                    }
                                }

                                dp.setValue(dataProperty, value);
                            }
                        }
                    } catch (error) {
                        if (dp) {
                            dp.removeItselfFromParent();
                        }
                    }
                }
                else {
                    dp.removeItselfFromParent();
                }
            });
        });
    });
}